import styled, { css } from "styled-components";
import { breakPointWidth } from "../../config";

export const FormBackground = styled.div`
  height: ${(props) =>
    props.dimentions.width > breakPointWidth ? "100vh" : "92vh"};
  width: 100vw;
  position: fixed;
  top: ${(props) => (props.dimentions.width > breakPointWidth ? "0" : "8vh")};
  left: 0;
  background-color: ${(props) =>
    props.dimentions.width > breakPointWidth
      ? "rgba(0, 0, 0, 0.5)"
      : "rgb(255, 255, 255)"};
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99;
`;

export const FormContainer = styled.div`
  padding: ${(props) =>
    props.dimentions.width > breakPointWidth ? "2rem 2rem;" : "1rem 1rem;"}
  max-height: ${(props) =>
    props.dimentions.width > breakPointWidth ? "80vh;" : "92vh;"}
  width: ${(props) =>
    props.dimentions.width > breakPointWidth
      ? props.width
        ? `${props.width};`
        : "60%;"
      : "100%;"}
  background: #ffffff;
  border-radius: ${(props) =>
    props.dimentions.width > breakPointWidth ? "15px;" : "0px;"}
  z-index: 10000;
`;

export const FormBox = styled.div`
  overflow-y: scroll;
  padding: ${(props) =>
    props?.dimentions?.width > breakPointWidth
      ? "0px 2rem 0px 0px;"
      : "0px 1rem 0px 0px;"};
  max-height: ${(props) =>
    props.dimentions.width > breakPointWidth ? "75vh;" : "80vh;"};
`;
export const InputGroup = styled.div`
  ${(props) =>
    props?.dimentions?.width > breakPointWidth
      ? css`
          display: grid;
          grid-template-columns: 0.5fr 2fr;
          grid-template-areas: "label field";
          grid-column-gap: 2rem;
          margin-bottom: ${(props) => (props.noMargin ? "0.5rem" : "1rem")};
        `
      : css``}
`;

export const FieldLabel = styled.div`
  font-weight: bold;
  font-size: 0.9rem;
  grid-area: "label";
  padding-top: 0.5rem;
  text-align: ${(props) =>
    props?.dimentions?.width > breakPointWidth ? "right;" : "left"};
`;

export const FieldInput = styled.div`
  font-size: 0.9rem;
  grid-area: "field";
  display: flex;
  flex-direction: column;
  justify-content: center;

  ${(props) =>
    props.submitButton
      ? css`
          display: flex;
          flex-direction: row;
          justify-content: flex-end;
        `
      : css``}
  ${(props) =>
    props.border
      ? css`
          padding: 10px 10px;
          border: 0.5px solid rgba(137, 137, 137, 0.4);
          box-sizing: border-box;
          border-radius: 6px;
        `
      : css``}
`;

export const SelectBox = styled.select`
  border: 1px solid #898989;
  background-color: transparent;
  height: 2rem;
  padding: 0px 0.5rem;
  border-radius: 6px;
  width: 100%;
  outline: 0;
  :focus : {
    outline: 0;
  }
`;

export const InputBox = styled.input`
  border: 1px solid #898989;
  background-color: transparent;
  padding: 0px 0.5rem;
  height: 2rem;
  border-radius: 6px;
  width: 100%;
  margin: 5px 0px;
  outline: 0;
  opacity: ${(props) => (props.disabled ? "0.7" : "1")};
  background: ${(props) => (props.disabled ? "#C4C4C4" : "")};

  :focus : {
    outline: 0;
  }
`;


export const TextArea = styled.textarea`
  border: 1px solid #898989;
  background-color: transparent;
  padding: 0px 0.5rem;
  height: 5rem;
  border-radius: 6px;
  width: 100%;
  margin: 5px 0px;
  outline: 0;
  opacity: ${(props) => (props.disabled ? "0.7" : "1")};
  background: ${(props) => (props.disabled ? "#C4C4C4" : "")};

  :focus : {
    outline: 0;
  }
`;
