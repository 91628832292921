import { UPDATE_MEMBERSHIPS } from "../actionTypes";

const membershipsReducer = (memberships = [], action) => {
  if (action.type === UPDATE_MEMBERSHIPS) {
    return (memberships = action.payload);
  }

  return memberships;
};

export default membershipsReducer;
