import React from "react";
const DoorsMenu = ({ tabStatus, setTabStatus }) => {
  return (
    <div className="dFlex aic ">
      <h5
        style={{
          borderBottom: `${tabStatus === "zone" ? "2px solid black" : ""}`,
          margin: "0 .5rem 0 0",
          textDecoration: "none",
          color: `${tabStatus === "zone" ? "black" : "grey"}`,
        }}
        className="font-weight-bold cursor-pointer"
        onClick={(e) => setTabStatus("zone")}
      >
        Zone
      </h5>
      {/* <h5
        style={{
          borderBottom: `${tabStatus === "logs" ? "2px solid black" : ""}`,
          margin: "0 .5rem 0 .5rem",
          textDecoration: "none",
          color: `${tabStatus === "zone" ? "grey" : "black"}`,
        }}
        className="font-weight-bold cursor-pointer"
        onClick={(e) => setTabStatus("logs")}
      >
        Logs
      </h5> */}
    </div>
  );
};

export default DoorsMenu;
