import React from "react";

const Confirmation = ({ setFilled, handleSubmit, formData, formatDate }) => {
  return (
    <div className="ml-auto mr-auto">
      <div className="pageHeading "> Send Entry Invitation</div>
      <div className="col-12">
        <div id="Details">
          {formData.firstName && formData.lastName ? (
            <div className="myTag">
              <div className="head">Name</div>
              <p id="Name">{formData.firstName + " " + formData.lastName}</p>
            </div>
          ) : null}
          {formData.contact ? (
            <div className="myTag">
              <div className="head">
                {formData.contact.includes("@") ? "Email" : "Phone"}
              </div>
              <p id="Phone">{formData.contact}</p>
            </div>
          ) : null}
          {formData.companyName ? (
            <div className="myTag">
              <div className="head">Company Name</div>
              <p id="cName">{formData.companyName}</p>
            </div>
          ) : null}
          {formData.noOfPeople ? (
            <div className="myTag">
              <div className="head">No of People.</div>
              <p id="noOfPeople">{formData.noOfPeople}</p>
            </div>
          ) : null}
          {formData.userToMeetContact ? (
            <div className="myTag">
              <div className="head">Whom to Meet?</div>
              <p id="Whom">{formData.userToMeetContact}</p>
            </div>
          ) : null}
          {formData.purpose ? (
            <div className="myTag">
              <div className="head" id="reasonLabel">
                Reason To Meet?
              </div>
              <p id="Reason">{formData.purpose}</p>
            </div>
          ) : null}
          {formData.startTime && formData.endTime ? (
            <div className="myTag">
              <div className="head">Access Validity</div>
              <p id="Time">
                {formatDate(formData.startTime) +
                  " - " +
                  formatDate(formData.endTime)}
              </p>
            </div>
          ) : null}
        </div>
      </div>
      <div className="col-12 my-1 text-center">
        <button
          id="submitbutton"
          className="bigButton"
          onClick={(e) => setFilled(false)}
        >
          Back
        </button>
      </div>
      <div className="col-12 my-1 text-center">
        <button
          id="submitbutton"
          className="bigButton"
          onClick={(e) => handleSubmit()}
        >
          {`Verify and send ${
            formData.contact.includes("@") ? "Email" : "SMS"
          }`}
        </button>
      </div>
    </div>
  );
};

export default Confirmation;
