import React, { useEffect, useState } from "react";
import { Link, Redirect, useHistory, useLocation } from "react-router-dom";
import { get, post } from "../Services/apiService";
import { createNotification, formatDate, notificationType } from "../Services/utils";
import { ATexpired } from "../../utils/logout";
import { useCookies } from "react-cookie";
import { NotificationManager } from "react-notifications";
import { useDispatch, useSelector } from "react-redux";
import { rootApi } from "../../config";
import { parseQueryParams } from "../../utils/utilFunctions";
const Home = () => {
  const [requests, setRequests] = useState(null);
  const [pending, setPending] = useState(null);
  const [approved, setApproved] = useState(null);
  const user = useSelector((state) => state.user);
  const [cookies, setCookie, removeCookie] = useCookies(["AT"]);
  const [unAuthorized, setUnAuthorized] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation()

  const getVisitorRequests = async () => {
    const url = `${rootApi}user/visitor/requests`;
    try {
      const response = await get(dispatch, url, user.AT);

      if (!response.data.success) {
        createNotification(NotificationManager, notificationType.error, response.data.error);
      } else {
        setRequests(response.data.memberships);
      }
      ATexpired(response, dispatch, removeCookie, history);
    } catch (err) {
      throw err;
    }
  };

  const validate = async (validate, membershipId) => {
    const url = `${rootApi}membership/validate`;
    const body = {
      validate,
      membershipId,
    };
    try {
      const response = await post(dispatch, url, body, user.AT);
      if (!response.data.success) {
        createNotification(NotificationManager, notificationType.error, response.data.error);
      } else {
        getVisitorRequests();
      }
      ATexpired(response, dispatch, removeCookie, history);
    } catch (err) {
      throw err;
    }
  };

  useEffect(() => {
    if (user && user.AT) {
      getVisitorRequests();
    }
  }, []);

  useEffect(() => {
    if (!user || !user.AT) {
      setUnAuthorized(true);
    }
  }, [user]);

  useEffect(() => {
    let approvedTemp = [];
    let pendingTemp = [];
    if (requests && requests.length) {
      for (let i = 0; i < requests.length; i++) {
        if (requests[i].approved === true || requests[i].approved === false) {
          approvedTemp.push(requests[i]);
        } else {
          pendingTemp.push(requests[i]);
        }
      }
    }
    if (pendingTemp) setPending([...pendingTemp]);
    if (approvedTemp) setApproved([...approvedTemp]);
  }, [requests]);

  return (
    <div className="row" style={{ maxWidth: "500px" }}>
      {unAuthorized ? <Redirect to="/" /> : null}
      <div className="col-12 w-100">
        <Link
          to={
            location.search.length && parseQueryParams(location.search).nodeId
              ? `/vms/invite?nodeId=${parseQueryParams(location.search).nodeId}`
              : "/vms/invite"
          }
        >
          <button id="newInvite" className="bigButton">
            New Invite
          </button>
        </Link>
      </div>
      <div className="col-12 w-100">
        <div id="prevInvites">
          <div className="strongText" style={{ textAlign: "center" }}>
            LOG OF PREVIOUS INVITES
          </div>
          <div className="col-12">
            <div className="head">Pending Requests</div>
          </div>
          <div className="col-12">
            <div id="actInvites">
              {pending && pending.length > 0
                ? pending.map((invite) => <Entry validate={validate} invite={invite} />)
                : null}
            </div>
          </div>
          <div className="col-12">
            <div className="head ">Processed Requests</div>
          </div>
          <div className="col-12">
            <div id="expiredInvites">
              {approved && approved.length > 0
                ? approved.map((invite) => <Entry validate={validate} invite={invite} />)
                : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;

const Entry = ({ invite, validate }) => {
  return (
    <div className="entry">
      <div className="log">
        <div className="typeA">
          <div className="head">Name</div>
          <div className="lft text-left">
            {invite.userInfo.firstName + " " + invite.userInfo.lastName}
          </div>
        </div>
        <div className="typeA">
          <div className="head">{invite.userInfo.mobile.number ? "Phone" : "Email"}</div>
          <div className="lft text-left">
            {invite.userInfo.mobile.number ? invite.userInfo.mobile.number : invite.userInfo.email.id}
          </div>
        </div>
        <div className="typeB">
          <div className="head">Company Name</div>
          <div className="lft text-left">{invite.companyName}</div>
        </div>
        <div className="typeA">
          <div className="head">Purpose of Visit</div>
          <div className="lft text-left">{invite.purpose}</div>
        </div>
        <div className="typeB">
          <div className="head">Access Time</div>
          <div className="lft text-left">
            {formatDate(invite.startTime) + " - " + formatDate(invite.endTime)}
          </div>
        </div>
        {invite.approved === true || invite.approved === false ? null : (
          <>
            <div
              className="typeC"
              id="acceptButton"
              style={{ color: "green" }}
              onClick={(e) => validate(true, invite.membershipId)}
            >
              Approve
            </div>
            <div
              className="typeC"
              id="rejectButton"
              onClick={(e) => validate(false, invite.membershipId)}
            >
              Reject
            </div>
          </>
        )}
      </div>
    </div>
  );
};
