import React, { useState } from "react";
import { useCookies } from "react-cookie";
import NotificationManager from "react-notifications/lib/NotificationManager";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { searchUserNode } from "../../../utils/requests";


const SEARCH = ({ user }) => {
  const [cookies, setCookie, removeCookie] = useCookies(["AT"]);
  const dispatch = useDispatch();
  const history = useHistory();
  const [response, setResponse] = useState([]);
  const [userName, setUserName] = useState([]);
  const [input, setInput] = useState("");
  return (
    <div className="py-5 px-2 text-center">
      <div className="form-group">
        <input
          placeholder="Mobile or email or rfId"
          type="text"
          className="form-control"
          required
          value={input}
          onChange={(e) => setInput(e.target.value)}
        />
      </div>
      <div
        className="btn btn-primary"
        onClick={async (e) => {
          let result = await searchUserNode(
            user,
            dispatch,
            NotificationManager,
            removeCookie,
            history,
            input
          );
          if (result.success) {
            setResponse(result.nodes);
            setUserName(result.name);
          }
        }}
      >
        Submit
      </div>
      <div style={{ fontSize: "large" }}>
        <b>User Name:</b>
      </div>
      <div style={{ fontSize: "large" }}>{userName}</div>
      <br />
      <br />
      <div style={{ fontSize: "large" }}>
        <b>Root Nodes with Memberships:</b>
      </div>
      {response.map((val, index) => {
        return (
          <div key={index} style={{ fontSize: "large" }}>
            {val}
          </div>
        );
      })}
    </div>
  );
};

export default SEARCH;
