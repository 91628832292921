import styled from "styled-components";

export const ZoneCurrentCardContainer = styled.div`
  padding: 1rem 1rem;
  background-color: #ffffff;
  border: 0.5px solid #e8e8e8;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  border-radius: 10px;
  margin-bottom: 1rem;
`;

export const ZoneCurrentCardTitle = styled.p`
  font-size: 1.2rem;
  font-weight: bold;
  font-style: normal;
  line-height: 1.5rem;
  margin-bottom: 1.5rem;
  color: #353535;
`;

export const ZoneCurrentStatusDiv = styled.div`
  height: 1rem;
  border-radius: 10px;
  border: 0.5px solid #e8e8e8;
  overflow: hidden;
`;

export const ZoneActiveStatusDiv = styled.div`
  height: 100%;
  margin-bottom: 5px;
  ${(props) => `width: ${props.currentPercentage}%`};
  ${(props) =>
    `background-color: ${props.currentPercentage > 100 ? "red" : "green"}`};
`;

export const ZonePeopleInsideCount = styled.p`
  font-size: 2rem;
  color: ${(props) =>
    props.align === "left"
      ? props.currentPercentage > 100
        ? "red"
        : "green"
      : "grey"};
  font-weight: bold;
  font-style: normal;
  margin: 0px;
  ${(props) => `text-align: ${props.align}`};
`;

export const ZonePeopleInsideCountSubtitle = styled.p`
  font-size: 0.9rem;
  font-weight: lighter;
  font-style: normal;
  color: grey;
  ${(props) => `text-align: ${props.align}`};
`;

// export const ZoneAccessCardSubtitle = styled.p`
//   font-size: 0.9rem;
//   font-weight: lighter;
//   font-style: normal;
//   color : grey;
// `;

// export const ZoneAccessCardEditIconContainer = styled.div`
//   text-align: right;
//   padding : 1rem 0.5rem 0 0;

// `;
//  ${(props) => `background: linear-gradient(
//     90deg,
//     ${props.currentPercentage > 90 ? "red" : "green"} ${
//       props.currentPercentage
//     }%,
//     #ffffff ${100 - props.currentPercentage}%
//     )`};
