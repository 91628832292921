import React, { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { getUserRole } from "../utils/utilFunctions";
import FeatureForm from "../components/featureForm/FeatureForm";
import { sendCustomPageView } from "../utils/tagManagerEvents";
import OACFORM from "../components/configure/OACFORM/OACFORM";
import OwnershipTransfer from "../components/configure/OwnershipTransfer/OwnershipTransfer";
import RFFORM from "../components/configure/RFFORM/RFFORM";
import PLATFORMLOCKFORM from "../components/configure/PLATFORMLOCKFORM/PLATFORMLOCKFORM";
import NORMALLOCKFORM from "../components/configure/NORMALLOCKFORM/NORMALLOCKFORM";
import DeviceQRCode from "../components/configure/DeviceQRCode/DeviceQRCode";
import SEARCH from "../components/configure/SEARCH/SEARCH.jsx";
import SEARCH_DEVICE from "../components/configure/SEARCH_DEVICE/SEARCH_DEVICE";
import NODESEARCHFORM from "../components/configure/NODESEARCHFORM/NODESEARCHFORM";
import DeviceFirmwareSection from "../components/configure/DeviceFirmwareSection/DeviceFirmwareSection";
import RFIDQrCode from "../components/configure/RFIDQrCode/RFIDQrCode";
import { FieldLabel, InputGroup, SelectBox } from "../components/forms/FormBase.styled";

const forms = [
  { name: "Device OwnerShip Transfer", value: "OwnershipTransfer" },
  { name: "Platform Lock", value: "PLATFORM LOCK" },
  { name: "Normal Lock", value: "NORMAL LOCK" },
  { name: "Get Device QR Codes", value: "QRCODE" },
  { name: "OAC", value: "OAC" },
  { name: "Node Features", value: "Features" },
  { name: "User Search", value: "SearchUser" },
  { name: "Device Search", value: "SearchDevice" },
  { name: "Node Search", value: "NODES" },
  { name: "Device Firmware", value: "deviceFirmware" },
  { name: "Generate RFID QR Codes", value: "RFIDQrCode" },
];

const Configure = () => {
  const [form, setForm] = useState("OwnershipTransfer");
  const user = useSelector((state) => state.user);
  const nodeInfo = useSelector((state) => state.nodeInfo);
  useEffect(() => {
    sendCustomPageView(
      user.userId,
      nodeInfo.name,
      nodeInfo.nodeCategory,
      getUserRole(user.roles, nodeInfo)
    );
  }, [nodeInfo, user]);
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12">
          <select class="custom-select" onChange={(e) => setForm(e.target.value)}>
            {forms.map(({ name, value }) => (
              <option key={value} value={value}>
                {name}
              </option>
            ))}
          </select>
        </div>
        <div className="col-12">
          {form === "RFCARD" ? (
            <RFFORM user={user} />
          ) : form === "PLATFORM LOCK" ? (
            <PLATFORMLOCKFORM user={user} />
          ) : form === "NORMAL LOCK" ? (
            <NORMALLOCKFORM user={user} />
          ) : form === "QRCODE" ? (
            <DeviceQRCode user={user} />
          ) : form === "OwnershipTransfer" ? (
            <OwnershipTransfer user={user} />
          ) : form === "SearchUser" ? (
            <SEARCH user={user} />
          ) : form === "SearchDevice" ? (
            <SEARCH_DEVICE user={user} />
          ) : form === "OAC" ? (
            <OACFORM user={user} />
          ) : form === "NODES" ? (
            <NODESEARCHFORM user={user} />
          ) : form === "Features" ? (
            <FeatureForm />
          ) : form === "deviceFirmware" ? (
            <DeviceFirmwareSection user={user} />
          ) : form === "RFIDQrCode" ? (
            <RFIDQrCode user={user} />
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Configure;
