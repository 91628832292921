import React, { useEffect, useState } from "react";
import {
  FormBackground,
  FormBox,
  FormContainer,
} from "../forms/FormBase.styled";
import { AiOutlineCloseCircle } from "react-icons/ai";
import {
  ButtonMobile,
  FieldLabelMobile,
  FieldValueMobile,
  ZoneLogsUserRowMobileView,
  ZoneLogsUserRowMobileWrapper,
} from "../zone/ZoneLogsUserRow/zoneLogsUserRow.styled";
import {
  PageButtonContainer,
  PageButton,
} from "../../paging.styled";
import {FaAngleDoubleLeft, FaAngleDoubleRight, FaAngleLeft, FaAngleRight} from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { convetToLocaleDateString } from "../../utils/utilFunctions";
import { removeMembershipFromAccessGroup, getGroupNodeMemberships } from "../../utils/requests";
import { useCookies } from "react-cookie";
import { useHistory } from "react-router-dom";
import NotificationManager from "react-notifications/lib/NotificationManager";
import { breakPointWidth } from "../../config";
import MembershipView from "../people/membershipView/MembershipView";
const GroupMembersMobileView = ({
  showMenu,
  setShowMenu,
  enabledGroupId,
  setEnabledGroupId,
}) => {
  const dimentions = useSelector((state) => state.dimentions);
  const [cookies, setCookie, removeCookie] = useCookies(["AT"]);
  const user = useSelector((state) => state.user);
  const utils = useSelector((state) => state.utils);
  const selectedNode = useSelector((state) => state.selectedNode);
  const [memberships, setMemberships] = useState([]);
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [noOfPages, setNoOfPages] = useState(1);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(()=>{
    getGroupNodeMemberships(
      user,
      dispatch,
      NotificationManager,
      removeCookie,
      history,
      selectedNode,
      enabledGroupId,
      pageNo,
      pageSize,
      setMemberships,
      setNoOfPages,
    )
  },[pageNo]);

  const handleRemoveMembership = async (groupId, membershipId) => {
    await removeMembershipFromAccessGroup(
      user,
      dispatch,
      NotificationManager,
      removeCookie,
      history,
      groupId,
      membershipId,
      selectedNode,
      utils
    );

    let temp = [...showMenu];
    temp.splice(temp.indexOf(membershipId), 1);
    setShowMenu([...temp]);
  };

  return (
    <FormBackground
      dimentions={dimentions}
      onClick={(e) => {
        setShowMenu(false);
        setEnabledGroupId(null);
      }}
    >
      <FormContainer
        dimentions={dimentions}
        onClick={(e) => e.stopPropagation()}
      >
        <FormBox dimentions={dimentions}>
          <div className="text-right">
            <p
              onClick={(e) => setShowMenu(false)}
              style={{ cursor: "pointer", fontSize: "1.2rem" }}
            >
              <AiOutlineCloseCircle />
            </p>
          </div>

          {dimentions.width > breakPointWidth ? (
            <div className="row m-0 p-0">
              <div className="col-12">
                <MembershipView
                  showMenu={showMenu}
                  memberships={memberships}
                  handleRemoveMembership={handleRemoveMembership}
                  accessGroupId={enabledGroupId}
                />
              </div>
            </div>
          ) : (
            <div className="row m-0 p-0">
              {memberships
                ? memberships.map((i, indx) =>
                    showMenu.includes(i.membershipId) ? (
                      <div
                        className="col-sm-12 col-xs-12 p-0 col-md-6 px-md-2"
                        key={i.membershipId}
                      >
                        <ZoneLogsUserRowMobileWrapper>
                          <ZoneLogsUserRowMobileView>
                            <div>
                              <FieldLabelMobile>Name</FieldLabelMobile>
                              <FieldValueMobile>
                                {i.userInfo.firstName +
                                  " " +
                                  i.userInfo.lastName}
                              </FieldValueMobile>
                            </div>
                            <div>
                              <FieldLabelMobile>ID</FieldLabelMobile>
                              <FieldValueMobile>
                                {i.userInfo.customUserId}
                              </FieldValueMobile>
                            </div>
                            <div>
                              <FieldLabelMobile>Email</FieldLabelMobile>
                              <FieldValueMobile>
                                {i.userInfo.email.verified
                                  ? i.userInfo.email.id
                                  : "-"}
                              </FieldValueMobile>
                            </div>
                            <div>
                              <FieldLabelMobile>Phone</FieldLabelMobile>
                              <FieldValueMobile>
                                {i.userInfo.mobile.verified
                                  ? i.userInfo.mobile.number
                                  : "-"}
                              </FieldValueMobile>
                            </div>
                            <div>
                              <FieldLabelMobile>Access Date</FieldLabelMobile>
                              <FieldValueMobile>
                                {convetToLocaleDateString(i.startTime)}
                              </FieldValueMobile>
                            </div>
                          </ZoneLogsUserRowMobileView>
                          <div className="col-12 text-center my-2 px-0">
                            <ButtonMobile
                              className="w-100"
                              onClick={(e) => {
                                handleRemoveMembership(
                                  enabledGroupId,
                                  i.membershipId
                                );
                              }}
                            >
                              Revoke
                            </ButtonMobile>
                          </div>
                        </ZoneLogsUserRowMobileWrapper>
                      </div>
                    ) : null
                  )
                : null}
            </div>
          )}
          <PageButtonContainer>
            {pageNo>3?<PageButton
            onClick={((e)=>{
              if(pageNo-5>0){
                setPageNo(pageNo-5);
              }else{
                setPageNo(1);
              }
            })
            }
            ><FaAngleLeft/></PageButton>:null}
            {pageNo-2>0?(<PageButton
            onClick={((e)=>{
              setPageNo(pageNo-2);
            })
            }
            >{pageNo-2}</PageButton>):null}
            {pageNo-1>0?(<PageButton
            onClick={((e)=>{
              setPageNo(pageNo-1);
            })
            }
            >{pageNo-1}</PageButton>):null}
            {noOfPages>1?
            <PageButton selected={true}>{pageNo}</PageButton>:null}
            {noOfPages>=pageNo+1?
            (<PageButton
            onClick={((e)=>{
              setPageNo(pageNo+1);
            })
            }
            >{pageNo+1}</PageButton>):null}
            {noOfPages>=pageNo+2?
            (<PageButton
            onClick={((e)=>{
              setPageNo(pageNo+2);
            })
            }
            >{pageNo+2}</PageButton>):null}
              {pageNo+2<noOfPages?<PageButton
            onClick={((e)=>{
              if(pageNo+5<=noOfPages){
                setPageNo(pageNo+5);
              }else{
                setPageNo(noOfPages);
              }
            })
            }
            ><FaAngleRight/></PageButton>:null}
          </PageButtonContainer>
  
        </FormBox>
      </FormContainer>
    </FormBackground>
  );
};

export default GroupMembersMobileView;
