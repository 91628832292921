import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateAddRootNode } from "../../../redux/addRootNode/addRootNodeActions";
import { SolidButton } from "../../buttons/solidButton.styled";
import GreenSwitch from "../../switches/GreenSwitch";
import { FormWrapper } from "../doorAddForm/DoorAddForm";
import { useCookies } from "react-cookie";
import { Tooltip } from "react-hover-tooltip";
import { BsInfoCircle } from "react-icons/bs";
import { FieldInput, FieldLabel, InputBox, InputGroup, SelectBox } from "../FormBase.styled";
import { useHistory, useLocation } from "react-router-dom";
import NotificationManager from "react-notifications/lib/NotificationManager";
import { addZoneAPI, updateNodeInfoAPI } from "../../../utils/requests";
import { apiEndPoints, formNames, nodeCategoryMap, OPS_LIST } from "../../../config";
import { toggleForm } from "../../../utils/utilFunctions";
import ButtonState from "../../ButtonState/ButtonState";

const ZoneAddForm = ({ update, formName }) => {
  const [cookies, setCookie, removeCookie] = useCookies(["AT"]);
  let history = useHistory();
  const location = useLocation();
  const [csNodeId, setCsNodeId] = useState("");
  const [formData, setFormData] = useState({
    name: "",
    nodeAddress: "",
    city: "",
    locationURL: "",
    locationCode: "",
    nodeCategory: null,
    isConferenceRoom: false,
    restrictIfPeopleLimitReached: false,
    restrictIfTempHigh: false,
    bypassAccessControl: false,
    enableAttendanceMonitoring: false,
    enableVisitorManagement: false,
    peopleLimit: "",
    newCsNodeId: "",
  });
  const addRootNode = useSelector((state) => state.addRootNode);
  const selectedNode = useSelector((state) => state.selectedNode);
  const formState = useSelector((state) => state.formState);
  const apiStatus = useSelector((state) => state.apiStatus);
  const dimentions = useSelector((state) => state.dimentions);
  const user = useSelector((state) => state.user);
  const nodeInfo = useSelector((state) => state.nodeInfo);
  const dispatch = useDispatch();
  const handleChange = (field, e) => {
    if (
      field === "name" ||
      field === "newCsNodeId" ||
      field === "city" ||
      field === "nodeAddress" ||
      field === "locationURL" ||
      field === "locationCode" ||
      field === "peopleLimit" ||
      field === "nodeCategory"
    ) {
      if (field === "nodeCategory" && e.target.value === "Select") {
        setFormData({ ...formData, [field]: null });
      } else {
        setFormData({ ...formData, [field]: e.target.value });
      }
    } else {
      setFormData({ ...formData, [field]: e.target.checked });
    }
  };

  const closeForm = () => {
    if (formState[formNames.addRootNodeForm]) {
      // dispatch(updateAddRootNode(false));
      toggleForm(formState, history, location, dispatch, formNames.addRootNodeForm);
    } else if (formState[formNames.zoneUpdateForm]) {
      toggleForm(formState, history, location, dispatch, formNames.zoneUpdateForm);
    } else {
      toggleForm(formState, history, location, dispatch, formNames.zoneAddForm);
    }
  };

  useEffect(() => {
    if (formState[formNames.zoneUpdateForm]) {
      const {
        name,
        nodeAddress,
        city,
        locationURL,
        locationCode,
        isConferenceRoom,
        restrictIfPeopleLimitReached,
        restrictIfTempHigh,
        bypassAccessControl,
        enableAttendanceMonitoring,
        enableVisitorManagement,
        peopleLimit,
        nodeCategory,
        csNodeId,
      } = nodeInfo;

      setFormData({
        ...formData,
        name,
        nodeAddress,
        city,
        locationURL,
        locationCode,
        isConferenceRoom,
        restrictIfPeopleLimitReached,
        restrictIfTempHigh,
        bypassAccessControl,
        enableAttendanceMonitoring,
        enableVisitorManagement,
        peopleLimit,
        nodeCategory,
        newCsNodeId: csNodeId,
      });
    }
    // eslint-disable-next-line
  }, [nodeInfo]);

  return (
    <FormWrapper dimentions={dimentions} closeForm={closeForm}>
      <h6 className="font-weight-bold mb-4">
        {formState[formNames.zoneUpdateForm] ? "Update" : "Add New"} Zone
      </h6>

      <InputGroup dimentions={dimentions}>
        <FieldLabel dimentions={dimentions}>Zone Name</FieldLabel>
        <FieldInput>
          <InputBox
            // placeholder="Zone name"
            value={formData.name}
            onChange={(e) => handleChange("name", e)}
          />
          {/* <div className="ml-3 mt-2 d-flex align-items-center">
            <input
              className="m-0"
              type="checkbox"
              checked={formData.isConferenceRoom}
              onChange={(e) => handleChange("isConferenceRoom", e)}
            />
            <span className="ml-2">Is this zone a confrence room ?</span>
          </div> */}
        </FieldInput>
      </InputGroup>
      {OPS_LIST.includes(user.mobileNo) ? (
        <InputGroup dimentions={dimentions}>
          <FieldLabel dimentions={dimentions}>CS Node ID</FieldLabel>
          <FieldInput>
            <InputBox value={formData.newCsNodeId} onChange={(e) => handleChange("newCsNodeId", e)} />
          </FieldInput>
        </InputGroup>
      ) : null}
      {(formState[formNames.addRootNodeForm] ||
        (formState[formNames.zoneUpdateForm] && nodeInfo.rootNodeId === null)) && (
        <InputGroup dimentions={dimentions}>
          <FieldLabel dimentions={dimentions}>Node Category</FieldLabel>
          <FieldInput>
            {OPS_LIST.includes(user.mobileNo) ? (
              <SelectBox
                value={formData.nodeCategory || "Select"}
                onChange={(e) => handleChange("nodeCategory", e)}
              >
                <option>Select</option>
                {Object.values(nodeCategoryMap).map((c) => (
                  <option>{c}</option>
                ))}
              </SelectBox>
            ) : (
              <div className="py-2">
                {`${formData.nodeCategory} `}
                <Tooltip content={<div>Please Contact customer care for changing Node category</div>}>
                  <BsInfoCircle size="0.9rem" />
                </Tooltip>
              </div>
            )}
          </FieldInput>
        </InputGroup>
      )}
      <InputGroup dimentions={dimentions}>
        <FieldLabel dimentions={dimentions}>Address</FieldLabel>
        <FieldInput>
          <InputBox
            // placeholder="Zone name"
            value={formData.nodeAddress}
            onChange={(e) => handleChange("nodeAddress", e)}
          />
        </FieldInput>
      </InputGroup>
      <InputGroup dimentions={dimentions}>
        <FieldLabel dimentions={dimentions}>City</FieldLabel>
        <FieldInput>
          <InputBox
            // placeholder="Zone name"
            value={formData.city}
            onChange={(e) => handleChange("city", e)}
          />
        </FieldInput>
      </InputGroup>
      <InputGroup dimentions={dimentions}>
        <FieldLabel dimentions={dimentions}>Location URL</FieldLabel>
        <FieldInput>
          <InputBox
            // placeholder="Zone name"
            value={formData.locationURL}
            onChange={(e) => handleChange("locationURL", e)}
          />
        </FieldInput>
      </InputGroup>
      <InputGroup dimentions={dimentions}>
        <FieldLabel dimentions={dimentions}>Location Code</FieldLabel>
        <FieldInput>
          <InputBox
            // placeholder="Zone name"
            value={formData.locationCode}
            onChange={(e) => handleChange("locationCode", e)}
          />
        </FieldInput>
      </InputGroup>
      {/* <InputGroup dimentions={dimentions}>
        <FieldLabel dimentions={dimentions}>Manage</FieldLabel>
        <FieldInput>
          <div className="d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center">
              <input
                className="m-0"
                type="checkbox"
                checked={formData.enableVisitorManagement}
                onChange={(e) => handleChange("enableVisitorManagement", e)}
              />
              <span className="ml-2">Visitor permission</span>
            </div>
            <div className="d-flex align-items-center">
              <input
                className="m-0"
                type="checkbox"
                checked={formData.enableAttendanceMonitoring}
                onChange={(e) => handleChange("enableAttendanceMonitoring", e)}
              />
              <span className="ml-2">Attendance</span>
            </div>
          </div>
        </FieldInput>
      </InputGroup>
      <InputGroup noMargin={true}>
        <FieldLabel className="text-muted">Restrictions</FieldLabel>
        <FieldInput></FieldInput>
      </InputGroup>
      <InputGroup dimentions={dimentions}>
        <FieldLabel dimentions={dimentions}>Allow if Threshold Reached</FieldLabel>
        <FieldInput>
          <GreenSwitch
            checked={formData.restrictIfPeopleLimitReached}
            onChange={(e) => handleChange("restrictIfPeopleLimitReached", e)}
          />
        </FieldInput>
      </InputGroup>
      <InputGroup dimentions={dimentions}>
        <FieldLabel dimentions={dimentions}>Threshold</FieldLabel>
        <FieldInput>
          <InputBox
            type="number"
            // placeholder="No of people"
            value={formData.peopleLimit}
            onChange={(e) => handleChange("peopleLimit", e)}
          />
        </FieldInput>
      </InputGroup>
      <InputGroup dimentions={dimentions}>
        <FieldLabel dimentions={dimentions}>Allow if Fever Detected</FieldLabel>
        <FieldInput>
          <GreenSwitch
            checked={formData.restrictIfTempHigh}
            onChange={(e) => handleChange("restrictIfTempHigh", e)}
          />
        </FieldInput>
      </InputGroup>
      <InputGroup dimentions={dimentions}>
        <FieldLabel dimentions={dimentions}>Bypass Access Control</FieldLabel>
        <FieldInput>
          <GreenSwitch
            checked={formData.bypassAccessControl}
            onChange={(e) => handleChange("bypassAccessControl", e)}
          />
        </FieldInput>
      </InputGroup> */}
      <InputGroup dimentions={dimentions}>
        <FieldLabel dimentions={dimentions}></FieldLabel>
        <FieldInput submitButton={true}>
          <SolidButton
            onClick={(e) =>
              formState[formNames.zoneUpdateForm]
                ? updateNodeInfoAPI(
                    user,
                    dispatch,
                    NotificationManager,
                    removeCookie,
                    history,
                    selectedNode,
                    { nodeId: selectedNode.nodeId, ...formData },
                    closeForm
                  )
                : addZoneAPI(
                    user,
                    dispatch,
                    NotificationManager,
                    removeCookie,
                    history,
                    { ...formData },
                    formState[formNames.addRootNodeForm],
                    closeForm,
                    selectedNode
                  )
            }
            submit={true}
          >
            <ButtonState
              loading={apiStatus[apiEndPoints.updateNodeInfoAPI] || apiStatus[apiEndPoints.addZoneAPI]}
              innerText={formState[formNames.zoneUpdateForm] ? "Update" : "Add"}
            />
          </SolidButton>
        </FieldInput>
      </InputGroup>
    </FormWrapper>
  );
};

export default ZoneAddForm;
