import styled, { css } from "styled-components";
import { breakPointWidth } from "../config";

export const AppContainer = styled.div`
  box-sizing: border-box;
  display: grid;
  max-height: 100vh;
  max-width: 100vw;
  padding-top: ${(props) => (props.dimentions.width > breakPointWidth ? "0px" : "9vh")};
  ${(props) =>
    props.dimentions.width > breakPointWidth
      ? props.pathname === "/visitor" || props.pathname === "/people"
        ? css`
            grid-template-columns: 15% 85%;
            grid-template-areas: "leftsidebar main ";
          `
        : props.pathname.startsWith("/vams") || props.pathname === "/" || props.pathname === "/apps"
        ? css`
            grid-template-columns: 100%;
            grid-template-areas: "main";
          `
        : css`
            /* grid-template-columns: 15% 70% 15%;
              grid-template-areas: "leftsidebar main rightsidebar"; */
            grid-template-columns: 15% 85%;
            grid-template-areas: "leftsidebar main";
          `
      : css`
          grid-template-columns: 100%;
          grid-template-areas: "main";
        `};
`;

export const IconContainer = styled.p`
  cursor: pointer;
  font-size: 1.3rem;
  margin: 0px 0px;
  margin-left 0.5rem;
  margin-bottom: 5px;
  color: #49eecc;
`;

export const OverFLowScrollBox = styled.div`
  height: 90%;
  overflow-y: scroll;
  :focus {
    outline: none;
  }
`;
