import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import NotificationManager from "react-notifications/lib/NotificationManager";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { breakPointWidth, rootApi } from "../../../config";
import { getUnassignedDivices } from "../../../utils/requests";
import { nullChecker, displayDate } from "../../../utils/utilFunctions";
import { OverFLowScrollBox } from "../../App.styled";
import PlaceholderMessage from "../../PlaceholderMessage";
import {
  ButtonMobile,
  FieldLabelMobile,
  FieldValueMobile,
  ZoneLogsUserRowMobileView,
  ZoneLogsUserRowMobileWrapper,
} from "../../zone/ZoneLogsUserRow/zoneLogsUserRow.styled";
import { DevicesRowContainer } from "../activeDevices/activeDevices.styled";
import {
  InActiveDevicesRowStyle,
  DeviceId,
  Type,
  AddDate,
  Button,
} from "./inActiveDevices.styled";

const InActiveDevices = ({ activateDevice }) => {
  const [devices, setDevices] = useState(null);
  const dimentions = useSelector((state) => state.dimentions);
  const user = useSelector((state) => state.user);
  const [cookies, setCookie, removeCookie] = useCookies(["AT"]);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    getUnassignedDivices(
      user,
      dispatch,
      NotificationManager,
      removeCookie,
      history,
      setDevices
    );
  }, []);
  return (
    <>
      {dimentions.width > breakPointWidth ? (
        <DevicesRowContainer>
          <InActiveDevicesRowStyle isHead={true}>
            <DeviceId>Device Id</DeviceId>
            <Type>Type</Type>
            <AddDate>Add Date</AddDate>
            <Button isHead={true}>Activate</Button>
          </InActiveDevicesRowStyle>
          <OverFLowScrollBox>
            {devices && devices.length ? (
              devices.map((v, index) => (
                <InActiveDevicesRowStyle>
                  <DeviceId>{nullChecker(v.customDeviceId)}</DeviceId>
                  <Type>{nullChecker(v.type)}</Type>
                  <AddDate>
                    {nullChecker(displayDate(v.addedTimestamp * 1000))}
                  </AddDate>
                  <Button
                    onClick={(e) => {
                      activateDevice();
                    }}
                  >
                    Activate
                  </Button>
                </InActiveDevicesRowStyle>
              ))
            ) : (
              <PlaceholderMessage message="No data to display" />
            )}
          </OverFLowScrollBox>
        </DevicesRowContainer>
      ) : (
        <>
          <div className="row m-0 p-0">
            {devices && devices.length ? (
              devices.map((v, index) => {
                return (
                  <div
                    className="col-sm-12 col-xs-12 p-0 col-md-6 px-md-2"
                    key={v.deviceId}
                  >
                    <ZoneLogsUserRowMobileWrapper>
                      <ZoneLogsUserRowMobileView>
                        <div>
                          <FieldLabelMobile>Device ID</FieldLabelMobile>
                          <FieldValueMobile>
                            {nullChecker(v.customDeviceId)}
                          </FieldValueMobile>
                        </div>
                        <div>
                          <FieldLabelMobile>Type</FieldLabelMobile>
                          <FieldValueMobile>
                            {nullChecker(v.type)}
                          </FieldValueMobile>
                        </div>
                        <div>
                          <FieldLabelMobile>Add Date</FieldLabelMobile>
                          <FieldValueMobile>
                            {nullChecker(displayDate(v.addedTimestamp * 1000))}
                          </FieldValueMobile>
                        </div>
                      </ZoneLogsUserRowMobileView>
                      <div className="row my-2">
                        <div className="col-12">
                          <ButtonMobile
                            className="w-100"
                            onClick={(e) => {
                              activateDevice();
                            }}
                            // onClick={(e) => removeDevice(v.customDeviceId)}
                          >
                            Activate
                          </ButtonMobile>
                        </div>
                      </div>
                    </ZoneLogsUserRowMobileWrapper>
                  </div>
                );
              })
            ) : (
              <PlaceholderMessage message="No data to display" />
            )}
          </div>
        </>
      )}
    </>
  );
};

export default InActiveDevices;
