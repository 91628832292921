import React from "react";
import { useCookies } from "react-cookie";

import { AiOutlineCloseCircle } from "react-icons/ai";
import NotificationManager from "react-notifications/lib/NotificationManager";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { removeMembershipFromAccessGroup } from "../../../utils/requests";
import { convetToLocaleDateString } from "../../../utils/utilFunctions";
import PlaceholderMessage from "../../PlaceholderMessage";
import {
  AccessDate,
  Email,
  ID,
  MembershipViewContainer,
  MembershipViewRowStyled,
  Name,
  Phone,
  Button,
  CloseButtonContainer,
} from "./membershipView.styled";

const MembershipView = ({
  showMenu,
  memberships,
  accessGroupId,
  handleRemoveMembership,
}) => {
  const [cookies, setCookie, removeCookie] = useCookies(["AT"]);
  const user = useSelector((state) => state.user);
  const utils = useSelector((state) => state.utils);
  const selectedNode = useSelector((state) => state.selectedNode);
  const dispatch = useDispatch();
  const history = useHistory();
  return (
    <MembershipViewContainer>
      <MembershipViewRowStyled isHead={true}>
        <Name>Name</Name>
        <Email>Email</Email>
        <Phone>Phone</Phone>
        <ID>ID</ID>
      </MembershipViewRowStyled>
      {memberships ? (
        memberships.map((i) =>
          showMenu.includes(i.membershipId) ? (
            <MembershipViewRowStyled key={i.membershipId}>
              <Name>{i.userInfo.firstName + " " + i.userInfo.lastName}</Name>
              <Email>
                {i.userInfo.email.verified ? i.userInfo.email.id : "-"}
              </Email>
              <Phone>
                {i.userInfo.mobile.verified ? i.userInfo.mobile.number : "-"}
              </Phone>
              <ID>{i.userInfo.customUserId}</ID>
              {/* <AccessDate>{convetToLocaleDateString(i.startTime)}</AccessDate> */}
              <Button
                onClick={(e) => {
                  handleRemoveMembership(accessGroupId, i.membershipId);
                }}
              >
                Revoke
              </Button>
            </MembershipViewRowStyled>
          ) : null
        )
      ) : (
        <PlaceholderMessage message="No data to display" />
      )}
    </MembershipViewContainer>
  );
};

export default MembershipView;
