import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  NotificationBackground,
  NotificationContainer,
  NotificationBox,
  NotificationBoxHeader,
  NotificationTextContainer,
  NotificationLink,
} from "./featureNotification.styled";
import { MdClose } from "react-icons/md";
import { OverFLowScrollBox } from "../integrations/IntegrationEmployeeSyncView/IntegrationEmployeeSyncView.styled";
import { formNames, notificationsData } from "../../config";
import { useHistory, useLocation } from "react-router";
import { convetToLocaleDateString, getUserRole, toggleForm } from "../../utils/utilFunctions";
import { fetchNotifications, markNotificationAsReadAPI } from "../../utils/requests";
import NotificationManager from "react-notifications/lib/NotificationManager";
import { useCookies } from "react-cookie";
import { sendNotificationClickEvent } from "../../utils/tagManagerEvents";
const FeatureNotofication = ({ notificationPageNo, setNotificationPageNo }) => {
  const notifications = useSelector((state) => state.notifications);
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const user = useSelector((state) => state.user);
  const [cookies, setCookie, removeCookie] = useCookies(["AT"]);
  const nodeInfo = useSelector((state) => state.nodeInfo);
  const formState = useSelector((state) => state.formState);
  const dimentions = useSelector((state) => state.dimentions);
  const closeForm = () => {
    toggleForm(formState, history, location, dispatch, formNames.notificationBox);
  };

  const markAsRead = (notificationId, markAllasRead) => {
    markNotificationAsReadAPI(
      user,
      dispatch,
      NotificationManager,
      removeCookie,
      history,
      nodeInfo.nodeId,
      notificationId,
      markAllasRead,
      notificationPageNo
    );
  };

  return (
    <NotificationBackground dimentions={dimentions} onClick={closeForm}>
      <NotificationContainer dimentions={dimentions} onClick={(e) => e.stopPropagation()}>
        <NotificationBoxHeader dimentions={dimentions}>
          <h6 className="font-weight-bold m-0">Notification</h6>
          <MdClose
            style={{ fontSize: "1.5rem", cursor: "pointer" }}
            onClick={(e) => {
              closeForm();
            }}
          />
        </NotificationBoxHeader>
        <NotificationBoxHeader dimentions={dimentions}>
          <div className="w-100">
            <div className="text-right">
              <p
                className="m-0 p-0 font-weight-bold"
                style={{ cursor: "pointer" }}
                onClick={(e) => markAsRead(null, true)}
              >
                Mark all as read
              </p>
            </div>
          </div>
        </NotificationBoxHeader>

        <NotificationBox dimentions={dimentions}>
          <div className="row m-0 p-0">
            {notifications.entries.map((v) =>
              !v.notificationId ? (
                <div className="col-12 m-0 p-0">
                  <NotificationLink
                    href={v.link}
                    target="_blank"
                  >
                    <NotificationTextContainer
                      dimentions={dimentions}
                      style={{ backgroundColor: "rgba(112, 218, 255, 0.45)" }}
                    >
                      <div
                        className="col-10 m-0 p-0 text-left"
                        style={{
                          fontSize: "14px",
                          cursor: "pointer",
                          fontWeight: `${!v.readTimestamp ? "bold" : "normal"}`,
                        }}
                      >
                        {v.text}
                      </div>
                      <div className="col-2 font-weight-bold text-danger text-right m-0 p-0">
                        {convetToLocaleDateString(v.createdTimestamp)}
                      </div>
                    </NotificationTextContainer>
                  </NotificationLink>
                </div>
              ) : (
                <div className="col-12 m-0 p-0">
                  <NotificationTextContainer dimentions={dimentions}>
                    <div
                      className="col-10 m-0 p-0 text-left"
                      style={{
                        fontSize: "14px",
                        cursor: "pointer",
                        fontWeight: `${!v.readTimestamp ? "bold" : "normal"}`,
                      }}
                      onClick={(e) => {
                        e.stopPropagation();
                        markAsRead(v.notificationId, null);
                        sendNotificationClickEvent(
                          user.userId,
                          nodeInfo.name,
                          nodeInfo.nodeCategory,
                          getUserRole(user.roles, nodeInfo)
                        );
                        toggleForm(
                          formState,
                          history,
                          location,
                          dispatch,
                          formNames.membershipAccessInfoUpdateForm,
                          {
                            membershipId: v.membershipId,
                            nodeId: nodeInfo.rootNodeId || nodeInfo.nodeId,
                          }
                        );
                      }}
                    >
                      {v.text}
                    </div>
                    <div className="col-2 font-weight-bold text-danger text-right m-0 p-0">
                      {convetToLocaleDateString(v.createdTimestamp)}
                    </div>
                  </NotificationTextContainer>
                </div>
              )
            )}
            <div className="col-12 m-0 p-0">
              <div
                className="d-flex align-items-center justify-content-center"
                style={{ padding: "1rem 1rem" }}
              >
                {notifications.lastPage ? null : (
                  <a
                    className="m-0 p-0 font-weight-bold text-danger cursor-pointer"
                    onClick={(e) => setNotificationPageNo(notificationPageNo + 1)}
                  >
                    Load More
                  </a>
                )}
              </div>
            </div>
          </div>
        </NotificationBox>
      </NotificationContainer>
    </NotificationBackground>
  );
};

export default FeatureNotofication;
