import styled from "styled-components";

export const ZoneAccessCardContainer = styled.div`
  padding: 1rem 1rem;
  background-color: #ffffff;
  border: 0.5px solid #e8e8e8;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  border-radius: 10px;
  margin-bottom: 1rem;
`;

export const ZoneAccessCardTitle = styled.p`
  font-size: 1.2rem;
  font-weight: bold;
  font-style: normal;
  line-height: 1.5rem;
  color: #353535;
  margin-bottom: 1.5rem;
`;

export const ZoneAccessCardCount = styled.p`
  font-size: 2rem;
  font-weight: bold;
  font-style: normal;
  margin: 0px;
`;

export const ZoneAccessCardSubtitle = styled.p`
  font-size: 0.9rem;
  font-weight: lighter;
  font-style: normal;
  color: grey;
`;

export const ZoneAccessCardEditIconContainer = styled.div`
  text-align: right;
  padding: 1rem 0.5rem 0 0;
`;
