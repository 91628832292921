import styled, { css } from "styled-components";

const hoverColor = "#353535";
const hoverBgColor = "#49eecc";
const uploadButtonColor = "grey";
const deleteButtonColor = "tomato";
const imagePreviewTextColor = "#C6C6C6";
const fileNameColor = "black";
const imagePreviewBorderColor = "#EBEBE4";
const errorTextColor = "tomato";

const imagePreviewCss = css`
    width: 200px;
    height: 200px;
`;

const buttonCss = css`
    font-size: 1rem;
    padding: 0.5rem;
    border-radius: 1rem;
    width: 150px;
    margin-bottom: 20px;
    margin-left: 10px;
    margin-right: 10px;
`;

export const ImageContainer = styled.div`
    display: flex;
    justify-content: center;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;    
`;

export const ImagePreviewContainer = styled.div`
`;

export const ImageFileName = styled.p`
    color: ${fileNameColor};
    font-size: 1em;
`;

export const BlankImagePreviewContainer = styled.div`
    ${imagePreviewCss};
    display: flex;
    color: ${imagePreviewTextColor};
    border: 1px solid ${imagePreviewBorderColor};
    align-items: center;
    justify-content: center;
`;


export const ImagePreview = styled.img`
    ${imagePreviewCss};
`;

export const UploadImageContainer = styled.div`
    text-align: center;
`;

export const FileUploadLabel = styled.label`
    color: ${uploadButtonColor};
    ${buttonCss};
    border: 2px solid ${hoverBgColor};
    cursor: pointer;
    :hover {
        color: ${hoverColor};
        background-color : ${hoverBgColor};
        outline: none;
    }
`;

export const FileInput = styled.input`
    display: none;
`;

export const ErrorText = styled.p`
    color: ${errorTextColor};
`;

export const SaveButton = styled.button`
    :disabled{
        cursor:not-allowed;        
        pointer-events: all !important;
    }
    :not(:disabled){
        cursor:pointer;
        :hover {
            color: ${hoverColor};
            background-color : ${hoverBgColor};
            outline: none;
        }
    }
    border: 2px solid ${hoverBgColor};
    ${buttonCss};
    background-color: transparent;
    color: ${uploadButtonColor};
    outline: none;
`;

export const DeleteButton = styled.button`
    :disabled{
        cursor:not-allowed;        
        pointer-events: all !important;
    }
    :not(:disabled){
        cursor:pointer;
        :hover {
            color: white;
            background-color : ${deleteButtonColor};
            outline: none;
        }
    }
    border: 2px solid ${deleteButtonColor};
    ${buttonCss};
    background-color: transparent;
    color: ${deleteButtonColor};
    outline: none;
`;