import { UPDATE_API_STATUS } from "../actionTypes";

const apiStatusReducer = (apiStatus = {}, action) => {
  if (action.type === UPDATE_API_STATUS) {
    return (apiStatus = { ...apiStatus, [action.payload.url]: action.payload.loading });
  }
  return apiStatus;
};

export default apiStatusReducer;
