import React, { useState } from "react";
import {
  DevicesRowContainer,
  ActiveDevicesRowStyle,
  DeviceId,
  Type,
  AddDate,
  Status,
  Zone,
  Delete,
  Config,
  DoorAssigned,
  Button,
  VersionInfo,
  SyncButtonMobile,
  ConnectionStatus,
} from "./activeDevices.styled";
import { nullChecker, displayDate, toggleForm, displayTimeMinutes } from "../../../utils/utilFunctions";
import { Tooltip } from "react-hover-tooltip";
import { BsInfoCircle } from "react-icons/bs";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import cs
import NotificationManager from "react-notifications/lib/NotificationManager";
import { breakPointWidth, formNames } from "../../../config";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useCookies } from "react-cookie";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import PlaceholderMessage from "../../PlaceholderMessage";
import {
  ButtonMobile,
  FieldLabelMobile,
  FieldValueMobile,
  ZoneLogsUserRowMobileView,
  ZoneLogsUserRowMobileWrapper,
} from "../../zone/ZoneLogsUserRow/zoneLogsUserRow.styled";
import { OverFLowScrollBox } from "../../App.styled";
import { deviceSetTimeAPI, deviceUnlockAPI, getDevices, removeDeviceAPI } from "../../../utils/requests";
import { FaSync } from "react-icons/fa";
import { RiDeleteBin6Line } from "react-icons/ri";
const ActiveDevices = () => {
  const [devices, setDevices] = useState(null);
  const user = useSelector((state) => state.user);
  const selectedNode = useSelector((state) => state.selectedNode);
  const enableOAC = useSelector((state) => state.nodeInfo.enableOAC);
  const dimentions = useSelector((state) => state.dimentions);
  const formState = useSelector((state) => state.formState);
  const [cookies, setCookie, removeCookie] = useCookies(["AT"]);
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    if (selectedNode.nodeId) {
      getDevices(
        user,
        dispatch,
        NotificationManager,
        removeCookie,
        history,
        selectedNode.nodeId,
        setDevices
      );
    }
  }, [selectedNode]);

  return (
    <>
      {dimentions.width > breakPointWidth ? (
        <DevicesRowContainer>
          <ActiveDevicesRowStyle isHead={true} enableOAC={enableOAC}>
            <DeviceId>Device ID</DeviceId>
            <Type>Type</Type>
            <AddDate>Add Date</AddDate>
            <Status>Status</Status>
            <Zone>Zone</Zone>
            <DoorAssigned>Door Assigned</DoorAssigned>
            <Config>Config</Config>
            <ConnectionStatus>Connection Status</ConnectionStatus>
            {enableOAC ? <Button isHead={true}>OACSync</Button> : null}
            <Button isHead={true}>RFSync</Button>
            <Delete isHead={true}>Delete</Delete>
            <VersionInfo isHead={true}>Info</VersionInfo>
          </ActiveDevicesRowStyle>
          <OverFLowScrollBox>
            {devices && devices.length ? (
              devices.map((v, index) => (
                <ActiveDevicesRowStyle enableOAC={enableOAC}>
                  <DeviceId>{nullChecker(v.customDeviceId)}</DeviceId>
                  <Type>{nullChecker(v.type)}</Type>
                  <AddDate>{nullChecker(displayDate(v.addedTimestamp * 1000))}</AddDate>
                  <Status>
                    {/* {v.status.stdatus === "Offline" ? (
                    <>
                    <Status>{v.status.status}</Status>
                    <Status>Last Online</Status>
                    <Status>{v.status.lastOnline}</Status>
                    </>
                    ) : (
                      <p>{v.status.status}</p>
                    )} */}
                    -
                  </Status>
                  <Zone>{nullChecker(v.nodeName)}</Zone>
                  <DoorAssigned>{nullChecker(v.doorName)}</DoorAssigned>
                  <Config>
                    {v.modified
                      ? `Sync pending from ${displayTimeMinutes(v.modified * 1000)}`
                      : v.lastSyncTime
                      ? `Last synced at ${displayTimeMinutes(v.lastSyncTime * 1000)}`
                      : "Synced"}
                  </Config>
                  <ConnectionStatus>
                    {v.connected
                      ? "Connected"
                      : v.disconnectionTime
                      ? `Disconnected at ${displayTimeMinutes(v.disconnectionTime * 1000)}`
                      : "-"}
                  </ConnectionStatus>
                  <Button
                    disabled={
                      v.disconnectionTime && v.disconnectionTime > parseInt(Date.now() / 1000) - 10 * 60
                        ? false
                        : v.connected
                        ? false
                        : true
                    }
                    onClick={(e) =>
                      deviceUnlockAPI(
                        user,
                        dispatch,
                        NotificationManager,
                        removeCookie,
                        history,
                        v.nodeId,
                        v.deviceId,
                        "UNLOCK_ENTRY"
                      )
                    }
                  >
                    Entry
                  </Button>
                  <Button
                    disabled={
                      v.disconnectionTime && v.disconnectionTime > parseInt(Date.now() / 1000) - 10 * 60
                        ? false
                        : v.connected
                        ? false
                        : true
                    }
                    onClick={(e) =>
                      deviceUnlockAPI(
                        user,
                        dispatch,
                        NotificationManager,
                        removeCookie,
                        history,
                        v.nodeId,
                        v.deviceId,
                        "UNLOCK_EXIT"
                      )
                    }
                  >
                    Exit
                  </Button>
                  <Button
                    disabled={
                      v.disconnectionTime && v.disconnectionTime > parseInt(Date.now() / 1000) - 10 * 60
                        ? false
                        : v.connected
                        ? false
                        : true
                    }
                    onClick={(e) =>
                      deviceSetTimeAPI(
                        user,
                        dispatch,
                        NotificationManager,
                        removeCookie,
                        history,
                        v.nodeId,
                        v.deviceId
                      )
                    }
                  >
                    Set Time
                  </Button>
                  {enableOAC ? (
                    <Button
                      onClick={(e) => {
                        toggleForm(formState, history, location, dispatch, formNames.checkOACForm, {
                          deviceId: v.deviceId,
                          type: v.type,
                        });
                      }}
                    >
                      OACs
                    </Button>
                  ) : null}
                  <Button
                    onClick={(e) =>
                      toggleForm(
                        formState,
                        history,
                        location,
                        dispatch,
                        formNames.checkDeviceRFDataForm,
                        {
                          deviceId: v.deviceId,
                        }
                      )
                    }
                  >
                    RFCards
                  </Button>

                  <Delete>
                    <RiDeleteBin6Line
                      onClick={(e) =>
                        removeDeviceAPI(
                          user,
                          dispatch,
                          NotificationManager,
                          removeCookie,
                          history,
                          v.customDeviceId,
                          selectedNode,
                          confirmAlert,
                          setDevices
                        )
                      }
                    />
                  </Delete>
                  <VersionInfo>
                    <Tooltip content={<div>{v.DFI}</div>}>
                      <BsInfoCircle />
                    </Tooltip>
                  </VersionInfo>
                </ActiveDevicesRowStyle>
              ))
            ) : (
              <PlaceholderMessage message="No data to display" />
            )}
          </OverFLowScrollBox>
        </DevicesRowContainer>
      ) : (
        <>
          <div className="row m-0 p-0">
            {devices && devices.length ? (
              devices.map((v, index) => {
                return (
                  <div className="col-sm-12 col-xs-12 p-0 col-md-6 px-md-2" key={v.customDeviceId}>
                    <ZoneLogsUserRowMobileWrapper>
                      <ZoneLogsUserRowMobileView>
                        <div>
                          <FieldLabelMobile>Device ID</FieldLabelMobile>
                          <FieldValueMobile>{nullChecker(v.customDeviceId)}</FieldValueMobile>
                        </div>
                        <div>
                          <FieldLabelMobile>Type</FieldLabelMobile>
                          <FieldValueMobile>{nullChecker(v.type)}</FieldValueMobile>
                        </div>
                        <div>
                          <FieldLabelMobile>Add Date</FieldLabelMobile>
                          <FieldValueMobile>
                            {nullChecker(displayDate(v.addedTimestamp * 1000))}
                          </FieldValueMobile>
                        </div>
                        <div>
                          <FieldLabelMobile>
                            Status{" "}
                            <Tooltip content={<div>{v.DFI}</div>}>
                              <BsInfoCircle />
                            </Tooltip>
                          </FieldLabelMobile>
                          <FieldValueMobile>-</FieldValueMobile>
                        </div>
                        <div>
                          <FieldLabelMobile>Zone</FieldLabelMobile>
                          <FieldValueMobile>{nullChecker(v.nodeName)}</FieldValueMobile>
                        </div>
                        <div>
                          <FieldLabelMobile>Door Assigned</FieldLabelMobile>
                          <FieldValueMobile>{nullChecker(v.doorName)}</FieldValueMobile>
                        </div>
                      </ZoneLogsUserRowMobileView>
                      <div style={{ marginTop: "0.5rem" }}>
                        <FieldLabelMobile>Status</FieldLabelMobile>
                        <div>
                          <FieldValueMobile>
                            {v.modified
                              ? `Sync pending from ${displayTimeMinutes(v.modified * 1000)}`
                              : v.lastSyncTime
                              ? `Last synced at ${displayTimeMinutes(v.lastSyncTime * 1000)}`
                              : "Synced"}
                          </FieldValueMobile>
                        </div>
                      </div>
                      <div style={{ marginTop: "0.5rem" }}>
                        <FieldLabelMobile>Connection Status</FieldLabelMobile>
                        <div>
                          <FieldValueMobile>
                            {v.connected
                              ? "Connected"
                              : v.disconnectionTime
                              ? `Disconnected at ${displayTimeMinutes(v.disconnectionTime * 1000)}`
                              : "-"}
                          </FieldValueMobile>
                        </div>
                      </div>
                      <div className="row my-2 justify-content-center">
                        <div className="col-6">
                          <SyncButtonMobile
                            className="w-100"
                            disabled={
                              v.disconnectionTime &&
                              v.disconnectionTime > parseInt(Date.now() / 1000) - 10 * 60
                                ? false
                                : v.connected
                                ? false
                                : true
                            }
                            onClick={(e) =>
                              deviceUnlockAPI(
                                user,
                                dispatch,
                                NotificationManager,
                                removeCookie,
                                history,
                                v.nodeId,
                                v.deviceId,
                                "UNLOCK_ENTRY"
                              )
                            }
                          >
                            Entry
                          </SyncButtonMobile>
                        </div>
                        <div className="col-6">
                          <SyncButtonMobile
                            className="w-100"
                            disabled={
                              v.disconnectionTime &&
                              v.disconnectionTime > parseInt(Date.now() / 1000) - 10 * 60
                                ? false
                                : v.connected
                                ? false
                                : true
                            }
                            onClick={(e) =>
                              deviceUnlockAPI(
                                user,
                                dispatch,
                                NotificationManager,
                                removeCookie,
                                history,
                                v.nodeId,
                                v.deviceId,
                                "UNLOCK_EXIT"
                              )
                            }
                          >
                            Exit
                          </SyncButtonMobile>
                        </div>
                      </div>
                      <div className="row my-2 justify-content-center">
                        {enableOAC ? (
                          <div className="col-6">
                            <SyncButtonMobile
                              className="w-100"
                              onClick={(e) => {
                                toggleForm(
                                  formState,
                                  history,
                                  location,
                                  dispatch,
                                  formNames.checkOACForm,
                                  {
                                    deviceId: v.deviceId,
                                    type: v.type,
                                  }
                                );
                              }}
                            >
                              OACs
                            </SyncButtonMobile>
                          </div>
                        ) : null}
                        <div className="col-6">
                          <SyncButtonMobile
                            className="w-100"
                            onClick={(e) =>
                              toggleForm(
                                formState,
                                history,
                                location,
                                dispatch,
                                formNames.checkDeviceRFDataForm,
                                {
                                  deviceId: v.deviceId,
                                }
                              )
                            }
                          >
                            RFCards
                          </SyncButtonMobile>
                        </div>
                      </div>
                      <div className="row my-2">
                        <div className="col-12">
                          <ButtonMobile
                            className="w-100"
                            disabled={
                              v.disconnectionTime &&
                              v.disconnectionTime > parseInt(Date.now() / 1000) - 10 * 60
                                ? false
                                : v.connected
                                ? false
                                : true
                            }
                            onClick={(e) =>
                              deviceSetTimeAPI(
                                user,
                                dispatch,
                                NotificationManager,
                                removeCookie,
                                history,
                                v.nodeId,
                                v.deviceId
                              )
                            }
                          >
                            Set Time
                          </ButtonMobile>
                        </div>
                      </div>
                      <div className="row my-2">
                        <div className="col-12">
                          <ButtonMobile
                            className="w-100"
                            onClick={(e) =>
                              removeDeviceAPI(
                                user,
                                dispatch,
                                NotificationManager,
                                removeCookie,
                                history,
                                v.customDeviceId,
                                selectedNode,
                                confirmAlert
                              )
                            }
                          >
                            Remove
                          </ButtonMobile>
                        </div>
                      </div>
                    </ZoneLogsUserRowMobileWrapper>
                  </div>
                );
              })
            ) : (
              <PlaceholderMessage message="No data to display" />
            )}
          </div>
        </>
      )}
    </>
  );
};

export default ActiveDevices;
