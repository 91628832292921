import styled, { css } from "styled-components";

export const DevicesWithNamesRowContainer = styled.div`
  border: 0.5px solid #e8e8e8;
  box-shadow: 0px 0.2rem 0.2rem 0.2rem rgba(0, 0, 0, 0.06);
  border-radius: 5px;
  margin-bottom: 20px;
  height: 65vh;
  position: relative;
`;

export const DevicesWithNamesRowStyled = styled.div`
  display: grid;
  grid-row-gap: 0.5rem;
  grid-column-gap: 0.2rem;
  padding: 0.5rem 1rem;

  ${(props) =>
    css`
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
      grid-template-areas: "deviceName customDeviceId mac updateDFITo edit delete";
    `}

  ${(props) =>
    props.active
      ? css`
          background-image: linear-gradient(91deg, #fe6dad -3%, #fc9f35);
        `
      : css``}
  ${(props) =>
    props.isHead
      ? css`
          font-weight: bold;
        `
      : css`
          :hover {
            color: white;
            background-image: linear-gradient(91deg, #fe6dad -3%, #fc9f35);
          }
        `}
`;

export const Name = styled.div`
  grid-area: "deviceName";
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
`;

export const CustomDeviceId = styled.div`
  grid-area: "customDeviceId";
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
`;
export const MAC = styled.div`
  grid-area: "mac";
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
`;

export const DFI = styled.div`
  grid-area: "updateDFITo";
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
`;
export const FirmwareId = styled.div`
  grid-area: "deviceFirmwareId";
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
`;

export const Delete = styled.div`
  grid-area: "delete";
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`;
export const Edit = styled.div`
  grid-area: "edit";
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`;
export const Button = styled.button`
  background-color: transparent;
  border: 1px solid white;
  outline: none;
  color: white;
  justify-self: center;
  align-self: center;
  cursor: pointer;
  font-size: 0.8rem;
  padding: 0.2rem 1rem;
  border-radius: 20px;
  opacity: 0.5;
  color: black;
  border: 2px solid black;
`;
