import React from "react";

import {
  ZoneLogsUserRowStyle,
  Sn,
  NameAvatar,
  AccessType,
  Type,
  Time,
  Door,
  Temperature,
  Button,
  ZoneLogsUserRowContainer,
  ZoneLogsUserRowMobileView,
  FieldLabelMobile,
  FieldValueMobile,
  ButtonMobile,
  ZoneLogsUserRowMobileWrapper,
} from "./zoneLogsUserRow.styled";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { breakPointWidth, rootApi, typeCode } from "../../../config";
import { get } from "../../../utils/apiService";
import { useEffect } from "react";
import { nullChecker, formatTemp } from "../../../utils/utilFunctions";
import { useHistory } from "react-router-dom";
import { useCookies } from "react-cookie";
import { ATexpired } from "../../../utils/logout";
import PlaceholderMessage from "../../PlaceholderMessage";
import { OverFLowScrollBox } from "../../App.styled";
import { createNotification, notificationType } from "../../../vms/Services/utils";
import { PageButtonContainer, PageButton } from "../../../paging.styled";
import { FaAngleDoubleLeft, FaAngleDoubleRight, FaAngleLeft, FaAngleRight } from "react-icons/fa";
import NotificationManager from "react-notifications/lib/NotificationManager";
const ZoneLogsUserRow = ({ filterDate }) => {
  const [cookies, setCookie, removeCookie] = useCookies(["AT"]);
  let history = useHistory();
  const dispatch = useDispatch();

  const [zoneLogs, setZoneLogs] = useState(null);
  const [pageNo, setPageNo] = useState(1);
  const [noOfPages, setNoOfPages] = useState(1);
  const selectedNode = useSelector((state) => state.selectedNode);
  const user = useSelector((state) => state.user);
  const dimentions = useSelector((state) => state.dimentions);

  const getZoneLogsAPI = async (d) => {
    const url = `${rootApi}node/accessLogs?nodeId=${selectedNode.nodeId}&startTime=${parseInt(
      new Date(filterDate).setHours(0, 0, 0, 0)
    )}&endTime=${parseInt(new Date(filterDate).setHours(23, 59, 59, 99))}&pageNo=${pageNo}`;
    try {
      const response = await get(dispatch, url, user.AT);
      if (!response.data.success) {
        createNotification(NotificationManager, notificationType.error, response.data.error);
      } else {
        setZoneLogs([...response.data.accessLogs]);
        setNoOfPages(response.data.noOfPages);
      }
      ATexpired(response, dispatch, removeCookie, history);
    } catch (err) {
      throw err;
    }
  };
  useEffect(() => {
    getZoneLogsAPI(filterDate);
  }, [selectedNode.nodeId, filterDate, pageNo]);

  return (
    <>
      {dimentions.width > breakPointWidth ? (
        <ZoneLogsUserRowContainer dimentions={dimentions}>
          <ZoneLogsUserRowStyle isHead={true}>
            <Sn>UDID</Sn>
            <NameAvatar>Name</NameAvatar>
            <AccessType>Access Way</AccessType>
            <Type>Access Type</Type>
            <Time>Time</Time>
            <Door>Door</Door>
            <Temperature>Temperature</Temperature>
          </ZoneLogsUserRowStyle>
          <OverFLowScrollBox>
            {zoneLogs && zoneLogs.length ? (
              zoneLogs.map((i, index) => {
                return (
                  <ZoneLogsUserRowStyle key={i.nodeAccessLogId}>
                    <Sn>{nullChecker(i.UDId)}</Sn>
                    <NameAvatar>{nullChecker(i.firstName) + " " + nullChecker(i.lastName)}</NameAvatar>
                    <AccessType>{nullChecker(i.accessType)}</AccessType>
                    <Type>{nullChecker(typeCode[i.recordType])}</Type>
                    <Time>{new Date(parseInt(i.deviceTimestamp)).toTimeString().slice(0, 8)}</Time>
                    <Door>{nullChecker(i.doorName)}</Door>
                    <Temperature>{formatTemp(i.temperature, nullChecker)}</Temperature>
                    <Button>Revoke</Button>
                  </ZoneLogsUserRowStyle>
                );
              })
            ) : (
              <PlaceholderMessage message="No data to display" />
            )}
          </OverFLowScrollBox>
        </ZoneLogsUserRowContainer>
      ) : (
        <div className="row m-0 p-0">
          {zoneLogs && zoneLogs.length ? (
            zoneLogs.map((i) => (
              <div className="col-sm-12 col-xs-12 p-0 col-md-6 px-md-2" key={i.nodeAccessLogId}>
                <ZoneLogsUserRowMobileWrapper>
                  <ZoneLogsUserRowMobileView>
                    <div>
                      <FieldLabelMobile>UDID</FieldLabelMobile>
                      <FieldValueMobile>{nullChecker(i.UDId)}</FieldValueMobile>
                    </div>
                    <div>
                      <FieldLabelMobile>Name</FieldLabelMobile>
                      <FieldValueMobile>
                        {nullChecker(i.firstName) + " " + nullChecker(i.lastName)}
                      </FieldValueMobile>
                    </div>
                    <div>
                      <FieldLabelMobile>Access Way</FieldLabelMobile>
                      <FieldValueMobile>{nullChecker(i.accessType)}</FieldValueMobile>
                    </div>
                    <div>
                      <FieldLabelMobile>Access Type</FieldLabelMobile>
                      <FieldValueMobile>{nullChecker(typeCode[i.recordType])}</FieldValueMobile>
                    </div>
                    <div>
                      <FieldLabelMobile>Time</FieldLabelMobile>
                      <FieldValueMobile>
                        {new Date(parseInt(i.deviceTimestamp)).toTimeString().slice(0, 8)}
                      </FieldValueMobile>
                    </div>
                    <div>
                      <FieldLabelMobile>Door</FieldLabelMobile>
                      <FieldValueMobile>{nullChecker(i.doorName)}</FieldValueMobile>
                    </div>
                    <div>
                      <FieldLabelMobile>Temperature</FieldLabelMobile>
                      <FieldValueMobile>{formatTemp(i.temperature, nullChecker)}</FieldValueMobile>
                    </div>
                  </ZoneLogsUserRowMobileView>
                  <div className="text-center">
                    <ButtonMobile>Revoke</ButtonMobile>
                  </div>
                </ZoneLogsUserRowMobileWrapper>
              </div>
            ))
          ) : (
            <PlaceholderMessage message="No data to display" />
          )}
        </div>
      )}
      <div>
        <PageButtonContainer>
          {pageNo > 3 ? (
            <PageButton
              onClick={(e) => {
                if (pageNo - 5 > 0) {
                  setPageNo(pageNo - 5);
                } else {
                  setPageNo(1);
                }
              }}
            >
              <FaAngleLeft />
            </PageButton>
          ) : null}
          {pageNo - 2 > 0 ? (
            <PageButton
              onClick={(e) => {
                setPageNo(pageNo - 2);
              }}
            >
              {pageNo - 2}
            </PageButton>
          ) : null}
          {pageNo - 1 > 0 ? (
            <PageButton
              onClick={(e) => {
                setPageNo(pageNo - 1);
              }}
            >
              {pageNo - 1}
            </PageButton>
          ) : null}
          {noOfPages > 1 ? <PageButton selected={true}>{pageNo}</PageButton> : null}
          {noOfPages >= pageNo + 1 ? (
            <PageButton
              onClick={(e) => {
                setPageNo(pageNo + 1);
              }}
            >
              {pageNo + 1}
            </PageButton>
          ) : null}
          {noOfPages >= pageNo + 2 ? (
            <PageButton
              onClick={(e) => {
                setPageNo(pageNo + 2);
              }}
            >
              {pageNo + 2}
            </PageButton>
          ) : null}
          {pageNo + 2 < noOfPages ? (
            <PageButton
              onClick={(e) => {
                if (pageNo + 5 <= noOfPages) {
                  setPageNo(pageNo + 5);
                } else {
                  setPageNo(noOfPages);
                }
              }}
            >
              <FaAngleRight />
            </PageButton>
          ) : null}
        </PageButtonContainer>
      </div>
    </>
  );
};

export default ZoneLogsUserRow;
