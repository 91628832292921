import React, { useEffect, useState } from "react";
import { FormWrapper } from "../doorAddForm/DoorAddForm";
import DatePicker from "react-datepicker";
import { readString, CSVDownloader } from "react-papaparse";
import "react-datepicker/dist/react-datepicker.css";
import { FieldInput, FieldLabel, InputBox, InputGroup, SelectBox } from "../FormBase.styled";
import { SolidButton } from "../../buttons/solidButton.styled";
import { useDispatch, useSelector } from "react-redux";
import { apiEndPoints, formNames, UDIDAllowedNodeCategories } from "../../../config";
import { convertTime, toggleForm } from "../../../utils/utilFunctions";
import { useCookies } from "react-cookie";
import { useHistory, useLocation } from "react-router-dom";
import NotificationManager from "react-notifications/lib/NotificationManager";
import { addMemberAPI } from "../../../utils/requests";
import { createNotification } from "../../../vms/Services/utils";
import ButtonState from "../../ButtonState/ButtonState";
import BulkOperationTable from "../bulkOperationForm/BulkOperationTable";
import SubMenu from "../../SubMenu/SubMenu";
import MembershipRolesForm from "../membershipRolesForm/MembershipRolesForm";
const PeopleAddForm = ({ getPeopleAPI }) => {
  const [cookies, setCookie, removeCookie] = useCookies(["AT"]);
  let history = useHistory();
  const dispatch = useDispatch();
  const [expanded, setExpanded] = useState({});
  const [accessPermission, setAccessPermission] = useState({});
  const [checkedNodes, setCheckedNodes] = useState({});
  const nodes = useSelector((state) => state.selectedNodeHierarchy);
  const utils = useSelector((state) => state.utils);
  const nodeInfo = useSelector((state) => state.nodeInfo);
  const user = useSelector((state) => state.user);
  const selectedNode = useSelector((state) => state.selectedNode);
  const dimentions = useSelector((state) => state.dimentions);
  const formState = useSelector((state) => state.formState);
  const apiStatus = useSelector((state) => state.apiStatus);
  const location = useLocation();
  // const [startTimeError, setStartTimeError] = useState(null);
  const [showEndDateField, setShowEndDateField] = useState(false);
  const [bulkUpload, setBulkUpload] = useState(false);
  const [bulkUploadDepartments, setBulkUploadDepartments] = useState(false);
  const [userNameError, setUserNameError] = useState("");
  const [apiRes, setApiRes] = useState(null);
  const initiallySetExpanded = (nodes, acc) => {
    let temp = acc ? acc : {};

    if (nodes.length !== undefined) {
      nodes.forEach((obj) => (temp[obj.nodeId] = false));
    }
    if (nodes.length !== undefined) {
      nodes.forEach((obj) => obj.children.forEach((nObj) => initiallySetExpanded(nObj, temp)));
    }
    return temp;
  };

  const [formData, setFormData] = useState({
    nodeId: selectedNode.nodeId,
    userName: "",
    UDId: "",
    roles: [],
    accessInfo: [],
    // startTime: new Date(),
    endTime: null, //convertTime(new Date().toString(), "end"),
  });

  useEffect(() => {
    if (!formData.nodeId || !formData.nodeId.length) {
      setFormData({ ...formData, nodeId: selectedNode.nodeId });
    }
  }, [formData]);

  const populateData = (data) => {
    const fieldIndexData = {};
    const refs = {
      Name: "name",
      "Email or Mobile No (only one type)": "emailIDORmobileNo",
      UserId: "UDId",
      Designation: "designation",
      "Start Date(MM/DD/YYYY)": "startTime",
      "End Date(MM/DD/YYYY)": "endTime",
      Department: "depId",
      SubDepartment: "subDepId",
      "Blood Group": "bloodGroup",
      "Emergency Contact No": "emergencyContactNo",
    };
    data[0].forEach((e, i) => {
      fieldIndexData[refs[e]] = i;
    });
    let bulkNewData = [];
    setBulkUpload(true);
    let userId = [],
      names = [],
      contacts = [],
      designations = [],
      startDates = [],
      endDates = [],
      departments = [],
      subDepartments = [],
      depNames = [],
      subDepNames = [],
      bloodGroups = [],
      emergencyContactNos = [];

    let hasDepartments = false,
      hasSubDepartments = false;
    if (data.some((x) => x[fieldIndexData.depId])) {
      hasDepartments = true;
      if (data.some((x) => x[fieldIndexData.subDepId])) {
        hasSubDepartments = true;
      }
    }

    for (let i = 1; i < data.length; i++) {
      if (data[i][0].length) {
        console.log(data[i]);
        userId.push(data[i][2]);
        names.push(data[i][0]);
        contacts.push(data[i][1]);
        designations.push(data[i][3]);
        bloodGroups.push(data[i][8]);
        emergencyContactNos.push(data[i][9]);
        if (data[i][4].length) {
          console.log(data[i][4]);
          startDates.push(convertTime(new Date(data[i][4]).toString(), "start"));
        } else {
          console.log(data[i][4]);
          startDates.push("");
        }
        if (data[i][5].length) {
          console.log(data[i][4]);
          endDates.push(convertTime(new Date(data[i][5]).toString(), "end"));
        } else {
          console.log(data[i][4]);
          endDates.push("");
        }
        if (hasDepartments) {
          if (data[i][6].length) {
            let x = nodeInfo.departments.find((elem) => elem.name === data[i][6]);
            if (x) {
              departments.push(x.depId);
              depNames.push(x.name);
            } else {
              createNotification(
                NotificationManager,
                "error",
                `${data[i][6]} department specified in the csv doesn't match with the departments of the node`
              );
              return;
            }
            if (hasSubDepartments) {
              if (data[i][7].length) {
                let y = x.subDepartments.find((elem) => elem.name === data[i][7]);
                if (y) {
                  subDepartments.push(y.subDepId);
                  subDepNames.push(y.name);
                } else {
                  createNotification(
                    NotificationManager,
                    "error",
                    `${data[i][7]} subdepartment specified in the csv doesn't match with the subDepartments of ${data[i][6]}`
                  );
                  return;
                }
              } else {
                subDepartments.push("");
                subDepNames.push("");
              }
            }
          } else {
            departments.push("");
            depNames.push("");
            subDepartments.push("");
            subDepNames.push("");
          }
        }
      }
    }
    if (departments.length !== 0) {
      setBulkUploadDepartments(true);
    }
    document.getElementById("names").value = names.join();
    document.getElementById("designation").value = designations.join();
    document.getElementById("email").value = contacts.join();
    document.getElementById("bloodGroup").value = bloodGroups.join();
    document.getElementById("emergencyContactNo").value = emergencyContactNos.join();

    let emailIds, mobileNos;
    let UDId = userId;
    let userName = names;
    let designation = designations;
    bloodGroups = bloodGroups;
    emergencyContactNos = emergencyContactNos;
    document.getElementById("addDetailsControl").setAttribute("aria-expanded", "true");
    document.getElementById("additionalDetails").classList.add("show");
    document.getElementById("BulkImportPeople").value = "";
    document.getElementById("startDates").value = startDates.join();
    document.getElementById("endDates").value = endDates.join();
    if (hasDepartments) {
      document.getElementById("departments").value = depNames.join();
      document.getElementById("subDepartments").value = subDepNames.join();
    }
    let startTimes = startDates.map((v) =>
      v === "" ? parseInt(new Date() / 1000) : parseInt(Date.parse(`${v}`) / 1000)
    );
    let endTimes = endDates.map((v) => (v === "" ? "" : parseInt(Date.parse(`${v}`) / 1000)));
    let temp = { ...formData };
    if (hasDepartments) {
      temp["depId"] = departments;
      if (hasSubDepartments) {
        temp["subDepId"] = subDepartments;
      }
    }
    delete temp.startTime;
    delete temp.endTime;
    delete temp.bloodGroup;
    delete temp.emergencyContactNo;
    if (contacts[0].includes("@")) {
      emailIds = contacts;
      setFormData({
        ...temp,
        UDId,
        userName,
        designation,
        emailIds,
        startTimes,
        endTimes,
        bloodGroups,
        emergencyContactNos,
      });
    } else {
      mobileNos = contacts;
      setFormData({
        ...temp,
        UDId,
        userName,
        designation,
        mobileNos,
        startTimes,
        endTimes,
        bloodGroups,
        emergencyContactNos,
      });
    }
    return true;
  };

  useEffect(() => {
    if (showEndDateField) {
      setFormData({ ...formData, endTime: convertTime(new Date().toString(), "end") });
    } else {
      setFormData({ ...formData, endTime: null });
    }
  }, [showEndDateField]);
  useEffect(() => {
    if (nodes) {
      setExpanded(initiallySetExpanded(nodes));
    }
  }, [nodes]);

  useEffect(() => {
    if (!formData.userName || !formData.userName.length) {
      setUserNameError("Name is required");
    } else {
      setUserNameError("");
    }
  }, [formData]);

  const closeForm = () => {
    toggleForm(formState, history, location, dispatch, formNames.peopleAddForm);
  };
  // const updateStartTime = (date) => {
  //   if (
  //     new Date(Date.parse(`${date}`)).getFullYear() > new Date(Date.now()).getFullYear() ||
  //     new Date(Date.now()).getMonth() < new Date(Date.parse(`${date}`)).getMonth() ||
  //     new Date(Date.now()).getDate() < new Date(Date.parse(`${date}`)).getDate()
  //   ) {
  //     console.log("date is greater than today");
  //     setFormData({
  //       ...formData,
  //       startTime: convertTime(date.toString(), "start"),
  //     });
  //   } else {
  //     setFormData({
  //       ...formData,
  //       startTime: new Date(),
  //     });
  //   }
  // };

  const handleAddMember = async (
    user,
    dispatch,
    NotificationManager,
    removeCookie,
    history,
    checkedNodes,
    formData,
    selectedNode,
    closeForm,
    utils,
    bulkUpload
  ) => {
    const res = await addMemberAPI(
      user,
      dispatch,
      NotificationManager,
      removeCookie,
      history,
      checkedNodes,
      formData,
      selectedNode,
      closeForm,
      utils,
      bulkUpload
    );
    if (res.error) {
      setFormData({ ...formData, userName: "" });
    }
    setApiRes({ ...res });
  };

  return (
    <FormWrapper dimentions={dimentions} closeForm={closeForm}>
      <h6 className="font-weight-bold mb-4">Add People</h6>

      <InputGroup dimentions={dimentions}>
        <FieldLabel dimentions={dimentions}>Zone</FieldLabel>
        <FieldInput>
          <InputBox
            disabled={true}
            value={nodeInfo.name}
            style={{ background: "#C4C4C4", opacity: "0.7" }}
          />
        </FieldInput>
      </InputGroup>
      <InputGroup dimentions={dimentions}>
        <FieldLabel />
        <div style={{ display: "flex" }}>
          <button
            className="solid-button"
            style={{ display: "inline-block", margin: "0rem 0.5rem" }}
            onClick={(e) => document.getElementById("BulkImportPeople").click()}
          >
            Bulk Import CSV
          </button>
          <input
            type="file"
            id="BulkImportPeople"
            name="BulkImportPeople"
            onChange={(e) => {
              let reader = new FileReader();
              reader.readAsText(e.target.files[0]);
              reader.onload = (ex) => {
                console.log(ex.target.result);
                var data = readString(ex.target.result, {
                  header: false,
                }).data;
                populateData(data);
              };

              // console.log(e.target.files[0])
            }}
            accept=".csv"
            style={{ display: "none" }}
          />
          <CSVDownloader
            className="solid-button"
            style={{ display: "inline-block", margin: "0rem 0.5rem" }}
            data={[
              {
                Name: "FirstName1 LastName1",
                "Email or Mobile No (only one type)": "9898989898",
                UserId: "EMP0001",
                Designation: "Designation1",
                "Start Date(MM/DD/YYYY)": "01/31/2020",
                "End Date(MM/DD/YYYY)": "01/31/2020",
                Department: "Sales",
                SubDepartment: "Audit Sales",
                "Blood Group": "A+",
                "Emergency Contact No": "9090909090",
              },
              {
                Name: "FirstName2 LastName2",
                "Email or Mobile No (only one type)": "8989898989",
                UserId: "EMP0002",
                Designation: "Designation2",
                "Start Date(MM/DD/YYYY)": "01/31/2020",
                "End Date(MM/DD/YYYY)": "01/31/2020",
                Department: "Marketing",
                SubDepartment: "Audit",
                "Blood Group": "O+",
                "Emergency Contact No": "9090909191",
              },
            ]}
            type="button"
            filename="Add People Sample"
            bom={true}
          >
            Download Sample CSV
          </CSVDownloader>
        </div>
      </InputGroup>
      <InputGroup dimentions={dimentions}>
        <FieldLabel dimentions={dimentions}>Email or Mobile</FieldLabel>
        <FieldInput>
          <InputBox
            // placeholder="Enter mobile(s) or email(s) seprated by (,)"
            id="email"
            onChange={(e) => {
              let temp = { ...formData };
              delete temp.mobileNo;
              delete temp.emailId;
              delete temp.emailIds;
              delete temp.mobileNos;
              if (e.target.value.includes(",")) {
                let email = [];
                let mobiles = [];
                let values = e.target.value.split(",");
                for (let i = 0; i < values.length; i++) {
                  if (values[i].includes("@")) {
                    email.push(values[i].trim());
                  } else {
                    mobiles.push(values[i].trim());
                  }
                }
                if (email.length) {
                  temp = { ...temp, emailIds: JSON.stringify(email) };
                }
                if (mobiles.length) {
                  temp = { ...temp, mobileNos: JSON.stringify(mobiles) };
                }
              } else {
                if (e.target.value.includes("@")) {
                  temp = { ...temp, emailId: e.target.value.trim() };
                } else {
                  temp = { ...temp, mobileNo: e.target.value.trim() };
                }
              }
              setFormData({ ...temp });
            }}
          />
        </FieldInput>
      </InputGroup>
      <InputGroup dimentions={dimentions}>
        <FieldLabel dimentions={dimentions}>Name</FieldLabel>
        <FieldInput>
          <InputBox
            id="names"
            // placeholder="Enter name(s) seprated by (,)"
            value={
              formData.userName
                ? formData.userName.includes(",")
                  ? JSON.parse(formData.userName).join(" ")
                  : formData.userName
                : ""
            }
            onChange={(e) => {
              let temp = { ...formData };
              delete temp.userName;
              let userName;
              if (e.target.value.includes(",")) {
                userName = JSON.stringify(e.target.value.split(",").map((v) => v.trim()));
              } else {
                userName = e.target.value;
              }
              setFormData({ ...formData, userName });
            }}
          />
          <p className="text-danger">{userNameError}</p>
        </FieldInput>
      </InputGroup>

      {/* setShowEndDateField */}

      <MembershipRolesForm formData={formData} setFormData={setFormData} />

      <div className="container-fluid">
        <InputGroup dimentions={dimentions} noMargin={true}>
          <FieldLabel dimentions={dimentions}></FieldLabel>
          <FieldInput>
            <p
              data-toggle="collapse"
              data-target="#additionalDetails"
              aria-expanded="false"
              aria-controls="additionalDetails"
              className="font-weight-bold text-info my-3 cursor-pointer"
              id="addDetailsControl"
            >
              Additional Details +
            </p>
          </FieldInput>
        </InputGroup>

        <div className="collapse my-3" id="additionalDetails">
          <div className="card card-body">
            {UDIDAllowedNodeCategories.includes(nodeInfo.nodeCategory) ? (
              <InputGroup dimentions={dimentions}>
                <FieldLabel dimentions={dimentions}>User Id</FieldLabel>
                <FieldInput>
                  <InputBox
                    // placeholder="Enter Member Id(s) seprated by (,) (optional)"
                    value={
                      formData.UDId.includes(",") ? JSON.parse(formData.UDId).join() : formData.UDId
                    }
                    onChange={(e) => {
                      let temp = { ...formData };
                      delete temp.UDId;
                      let UDId;
                      if (e.target.value.includes(",")) {
                        UDId = JSON.stringify(e.target.value.split(",").map((v) => v.trim()));
                      } else {
                        UDId = e.target.value.trim();
                      }
                      setFormData({ ...formData, UDId });
                    }}
                  />
                </FieldInput>
              </InputGroup>
            ) : null}
            {bulkUpload ? (
              <>
                <InputGroup dimentions={dimentions}>
                  <FieldLabel dimentions={dimentions}>Start Dates</FieldLabel>
                  <FieldInput>
                    <InputBox id="startDates" disabled="true" />
                  </FieldInput>
                </InputGroup>
                <InputGroup dimentions={dimentions}>
                  <FieldLabel dimentions={dimentions}>End Dates</FieldLabel>
                  <FieldInput>
                    <InputBox id="endDates" disabled="true" />
                  </FieldInput>
                </InputGroup>
              </>
            ) : null}
            {/* {!bulkUpload ? (
              <InputGroup dimentions={dimentions}>
                <FieldLabel dimentions={dimentions}>Start Date</FieldLabel>
                <FieldInput>
                  <DatePicker
                    dateFormat="dd/MM/yyyy"
                    selected={formData.startTime}
                    onChange={(date) => updateStartTime(date)}
                  />
                </FieldInput>
              </InputGroup>
            ) : null} */}

            {showEndDateField && !bulkUpload ? (
              <InputGroup dimentions={dimentions}>
                <FieldLabel dimentions={dimentions}>End Date</FieldLabel>
                <FieldInput>
                  <DatePicker
                    dateFormat="dd/MM/yyyy"
                    selected={formData.endTime}
                    minDate={new Date()}
                    onChange={(date) =>
                      setFormData({
                        ...formData,
                        endTime: convertTime(date.toString(), "end"),
                      })
                    }
                  />
                </FieldInput>
              </InputGroup>
            ) : null}
            {!bulkUpload ? (
              <InputGroup dimentions={dimentions}>
                <FieldLabel dimentions={dimentions}></FieldLabel>
                <FieldInput>
                  <label className="d-flex align-items-center">
                    <input
                      type="checkbox"
                      className="mr-2"
                      checked={showEndDateField}
                      onChange={(e) => setShowEndDateField(e.target.checked)}
                    />
                    Set membership end date
                    {/* <span className="cursor-pointer" style={{ color: "blue" }}></span> */}
                  </label>
                </FieldInput>
              </InputGroup>
            ) : null}
            {!bulkUploadDepartments ? (
              <InputGroup dimentions={dimentions}>
                <FieldLabel dimentions={dimentions}>Department</FieldLabel>
                <FieldInput>
                  <SelectBox onChange={(e) => setFormData({ ...formData, depId: e.target.value })}>
                    <option defaultValue value={""}>
                      select
                    </option>
                    {nodeInfo?.departments?.map((x) => (
                      <option value={x.depId}>{x.name}</option>
                    ))}
                  </SelectBox>
                </FieldInput>
              </InputGroup>
            ) : (
              <InputGroup dimentions={dimentions}>
                <FieldLabel dimentions={dimentions}>Department</FieldLabel>
                <FieldInput>
                  <InputBox id="departments" disabled="true" />
                </FieldInput>
              </InputGroup>
            )}
            {!bulkUploadDepartments && formData?.depId?.length ? (
              <InputGroup dimentions={dimentions}>
                <FieldLabel dimentions={dimentions}>Sub Department</FieldLabel>
                <FieldInput>
                  <SelectBox onChange={(e) => setFormData({ ...formData, subDepId: e.target.value })}>
                    <option defaultValue value={""}>
                      select
                    </option>
                    {nodeInfo.departments
                      .filter((v) => v.depId === formData.depId)[0]
                      .subDepartments.map((x) => (
                        <option value={`${x.subDepId}`}>{x.name}</option>
                      ))}
                  </SelectBox>
                </FieldInput>
              </InputGroup>
            ) : null}
            {bulkUploadDepartments ? (
              <InputGroup dimentions={dimentions}>
                <FieldLabel dimentions={dimentions}>SubDepartment</FieldLabel>
                <FieldInput>
                  <InputBox id="subDepartments" disabled="true" />
                </FieldInput>
              </InputGroup>
            ) : null}
            <InputGroup dimentions={dimentions}>
              <FieldLabel dimentions={dimentions}>Designation</FieldLabel>
              <FieldInput>
                <InputBox
                  id="designation"
                  onChange={(e) => {
                    let temp = { ...formData };
                    delete temp.designation;
                    let designation;
                    if (e.target.value.includes(",")) {
                      designation = JSON.stringify(e.target.value.split(",").map((v) => v.trim()));
                    } else {
                      designation = e.target.value.trim();
                    }
                    setFormData({ ...formData, designation });
                  }}
                />
              </FieldInput>
            </InputGroup>
            <InputGroup dimentions={dimentions}>
              <FieldLabel dimentions={dimentions}>Blood Group</FieldLabel>
              <FieldInput>
                <InputBox
                  id="bloodGroup"
                  onChange={(e) => {
                    let temp = { ...formData };
                    delete temp.bloodGroup;
                    let bloodGroup;
                    if (e.target.value.includes(",")) {
                      bloodGroup = JSON.stringify(e.target.value.split(",").map((v) => v.trim()));
                    } else {
                      bloodGroup = e.target.value.trim();
                    }
                    setFormData({ ...formData, bloodGroup });
                  }}
                />
              </FieldInput>
            </InputGroup>
            <InputGroup dimentions={dimentions}>
              <FieldLabel dimentions={dimentions}>Emergency Contact No</FieldLabel>
              <FieldInput>
                <InputBox
                  id="emergencyContactNo"
                  onChange={(e) => {
                    let temp = { ...formData };
                    delete temp.emergencyContactNo;
                    let emergencyContactNo;
                    if (e.target.value.includes(",")) {
                      emergencyContactNo = JSON.stringify(
                        e.target.value.split(",").map((v) => v.trim())
                      );
                    } else {
                      emergencyContactNo = e.target.value.trim();
                    }
                    setFormData({ ...formData, emergencyContactNo });
                  }}
                />
              </FieldInput>
            </InputGroup>
          </div>
        </div>
      </div>
      {/* {!formData.accessGroups.length ? ( */}
      <InputGroup dimentions={dimentions}>
        <FieldLabel dimentions={dimentions}>Access Permissions</FieldLabel>
        <FieldInput border={true}>
          {nodes && nodes.length
            ? nodes.map((node) => (
                <SubMenu
                  key={node.nodeId}
                  checkedNodes={checkedNodes}
                  setCheckedNodes={setCheckedNodes}
                  name={node.name}
                  nodeId={node.nodeId}
                  hierarchy={node.children}
                  expanded={expanded}
                  setExpanded={setExpanded}
                  accessPermission={accessPermission}
                  setAccessPermission={setAccessPermission}
                  // nodesAccessibleFromGroups={nodesAccessibleFromGroups}
                />
              ))
            : null}
        </FieldInput>
      </InputGroup>
      {apiRes && apiRes.users && apiRes.users.length ? (
        <InputGroup dimentions={dimentions}>
          <FieldLabel dimentions={dimentions} className="text-danger">
            Entries with error
          </FieldLabel>
          <FieldInput border={true}>
            <BulkOperationTable data={apiRes.users} />
            <small className="text-danger font-weight-bold">{apiRes.error}</small>
          </FieldInput>
        </InputGroup>
      ) : null}
      <InputGroup dimentions={dimentions}>
        <FieldLabel dimentions={dimentions}></FieldLabel>
        <FieldInput submitButton={true}>
          <SolidButton
            disabled={userNameError.length || !formData.userName || !formData.userName.length}
            submit={true}
            onClick={(e) =>
              handleAddMember(
                user,
                dispatch,
                NotificationManager,
                removeCookie,
                history,
                checkedNodes,
                formData,
                selectedNode,
                closeForm,
                utils,
                bulkUpload
              )
            }
          >
            <ButtonState loading={apiStatus[apiEndPoints.addMemberAPI]} innerText={"Add"} />
          </SolidButton>
        </FieldInput>
      </InputGroup>
    </FormWrapper>
  );
};

export default PeopleAddForm;
