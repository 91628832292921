import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import NotificationManager from "react-notifications/lib/NotificationManager";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { apiEndPoints, formNames } from "../../../config";
import { addDeviceFirmwareAPI, updateDeviceFirmwareAPI } from "../../../utils/requests";
import { toggleForm } from "../../../utils/utilFunctions";
import { SolidButton } from "../../buttons/solidButton.styled";
import ButtonState from "../../ButtonState/ButtonState";
import { FormWrapper } from "../doorAddForm/DoorAddForm";
import { FieldInput, FieldLabel, InputBox, InputGroup, SelectBox } from "../FormBase.styled";
const DeviceFirmwareByNameForm = ({ edit }) => {
  const dimentions = useSelector((state) => state.dimentions);
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [cookies, setCookie, removeCookie] = useCookies(["AT"]);
  const initialData = useSelector((state) => state.formState[formNames.editDeviceFirmwareByNameForm]);
  const history = useHistory();
  const formState = useSelector((state) => state.formState);
  const apiStatus = useSelector((state) => state.apiStatus);
  const location = useLocation();
  const [formData, setFormData] = useState({
    deviceName: "",
    updateDFITo: "",
  });
  //  deviceName / customDeviceId / MAC;

  useEffect(() => {
    if (edit && initialData) {
      let { customDeviceId, updateDFITo, deviceFirmwareId } = initialData;
      setFormData({ customDeviceId, updateDFITo, deviceFirmwareId });
    }
  }, [edit, initialData]);

  const closeForm = () => {
    {
      edit
        ? toggleForm(formState, history, location, dispatch, formNames.editDeviceFirmwareByNameForm)
        : toggleForm(formState, history, location, dispatch, formNames.addDeviceFirmwareByNameForm);
    }
  };

  const handleSubmit = async () => {
    if (edit) {
      await updateDeviceFirmwareAPI(
        user,
        dispatch,
        NotificationManager,
        removeCookie,
        history,
        formData,
        closeForm
      );
    } else {
      await addDeviceFirmwareAPI(
        user,
        dispatch,
        NotificationManager,
        removeCookie,
        history,
        formData,
        closeForm
      );
    }
  };

  return (
    <FormWrapper dimentions={dimentions} closeForm={closeForm}>
      <h6 className="font-weight-bold mb-4">Device Firmware {edit ? "Update" : "Add"}</h6>
      {edit ? null : (
        <InputGroup dimentions={dimentions}>
          <FieldLabel dimentions={dimentions}>Form Type</FieldLabel>
          <FieldInput>
            <SelectBox
              onChange={(e) => {
                setFormData({ updateDFITo: "", [e.target.value]: "" });
              }}
            >
              <option value={"deviceName"}>Add by device name</option>
              <option value={"customDeviceId"}>Add by Custom Device ID</option>
              <option value={"MAC"}>Add by device MAC</option>
            </SelectBox>
          </FieldInput>
        </InputGroup>
      )}

      <InputGroup dimentions={dimentions}>
        <FieldLabel dimentions={dimentions}>
          {console.log({
            deviceName: fieldExist(formData, "deviceName"),
            MAC: fieldExist(formData, "MAC"),
            customDeviceId: fieldExist(formData, "customDeviceId"),
          })}
          {fieldExist(formData, "deviceName")
            ? "Device Name"
            : fieldExist(formData, "customDeviceId")
            ? "Custom Device Id"
            : fieldExist(formData, "MAC")
            ? "MAC Address"
            : ""}
        </FieldLabel>
        <FieldInput>
          {fieldExist(formData, "deviceName") ? (
            <InputBox
              type="text"
              value={formData.deviceName}
              onChange={(e) => setFormData({ ...formData, deviceName: e.target.value })}
            />
          ) : fieldExist(formData, "customDeviceId") ? (
            <InputBox
              type="text"
              value={formData.customDeviceId}
              onChange={(e) => setFormData({ ...formData, customDeviceId: e.target.value })}
            />
          ) : fieldExist(formData, "MAC") ? (
            <InputBox
              type="text"
              value={formData.MAC}
              onChange={(e) => setFormData({ ...formData, MAC: e.target.value })}
            />
          ) : null}
        </FieldInput>
      </InputGroup>
      <InputGroup dimentions={dimentions}>
        <FieldLabel dimentions={dimentions}>Update DFI To</FieldLabel>
        <FieldInput>
          <InputBox
            type="text"
            value={formData.updateDFITo}
            onChange={(e) => setFormData({ ...formData, updateDFITo: e.target.value })}
          />
        </FieldInput>
      </InputGroup>
      <InputGroup dimentions={dimentions}>
        <FieldLabel dimentions={dimentions}></FieldLabel>
        <FieldInput submitButton={true}>
          <SolidButton submit={true} onClick={handleSubmit}>
            <ButtonState
              loading={
                apiStatus[apiEndPoints.updateDeviceFirmwareAPI] ||
                apiStatus[apiEndPoints.addDeviceFirmwareAPI]
              }
              innerText={edit ? "Update" : "Add"}
            />
          </SolidButton>
        </FieldInput>
      </InputGroup>
    </FormWrapper>
  );
};

export default DeviceFirmwareByNameForm;

const fieldExist = (obj, key) => {
  return typeof obj[key] !== "undefined" ? true : false;
};
