import styled, { css } from "styled-components";
import { breakPointWidth } from "../../../config";

export const InputGroup = styled.div`
  ${(props) =>
    props?.dimentions?.width > breakPointWidth
      ? css`
          display: grid;
          grid-template-areas: "label field";
          grid-column-gap: 3rem;
          margin-bottom: ${(props) => (props.noMargin ? "0.5rem" : "1rem")};
        `
      : css``}
    grid-template-columns: 0.2fr 0.7fr;    
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem 1rem;
  background-color: #ffffff;
  border: 0.5px solid #e8e8e8;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  border-radius: 10px;
  margin-bottom: 1rem;
`;