import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { SideNav, SideNavItem, SideNavItemLogo, SideNavItemTitle } from "./Sidenav.styled";
import { GrFormClose } from "react-icons/gr";
import { Link, useHistory, useLocation } from "react-router-dom";
import { BsPieChartFill } from "react-icons/bs";
import { FaDoorOpen } from "react-icons/fa";
import { HiUserGroup } from "react-icons/hi";
import { GoDeviceMobile } from "react-icons/go";
import { FcDataConfiguration, FcInfo } from "react-icons/fc";
import { VscChecklist } from "react-icons/vsc";
import { AiTwotoneSetting } from "react-icons/ai";
import { BiBarChartSquare, BiCalendarEvent } from "react-icons/bi";
import { RiLogoutBoxRLine, RiUserSharedFill } from "react-icons/ri";
import { logout } from "../../utils/logout";
import {
  advancedFeatures,
  basicFeatures,
  basicFeatures2,
  breakPointWidth,
  cloudFrontOrigin,
  formNames,
  OPS_LIST,
} from "../../config";
import { isAdmin, sortPlanObject, toggleForm } from "../../utils/utilFunctions";
import DisabledOverlay, { DisabledLockIcon } from "../DisabledOverlay";
import { ZoneAccessCardSubtitle } from "../zone/zoneCards/zoneAccessCard/zoneAccessCard.styled";

const Sidenav = ({ removeCookie, history, dispatch, params, toggleSidebar }) => {
  const location = useLocation();
  const user = useSelector((state) => state.user);
  const nodeInfo = useSelector((state) => state.nodeInfo);
  const dimentions = useSelector((state) => state.dimentions);
  const selectedNode = useSelector((state) => state.selectedNode);
  const formState = useSelector((state) => state.formState);
  const [links, setlinks] = useState([
    {
      title: "Access",
      secure: true,
      active: true,
      path: "/access",
      imgSrc: <BsPieChartFill />,
    },
    {
      title: "Visitor",
      secure: true,
      active: false,
      path: "/visitor",
      imgSrc: <RiUserSharedFill />,
    },
    {
      title: "People",
      secure: true,
      active: false,
      path: "/people",
      imgSrc: <HiUserGroup />,
    },
    {
      title: "Devices",
      secure: true,
      active: false,
      path: "/devices",
      imgSrc: <GoDeviceMobile />,
    },
    {
      title: "Attendance",
      active: false,
      path: "/attendance",
      imgSrc: <VscChecklist />,
    },
    // {
    //   title: "Accounts",
    //   active: false,
    //   path: "/accounts",
    //   imgSrc: <AiTwotoneSetting />,
    // },
    // {
    //   title: "Reports",
    //   active: false,
    //   path: "/reports",
    //   imgSrc: <BiBarChartSquare />,
    // },
    // {
    //   title: "Booking",
    //   active: false,
    //   path: "/booking",
    //   imgSrc: <BiCalendarEvent />,
    // },
    {
      title: "Configure",
      secure: true,
      active: false,
      path: "/configure",
      imgSrc: <FcDataConfiguration />,
    },
    {
      title: "Integrations",
      secure: true,
      active: false,
      path: "/integrations",
      imgSrc: <FcDataConfiguration />,
    },
  ]);
  useEffect(() => {
    if (params.logout) {
      logout(dispatch, removeCookie, null, true);
    }
  }, [params]);

  //to alter the active property of selected link
  const setActive = (title) => {
    console.log(title);
    const newLinks = links.map((l) =>
      l.title === title ? { ...l, active: true } : { ...l, active: false }
    );
    toggleSidebar(dispatch, "left");
    setlinks(newLinks);
  };

  return (
    <SideNav dimentions={dimentions}>
      <div className="text-right">
        {dimentions.width > breakPointWidth ? null : (
          <GrFormClose style={{ fontSize: "2rem" }} onClick={(e) => toggleSidebar(dispatch, "left")} />
        )}
      </div>
      <div className="mb-5">
        {dimentions.width > breakPointWidth ? (
          <Link to="/" className="mb-2">
            <img
              alt="Securepass"
              id="brandLogo"
              className="p-0 m-0"
              style={{ width: "100px" }}
              src={`${cloudFrontOrigin}SecurePass_b3mm6p_rnqlfl.jpg`}
            />
          </Link>
        ) : null}
        {nodeInfo && nodeInfo.features ? (
          <ZoneAccessCardSubtitle className=" font-weight-bold ml-0">
            {JSON.stringify(sortPlanObject(nodeInfo.features)) ===
            JSON.stringify(sortPlanObject(advancedFeatures))
              ? "Advanced Plan"
              : JSON.stringify(sortPlanObject(nodeInfo.features)) ===
                JSON.stringify(sortPlanObject(basicFeatures))
              ? "Basic Plan"
              : JSON.stringify(sortPlanObject(nodeInfo.features)) ===
                JSON.stringify(sortPlanObject(basicFeatures2))
              ? "Basic Plan"
              : "Custom Plan"}
          </ZoneAccessCardSubtitle>
        ) : null}
      </div>
      {location.pathname === "/developer" ? (
        <div className="mt-5">
          <SideNavItem key={1}>
            <SideNavItemLogo active={true}>
              <FcDataConfiguration />
            </SideNavItemLogo>
            <SideNavItemTitle active={true}>
              <p
                onClick={(e) =>
                  toggleForm(formState, history, location, dispatch, formNames.addOauthAppForm)
                }
                className="nav-link m-0 p-0 cursor-pointer"
              >
                Create Oauth App
              </p>
            </SideNavItemTitle>
          </SideNavItem>
          {selectedNode && selectedNode.nodeId ? (
            <SideNavItem key={2}>
              <SideNavItemLogo active={true}>
                <FcDataConfiguration />
              </SideNavItemLogo>
              <SideNavItemTitle active={true}>
                <p
                  onClick={(e) =>
                    toggleForm(
                      formState,
                      history,
                      location,
                      dispatch,
                      formNames.createUserCredentialsForm
                    )
                  }
                  className="nav-link m-0 p-0 cursor-pointer"
                >
                  Generate Self Use credentials
                </p>
              </SideNavItemTitle>
            </SideNavItem>
          ) : null}
        </div>
      ) : (
        links.map(({ title, active, path, imgSrc, secure }) => {
          if (title === "Configure") {
            if (user && user.mobileNo && OPS_LIST.includes(user.mobileNo)) {
              return (
                <SideNavItem key={path}>
                  <SideNavItemLogo active={location.pathname === path ? true : false}>
                    {imgSrc}
                  </SideNavItemLogo>
                  <SideNavItemTitle active={active}>
                    <Link to={path} onClick={(e) => setActive(title)} className="nav-link">
                      {title}
                    </Link>
                  </SideNavItemTitle>
                </SideNavItem>
              );
            } else return null;
          }
          if (title === "Visitor") {
            if (
              user &&
              user.visitorPermissions &&
              user.roles &&
              selectedNode &&
              nodeInfo &&
              user.visitorPermissions[selectedNode.nodeId] &&
              isAdmin(user, nodeInfo)
            ) {
              return (
                <SideNavItem key={path}>
                  <SideNavItemLogo active={location.pathname === path ? true : false}>
                    {imgSrc}
                  </SideNavItemLogo>
                  <SideNavItemTitle active={active}>
                    <Link to={path} onClick={(e) => setActive(title)} className="nav-link">
                      {title}
                    </Link>
                  </SideNavItemTitle>
                </SideNavItem>
              );
            } else return null;
          }
          if (title === "Devices") {
            if (user && user.mobileNo && OPS_LIST.includes(user.mobileNo)) {
              return (
                <SideNavItem key={path}>
                  <SideNavItemLogo active={location.pathname === path ? true : false}>
                    {imgSrc}
                  </SideNavItemLogo>
                  <SideNavItemTitle active={active}>
                    <Link to={path} onClick={(e) => setActive(title)} className="nav-link">
                      {title}
                    </Link>
                  </SideNavItemTitle>
                </SideNavItem>
              );
            } else return null;
          }
          if (title === "Integrations") {
            // if (nodeInfo?.features?.allowIntegrations) {
            return (
              <SideNavItem key={path}>
                <SideNavItemLogo active={location.pathname === path ? true : false}>
                  {nodeInfo?.features?.allowIntegrations ? imgSrc : <DisabledLockIcon />}
                </SideNavItemLogo>
                <SideNavItemTitle active={active} style={{ position: "relative" }}>
                  {nodeInfo?.features?.allowIntegrations ? null : <DisabledOverlay />}
                  <Link to={path} onClick={(e) => setActive(title)} className="nav-link">
                    {title}
                  </Link>
                </SideNavItemTitle>
              </SideNavItem>
            );
            // } else return null;
          } else {
            return isAdmin(user, nodeInfo) ? (
              <SideNavItem key={path}>
                <SideNavItemLogo active={location.pathname === path ? true : false}>
                  {imgSrc}
                </SideNavItemLogo>
                <SideNavItemTitle active={active}>
                  <Link to={path} onClick={(e) => setActive(title)} className="nav-link">
                    {title}
                  </Link>
                </SideNavItemTitle>
              </SideNavItem>
            ) : secure ? null : (
              <SideNavItem key={path}>
                <SideNavItemLogo active={location.pathname === path ? true : false}>
                  {imgSrc}
                </SideNavItemLogo>
                <SideNavItemTitle active={active}>
                  <Link to={path} onClick={(e) => setActive(title)} className="nav-link">
                    {title}
                  </Link>
                </SideNavItemTitle>
              </SideNavItem>
            );
          }
        })
      )}
      <SideNavItem style={{ cursor: "pointer" }}>
        <SideNavItemLogo active={false} style={{ color: "red" }}>
          <RiLogoutBoxRLine />
        </SideNavItemLogo>
        <SideNavItemTitle
          style={{ color: "red", fontWeight: "bold" }}
          onClick={(e) => logout(dispatch, removeCookie, history)}
          className="nav-link"
        >
          Logout
        </SideNavItemTitle>
      </SideNavItem>
    </SideNav>
  );
};

export default Sidenav;
