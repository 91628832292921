import React, { useEffect, useState } from "react";
import { FormWrapper } from "../doorAddForm/DoorAddForm";
import { FieldInput, FieldLabel, InputBox, InputGroup, SelectBox } from "../FormBase.styled";
import { SolidButton } from "../../buttons/solidButton.styled";
import { useDispatch, useSelector } from "react-redux";
import { apiEndPoints, formNames, UDIDAllowedNodeCategories } from "../../../config";
import { useCookies } from "react-cookie";
import { useHistory, useLocation } from "react-router-dom";
import DatePicker from "react-datepicker";
import { convertTime, decodeTime, toggleForm } from "../../../utils/utilFunctions";
import NotificationManager from "react-notifications/lib/NotificationManager";
import {
  // getAccessGroupsAPI,
  getAccessInfoAPI,
  getMembershipByMembershipId,
  getPeopleAPI,
  updateMemberShipAPI,
} from "../../../utils/requests";
import ButtonState from "../../ButtonState/ButtonState";
import SubMenu from "../../SubMenu/SubMenu";
import MembershipRolesForm from "../membershipRolesForm/MembershipRolesForm";
const MembershipAccessInfoUpdateForm = ({ data }) => {
  const [cookies, setCookie, removeCookie] = useCookies(["AT"]);
  let history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const [expanded, setExpanded] = useState({});
  const [checkedNodes, setCheckedNodes] = useState({});
  const [accessPermission, setAccessPermission] = useState({});
  const [nodesAccessibleFromGroups, setNodesaccessibleFromGroups] = useState(null);
  const nodes = useSelector((state) => state.selectedNodeHierarchy);
  const utils = useSelector((state) => state.utils);
  const nodeInfo = useSelector((state) => state.nodeInfo);
  const groups = useSelector((state) => state.groups);
  const formState = useSelector((state) => state.formState);
  const selectedNode = useSelector((state) => state.selectedNode);
  const dimentions = useSelector((state) => state.dimentions);
  const apiStatus = useSelector((state) => state.apiStatus);
  const user = useSelector((state) => state.user);
  const [accessInfo, setAccessInfo] = useState(null);
  const [previousMembershipData, setPreviousMembershipData] = useState({ ...data });

  const initiallySetExpanded = (nodes, acc) => {
    let temp = acc ? acc : {};

    if (nodes.length !== undefined) {
      nodes.forEach((obj) => (temp[obj.nodeId] = true));
    }
    if (nodes.length !== undefined) {
      nodes.forEach((obj) => obj.children.forEach((nObj) => initiallySetExpanded(nObj, temp)));
    }
    return temp;
  };

  const [formData, setFormData] = useState({
    nodeId: previousMembershipData.nodeId,
    membershipId: previousMembershipData.membershipId,
    // UDId: previousMembershipData.UDId,
    // startTime: new Date(previousMembershipData.startTime * 1000),
    // endTime: previousMembershipData.endTime
    //   ? new Date(previousMembershipData.endTime * 1000)
    //   : previousMembershipData.endTime,
    // accessInfo: [],
  });

  const [showEndDateField, setShowEndDateField] = useState(previousMembershipData.endTime);
  const [showStartDateField, setShowStartDateField] = useState(false);

  const deStructurepreviousAccessGroupData = (accessInfo) => {
    setFormData({ ...formData });
    let checkedNodes = {};
    accessInfo.length &&
      accessInfo.forEach(
        (i) =>
          i.nodeId &&
          (checkedNodes[i.accessInfo.nodeId] = {
            ...i.accessInfo,
            startTimeInDay: decodeTime(i.accessInfo.startTimeInDay),
            endTimeInDay: decodeTime(i.accessInfo.endTimeInDay),
          })
      );
    setCheckedNodes({ ...checkedNodes });
  };

  // useEffect(() => {
  //   if (showEndDateField) {
  //     setFormData({ ...formData, endTime: new Date(Date.now()) });
  //   } else {
  //     let temp = formData;
  //     delete temp.endTime;
  //     setFormData({ ...temp });
  //   }
  // }, [showEndDateField]);

  const fetchMembershipDetailsIfClickedFromNotifications = async () => {
    const m = await getMembershipByMembershipId(
      user,
      dispatch,
      NotificationManager,
      removeCookie,
      history,
      data.nodeId,
      data.membershipId
    );

    if (m) {
      setPreviousMembershipData({
        ...previousMembershipData,
        subDepId: m.subDepId,
        depId: m.depId,
        UDId: m.UDId,
        startTime: m.startTime,
        endTime: m.endTime,
        // accessGroups: m.accessGroups,
        designation: m.designation,
      });
    }
  };

  useEffect(() => {
    if (data && data.membershipId && data.nodeId && !previousMembershipData.startTime) {
      console.log("api ran");
      fetchMembershipDetailsIfClickedFromNotifications();
    }
  }, [data, previousMembershipData]);

  useEffect(() => {
    if (previousMembershipData.startTime) {
      let params = {};
      if (previousMembershipData.depId) {
        params.depId = previousMembershipData.depId;
      }
      if (previousMembershipData.subDepId) {
        params.subDepId = previousMembershipData.subDepId;
      }
      if (previousMembershipData.designation) {
        params.designation = previousMembershipData.designation;
      }
      if (previousMembershipData.emergencyContactNo) {
        params.emergencyContactNo = previousMembershipData.emergencyContactNo;
      }
      if (previousMembershipData.roles) {
        params.roles = previousMembershipData.roles.map((r) => ({ nodeId: r.nodeId, role: r.role }));
      }
      setFormData({
        ...formData,
        ...params,
        UDId: previousMembershipData.UDId,
        startTime: new Date(previousMembershipData.startTime * 1000),
        endTime: previousMembershipData.endTime
          ? new Date(previousMembershipData.endTime * 1000)
          : previousMembershipData.endTime,
        accessInfo: [],
      });
    }
  }, [previousMembershipData]);

  // useEffect(() => {
  //   if (nodes) {
  //     setExpanded({ ...initiallySetExpanded(nodes) });
  //   }
  //   getPeopleAPI(
  //     user,
  //     dispatch,
  //     NotificationManager,
  //     removeCookie,
  //     history,
  //     selectedNode,
  //     utils.includeExpired
  //   );
  // }, [nodes]);

  useEffect(() => {
    if (previousMembershipData.membershipId) {
      getAccessInfoAPI(
        user,
        dispatch,
        NotificationManager,
        removeCookie,
        history,
        previousMembershipData.nodeId,
        previousMembershipData.membershipId,
        setAccessInfo
      );
    }
    // if (previousMembershipData.accessGroups && previousMembershipData.accessGroups.length) {
    //   getAccessGroupsAPI(
    //     user,
    //     dispatch,
    //     NotificationManager,
    //     removeCookie,
    //     history,
    //     previousMembershipData.nodeId
    //   );
    // }
  }, [previousMembershipData]);

  useEffect(() => {
    if (groups && groups.length) {
      let ns = {};

      groups.forEach((g) => {
        if (g.membershipIds.includes(previousMembershipData.membershipId)) {
          g.accessInfo.forEach((ai) => (ns[ai.nodeId] = g.name));
        }
      });
      setNodesaccessibleFromGroups({ ...ns });
    }
  }, [groups, previousMembershipData]);

  useEffect(() => {
    if (accessInfo) {
      deStructurepreviousAccessGroupData(accessInfo);
    }
  }, [accessInfo]);

  const closeForm = () => {
    toggleForm(formState, history, location, dispatch, formNames.membershipAccessInfoUpdateForm);
  };

  return (
    <FormWrapper dimentions={dimentions} closeForm={closeForm}>
      <h6 className="font-weight-bold mb-4">Update Membership - Access Info</h6>
      {previousMembershipData.startTime ? (
        <>
          <InputGroup dimentions={dimentions}>
            <FieldLabel dimentions={dimentions}>Start Date</FieldLabel>
            <FieldInput>
              <DatePicker
                dateFormat="dd/MM/yyyy"
                selected={formData.startTime}
                onChange={(date) =>
                  setFormData({
                    ...formData,
                    startTime: convertTime(date.toString(), "start"),
                  })
                }
              />
            </FieldInput>
          </InputGroup>
          <InputGroup dimentions={dimentions}>
            <FieldLabel dimentions={dimentions}>End Date</FieldLabel>
            <FieldInput>
              <div className="d-flex align-items-center">
                <label className="d-flex align-items-center mr-3 m-0">
                  <input
                    type="checkbox"
                    className="mr-2"
                    checked={!formData.endTime}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        endTime: e.target.checked
                          ? null
                          : previousMembershipData.endTime
                          ? new Date(previousMembershipData.endTime * 1000)
                          : new Date(Date.now()),
                      })
                    }
                  />
                  Permanent
                </label>
                {formData.endTime ? (
                  <DatePicker
                    dateFormat="dd/MM/yyyy"
                    selected={formData.endTime}
                    minDate={new Date()}
                    onChange={(date) =>
                      setFormData({
                        ...formData,
                        endTime: convertTime(date.toString(), "end"),
                      })
                    }
                  />
                ) : null}
              </div>
            </FieldInput>
          </InputGroup>
          {UDIDAllowedNodeCategories.includes(nodeInfo.nodeCategory) ? (
            <InputGroup dimentions={dimentions}>
              <FieldLabel dimentions={dimentions}>User ID</FieldLabel>
              <FieldInput>
                {/* {nodes ? checkedNodes[nodes[0].nodeId].accessInfo.nodeId :""} */}
                <InputBox
                  value={formData.UDId}
                  // placeholder="Enter Member Id (optional)"
                  onChange={(e) => setFormData({ ...formData, UDId: e.target.value })}
                />
              </FieldInput>
            </InputGroup>
          ) : null}
          <InputGroup dimentions={dimentions}>
            <FieldLabel dimentions={dimentions}>Emergency Contact No</FieldLabel>
            <FieldInput>
              {/* {nodes ? checkedNodes[nodes[0].nodeId].accessInfo.nodeId :""} */}
              <InputBox
                value={formData.emergencyContactNo || ""}
                // placeholder="Enter Member Id (optional)"
                onChange={(e) => setFormData({ ...formData, emergencyContactNo: e.target.value })}
              />
            </FieldInput>
          </InputGroup>
          <InputGroup dimentions={dimentions}>
            <FieldLabel dimentions={dimentions}>Designation</FieldLabel>
            <FieldInput>
              {/* {nodes ? checkedNodes[nodes[0].nodeId].accessInfo.nodeId :""} */}
              <InputBox
                value={formData.designation || ""}
                // placeholder="Enter Member Id (optional)"
                onChange={(e) => setFormData({ ...formData, designation: e.target.value })}
              />
            </FieldInput>
          </InputGroup>
          {/* <InputGroup dimentions={dimentions}>
        <FieldLabel dimentions={dimentions}></FieldLabel>
        <FieldInput>
          <label className="d-flex align-items-center">
            <input
              type="checkbox"
              className="mr-2"
              checked={showEndDateField}
              onChange={(e) => setShowEndDateField(e.target.checked)}
            />
            Set membership end date
          </label>
        </FieldInput>
      </InputGroup> */}
          {/* <InputGroup dimentions={dimentions}>
        <FieldLabel dimentions={dimentions}></FieldLabel>
        <FieldInput>
          <label className="d-flex align-items-center">
            <input
              type="checkbox"
              className="mr-2"
              checked={showStartDateField}
              onChange={(e) => {
                if (e.target.checked) {
                  setFormData({
                    ...formData,
                    startTime: new Date(previousMembershipData.startTime * 1000),
                  });
                } else {
                  let temp = formData;
                  delete temp.startTime;
                  setFormData({ ...temp });
                }
                setShowStartDateField(e.target.checked);
              }}
            />
            Set membership Start date
          </label>
        </FieldInput>
      </InputGroup> */}
          <InputGroup dimentions={dimentions}>
            <FieldLabel dimentions={dimentions}>Department</FieldLabel>
            <FieldInput>
              <SelectBox
                // defaultValue={formData.depId || ""}
                onChange={(e) => {
                  if (e.target.value.length === 0) {
                    setFormData({
                      ...formData,
                      depId: e.target.value,
                      subDepId: "",
                    });
                  } else {
                    setFormData({
                      ...formData,
                      depId: e.target.value,
                    });
                  }
                }}
              >
                <option value={""}>Select</option>
                {nodeInfo &&
                  nodeInfo.departments &&
                  nodeInfo.departments.map((x) => (
                    <option selected={x.depId === formData.depId} value={`${x.depId}`}>
                      {x.name}
                    </option>
                  ))}
              </SelectBox>
            </FieldInput>
          </InputGroup>
          {formData?.depId?.length ? (
            <InputGroup dimentions={dimentions}>
              <FieldLabel dimentions={dimentions}>Sub Department</FieldLabel>
              <FieldInput>
                <SelectBox
                  // defaultValue={formData.subDepId || ""}
                  onChange={(e) => setFormData({ ...formData, subDepId: e.target.value })}
                >
                  <option value={""} selected={!formData.subDepId || !formData.subDepId.length}>
                    Select
                  </option>
                  {nodeInfo &&
                    nodeInfo.departments &&
                    nodeInfo.departments
                      .filter((v) => v.depId === formData.depId)[0]
                      ?.subDepartments?.map((x) => (
                        <option selected={formData.subDepId === x.subDepId} value={`${x.subDepId}`}>
                          {x.name}
                        </option>
                      ))}
                </SelectBox>
              </FieldInput>
            </InputGroup>
          ) : null}
          {/* {!previousMembershipData?.accessGroups?.length ? ( */}
          <MembershipRolesForm formData={formData} setFormData={setFormData} />
          <InputGroup dimentions={dimentions}>
            <FieldLabel dimentions={dimentions}>Access Permissions</FieldLabel>
            <FieldInput border={true}>
              {nodes
                ? nodes.map((node) => (
                    <SubMenu
                      nodesAccessibleFromGroups={nodesAccessibleFromGroups}
                      checkedNodes={checkedNodes}
                      setCheckedNodes={setCheckedNodes}
                      name={node.name}
                      parentId={node.nodeId}
                      nodeId={node.nodeId}
                      hierarchy={node.children}
                      expanded={expanded}
                      setExpanded={setExpanded}
                      accessPermission={accessPermission}
                      setAccessPermission={setAccessPermission}
                    />
                  ))
                : null}
            </FieldInput>
          </InputGroup>
          {/* ) : (
            <InputGroup dimentions={dimentions}>
              <FieldLabel dimentions={dimentions}></FieldLabel>
              <FieldInput>
                <p className="mt-5 font-weight-bold text-info">
                  Since this member is a part of access group, you can’t edit their access here. Please
                  go to groups tab and edit from there directly
                </p>
              </FieldInput>
            </InputGroup>
          )} */}
          <InputGroup dimentions={dimentions}>
            <FieldLabel dimentions={dimentions}></FieldLabel>
            <FieldInput submitButton={true}>
              <SolidButton
                submit={true}
                onClick={async (e) => {
                  await updateMemberShipAPI(
                    user,
                    dispatch,
                    NotificationManager,
                    removeCookie,
                    history,
                    formData,
                    checkedNodes,
                    nodeInfo,
                    selectedNode,
                    closeForm,
                    utils
                    // previousMembershipData.nodeId,
                    // previousMembershipData.membershipId,
                    // setAccessInfo
                  );
                  let dispV = data.displayValues.filter((i) => i.membershipId !== data.membershipId);
                  if (data.displaySearch) {
                    let m = await getMembershipByMembershipId(
                      user,
                      dispatch,
                      NotificationManager,
                      removeCookie,
                      history,
                      data.nodeId,
                      data.membershipId
                    );
                    if (m) {
                      dispV.splice(data.index, 0, m);
                      data.setDisplayValues(dispV);
                    }
                  }
                }}
              >
                <ButtonState
                  loading={apiStatus[apiEndPoints.updateMemberShipAPI]}
                  innerText={"Update Membership"}
                />
              </SolidButton>
            </FieldInput>
          </InputGroup>{" "}
        </>
      ) : (
        <div className="text-center pt-5">
          <div className="spinner-border text-secondary mt-5 " role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      )}
    </FormWrapper>
  );
};

export default MembershipAccessInfoUpdateForm;
