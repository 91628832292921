import styled from "styled-components";

export const ZoneDepartmentCardContainer = styled.div`
  padding: 1rem 1rem;
  background-color: #ffffff;
  border: 0.5px solid #e8e8e8;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  border-radius: 10px;
  margin-bottom: 1rem;
`;

export const Title = styled.p`
  font-size: 1.2rem;
  margin-bottom: 1.5rem;
  font-weight: bold;
  font-style: normal;
  line-height: 1.5rem;
  color: #353535;
`;

export const ItemWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0.5rem 0px;
`;

export const ItemTitle = styled.p`
  color: #43424b;
  font-size: 0.9rem;
  margin: 0px 0px;
`;

export const ActionButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
