import React, { useEffect, useState } from "react";
import {
  CheckBox,
  CheckBoxContainer,
  CheckBoxLabel,
} from "../../components/forms/attendanceFormatForm/attendanceFormatForm.styled";
import { BiChevronDownCircle } from "react-icons/bi";
import { getQueryParams, inputFieldsMap } from "../Services/utils";
import ContactPicker from "./ContactPicker";
const InviteForm = ({
  handleChange,
  error,
  startTime,
  setSelectedNode,
  selectedNode,
  includeSelected,
  setIncludeSelected,
  formatDate,
  setSelectedChildNodes,
  selectedChildNodes,
  formData,
  handleSubmit,
  nodes,
  setError,
  setStartTime,
  setEndTime,
}) => {
  const [fields, setFields] = useState(null);
  const [collapsed, setCollapsed] = useState(false);
  useEffect(() => {
    let currentTime, startTime, endTime;
    currentTime = new Date();
    let offset = currentTime.getTimezoneOffset();
    startTime = new Date(currentTime.getTime() - offset * 60000).toISOString().slice(0, 16);
    endTime = new Date(currentTime.getTime() - offset * 60000 + 86400000).toISOString().slice(0, 16);
    setStartTime(startTime);
    setEndTime(endTime);
  }, []);

  const handleSelectedChildNodesChange = (e, nodeId) => {
    console.log(nodeId);
    if (e.target.checked) {
      setSelectedChildNodes([...selectedChildNodes, nodeId]);
    } else if (!e.target.checked) {
      setSelectedChildNodes([...selectedChildNodes.filter((n) => n !== nodeId)]);
    }
  };

  useEffect(() => {
    if (selectedNode) {
      setFields([...inputFieldsMap(selectedNode)]);
    } else {
      setFields(null);
    }
  }, [selectedNode]);

  return (
    <div id="requestForm" className="text-left w-100 mb-5 pb-5">
      <div className="col-12 my-3">
        {getQueryParams(window.location.search).nodeId ? (
          nodes &&
          nodes.length && (
            <CheckBoxContainer>
              <CheckBox
                type="checkbox"
                name="firstName"
                checked={includeSelected}
                onChange={(e) => setIncludeSelected(!includeSelected)}
              />
              <CheckBoxLabel>
                {nodes.filter((n) => n.nodeId === getQueryParams(window.location.search).nodeId)[0].name}
              </CheckBoxLabel>
            </CheckBoxContainer>
          )
        ) : (
          <>
            <select
              disabled={getQueryParams(window.location.search).nodeId}
              className="vms-select"
              id="AccessArea"
              onChange={(e) => {
                if (e.target.value === "Select a node") {
                  setSelectedNode(null);
                  setError({ ...error, accessError: true });
                } else {
                  setError({ ...error, accessError: false });
                  setSelectedNode({ ...JSON.parse(e.target.value) });
                }
              }}
            >
              <option value={null}>{"Select a node"}</option>
              {nodes.map((n) => (
                <option selected={formData?.nodeId === n.nodeId} value={JSON.stringify(n)}>
                  {n.parentIds.map((i) => "- ")}
                  {n.name}
                </option>
              ))}
            </select>

            {error.accessError ? (
              <div className="col-12">
                <small className="vms-small" id="accessError">
                  Please select a Node.
                </small>
              </div>
            ) : null}
          </>
        )}
      </div>

      {nodes && nodes.length && selectedNode && selectedNode.nodeId && selectedChildNodes ? (
        <div className="col-12 my-3">
          {nodes
            .filter((node) => node.parentIds.includes(selectedNode.nodeId))
            .map((n) => (
              <CheckBoxContainer>
                <CheckBox
                  type="checkbox"
                  name="firstName"
                  checked={selectedChildNodes.includes(n.nodeId)}
                  onChange={(e) => handleSelectedChildNodesChange(e, n.nodeId)}
                />
                <CheckBoxLabel>{n.name}</CheckBoxLabel>
              </CheckBoxContainer>
            ))}
        </div>
      ) : null}

      {fields && fields.includes("contact") ? (
        <>
          <div className="col-12 my-3" style={{ display: "flex", alignItems: "center" }}>
            <input
              type="tel"
              id="phoneNumber"
              required
              className="vms-input"
              name="contact"
              onChange={handleChange}
              value={formData.contact}
              placeholder={"Visitor’s Phone Number or Email"}
            />
            <ContactPicker handleChange={handleChange} fieldName={"contact"} />
          </div>
          {error.numberError ? (
            <div className="col-12">
              <small className="vms-small" id="numberError">
                &nbsp;
              </small>
            </div>
          ) : null}
        </>
      ) : null}

      {/* <> {JSON.stringify(formData)}</> */}

      {fields &&
      selectedNode &&
      selectedNode.slots &&
      selectedNode.slots.length > 0 &&
      fields.includes("slots") ? (
        <>
          <div className="col-12 my-3">
            <select name="slots" className="vms-select" onChange={handleChange}>
              <option disabled selected>
                Select a slot
              </option>
              {selectedNode.slots.map((slot) => (
                <option
                  value={JSON.stringify(slot)}
                  disabled={slot.peopleLimit - slot.noOfApplications <= 0}
                >
                  {`
          ${formatDate(slot.startTime)} - ${formatDate(slot.endTime)} ${
                    slot.peopleLimit >= 0 || slot.peopleLimit != null
                      ? (slot.peopleLimit - slot.noOfApplications < 0
                          ? 0
                          : slot.peopleLimit - slot.noOfApplications) +
                        "/" +
                        slot.peopleLimit +
                        " Left"
                      : ""
                  }
          `}
                </option>
              ))}
            </select>
          </div>
        </>
      ) : null}

      <hr className="my-4"></hr>

      <div>
        <div class="collapse" id="collapseExample">
          {fields && fields.includes("name") ? (
            <>
              <div className="col-12 my-3">
                <input
                  className="vms-input"
                  type="text"
                  required
                  id="firstName"
                  name="name"
                  onChange={handleChange}
                  title="Must contain only alphabets"
                  value={formData.name}
                  placeholder="Visitor’s Name"
                />
              </div>
              {error.firstName ? (
                <div className="col-12">
                  <small className="vms-small" clas id="fNameError">
                    Please enter visitor's Name
                  </small>
                </div>
              ) : null}
            </>
          ) : null}

          {fields && fields.includes("companyName") ? (
            <>
              <div className="col-12 my-3">
                <input
                  className="vms-input"
                  type="text"
                  required
                  id="companyName"
                  name="companyName"
                  onChange={handleChange}
                  title="Company Name"
                  value={formData.companyName}
                  placeholder="Company Name"
                />
              </div>
              {error.purpose ? (
                <div className="col-12">
                  <small className="vms-small" clas id="companyError">
                    Please enter company name
                  </small>
                </div>
              ) : null}
            </>
          ) : null}
          {fields && fields.includes("purpose") ? (
            <>
              <div className="col-12 my-3">
                <input
                  className="vms-input"
                  type="text"
                  required
                  id="purpose"
                  name="purpose"
                  onChange={handleChange}
                  title="Purpose of visit"
                  value={formData.purpose}
                  placeholder="Purpose of visit"
                />
              </div>
              {error.purpose ? (
                <div className="col-12">
                  <small className="vms-small" clas id="purposeError">
                    Please enter purpose of visit
                  </small>
                </div>
              ) : null}
            </>
          ) : null}
          {fields && fields.includes("startTime") ? (
            <>
              <div className="col-12 my-3 ml-auto mr-auto">
                <p className="col-5 my-1 vms-heading">Start Time</p>
                <input
                  className="vms-input"
                  required
                  type="datetime-local"
                  id="startTime"
                  name="startTime"
                  value={startTime}
                  onChange={handleChange}
                  placeholder="Start Time"
                />

                {error.stError ? (
                  <div className="col-12 my-3">
                    <small className="vms-small" id="stError">
                      Enter the proper Start Time
                    </small>
                  </div>
                ) : null}
              </div>
            </>
          ) : null}
        </div>
        <div className="text-center">
          <p style={{ fontSize: "1rem", margin: "0px" }}>Add Name</p>
          <BiChevronDownCircle
            onClick={(e) => setCollapsed(!collapsed)}
            data-toggle="collapse"
            aria-controls="collapseExample"
            data-target="#collapseExample"
            role="button"
            style={{
              fontSize: "2.5rem",
              transform: !collapsed ? "rotate(0deg)" : "rotate(180deg)",
              transition: "all 0.5s ease",
            }}
          />
        </div>
      </div>

      <div className="fixed-bottom bg-white py-4">
        <div className="col-12 text-center">
          <button
            disabled={!includeSelected && !selectedChildNodes.length}
            id="submitbutton"
            className="bigButton"
            onClick={(e) => handleSubmit()}
          >
            Send OTP
          </button>
        </div>
      </div>
    </div>
  );
};

export default InviteForm;
