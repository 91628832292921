import { UPDATE_UTILS } from "../actionTypes";

const utilsReducer = (utils = {}, action) => {
  if (action.type === UPDATE_UTILS) {
    return (utils = { ...utils, ...action.payload });
  }

  return utils;
};

export default utilsReducer;
