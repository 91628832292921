import React, { useEffect, useState } from "react";
import { isPlatformAllowed } from "../utils/pricingChecks";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
const CheckPlatform = () => {
  const nodeInfo = useSelector((state) => state.nodeInfo);
  const [allowed, setAllowed] = useState(true);
  useEffect(() => {
    if (nodeInfo) setAllowed(isPlatformAllowed(nodeInfo));

  }, [nodeInfo]);

  return (
    <>
      {nodeInfo.features ? (
        allowed === false ? (
          <Redirect to="/apps?platformRestricted=true" />
        ) : window.location.pathname + window.location.search === "/apps?platformRestricted=true" &&
          allowed === true ? (
          <Redirect to="/apps" />
        ) : null
      ) : null}
    </>
  );
};

export default CheckPlatform;

// window.location.pathname + window.location.search === "/apps?platformRestricted=true" ? ( //{" "}
//   <Redirect to="/apps" />
// ) : window.location.pathname + window.location.search !== "/apps?platformRestricted=true" ? ( //{" "}
//   <Redirect to="/apps?platformRestricted=true" />
// ) : null}
