import React from "react";
import { useState } from "react";
import { useCookies } from "react-cookie";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { formNames, rootApi } from "../../../config";
import { toggleForm } from "../../../utils/utilFunctions";
import { SolidButton } from "../../buttons/solidButton.styled";
import { FormWrapper } from "../doorAddForm/DoorAddForm";
import { GrAddCircle } from "react-icons/gr";
import { TiDeleteOutline } from "react-icons/ti";
import {
  FieldInput,
  FieldLabel,
  InputBox,
  InputGroup,
} from "../FormBase.styled";

import NotificationManager from "react-notifications/lib/NotificationManager";
import { removeRfid, updateRfid } from "../../../utils/requests";

const RFIDAddForm = ({ id, getPeopleAPI }) => {
  const [cookies, setCookie, removeCookie] = useCookies(["AT"]);
  const history = useHistory();
  const dispatch = useDispatch();
  const dimentions = useSelector((state) => state.dimentions);
  const utils = useSelector((state) => state.utils);
  const selectedNode = useSelector((state) => state.selectedNode);
  const formState = useSelector((state) => state.formState);
  const location = useLocation();
  const user = useSelector((state) => state.user);
  const [rfid, setRfid] = useState("");
  const membershipData = useSelector(
    (state) => state.formState[formNames.rfidAddForm]
  );

  const closeForm = () => {
    toggleForm(
      formState,
      history,
      location,
      dispatch,
      formNames.rfidAddForm
    );
  };

  return (
    <FormWrapper dimentions={dimentions} closeForm={closeForm}>
      <h6 className="font-weight-bold mb-4">Add RFID</h6>
      <InputGroup dimentions={dimentions}>
        <FieldLabel dimentions={dimentions}>RFID</FieldLabel>
        <FieldInput>
          <div className="d-flex align-items-center">
            <InputBox
              style={{ width: "150px" }}
              value={rfid}
              onChange={(e) => setRfid(e.target.value)}
              // placeholder="Enter RFID"
            />
            <SolidButton
              onClick={(e) =>
                updateRfid(
                  user,
                  dispatch,
                  NotificationManager,
                  removeCookie,
                  history,
                  selectedNode,
                  rfid,
                  membershipData.userId,
                  membershipData,
                  utils
                )
              }
            >
              Add
            </SolidButton>
          </div>
        </FieldInput>
      </InputGroup>
      {membershipData.RFCards && membershipData.RFCards.length ? (
        <>
          <hr className="bg-dark" />
          {membershipData.RFCards.map((cn) => (
            <InputGroup dimentions={dimentions}>
              <FieldLabel dimentions={dimentions}></FieldLabel>
              <FieldInput>
                <div className="d-flex align-items-center">
                  <p style={{ width: "100px" }} className="m-0">
                    {cn.customRFId}
                  </p>
                  <TiDeleteOutline
                    onClick={(e) =>
                      removeRfid(
                        user,
                        dispatch,
                        NotificationManager,
                        removeCookie,
                        history,
                        cn.customRFId,
                        membershipData.userId,
                        selectedNode,
                        membershipData,
                        utils
                      )
                    }
                    style={{
                      fontSize: "1.3rem",
                      color: "red",
                      cursor: "pointer",
                      marginLeft: "15px",
                    }}
                  />
                </div>
              </FieldInput>
            </InputGroup>
          ))}
        </>
      ) : null}
    </FormWrapper>
  );
};

export default RFIDAddForm;
