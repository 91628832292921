import styled, { css } from "styled-components";

export const DevicesRowContainer = styled.div`
  border: 0.5px solid #e8e8e8;
  box-shadow: 0px 0.2rem 0.2rem 0.2rem rgba(0, 0, 0, 0.06);
  border-radius: 5px;
  height: 36vh;
  margin-bottom: 20px;
  position: relative;
  padding-bottom : 30px;
`;

export const ActiveDevicesRowStyle = styled.div`
  display: grid;
  grid-row-gap: 0.5rem;
  grid-column-gap: 0.2rem;
  padding: 0.5rem 1rem;
  ${(props) =>
    props.enableOAC
      ? css`
          grid-template-columns: 1fr 1fr 1fr 1fr 2fr 2fr 1.5fr 1.5fr 1fr 1fr 1fr 1fr 1fr 0.25fr 0.25fr;
          grid-template-areas: "deviceId type addDate status zone doorAssigned config connectionStatus button button button button button delete info";
        `
      : css`
          grid-template-columns: 1fr 1fr 1fr 1.5fr 2fr 2fr 1.5fr 1.5fr 1.5fr 1fr 1fr 1fr 0.25fr 0.25fr;
          grid-template-areas: "deviceId type addDate status zone doorAssigned config connectionStatus button button delete  button button info";
        `}

  ${(props) =>
    props.active
      ? css`
          background-image: linear-gradient(91deg, #fe6dad -3%, #fc9f35);
        `
      : css``}
  ${(props) =>
    props.isHead
      ? css`
          font-weight: bold;
          padding-right: 1.5rem;
        `
      : css`
          :hover {
            background-image: linear-gradient(91deg, #fe6dad -3%, #fc9f35);
            color: white;
          }
        `}
`;

export const DeviceId = styled.div`
  display: flex;
  grid-area: "deviceId";
  justify-content: center;
  align-items: center;
`;
export const Type = styled.div`
  grid-area: "type";
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const AddDate = styled.div`
  grid-area: "addDate";
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const Status = styled.div`
  grid-area: "status";
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 0;
`;
export const Zone = styled.div`
  grid-area: "zone";
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const Config = styled.div`
  grid-area: "config";
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ConnectionStatus = styled.div`
  grid-area: "connectionStatus";
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const DoorAssigned = styled.div`
  grid-area: "doorAssigned";
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Button = styled.button`
  grid-area: "button";
  background-color: transparent;
  border: 1px solid black;
  outline: none;
  color: black;
  justify-self: center;
  align-self: center;
  cursor: pointer;
  font-size: 0.8rem;
  padding: 0.2rem 1rem;
  border-radius: 20px;
  ${(props) =>
    props.isHead
      ? css`
          visibility: hidden;
        `
      : css``}
  :hover {
    opacity: 0.5;
    color: black;
    border: 1px solid black;
  }
`;

export const Delete = styled.div`
  grid-area: "delete";
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  ${(props) =>
    props.isHead
      ? css`
          visibility: hidden;
        `
      : css``}
  `;

export const VersionInfo = styled.div`
  grid-area: "info";
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  ${(props) =>
    props.isHead
      ? css`
          visibility: hidden;
        `
      : css``}
  `;

export const SyncButtonMobile = styled.button`
background-color: orange;
box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.15);
border-radius: 25px;
outline: none;
color: white;
justify-self: center;
align-self: center;
cursor: pointer;
font-size: 1rem;
font-weight : bold;
padding: 0.3rem 1.2rem;
border : 0px;
`;
