import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SolidButton } from "../../buttons/solidButton.styled";
import { FormWrapper } from "../doorAddForm/DoorAddForm";
import { useCookies } from "react-cookie";
import { FieldInput, FieldLabel, InputBox, InputGroup } from "../FormBase.styled";
import { useHistory } from "react-router-dom";
import NotificationManager from "react-notifications/lib/NotificationManager";
import { updateNodeInfoAPI } from "../../../utils/requests";
import { apiEndPoints } from "../../../config";
import ButtonState from "../../ButtonState/ButtonState";

const PeopleLimitEditForm = ({ setShowPeopleLimitEditForm, threashold }) => {
  const [cookies, setCookie, removeCookie] = useCookies(["AT"]);
  let history = useHistory();
  const dispatch = useDispatch();
  const [peopleLimit, setPeopleLimit] = useState(threashold);
  const selectedNode = useSelector((state) => state.selectedNode);
  const apiStatus = useSelector((state) => state.apiStatus);
  const dimentions = useSelector((state) => state.dimentions);
  const user = useSelector((state) => state.user);
  const closeForm = () => {
    setShowPeopleLimitEditForm(false);
  };
  const updateNodeInfo = async (reqBody) => {
    let body = { nodeId: selectedNode.nodeId, peopleLimit };
    updateNodeInfoAPI(
      user,
      dispatch,
      NotificationManager,
      removeCookie,
      history,
      selectedNode,
      body,
      closeForm
    );
  };

  return (
    <FormWrapper dimentions={dimentions} closeForm={closeForm}>
      <h6 className="font-weight-bold mb-4">Edit Threashold Count</h6>
      <InputGroup dimentions={dimentions}>
        <FieldLabel dimentions={dimentions}>Threashold</FieldLabel>
        <FieldInput>
          <InputBox
            // placeholder="Number of people"
            type="number"
            value={peopleLimit}
            onChange={(e) => setPeopleLimit(e.target.value)}
          />
        </FieldInput>
      </InputGroup>
      <InputGroup dimentions={dimentions}>
        <FieldLabel dimentions={dimentions}></FieldLabel>
        <FieldInput submitButton={true}>
          <SolidButton submit={true} onClick={updateNodeInfo}>
            <ButtonState loading={apiStatus[apiEndPoints.updateNodeInfoAPI]} innerText={"Update"} />
          </SolidButton>
        </FieldInput>
      </InputGroup>
    </FormWrapper>
  );
};

export default PeopleLimitEditForm;
