import React, { useEffect, useState } from "react";
import { FieldInput, FieldLabel, InputGroup, SelectBox } from "../FormBase.styled";
import { MdAddCircle, MdRemoveCircle } from "react-icons/md";
import { useSelector } from "react-redux";
import { membershipRoles } from "../../../config";
import { isAdmin } from "../../../utils/utilFunctions";
const MembershipRolesForm = ({ formData, setFormData }) => {
  const [nodesMap, setNodesMap] = useState({});
  const [nodeNameMap, setNodeNameMap] = useState({});
  const [roleToPush, setRoleToPush] = useState({ nodeId: "", role: "" });
  const nodeInfo = useSelector((state) => state.nodeInfo);
  const user = useSelector((state) => state.user);
  const dimentions = useSelector((state) => state.dimentions);
  const selectedNode = useSelector((state) => state.selectedNode);
  const nodes = useSelector((state) => state.nodes);
  const validateRoleAndPush = (rtp) => {
    if (rtp.nodeId.length && rtp.role.length) {
      if (formData.roles.filter((r) => r.nodeId === rtp.nodeId && r.role === rtp.role).length) {
        return;
      } else {
        setFormData({ ...formData, roles: [...formData.roles, { ...rtp }] });
      }
    } else {
      return;
    }
  };

  const handleRoleRemove = (i) => {
    let r = [...formData.roles];
    r[i] = null;
    setFormData({ ...formData, roles: [...r.filter((n) => n)] });
  };

  const populateNodeNameMap = (sn) => {
    const nm = {};
    const addToNm = (a) => {
      nm[a.nodeId] = a.name;
      if (a.children && a.children.length) {
        a.children.forEach((n) => addToNm(n));
      }
    };
    sn.forEach((sn) => addToNm(sn));
    setNodeNameMap({ ...nm });
  };

  const populateNodesMap = (sn) => {
    const nm = {};
    const addToNm = (a) => {
      nm[a.nodeId] = a.name;
      if (a.children && a.children.length) {
        a.children.forEach((n) => addToNm(n));
      }
    };
    const id = sn.rootNodeId || sn.nodeId;
    const node = nodes.filter((n) => n.nodeId === id);
    addToNm(node[0]);
    setNodesMap({ ...nm });
  };

  useEffect(() => {
    if (nodes) {
      populateNodeNameMap(nodes);
    }
  }, [nodes]);

  useEffect(() => {
    populateNodesMap(selectedNode);
  }, [selectedNode]);

  return (
    <InputGroup dimentions={dimentions}>
      <FieldLabel dimentions={dimentions}>Roles</FieldLabel>
      <FieldInput>
        <div className="container-fluid py-3">
          {/* display roles */}
          <div className="row p-2">
            {formData.roles &&
              formData.roles.map((r, i) =>
                Object.keys(user.roles).includes(selectedNode.rootNodeId) ||
                Object.keys(user.roles).includes(selectedNode.nodeId) ? (
                  <>
                    <div className="col-6">
                      <p>{nodeNameMap[r.nodeId]}</p>
                    </div>
                    <div className="col-5">{r.role}</div>
                    <div className="col-1">
                      {isAdmin(user, { nodeId: r.nodeId }) ? (
                        <MdRemoveCircle
                          onClick={(e) => handleRoleRemove(i)}
                          style={{ color: "red", fontSize: "1.5rem", cursor: "pointer" }}
                        />
                      ) : null}
                    </div>
                  </>
                ) : null
              )}
          </div>
          {/* form for roles */}
          <div className="row">
            <div className="col-md-6 col-sm-12 col-xs-12">
              <SelectBox onChange={(e) => setRoleToPush({ ...roleToPush, nodeId: e.target.value })}>
                <option value={null}>Select</option>
                {Object.entries(nodesMap).map(([id, name]) =>
                  isAdmin(user, { nodeId: id }) ? <option value={id}>{name}</option> : null
                )}
              </SelectBox>
            </div>
            <div className="col-md-6 col-sm-12 col-xs-12">
              <SelectBox onChange={(e) => setRoleToPush({ ...roleToPush, role: e.target.value })}>
                {membershipRoles(nodeInfo.nodeCategory).map((x) => (
                  <option>{x}</option>
                ))}
              </SelectBox>
            </div>
            <div className="col-12">
              <button
                className="btn btn-sm mt-3 btn-block"
                onClick={(e) => validateRoleAndPush(roleToPush)}
                style={{ backgroundColor: "#49eecc", fontWeight: "bold" }}
                disabled={!roleToPush?.nodeId?.length || !roleToPush?.role?.length}
              >
                Add
              </button>
              {/* <MdAddCircle
                onClick={(e) => validateRoleAndPush(roleToPush)}
                
              /> */}
            </div>
          </div>
        </div>
      </FieldInput>
    </InputGroup>
  );
};

export default MembershipRolesForm;
