import React from "react";

// import PeopleView from "../peopleView/PeopleView";
import { useState } from "react";
import Popover, { ArrowContainer } from "react-tiny-popover";
// import { BiEdit } from "react-icons/bi";
import { useEffect } from "react";
import { breakPointWidth, formNames } from "../../../config";
import { useSelector } from "react-redux";
import { SolidButton } from "../../buttons/solidButton.styled";
import {
  Button,
  EditBtnContainer,
  GroupName,
  GroupRowContainer,
  GroupRowStyled,
  InceptionDate,
  People,
  RemoveBtnContainer,
} from "./groupRow.styled";
import MembershipView from "../membershipView/MembershipView";
import { displayDate, nullChecker, toggleForm } from "../../../utils/utilFunctions";
import {
  ButtonMobile,
  FieldLabelMobile,
  FieldValueMobile,
  ZoneLogsUserRowMobileView,
  ZoneLogsUserRowMobileWrapper,
} from "../../zone/ZoneLogsUserRow/zoneLogsUserRow.styled";
import PlaceholderMessage from "../../PlaceholderMessage";
import GroupMembersMobileView from "../../groupMembersMobileView/GroupMembersMobileView";
import { OverFLowScrollBox } from "../../App.styled";
import NotificationManager from "react-notifications/lib/NotificationManager";
// import { removeAccessGroupAPI } from "../../../utils/requests";
import { useLocation } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import { removeAccessGroupAPI } from "../../../utils/requests";
const GroupRow = ({ removeCookie, history, dispatch, getAccessGroupsAPI }) => {
  const [positions, setPositions] = useState([]);
  const [showAccessInfoMenu, setShowAccessInfoMenu] = useState([]);
  const [showMembershipInfoMenu, setShowMembershipInfoMenu] = useState([]);
  const groups = useSelector((state) => state.groups);
  const user = useSelector((state) => state.user);
  const selectedNode = useSelector((state) => state.selectedNode);
  const formState = useSelector((state) => state.formState);
  const location = useLocation();
  const dimentions = useSelector((state) => state.dimentions);
  const [showMemberships, setShowMemberships] = useState(false);
  const [enabledGroupId, setEnabledGroupId] = useState(null);

  useEffect(() => {
    if (selectedNode) {
      getAccessGroupsAPI(
        user,
        dispatch,
        NotificationManager,
        removeCookie,
        history,
        selectedNode.nodeId
      );
    }
  }, [selectedNode.nodeId]);

  return (
    <>
      {showMemberships && showMemberships.length && enabledGroupId ? (
        <GroupMembersMobileView
          showMenu={showMemberships}
          setShowMenu={setShowMemberships}
          enabledGroupId={enabledGroupId}
          setEnabledGroupId={setEnabledGroupId}
        />
      ) : null}
      <div className="col-12 text-right my-3">
        <SolidButton
          onClick={(e) => toggleForm(formState, history, location, dispatch, formNames.groupAddForm)}
        >
          Add Group
        </SolidButton>
      </div>
      {dimentions.width > breakPointWidth ? (
        <GroupRowContainer>
          <GroupRowStyled isHead={true}>
            <GroupName>Group name</GroupName>
            <InceptionDate>Inception date</InceptionDate>
            <People isHead={true}>People</People>
            {/* <Gates isHead={true}>Gates</Gates> */}
            <EditBtnContainer></EditBtnContainer>
            <RemoveBtnContainer></RemoveBtnContainer>
          </GroupRowStyled>
          <OverFLowScrollBox>
            {groups && groups.length ? (
              groups.map((i, idx) => {
                return (
                  <GroupRowStyled
                    key={i.accessGroupId}
                    ref={(el) => {
                      let temp = positions;
                      let show = showAccessInfoMenu;
                      show[idx] = false;
                      if (!temp[idx]) {
                        temp[idx] = el
                          ? {
                              top: el.getBoundingClientRect().top,
                              left: el.getBoundingClientRect().left,
                              width: el.getBoundingClientRect().width,
                            }
                          : null;
                        setShowAccessInfoMenu(show);
                        setShowMembershipInfoMenu(show);
                        setPositions([...temp]);
                      }
                    }}
                  >
                    <GroupName>{i.name}</GroupName>
                    <InceptionDate>{displayDate(i.createdTimestamp * 1000)}</InceptionDate>

                    <People
                      onClick={(e) => {
                        setShowMemberships(i.membershipIds);
                        setEnabledGroupId(i.accessGroupId);
                      }}
                    >
                      View
                    </People>

                    {/* <Popover
                      onClickOutside={(e) =>
                        setShowMembershipInfoMenu([
                          ...showMembershipInfoMenu.map((v) => false),
                        ])
                      }
                      isOpen={showMembershipInfoMenu[idx]}
                      position={"bottom"} // preferred position
                      content={({ position, targetRect, popoverRect }) => (
                        <ArrowContainer
                          position={position}
                          targetRect={targetRect}
                          popoverRect={popoverRect}
                          arrowColor={"white"}
                          arrowSize={10}
                          arrowStyle={{ border: "0.5px solid #e8e8e8" }}
                        >
                          <MembershipView
                            showMenu={showMembershipInfoMenu}
                            data={i.membershipIds}
                            setShowMenu={setShowMembershipInfoMenu}
                            accessGroupId={i.accessGroupId}
                          />
                        </ArrowContainer>
                      )}
                    >
                      <People
                        onClick={(e) => {
                          let show = showMembershipInfoMenu.map((v, i) =>
                            i === idx ? true : false
                          );
                          setShowMembershipInfoMenu([...show]);
                        }}
                      >
                        View
                      </People>
                    </Popover> */}

                    {/* <Popover
                    onClickOutside={(e) =>
                      setShowAccessInfoMenu([
                        ...showAccessInfoMenu.map((v) => false),
                      ])
                    }
                    isOpen={showAccessInfoMenu[idx]}
                    position={"bottom"} // preferred position
                    content={({ position, targetRect, popoverRect }) => (
                      <ArrowContainer
                        position={position}
                        targetRect={targetRect}
                        popoverRect={popoverRect}
                        arrowColor={"white"}
                        arrowSize={10}
                        arrowStyle={{ border: "0.5px solid #e8e8e8" }}
                      >
                        <PeopleView
                          showMenu={showAccessInfoMenu}
                          nodeId={selectedNode.nodeId}
                          data={i.accessInfo}
                          setShowMenu={setShowAccessInfoMenu}
                        />
                      </ArrowContainer>
                    )}
                  >
                    <Gates
                      onClick={(e) => {
                        let show = showAccessInfoMenu.map((v, i) =>
                          i === idx ? true : false
                        );
                        setShowAccessInfoMenu(show);
                      }}
                    >
                      View
                    </Gates>
                  </Popover> */}
                    <EditBtnContainer>
                      <Button
                        onClick={(e) =>
                          toggleForm(
                            formState,
                            history,
                            location,
                            dispatch,
                            formNames.groupUpdateForm,
                            i
                          )
                        }
                      >
                        Edit Group
                      </Button>
                    </EditBtnContainer>
                    <RemoveBtnContainer>
                      <Button
                        onClick={(e) =>
                          removeAccessGroupAPI(
                            user,
                            dispatch,
                            NotificationManager,
                            removeCookie,
                            history,
                            selectedNode.nodeId,
                            i.accessGroupId,
                            confirmAlert
                          )
                        }
                      >
                        Remove
                      </Button>
                    </RemoveBtnContainer>
                  </GroupRowStyled>
                );
              })
            ) : (
              <PlaceholderMessage message="No data to display" />
            )}
          </OverFLowScrollBox>
        </GroupRowContainer>
      ) : (
        <>
          <div className="row m-0 p-0">
            {groups && groups.length ? (
              groups.map((i, idx) => {
                return (
                  <div className="col-sm-12 col-xs-12 p-0 col-md-6 px-md-2" key={i.accessGroupId}>
                    <ZoneLogsUserRowMobileWrapper>
                      <ZoneLogsUserRowMobileView>
                        <div>
                          <FieldLabelMobile>Group Name</FieldLabelMobile>
                          <FieldValueMobile>{nullChecker(i.name)}</FieldValueMobile>
                        </div>

                        <div>
                          <FieldLabelMobile>Inception Date</FieldLabelMobile>
                          <FieldValueMobile>{displayDate(i.createdTimestamp * 1000)}</FieldValueMobile>
                        </div>
                      </ZoneLogsUserRowMobileView>
                      <div className="row my-2 mt-2">
                        <div className="col-12 mb-2">
                          <ButtonMobile
                            type="info"
                            className="w-100"
                            onClick={(e) => {
                              setShowMemberships(i.membershipIds);
                              setEnabledGroupId(i.accessGroupId);
                            }}
                          >
                            View Members
                          </ButtonMobile>
                        </div>
                        <div className="col-6">
                          <ButtonMobile
                            type="info"
                            className="w-100"
                            onClick={(e) =>
                              toggleForm(
                                formState,
                                history,
                                location,
                                dispatch,
                                formNames.groupUpdateForm,
                                i
                              )
                            }
                          >
                            Edit
                          </ButtonMobile>
                        </div>
                        <div className="col-6">
                          <ButtonMobile
                            className="w-100"
                            onClick={(e) =>
                              removeAccessGroupAPI(
                                user,
                                dispatch,
                                NotificationManager,
                                removeCookie,
                                history,
                                selectedNode.nodeId,
                                i.accessGroupId,
                                confirmAlert
                              )
                            }
                          >
                            Remove
                          </ButtonMobile>
                        </div>
                      </div>
                    </ZoneLogsUserRowMobileWrapper>
                  </div>
                );
              })
            ) : (
              <PlaceholderMessage message="No data to display" />
            )}
          </div>
        </>
      )}
    </>
  );
};

export default GroupRow;
