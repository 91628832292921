import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { formNames } from "../../../config";
import { SolidButton } from "../../buttons/solidButton.styled";
import { FormWrapper } from "../doorAddForm/DoorAddForm";
import { toggleForm } from "../../../utils/utilFunctions";
import { FieldInput, FieldLabel, InputBox } from "../FormBase.styled";
import { InputGroup } from "../freshTeamsIntegrationForm/freshTeamsIntegrationForm.styled";
import { useHistory, useLocation } from "react-router-dom";
import { editIntegrationUser } from "../../../redux/integrationUserEdit/integrationUserEditAction";

const IntegrationEmployeeSyncEditForm = () => {
  const closeForm = () => {
    toggleForm(formState, history, location, dispatch, formNames.integrationEmployeeDetailsEditForm);
  };
  let history = useHistory();
  const dispatch = useDispatch();
  const dimentions = useSelector((state) => state.dimentions);
  const formState = useSelector((state) => state.formState);
  const location = useLocation();
  const initialData = useSelector(
    (state) => state.formState[formNames.integrationEmployeeDetailsEditForm]
  );
  const [formData, setFormData] = useState({
    index: -1,
    indentifier: "",
    mobileNo: "",
    email: "",
    firstName: "",
    lastName: "",
    UDId: "",
  });

  useEffect(() => {
    if (initialData) {
      setFormData({ ...initialData });
    }
  }, [initialData]);

  return (
    <FormWrapper dimentions={dimentions} closeForm={closeForm}>
      <h6 className="font-weight-bold mb-4">Edit Users Details</h6>
      <InputGroup dimentions={dimentions}>
        <FieldLabel dimentions={dimentions}>Mobile Number</FieldLabel>
        <FieldInput>
          <InputBox
            type="text"
            // placeholder="Enter Domain name"
            disabled={formData.indentifier === "Mobile Number" ? true : false}
            value={formData.mobileNo}
            onChange={(e) => setFormData({ ...formData, mobileNo: e.target.value })}
          />
        </FieldInput>
      </InputGroup>
      <InputGroup dimentions={dimentions}>
        <FieldLabel dimentions={dimentions}>Email</FieldLabel>
        <FieldInput>
          <InputBox
            type="text"
            disabled={formData.indentifier === "Email Id" ? true : false}
            // placeholder="Enter API Key"
            value={formData.email}
            onChange={(e) => setFormData({ ...formData, email: e.target.value })}
          />
        </FieldInput>
      </InputGroup>
      <InputGroup dimentions={dimentions}>
        <FieldLabel dimentions={dimentions}>First Name</FieldLabel>
        <FieldInput>
          <InputBox
            type="text"
            // placeholder="Enter API Key"
            value={formData.firstName}
            onChange={(e) => setFormData({ ...formData, firstName: e.target.value })}
          />
        </FieldInput>
      </InputGroup>
      <InputGroup dimentions={dimentions}>
        <FieldLabel dimentions={dimentions}>Last Name</FieldLabel>
        <FieldInput>
          <InputBox
            type="text"
            // placeholder="Enter API Key"
            value={formData.lastName}
            onChange={(e) => setFormData({ ...formData, lastName: e.target.value })}
          />
        </FieldInput>
      </InputGroup>
      <InputGroup dimentions={dimentions}>
        <FieldLabel dimentions={dimentions}>UDId</FieldLabel>
        <FieldInput>
          <InputBox
            type="text"
            // placeholder="Enter API Key"
            value={formData.UDId}
            onChange={(e) => setFormData({ ...formData, UDId: e.target.value })}
          />
        </FieldInput>
      </InputGroup>
      <InputGroup dimentions={dimentions}>
        <FieldLabel dimentions={dimentions}></FieldLabel>
        <FieldInput submitButton={true}>
          <SolidButton
            submit={true}
            onClick={(e) => {
              dispatch(editIntegrationUser(formData));
              closeForm();
            }}
          >
            Save
          </SolidButton>
        </FieldInput>
      </InputGroup>
    </FormWrapper>
  );
};

export default IntegrationEmployeeSyncEditForm;
