import React, { useState } from "react";
import { useCookies } from "react-cookie";
import NotificationManager from "react-notifications/lib/NotificationManager";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { rootApi } from "../../../config";
import { get } from "../../../utils/apiService";
import { ATexpired } from "../../../utils/logout";
import { createNotification, notificationType } from "../../../vms/Services/utils";

const OwnershipTransfer = ({ user }) => {
  const [cookies, setCookie, removeCookie] = useCookies(["AT"]);
  const dispatch = useDispatch();
  const history = useHistory();
  const [response, setResponse] = useState(null);

  const changeOwnershipAPI = async (e) => {
    e.preventDefault();
    const url = `${rootApi}operations/changeOwnership?customDeviceId=${e.target.customDeviceId.value}&mobileNo=${e.target.mobileNo.value}`;

    try {
      const response = await get(dispatch, url, user.AT);
      // console.log(response);
      if (!response.data.success) {
        createNotification(NotificationManager, notificationType.error, response.data.error);
      } else {
        // console.log(response);
        setResponse({ ...response.data });
      }
      ATexpired(response, dispatch, removeCookie, history);
    } catch (err) {
      console.log("ownership transfer failed");
    }
  };

  return (
    <div className="py-5 px-2 text-center">
      <form onSubmit={(e) => changeOwnershipAPI(e)}>
        <div className="form-group">
          <input
            placeholder="Device Id"
            type="text"
            className="form-control"
            required
            name="customDeviceId"
          />
        </div>
        <div className="form-group">
          <input placeholder="Mobile No" type="text" className="form-control" required name="mobileNo" />
        </div>
        {response ? <h6>{response.error ? response.error : response.message}</h6> : null}
        <button type="submit" className="btn btn-primary">
          Submit
        </button>
      </form>
    </div>
  );
};

export default OwnershipTransfer;
