import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import NotificationManager from "react-notifications/lib/NotificationManager";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import styled from "styled-components";
import { breakPointWidth, formNames } from "../../config";
import { getPeopleAPI, searchPeopleAPI } from "../../utils/requests";
import { nullChecker, toggleForm } from "../../utils/utilFunctions";
import { FormWrapper } from "./doorAddForm/DoorAddForm";
import { FieldInput, FieldLabel, InputBox, InputGroup } from "./FormBase.styled";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";
import { FcSearch } from "react-icons/fc";
import { PageButton, PageButtonContainer } from "../../paging.styled";
import { SolidButton } from "../buttons/solidButton.styled";
const SearchBox = styled.input`
  border: 1px solid #898989;
  background-color: transparent;
  padding: 0px 0.5rem;
  height: 2rem;
  border-radius: 25px;
  outline: 0;

  opacity: ${(props) => (props.disabled ? "0.7" : "1")};
  background: ${(props) => (props.disabled ? "#C4C4C4" : "")};
  width: ${(props) => (props.dimentions.width > breakPointWidth ? "70%" : "100%")};
  :focus : {
    outline: 0;
  }
`;

const AttendanceUserfilterForm = ({ userIds, setUserIds }) => {
  const [cookies, setCookie, removeCookie] = useCookies(["AT"]);
  let history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const nodes = useSelector((state) => state.nodes);
  const selectedNode = useSelector((state) => state.selectedNode);
  const dimentions = useSelector((state) => state.dimentions);
  const memberships = useSelector((state) => state.memberships);
  const formState = useSelector((state) => state.formState);
  const user = useSelector((state) => state.user);
  const utils = useSelector((state) => state.utils);
  const [displaySearch, setDisplaySearch] = useState(false);
  const [displayValues, setDisplayValues] = useState(memberships);
  const [searchQuery, setSearchQuery] = useState("");
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [noOfPages, setNoOfPages] = useState(1);
  const [tempPage, setTempPage] = useState([1, 1]);

  const updateUserIds = (id, name) => {
    let temp = { ...userIds };
    if (temp[id]) {
      delete temp[id];
    } else temp = { ...temp, [id]: name };

    setUserIds({ ...temp });
  };

  useEffect(() => {
    if (!displaySearch) {
      setDisplayValues(memberships);
    }
  }, [memberships, displaySearch]);

  useEffect(() => {
    getPeopleAPI(
      user,
      dispatch,
      NotificationManager,
      removeCookie,
      history,
      selectedNode,
      utils,
      pageNo,
      pageSize,
      setNoOfPages,
      false
    );
    // eslint-disable-next-line
  }, [nodes, pageNo]);

  const closeForm = () => {
    toggleForm(formState, history, location, dispatch, formNames.attendanceUserFilterForm);
  };
  return (
    <FormWrapper dimentions={dimentions} closeForm={closeForm}>
      <h5 className="font-weight-bold mb-4">Select Users </h5>
      <h6 className="font-weight-bold mb-4">{Object.keys(userIds).length} Selected</h6>
      <InputGroup dimentions={dimentions} noMargin={true}>
        <FieldLabel dimentions={dimentions}></FieldLabel>
        <FieldInput>
          <div className="container">
            <div className="row" style={{ maxHeight: "20vh", overflow: "scroll" }}>
              {Object.keys(userIds).map((uid) => (
                <div
                  key={uid}
                  className="col-sm-12 col-md-4 col-xs-12 col-lg-4 d-flex px-0 mx-0 justify-content-center align-items-center my-2"
                >
                  <span
                    className={`badge badge-pill badge-success px-3 py-2 mr-3 cursor-pointer w-100`}
                    onClick={(e) => updateUserIds(uid, userIds[uid])}
                  >
                    <p className="mb-1">{userIds[uid]}</p>
                    {/* <span> {m.role}</span> */}
                  </span>
                </div>
              ))}
            </div>
          </div>
        </FieldInput>
      </InputGroup>
      <div>
        {displayValues.length ? (
          <InputGroup dimentions={dimentions} noMargin={true}>
            <FieldLabel dimentions={dimentions}>Users</FieldLabel>
            <FieldInput>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <SearchBox
                  dimentions={dimentions}
                  placeholder="Type to search..."
                  value={searchQuery}
                  onChange={(e) => {
                    if (e.target.value === "") {
                      if (displaySearch) {
                        setDisplaySearch(false);
                        setPageNo(tempPage[0]);
                        setNoOfPages(tempPage[1]);
                        setDisplayValues(memberships);
                      }
                    }
                    setSearchQuery(e.target.value);
                  }}
                  onKeyDown={(e) => {
                    if (e.keyCode === 13) {
                      if (!displaySearch) {
                        setTempPage([pageNo, noOfPages]);
                      }
                      setDisplaySearch(true);
                      setPageNo(1);
                      searchPeopleAPI(
                        user,
                        dispatch,
                        NotificationManager,
                        removeCookie,
                        history,
                        selectedNode,
                        searchQuery,
                        utils,
                        1,
                        pageSize,
                        setDisplaySearch,
                        setDisplayValues,
                        setNoOfPages
                      );
                    }
                  }}
                />
                <FcSearch
                  size="1.5rem"
                  style={{ paddingLeft: "0.2rem" }}
                  cursor="pointer"
                  onClick={(e) => {
                    if (!displaySearch) {
                      setTempPage([pageNo, noOfPages]);
                    }
                    setDisplaySearch(true);
                    setPageNo(1);
                    searchPeopleAPI(
                      user,
                      dispatch,
                      NotificationManager,
                      removeCookie,
                      history,
                      selectedNode,
                      searchQuery,
                      utils,
                      1,
                      pageSize,
                      setDisplaySearch,
                      setDisplayValues,
                      setNoOfPages,
                      false
                    );
                  }}
                />
              </div>
              <div className="container" style={{ height: "20vh", overflow: "scroll" }}>
                <div className="row">
                  {displayValues.map((m) => (
                    <div
                      key={m.membershipId}
                      className="col-sm-12 col-md-4 col-xs-12 col-lg-4 d-flex px-0 mx-0 justify-content-center align-items-center my-2"
                    >
                      <span
                        className={`badge badge-pill ${
                          Object.keys(userIds).includes(m.userId) ? "badge-success" : "badge-secondary"
                        } px-3 py-2 mr-3 cursor-pointer w-100`}
                        onClick={(e) =>
                          updateUserIds(
                            m.userId,
                            nullChecker(m.userInfo.firstName) + " " + nullChecker(m.userInfo.lastName)
                          )
                        }
                      >
                        {nullChecker(m.userInfo.firstName) + " " + nullChecker(m.userInfo.lastName)}
                        <p className="mb-1"></p>
                        <span> {m.role}</span>
                      </span>
                    </div>
                  ))}
                </div>
              </div>
              <PageButtonContainer>
                {pageNo > 3 ? (
                  <PageButton
                    onClick={(e) => {
                      if (pageNo - 5 > 0) {
                        setPageNo(pageNo - 5);
                      } else {
                        setPageNo(1);
                      }
                    }}
                  >
                    <FaAngleLeft />
                  </PageButton>
                ) : null}
                {pageNo - 2 > 0 ? (
                  <PageButton
                    onClick={(e) => {
                      setPageNo(pageNo - 2);
                    }}
                  >
                    {pageNo - 2}
                  </PageButton>
                ) : null}
                {pageNo - 1 > 0 ? (
                  <PageButton
                    onClick={(e) => {
                      setPageNo(pageNo - 1);
                    }}
                  >
                    {pageNo - 1}
                  </PageButton>
                ) : null}
                {noOfPages > 1 ? <PageButton selected={true}>{pageNo}</PageButton> : null}
                {noOfPages >= pageNo + 1 ? (
                  <PageButton
                    onClick={(e) => {
                      setPageNo(pageNo + 1);
                    }}
                  >
                    {pageNo + 1}
                  </PageButton>
                ) : null}
                {noOfPages >= pageNo + 2 ? (
                  <PageButton
                    onClick={(e) => {
                      setPageNo(pageNo + 2);
                    }}
                  >
                    {pageNo + 2}
                  </PageButton>
                ) : null}
                {pageNo + 2 < noOfPages ? (
                  <PageButton
                    onClick={(e) => {
                      if (pageNo + 5 <= noOfPages) {
                        setPageNo(pageNo + 5);
                      } else {
                        setPageNo(noOfPages);
                      }
                    }}
                  >
                    <FaAngleRight />
                  </PageButton>
                ) : null}
              </PageButtonContainer>
            </FieldInput>
          </InputGroup>
        ) : null}
        <InputGroup dimentions={dimentions}>
          <FieldLabel dimentions={dimentions}></FieldLabel>
          <FieldInput submitButton={true}>
            <SolidButton submit={true} onClick={(e) => closeForm()}>
              Add
            </SolidButton>
          </FieldInput>
        </InputGroup>
      </div>
    </FormWrapper>
  );
};

export default AttendanceUserfilterForm;
