import React from "react";
import { MdCheckCircle } from "react-icons/md";
export const SuccessWindow = () => {
  return (
    <>
      <div className="ml-auto mr-auto">
        <div className="col-12 mt-5 pt-5">
          <MdCheckCircle style={{ fontSize: "100px", color: "green" }} />
          <h5 className="mt-5">Request submitted Successfully</h5>
        </div>
      </div>
    </>
  );
};
