import {
  EDIT_NODE_INFO_DEPARTMENTS,
  UPDATE_NODE_INFO,
  UPDATE_NODE_INFO_ALERT,
  UPDATE_NODE_INFO_DEPARTMENTS,
  UPDATE_NODE_INFO_PEOPLE_LIMIT,
  UPDATE_NODE_INFO_RESTRICTIONS,
} from "../actionTypes";

const nodeInfoReducer = (nodeInfo = {}, action) => {
  if (action.type === UPDATE_NODE_INFO) {
    return (nodeInfo = action.payload);
  }

  if (action.type === UPDATE_NODE_INFO_ALERT) {
    let keys = Object.keys(action.payload);
    if (keys.includes("peopleLimitAlert")) {
      nodeInfo = {
        ...nodeInfo,
        alerts: {
          ...nodeInfo.alerts,
          peopleLimit: action.payload.peopleLimitAlert,
        },
      };
    }
    if (keys.includes("highTempAlert")) {
      nodeInfo = {
        ...nodeInfo,
        alerts: {
          ...nodeInfo.alerts,
          highTemp: action.payload.highTempAlert,
        },
      };
    }
    if (keys.includes("tailGatingAlert")) {
      nodeInfo = {
        ...nodeInfo,
        alerts: {
          ...nodeInfo.alerts,
          tailGating: action.payload.tailGatingAlert,
        },
      };
    }
  }

  if (action.type === UPDATE_NODE_INFO_RESTRICTIONS) {
    nodeInfo = { ...nodeInfo, ...action.payload };
  }

  if (action.type === UPDATE_NODE_INFO_PEOPLE_LIMIT) {
    nodeInfo = { ...nodeInfo, peopleLimit: action.payload };
  }
  if (action.type === UPDATE_NODE_INFO_DEPARTMENTS) {
    nodeInfo = { ...nodeInfo, departments: action.payload };
  }
  return nodeInfo;
};

export default nodeInfoReducer;
