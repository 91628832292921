import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";
import { FcSearch } from "react-icons/fc";
import { NotificationManager } from "react-notifications";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { apiEndPoints } from "../../config";
import { PageButton, PageButtonContainer } from "../../paging.styled";
import { updateSelectedNode } from "../../redux/selectedNode/selectedNodeActions";
import { getPeopleAPI, searchPeopleAPI, updateRfidViaQR } from "../../utils/requests";
import { nullChecker } from "../../utils/utilFunctions";
import { createNotification, notificationType } from "../../vms/Services/utils";
import { SearchBox } from "../people/peopleRow/PeopleRow";
import {
  FieldLabelMobile,
  FieldValueMobile,
  ZoneLogsUserRowMobileView,
  ZoneLogsUserRowMobileWrapper,
} from "../zone/ZoneLogsUserRow/zoneLogsUserRow.styled";
import LoadingSpinner from "../LoadingSpinner";
const Memberships = ({ params }) => {
  const [cookies, setCookie, removeCookie] = useCookies(["AT"]);
  const user = useSelector((state) => state.user);
  const selectedNode = useSelector((state) => state.selectedNode);
  const nodes = useSelector((state) => state.nodes);
  const dimentions = useSelector((state) => state.dimentions);
  const utils = useSelector((state) => state.utils);
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const [searchQuery, setSearchQuery] = useState("");
  const memberships = useSelector((state) => state.memberships);
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [noOfPages, setNoOfPages] = useState(1);
  const [tempPage, setTempPage] = useState([1, 1]);
  const [displaySearch, setDisplaySearch] = useState(false);
  const [displayValues, setDisplayValues] = useState(null);
  const apiStatus = useSelector((state) => state.apiStatus);

  useEffect(() => {
    if (!displaySearch) {
      setDisplayValues(memberships);
    }
  }, [displaySearch, memberships]);

  useEffect(() => {
    if (nodes && nodes.length && params.nodeId && selectedNode.nodeId !== params.nodeId) {
      const node = nodes.filter((n) => n.nodeId === params.nodeId);
      if (node && node.length) {
        dispatch(
          updateSelectedNode({
            name: node[0].name,
            nodeId: node[0].nodeId,
            parentId: node[0].parentId,
            children: node[0].children,
            expanded: false,
          })
        );
      }
    }
  }, [params.nodeId, selectedNode.nodeId]);

  useEffect(() => {
    if (params.nodeId) {
      if (!displaySearch) {
        getPeopleAPI(
          user,
          dispatch,
          NotificationManager,
          removeCookie,
          history,
          selectedNode,
          utils,
          pageNo,
          pageSize,
          setNoOfPages,
          null,
          ""
        );
      } else {
        searchPeopleAPI(
          user,
          dispatch,
          NotificationManager,
          removeCookie,
          history,
          selectedNode,
          searchQuery,
          utils,
          pageNo,
          pageSize,
          setDisplaySearch,
          setDisplayValues,
          setNoOfPages
        );
      }
    }
  }, [pageNo, pageSize, params.nodeId]);

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-12 d-flex align-items-center justify-content-start m-0 my-3 p-0">
            <SearchBox
              dimentions={dimentions}
              placeholder="Type to search..."
              value={searchQuery}
              onChange={(e) => {
                if (e.target.value === "") {
                  if (displaySearch) {
                    setDisplaySearch(false);
                    setPageNo(tempPage[0]);
                    setNoOfPages(tempPage[1]);
                  }
                }
                setSearchQuery(e.target.value);
              }}
              onKeyDown={(e) => {
                if (e.keyCode === 13) {
                  if (!displaySearch) {
                    setTempPage([pageNo, noOfPages]);
                  }
                  setDisplaySearch(true);
                  setPageNo(1);
                  searchPeopleAPI(
                    user,
                    dispatch,
                    NotificationManager,
                    removeCookie,
                    history,
                    selectedNode,
                    searchQuery,
                    utils,
                    1,
                    pageSize,
                    setDisplaySearch,
                    setDisplayValues,
                    setNoOfPages
                  );
                }
              }}
            />
            <FcSearch
              size="1.5rem"
              style={{ paddingLeft: "0.2rem" }}
              cursor="pointer"
              onClick={(e) => {
                if (!displaySearch) {
                  setTempPage([pageNo, noOfPages]);
                }
                setDisplaySearch(true);
                setPageNo(1);
                searchPeopleAPI(
                  user,
                  dispatch,
                  NotificationManager,
                  removeCookie,
                  history,
                  selectedNode,
                  searchQuery,
                  utils,
                  1,
                  pageSize,
                  setDisplaySearch,
                  setDisplayValues,
                  setNoOfPages
                );
              }}
            />
          </div>

          {apiStatus[apiEndPoints.getPeopleAPI] ||
          apiStatus[apiEndPoints.searchPeopleAPI] ||
          !displayValues ? (
            <LoadingSpinner />
          ) : displayValues && displayValues.length ? (
            <>
              {displayValues.map((i) => (
                <div
                  className="col-sm-12 col-xs-12 p-0 col-md-6 px-md-2"
                  key={i.membershipId}
                  onClick={(e) =>
                    updateRfidViaQR(
                      user,
                      createNotification,
                      notificationType,
                      dispatch,
                      history,
                      location,
                      removeCookie,
                      params.RFCardQRCode,
                      i.userId,
                      params.nodeId
                    )
                  }
                >
                  <ZoneLogsUserRowMobileWrapper>
                    <ZoneLogsUserRowMobileView>
                      <div>
                        <FieldLabelMobile>ID</FieldLabelMobile>
                        <FieldValueMobile>{nullChecker(i.userInfo.customUserId)}</FieldValueMobile>
                      </div>
                      <div>
                        <FieldLabelMobile>Name</FieldLabelMobile>
                        <FieldValueMobile>
                          {nullChecker(i.userInfo.firstName) + " " + nullChecker(i.userInfo.lastName)}
                        </FieldValueMobile>
                      </div>
                      <div>
                        <FieldLabelMobile>User ID</FieldLabelMobile>
                        <FieldValueMobile>{nullChecker(i.UDId)}</FieldValueMobile>
                      </div>
                      <div>
                        <FieldLabelMobile>Role</FieldLabelMobile>
                        <FieldValueMobile>
                          {i.roles.filter((v) => v.nodeId === selectedNode.nodeId).length
                            ? i.roles.filter((v) => v.nodeId === selectedNode.nodeId)[0].role
                            : "-"}
                        </FieldValueMobile>
                      </div>
                    </ZoneLogsUserRowMobileView>
                  </ZoneLogsUserRowMobileWrapper>
                </div>
              ))}
            </>
          ) : (
            <LoadingSpinner />
          )}
        </div>
      </div>

      <div>
        <PageButtonContainer>
          {pageNo > 3 ? (
            <PageButton
              onClick={(e) => {
                if (pageNo - 5 > 0) {
                  setPageNo(pageNo - 5);
                } else {
                  setPageNo(1);
                }
              }}
            >
              <FaAngleLeft />
            </PageButton>
          ) : null}
          {pageNo - 2 > 0 ? (
            <PageButton
              onClick={(e) => {
                setPageNo(pageNo - 2);
              }}
            >
              {pageNo - 2}
            </PageButton>
          ) : null}
          {pageNo - 1 > 0 ? (
            <PageButton
              onClick={(e) => {
                setPageNo(pageNo - 1);
              }}
            >
              {pageNo - 1}
            </PageButton>
          ) : null}
          {noOfPages > 1 ? <PageButton selected={true}>{pageNo}</PageButton> : null}
          {noOfPages >= pageNo + 1 ? (
            <PageButton
              onClick={(e) => {
                setPageNo(pageNo + 1);
              }}
            >
              {pageNo + 1}
            </PageButton>
          ) : null}
          {noOfPages >= pageNo + 2 ? (
            <PageButton
              onClick={(e) => {
                setPageNo(pageNo + 2);
              }}
            >
              {pageNo + 2}
            </PageButton>
          ) : null}
          {pageNo + 2 < noOfPages ? (
            <PageButton
              onClick={(e) => {
                if (pageNo + 5 <= noOfPages) {
                  setPageNo(pageNo + 5);
                } else {
                  setPageNo(noOfPages);
                }
              }}
            >
              <FaAngleRight />
            </PageButton>
          ) : null}
        </PageButtonContainer>
      </div>
    </>
  );
};

export default Memberships;
