import React, { useState } from "react";
import { confirmAlert } from "react-confirm-alert";
import { useCookies } from "react-cookie";
import NotificationManager from "react-notifications/lib/NotificationManager";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { updateNodeInfoAPI } from "../../../utils/requests";
import GreenSwitch from "../../switches/GreenSwitch";
import {
  ToggleBox,
  ToggleTitle,
} from "../../zone/zoneCards/zoneNotificationsCard/zoneNotificationCard.styled";

const tabStatusConstants = {
  "OAC Notifications": "OAC Notifications",
  OAC: "OAC",
};

const OACFORM = ({ user }) => {
  const [tabStatus, setTabStatus] = useState(tabStatusConstants.OAC);

  return (
    <div className="py-5 px-2 text-center">
      <div className="dFlex aic mb-4">
        <div className="d-flex">
          <div style={{ position: "relative" }}>
            <h5
              style={{
                borderBottom: `${tabStatus === tabStatusConstants.OAC ? "2px solid black" : ""}`,
                margin: "0 .5rem 0 0",
                textDecoration: "none",
                color: `${tabStatus === tabStatusConstants.OAC ? "black" : "grey"}`,
              }}
              className="font-weight-bold cursor-pointer"
              onClick={(e) => setTabStatus(tabStatusConstants.OAC)}
            >
              {tabStatusConstants.OAC}
            </h5>
          </div>
        </div>
        <div className="d-flex">
          <div style={{ position: "relative" }}>
            <h5
              style={{
                borderBottom: `${
                  tabStatus === tabStatusConstants["OAC Notifications"] ? "2px solid black" : ""
                }`,
                margin: "0 .5rem 0 .5rem",
                textDecoration: "none",
                color: `${tabStatus === tabStatusConstants["OAC Notifications"] ? "black" : "grey"}`,
              }}
              className="font-weight-bold cursor-pointer"
              onClick={(e) => setTabStatus(tabStatusConstants["OAC Notifications"])}
            >
              {tabStatusConstants["OAC Notifications"]}
            </h5>
          </div>
        </div>
      </div>
      {tabStatus === tabStatusConstants.OAC ? <OAC user={user} /> : <OACNotification user={user} />}
    </div>
  );
};

export default OACFORM;

const OAC = ({ user }) => {
  const [cookies, setCookie, removeCookie] = useCookies(["AT"]);
  const dispatch = useDispatch();
  const history = useHistory();
  const nodeInfo = useSelector((state) => state.nodeInfo);
  const selectedNode = useSelector((state) => state.selectedNode);
  const [reqBody, setReqBody] = useState({
    enableOAC: nodeInfo.enableOAC,
    updateForChildNodes: false,
  });
  const onSubmit = () => {
    updateNodeInfo(
      `This action would ${!reqBody.enableOAC ? "disable" : "enable"} unlocking device using Key codes.`,
      reqBody
    );
  };
  const updateNodeInfo = async (description, reqBody) => {
    let body = { nodeId: selectedNode.nodeId, ...reqBody };
    confirmAlert({
      title: "Confirm!",
      message: description,
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            updateNodeInfoAPI(
              user,
              dispatch,
              NotificationManager,
              removeCookie,
              history,
              selectedNode,
              body
            );
          },
        },
        {
          label: "No",
          onClick: () => null,
        },
      ],
    });
  };
  return (
    <>
      <ToggleBox>
        <ToggleTitle>Keycode OAC</ToggleTitle>
        <GreenSwitch
          checked={reqBody.enableOAC}
          onChange={(e) => setReqBody({ ...reqBody, enableOAC: e.target.checked })}
        />
      </ToggleBox>
      <ToggleBox>
        <ToggleTitle>Update For Child Nodes</ToggleTitle>
        <GreenSwitch
          checked={reqBody.updateForChildNodes}
          onChange={(e) => setReqBody({ ...reqBody, updateForChildNodes: e.target.checked })}
        />
      </ToggleBox>
      <button onClick={onSubmit} className="btn btn-primary mt-5">
        Submit
      </button>
    </>
  );
};

const OACNotification = ({ user }) => {
  const [cookies, setCookie, removeCookie] = useCookies(["AT"]);
  const dispatch = useDispatch();
  const history = useHistory();
  const nodeInfo = useSelector((state) => state.nodeInfo);
  const selectedNode = useSelector((state) => state.selectedNode);
  const [reqBody, setReqBody] = useState({
    enableOACNotification: nodeInfo.enableOACNotification,
    updateForChildNodes: false,
  });
  const onSubmit = () => {
    let body = { nodeId: selectedNode.nodeId, ...reqBody };
    updateNodeInfoAPI(user, dispatch, NotificationManager, removeCookie, history, selectedNode, body);
  };

  return (
    <>
      <ToggleBox>
        <ToggleTitle>Enable Notifications for Keycodes</ToggleTitle>
        <GreenSwitch
          checked={reqBody.enableOACNotification}
          onChange={(e) => setReqBody({ ...reqBody, enableOACNotification: e.target.checked })}
        />
      </ToggleBox>
      <ToggleBox>
        <ToggleTitle>Update For Child Nodes</ToggleTitle>
        <GreenSwitch
          checked={reqBody.updateForChildNodes}
          onChange={(e) => setReqBody({ ...reqBody, updateForChildNodes: e.target.checked })}
        />
      </ToggleBox>

      <button onClick={onSubmit} className="btn btn-primary mt-5">
        Submit
      </button>
    </>
  );
};
