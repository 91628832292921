import React, { useState } from "react";
import { useCookies } from "react-cookie";
import NotificationManager from "react-notifications/lib/NotificationManager";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { rfCardAdd } from "../../../utils/requests";

const RFFORM = ({ user }) => {
  const [cookies, setCookie, removeCookie] = useCookies(["AT"]);
  const dispatch = useDispatch();
  const history = useHistory();
  const [response, setResponse] = useState(null);

  return (
    <div className="py-5 px-2 text-center">
      {response ? (
        <div className="text-center">
          <h4 className={`${response.success ? "text-success" : "text-danger"} my-4 font-weight-bold`}>
            {response.message ? response.message : response.error}
          </h4>
          <h6>Custom RFID : {response.customRFId}</h6>
          <button className="btn btn-sm btn-info" onClick={(e) => setResponse(null)}>
            Add Another
          </button>
        </div>
      ) : (
        <form
          onSubmit={(e) =>
            rfCardAdd(e, user, dispatch, NotificationManager, removeCookie, history, setResponse)
          }
        >
          <div className="form-group">
            <input
              placeholder="RF Card Id"
              type="text"
              className="form-control"
              required
              name="cardIdentifier"
            />
          </div>

          <button type="submit" className="btn btn-primary">
            Submit
          </button>
        </form>
      )}
    </div>
  );
};

export default RFFORM;
