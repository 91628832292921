import React, { useState } from "react";
import { useCookies } from "react-cookie";
import NotificationManager from "react-notifications/lib/NotificationManager";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { rootApi } from "../../../config";
import { get, post } from "../../../utils/apiService";
import { ATexpired } from "../../../utils/logout";
import { createNotification, notificationType } from "../../../vms/Services/utils";
import { jsPDF } from "jspdf";
import QRCode from "qrcode";

const RFIDQrCode = ({ user }) => {
  const [cookies, setCookie, removeCookie] = useCookies(["AT"]);
  const dispatch = useDispatch();
  const history = useHistory();
  const [response, setResponse] = useState(null);
  const [downloading, setDownloading] = useState(false);
  const [loading, setLoading] = useState(false);

  const getRFIDQRCodes = async (e) => {
    setLoading(true);
    setResponse(null);
    setDownloading(false);
    e.preventDefault();
    const url = `${rootApi}RFCard/generateQRCodes`;
    const body = {
      numberOfQRCodes: e.target.numberOfQRCodes.value,
    };
    try {
      const response = await post(dispatch, url, body, user.AT);

      if (!response.data.success) {
        createNotification(NotificationManager, notificationType.error, response.data.error);
        setLoading(false);
      } else {
        // success, RFCards: [{RFCardQRCode, customRFId}], numberOfQRCodes
        const { error, success, message, RFCards, numberOfQRCodes } = response.data;
        setResponse({ error, success, message, RFCards, numberOfQRCodes });
        setLoading(false);
      }
      ATexpired(response, dispatch, removeCookie, history);
    } catch (err) {
      console.log("unable to fetch RFID qr codes");
    }
  };

  const downloadAllQRCode = async () => {
    setDownloading(`Processing`);
    const format = [41.5, 25.8];
    let doc = new jsPDF({
      orientation: "p",
      unit: "in",
      format,
    });
    const posterUrl = "https://securepass-static-assets.s3.ap-south-1.amazonaws.com/stanza_rfid_qr.svg";
    // `${cloudFrontOrigin}50182.svg`;
    const canvas = document.getElementById(`myCanvas23`);
    // append qr image to canvas
    const context = canvas.getContext("2d");
    const bg = new Image();
    bg.src = posterUrl;
    bg.setAttribute("crossorigin", "anonymous");
    bg.onload = function () {
      response.RFCards.forEach(async ({ RFCardQRCode, customRFId }, i) => {
        setDownloading(`Processing ${i + 1} of ${response.RFCards.length}`);
        QRCode.toDataURL(`https://dashboard.thesecurepass.com/assignRFID?RFCardQRCode=${RFCardQRCode}`, {
          errorCorrectionLevel: "H",
          type: "image/jpeg",
          quality: 1,
          margin: 3,
          color: {
            dark: "#000000",
            light: "#cdefe8",
          },
        })
          .then((qrurl) => {
            loadImage(qrurl)
              .then((qr) => {
                context.drawImage(bg, 0, 0);
                context.drawImage(qr, 576.42, 1383.08, 1347.16, 1347.16);
                context.font = "bold 180px Roboto white";
                context.fillStyle = "#ffffff";
                context.textAlign = "center";
                context.fillText(customRFId, canvas.width / 2, 3850);
                const pngUrl = canvas.toDataURL("image/jpeg");
                if (i > 0) {
                  doc.addPage(format, "p");
                }
                doc.addImage(pngUrl, "JPEG", 0, 0);
                setDownloading(`Processed ${i + 1} of ${response.RFCards.length}`);
                if (i === response.RFCards.length - 1) {
                  doc.save("allQRS.pdf", { returnPromise: true }).then(() => {
                    setDownloading("PDF file downloaded successfully...");
                  });
                  setDownloading("Preparing PDF for downloading, please wait..");
                }
                context.clearRect(0, 0, canvas.width, canvas.height);
              })
              .catch((error) => console.log(error, i));
          })
          .catch((error) => console.log(error));
      });
    };
  };

  const loadImage = (url) => {
    return new Promise((resolve, reject) => {
      const qr = new Image(1347.16, 1347.16);
      qr.addEventListener("load", () => {
        resolve(qr);
      });
      qr.addEventListener("error", () => {
        reject("error loading image (QR)");
      });

      qr.width = 1347.16;
      qr.height = 1347.16;
      qr.setAttribute("crossorigin", "anonymous");
      qr.src = url;
    });
  };

  return (
    <div className="py-5 px-2 text-center">
      <form onSubmit={getRFIDQRCodes}>
        <div className="form-group">
          <input
            placeholder="No of RFIDs to be generated"
            type="text"
            className="form-control"
            required
            name="numberOfQRCodes"
          />
        </div>

        <button type="submit" disabled={loading} className="btn btn-primary">
          {loading ? "Loading" : "Submit"}
        </button>
      </form>
      <p className="mt-4 text-danger font-weight-bold">
        Please wait before making another request this request may take a while
        <br />
        Maximium 1000 cards can be generated in one attempt
        <br />
        Please make sure to download QRs after making a request if something goes wrong in between please
        report to development team.
      </p>
      {response ? (
        <>
          <div className="text-center">
            <h4 className={`${response.success ? "text-success" : "text-danger"} my-4 font-weight-bold`}>
              QR Codes Fetched
            </h4>
          </div>
          <div className="container border border-sm rounded shadow shadow-sm">
            <div className="row">
              {response.RFCards.length ? (
                <>
                  <div className="col-12 my-4 text-center">
                    {downloading ? (
                      <p>{downloading}</p>
                    ) : response.RFCards.length === response.numberOfQRCodes ? (
                      <p className="text-success"> {response.RFCards.length} qrcodes fetched</p>
                    ) : (
                      <p className="text-warning">
                        {" "}
                        {response.RFCards.length} out of {response.numberOfQRCodes} qrcodes fetched
                      </p>
                    )}
                  </div>
                  <div className="col-12 my-4">
                    <button
                      className="w-100 btn btn-warning btn-sm"
                      style={{ outline: "0" }}
                      id="downloadButton"
                      onClick={(e) => downloadAllQRCode()}
                    >
                      Download All
                    </button>
                  </div>
                </>
              ) : (
                <div className="col-12">
                  <h4 className={`${"text-danger"} text-center my-4 font-weight-bold`}>
                    {response.message}
                  </h4>
                </div>
              )}
            </div>
          </div>
        </>
      ) : null}
      <canvas style={{ display: "none" }} id={`myCanvas23`} width="2500" height="3981.06"></canvas>
    </div>
  );
};

export default RFIDQrCode;
