import React from "react";
import { useSelector } from "react-redux";
import styled, { css } from "styled-components";
import { breakPointWidth } from "../config";
import { FieldLabelMobile } from "./zone/ZoneLogsUserRow/zoneLogsUserRow.styled";

const LeftSidebar = ({ children, dimentions }) => {
  const sidebarStatus = useSelector((state) => state.sidebarStatus);
  return (
    <LeftSideBarContainer sidebarStatus={sidebarStatus} dimentions={dimentions}>
      <div className="d-flex h-100 flex-column justify-content-between">
        {children}

        <FieldLabelMobile className="text-center">&copy; 2021 All Rights Reserved</FieldLabelMobile>
      </div>
    </LeftSideBarContainer>
  );
};

export default LeftSidebar;

export const LeftSideBarContainer = styled.div`
  ${(props) =>
    props.dimentions.width > breakPointWidth
      ? css`
          grid-area: leftsidebar;
          border-right: 1px solid #d1d1d1;
          height: 100vh;
          z-index: 50;
          width: 15vw;
          padding: 2rem 2rem;
        `
      : css`
          transition: all 0.2s ease;
          background-color: white;
          z-index: 50;
          height: 100vh;
          width: 100vw;
          ${props.sidebarStatus.left
            ? "transform : translate(0px ,0px);"
            : "transform : translate(-100%, 0px);"}
          padding: 2rem 2rem;
          position: fixed;
        `}
`;

export const IconContainer = styled.p`
  cursor: pointer;
  font-size: 1.3rem;
  margin: 0px 0px;
  margin-left 0.5rem;
  margin-bottom: 5px;
  color: #49eecc;
`;
