import styled from "styled-components";

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem 1rem;
  background-color: #ffffff;
  border: 0.5px solid #e8e8e8;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  border-radius: 10px;
  margin-bottom: 1rem;
`;

export const SolidButton = styled.button`
  border: 2px solid #49eecc;
  border-radius: 2rem;
  background-color: transparent;
  color: grey;
  margin-left: 1rem;
  outline: none;
  cursor: pointer;
  margin-top: 10px;
  padding: 0.2rem 0.5rem;
  font-size: 0.8rem;
  :hover {
    border: 2px solid #49eecc;
    color: #353535;
    background-color: #49eecc;
    outline: none;
  }
`;
