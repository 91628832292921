import React, { useEffect, useRef, useState } from "react";

const useIntersectionAndHover = () => {
  const [hovered, setHovered] = useState(false);
  const [inView, setInView] = useState(false);
  const ref = useRef(null);
  const options = {
    root: null,
    rootMargin: "0px",
    threshold: 1.0,
  };

  const callbackFunction = (entries) => {
    const [entry] = entries;
    setInView(entry.isIntersecting);
  };

  const handleMouseOver = () => setHovered(true);
  const handleMouseOut = () => setHovered(false);
  useEffect(
    () => {
      const node = ref.current;
      const observer = new IntersectionObserver(callbackFunction, options);
      if (node) {
        node.addEventListener("mouseover", handleMouseOver);
        node.addEventListener("mouseout", handleMouseOut);
        observer.observe(node);
        return () => {
          node.removeEventListener("mouseover", handleMouseOver);
          node.removeEventListener("mouseout", handleMouseOut);
          observer.unobserve(node);
        };
      }
    },
    [ref.current] // Recall only if ref changes
  );
  return [ref, hovered, inView];
};

export default useIntersectionAndHover;
