import { UPDATE_SELECTED_NODE_HIERARCHY } from "../actionTypes";

const selectedNodeHierarchyReducer = (selectedNodeHierarchy = {}, action) => {
  if (action.type === UPDATE_SELECTED_NODE_HIERARCHY) {
    return (selectedNodeHierarchy = action.payload);
  }

  return selectedNodeHierarchy;
};

export default selectedNodeHierarchyReducer;
