import React, { useEffect, useState } from "react";
import {
  ZoneDepartmentCardContainer,
  Title,
  ItemWrapper,
  ItemTitle,
  ActionButtonsWrapper,
} from "./ZoneDepartmentCard.styled";
import NotificationManager from "react-notifications/lib/NotificationManager";
import { TiDeleteOutline } from "react-icons/ti";
import { IconContainer, OverFLowScrollBox } from "../../../App.styled";
import { SolidButton } from "../../../buttons/solidButton.styled";
import { BiEdit } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import { useCookies } from "react-cookie";
import { useHistory, useLocation } from "react-router-dom";
import { toggleForm } from "../../../../utils/utilFunctions";
import { formNames } from "../../../../config";
import { ZoneAccessCardSubtitle } from "../zoneAccessCard/zoneAccessCard.styled";
import { removeDepartmentAPI } from "../../../../utils/requests";
import { confirmAlert } from "react-confirm-alert";

const ZoneDepartmentCard = () => {
  const [showDelete, setShowDelete] = useState(false);
  let history = useHistory();
  const dispatch = useDispatch();
  const [cookies, setCookie, removeCookie] = useCookies(["AT"]);
  const nodeInfo = useSelector((state) => state.nodeInfo);
  const user = useSelector((state) => state.user);
  const utils = useSelector((state) => state.utils);
  const selectedNode = useSelector((state) => state.selectedNode);
  const formState = useSelector((state) => state.formState);
  const location = useLocation();

  return (
    <ZoneDepartmentCardContainer>
      {/* {showAddAccessForm ? (
        <AddAccessForm
          setUsersToAlert={setUsersToAlert}
          usersToAlert={usersToAlert}
          setShowAddAccessForm={setShowAddAccessForm}
        />
      ) : null} */}
      <Title>Departments</Title>
      <div style={{ height: "15vh" }}>
        <OverFLowScrollBox>
          {nodeInfo && nodeInfo.departments ? (
            nodeInfo.departments.map((v) => {
              return (
                <ItemWrapper>
                  <ItemTitle>{v.name}</ItemTitle>
                  <ActionButtonsWrapper>
                    <IconContainer>
                      <BiEdit
                        className="m-0 p-0"
                        onClick={(e) => {
                          console.log(v.depId);
                          toggleForm(formState, history, location, dispatch, formNames.departmentForm, {
                            depId: v.depId,
                          });
                        }}
                      />
                    </IconContainer>
                    {showDelete ? (
                      <IconContainer>
                        <TiDeleteOutline
                          className="text-danger m-0 p-0"
                          onClick={(e) =>
                            removeDepartmentAPI(
                              user,
                              dispatch,
                              NotificationManager,
                              removeCookie,
                              history,
                              confirmAlert,
                              selectedNode,
                              utils,
                              v.depId
                            )
                          }
                        />
                      </IconContainer>
                    ) : null}
                  </ActionButtonsWrapper>
                </ItemWrapper>
              );
            })
          ) : (
            <div className="h-100 w-100 d-flex justify-content-center align-items-center">
              <ZoneAccessCardSubtitle>No Data</ZoneAccessCardSubtitle>
            </div>
          )}
        </OverFLowScrollBox>
      </div>
      <div className="d-flex align-items-center justify-content-end mt-4">
        <SolidButton
          onClick={(e) => toggleForm(formState, history, location, dispatch, formNames.departmentForm)}
        >
          Add Department
        </SolidButton>
        <IconContainer>
          <BiEdit onClick={(e) => setShowDelete(!showDelete)} className="m-0 p-0" />
        </IconContainer>
      </div>
    </ZoneDepartmentCardContainer>
  );
};

export default ZoneDepartmentCard;
