import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SolidButton } from "../../buttons/solidButton.styled";
import GreenSwitch from "../../switches/GreenSwitch";
import { FormWrapper } from "../doorAddForm/DoorAddForm";
import { useCookies } from "react-cookie";
import { FieldInput, FieldLabel, InputBox, InputGroup } from "../FormBase.styled";
import { useHistory, useLocation } from "react-router-dom";
import NotificationManager from "react-notifications/lib/NotificationManager";
import { deviceConfigUpdate, getDeviceConfig } from "../../../utils/requests";
import { apiEndPoints, formNames } from "../../../config";
import { toggleForm } from "../../../utils/utilFunctions";
import { ToggleBox } from "../../zone/zoneCards/zoneDeviceRestrictionCard/ZoneDeviceRestrictionCard.styles";
import { ToggleTitle } from "../../zone/zoneCards/zoneNotificationsCard/zoneNotificationCard.styled";
import { FaSpinner } from "react-icons/fa";
import ButtonState from "../../ButtonState/ButtonState";

const DeviceConfigForm = () => {
  const [cookies, setCookie, removeCookie] = useCookies(["AT"]);
  let history = useHistory();
  const location = useLocation();
  const selectedNode = useSelector((state) => state.selectedNode);
  const [deviceId, setDeviceId] = useState(null);
  const formState = useSelector((state) => state.formState);
  const dimentions = useSelector((state) => state.dimentions);
  const user = useSelector((state) => state.user);
  const [gateOpenAlertDuration, setGateOpenAlertDuation] = useState(0);
  const apiStatus = useSelector((state) => state.apiStatus);
  const dispatch = useDispatch();
  const [config, setConfig] = useState(null);

  const closeForm = () => {
    console.log("clicked");

    toggleForm(formState, history, location, dispatch, formNames.deviceConfigForm);
  };

  const handleChange = (f, v) => {
    deviceConfigUpdate(
      user,
      dispatch,
      NotificationManager,
      removeCookie,
      history,
      setConfig,
      selectedNode,
      deviceId.deviceId,
      { [f]: v }
    );
  };

  useEffect(() => {
    if (deviceId) {
      getDeviceConfig(
        user,
        dispatch,
        NotificationManager,
        removeCookie,
        history,
        setConfig,
        selectedNode,
        deviceId.deviceId
      );
    }
  }, [deviceId]);

  useEffect(() => {
    if (config) setGateOpenAlertDuation(config.GOAD / 1000);
  }, [config]);

  useEffect(() => {
    setDeviceId(formState[formNames.deviceConfigForm]);
  }, [formState]);

  return (
    <FormWrapper dimentions={dimentions} closeForm={closeForm} width={"30%"}>
      <h6 className="font-weight-bold mb-4">
        Device Configuration for - {deviceId ? deviceId.customDeviceId : ""}
      </h6>
      {config && deviceId ? (
        <>
          <InputGroup dimentions={dimentions}>
            <FieldLabel dimentions={dimentions}></FieldLabel>
            <FieldInput>
              <ToggleBox>
                <ToggleTitle>Bypass Temperature Scanner</ToggleTitle>
                <GreenSwitch
                  checked={config.BTS}
                  onChange={(e) => handleChange("bypassTemperatureScanner", e.target.checked)}
                />
              </ToggleBox>
            </FieldInput>
          </InputGroup>
          <InputGroup dimentions={dimentions}>
            <FieldLabel dimentions={dimentions}></FieldLabel>
            <FieldInput>
              <ToggleBox>
                <ToggleTitle>Allow if Temperature High</ToggleTitle>
                <GreenSwitch
                  checked={config.AITH}
                  onChange={(e) => handleChange("allowIfTemperatureHigh", e.target.checked)}
                />
              </ToggleBox>
            </FieldInput>
          </InputGroup>
          <InputGroup dimentions={dimentions}>
            <FieldLabel dimentions={dimentions}></FieldLabel>
            <FieldInput>
              <ToggleBox>
                <ToggleTitle>Gate Open Alert</ToggleTitle>
                <GreenSwitch
                  checked={config.GOA}
                  onChange={(e) => handleChange("gateOpenAlert", e.target.checked)}
                />
              </ToggleBox>
            </FieldInput>
          </InputGroup>
          <InputGroup dimentions={dimentions}>
            <FieldLabel dimentions={dimentions}></FieldLabel>
            <FieldInput>
              <ToggleBox>
                <ToggleTitle>Gate Open Alert Duration (Seconds)</ToggleTitle>
              </ToggleBox>
            </FieldInput>
          </InputGroup>
          <InputGroup dimentions={dimentions} className="p-0">
            <FieldLabel dimentions={dimentions} className="p-0"></FieldLabel>
            <FieldInput>
              <ToggleBox>
                <ToggleTitle className="p-0"></ToggleTitle>
                <>
                  <InputBox
                    className="my-0"
                    type="number"
                    placeholder="Enter duration in seconds..."
                    value={parseInt(gateOpenAlertDuration)}
                    onChange={(e) => setGateOpenAlertDuation(e.target.value)}
                  />
                  <SolidButton
                    onClick={(e) =>
                      handleChange("gateOpenAlertDuration", parseInt(gateOpenAlertDuration * 1000))
                    }
                  >
                    <ButtonState
                      loading={apiStatus[apiEndPoints.deviceConfigUpdate]}
                      innerText={"Update"}
                    />
                  </SolidButton>
                </>
              </ToggleBox>
            </FieldInput>
          </InputGroup>
          <InputGroup dimentions={dimentions}>
            <FieldLabel dimentions={dimentions}></FieldLabel>
            <FieldInput>
              <ToggleBox>
                <ToggleTitle> Bypass Proximity Scanner</ToggleTitle>
                <GreenSwitch
                  checked={config.BPS}
                  onChange={(e) => handleChange("bypassProximityScanner", e.target.checked)}
                />
              </ToggleBox>
            </FieldInput>
          </InputGroup>
          <InputGroup dimentions={dimentions}>
            <FieldLabel dimentions={dimentions}></FieldLabel>
            <FieldInput>
              <ToggleBox>
                <ToggleTitle>Bypass Access Control</ToggleTitle>
                <GreenSwitch
                  checked={config.BAC}
                  onChange={(e) => handleChange("bypassAccessControl", e.target.checked)}
                />
              </ToggleBox>
            </FieldInput>
          </InputGroup>
          <InputGroup dimentions={dimentions}>
            <FieldLabel dimentions={dimentions}></FieldLabel>
            <FieldInput>
              <ToggleBox>
                <ToggleTitle>Exit Authentication</ToggleTitle>
                <GreenSwitch
                  checked={config.EA}
                  onChange={(e) => handleChange("exitAuthentication", e.target.checked)}
                />
              </ToggleBox>
            </FieldInput>
          </InputGroup>
        </>
      ) : (
        <>
          <FaSpinner />
        </>
      )}
    </FormWrapper>
  );
};

export default DeviceConfigForm;

//  Bypass Temperature Scanner
//  Allow if Temperature High
//  Gate Open Alert Duration
//  Bypass Proximity Scanner
//  Bypass Access Control
//  Exit Authentication
//  Gate Open Alert
