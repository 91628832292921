import styled from "styled-components";

export const NavigationHeaderBox = styled.div`
  width: 100vw;
  height: 8vh;
  position: fixed;
  border-bottom: 1px solid grey;
  background-color: white;
  top: 0;
  left: 0;
  z-index: 55;
  padding: 0rem 2.2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
