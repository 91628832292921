import styled, { css } from "styled-components";
import { breakPointWidth } from "../../config";

export const NotificationBackground = styled.div`
  height: ${(props) => (props.dimentions.width > breakPointWidth ? "100vh" : "92vh")};
  width: 100vw;
  position: fixed;
  top: ${(props) => (props.dimentions.width > breakPointWidth ? "0" : "8vh")};
  left: 0;
  background-color: ${(props) =>
    props.dimentions.width > breakPointWidth ? "transparent" : "rgb(255, 255, 255)"};
  display: flex;
  justify-content: end;
  align-items: start;
  z-index: 99;
`;

export const NotificationContainer = styled.div`
  /* padding: ${(props) => (props.dimentions.width > breakPointWidth ? "2rem 2rem;" : "1rem 1rem;")} */
  height: ${(props) => (props.dimentions.width > breakPointWidth ? "55vh;" : "92vh;")}
  width: ${(props) =>
    props.dimentions.width > breakPointWidth ? (props.width ? `${props.width};` : "30%;") : "100%;"}
  position: fixed;
  top: ${(props) => (props.dimentions.width > breakPointWidth ? "20px" : "8vh")};
  right : ${(props) => (props.dimentions.width > breakPointWidth ? "20px" : "0px")};
  background: #ffffff;
  box-shadow: ${(props) =>
    props.dimentions.width > breakPointWidth
      ? "0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12)"
      : ""};
  border-radius: ${(props) => (props.dimentions.width > breakPointWidth ? "15px;" : "0px;")}
  z-index: 10000;
`;

export const NotificationBox = styled.div`
  overflow-y: scroll;
  /* padding: ${(props) =>
    props?.dimentions?.width > breakPointWidth ? "0px 2rem 0px 0px;" : "0px 1rem 0px 0px;"}; */
  /* max-height: ${(props) => (props.dimentions.width > breakPointWidth ? "75vh;" : "80vh;")}; */
  height: ${(props) => (props.dimentions.width > breakPointWidth ? "40vh;" : "80vh;")};
`;

export const NotificationBoxHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #c3c3c3;
  /* padding: ${(props) =>
    props.dimentions.width > breakPointWidth ? "1rem 1rem;" : "0.5rem 0.5rem;"} */
  padding: 1rem 1rem;
`;

export const NotificationTextContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  border-bottom: 1px solid #c3c3c3;
  /* padding: ${(props) =>
    props.dimentions.width > breakPointWidth ? "1rem 1rem;" : "0.5rem 0.5rem;"}; */
  padding: 1rem 1rem;
  color: black;
`;

export const NotificationLink = styled.a`
  text-decoration: none !important;
  :hover {
    background-color: rgba(218, 223, 225, 1);
  }
`;
