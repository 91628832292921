import styled from "styled-components";

export const ZoneStatusCardContainer = styled.div`
  padding: 1rem 1rem;
  background-color: #ffffff;
  border: 0.5px solid #e8e8e8;
  max-height : 75vh;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  border-radius: 10px;
  margin-bottom: 1rem;
`;

export const Floor = styled.p`
  font-size: 1.2rem;
  margin-bottom: 1.5rem;
  font-weight: bold;
  font-style: normal;
  line-height: 1.5rem;
  color: #353535;
`;

export const Entrance = styled.p`
  font-size: 1.2rem;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  margin-right: 0.5rem;
  font-weight: bold;
  font-style: normal;
  line-height: 1.5rem;
  color: #43424b;
`;

export const EntranceStatusBadge = styled.div`
  display: flex;
  align-items: center;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  justify-content: center;
  font-size: 0.8rem;
  font-weight: bold;
  font-style: normal;
  padding: 1px 3px;
  border-radius: 5px;
  background-color: ${(props) => (props.open ? "#E53D54" : "#00CE6B")};
  color: #ffffff;
`;

export const Device = styled.p`
  color: #898989;
  margin: 0.5rem 0px;
  font-size: 0.8rem;
  margin-right: 1rem;
  font-style: normal;
`;

export const DeviceId = styled.p`
  color: #898989;
  font-size: 0.8rem;
  font-weight: lighter;
  font-style: normal;
  margin: 0.5rem 0px;
  text-decoration: underline;
`;
