import styled, { css } from "styled-components";

export const RowContainer = styled.div`
  border: 0.5px solid #e8e8e8;
  box-shadow: 0px 0.2rem 0.2rem 0.2rem rgba(0, 0, 0, 0.06);
  border-radius: 5px;
  height: 30vh;
  position: relative;
  padding-bottom : 30px;
  margin-bottom:10px;
`;

export const FormContainer = styled.div`
border: 0.5px solid #e8e8e8;
box-shadow: 0px 0.2rem 0.2rem 0.2rem rgba(0, 0, 0, 0.06);
border-radius: 5px;
margin-bottom: 20px;
padding-bottom: 5px;
position: relative;
:hover {
  cursor: pointer;
}
`;

export const FormContainerMobile = styled.div`
  padding: 0.5rem;
  border: 0.5px solid #e8e8e8;
  box-shadow: 0px 0.2rem 0.2rem 0.2rem rgba(0, 0, 0, 0.06);
  border-radius: 5px;
  margin-bottom: 20px;
  position: relative;
  :hover {
    cursor: pointer;
}
`;

export const RowStyle = styled.div`
  display: grid;
  grid-row-gap: 0.5rem;
  padding: 0.5rem 1rem;
  grid-template-columns: 2fr 0.25fr 1.5fr 1.5fr 1.5fr 1fr 2fr 2fr 0.25fr;
  grid-template-areas: "Code EditCode Type NodeName DoorName Direction StartTime EndTime Delete";

  ${(props) =>
    props.active&&!props.disabled
      ? css`
          background-image: linear-gradient(91deg, #fe6dad -3%, #fc9f35);
        `
      : css``}
  ${(props) =>
    props.isHead
      ? css`
          font-weight: bold;
          padding-right: 1.5rem;
        `
      :!props.disabled && css`
          :hover {
            background-image: linear-gradient(91deg, #fe6dad -3%, #fc9f35);
            color : white;
          }
        `}
  ${(props)=>
    props.disabled?
    css`
      opacity: 0.5;
      background: linear-gradient(0deg, #606060, #696969);
      pointer-events:initial;
      color:white;
    `:css``}
  
`;

export const Code = styled.div`
  display: flex;
  grid-area: "Code";
  text-align: center;
  justify-content: center;
  align-items: center;
`;

export const EditCode = styled.div`
grid-area: "EditCode";
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  `;

export const Type = styled.div`
  display: flex;
  grid-area: "Type";
  justify-content: center;
  align-items: center;
`;

export const DoorName = styled.div`
  grid-area: "DoorName";
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
`;
export const NodeName = styled.div`
  grid-area: "NodeName";
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
`;
export const Direction = styled.div`
  grid-area: "Direction";
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const StartTime = styled.div`
  grid-area: "StartTime";
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const EndTime = styled.div`
  grid-area: "EndTime";
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Delete = styled.div`
  grid-area: "Delete";
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  `;

export const Button = styled.button`
  justify-self: center;
  align-self: center;
  cursor: pointer;
  font-size: 0.8rem;
  padding: 0.2rem 1rem;
  border-radius: 20px;
  border: 1px solid black;
  :hover {
    background-color: orange;
  }
`;
 
export const CustomPopup = styled.div`
  width: 25%;
  padding: 1rem;
  `;

export const FieldInputMobile = styled.div`
font-size: 0.9rem;
 ${(props) =>
    props.border
      ? css`
          padding: 10px 10px;
          border: 0.5px solid rgba(137, 137, 137, 0.4);
          box-sizing: border-box;
          border-radius: 6px;
        `
      : css``}
`;

export const NodeList = styled.div`
  padding: 2px 10px;
  cursor: pointer;
  display: flex;
  border-radius: 10px;
  align-items: center;
`;

export const NodeSelectContainer = styled.div`
  ${(props) => (props.expanded ? "height: 15vh;" : ""  )}
  ${(props) => (props.disabled?"background-color:#606060;":"background-color:#ffffff;")}
  ${(props) => (props.position==="absolute"?"position:absolute;":'')}
  overflow-y: scroll;
  *::-webkit-scrollbar{
    width: 0px;
  }
  border: 0.5px solid #e8e8e8;
  border-radius: 6px;
  outline: none;
  overflow: hidden;
  padding: ${(props) => (props.expanded ? "0px 5px" : "0px 0px")};
`;

  