import React, { useEffect, useRef, useState } from "react";
import {
  VisitorRowStyled,
  Name,
  VisitorRowContainer,
  Phone,
  Email,
  Company,
  ContactedPerson,
  InTime,
  OutTime,
  Duration,
  Temperature,
  Alert,
  Button,
} from "./visitorRow.styled";
import CsvDownload from "react-json-to-csv";
import { SolidButton } from "../../buttons/solidButton.styled";
import { GoAlert } from "react-icons/go";
import { apiEndPoints, breakPointWidth, formNames } from "../../../config";
import { useSelector } from "react-redux";
import { OverFLowScrollBox } from "../../App.styled";
import { convetToLocaleDateString, getTimeFromUNIX, nullChecker } from "../../../utils/utilFunctions";
import {
  ButtonMobile,
  FieldLabelMobile,
  FieldValueMobile,
  ZoneLogsUserRowMobileView,
  ZoneLogsUserRowMobileWrapper,
} from "../../zone/ZoneLogsUserRow/zoneLogsUserRow.styled";
import PlaceholderMessage from "../../PlaceholderMessage";
import AccessInfoMobileView from "../../accessInfoMobileView/AccessInfoMobileView";
import NotificationManager from "react-notifications/lib/NotificationManager";
import { Link } from "react-router-dom";
import { ZoneLogsCSVOptionsContainer } from "../../zone/zoneLogs/ZoneLogs";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { InputBox } from "../../forms/FormBase.styled";
import LoadingSpinner from "../../LoadingSpinner";
const VisitorRow = ({ removeCookie, history, dispatch, getVisitorsLogsAPI }) => {
  const [positions, setPositions] = useState([]);
  const [showMenu, setShowMenu] = useState([]);
  const [showAIMobile, setShowAiMobile] = useState(false);
  const selectedNode = useSelector((state) => state.selectedNode);
  const visitorLogs = useSelector((state) => state.visitorLogs);
  const user = useSelector((state) => state.user);
  const dimentions = useSelector((state) => state.dimentions);
  const [csvDate, setCsvDate] = useState({ csvStartDate: Date.now(), csvEndDate: Date.now() });

  const apiStatus = useSelector((state) => state.apiStatus);
  useEffect(() => {
    if (dimentions.width < breakPointWidth && visitorLogs) {
      let show = visitorLogs.data.map((v) => false);
      setShowMenu(show);
    }
  }, [dimentions]);

  useEffect(() => {
    if (selectedNode && selectedNode.nodeId) {
      getVisitorsLogsAPI(
        csvDate.csvStartDate,
        csvDate.csvEndDate,
        user,
        dispatch,
        NotificationManager,
        removeCookie,
        history,
        selectedNode
      );
    }
  }, []);

  useEffect(() => {
    if (selectedNode) {
      getVisitorsLogsAPI(
        csvDate.csvStartDate,
        csvDate.csvEndDate,
        user,
        dispatch,
        NotificationManager,
        removeCookie,
        history,
        selectedNode
      );
    }
  }, [selectedNode, csvDate]);
  return (
    <>
      {showAIMobile ? (
        <AccessInfoMobileView
          showMenu={showAIMobile}
          nodeId={showAIMobile.accessInfo[0].nodeId}
          data={showAIMobile.accessInfo}
          setShowMenu={setShowAiMobile}
          // membershipId={}csvDate
        />
      ) : null}
      <div>
        <div className="d-flex justify-content-between align-items-center mb-4">
          <h5 className="font-weight-bold m-0">Logs</h5>
          <div>
            <SolidButton
            // onClick={(e) => toggleForm(dispatch, formNames.visitorAddForm)}
            >
              <Link
                to={`/vms/invite?nodeId=${selectedNode.nodeId}`}
                style={{ textDecoration: "none", color: "inherit" }}
              >
                Add new Visitor
              </Link>
            </SolidButton>

            <SolidButton
            // onClick={(e) => toggleForm(dispatch, formNames.visitorAddForm)}
            >
              <Link to={`/vms`} style={{ textDecoration: "none", color: "inherit" }}>
                Approve Visitors
              </Link>
            </SolidButton>

            <CsvDownload
              className="solid-button mr-2"
              disabled={!visitorLogs || !visitorLogs.data || !visitorLogs.data.length}
              data={
                visitorLogs && visitorLogs.data && visitorLogs.data.length
                  ? visitorLogs.data.map((d) => {
                      let t = { ...d };
                      delete t.accessInfo;
                      t.inTime = t.inTime
                        ? convetToLocaleDateString(parseInt(t.inTime / 1000)) +
                          " - " +
                          getTimeFromUNIX(t.inTime)
                        : "-";
                      t.outTime = t.outTime
                        ? convetToLocaleDateString(parseInt(t.outTime / 1000)) +
                          " - " +
                          getTimeFromUNIX(t.outTime)
                        : "-";
                      return t;
                    })
                  : null
              }
              name={"Name"}
              filename={`Visitor-Logs-Report-${new Date(csvDate.csvStartDate)
                .toString()
                .slice(0, 15)}-${new Date(csvDate.csvEndDate).toString().slice(0, 15)}.csv`}
            >
              Download CSV
            </CsvDownload>
          </div>
        </div>
      </div>
      <ZoneLogsCSVOptionsContainer dimentions={dimentions}>
        {/* <div>
          <p className=" mb-1 font-weight-bold">From</p>
          <DatePicker
            dateFormat="dd/MM/yyyy"
            className="react-datepicker-custom-css"
            selected={csvDate.csvStartDate}
            maxDate={Date.now()}
            onChange={(date) => setcsvDate.csvStartDate(date)}
          />
        </div>
        <div>
          <p className="mb-1 font-weight-bold">To</p>
          <DatePicker
            dateFormat="dd/MM/yyyy"
            className="react-datepicker-custom-css"
            selected={csvDate.csvEndDate}
            maxDate={Date.now()}
            onChange={(date) => setcsvDate.csvEndDate(date)}
          />
        </div> */}
        <div className="d-flex justify-content-between">
          <div>
            <p className="  mb-1 font-weight-bold" style={{ fontSize: "1rem" }}>
              From<span className="ml-4">To</span>
            </p>

            <DateRangePicker
              className="m-0"
              initialSettings={{
                startDate: `${new Date(csvDate.csvStartDate).getMonth() + 1}/${new Date(
                  csvDate.csvStartDate
                ).getDate()}/${new Date(csvDate.csvStartDate).getFullYear()}`,
                endDate: `${new Date(csvDate.csvEndDate).getMonth() + 1}/${new Date(
                  csvDate.csvEndDate
                ).getDate()}/${new Date(csvDate.csvEndDate).getFullYear()}`,
                showDropdowns: true,
              }}
              onCallback={(s, e) => {
                setCsvDate({ csvStartDate: s, csvEndDate: e });
              }}
            >
              <InputBox
                style={{ height: "30px", fontSize: "0.9rem" }}
                className="m-0"
                value={`${new Date(csvDate.csvStartDate).getMonth() + 1}/${new Date(
                  csvDate.csvStartDate
                ).getDate()}/${new Date(csvDate.csvStartDate).getFullYear()} - ${
                  new Date(csvDate.csvEndDate).getMonth() + 1
                }/${new Date(csvDate.csvEndDate).getDate()}/${new Date(
                  csvDate.csvEndDate
                ).getFullYear()}`}
              />
            </DateRangePicker>
          </div>
        </div>
      </ZoneLogsCSVOptionsContainer>
      {dimentions.width > breakPointWidth ? (
        <VisitorRowContainer>
          <VisitorRowStyled isHead={true}>
            <Name>Name</Name>
            <Phone>Phone</Phone>
            <Email>Email</Email>
            <Company>Company</Company>
            <ContactedPerson>Contacted Person</ContactedPerson>
            <InTime>In Time</InTime>
            <OutTime>Out Time</OutTime>
            <Duration>Purpose</Duration>
            {/* <Temperature>Temperature</Temperature> */}
            <Alert isHead={true}></Alert>
            <Button isHead={true} />
          </VisitorRowStyled>
          <OverFLowScrollBox>
            {visitorLogs &&
              visitorLogs.data &&
              visitorLogs.data.map((i, index) => {
                return (
                  <VisitorRowStyled
                    key={i.inTime + i.outTime}
                    isHead={false}
                    ref={(el) => {
                      if (dimentions.width > breakPointWidth) {
                        let temp = positions;
                        let show = showMenu;
                        show[index] = false;
                        if (!temp[index]) {
                          temp[index] = el
                            ? {
                                top: el.getBoundingClientRect().top,
                                left: el.getBoundingClientRect().left,
                                width: el.getBoundingClientRect().width,
                              }
                            : null;
                          setShowMenu(show);
                          setPositions([...temp]);
                        }
                      }
                    }}
                  >
                    <Name>{nullChecker(i.name)}</Name>
                    <Phone>{nullChecker(i.phone)}</Phone>
                    <Email>{nullChecker(i.email)}</Email>
                    <Company>{nullChecker(i.company)}</Company>
                    <ContactedPerson>{nullChecker(i.contactedPerson)}</ContactedPerson>
                    <InTime>
                      {i.inTime
                        ? convetToLocaleDateString(parseInt(i.inTime / 1000)) +
                          " - " +
                          getTimeFromUNIX(i.inTime)
                        : "-"}
                    </InTime>
                    <OutTime>
                      {i.outTime
                        ? convetToLocaleDateString(parseInt(i.outTime / 1000)) +
                          " - " +
                          getTimeFromUNIX(i.outTime)
                        : "-"}
                    </OutTime>
                    <Duration>{nullChecker(null)}</Duration>
                    {/* using this for purpose of visit */}
                    {/* <Temperature>{formatTemp(i.temperature, nullChecker)}</Temperature> */}
                    <Alert>
                      {i.duration === "01:43" ? (
                        <GoAlert data-toggle="tooltip" data-placement="top" title="Overstay" />
                      ) : null}
                    </Alert>
                    <Button>Blacklist</Button>
                  </VisitorRowStyled>
                );
              })}
            {apiStatus[apiEndPoints.getVisitorsLogsAPI] ? (
              <LoadingSpinner />
            ) : (
              visitorLogs?.data.length === 0 && <PlaceholderMessage message="No data to display" />
            )}
          </OverFLowScrollBox>
        </VisitorRowContainer>
      ) : (
        <>
          <div className="row m-0 p-0">
            {visitorLogs &&
              visitorLogs.data &&
              visitorLogs.data.map((i, index) => {
                return (
                  <div className="col-sm-12 col-xs-12 p-0 col-md-6 px-md-2" key={i.inTime + i.outTime}>
                    <ZoneLogsUserRowMobileWrapper>
                      <ZoneLogsUserRowMobileView>
                        <div>
                          <FieldLabelMobile>Name</FieldLabelMobile>
                          <FieldValueMobile>{nullChecker(i.name)}</FieldValueMobile>
                        </div>
                        <div>
                          <FieldLabelMobile>Phone</FieldLabelMobile>
                          <FieldValueMobile>{nullChecker(i.phone)}</FieldValueMobile>
                        </div>
                        <div>
                          <FieldLabelMobile>Email</FieldLabelMobile>
                          <FieldValueMobile>{nullChecker(i.email)}</FieldValueMobile>
                        </div>
                        <div>
                          <FieldLabelMobile>Company</FieldLabelMobile>
                          <FieldValueMobile>{nullChecker(i.company)}</FieldValueMobile>
                        </div>
                        <div>
                          <FieldLabelMobile>Contacted Person</FieldLabelMobile>
                          <FieldValueMobile>{nullChecker(i.company)}</FieldValueMobile>
                        </div>
                        <div>
                          <FieldLabelMobile>In Time</FieldLabelMobile>
                          <FieldValueMobile>
                            {i.inTime
                              ? convetToLocaleDateString(parseInt(i.inTime / 1000)) +
                                " - " +
                                getTimeFromUNIX(i.inTime)
                              : "-"}
                          </FieldValueMobile>
                        </div>
                        <div>
                          <FieldLabelMobile>Out Time</FieldLabelMobile>
                          <FieldValueMobile>
                            {i.outTime
                              ? convetToLocaleDateString(parseInt(i.outTime / 1000)) +
                                " - " +
                                getTimeFromUNIX(i.outTime)
                              : "-"}
                          </FieldValueMobile>
                        </div>
                        <div>
                          <FieldLabelMobile>Purpose</FieldLabelMobile>
                          <FieldValueMobile>{nullChecker(null)}</FieldValueMobile>
                        </div>
                        {/* <div>
                          <FieldLabelMobile>Temparature</FieldLabelMobile>
                          <FieldValueMobile>{formatTemp(i.temperature, nullChecker)}</FieldValueMobile>
                        </div> */}
                      </ZoneLogsUserRowMobileView>
                      <div className="row my-2">
                        <div className="col-12">
                          <ButtonMobile
                            type="info"
                            className="w-100"
                            onClick={(e) => {
                              setShowAiMobile(i);
                            }}
                          >
                            View
                          </ButtonMobile>
                        </div>
                        {/*
                        <div className="col-6">
                           <ButtonMobile className="w-100">
                            Blacklist
                          </ButtonMobile> 
                        </div>
                          */}
                      </div>
                    </ZoneLogsUserRowMobileWrapper>
                  </div>
                );
              })}

            {apiStatus[apiEndPoints.getVisitorsLogsAPI] ? (
              <LoadingSpinner />
            ) : (
              visitorLogs.data.length === 0 && <PlaceholderMessage message="No data to display" />
            )}
          </div>
        </>
      )}
    </>
  );
};

export default VisitorRow;
