import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { apiEndPoints } from "../../../config";
import { SolidButton } from "../../buttons/solidButton.styled";
import { useCookies } from "react-cookie";
import {
  FieldInput,
  FieldLabel,
  InputBox,
  InputGroup,
} from "../FormBase.styled";
import { useHistory } from "react-router-dom";
import NotificationManager from "react-notifications/lib/NotificationManager";
import { updateNodeInfoAPI } from "../../../utils/requests";
import ButtonState from "../../ButtonState/ButtonState";

const GateOpenAlertDuration = () => {
  const [cookies, setCookie, removeCookie] = useCookies(["AT"]);
  let history = useHistory();
  const dispatch = useDispatch();
  const nodeInfo = useSelector((state) => state.nodeInfo);
  const dimentions = useSelector((state) => state.dimentions);
  const selectedNode = useSelector((state) => state.selectedNode);
  const apiStatus = useSelector((state) => state.apiStatus);
  const user = useSelector((state) => state.user);
  const [duration, setDuration] = useState(
    parseInt(nodeInfo.gateOpenAlertDuration)
  );
  const updateNodeInfo = async () => {
    let body = { nodeId: selectedNode.nodeId, gateOpenAlertDuration: duration };
    await updateNodeInfoAPI(
      user,
      dispatch,
      NotificationManager,
      removeCookie,
      history,
      selectedNode,
      body
    );
  };

  return (
    <>
      <h6 className="font-weight-bold mb-4">Gate Open Alert Duration</h6>
      <InputGroup dimentions={dimentions}>
        <FieldLabel dimentions={dimentions}>Duration (seconds)</FieldLabel>
        <FieldInput>
          <InputBox
            type="number"
            // placeholder="Enter duration in seconds..."
            value={parseInt(duration / 1000)}
            onChange={(e) => setDuration(parseInt(e.target.value * 1000))}
          />
        </FieldInput>
      </InputGroup>
      <InputGroup dimentions={dimentions}>
        <FieldLabel dimentions={dimentions}></FieldLabel>
        <FieldInput submitButton={true}>
          <SolidButton submit={true} onClick={updateNodeInfo}>
            <ButtonState loading={apiStatus[apiEndPoints.updateNodeInfoAPI]} innerText={"Add"} />
          </SolidButton>
        </FieldInput>
      </InputGroup>
    </>
  );
};

export default GateOpenAlertDuration;
