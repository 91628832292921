import { cloudFrontOrigin } from "../config";

// export const rootApi = "https://api.snglty.com/v2/";
// export const rootApi = "http://localhost:4000/v2/";

// export const cookieDomain = ".thesecurepass.com";
export const cookieDomain = ".netlify.app";
// export const cookieDomain = "localhost"

export const loginURI =
  "https://dashboard.thesecurepass.com?backto=https://dashboard.thesecurepass.com/vms";

export const logoutURI = "https://dashboard.thesecurepass.com?logout=true";

export const navList = [
  {
    title: "Access",
    image: `${cloudFrontOrigin}Group_294_k5k3ue.svg`,
    path: "/access",
  },
  {
    title: "Visitor",
    image: `${cloudFrontOrigin}Group_292_ldjnsg.svg`,
    path: "/visitor",
  },
  {
    title: "Attendance",
    image: `${cloudFrontOrigin}Group_10_igutaz.svg`,
    path: "/attendance",
  },
  // {
  //   title: "Booking",
  //   image: `${cloudFrontOrigin}Group_284_mfesku.svg`,
  //   path: "/booking",
  // },
  // {
  //   title: "Live Feed",
  //   image: `${cloudFrontOrigin}Group_286_p3fxum.svg`,
  //   path: "/livefeed",
  // },
  // {
  //   title: "Store",
  //   image: `${cloudFrontOrigin}Group_296_kmgjwt.svg`,
  //   path: "/store",
  // },
];

export const restrictedNavList = [
  {
    title: "Attendance",
    image: `${cloudFrontOrigin}Group_10_igutaz.svg`,
    path: "/attendance",
  },
  {
    title: "Visitor",
    image: `${cloudFrontOrigin}Group_292_ldjnsg.svg`,
    path: "/visitor",
  },

  // {
  //   title: "Booking",
  //   image: `${cloudFrontOrigin}Group_284_mfesku.svg`,
  //   path: "/booking",
  // },
  // {
  //   title: "Live Feed",
  //   image: `${cloudFrontOrigin}Group_286_p3fxum.svg`,
  //   path: "/livefeed",
  // },
  // {
  //   title: "Store",
  //   image: `${cloudFrontOrigin}Group_296_kmgjwt.svg`,
  //   path: "/store",
  // },
];
