import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { apiEndPoints, formNames, rootApi } from "../../../config";
import { post, get } from "../../../utils/apiService";
import { decodeTime, encodeTime, toggleForm } from "../../../utils/utilFunctions";
import { SolidButton } from "../../buttons/solidButton.styled";
import { FormWrapper } from "../doorAddForm/DoorAddForm";
import { useCookies } from "react-cookie";
import { FieldInput, FieldLabel, InputBox, InputGroup, SelectBox } from "../FormBase.styled";
import { useHistory, useLocation } from "react-router-dom";
import { ATexpired } from "../../../utils/logout";
import TimePicker from "react-time-picker";
import AccessDays from "../AccessDays";
import NotificationManager from "react-notifications/lib/NotificationManager";
import { updateNodeInfoAPI } from "../../../utils/requests";
import GreenSwitch from "../../switches/GreenSwitch";
import ButtonState from "../../ButtonState/ButtonState";

const AttendanceInfoUpdateForm = () => {
  const closeForm = () => {
    toggleForm(formState, history, location, dispatch, formNames.attendanceInfoUpdateForm);
  };
  const selectedNode = useSelector((state) => state.selectedNode);
  const apiStatus = useSelector((state) => state.apiStatus);
  const [cookies, setCookie, removeCookie] = useCookies(["AT"]);
  let history = useHistory();
  const location = useLocation();
  const [formData, setFormData] = useState({
    workDays: [true, true, true, true, true, false, false],
    startTimeInDay: 600,
    halfDayMin: 240,
    fullDayMin: 360,
    workHours: 540,
  });
  const [workHours, setWorkHours] = useState("00");
  const [workMin, setWorkMin] = useState("00");
  const [fullDayHours, setFullDayHours] = useState("00");
  const [fullDayMin, setFullDayMin] = useState("00");
  const [halfDayHours, setHalfDayHours] = useState("00");
  const [halfDayMin, setHalfDayMin] = useState("00");
  const [error, setError] = useState(false);
  const handleDaysChange = (index, nodeId, selectAll, clearAll) => {
    let tempDays = [...formData.workDays];
    tempDays[index] = !tempDays[index];
    if (selectAll) {
      tempDays = tempDays.map((i) => true);
    }
    if (clearAll) {
      tempDays = tempDays.map((i) => false);
    }
    setFormData({ ...formData, workDays: [...tempDays] });
  };
  const nodeInfo = useSelector((state) => state.nodeInfo);
  const formState = useSelector((state) => state.formState);
  const dispatch = useDispatch();
  const dimentions = useSelector((state) => state.dimentions);
  const user = useSelector((state) => state.user);

  const updateNodeInfo = async (formData) => {
    let tempData = { ...formData };
    tempData.workDays = JSON.stringify(tempData.workDays);
    let body = { nodeId: selectedNode.nodeId, ...tempData };
    if (!error) {
      await updateNodeInfoAPI(
        user,
        dispatch,
        NotificationManager,
        removeCookie,
        history,
        selectedNode,
        body,
        closeForm
      );
    }
  };

  useEffect(() => {
    setFormData({
      ...formData,
      fullDayMin: encodeTime(`${fullDayHours}:${fullDayMin}`),
    });
  }, [fullDayMin, fullDayHours]);
  useEffect(() => {
    setFormData({
      ...formData,
      halfDayMin: encodeTime(`${halfDayHours}:${halfDayMin}`),
    });
  }, [halfDayMin, halfDayHours]);

  useEffect(() => {
    setFormData({
      ...formData,
      workHours: encodeTime(`${workHours}:${workMin}`),
    });
  }, [workHours, workMin]);

  useEffect(() => {
    let temp = {};
    if (!nodeInfo.workDays) {
      temp.workDays = [true, true, true, true, true, true, true];
    } else if (nodeInfo.workDays) {
      temp.workDays = [...nodeInfo.workDays];
    }
    if (nodeInfo.startTimeInDay) {
      temp.startTimeInDay = nodeInfo.startTimeInDay;
    }
    if (nodeInfo.fullDayMin) {
      const time = decodeTime(nodeInfo.fullDayMin).split(":");
      setFullDayHours(time[0]);
      setFullDayMin(time[1]);
      temp.fullDayMin = nodeInfo.fullDayMin;
    }
    if (nodeInfo.workHours) {
      const time = decodeTime(nodeInfo.workHours).split(":");
      setWorkHours(time[0]);
      setWorkMin(time[1]);
      temp.workHours = nodeInfo.workHours;
    }
    if (nodeInfo.halfDayMin) {
      const time = decodeTime(nodeInfo.halfDayMin).split(":");
      setHalfDayHours(time[0]);
      setHalfDayMin(time[1]);
      temp.halfDayMin = nodeInfo.halfDayMin;
    }
    if (temp.workDays || temp.startTimeInDay) {
      setFormData({ ...formData, ...temp });
    }
  }, [nodeInfo]);

  useEffect(() => {
    const { halfDayMin, fullDayMin, workHours } = formData;
    setError(false);
    if (halfDayMin > fullDayMin || halfDayMin > workHours) {
      setError("Half day duration must be lesser than Minimum Full Day Duration & Work hours ");
    }
    if (fullDayMin < halfDayMin || fullDayMin > workHours) {
      setError(
        "Minimum Full Day Duration must be greater than Half day duration & less than Work hours "
      );
    }
    if (workHours < halfDayMin || workHours < fullDayMin) {
      setError("Work hours must be greater than Half day duration &  Minimum Full Day Duration ");
    }
  }, [formData]);

  return (
    <FormWrapper dimentions={dimentions} closeForm={closeForm}>
      <h6 className="font-weight-bold mb-4">Add Attendance Info</h6>
      <InputGroup dimentions={dimentions}>
        <FieldLabel dimentions={dimentions}>Work Days</FieldLabel>
        <FieldInput>
          <AccessDays daysofWeek={formData.workDays} onClick={handleDaysChange} onAttendance={true} />
        </FieldInput>
      </InputGroup>
      <InputGroup dimentions={dimentions}>
        <FieldLabel dimentions={dimentions}>In Time</FieldLabel>
        <FieldInput style={{ width: "70px" }}>
          <TimePicker
            required
            clockIcon={null}
            isOpen={false}
            clearIcon={null}
            value={decodeTime(formData.startTimeInDay)}
            onChange={(e) => setFormData({ ...formData, startTimeInDay: encodeTime(e) })}
          />
        </FieldInput>
      </InputGroup>
      <InputGroup dimentions={dimentions}>
        <FieldLabel dimentions={dimentions}>Work Hours</FieldLabel>
        <FieldInput>
          <div className="d-flex align-items-center">
            <InputBox
              type="number"
              style={{ width: "70px" }}
              placeholder="HH"
              className="mr-2"
              value={workHours}
              onChange={(e) => setWorkHours(e.target.value)}
            />
            :
            <InputBox
              type="number"
              value={workMin}
              style={{ width: "70px" }}
              placeholder="MM"
              className="mx-2"
              onChange={(e) => setWorkMin(e.target.value)}
            />
            <span>HH : MM</span>
          </div>
        </FieldInput>
      </InputGroup>
      <InputGroup dimentions={dimentions}>
        <FieldLabel dimentions={dimentions}>
          Minimum Full
          <br />
          Day Duration
        </FieldLabel>
        <FieldInput>
          <div className="d-flex align-items-center">
            <InputBox
              type="number"
              style={{ width: "70px" }}
              placeholder="HH"
              className="mr-2"
              value={fullDayHours}
              onChange={(e) => setFullDayHours(e.target.value)}
            />
            :
            <InputBox
              type="number"
              value={fullDayMin}
              style={{ width: "70px" }}
              placeholder="MM"
              className="mx-2"
              onChange={(e) => setFullDayMin(e.target.value)}
            />
            <span>HH : MM</span>
          </div>
        </FieldInput>
      </InputGroup>
      <InputGroup dimentions={dimentions}>
        <FieldLabel dimentions={dimentions}>
          Minimum Half
          <br />
          Day Duration
        </FieldLabel>
        <FieldInput>
          <div className="d-flex align-items-center">
            <InputBox
              style={{ width: "70px" }}
              placeholder="HH"
              type="number"
              value={halfDayHours}
              className="mr-2"
              onChange={(e) => setHalfDayHours(e.target.value)}
            />
            :
            <InputBox
              type="number"
              value={halfDayMin}
              style={{ width: "70px" }}
              placeholder="MM"
              className="mx-2"
              onChange={(e) => setHalfDayMin(e.target.value)}
            />
            <span>HH : MM</span>
          </div>
        </FieldInput>
      </InputGroup>
      {error ? (
        <InputGroup dimentions={dimentions}>
          <FieldLabel dimentions={dimentions}></FieldLabel>
          <FieldInput>
            <div className="d-flex align-items-center justify-content-start font-weight-bold text-danger">
              {error}
            </div>
          </FieldInput>
        </InputGroup>
      ) : null}
      <InputGroup dimentions={dimentions}>
        <FieldLabel dimentions={dimentions}>Consider actual in time</FieldLabel>
        <FieldInput>
          <GreenSwitch
            checked={nodeInfo.considerActualInTime}
            onChange={(e) =>
              updateNodeInfoAPI(
                user,
                dispatch,
                NotificationManager,
                removeCookie,
                history,
                selectedNode,
                {
                  nodeId: selectedNode.nodeId,
                  considerActualInTime: !nodeInfo.considerActualInTime,
                }
              )
            }
          />
        </FieldInput>
      </InputGroup>
      <InputGroup dimentions={dimentions}>
        <FieldLabel dimentions={dimentions}>Attendance from In Times only</FieldLabel>
        <FieldInput>
          <GreenSwitch
            checked={nodeInfo.considerOnlyInTime}
            onChange={(e) =>
              updateNodeInfoAPI(
                user,
                dispatch,
                NotificationManager,
                removeCookie,
                history,
                selectedNode,
                {
                  nodeId: selectedNode.nodeId,
                  considerOnlyInTime: !nodeInfo.considerOnlyInTime,
                }
              )
            }
          />
        </FieldInput>
      </InputGroup>
      <InputGroup dimentions={dimentions}>
        <FieldLabel dimentions={dimentions}></FieldLabel>
        <FieldInput submitButton={true}>
          <SolidButton disabled={error} submit={true} onClick={(e) => updateNodeInfo(formData)}>
            <ButtonState loading={apiStatus[apiEndPoints.updateNodeInfoAPI]} innerText={"Add"} />
          </SolidButton>
        </FieldInput>
      </InputGroup>
    </FormWrapper>
  );
};

export default AttendanceInfoUpdateForm;
