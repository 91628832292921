import styled from "styled-components";

export const StatsContainer = styled.div`
  border: 0.5px solid #e8e8e8;
  box-sizing: border-box;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.06);
  border-radius: 10px;
  padding: 1rem 1rem;
  width : 100%;
  margin : 1rem 0rem;
`;

export const StatsWrapper = styled.div`
  display: grid;
  margin : 0.5rem 0rem;
  width: 100%;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 1rem;
`;
