import React, { useEffect, useState } from "react";
import { apiEndPoints, formNames } from "../../../config";
import {breakPointWidth} from "../../../config";
import { FormWrapper } from "../doorAddForm/DoorAddForm";
import { useCookies } from "react-cookie";
import { toggleForm } from "../../../utils/utilFunctions";
import {Tooltip} from "react-hover-tooltip";
import { confirmAlert } from "react-confirm-alert"; // Import
import {
  RowContainer,
  RowStyle,
  CardID,
  Disabled,
  StartTime,
  EndTime,
  StartTimeInDay,
  EndTimeInDay,
  Button,
  Status,
} from "./RFData.styled";
import { OverFLowScrollBox } from "../../App.styled";
import {
  nullChecker,
  displayTimeMinutes,
  decodeTime,
} from "../../../utils/utilFunctions";
import {
  FieldLabelMobile,
  FieldValueMobile,
  ZoneLogsUserRowMobileView,
  ZoneLogsUserRowMobileWrapper,
} from "../../zone/ZoneLogsUserRow/zoneLogsUserRow.styled";
import { useHistory, useLocation } from "react-router-dom";
import NotificationManager from "react-notifications/lib/NotificationManager";
import { getDeviceOACs, resyncRFCards, getDeviceRFData} from "../../../utils/requests";
import {BsFillExclamationTriangleFill} from "react-icons/bs";
import {useSelector, useDispatch} from "react-redux";
import ButtonState from "../../ButtonState/ButtonState";

const CheckDeviceRFDataForm = () => {
    const closeForm = () => {
      toggleForm(formState, history, location, dispatch, formNames.checkDeviceRFDataForm);
    };
    const [cookies, setCookie, removeCookie] = useCookies(["AT"]);
    let history = useHistory();
    const location = useLocation();
    const user = useSelector((state) => state.user);
    const initialData = useSelector((state)=>state.formState[formNames.checkDeviceRFDataForm]);
    const dispatch = useDispatch();
    const dimentions = useSelector((state) => state.dimentions);
    const selectedNode = useSelector((state) => state.selectedNode);
    const apiStatus = useSelector((state) => state.apiStatus);
    const formState = useSelector((state)=>state.formState);
    const [RFData,setRFData] = useState([]);
    
    useEffect(()=>{
        if(initialData.deviceId)
        getDeviceRFData(
            user,
            dispatch,
            NotificationManager,
            removeCookie,
            history,
            initialData.deviceId,
            selectedNode.nodeId,
            setRFData,
        )
    },[initialData.deviceId]);

return dimentions.width > breakPointWidth ? (
  <FormWrapper dimentions={dimentions} closeForm={closeForm}>
    <Button
      style={{ width: "200px", display: "inline-block", marginBottom: "0.5rem" }}
      onClick={(e) =>
        resyncRFCards(
          user,
          dispatch,
          NotificationManager,
          removeCookie,
          history,
          confirmAlert,
          selectedNode,
          initialData.deviceId
        )
      }
    >
      <ButtonState loading={apiStatus[apiEndPoints.resyncRFCards]} innerText={"Resync RF Cards"} />
    </Button>
    <RowContainer>
      <RowStyle isHead={true}>
        <CardID>CardID</CardID>
        <StartTime>Start Time</StartTime>
        <EndTime>End Time</EndTime>
        <StartTimeInDay>Start Time In Day</StartTimeInDay>
        <EndTimeInDay>End Time In Day</EndTimeInDay>
        <Disabled>Disabled</Disabled>
        <Status>Sync Status</Status>
      </RowStyle>
      <OverFLowScrollBox>
        {RFData && RFData.length
          ? RFData.map((i) => {
              if (i.customRFID && !(i.disabled && i.modified === 0))
                return (
                  <RowStyle isHead={true}>
                    <CardID>
                      {i.customRFID}
                      {i.disabled ? (
                        <Tooltip content={<div>Sync needed before this Card stops working</div>}>
                          <BsFillExclamationTriangleFill color="#EED202" size="1rem" />
                        </Tooltip>
                      ) : null}
                      {i.modified && !i.disabled ? (
                        <Tooltip content={<div>Sync needed before this Card starts working</div>}>
                          <BsFillExclamationTriangleFill color="#EED202" size="1rem" />
                        </Tooltip>
                      ) : null}
                    </CardID>
                    <StartTime>{displayTimeMinutes(i.startTime * 1000)}</StartTime>
                    <EndTime>{i.endTime ? displayTimeMinutes(i.endTime * 1000) : "-"}</EndTime>
                    <StartTimeInDay>{decodeTime(i.startTimeInDay)}</StartTimeInDay>
                    <EndTimeInDay>{decodeTime(i.endTimeInDay)}</EndTimeInDay>
                    <Disabled>{i.disabled ? "Disabled" : "-"}</Disabled>
                    <Status>
                      {i.modified
                        ? `Sync pending from ${displayTimeMinutes(i.modified * 1000)}`
                        : i.lastSyncTime
                        ? `Last synced at ${displayTimeMinutes(i.lastSyncTime * 1000)}`
                        : "Synced"}
                    </Status>
                  </RowStyle>
                );
            })
          : null}
      </OverFLowScrollBox>
    </RowContainer>
  </FormWrapper>
) : (
  <FormWrapper dimentions={dimentions} closeForm={closeForm}>
    <div className="col-sm-12 col-xs-12 p-0 col-md-12 px-md-2">
      <Button
        style={{ width: "200px", display: "inline-block", marginBottom: "0.5rem" }}
        onClick={(e) =>
          resyncRFCards(
            user,
            dispatch,
            NotificationManager,
            removeCookie,
            history,
            confirmAlert,
            selectedNode,
            initialData.deviceId
          )
        }
      >
        <ButtonState loading={apiStatus[apiEndPoints.resyncRFCards]} innerText={"Resync RF Cards"} />
      </Button>
      {RFData && RFData.length
        ? RFData.map((i) => {
            if (i.customRFID && !(i.disabled && i.modified === 0))
              return (
                <ZoneLogsUserRowMobileWrapper>
                  <ZoneLogsUserRowMobileView>
                    <div>
                      <FieldLabelMobile>CardID</FieldLabelMobile>
                      <div>
                        <FieldValueMobile>
                          {i.customRFID}
                          {i.disabled ? (
                            <Tooltip content={<div>Sync needed before this Card stops working</div>}>
                              <BsFillExclamationTriangleFill color="#EED202" size="1rem" />
                            </Tooltip>
                          ) : null}
                          {i.modified && !i.disabled ? (
                            <Tooltip content={<div>Sync needed before this Card starts working</div>}>
                              <BsFillExclamationTriangleFill color="#EED202" size="1rem" />
                            </Tooltip>
                          ) : null}
                        </FieldValueMobile>
                      </div>
                    </div>
                    <div>
                      <FieldLabelMobile>Disabled</FieldLabelMobile>
                      <div>
                        <FieldValueMobile>{i.disabled ? "Disabled" : "-"}</FieldValueMobile>
                      </div>
                    </div>
                    <div>
                      <FieldLabelMobile>Start Time</FieldLabelMobile>
                      <div>
                        <FieldValueMobile>{displayTimeMinutes(i.startTime * 1000)}</FieldValueMobile>
                      </div>
                    </div>
                    <div>
                      <FieldLabelMobile>End Time</FieldLabelMobile>
                      <div>
                        <FieldValueMobile>
                          {i.endTime ? displayTimeMinutes(i.endTime * 1000) : "-"}
                        </FieldValueMobile>
                      </div>
                    </div>
                    <div>
                      <FieldLabelMobile>Start Time In Day</FieldLabelMobile>
                      <div>
                        <FieldValueMobile>{decodeTime(i.startTimeInDay)}</FieldValueMobile>
                      </div>
                    </div>
                    <div>
                      <FieldLabelMobile>End Time In Day</FieldLabelMobile>
                      <div>
                        <FieldValueMobile>{decodeTime(i.endTimeInDay)}</FieldValueMobile>
                      </div>
                    </div>
                  </ZoneLogsUserRowMobileView>
                  <div style={{ marginTop: "0.5rem" }}>
                    <FieldLabelMobile>Status</FieldLabelMobile>
                    <div>
                      <FieldValueMobile>
                        {i.modified
                          ? `Sync pending from ${displayTimeMinutes(i.modified * 1000)}`
                          : i.lastSyncTime
                          ? `Last synced at ${displayTimeMinutes(i.lastSyncTime * 1000)}`
                          : "Synced"}
                      </FieldValueMobile>
                    </div>
                  </div>
                </ZoneLogsUserRowMobileWrapper>
              );
          })
        : null}
    </div>
  </FormWrapper>
);
    };  

export default CheckDeviceRFDataForm;
