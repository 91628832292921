import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { apiEndPoints, formNames, rootApi } from "../../../config";
import DatePicker from "react-datepicker";
import { convetToLocaleDateString, toggleForm } from "../../../utils/utilFunctions";
import { SolidButton } from "../../buttons/solidButton.styled";
import { FormWrapper } from "../doorAddForm/DoorAddForm";
import { useCookies } from "react-cookie";
import { FieldInput, FieldLabel, InputBox, InputGroup } from "../FormBase.styled";
import { useHistory, useLocation } from "react-router-dom";
import NotificationManager from "react-notifications/lib/NotificationManager";
import { nodeHolidayAdd, nodeHolidayRemove, nodeHolidayUpdate } from "../../../utils/requests";
import { IconContainer } from "../../App.styled";
import { BiEdit } from "react-icons/bi";
import { RiDeleteBinLine } from "react-icons/ri";
import ButtonState from "../../ButtonState/ButtonState";
const NodeHolidaysForm = () => {
  const [cookies, setCookie, removeCookie] = useCookies(["AT"]);
  let history = useHistory();
  const location = useLocation();
  const nodeInfo = useSelector((state) => state.nodeInfo);
  const apiStatus = useSelector((state) => state.apiStatus);
  const selectedNode = useSelector((state) => state.selectedNode);
  const formState = useSelector((state) => state.formState);
  const dispatch = useDispatch();
  const dimentions = useSelector((state) => state.dimentions);
  const user = useSelector((state) => state.user);
  const [addHolidayDateValue, setAddHolidayDateValue] = useState(Date.now());
  const [addHolidayInputFieldValue, setAddHolidayInputFieldValue] = useState("");
  const [update, setUpdate] = useState(null);

  const [holidayList, setHolidayList] = useState([]);

  const closeForm = () => {
    toggleForm(formState, history, location, dispatch, formNames.nodeHolidayUpdateForm);
  };

  const handleEdit = (h) => {
    setAddHolidayDateValue(parseInt(h.timestamp) * 1000);
    setAddHolidayInputFieldValue(h.name);
    setUpdate(h.holidayId);
  };

  const addHoliday = async () => {
    let body;
    if (update !== null) {
      nodeHolidayUpdate(
        user,
        dispatch,
        NotificationManager,
        removeCookie,
        history,
        selectedNode,
        update,
        addHolidayInputFieldValue,
        `${new Date(addHolidayDateValue).setHours(0, 0, 0, 0) / 1000}`
      );

      setAddHolidayDateValue(Date.now());
      setAddHolidayInputFieldValue("");
      setUpdate(null);
    } else {
      nodeHolidayAdd(
        user,
        dispatch,
        NotificationManager,
        removeCookie,
        history,
        selectedNode,
        addHolidayInputFieldValue,
        `${new Date(addHolidayDateValue).setHours(0, 0, 0, 0) / 1000}`
      );
      setAddHolidayDateValue(Date.now());
      setAddHolidayInputFieldValue("");
    }
  };

  useEffect(() => {
    if (nodeInfo && nodeInfo.holidayList && nodeInfo.holidayList.length) {
      setHolidayList(
        nodeInfo.holidayList.sort((a, b) =>
          a.timestamp > b.timestamp ? 1 : b.timestamp > a.timestamp ? -1 : 0
        )
      );
    } else {
      setHolidayList([]);
    }
  }, [nodeInfo]);

  return (
    <FormWrapper dimentions={dimentions} closeForm={closeForm}>
      <h6 className="font-weight-bold mb-4">Manage Holidays</h6>
      <InputGroup dimentions={dimentions}>
        <FieldLabel dimentions={dimentions}>Holidays</FieldLabel>
        <FieldInput>
          <div className="row">
            {holidayList && holidayList.length ? (
              holidayList.map((h, i) => (
                <div key={h.holidayId} className="col-md-6 col-lg-6 col-sm-12 col-xs-12">
                  <div className="row rounded border m-2">
                    <div className="col-9 d-flex align-items-center">
                      <div>
                        <p style={{ fontSize: "1.1rem", fontWeight: "bold" }} className="m-0 p-0">
                          {h.name}
                        </p>
                        <h4 style={{ fontSize: "1.4rem", fontWeight: "bold" }}>
                          {convetToLocaleDateString(h.timestamp)}
                        </h4>
                      </div>
                    </div>
                    <div className="col-3 text-right">
                      <IconContainer>
                        <BiEdit
                          style={{ fontSize: "1.5rem", fontWeight: "bold" }}
                          onClick={(e) => handleEdit(h)}
                        />
                      </IconContainer>
                      <IconContainer>
                        <ButtonState
                          loading={apiStatus[apiEndPoints.nodeHolidayRemove]}
                          innerText={
                            <RiDeleteBinLine
                              style={{
                                fontSize: "1.5rem",
                                fontWeight: "bold",
                                color: "red",
                              }}
                              onClick={(e) =>
                                nodeHolidayRemove(
                                  user,
                                  dispatch,
                                  NotificationManager,
                                  removeCookie,
                                  history,
                                  selectedNode,
                                  h.holidayId
                                )
                              }
                            />
                          }
                        />
                      </IconContainer>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 p-2 border  rounded shadow shadow-sm  m-2 ">
                <p className="m-0 font-weight-bold text-info">No holidays for this year</p>
              </div>
            )}
          </div>
        </FieldInput>
      </InputGroup>

      <InputGroup dimentions={dimentions}>
        <FieldLabel dimentions={dimentions}></FieldLabel>
        <FieldInput className="font-weight-bold">Add Holiday</FieldInput>
      </InputGroup>

      <InputGroup dimentions={dimentions}>
        <FieldLabel dimentions={dimentions}>Date</FieldLabel>
        <FieldInput>
          <DatePicker
            dateFormat="dd/MM/yyyy"
            className="react-datepicker-custom-css"
            selected={addHolidayDateValue}
            onChange={(date) => setAddHolidayDateValue(date)}
          />
        </FieldInput>
      </InputGroup>
      <InputGroup dimentions={dimentions}>
        <FieldLabel dimentions={dimentions}>Occasion</FieldLabel>
        <FieldInput>
          <InputBox
            value={addHolidayInputFieldValue}
            onChange={(e) => setAddHolidayInputFieldValue(e.target.value)}
          />
        </FieldInput>
      </InputGroup>
      <InputGroup dimentions={dimentions}>
        <FieldLabel dimentions={dimentions}></FieldLabel>
        <FieldInput submitButton={true}>
          <SolidButton disabled={!addHolidayInputFieldValue.length} submit={true} onClick={addHoliday}>
            <ButtonState
              loading={
                apiStatus[apiEndPoints.nodeHolidayAdd] || apiStatus[apiEndPoints.nodeHolidayUpdate]
              }
              innerText={update !== null ? "Update" : "Add"}
            />
          </SolidButton>
        </FieldInput>
      </InputGroup>
    </FormWrapper>
  );
};

export default NodeHolidaysForm;
