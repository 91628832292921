import { set } from "date-fns";
import NotificationManager from "react-notifications/lib/NotificationManager";
import { formNames, rootApi, typeCode, SUPER_ADMIN_LIST } from "../config";
import { updateDeviceFirmwareAction } from "../redux/deviceFirmware/deviceFirmwareActions";
import { updateFormData } from "../redux/formStatus/formStatusActions";
import { updateGroups } from "../redux/groups/groupsActions";
import { updateMembership } from "../redux/memberships/membershipsActions";
import { updateNodeInfo, updateNodeInfoDepatments } from "../redux/nodeInfo/nodeInfoActions";
import { updateNodes } from "../redux/nodes/nodesActions";
import { updateNotifications } from "../redux/notifications/notificationsActions";
import { updateSelectedNode } from "../redux/selectedNode/selectedNodeActions";
import { updateSelectedNodeHierarchy } from "../redux/selectedNodeHierarchy/selectedNodeHierarchyActions";
import { updateUser } from "../redux/user/userActions";
import { updateVisitorLogs } from "../redux/visitorLogs/visitorLogsActions";
import { createNotification, notificationType } from "../vms/Services/utils";
import { get, post, postMultipartFormData } from "./apiService";
import { ATexpired } from "./logout";
import { convertTime, encodeTime, isAdmin, parseQueryParams, sanitizeReqBody } from "./utilFunctions";

export const getUserRoles = async (user, dispatch, NotificationManager, removeCookie, history) => {
  const url = `${rootApi}user/roles`;
  try {
    const response = await get(dispatch, url, user.AT);
    if (!response.data.success) {
      createNotification(NotificationManager, notificationType.error, response.data.error);
    } else {
      let r = {};
      let ai = {};

      response.data.roles.forEach((v) => {
        if (v.accessInfo) {
          v.accessInfo.forEach((a) => {
            ai[a.nodeId] = a.visitorManagement;
          });
        }
        r[v.nodeId] = {
          ...v,
          userId: response.data.userInfo.userId,
          userInfo: response.data.userInfo,
        };
      });
      return { roles: r, visitorPermissions: ai };
    }
    ATexpired(response, dispatch, removeCookie, history);
  } catch (err) {
    throw err;
  }
};

export const getVisitorsLogsAPI = async (
  sd,
  ed,
  user,
  dispatch,
  NotificationManager,
  removeCookie,
  history,
  selectedNode
) => {
  dispatch(
    updateVisitorLogs({
      data: [],
      visitorLoggedOut: 0,
      visitorInside: 0,
      totalNoOfVisitor: 0,
    })
  );
  const url = `${rootApi}node/visitorLogs?nodeId=${selectedNode.nodeId}&startTime=${parseInt(
    new Date(sd).setHours(0, 0, 0, 0) / 1000
  )}&endTime=${parseInt(new Date(ed).setHours(23, 59, 59, 99) / 1000)}`;
  try {
    const response = await get(dispatch, url, user.AT);
    if (!response.data.success) {
      createNotification(NotificationManager, notificationType.error, response.data.error);
    } else {
      const { visitorLoggedOut, visitorInside, totalNoOfVisitor } = response.data;

      dispatch(
        updateVisitorLogs({
          data: response.data.visitorLogs,
          visitorLoggedOut,
          visitorInside,
          totalNoOfVisitor,
        })
      );
    }
    ATexpired(response, dispatch, removeCookie, history);
  } catch (err) {
    throw err;
  }
};

export const removeMembershipAPI = async (
  user,
  dispatch,
  NotificationManager,
  removeCookie,
  history,
  userMembershipMobileNo,
  membershipId,
  selectedNode,
  utils,
  confirmAlert
) => {
  let normalMessage = `Are you sure you want to remove this membership and its access permissions\nThis membership will also be removed from every group it was added to.`;
  let superAdminMessage = `On deleting the account 999999999, SecurePass will not have any access to your dashboard.\n
  Note:- for troubleshooting or issues/bugs removal,  SecurePass team requires access to your dashboard using this Admin account. This makes us solve your queries remotely. So it is recommended not to delete this account for hassle free experience.\n
  Please note that the issue resolution time might increase if SecurePass team is not the Admin. For further clarifications please contact our customer support team.\n
  If you still want to continue deleting this account, press the Yes button. To go back, press the No button.`;

  let message = SUPER_ADMIN_LIST.includes(userMembershipMobileNo) ? superAdminMessage : normalMessage;

  confirmAlert({
    title: "Confirm to delete",
    message: message,
    buttons: [
      {
        label: "Yes",
        onClick: async () => {
          const url = `${rootApi}membership/revoke`;
          const nodeId = selectedNode.nodeId;
          const body = {
            membershipId,
            nodeId,
          };
          try {
            const response = await post(dispatch, url, body, user.AT);
            if (!response.data.success) {
              createNotification(NotificationManager, notificationType.error, response.data.error);
            } else {
              getPeopleAPI(
                user,
                dispatch,
                NotificationManager,
                removeCookie,
                history,
                selectedNode,
                utils
              );
            }
            ATexpired(response, dispatch, removeCookie, history);
          } catch (err) {
            throw err;
          }
        },
      },
      {
        label: "No",
        onClick: () => null,
      },
    ],
  });
};

export const getPeopleAPI = async (
  user,
  dispatch,
  NotificationManager,
  removeCookie,
  history,
  selectedNode,
  utils,
  pageNo,
  pageSize,
  setNoOfPages,
  forGroup,
  role
) => {
  let { includeExpired, includeChildMemberships } = utils;

  let pgNo = pageNo ? pageNo : 1;
  let pgSize = pageSize ? pageSize : 20;
  let forGroups = forGroup ? forGroup : false;
  let url = `${rootApi}node/memberships?nodeId=${selectedNode.nodeId}&includeExpired=${
    includeExpired ? includeExpired : false
  }&includeChildMemberships=${
    includeChildMemberships ? includeChildMemberships : false
  }&pageNo=${pgNo}&pageSize=${pgSize}&forGroups=${forGroups}`;
  if (role) {
    url += `&role=${role.trim()}`;
  }
  console.log({ includeExpired, includeChildMemberships, url });
  dispatch(updateMembership([]));
  if (selectedNode && isAdmin(user, selectedNode)) {
    try {
      const response = await get(dispatch, url, user.AT);
      if (!response.data.success) {
        createNotification(NotificationManager, notificationType.error, response.data.error);
        console.log(response.data.membership);
      } else {
        let temp = [...response.data.memberships];
        for (let i = 0; i < temp.length; i++) {
          temp[i].searchField = `${temp[i].userInfo.firstName ? temp[i].userInfo.firstName : ""}${
            temp[i].userInfo.lastName ? temp[i].userInfo.lastName : ""
          }${temp[i].userInfo.mobile.number ? temp[i].userInfo.mobile.number : ""}${
            temp[i].userInfo.email.id ? temp[i].userInfo.email.id : ""
          }${temp[i].UDId ? temp[i].UDId : ""}${
            temp[i].RFCards && temp[i].RFCards.length
              ? temp[i].RFCards.map((v) => v.customRFId).join("")
              : ""
          }`.toLowerCase();
        }
        if (typeof setNoOfPages === "function") {
          setNoOfPages(response.data.noOfPages);
        }
        dispatch(updateMembership(temp));
      }
      ATexpired(response, dispatch, removeCookie, history);
    } catch (err) {
      throw err;
    }
  }
};

export const getAllMemberships = async (
  user,
  dispatch,
  NotificationManager,
  removeCookie,
  history,
  nodeId,
  setAllMemberships,
  includeChildMemberships,
  includeExpired
) => {
  let pageNo = 1;
  let memberships = [];
  try {
    while (pageNo < 10000 && nodeId) {
      let url = `${rootApi}node/memberships?nodeId=${nodeId}&includeChildMemberships=${
        includeChildMemberships ? includeChildMemberships : false
      }&includeExpired=${
        includeExpired ? includeExpired : false
      }&includeDepartmentName=true&pageNo=${pageNo}&pageSize=1000`;
      let response = await get(dispatch, url, user.AT);
      if (!response.data.success) {
        createNotification(NotificationManager, notificationType.error, response.data.error);
        break;
      } else {
        memberships = [...memberships, ...response.data.memberships];
        if (pageNo === response.data.noOfPages || response.data.noOfPages === 0) {
          break;
        } else {
          pageNo++;
        }
      }
      ATexpired(response, dispatch, removeCookie, history);
    }
    setAllMemberships([...memberships]);
    return { success: true, memberships };
  } catch (err) {
    throw err;
  }
};

export const searchPeopleAPI = async (
  user,
  dispatch,
  NotificationManager,
  removeCookie,
  history,
  selectedNode,
  searchKey,
  utils,
  pageNo,
  pageSize,
  setDisplaySearch,
  setDisplayValues,
  setNoOfPages,
  forGroup
) => {
  let { includeExpired, includeChildMemberships } = utils;
  let pgNo = pageNo ? pageNo : 1;
  let pgSize = pageSize ? pageSize : 20;
  let forGroups = forGroup ? forGroup : false;
  const url = `${rootApi}node/membership/search?nodeId=${
    selectedNode.nodeId
  }&searchKey=${searchKey}&includeExpired=${
    includeExpired ? includeExpired : false
  }&includeChildMemberships=${
    includeChildMemberships ? includeChildMemberships : false
  }&pageNo=${pgNo}&pageSize=${pgSize}&forGroups=${forGroups}`;
  try {
    const response = await get(dispatch, url, user.AT);
    if (!response.data.success) {
      createNotification(NotificationManager, notificationType.error, response.data.error);
    } else {
      setDisplayValues(response.data.searchResult);
      setDisplaySearch(true);
      if (typeof setNoOfPages === "function") {
        setNoOfPages(response.data.noOfPages);
      }
    }
    ATexpired(response, dispatch, removeCookie, history);
  } catch (err) {
    throw err;
  }
};

export const getAccessGroupsAPI = async (
  user,
  dispatch,
  NotificationManager,
  removeCookie,
  history,
  nodeId
) => {
  const url = `${rootApi}node/accessGroups?nodeId=${nodeId}`;
  try {
    const response = await get(dispatch, url, user.AT);
    if (!response.data.success) {
      createNotification(NotificationManager, notificationType.error, response.data.error);
    } else {
      dispatch(updateGroups(response.data.accessGroups));
    }
    ATexpired(response, dispatch, removeCookie, history);
  } catch (err) {
    throw err;
  }
};

export const getAccessInfoAPI = async (
  user,
  dispatch,
  NotificationManager,
  removeCookie,
  history,
  nodeId,
  membershipId,
  setAccessInfo
) => {
  const url = `${rootApi}node/membership/accessInfo?nodeId=${nodeId}&membershipId=${membershipId}`;
  const response = await get(dispatch, url, user.AT);
  if (!response.data.success) {
    createNotification(NotificationManager, notificationType.error, response.data.error);
  } else {
    setAccessInfo(response.data.membership);
  }
  ATexpired(response, dispatch, removeCookie, history);
};

export const membershipAccessInfoRevoke = async (
  user,
  dispatch,
  NotificationManager,
  removeCookie,
  history,
  nodeId,
  membershipId,
  setAccessInfo
) => {
  const url = `${rootApi}membership/accessInfo/revoke`;
  const body = { membershipId, nodeId };
  try {
    const response = await post(dispatch, url, body, user.AT);
    if (!response.data.success) {
      createNotification(NotificationManager, notificationType.error, response.data.error);
    } else {
      getAccessInfoAPI(
        user,
        dispatch,
        NotificationManager,
        removeCookie,
        history,
        nodeId,
        membershipId,
        setAccessInfo
      );
    }
    ATexpired(response, dispatch, removeCookie, history);
  } catch (err) {
    throw err;
  }
};

export const removeDeviceAPI = async (
  user,
  dispatch,
  NotificationManager,
  removeCookie,
  history,
  customDeviceId,
  selectedNode,
  confirmAlert,
  setDevices
) => {
  confirmAlert({
    title: "Confirm to delete",
    message: "Are you sure you want to remove this device ?",
    buttons: [
      {
        label: "Yes",
        onClick: async () => {
          const url = `${rootApi}door/removeDevice`;
          try {
            const response = await post(
              dispatch,
              url,
              { customDeviceId, nodeId: selectedNode.nodeId },
              user.AT
            );
            if (!response.data.success) {
              createNotification(NotificationManager, notificationType.error, response.data.error);
            } else {
              getDevices(
                user,
                dispatch,
                NotificationManager,
                removeCookie,
                history,
                selectedNode.nodeId,
                setDevices
              );
            }
            getNodeInfo(user, dispatch, NotificationManager, removeCookie, history, selectedNode);
            ATexpired(response, dispatch, removeCookie, history);
          } catch (err) {
            throw err;
          }
        },
      },
      {
        label: "No",
        onClick: () => null,
      },
    ],
  });
};

export const getInActiveDevices = async (
  user,
  dispatch,
  NotificationManager,
  removeCookie,
  history,
  setDevices
) => {
  const url = `${rootApi}user/devices?unassigned=true`;
  try {
    const response = await get(dispatch, url, user.AT);
    if (!response.data.success) {
      createNotification(NotificationManager, notificationType.error, response.data.error);
    } else {
      setDevices(response.data.devices);
    }
    ATexpired(response, dispatch, removeCookie, history);
  } catch (err) {
    throw err;
  }
};

export const addUserAlertAPI = async (
  user,
  dispatch,
  NotificationManager,
  removeCookie,
  history,
  selectedNode,
  userId,
  closeForm,
  setUsersToAlert
) => {
  const url = `${rootApi}node/alerts/addUser`;

  const nodeId = selectedNode.nodeId;
  const body = { nodeId, userId };

  try {
    const response = await post(dispatch, url, body, user.AT);
    if (!response.data.success) {
      createNotification(NotificationManager, notificationType.error, response.data.error);
    } else {
      getNodeUsersToAlert(
        user,
        dispatch,
        NotificationManager,
        removeCookie,
        history,
        selectedNode,
        setUsersToAlert
      );
      closeForm();
    }
    ATexpired(response, dispatch, removeCookie, history);
  } catch (err) {
    throw err;
  }
};

export const getNodeInfo = async (
  user,
  dispatch,
  NotificationManager,
  removeCookie,
  history,
  selectedNode
) => {
  const url = `${rootApi}node/info?nodeId=${selectedNode.nodeId}`;
  try {
    let response = await get(dispatch, url, user.AT);
    if (!response.data.success) {
      createNotification(NotificationManager, notificationType.error, response.data.error);
      dispatch(
        updateNodeInfo({
          success: false,
        })
      );
    } else {
      getNodes(user, dispatch, NotificationManager, removeCookie, history);
      // let features = { ...response.data.features };
      // delete
      if (response.data.features.allowGroups) delete response.data.features.allowGroups;
      dispatch(
        updateNodeInfo({
          ...response.data,
          // peopleWithAccess: response.data.peopleWithAccess,
          // peopleInside: response.data.peopleInside,
        })
      );
      if (selectedNode.nodeId === response.data.nodeId) {
        dispatch(
          updateSelectedNode({
            ...selectedNode,
            name: response.data.name,
            rootNodeId: response.data.rootNodeId,
          })
        );
      }
    }
    ATexpired(response, dispatch, removeCookie, history);
  } catch (err) {
    throw err;
  }
};
export const updateNodeInfoAPI = async (
  user,
  dispatch,
  NotificationManager,
  removeCookie,
  history,
  selectedNode,
  body,
  closeForm
) => {
  const url = `${rootApi}node/info/update`;
  let b = sanitizeReqBody(body);
  try {
    let csNodeResponse;
    if (body.newCsNodeId && body.newCsNodeId.length) {
      csNodeResponse = await updateCsNodeId(
        user,
        dispatch,
        NotificationManager,
        removeCookie,
        history,
        selectedNode,
        body.newCsNodeId
      );
    }
    const response = await post(dispatch, url, b, user.AT);
    if ((response && !response.data.success) || (csNodeResponse && !csNodeResponse.data.success)) {
      createNotification(NotificationManager, notificationType.error, response.data.error);
    } else {
      getNodeInfo(user, dispatch, NotificationManager, removeCookie, history, selectedNode);
      createNotification(NotificationManager, notificationType.success, response.data.message);
      if (typeof closeForm === "function") {
        closeForm();
      }
    }
    ATexpired(response, dispatch, removeCookie, history);
  } catch (err) {
    throw err;
  }
};

export const updateCsNodeId = async (
  user,
  dispatch,
  NotificationManager,
  removeCookie,
  history,
  selectedNode,
  newCsNodeId
) => {
  const url = `${rootApi}node/updateCsNodeId`;
  let b = {
    nodeId: selectedNode.nodeId,
    newCsNodeId,
  };

  try {
    const response = await post(dispatch, url, b, user.AT);
    if (!response.data.success) {
      createNotification(NotificationManager, notificationType.error, response.data.error);
    } else {
      createNotification(NotificationManager, notificationType.success, response.data.message);
      return response;
    }
    ATexpired(response, dispatch, removeCookie, history);
  } catch (err) {
    throw err;
  }
};

export const deviceAddAPI = async (
  user,
  dispatch,
  NotificationManager,
  removeCookie,
  history,
  deviceAddData,
  deviceId,
  closeForm
) => {
  const url = `${rootApi}door/addDevice`;
  const { doorId, nodeId, mode } = deviceAddData;

  const body = mode ? { deviceId, mode, doorId, nodeId } : { deviceId, doorId, nodeId };
  try {
    const response = await post(dispatch, url, body, user.AT);
    if (!response.data.success) {
      createNotification(NotificationManager, notificationType.error, response.data.error);
    } else {
      getNodeInfo(user, dispatch, NotificationManager, removeCookie, history, deviceAddData);
      closeForm();
    }
    ATexpired(response, dispatch, removeCookie, history);
  } catch (err) {
    console.log("member add failed");
  }
};

export const addDoorAPI = async (
  user,
  dispatch,
  NotificationManager,
  removeCookie,
  history,
  doorName,
  selectedNode,
  closeForm
) => {
  const url = `${rootApi}node/addDoor`;

  const body = {
    doorName,
    nodeId: selectedNode.nodeId,
  };

  try {
    const response = await post(dispatch, url, body, user.AT);
    if (!response.data.success) {
      createNotification(NotificationManager, notificationType.error, response.data.error);
    } else {
      getNodeInfo(user, dispatch, NotificationManager, removeCookie, history, selectedNode);
      closeForm();
    }
    ATexpired(response, dispatch, removeCookie, history);
  } catch (err) {
    throw err;
  }
};

export const updateUserNameAPI = async (
  user,
  dispatch,
  NotificationManager,
  removeCookie,
  history,
  selectedNode,
  formData,
  closeForm,
  utils
) => {
  const url = `${rootApi}user/updateInfo`;
  try {
    const response = await post(dispatch, url, formData, user.AT);
    if (!response.data.success) {
      createNotification(NotificationManager, notificationType.error, response.data.error);
    } else {
      getPeopleAPI(user, dispatch, NotificationManager, removeCookie, history, selectedNode, utils);
      closeForm();
    }
    ATexpired(response, dispatch, removeCookie, history);
  } catch (err) {
    throw err;
  }
};

export const addGroupAPI = async (
  user,
  dispatch,
  NotificationManager,
  removeCookie,
  history,
  checkedNodes,
  formData,
  selectedNode,
  utils,
  closeForm
) => {
  const url = `${rootApi}accessgroup/create`;
  let accessInfo = Object.values(checkedNodes);
  accessInfo = accessInfo.map((obj) => {
    if (obj.startTimeInDay && obj.startTimeInDay) {
      return {
        ...obj,
        startTimeInDay: encodeTime(obj.startTimeInDay),
        endTimeInDay: encodeTime(obj.endTimeInDay),
      };
    } else if (obj.endTimeInDay) {
      return {
        ...obj,
        endTimeInDay: encodeTime(obj.endTimeInDay),
      };
    } else if (obj.startTimeInDay) {
      return {
        ...obj,
        startTimeInDay: encodeTime(obj.startTimeInDay),
      };
    }
  });
  accessInfo = accessInfo.filter((v) => v);
  const body = {
    ...formData,
    accessInfo: JSON.stringify(accessInfo),
    membershipIds: JSON.stringify(formData.membershipIds),
  };
  try {
    const response = await post(dispatch, url, body, user.AT);
    if (!response.data.success) {
      createNotification(NotificationManager, notificationType.error, response.data.error);
    } else {
      getAccessGroupsAPI(
        user,
        dispatch,
        NotificationManager,
        removeCookie,
        history,
        selectedNode.nodeId
      );
      getPeopleAPI(user, dispatch, NotificationManager, removeCookie, history, selectedNode, utils);
      closeForm();
    }
    ATexpired(response, dispatch, removeCookie, history);
  } catch (err) {
    console.log("group creation failed");
  }
};

export const updateMemberShipAPI = async (
  user,
  dispatch,
  NotificationManager,
  removeCookie,
  history,
  formData,
  checkedNodes,
  nodeInfo,
  selectedNode,
  closeForm,
  utils
) => {
  const url = `${rootApi}membership/update`;
  let accessInfo = Object.values(checkedNodes);
  accessInfo = accessInfo.map((obj) => {
    if (obj.startTimeInDay && obj.startTimeInDay) {
      return {
        ...obj,
        startTimeInDay: encodeTime(obj.startTimeInDay),
        endTimeInDay: encodeTime(obj.endTimeInDay),
      };
    } else if (obj.endTimeInDay) {
      return {
        ...obj,
        endTimeInDay: encodeTime(obj.endTimeInDay),
      };
    } else if (obj.startTimeInDay) {
      return {
        ...obj,
        startTimeInDay: encodeTime(obj.startTimeInDay),
      };
    }
  });
  accessInfo = accessInfo.filter((v) => v);
  let body = {
    ...formData,
    roles: JSON.stringify(formData.roles),
    accessInfo: JSON.stringify(accessInfo),
  };

  if (formData.endTime) {
    let endTime;
    endTime = parseInt(Date.parse(`${formData.endTime}`) / 1000);
    body = {
      ...body,
      endTime,
    };
  }
  if (formData.startTime) {
    let startTime;
    startTime = parseInt(Date.parse(`${formData.startTime}`) / 1000);
    body = {
      ...body,
      startTime,
    };
  }

  try {
    const response = await post(dispatch, url, body, user.AT);
    if (!response.data.success) {
      createNotification(NotificationManager, notificationType.error, response.data.error);
    } else {
      getPeopleAPI(user, dispatch, NotificationManager, removeCookie, history, selectedNode, utils);
      closeForm();
    }
    ATexpired(response, dispatch, removeCookie, history);
  } catch (err) {
    console.log("group update failed");
  }
};

export const updateGroupApi = async (
  user,
  dispatch,
  NotificationManager,
  removeCookie,
  history,
  checkedNodes,
  formData,
  selectedNode,
  closeForm,
  utils
) => {
  const url = `${rootApi}accessgroup/update`;
  let accessInfo = Object.values(checkedNodes);
  accessInfo = accessInfo.map((obj) => {
    if (obj.startTimeInDay && obj.startTimeInDay) {
      return {
        ...obj,
        startTimeInDay: encodeTime(obj.startTimeInDay),
        endTimeInDay: encodeTime(obj.endTimeInDay),
      };
    } else if (obj.endTimeInDay) {
      return {
        ...obj,
        endTimeInDay: encodeTime(obj.endTimeInDay),
      };
    } else if (obj.startTimeInDay) {
      return {
        ...obj,
        startTimeInDay: encodeTime(obj.startTimeInDay),
      };
    }
  });
  accessInfo = accessInfo.filter((v) => v);
  const body = {
    ...formData,
    accessInfo: JSON.stringify(accessInfo),
    membershipIds: JSON.stringify(formData.membershipIds),
  };
  try {
    const response = await post(dispatch, url, body, user.AT);
    if (!response.data.success) {
      createNotification(NotificationManager, notificationType.error, response.data.error);
    } else {
      getAccessGroupsAPI(
        user,
        dispatch,
        NotificationManager,
        removeCookie,
        history,
        selectedNode.nodeId
      );
      getPeopleAPI(user, dispatch, NotificationManager, removeCookie, history, selectedNode, utils);
      closeForm();
    }
    ATexpired(response, dispatch, removeCookie, history);
  } catch (err) {
    console.log("group update failed");
  }
};

export const removeMembershipFromAccessGroup = async (
  user,
  dispatch,
  NotificationManager,
  removeCookie,
  history,
  accessGroupId,
  membershipId,
  selectedNode,
  utils
) => {
  const url = `${rootApi}accessgroup/membership/remove`;
  const body = {
    accessGroupId,
    membershipId,
  };
  try {
    const response = await post(dispatch, url, body, user.AT);
    if (!response.data.success) {
      createNotification(NotificationManager, notificationType.error, response.data.error);
    } else {
      getAccessGroupsAPI(
        user,
        dispatch,
        NotificationManager,
        removeCookie,
        history,
        selectedNode.nodeId
      );
      getPeopleAPI(user, dispatch, NotificationManager, removeCookie, history, selectedNode, utils);
    }
    ATexpired(response, dispatch, removeCookie, history);
  } catch (err) {
    console.log("Membership remove Failed");
  }
};

export const addMemberAPI = async (
  user,
  dispatch,
  NotificationManager,
  removeCookie,
  history,
  checkedNodes,
  formData,
  selectedNode,
  closeForm,
  utils,
  bulkUpload
) => {
  const url = `${rootApi}membership/bulkAddOrUpdate`;
  let accessInfo = Object.values(checkedNodes);
  accessInfo = accessInfo.map((obj) => {
    if (obj.startTimeInDay && obj.endTimeInDay) {
      return {
        ...obj,
        startTimeInDay: encodeTime(obj.startTimeInDay),
        endTimeInDay: encodeTime(obj.endTimeInDay),
      };
    } else if (obj.endTimeInDay) {
      return {
        ...obj,
        endTimeInDay: encodeTime(obj.endTimeInDay),
      };
    } else if (obj.startTimeInDay) {
      return {
        ...obj,
        endTimeInDay: encodeTime(obj.startTimeInDay),
      };
    }
  });
  accessInfo = accessInfo.filter((v) => v);
  let body;

  if (bulkUpload) {
    let nodeId = formData.nodeId;
    body = {
      nodeId,
      bulkUserMembershipData: [],
    };

    for (let i = 0; i < formData.userName.length; i++) {
      let nodeId = formData.nodeId;
      let [firstName, lastName] = formData.userName[i].split(/ (.*)/);
      let UDId = formData.UDId[i];
      let bloodGroup = formData.bloodGroups[i];
      let depId = formData.depId[i];
      let subDepId = formData.subDepId[i];
      let designation = formData.designation[i];
      let emergencyContactNo = formData.emergencyContactNos[i];
      let endTime = formData.endTimes[i];
      let startTime = formData.startTimes[i];
      let mobileNo = "";
      let emailId = "";
      if (formData.mobileNos && formData.mobileNos.length) {
        mobileNo = formData.mobileNos[i];
        body.bulkUserMembershipData.push({
          firstName,
          lastName,
          UDId,
          bloodGroup,
          depId,
          subDepId,
          designation,
          emergencyContactNo,
          roles: formData.roles,
          endTime,
          // startTime,
          mobileNo,
        });
      } else {
        emailId = formData.emailIds[i];
        body.bulkUserMembershipData.push({
          firstName,
          lastName,
          UDId,
          bloodGroup,
          depId,
          subDepId,
          designation,
          emergencyContactNo,
          endTime,
          // startTime,
          emailId,
          roles: formData.roles,
          accessInfo: accessInfo,
        });
      }
      if (i === formData.userName.length - 1) {
        body = { ...body, bulkUserMembershipData: JSON.stringify(body.bulkUserMembershipData) };
      }
    }
  } else {
    let nodeId = formData.nodeId;
    let [firstName, lastName] = formData.userName.split(/ (.*)/);
    delete formData.nodeId;
    delete formData.userName;

    body = {
      nodeId,
      bulkUserMembershipData: JSON.stringify([
        {
          ...formData,
          firstName,
          lastName,
          roles: formData.roles,
          accessInfo: accessInfo,
          // startTime: parseInt(Date.parse(`${formData.startTime}`) / 1000),
          endTime: parseInt(Date.parse(`${formData.endTime}`) / 1000),
        },
      ]),
    };
  }
  try {
    const response = await post(dispatch, url, body, user.AT);
    if (!response.data.success) {
      createNotification(NotificationManager, notificationType.error, response.data.error);
      return response.data;
    } else {
      getPeopleAPI(user, dispatch, NotificationManager, removeCookie, history, selectedNode, utils);
      closeForm();
      return response.data;
    }
    ATexpired(response, dispatch, removeCookie, history);
  } catch (err) {
    console.log("member add failed");
    return null;
  }
};

export const updateRfid = async (
  user,
  dispatch,
  NotificationManager,
  removeCookie,
  history,
  selectedNode,
  rfid,
  userId,
  membershipData,
  utils
) => {
  const url = `${rootApi}RFCard/assignUser`;
  const nodeId = selectedNode.nodeId;
  const body = {
    customRFId: rfid,
    userId,
  };
  try {
    const response = await post(dispatch, url, body, user.AT);

    if (!response.data.success) {
      createNotification(NotificationManager, notificationType.error, response.data.error);
    } else {
      getPeopleAPI(user, dispatch, NotificationManager, removeCookie, history, selectedNode, utils);
      dispatch(
        updateFormData(formNames.rfidAddForm, {
          ...membershipData,
          RFCards: [...membershipData.RFCards, { customRFId: parseInt(rfid) }],
        })
      );
      // closeForm();
    }
    ATexpired(response, dispatch, removeCookie, history);
  } catch (err) {
    throw err;
  }
};

export const removeRfid = async (
  user,
  dispatch,
  NotificationManager,
  removeCookie,
  history,
  customRFId,
  userId,
  selectedNode,
  membershipData,
  utils
) => {
  const url = `${rootApi}RFCard/removeUser`;
  const body = {
    customRFId,
    userId,
  };
  try {
    const response = await post(dispatch, url, body, user.AT);
    if (!response.data.success) {
      createNotification(NotificationManager, notificationType.error, response.data.error);
    } else {
      getPeopleAPI(user, dispatch, NotificationManager, removeCookie, history, selectedNode, utils);
      let temp = membershipData.RFCards.filter((v) => v.customRFId !== customRFId);
      dispatch(
        updateFormData(formNames.rfidAddForm, {
          ...membershipData,
          RFCards: [...temp],
        })
      );
    }
    ATexpired(response, dispatch, removeCookie, history);
  } catch (err) {
    throw err;
  }
};

export const visitorAddAPI = async (
  user,
  dispatch,
  NotificationManager,
  removeCookie,
  history,
  checkedNodes,
  formData,
  closeForm,
  selectedNode
) => {
  const url = `${rootApi}node/visitor/add`;
  let permission = Object.values(checkedNodes);
  permission = permission.map((obj) => {
    if (obj.startTimeInDay && obj.startTimeInDay) {
      return {
        ...obj,
        startTimeInDay: encodeTime(obj.startTimeInDay),
        endTimeInDay: encodeTime(obj.endTimeInDay),
      };
    } else if (obj.endTimeInDay) {
      return {
        ...obj,

        endTimeInDay: encodeTime(obj.endTimeInDay),
      };
    } else if (obj.startTimeInDay) {
      return {
        ...obj,
        startTimeInDay: encodeTime(obj.startTimeInDay),
      };
    }
  });
  permission = permission.filter((v) => v);
  const body = {
    ...formData,
    accessInfo: JSON.stringify(permission),
    startTime: parseInt(Date.parse(`${formData.startTime}`) / 1000),
    endTime: parseInt(Date.parse(`${formData.endTime}`) / 1000),
  };
  try {
    const response = await post(dispatch, url, body, user.AT);
    if (!response.data.success) {
      createNotification(NotificationManager, notificationType.error, response.data.error);
    } else {
      // getVisitorsLogsAPI(user, dispatch, NotificationManager, removeCookie, history, selectedNode);
      closeForm();
    }
    ATexpired(response, dispatch, removeCookie, history);
  } catch (err) {
    console.log("visitor add failed");
  }
};

export const getNodes = async (user, dispatch, NotificationManager, removeCookie, history) => {
  const url = `${rootApi}user/node/hierarchy`;
  try {
    const response = await get(dispatch, url, user.AT);
    if (!response.data.success) {
      createNotification(NotificationManager, notificationType.error, response.data.error);
    } else {
      dispatch(updateNodes(response.data.nodes));
    }
    ATexpired(response, dispatch, removeCookie, history);
  } catch (err) {
    throw err;
  }
};

export const addZoneAPI = async (
  user,
  dispatch,
  NotificationManager,
  removeCookie,
  history,
  formData,
  addRootNode,
  closeForm,
  selectedNode
) => {
  let body = {
    ...formData,
  };
  const url = addRootNode ? `${rootApi}node/addRootNode` : `${rootApi}node/add`;
  let b = sanitizeReqBody(body);
  b = addRootNode ? { ...b } : { ...b, parentNodeId: selectedNode.nodeId };
  try {
    const response = await post(dispatch, url, b, user.AT);
    if (!response.data.success) {
      createNotification(NotificationManager, notificationType.error, response.data.error);
    } else {
      getNodes(user, dispatch, NotificationManager, removeCookie, history);
      const { roles, visitorPermissions } = await getUserRoles(
        { AT: response.data.AT },
        dispatch,
        NotificationManager,
        removeCookie,
        history
      );

      const r = {};
      Object.values(roles).forEach((ra) =>
        ra.roles.forEach((rl) => {
          r[rl.nodeId] = rl.role;
        })
      );

      dispatch(updateUser({ ...user, roles: r, visitorPermissions }));
      closeForm();
    }
    ATexpired(response, dispatch, removeCookie, history);
  } catch (err) {
    throw err;
  }
};

export const removeAccessGroupAPI = async (
  user,
  dispatch,
  NotificationManager,
  removeCookie,
  history,
  nodeId,
  accessGroupId,
  confirmAlert
) => {
  confirmAlert({
    title: "Confirm to delete",
    message: "Are you sure you want to delete this Group ?",
    buttons: [
      {
        label: "Yes",
        onClick: async () => {
          const url = `${rootApi}accessgroup/remove`;
          const body = { nodeId, accessGroupId };

          try {
            const response = await post(dispatch, url, body, user.AT);
            if (!response.data.success) {
              createNotification(NotificationManager, notificationType.error, response.data.error);
            } else {
              getAccessGroupsAPI(user, dispatch, NotificationManager, removeCookie, history, nodeId);
            }
            ATexpired(response, dispatch, removeCookie, history);
          } catch (err) {
            throw err;
          }
        },
      },
      {
        label: "No",
        onClick: () => null,
      },
    ],
  });
};

export const getSelectedNodeHierarchy = async (
  user,
  dispatch,
  NotificationManager,
  removeCookie,
  history,
  selectedNode
) => {
  const url = `${rootApi}node/hierarchy?nodeId=${selectedNode.nodeId}`;
  if (isAdmin(user, selectedNode))
    try {
      const response = await get(dispatch, url, user.AT);
      if (!response.data.success) {
        createNotification(NotificationManager, notificationType.error, response.data.error);
      } else {
        dispatch(updateSelectedNodeHierarchy([response.data]));
      }
      ATexpired(response, dispatch, removeCookie, history);
    } catch (err) {
      throw err;
    }
};

export const getNodeUsersToAlert = async (
  user,
  dispatch,
  NotificationManager,
  removeCookie,
  history,
  selectedNode,
  setUsersToAlert
) => {
  const url = `${rootApi}node/alerts/users?nodeId=${selectedNode.nodeId}`;
  try {
    const response = await get(dispatch, url, user.AT);
    if (!response.data.success) {
      createNotification(NotificationManager, notificationType.error, response.data.error);
    } else {
      setUsersToAlert(response.data.alertUsers);
    }
    ATexpired(response, dispatch, removeCookie, history);
  } catch (err) {
    throw err;
  }
};

export const removeUserFromAlertGroupAPI = async (
  user,
  dispatch,
  NotificationManager,
  removeCookie,
  history,
  userId,
  selectedNode,
  setUsersToAlert
) => {
  const url = `${rootApi}node/alerts/removeUser`;
  let body = { nodeId: selectedNode.nodeId, userId };
  try {
    const response = await post(dispatch, url, body, user.AT);
    if (!response.data.success) {
      createNotification(NotificationManager, notificationType.error, response.data.error);
    } else {
      getNodeUsersToAlert(
        user,
        dispatch,
        NotificationManager,
        removeCookie,
        history,
        selectedNode,
        setUsersToAlert
      );
    }
    ATexpired(response, dispatch, removeCookie, history);
  } catch (err) {
    throw err;
  }
};

export const deleteDoorAPI = async (
  user,
  dispatch,
  NotificationManager,
  removeCookie,
  history,
  confirmAlert,
  doorId,
  selectedNode
) => {
  const url = `${rootApi}door/delete`;
  confirmAlert({
    title: "Confirm to delete",
    message: "Are you sure you want to remove this door ?",
    buttons: [
      {
        label: "Yes",
        onClick: async () => {
          try {
            const response = await post(dispatch, url, { doorId, nodeId: selectedNode.nodeId }, user.AT);
            if (!response.data.success) {
              createNotification(NotificationManager, notificationType.error, response.data.error);
            } else {
              getNodeInfo(user, dispatch, NotificationManager, removeCookie, history, selectedNode);
            }
            ATexpired(response, dispatch, removeCookie, history);
          } catch (err) {
            throw err;
          }
        },
      },
      {
        label: "No",
        onClick: () => null,
      },
    ],
  });
};

export const rfCardAdd = async (
  e,
  user,
  dispatch,
  NotificationManager,
  removeCookie,
  history,
  setResponse
) => {
  e.preventDefault();
  const url = `${rootApi}RFCard/add`;
  const body = {
    cardIdentifier: e.target.cardIdentifier.value,
  };
  try {
    const response = await post(dispatch, url, body, user.AT);
    if (!response.data.success) {
      createNotification(NotificationManager, notificationType.error, response.data.error);
    } else {
      const { error, success, message, customRFId } = response.data;
      setResponse({ error, success, message, customRFId });
    }
    ATexpired(response, dispatch, removeCookie, history);
  } catch (err) {
    console.log("rf card add failed");
  }
};

export const getDevices = async (
  user,
  dispatch,
  NotificationManager,
  removeCookie,
  history,
  id,
  setDevices
) => {
  const url = `${rootApi}node/devices?nodeId=${id}`;

  try {
    const response = await get(dispatch, url, user.AT);
    if (!response.data.success) {
      createNotification(NotificationManager, notificationType.error, response.data.error);
    } else {
      setDevices(response.data.devices);
    }
    ATexpired(response, dispatch, removeCookie, history);
  } catch (err) {
    throw err;
  }
};

export const getUnassignedDivices = async (
  user,
  dispatch,
  NotificationManager,
  removeCookie,
  history,
  setDevices
) => {
  const url = `${rootApi}user/devices?unassigned=true`;
  try {
    const response = await get(dispatch, url, user.AT);
    if (!response.data.success) {
      createNotification(NotificationManager, notificationType.error, response.data.error);
    } else {
      setDevices(response.data.devices);
    }
    ATexpired(response, dispatch, removeCookie, history);
  } catch (err) {
    throw err;
  }
};

export const deleteZoneAPI = async (
  user,
  dispatch,
  NotificationManager,
  removeCookie,
  history,
  selectedNode,
  confirmAlert,
  nodes
) => {
  confirmAlert({
    title: "Confirm to delete",
    message:
      "This will remove all the child nodes, doors, and also all the devices assigned to them. This is an irreversible action. Are you sure you want to remove this zone? ",
    buttons: [
      {
        label: "Yes",
        onClick: async () => {
          const url = `${rootApi}node/delete`;
          const nodeId = selectedNode.nodeId;
          try {
            const response = await post(dispatch, url, { nodeId }, user.AT);
            if (!response.data.success) {
              createNotification(NotificationManager, notificationType.error, response.data.error);
            } else {
              await getNodes(user, dispatch, NotificationManager, removeCookie, history);
              dispatch(
                updateSelectedNode({
                  name: nodes[0].name,
                  nodeId: nodes[0].nodeId,
                  expanded: false,
                  parentId: nodes[0].parentId,
                })
              );
            }
            ATexpired(response, dispatch, removeCookie, history);
          } catch (err) {
            throw err;
          }
        },
      },
      {
        label: "No",
        onClick: () => null,
      },
    ],
  });
};

export const getUserInfo = async (user, dispatch, NotificationManager, removeCookie, history) => {
  try {
    let response = await get(dispatch, `${rootApi}user/info`, user.AT);

    if (!response.data.success) {
      createNotification(NotificationManager, notificationType.error, response.data.error);
    } else {
      const { roles, visitorPermissions } = await getUserRoles(
        { AT: response.data.AT },
        dispatch,
        NotificationManager,
        removeCookie,
        history
      );

      const r = {};
      Object.values(roles).forEach((ra) =>
        ra.roles.forEach((rl) => {
          console.log({ ra, rl });
          if (r[rl.nodeId]) {
            r[rl.nodeId].push(rl.role);
          } else {
            r[rl.nodeId] = [rl.role];
          }
        })
      );

      dispatch(updateUser({ ...response.data, roles: r, visitorPermissions }));
      if (user.initialPath) {
        window.location.href = user.initialPath;
      }
    }
    ATexpired(response, dispatch, removeCookie, history);
  } catch (err) {
    console.log(err);
  }
};

export const generateOACs = async (
  user,
  dispatch,
  NotificationManager,
  removeCookie,
  history,
  membershipId,
  selectedNode,
  customOAC,
  OACType,
  startTime,
  endTime,
  OACs,
  setOACs,
  includeChild
) => {
  const url = `${rootApi}node/generateOACs`;
  if (!endTime) {
    endTime = "";
  }
  if (!startTime) {
    startTime = "";
  }
  const body = {
    OACType,
    startTime,
    nodeId: selectedNode.nodeId,
    grantedTo: membershipId,
    endTime,
    includeChild,
  };
  if (customOAC) {
    body.customOAC = customOAC;
  }
  try {
    console.log(body);
    const response = await post(dispatch, url, body, user.AT);
    if (!response.data.success) {
      createNotification(NotificationManager, notificationType.error, response.data.error);
    } else {
      console.log(response);
      setOACs([...OACs, ...response.data.OACs]);
    }
    ATexpired(response, dispatch, removeCookie, history);
  } catch (error) {
    console.log(error);
  }
};

export const getOACs = async (
  user,
  dispatch,
  NotificationManager,
  removeCookie,
  history,
  membershipId,
  selectedNode,
  setOACs
) => {
  console.log("members", membershipId, selectedNode);
  const url = `${rootApi}node/OACs?nodeId=${selectedNode.nodeId}&membershipId=${membershipId}&includeChild=true`;
  if (membershipId) {
    try {
      const response = await get(dispatch, url, user.AT);
      if (!response.data.success) {
        createNotification(NotificationManager, notificationType.error, response.data.error);
      } else {
        console.log(response);
        setOACs([...response.data.OACs]);
      }
      ATexpired(response, dispatch, removeCookie, history);
    } catch (error) {
      console.log(error);
    }
  }
};

export const getDeviceIds = async (
  user,
  dispatch,
  NotificationManager,
  removeCookie,
  history,
  setDevices
) => {
  const url = `${rootApi}user/devices?unassigned=true`;
  try {
    const response = await get(dispatch, url, user.AT);
    if (!response.data.success) {
      createNotification(NotificationManager, notificationType.error, response.data.error);
    } else {
      setDevices([...response.data.devices]);
    }
    ATexpired(response, dispatch, removeCookie, history);
  } catch (err) {
    console.log(err);
  }
};

export const addDepartmentAPI = async (
  user,
  body,
  dispatch,
  NotificationManager,
  removeCookie,
  history,
  setResponse,
  selectedNode,
  closeForm
) => {
  const url = `${rootApi}node/department/add`;
  try {
    const response = await post(dispatch, url, body, user.AT);
    if (!response.data.success) {
      createNotification(NotificationManager, notificationType.error, response.data.error);
    } else {
      setResponse(response.data);
      dispatch(updateNodeInfoDepatments(response.data.departments));
    }
    ATexpired(response, dispatch, removeCookie, history);
  } catch (err) {
    console.log("department add failed");
  }
};

export const updateDepartmentAPI = async (
  user,
  body,
  dispatch,
  NotificationManager,
  removeCookie,
  history,
  setResponse,
  selectedNode,
  closeForm
) => {
  const url = `${rootApi}node/department/update`;
  try {
    const response = await post(dispatch, url, body, user.AT);
    if (!response.data.success) {
      createNotification(NotificationManager, notificationType.error, response.data.error);
    } else {
      setResponse(response.data);
      getNodeInfo(user, dispatch, NotificationManager, removeCookie, history, selectedNode);
    }
    ATexpired(response, dispatch, removeCookie, history);
  } catch (err) {
    console.log("department add failed");
  }
};

export const removeDepartmentAPI = async (
  user,
  dispatch,
  NotificationManager,
  removeCookie,
  history,
  confirmAlert,
  selectedNode,
  utils,
  departmentId,
  closeForm
) => {
  confirmAlert({
    title: "Confirm to delete",
    message:
      "This will remove the department, and all its sub departments. Are you sure you want to remove this department? ",
    buttons: [
      {
        label: "Yes",
        onClick: async () => {
          const url = `${rootApi}node/department/remove`;
          const nodeId = selectedNode.nodeId;
          try {
            const response = await post(dispatch, url, { nodeId, departmentId }, user.AT);
            if (!response.data.success) {
              createNotification(NotificationManager, notificationType.error, response.data.error);
            } else {
              getNodeInfo(user, dispatch, NotificationManager, removeCookie, history, selectedNode);
              getPeopleAPI(
                user,
                dispatch,
                NotificationManager,
                removeCookie,
                history,
                selectedNode,
                utils
              );
              if (closeForm) closeForm();
            }
            ATexpired(response, dispatch, removeCookie, history);
          } catch (err) {
            throw err;
          }
        },
      },
      {
        label: "No",
        onClick: () => null,
      },
    ],
  });
};

export const getOauthPermissions = async (
  user,
  dispatch,
  NotificationManager,
  removeCookie,
  history,
  clientId,
  setClientInfo
) => {
  const url = `${rootApi}oauth/clientInfo?clientId=${clientId}`;
  try {
    const response = await get(dispatch, url, user.AT);
    if (!response.data.success) {
      createNotification(NotificationManager, notificationType.error, response.data.error);
      setClientInfo(response.data);
    } else if (response.data.code === 1007) {
      createNotification(NotificationManager, notificationType.success, response.data.message);
      setClientInfo(response.data);
    } else {
      setClientInfo(response.data);
      console.log(response);
    }
    ATexpired(response, dispatch, removeCookie, history);
  } catch (err) {
    console.log(err);
  }
};

export const getOauthApproovedApps = async (
  user,
  dispatch,
  NotificationManager,
  removeCookie,
  history,
  setAllowedApps
) => {
  const url = `${rootApi}oauth/getApprovedApps`;
  try {
    const response = await get(dispatch, url, user.AT);
    if (!response.data.success) {
      createNotification(NotificationManager, notificationType.error, response.data.error);
    } else {
      setAllowedApps(response.data.apps);
    }
    ATexpired(response, dispatch, removeCookie, history);
  } catch (err) {
    console.log(err);
  }
};

export const generateUserCredentials = async (
  user,
  dispatch,
  NotificationManager,
  removeCookie,
  history,
  nodeId,
  setCredentials
) => {
  const url = `${rootApi}user/generateApiKeys`;
  const body = { nodeId };
  try {
    const response = await post(dispatch, url, body, user.AT);
    if (!response.data.success) {
      createNotification(NotificationManager, notificationType.error, response.data.error);
    } else {
      const { accessToken, refreshToken, message } = response.data;
      setCredentials({ accessToken, refreshToken, message });
    }
    ATexpired(response, dispatch, removeCookie, history);
  } catch (err) {
    console.log(err);
  }
};

export const getUserCredintials = async (
  user,
  dispatch,
  NotificationManager,
  removeCookie,
  history,
  nodeId,
  setCredentials
) => {
  const url = `${rootApi}user/getApiKeys`;
  const body = { nodeId };
  try {
    const response = await post(dispatch, url, body, user.AT);
    if (!response.data.success) {
      createNotification(NotificationManager, notificationType.error, response.data.error);
    } else {
      setCredentials(response.data);
    }
    ATexpired(response, dispatch, removeCookie, history);
  } catch (err) {
    setCredentials({ message: "Error processing request, please try again" });
    console.log(err);
  }
};

export const allowOauth = async (
  user,
  dispatch,
  NotificationManager,
  removeCookie,
  history,
  allow,
  scopes,
  clientId,
  state,
  responseType,
  redirectUri,
  location,
  nodeId
) => {
  const url = `${rootApi}oauth/dialog/allow`;
  const body = { allow, scopes, nodeId, clientId, state, responseType, redirectUri };

  try {
    const response = await post(dispatch, url, body, user.AT);
    console.log(response.data);
    if (!response.data.success) {
      createNotification(NotificationManager, notificationType.error, response.data.error);
    } else {
      window.location.href = response.data.redirectUri;
    }
    ATexpired(response, dispatch, removeCookie, history);
  } catch (err) {
    console.log("oauth allow failed");
  }
};

export const updateOAC = async (
  user,
  dispatch,
  NotificationManager,
  removeCookie,
  history,
  nodeId,
  OACId,
  deviceId,
  OAC,
  OACs,
  setOACs
) => {
  const url = `${rootApi}device/updateOAC`;
  const body = {
    OACId,
    OAC,
    nodeId,
    deviceId,
  };
  console.log(OACId, deviceId, nodeId);
  try {
    const response = await post(dispatch, url, body, user.AT);
    console.log(response);
    if (!response.data.success) {
      createNotification(NotificationManager, notificationType.error, response.data.error);
    } else {
      let arr = OACs.map((i) => {
        if (i.OACId === OACId) {
          i.newOAC = OAC;
          i.modified = 1;
        }
        return i;
      });
      console.log(arr);
      setOACs(arr);
    }
    ATexpired(response, dispatch, removeCookie, history);
  } catch (err) {
    console.log("Failed to Delete OAC, ", err);
  }
};

export const deleteOAC = async (
  user,
  dispatch,
  NotificationManager,
  removeCookie,
  history,
  confirmAlert,
  nodeId,
  OACId,
  deviceId,
  OACs,
  setOACs
) => {
  confirmAlert({
    title: "Confirm to delete",
    message: "Are you sure you want to remove this keycode from the device?",
    buttons: [
      {
        label: "Yes",
        onClick: async () => {
          const url = `${rootApi}device/deleteOAC`;
          const body = {
            OACId,
            nodeId,
            deviceId,
          };
          try {
            const response = await post(dispatch, url, body, user.AT);
            // console.log(response);
            if (!response.data.success) {
              createNotification(NotificationManager, notificationType.error, response.data.error);
            } else {
              let arr = OACs.map((i) => {
                if (i.OACId === OACId) {
                  i.disabled = "true";
                  i.modified = 1;
                }
                return i;
              });
              console.log(arr);
              setOACs(arr);
            }
            ATexpired(response, dispatch, removeCookie, history);
          } catch (err) {
            console.log("Failed to Delete OAC, ", err);
          }
        },
      },
      {
        label: "No",
        onClick: () => null,
      },
    ],
  });
};

export const resyncOACs = async (
  user,
  dispatch,
  NotificationManager,
  removeCookie,
  history,
  confirmAlert,
  selectedNode,
  deviceId
) => {
  confirmAlert({
    title: "Confirm to resync OACs",
    message:
      "This will delete all the OACs in the device and resyncs' all the active codes again. Next unlock and sync needs to be done carefully without interruption for this operation to succeed.",
    buttons: [
      {
        label: "Yes",
        onClick: async () => {
          const url = `${rootApi}device/resyncOACs`;
          const nodeId = selectedNode.nodeId;
          const body = {
            nodeId,
            deviceId,
          };
          try {
            const response = await post(dispatch, url, body, user.AT);
            // console.log(response);
            if (!response.data.success) {
              createNotification(NotificationManager, notificationType.error, response.data.error);
            } else {
              createNotification(
                NotificationManager,
                notificationType.success,
                "Resync OACs successful."
              );
            }
            ATexpired(response, dispatch, removeCookie, history);
          } catch (error) {
            console.log("Failed to resync OACs, ", error);
          }
        },
      },
      {
        label: "No",
        onClick: () => null,
      },
    ],
  });
};

export const resyncRFCards = async (
  user,
  dispatch,
  NotificationManager,
  removeCookie,
  history,
  confirmAlert,
  selectedNode,
  deviceId
) => {
  confirmAlert({
    title: "Confirm to resync RFCards",
    message:
      "This will delete all the RF Cards in the device and resyncs' all the active cards again. Next unlock and sync needs to be done carefully without interruption for this operation to succeed.",
    buttons: [
      {
        label: "Yes",
        onClick: async () => {
          const url = `${rootApi}device/resyncRFCards`;
          const nodeId = selectedNode.nodeId;
          const body = {
            nodeId,
            deviceId,
          };
          try {
            const response = await post(dispatch, url, body, user.AT);
            // console.log(response);
            if (!response.data.success) {
              createNotification(NotificationManager, notificationType.error, response.data.error);
            } else {
              createNotification(
                NotificationManager,
                notificationType.success,
                "Resync RFCards successful."
              );
            }
            ATexpired(response, dispatch, removeCookie, history);
          } catch (error) {
            console.log("Failed to resync RFCards, ", error);
          }
        },
      },
      {
        label: "No",
        onClick: () => null,
      },
    ],
  });
};

export const getUserDeviceRequestsAPI = async (
  user,
  dispatch,
  NotificationManager,
  removeCookie,
  history,
  setResponse,
  selectedNode
) => {
  const url = `${rootApi}node/userDevice/requests?nodeId=${selectedNode.nodeId}`;
  // console.log("API CALL------------------------- and nodeId = "+selectedNode.nodeId);
  try {
    const response = await get(dispatch, url, user.AT);
    if (!response.data.success) {
      createNotification(NotificationManager, notificationType.error, response.data.error);
    } else {
      setResponse(response.data);
    }
    ATexpired(response, dispatch, removeCookie, history);
  } catch (err) {
    console.log("Fetching of user device requests failed");
  }
};

export const updateUserDeviceRequestAPI = async (
  user,
  dispatch,
  NotificationManager,
  removeCookie,
  history,
  closeForm,
  setResponse,
  userId,
  userDeviceId,
  nodeId,
  approved
) => {
  const body = {
    nodeId,
    userId,
    userDeviceId,
    approved,
  };
  const url = `${rootApi}user/verifyDeviceWithoutCode`;
  try {
    const response = await post(dispatch, url, body, user.AT);
    if (!response.data.success) {
      createNotification(NotificationManager, notificationType.error, response.data.error);
    } else {
      // closeForm();
      setResponse(response.data);
    }
    ATexpired(response, dispatch, removeCookie, history);
  } catch (err) {
    console.log("Fetching of user device requests failed");
  }
};

export const getDeviceConfig = async (
  user,
  dispatch,
  NotificationManager,
  removeCookie,
  history,
  setResponse,
  selectedNode,
  deviceId
) => {
  const url = `${rootApi}device/configuration?nodeId=${selectedNode.nodeId}&deviceId=${deviceId}`;

  try {
    const response = await get(dispatch, url, user.AT);
    if (!response.data.success) {
      createNotification(NotificationManager, notificationType.error, response.data.error);
    } else {
      setResponse(response.data);
    }
    ATexpired(response, dispatch, removeCookie, history);
  } catch (err) {
    console.log("Fetching of user device requests failed");
  }
};

export const deviceConfigUpdate = async (
  user,
  dispatch,
  NotificationManager,
  removeCookie,
  history,
  setResponse,
  selectedNode,
  deviceId,
  b
) => {
  let body = {
    ...b,
    nodeId: selectedNode.nodeId,
    deviceId,
  };
  const url = `${rootApi}device/configuration/update`;
  try {
    const response = await post(dispatch, url, body, user.AT);
    if (!response.data.success) {
      createNotification(NotificationManager, notificationType.error, response.data.error);
    } else {
      getDeviceConfig(
        user,
        dispatch,
        NotificationManager,
        removeCookie,
        history,
        setResponse,
        selectedNode,
        deviceId
      );
    }
    ATexpired(response, dispatch, removeCookie, history);
  } catch (err) {
    console.log("Fetching of user device requests failed");
  }
};

export const nodeHolidayAdd = async (
  user,
  dispatch,
  NotificationManager,
  removeCookie,
  history,
  selectedNode,
  name,
  timestamp
) => {
  const body = {
    nodeId: selectedNode.nodeId,
    name,
    timestamp,
  };
  const url = `${rootApi}node/holiday/add`;
  try {
    const response = await post(dispatch, url, body, user.AT);
    if (!response.data.success) {
      createNotification(NotificationManager, notificationType.error, response.data.error);
    } else {
      getNodeInfo(user, dispatch, NotificationManager, removeCookie, history, selectedNode);
    }
    ATexpired(response, dispatch, removeCookie, history);
  } catch (err) {
    console.log("Adding Holiday Failed", err);
  }
};
export const nodeHolidayUpdate = async (
  user,
  dispatch,
  NotificationManager,
  removeCookie,
  history,
  selectedNode,
  holidayId,
  name,
  timestamp
) => {
  const body = {
    nodeId: selectedNode.nodeId,
    holidayId,
    name,
    timestamp,
  };
  const url = `${rootApi}node/holiday/update`;
  try {
    const response = await post(dispatch, url, body, user.AT);
    if (!response.data.success) {
      createNotification(NotificationManager, notificationType.error, response.data.error);
    } else {
      getNodeInfo(user, dispatch, NotificationManager, removeCookie, history, selectedNode);
    }
    ATexpired(response, dispatch, removeCookie, history);
  } catch (err) {
    console.log("Updating Holiday Failed", err);
  }
};
export const nodeHolidayRemove = async (
  user,
  dispatch,
  NotificationManager,
  removeCookie,
  history,
  selectedNode,
  holidayId
) => {
  const body = {
    nodeId: selectedNode.nodeId,
    holidayId,
  };
  const url = `${rootApi}node/holiday/remove`;
  try {
    const response = await post(dispatch, url, body, user.AT);
    if (!response.data.success) {
      createNotification(NotificationManager, notificationType.error, response.data.error);
    } else {
      getNodeInfo(user, dispatch, NotificationManager, removeCookie, history, selectedNode);
    }
    ATexpired(response, dispatch, removeCookie, history);
  } catch (err) {
    console.log("removing Holiday Failed", err);
  }
};

export const updateIntegrationCredentialsAPI = async (
  integrationType,
  user,
  dispatch,
  NotificationManager,
  removeCookie,
  history,
  selectedNode,
  formData,
  closeForm
) => {
  const url = `${rootApi}integration/update`;
  const body = {
    nodeId: selectedNode.nodeId,
    integrationType: integrationType,
  };
  if (integrationType === "FRESHTEAMS") {
    body.freshTeamsApiKey = formData.apiKey;
    body.freshTeamsHost = formData.host;
  }
  if (integrationType === "GREYTHR") {
    body.greytHRHost = formData.host;
    body.greytHrApiId = formData.apiId;
    body.greytHrPrivateKey = formData.privateKey;
    body.syncLogsToGreytHr = formData.syncLogsToGreytHr;
  }
  try {
    const response = await post(dispatch, url, body, user.AT);
    if (!response.data.success) {
      createNotification(NotificationManager, notificationType.error, response.data.error);
    } else {
      //fetch the details of all integrations
      closeForm();
    }
    ATexpired(response, dispatch, removeCookie, history);
  } catch (err) {
    console.log("member add failed");
  }
};

export const IntegrationEmployeeListAPI = async (
  user,
  selectedNode,
  integrationType,
  NotificationManager,
  dispatch,
  removeCookie,
  history
) => {
  try {
    const url = `${rootApi}integration/employeeList?nodeId=${selectedNode.nodeId}&integrationType=${integrationType}`;
    const response = await get(dispatch, url, user.AT);
    if (!response.data.success) {
      createNotification(NotificationManager, notificationType.error, response.data.error);
    } else {
      return response.data.employees;
    }
    ATexpired(response, dispatch, removeCookie, history);
  } catch (err) {
    return { success: false, error: "Error while getting employee List" };
  }
};

export const SyncMatchedEmoloyeesAPI = async (
  user,
  selectedNode,
  employeesToSync,
  NotificationManager,
  uniqueIdentifier,
  dispatch,
  removeCookie,
  history
) => {
  try {
    const url = `${rootApi}integration/syncEmployees`;
    const body = {
      nodeId: selectedNode.nodeId,
      employeesToSync: JSON.stringify(employeesToSync),
      identifier: uniqueIdentifier === "Mobile Number" ? "mobileNo" : "email",
    };
    const response = await post(dispatch, url, body, user.AT);
    if (!response.data.success) {
      createNotification(NotificationManager, notificationType.error, response.data.error);
    } else {
      //fetch the details of all integrations
      return response.data;
    }
    ATexpired(response, dispatch, removeCookie, history);
  } catch (err) {
    return { success: false, error: "Error while getting employee List" };
  }
};

export const getIntegrationsDetailsAPI = async (
  user,
  selectedNode,
  NotificationManager,
  dispatch,
  removeCookie,
  history
) => {
  try {
    const url = `${rootApi}integration/integrationDetails?nodeId=${selectedNode.nodeId}`;
    const response = await get(dispatch, url, user.AT);
    if (!response.data.success) {
      createNotification(NotificationManager, notificationType.error, response.data.error);
    } else {
      return response.data;
    }
    ATexpired(response, dispatch, removeCookie, history);
  } catch (err) {
    console.log("Getting Integrations Details failed", err);
    return { success: false, error: "Network error while fetching Integrations Details" };
  }
};

export const updateDoorInfo = async (
  user,
  body,
  NotificationManager,
  dispatch,
  removeCookie,
  history,
  closeForm,
  selectedNode
) => {
  const url = `${rootApi}door/info/update`;
  try {
    const response = await post(dispatch, url, body, user.AT);
    if (!response.data.success) {
      createNotification(NotificationManager, notificationType.error, response.data.error);
    } else {
      closeForm();
      getNodeInfo(user, dispatch, NotificationManager, removeCookie, history, selectedNode);
    }
    ATexpired(response, dispatch, removeCookie, history);
  } catch (err) {
    console.log("Updating Holiday Failed", err);
  }
};

export const getDeviceOACs = async (
  user,
  dispatch,
  NotificationManager,
  removeCookie,
  history,
  deviceId,
  nodeId,
  setOACs
) => {
  try {
    const url = `${rootApi}device/OACs?deviceId=${deviceId}&nodeId=${nodeId}`;
    const response = await get(dispatch, url, user.AT);
    if (!response.data.success) {
      createNotification(NotificationManager, notificationType.error, response.data.error);
    } else {
      console.log(response.data);
      setOACs([...response.data.OACs]);
    }
    ATexpired(response, dispatch, removeCookie, history);
  } catch (err) {
    console.log("Fetching of device OACs failed");
  }
};

export const getDeviceRFData = async (
  user,
  dispatch,
  NotificationManager,
  removeCookie,
  history,
  deviceId,
  nodeId,
  setRFData
) => {
  try {
    const url = `${rootApi}device/RFData?deviceId=${deviceId}&nodeId=${nodeId}`;
    const response = await get(dispatch, url, user.AT);
    if (!response.data.success) {
      createNotification(NotificationManager, notificationType.error, response.data.error);
    } else {
      setRFData([...response.data.RFData]);
    }
    ATexpired(response, dispatch, removeCookie, history);
  } catch (err) {
    console.log("Fetching of device OACs failed");
  }
};

export const getGroupNodeMemberships = async (
  user,
  dispatch,
  NotificationManager,
  removeCookie,
  history,
  selectedNode,
  accessGroupId,
  pageNo,
  pageSize,
  setMemberships,
  setNoOfPages
) => {
  let pgNo = pageNo ? pageNo : 1;
  let pgSize = pageSize ? pageSize : 20;
  const url = `${rootApi}accessgroup/memberships?nodeId=${selectedNode.nodeId}&accessGroupId=${accessGroupId}&pageNo=${pgNo}&pageSize=${pgSize}`;
  try {
    const response = await get(dispatch, url, user.AT);
    if (!response.data.success) {
      createNotification(NotificationManager, notificationType.error, response.data.error);
    } else {
      setMemberships(response.data.memberships);
      setNoOfPages(response.data.noOfPages);
    }
    ATexpired(response, dispatch, removeCookie, history);
  } catch (err) {
    console.log("Failed to GetGroupNodeMemberships", err);
  }
};

export const uploadUserProfileImageAPI = async (
  user,
  dispatch,
  NotificationManager,
  removeCookie,
  history,
  closeForm,
  formData
) => {
  const url = `${rootApi}user/profileImage/upload`;

  try {
    const response = await postMultipartFormData(dispatch, url, formData, user.AT);
    if (!response.data.success) {
      createNotification(NotificationManager, notificationType.error, response.data.error);
    } else {
    }
    ATexpired(response, dispatch, removeCookie, history);
  } catch (err) {
    console.log("Uploading of user profile image failed");
  }
};

export const getUserProfileImageAPI = async (
  user,
  dispatch,
  NotificationManager,
  removeCookie,
  history,
  membershipId,
  setResponse,
  closeForm
) => {
  const url = `${rootApi}user/profileImage?membershipId=${membershipId}`;

  try {
    const response = await get(dispatch, url, user.AT);
    if (!response.data.success) {
      // createNotification(NotificationManager, notificationType.error, response.data.error);
      setResponse("");
    } else {
      setResponse(response.data.userProfileImageURL);
    }
    ATexpired(response, dispatch, removeCookie, history);
  } catch (err) {
    console.log(err);
    console.log("Fetching of user profile image failed");
  }
};

export const deleteUserProfileImageAPI = async (
  user,
  dispatch,
  NotificationManager,
  removeCookie,
  history,
  membershipId,
  closeForm
) => {
  const body = {
    membershipId,
  };
  const url = `${rootApi}user/profileImage/remove`;

  try {
    const response = await post(dispatch, url, body, user.AT);
    if (!response.data.success) {
      createNotification(NotificationManager, notificationType.error, response.data.error);
    } else {
    }
    ATexpired(response, dispatch, removeCookie, history);
  } catch (err) {
    console.log(err);
    console.log("Deleting of user profile image failed");
  }
};

export const registerOauthApp = async (
  user,
  dispatch,
  NotificationManager,
  removeCookie,
  history,
  nodeId,
  formData
) => {
  const url = `${rootApi}oauth/registerApp`;
  const body = {
    name: formData.name,
    redirectUri: formData.redirectUri,
    appDescription: formData.appDescription,
  };
  try {
    const response = await post(dispatch, url, body, user.AT);
    if (!response.data.success) {
      createNotification(NotificationManager, notificationType.error, response.data.error);
    }
    ATexpired(response, dispatch, removeCookie, history);
    return response.data; //returning response
  } catch (err) {
    console.log("Error while creating Oauth app", err);
    return { success: false, error: "Error while registering app" };
  }
};

export const updateOauthApp = async (
  user,
  dispatch,
  NotificationManager,
  removeCookie,
  history,
  formData,
  clientId,
  nodeId,
  closeForm
) => {
  try {
    const url = `${rootApi}oauth/update`;
    const body = {
      clientId: clientId,
      name: formData.name,
      redirectUri: formData.redirectUri,
      appDescription: formData.appDescription,
    };
    const response = await post(dispatch, url, body, user.AT);
    if (!response.data.success) {
      createNotification(NotificationManager, notificationType.error, response.data.error);
    } else {
      closeForm();
    }
    ATexpired(response, dispatch, removeCookie, history);
    return response.data;
  } catch (err) {
    console.log("Error while updating Oauth credentials");
    return { success: false, error: "Error while updating" };
  }
};

export const fetchOauthApps = async (user, dispatch, NotificationManager, removeCookie, history) => {
  try {
    const url = `${rootApi}oauth/oauthApps`;
    const response = await get(dispatch, url, user.AT);
    if (!response.data.success) {
      createNotification(NotificationManager, notificationType.error, response.data.error);
    } else {
      return response.data;
    }
    ATexpired(response, dispatch, removeCookie, history);
    return response.data;
  } catch (err) {
    console.log("Error while fetching oauth apps", err);
    return { success: false, error: "Error while fetching oauth apps" };
  }
};

export const fetchAppDetails = async (
  user,
  dispatch,
  NotificationManager,
  removeCookie,
  history,
  clientId
) => {
  try {
    const url = `${rootApi}oauth/appDetails?clientId=${clientId}`;
    const response = await get(dispatch, url, user.AT);
    if (!response.data.success) {
      createNotification(NotificationManager, notificationType.error, response.data.error);
    } else {
      return response.data;
    }
    ATexpired(response, dispatch, removeCookie, history);
    return response.data; //returning response
  } catch (err) {
    console.log("Error while fetching app details", err);
    return { success: false, error: "Error while fetching app details" };
  }
};
export const getNodeLogSyncStatusRequestsAPI = async (
  user,
  dispatch,
  NotificationManager,
  removeCookie,
  history,
  setResponse,
  selectedNode
) => {
  const url = `${rootApi}node/logs/syncStatus?nodeId=${selectedNode.nodeId}`;
  try {
    const response = await get(dispatch, url, user.AT);
    if (!response.data.success) {
      createNotification(NotificationManager, notificationType.error, response.data.error);
    } else {
      setResponse(response.data);
    }
    ATexpired(response, dispatch, removeCookie, history);
  } catch (err) {
    console.log("Fetching of log sync status failed");
  }
};

export const searchUserNode = async (
  user,
  dispatch,
  NotificationManager,
  removeCookie,
  history,
  input
) => {
  try {
    const url = `${rootApi}operations/findUserNode?${
      isNaN(parseInt(input))
        ? "emailId=" + input
        : input.length === 10
        ? "mobileNo=" + input
        : "customRFId=" + input
    }`;
    const response = await get(dispatch, url, user.AT);
    if (!response.data.success) {
      createNotification(NotificationManager, notificationType.error, response.data.error);
    } else {
      return response.data;
    }
    ATexpired(response, dispatch, removeCookie, history);
    return response.data;
  } catch (err) {
    return { success: false, error: "Error while fetching user Node" };
  }
};

export const findDeviceNode = async (
  user,
  dispatch,
  NotificationManager,
  removeCookie,
  history,
  input
) => {
  try {
    const url = `${rootApi}operations/findDeviceNode?customDeviceId=${input}`;
    const response = await get(dispatch, url, user.AT);
    if (!response.data.success) {
      createNotification(NotificationManager, notificationType.error, response.data.error);
    } else {
      return response.data;
    }
    ATexpired(response, dispatch, removeCookie, history);
    return response.data;
  } catch (err) {
    return { success: false, error: "Error while fetching device Node" };
  }
};

export const fetchNotifications = async (
  user,
  dispatch,
  NotificationManager,
  removeCookie,
  history,
  nodeId,
  pageSize,
  pageNo
) => {
  try {
    const url = `${rootApi}notifications/?nodeId=${nodeId}&pageSize=${pageSize}&pageNo=${pageNo}`;
    const response = await get(dispatch, url, user.AT);
    if (!response.data.success) {
      createNotification(NotificationManager, notificationType.error, response.data.error);
    } else {
      return response.data;
    }
    ATexpired(response, dispatch, removeCookie, history);
  } catch (err) {
    console.log("Error while fetching notifications", err);
    return { success: false, error: "Error while fetching notifications" };
  }
};

export const markNotificationAsReadAPI = async (
  user,
  dispatch,
  NotificationManager,
  removeCookie,
  history,
  nodeId,
  notificationId,
  markAllAsRead,
  pageNo
) => {
  try {
    const url = `${rootApi}notifications/update`;
    let body = {
      nodeId: nodeId,
      notificationId,
      markAllAsRead,
    };

    if (body.markAllAsRead === true) {
      delete body.notificationId;
    } else {
      delete body.markAllAsRead;
    }

    const response = await post(dispatch, url, body, user.AT);
    if (!response.data.success) {
      createNotification(NotificationManager, notificationType.error, response.data.error);
    } else {
      // closeForm();
      let temp = [];

      for (let i = 1; i <= pageNo; i++) {
        const notif = await fetchNotifications(
          user,
          dispatch,
          NotificationManager,
          removeCookie,
          history,
          nodeId,
          10,
          i
        );
        temp = [...temp, ...notif.notifications];
      }
      dispatch(updateNotifications({ notifications: temp, pageNo: 1 }));
    }
    ATexpired(response, dispatch, removeCookie, history);
    return response.data;
  } catch (err) {
    console.log("Error while updating notification as read");
  }
};

export const getMembershipByMembershipId = async (
  user,
  dispatch,
  NotificationManager,
  removeCookie,
  history,
  nodeId,
  membershipId
) => {
  const url = `${rootApi}node/membership/search?nodeId=${nodeId}&membershipId=${membershipId}&includeExpired=${true}`;
  try {
    const response = await get(dispatch, url, user.AT);
    if (!response.data.success) {
      createNotification(NotificationManager, notificationType.error, response.data.error);
      // console.log(response.data.searchResult);
    } else {
      return response.data.searchResult[0];
    }
    ATexpired(response, dispatch, removeCookie, history);
  } catch (err) {
    throw err;
  }
};

export const getDeviceFirmwareListAPI = async (
  user,
  dispatch,
  NotificationManager,
  removeCookie,
  history
) => {
  const url = `${rootApi}device/firmware/list`;
  try {
    const response = await get(dispatch, url, user.AT);
    if (!response.data.success) {
      createNotification(NotificationManager, notificationType.error, response.data.error);
    } else {
      dispatch(updateDeviceFirmwareAction(response.data.devices));
      return;
    }
    ATexpired(response, dispatch, removeCookie, history);
  } catch (err) {
    throw err;
  }
};

export const deleteDeviceFirmwareAPI = async (
  user,
  dispatch,
  NotificationManager,
  removeCookie,
  history,
  deviceFirmwareId
) => {
  try {
    const url = `${rootApi}device/firmware/delete`;
    const body = {
      deviceFirmwareId,
    };
    const response = await post(dispatch, url, body, user.AT);
    if (!response.data.success) {
      createNotification(NotificationManager, notificationType.error, response.data.error);
    } else {
      createNotification(NotificationManager, notificationType.success, response.data.message);
      getDeviceFirmwareListAPI(user, dispatch, NotificationManager, removeCookie, history);
    }
    ATexpired(response, dispatch, removeCookie, history);
    return;
  } catch (err) {
    console.log("Error while deleting device firmaeare id");
    return { success: false, error: "Error while deleting device firmaeare id", err };
  }
};

export const addDeviceFirmwareAPI = async (
  user,
  dispatch,
  NotificationManager,
  removeCookie,
  history,
  formData,
  closeForm
) => {
  try {
    const url = `${rootApi}device/firmware/add`;
    const body = {
      ...formData,
    };
    const response = await post(dispatch, url, body, user.AT);
    if (!response.data.success) {
      createNotification(NotificationManager, notificationType.error, response.data.error);
    } else {
      createNotification(NotificationManager, notificationType.success, response.data.message);
      getDeviceFirmwareListAPI(user, dispatch, NotificationManager, removeCookie, history);
      closeForm();
    }
    ATexpired(response, dispatch, removeCookie, history);
    return;
  } catch (err) {
    console.log("Error while adding device firmaeare");
    return { success: false, error: "Error while adding device firmaeare", err };
  }
};

export const updateDeviceFirmwareAPI = async (
  user,
  dispatch,
  NotificationManager,
  removeCookie,
  history,
  formData,
  closeForm
) => {
  try {
    const url = `${rootApi}device/firmware/update`;
    const body = {
      ...formData,
    };
    const response = await post(dispatch, url, body, user.AT);
    if (!response.data.success) {
      createNotification(NotificationManager, notificationType.error, response.data.error);
    } else {
      createNotification(NotificationManager, notificationType.success, response.data.message);
      getDeviceFirmwareListAPI(user, dispatch, NotificationManager, removeCookie, history);
      closeForm();
    }
    ATexpired(response, dispatch, removeCookie, history);
    return;
  } catch (err) {
    console.log("Error while updating device firmaeare");
    return { success: false, error: "Error while updating device firmaeare", err };
  }
};

export const bulkRfidAssignAPI = async (
  user,
  dispatch,
  NotificationManager,
  removeCookie,
  history,
  formData,
  closeForm,
  setError
) => {
  setError(false);
  try {
    const url = `${rootApi}RFCard/bulkAssignUsers `;
    const body = {
      ...formData,
    };
    const response = await post(dispatch, url, body, user.AT);
    if (!response.data.success) {
      createNotification(NotificationManager, notificationType.error, response.data.error);
      setError({ message: response.data.error, users: response.data.users });
    } else {
      createNotification(NotificationManager, notificationType.success, response.data.message);
      closeForm();
    }
    ATexpired(response, dispatch, removeCookie, history);
    return;
  } catch (err) {
    setError("Error while bulk assigning RFIDs");
    console.log("Error while bulk assigning RFIDs", err);
    return { success: false, error: "Error while bulk assigning RFIDs", err };
  }
};

export const bulkDeleteMembershipsAPI = async (
  user,
  dispatch,
  NotificationManager,
  removeCookie,
  history,
  formData,
  closeForm,
  setError
) => {
  try {
    setError(false);
    const url = `${rootApi}membership/bulkDeleteMemberships `;
    const body = {
      ...formData,
    };
    const response = await post(dispatch, url, body, user.AT);
    if (!response.data.success) {
      createNotification(NotificationManager, notificationType.error, response.data.error);
      setError({ message: response.data.error, users: response.data.users });
    } else {
      createNotification(NotificationManager, notificationType.success, response.data.message);
      closeForm();
    }
    ATexpired(response, dispatch, removeCookie, history);
    return;
  } catch (err) {
    setError("Error while bulk deleting memberships");
    console.log("Error while bulk delete memberships", err);
    return { success: false, error: "Error while bulk delete memberships", err };
  }
};

export const bulkUpdateAccessAPI = async (
  user,
  dispatch,
  NotificationManager,
  removeCookie,
  history,
  formData,
  closeForm,
  setError
) => {
  setError(false);
  try {
    const url = `${rootApi}membership/bulkUpdateAccess `;
    const body = {
      ...formData,
    };
    const response = await post(dispatch, url, body, user.AT);
    if (!response.data.success) {
      createNotification(NotificationManager, notificationType.error, response.data.error);
      setError({ message: response.data.error, users: response.data.users });
    } else {
      createNotification(NotificationManager, notificationType.success, response.data.message);
      closeForm();
    }
    ATexpired(response, dispatch, removeCookie, history);
    return;
  } catch (err) {
    console.log("Error while bulk update access", err);
    setError("Unable to update bulk Access");
    return { success: false, error: "Error while bulk update access", err };
  }
};

export const deviceUnlockAPI = async (
  user,
  dispatch,
  NotificationManager,
  removeCookie,
  history,
  nodeId,
  deviceId,
  unlockType
) => {
  try {
    const url = `${rootApi}device/unlock`;
    const body = {
      nodeId,
      deviceId,
      unlockType,
    };
    const response = await post(dispatch, url, body, user.AT);
    if (!response.data.success) {
      createNotification(NotificationManager, notificationType.error, response.data.error);
    } else {
      createNotification(NotificationManager, notificationType.success, response.data.message);
    }
    ATexpired(response, dispatch, removeCookie, history);
    return;
  } catch (err) {
    console.log("Error while unlocking device");
    return;
  }
};

export const deviceSetTimeAPI = async (
  user,
  dispatch,
  NotificationManager,
  removeCookie,
  history,
  nodeId,
  deviceId
) => {
  try {
    const url = `${rootApi}device/setTime`;
    const body = {
      nodeId,
      deviceId,
    };
    const response = await post(dispatch, url, body, user.AT);
    if (!response.data.success) {
      createNotification(NotificationManager, notificationType.error, response.data.error);
    } else {
      createNotification(NotificationManager, notificationType.success, response.data.message);
    }
    ATexpired(response, dispatch, removeCookie, history);
    return;
  } catch (err) {
    console.log("Error while setting time in device", err);
    return;
  }
};

export const nodePlatformLockLogsSync = async (
  user,
  dispatch,
  NotificationManager,
  removeCookie,
  history,
  nodeId
) => {
  try {
    const url = `${rootApi}node/syncPlatformLogs`;
    const body = {
      nodeId,
    };
    const response = await post(dispatch, url, body, user.AT);
    if (!response.data.success) {
      createNotification(NotificationManager, notificationType.error, response.data.error);
    } else {
      createNotification(NotificationManager, notificationType.success, response.data.message);
    }
    ATexpired(response, dispatch, removeCookie, history);
    return;
  } catch (err) {
    console.log("Error while syncing Platform Locks");
    return;
  }
};

// export const getSdpInfo = async (
//   user,
//   dispatch,
//   NotificationManager,
//   removeCookie,
//   history,
//   sdp,
//   setSdpInfo,
//   setDeclarationRes
// ) => {
//   const url = `${rootApi}membership/sdpInfo?sdp=${sdp}`;
//   try {
//     const response = await get(dispatch,url);

//     if (!response.data.success) {
//       createNotification(
//         NotificationManager,
//         notificationType.error,
//         response.data.error
//       );
//     } else {
//       if (response.data.selfDeclarationTimestamp === 0) {
//         setSdpInfo(response.data);
//       } else if (response.data.selfDeclarationTimestamp > 0) {
//         setDeclarationRes(response.data);
//       }
//     }
//     ATexpired(response, dispatch, removeCookie, history);
//   } catch (err) {
//     console.log(err);
//   }
// };

// export const submitDeclaration = async (
//   user,
//   dispatch,
//   NotificationManager,
//   removeCookie,
//   history,
//   params,
//   setSdpInfo,
//   setDeclarationRes
// ) => {
//   const url = `${rootApi}membership/selfDeclaration`;
//   try {
//     const response = await post(dispatch,url, { selfDeclarationPayload: params.sdp });
//     if (!response.data.success) {
//       createNotification(
//         NotificationManager,
//         notificationType.error,
//         response.data.error
//       );
//     } else {
//       setSdpInfo(null);
//       setDeclarationRes(response.data);
//     }
//     ATexpired(response, dispatch, removeCookie, history);
//   } catch (err) {
//     console.log(err);
//   }
// };

// export const getVisitorRequests = async (
//   user,
//   dispatch,
//   NotificationManager,
//   removeCookie,
//   history,
//   setRequests
// ) => {
//   const url = `${rootApi}user/visitor/requests`;
//   try {
//     const response = await get(dispatch,url, user.AT);

//     if (!response.data.success) {
//       createNotification(
//         NotificationManager,
//         notificationType.error,
//         response.data.error
//       );
//     } else {
//       setRequests(response.data.data);
//     }
//     ATexpired(response, dispatch, removeCookie, history);
//   } catch (err) {
//     throw err;
//   }
// };

// export const validate = async (
//   user,
//   dispatch,
//   NotificationManager,
//   removeCookie,
//   history,
//   validate,
//   membershipId,
//   setRequests
// ) => {
//   const url = `${rootApi}membership/validate`;
//   const body = {
//     validate,
//     membershipId,
//   };
//   try {
//     const response = await post(dispatch,url, body, user.AT);
//     if (!response.data.success) {
//       createNotification(
//         NotificationManager,
//         notificationType.error,
//         response.data.error
//       );
//     } else {
//       getVisitorRequests(
//         user,
//         dispatch,
//         NotificationManager,
//         removeCookie,
//         history,
//         setRequests
//       );
//     }
//     ATexpired(response, dispatch, removeCookie, history);
//   } catch (err) {
//     throw err;
//   }
// };

// export const getNodeList = async (
//   user,
//   dispatch,
//   NotificationManager,
//   removeCookie,
//   history,
//   setNodes
// ) => {
//   const url = `${rootApi}user/employeeNodes`;
//   try {
//     const response = await get(dispatch,url, user.AT);

//     if (!response.data.success) {
//       createNotification(
//         NotificationManager,
//         notificationType.error,
//         response.data.error
//       );
//     } else {
//       setNodes(response.data.message);
//     }
//     ATexpired(response, dispatch, removeCookie, history);
//   } catch (err) {
//     throw err;
//   }
// };

// export const getNodeInfoFromQR = async (NotificationManager, qr, setNode) => {
//   const url = `${rootApi}node/visitor/qrDetails?qr=${qr}`;
//   try {
//     const response = await get(dispatch,url);
//     if (!response.data.success) {
//       createNotification(
//         NotificationManager,
//         notificationType.error,
//         response.data.error
//       );
//     } else {
//       setNode(response.data.data);
//     }
//   } catch (err) {
//     throw err;
//   }
// };

// export const visitorSelfRequestAPI = async (
//   NotificationManager,
//   formData,
//   setSuccess
// ) => {
//   const url = `${rootApi}node/visitor/selfRequest`;
//   const body = {
//     ...formData,
//   };
//   try {
//     const response = await post(dispatch,url, body);

//     if (!response.data.success) {
//       createNotification(
//         NotificationManager,
//         notificationType.error,
//         response.data.error
//       );
//     } else {
//       setSuccess(true);
//     }
//   } catch (err) {
//     console.log("invite Failed");
//   }
// };

export const updateRfidViaQR = async (
  user,
  createNotification,
  notificationType,
  dispatch,
  history,
  location,
  removeCookie,
  RFCardQRCode,
  userId,
  nodeId
) => {
  const url = `${rootApi}RFCard/assignUser`;
  const body = {
    RFCardQRCode,
    userId,
  };
  try {
    const response = await post(dispatch, url, body, user.AT);

    if (!response.data.success) {
      createNotification(NotificationManager, notificationType.error, response.data.error);
    } else {
      window.location.href = `${window.location.origin}${location.pathname}${location.search}&nodeId=${nodeId}&assigned=true`;
    }
    ATexpired(response, dispatch, removeCookie, history);
  } catch (err) {
    throw err;
  }
};
