import React, { useEffect, useState } from "react";
import { formNames } from "../../../config";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useCookies } from "react-cookie";
import { nullChecker, toggleForm } from "../../../utils/utilFunctions";
import { getIntegrationsDetailsAPI, getOauthApproovedApps } from "../../../utils/requests";
import NotificationManager from "react-notifications/lib/NotificationManager";
import { Card, SolidButton } from "./integrationView.styled";
import FreshTeamsLogo from "../../../assets/freshTeamsLogo.jpeg";
import OauthAppLogo from "../../../assets/OauthApp.png";
import GreytHrLogo from "../../../assets/greytHR.jpg";

const IntegrationsView = ({ updateSyncView }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const selectedNode = useSelector((state) => state.selectedNode);
  // const [integrationDetails, setIntegrationDetails] = useState([]);
  const formState = useSelector((state) => state.formState);
  const location = useLocation();
  const user = useSelector((state) => state.user);
  const [credentialsMap, setCredentialsMap] = useState({});
  const [cookies, setCookie, removeCookie] = useCookies(["AT"]);
  const [allowedOauthApps, setAllowedOauthApps] = useState(null);

  const getData = async () => {
    let result = await getIntegrationsDetailsAPI(
      user,
      selectedNode,
      NotificationManager,
      dispatch,
      removeCookie,
      history
    );
    let newMap = {};
    if (result && result.success) {
      for (let i = 0; i < result.integrations.length; i++) {
        newMap[result.integrations[i].integrationType] = result.integrations[i];
      }
      setCredentialsMap(newMap);
      // await setIntegrationDetails(result.integrations);
    }
  };

  useEffect(() => {
    getOauthApproovedApps(
      user,
      dispatch,
      NotificationManager,
      removeCookie,
      history,
      setAllowedOauthApps
    );
  }, []);

  useEffect(() => {
    getData();
  }, [selectedNode, formState.freshTeamsIntegrationFrom]);
  return (
    <div style={{ maxHeight: "80vh", overflow: "scroll" }} className="container-fluid">
      <div className="d-flex flex-column justify-content-between my-2">
        <h5 className="font-weight-bold">Integrations</h5>
      </div>
      <div className="row">
        <div className="col-md-3 col-lg-3 col-sm-6 col-xs-6">
          <Card>
            <img src={FreshTeamsLogo} />
            <SolidButton
              onClick={(e) => {
                let intData = {};
                if (credentialsMap["FRESHTEAMS"]) {
                  intData.host = credentialsMap["FRESHTEAMS"].freshTeams.host;
                  intData.apiKey = credentialsMap["FRESHTEAMS"].freshTeams.apiKey;
                }

                toggleForm(
                  formState,
                  history,
                  location,
                  dispatch,
                  formNames.freshTeamsIntegrationFrom,
                  intData
                );
              }}
            >
              Integrate FreshTeams
            </SolidButton>
            {credentialsMap["FRESHTEAMS"] ? (
              <SolidButton onClick={(e) => updateSyncView({ type: "FRESHTEAMS", showSync: true })}>
                Sync Employees
              </SolidButton>
            ) : null}
          </Card>
        </div>
        <div className="col-md-3 col-lg-3 col-sm-12 col-xs-12">
          <Card>
            <img src={GreytHrLogo} style={{ marginBottom: "10px", marginTop: "10px" }} />
            <SolidButton
              onClick={(e) => {
                let intData = {};
                if (credentialsMap["GREYTHR"]) {
                  intData.host = credentialsMap["GREYTHR"].greytHR.host;
                  intData.apiId = credentialsMap["GREYTHR"].greytHR.apiId;
                  intData.privateKey = credentialsMap["GREYTHR"].greytHR.privateKey;
                  intData.syncLogsToGreytHr = true;
                }

                toggleForm(
                  formState,
                  history,
                  location,
                  dispatch,
                  formNames.greytHrIntegrationForm,
                  intData
                );
              }}
            >
              Integrate Attendance
            </SolidButton>
            {credentialsMap["GREYTHR"] && credentialsMap["GREYTHR"].username && credentialsMap["GREYTHR"].password? (
              <SolidButton onClick={(e) => updateSyncView({ type: "GREYTHR", showSync: true })}>
                Sync Employees
              </SolidButton>
            ) : null}
          </Card>
        </div>
        {allowedOauthApps && allowedOauthApps.length ? (
          <>
            {allowedOauthApps.map((a) => (
              <div className="col-md-3 col-lg-3 col-sm-6 col-xs-6">
                <Card>
                  <img src={OauthAppLogo} style={{ padding: "40px", paddingBottom: "20px" }} />
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      fontSize: "1rem",
                    }}
                    className="font-weight-bold"
                  >
                    {a.name}
                  </div>
                  <p className="text-center pt-2">{nullChecker(a.description)}</p>
                  <SolidButton onClick={(e) => null}>
                    <Link
                      to={`/dialog?clientId=${a.clientId}&scopes=swap_data,user_info&redirectUri=${a.redirectUri}&responseType=code`}
                      style={{ textDecoration: "none", color: "inherit" }}
                    >
                      Integrate {a.name}
                    </Link>
                  </SolidButton>
                </Card>
              </div>
            ))}
          </>
        ) : null}
      </div>
    </div>
  );
};

export default IntegrationsView;
