import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { apiEndPoints, formNames } from "../../../config";
import { SolidButton } from "../../buttons/solidButton.styled";
import { FormWrapper } from "../doorAddForm/DoorAddForm";
import { useCookies } from "react-cookie";
import { toggleForm } from "../../../utils/utilFunctions";
import {
  FieldInput,
  FieldLabel,
  InputBox,
  InputGroup,
} from "../FormBase.styled";
import { ImageContainer, ImagePreviewContainer, BlankImagePreviewContainer , ImagePreview, ImageFileName, UploadImageContainer, FileUploadLabel, FileInput, ErrorText, SaveButton, DeleteButton } from "./EditNameForm.styled";
import { useHistory, useLocation } from "react-router-dom";
import NotificationManager from "react-notifications/lib/NotificationManager";
import { updateUserNameAPI, uploadUserProfileImageAPI, getUserProfileImageAPI, deleteUserProfileImageAPI } from "../../../utils/requests";
import ButtonState from "../../ButtonState/ButtonState";

const EditNameForm = ({ getPeopleAPI }) => {
  const maxFileSize =  1048576;
  const closeForm = () => {
    toggleForm(formState, history, location, dispatch, formNames.editNameForm);
  };
  const [cookies, setCookie, removeCookie] = useCookies(["AT"]);
  let history = useHistory();
  const dispatch = useDispatch();
  const apiStatus = useSelector((state) => state.apiStatus);
  const dimentions = useSelector((state) => state.dimentions);
  const selectedNode = useSelector((state) => state.selectedNode);
  const formState = useSelector(state => state.formState)
  const utils = useSelector((state) => state.utils);
  const location = useLocation()
  const initialData = useSelector(
    (state) => state.formState[formNames.editNameForm]
  );

  const user = useSelector((state) => state.user);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    userId: "",
  });

  const [nodeMembershipFormData, setNodeMembershipFormData] = useState({
    membershipId: "",
    isIDCardAvailable: false,
  });

  const [errorData, setErrorData] = useState('');
  const [fileData, setFileData] = useState();
  const [imagePreview, setImagePreview] = useState();

  const getUserProfileImageAPICall = async (membershipId) => {
    getUserProfileImageAPI(                
      user,
      dispatch,
      NotificationManager,
      removeCookie,
      history,
      membershipId,
      setImagePreview,
      closeForm,
    );
  }

  const checkFileSize = (size) => {
    if(size > maxFileSize)
      return { success: false, error: `File size exceeds max file size of 1MB`};
    else 
      return { success: true };
  }

  const checkValidImage = (imageType) => {
    const validImageTypes = ["image/png", "image/jpeg", "image/jpg"];
    if(!validImageTypes.includes(imageType))
      return { success: false, error: `Only PNG and JPEG files are allowed`};
    else 
      return { success: true };
  }

  const handleFileChange = (e) => {
    if(e.target.files && e.target.files.length){
      let error = "";
      let success = true;
      let file = e.target.files[0];
      let getFileSize = checkFileSize(file.size);
      let getImageType = checkValidImage(file.type);
      if(!getFileSize.success){
        error = getFileSize.error;
        success = false;
      }
      else if(!getImageType.success){
        error = getImageType.error;
        success = false;
      }
      if(success){
        setImagePreview(URL.createObjectURL(file));
        setFileData(file);
        setErrorData('');
      }
      else{
        setErrorData(error);
        setFileData(null);
        setImagePreview(null);
      }
    }
  }

  const handleUploadClick = async (e) => {
    e.preventDefault();
    let error = "";
    let success = true;
    let file = fileData;
    if(file){
      let getFileSize = checkFileSize(file.size);
      let getImageType = checkValidImage(file.type);
      if(!getFileSize.success){
        error = getFileSize.error;
        success = false;
      }
      else if(!getImageType.success){
        error = getImageType.error;
        success = false;
      }
      if(success){
        let membershipId = nodeMembershipFormData.membershipId;
        const formUploadData = new FormData();
        formUploadData.append("profileImage", file);
        formUploadData.append("membershipId", `${membershipId}`);
        await uploadUserProfileImageAPI(
            user,
            dispatch,
            NotificationManager,
            removeCookie,
            history,
            closeForm,
            formUploadData,
        );
        setFileData(null);
        setImagePreview(null);
        setErrorData('');
        getUserProfileImageAPICall(membershipId);
      }
      else{
        setErrorData(error);
        setFileData(null);
        setImagePreview(null);
      }
    }
  }

  const handleDeleteClick = async (e) => {
    e.preventDefault();
    let file = fileData;
    let membershipId = nodeMembershipFormData.membershipId;
    if(imagePreview && !file){
      await deleteUserProfileImageAPI(
        user,
        dispatch,
        NotificationManager,
        removeCookie,
        history,
        membershipId,
        closeForm,
      );
      getUserProfileImageAPICall(membershipId);
    }
  }

  useEffect(() => {
    if (initialData) {
      let { membershipId , isIDCardAvailable, ...restObj } = initialData;
      setFormData({ ...restObj });
      setNodeMembershipFormData({membershipId, isIDCardAvailable});
      if(isIDCardAvailable && membershipId){
        getUserProfileImageAPICall(membershipId);
      }
    }
  }, [initialData]);

  return (
    <FormWrapper dimentions={dimentions} closeForm={closeForm}>
      <h6 className="font-weight-bold mb-4">Edit Name</h6>
      <InputGroup dimentions={dimentions}>
        <FieldLabel dimentions={dimentions}>First Name</FieldLabel>
        <FieldInput>
          <InputBox
            type="text"
            // placeholder="Enter first name"
            value={formData.firstName}
            onChange={(e) => setFormData({ ...formData, firstName: e.target.value })}
          />
        </FieldInput>
      </InputGroup>
      <InputGroup dimentions={dimentions}>
        <FieldLabel dimentions={dimentions}>Last Name</FieldLabel>
        <FieldInput>
          <InputBox
            type="text"
            // placeholder="Enter last name"
            value={formData.lastName}
            onChange={(e) => setFormData({ ...formData, lastName: e.target.value })}
          />
        </FieldInput>
      </InputGroup>
      {nodeMembershipFormData.isIDCardAvailable ? (
        <InputGroup dimentions={dimentions}>
          <FieldLabel dimentions={dimentions}>Profile Picture</FieldLabel>
          <FieldInput>
            <UploadImageContainer>
              <ImageContainer>
                <ImagePreviewContainer>
                  {imagePreview && imagePreview.length ? (
                    <>
                      <ImagePreview src={imagePreview} />
                      <ImageFileName>{fileData ? fileData.name : ""}</ImageFileName>
                    </>
                  ) : (
                    <BlankImagePreviewContainer>Image Preview</BlankImagePreviewContainer>
                  )}
                </ImagePreviewContainer>
              </ImageContainer>
              {errorData && errorData.length ? <ErrorText>{errorData}</ErrorText> : null}
              <FileUploadLabel htmlFor="file-upload">{"Upload Picture"}</FileUploadLabel>
              <SaveButton disabled={fileData ? false : true} onClick={(e) => handleUploadClick(e)}>
                Save
              </SaveButton>
              {imagePreview && !fileData ? (
                <div>
                  <DeleteButton
                    disabled={imagePreview ? false : true}
                    onClick={(e) => handleDeleteClick(e)}
                  >
                    Delete Picture
                  </DeleteButton>
                </div>
              ) : null}
              <FileInput
                id="file-upload"
                type="file"
                onChange={(e) => handleFileChange(e)}
                accept="image/*"
              />
            </UploadImageContainer>
          </FieldInput>
        </InputGroup>
      ) : null}
      <InputGroup dimentions={dimentions}>
        <FieldLabel dimentions={dimentions}></FieldLabel>
        <FieldInput submitButton={true}>
          <SolidButton
            submit={true}
            onClick={(e) =>
              updateUserNameAPI(
                user,
                dispatch,
                NotificationManager,
                removeCookie,
                history,
                selectedNode,
                formData,
                closeForm,
                utils
              )
            }
          >
            <ButtonState loading={apiStatus[apiEndPoints.updateUserNameAPI]} innerText={"Update Details"} />
          </SolidButton>
        </FieldInput>
      </InputGroup>
    </FormWrapper>
  );
};

export default EditNameForm;
