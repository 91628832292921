import React from "react";
import { Link } from "react-router-dom";
import "../googleSignIn.css";
const SignInWithGoogleButton = ({ authURL }) => {
  return authURL ? (
    <a href={authURL} terget="blank" className="google-btn">
      <div className="google-icon-wrapper">
        <img
          className="google-icon"
          src="https://upload.wikimedia.org/wikipedia/commons/5/53/Google_%22G%22_Logo.svg"
        />
      </div>
      <p className="btn-text">
        <b>Sign in with google</b>
      </p>
    </a>
  ) : (
    "loading"
  );
};

export default SignInWithGoogleButton;

// https://api.snglty.com/staging-securepass-v2/user/oauthURL?oauthProvider=google&redirectURI=localhost:3000
