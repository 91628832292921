import styled from "styled-components";

export const ZonePlanCardContainer = styled.div`
  padding: 1rem 1rem;
  background-color: #ffffff;
  border: 0.5px solid #e8e8e8;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  margin-bottom: 1rem;
  border-radius: 10px;
`;

export const Title = styled.p`
  font-size: 1.2rem;
  margin-bottom: 1.5rem;
  font-weight: bold;
  font-style: normal;
  line-height: 1.5rem;
  color: #353535;
`;

export const AccessLimit = styled.p`
  font-size: 1.2rem;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  margin-right: 0.5rem;
  font-weight: bold;
  font-style: normal;
  line-height: 1.5rem;
  color: #43424b;
`;

export const PlanPerks = styled.p`
  color: #898989;
  margin-bottom: 0.5rem;
  font-size: 1rem;
  margin-right: 1rem;
  font-style: normal;
  line-height: 1.5rem;
`;

export const UpgradeButton = styled.button`
  background-color: #49eecc;
  width: 100%;
  border: none;
  border-radius: 2rem;
  padding: 0.6rem 1rem;
  color: #ffffff;
  font-size: 1.3rem;
  font-weight: bold;
  outline: none;
  cursor: pointer;
  :hover {
    opacity: 0.5;
    color: black;
    outline: none;
  }
`;
