import { UPDATE_SELECTED_NODE } from "../actionTypes";

const selectedNodeReducer = (selectedNode = {}, action) => {
  if (action.type === UPDATE_SELECTED_NODE) {
    return (selectedNode = action.payload);
  }

  return selectedNode;
};

export default selectedNodeReducer;
