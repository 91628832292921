import React, { useState, useEffect } from "react";
import { useCookies } from "react-cookie";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import NotificationManager from "react-notifications/lib/NotificationManager";
import {
    getUserDeviceRequestsAPI,
    updateUserDeviceRequestAPI,
  } from "../../../utils/requests";
import { breakPointWidth, formNames } from "../../../config";
import { toggleForm, nullChecker, convetToLocaleDateString } from "../../../utils/utilFunctions";
import { FormWrapper } from "../doorAddForm/DoorAddForm";
import PlaceholderMessage from "../../PlaceholderMessage";
import { RowContainer, RowStyled, Name, Phone, Email, DeviceModel, CreatedDate, Status, ApproveButton, RejectButton, ApproveButtonMobile, RejectButtonMobile, StatusMobile } from "./UserDeviceRequestsForm.styled";
import { OverFLowScrollBox } from "../../App.styled";
import {
    FieldLabelMobile,
    FieldValueMobile,
    ZoneLogsUserRowMobileView,
    ZoneLogsUserRowMobileWrapper,
} from "../../zone/ZoneLogsUserRow/zoneLogsUserRow.styled";

const UserDeviceRequestsForm = () => {
    const [cookies, setCookie, removeCookie] = useCookies(["AT"]);
    const location = useLocation();
    let history = useHistory();
    const dispatch = useDispatch();
    const [data, setData] = useState([]);
    const [updatedResponse, setUpdatedResponse] = useState({});
    const user = useSelector((state) => state.user);
    const selectedNode = useSelector((state) => state.selectedNode);
    const dimentions = useSelector((state) => state.dimentions);
    const formState = useSelector((state) => state.formState);
    const closeForm = () => {
        toggleForm(formState, history, location, dispatch, formNames.userDeviceRequestsForm);
    };
    
    const getUserDeviceRequestsAPICall = () => {
        getUserDeviceRequestsAPI(
            user,
            dispatch,
            NotificationManager,
            removeCookie,
            history,
            setData,
            selectedNode
        );
    }

    useEffect( () => {
        getUserDeviceRequestsAPICall();
    }, [selectedNode]);

    useEffect( () => {
        if(Object.keys(updatedResponse).length)
            getUserDeviceRequestsAPICall();
    }, [updatedResponse]);

    return (
        <FormWrapper dimentions={dimentions} closeForm={closeForm}>
            {dimentions.width > breakPointWidth ? (
                <RowContainer>
                    <RowStyled isHead={true}>
                        <Name>{"Name"}</Name>
                        <Phone>{"Phone"}</Phone>
                        <Email>{"Email"}</Email>
                        <DeviceModel>{"Device Model"}</DeviceModel>
                        <CreatedDate>{"Date"}</CreatedDate>
                        <Status>{"Status"}</Status>
                    </RowStyled>
                    <OverFLowScrollBox>
                        {data.userDeviceRequests && data.userDeviceRequests.length
                        ? data.userDeviceRequests.map((item, index) => {
                                return (
                                  <RowStyled key={`${item.userId}${item.userDeviceId}`}>
                                    <Name>
                                      {nullChecker(item.firstName) + " " + nullChecker(item.lastName)}
                                    </Name>
                                    <Phone>{nullChecker(item.mobileNo)}</Phone>
                                    <Email>{nullChecker(item.emailId)}</Email>
                                    <DeviceModel>{nullChecker(item.deviceModel)}</DeviceModel>
                                    <CreatedDate>{convetToLocaleDateString(item.timestamp)}</CreatedDate>
                                    {item.active ? (
                                      <Status>
                                        <ApproveButton
                                          onClick={(e) =>
                                            updateUserDeviceRequestAPI(
                                              user,
                                              dispatch,
                                              NotificationManager,
                                              removeCookie,
                                              history,
                                              closeForm,
                                              setUpdatedResponse,
                                              item.userId,
                                              item.userDeviceId,
                                              selectedNode.nodeId,
                                              true
                                            )
                                          }
                                        >
                                          {"Approve"}
                                        </ApproveButton>
                                        <RejectButton
                                          onClick={(e) =>
                                            updateUserDeviceRequestAPI(
                                              user,
                                              dispatch,
                                              NotificationManager,
                                              removeCookie,
                                              history,
                                              closeForm,
                                              setUpdatedResponse,
                                              item.userId,
                                              item.userDeviceId,
                                              selectedNode.nodeId,
                                              false
                                            )
                                          }
                                        >
                                          {"Reject"}
                                        </RejectButton>
                                      </Status>
                                    ) : (
                                      <Status>{"Inactive"}</Status>
                                    )}
                                  </RowStyled>
                                );}
                            )
                        : <PlaceholderMessage message="No data to display" />}
                    </OverFLowScrollBox>
                </RowContainer>
            ) : (
                <div className="row m-0 p-0">
                    {data.userDeviceRequests && data.userDeviceRequests.length ? ( 
                        data.userDeviceRequests.map((item, index) => {
                    return (
                      <div
                        className="col-sm-12 col-xs-12 p-0 col-md-6 px-md-2"
                        key={`${item.userId}${item.userDeviceId}`}
                      >
                        <ZoneLogsUserRowMobileWrapper>
                          <ZoneLogsUserRowMobileView>
                            <div>
                              <FieldLabelMobile>{"Name"}</FieldLabelMobile>
                              <div className="d-flex justify-content-between">
                                <FieldValueMobile>
                                  {nullChecker(item.firstName) + " " + nullChecker(item.lastName)}
                                </FieldValueMobile>
                              </div>
                            </div>
                            <div>
                              <FieldLabelMobile>{"Phone"}</FieldLabelMobile>
                              <FieldValueMobile>{nullChecker(item.mobileNo)}</FieldValueMobile>
                            </div>
                            <div>
                              <FieldLabelMobile>{"Email"}</FieldLabelMobile>
                              <FieldValueMobile>{nullChecker(item.emailId)}</FieldValueMobile>
                            </div>
                            <div>
                              <FieldLabelMobile>{"Date"}</FieldLabelMobile>
                              <FieldValueMobile>
                                {convetToLocaleDateString(item.timestamp)}
                              </FieldValueMobile>
                            </div>
                          </ZoneLogsUserRowMobileView>
                          <div className="row my-2">
                            {item.active ? (
                              <>
                                <div className="col-6">
                                  <ApproveButtonMobile
                                    className="w-100"
                                    onClick={(e) =>
                                      updateUserDeviceRequestAPI(
                                        user,
                                        dispatch,
                                        NotificationManager,
                                        removeCookie,
                                        history,
                                        closeForm,
                                        setUpdatedResponse,
                                        item.userId,
                                        item.userDeviceId,
                                        selectedNode.nodeId,
                                        true
                                      )
                                    }
                                  >
                                    {"Approve"}
                                  </ApproveButtonMobile>
                                </div>
                                <div className="col-6">
                                  <RejectButtonMobile
                                    className="w-100"
                                    onClick={(e) =>
                                      updateUserDeviceRequestAPI(
                                        user,
                                        dispatch,
                                        NotificationManager,
                                        removeCookie,
                                        history,
                                        closeForm,
                                        setUpdatedResponse,
                                        item.userId,
                                        item.userDeviceId,
                                        selectedNode.nodeId,
                                        false
                                      )
                                    }
                                  >
                                    {"Reject"}
                                  </RejectButtonMobile>
                                </div>
                              </>
                            ) : (
                              <StatusMobile className="col-12">{"Inactive"}</StatusMobile>
                            )}
                          </div>
                        </ZoneLogsUserRowMobileWrapper>
                      </div>
                    );
                  })
                ) : (
                  <PlaceholderMessage message="No data to display" />
                )}
              </div>    
            )}
        </FormWrapper>
    );
      
}

export default UserDeviceRequestsForm;