import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import DatePicker from "react-datepicker";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import { formNames, membershipRoles, rootApi, typeCode } from "../../../config";
import { get } from "../../../utils/apiService";
import {nodePlatformLockLogsSync} from "../../../utils/requests";
import { ATexpired } from "../../../utils/logout";
import AttendanceLogsUserRow from "../attendanceLogsRow/AttendanceLogsUserRow";
import AttendanceSheetDownload from "../AttendanceSheetDownload";
import {
  detectDeviceType,
  formatTemp,
  getUserRole,
  isAdmin,
  nullChecker,
  toggleForm,
} from "../../../utils/utilFunctions";
import { createNotification, getQueryParams, notificationType } from "../../../vms/Services/utils";
import NotificationManager from "react-notifications/lib/NotificationManager";
import { InputBox, SelectBox } from "../../forms/FormBase.styled";
import GreenSwitch from "../../switches/GreenSwitch";
import { TimingContainer } from "../cards/timings/timing.styled";
import { IconContainer } from "../../App.styled";
import { FcSettings } from "react-icons/fc";
import DisabledOverlay, { DisabledLockIcon } from "../../DisabledOverlay";
import { SolidButton } from "../../buttons/solidButton.styled";
import { sendLogViewEvent } from "../../../utils/tagManagerEvents";
import useIntersectionAndHover from "../../../hooks/useIntersectionAndHover";
import AttendanceUserfilterForm from "../../forms/AttendanceUserfilterForm";
import AttendanceCSVDownload from "../AttendanceCSVDownload";
const AttendanceLogsConfigBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 0.2rem;
  margin: 0px 0px;
  border-radius: 10px;
`;

const AttendanceLogs = ({
  date,
  setDate,
  zoneLogs,
  department,
  subDepartment,
  setDepartment,
  setSubDepartment,
  includeChild,
  setIncludeChild,
  setSelfLogs,
  selfLogs,
  setActiveMembershipsCount,
}) => {
  const [cookies, setCookie, removeCookie] = useCookies(["AT"]);
  let history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const [csvStartDate, setCsvStartDate] = useState(Date.now());
  const [csvEndDate, setCsvEndDate] = useState(Date.now());
  const [csvData, setCsvData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [allMemberships, setAllMemberships] = useState(null);
  const [accessLogs, setAccessLogs] = useState(null);
  const user = useSelector((state) => state.user);
  const selectedNode = useSelector((state) => state.selectedNode);
  const dimentions = useSelector((state) => state.dimentions);
  const nodeInfo = useSelector((state) => state.nodeInfo);
  const formState = useSelector((state) => state.formState);
  const [departmentInfo, setDepartmentInfo] = useState([]);
  const [formStatus, setFormStatus] = useState({});
  const [role, setRole] = useState(null);
  const [dateWatcher, setDateWatcher] = useState(Date.now() + Date.now());
  const [analyticsLogSent, setAnalyticsLogSent] = useState(false);
  const [userIds, setUserIds] = useState({});
  const [attendanceDateBoxRef, attendanceDateBoxIsHovered, attendanceDateBoxInView] =
    useIntersectionAndHover();
  const [attendanceLogBoxRef, attendanceLogBoxIsHovered, attendanceLogBoxInView] =
    useIntersectionAndHover();
  const getDepartments = async (nodeId) => {
    const url = `${rootApi}node/info?nodeId=${nodeId}`;
    try {
      const response = await get(dispatch, url, user.AT);
      if (!response.data.success) {
        createNotification(NotificationManager, notificationType.error, response.data.error);
      } else {
        setDepartmentInfo(response.data.departments);
      }
      ATexpired(response, dispatch, removeCookie, history);
    } catch (err) {
      throw err;
    }
  };

  useEffect(() => {
    setFormStatus({ ...getQueryParams(location.search) });
  }, [location]);
  //send logs View Event to analytics

  const sendLog = () => {
    sendLogViewEvent(
      user.userId,
      nodeInfo.name,
      nodeInfo.nodeCategory,
      getUserRole(user.roles, nodeInfo),
      date
    );
    setAnalyticsLogSent(true);
  };

  useEffect(() => {
    if (detectDeviceType() === "Mobile" && attendanceDateBoxInView && !analyticsLogSent) {
      sendLog();
    } else if (detectDeviceType() === "Desktop" && attendanceDateBoxIsHovered && !analyticsLogSent) {
      sendLog();
    }
  }, [attendanceDateBoxIsHovered, attendanceDateBoxInView]);
  useEffect(() => {
    if (detectDeviceType() === "Mobile" && attendanceLogBoxInView && !analyticsLogSent) {
      sendLog();
    } else if (detectDeviceType() === "Desktop" && attendanceLogBoxIsHovered && !analyticsLogSent) {
      sendLog();
    }
  }, [attendanceLogBoxIsHovered, attendanceLogBoxInView]);

  useEffect(() => {
    if (date)
      sendLogViewEvent(
        user.userId,
        nodeInfo.name,
        nodeInfo.nodeCategory,
        getUserRole(user.roles, nodeInfo),
        date
      );
  }, [date]);

  useEffect(() => {
    if (nodeInfo.departments) {
      setDepartmentInfo(nodeInfo.departments);
    } else if (nodeInfo.rootNodeId) {
      getDepartments(nodeInfo.rootNodeId);
    }
  }, [nodeInfo]);

  const updateCsvData = async (sd, ed) => {
    setAccessLogs(null);
    setCsvData(null);
    setLoading(true);
    let pageNo = 1;
    let logs = [];
    try {
      // setActiveMembershipsCount(membersHaveAccess.length);
      while (pageNo < 10000) {
        let url = `${rootApi}node/accessLogs?nodeId=${selectedNode.nodeId}&startTime=${new Date(
          sd
        ).setHours(0, 0, 0, 0)}&endTime=${new Date(ed).setHours(23, 59, 59, 999)}${
          department.length ? `&depId=${department}` : ``
        }${subDepartment.length && department.length ? `&subDepId=${subDepartment}` : ``}${
          role ? `&role=${role}` : ``
        }${
          selfLogs ? `&sendSelfLogs=true` : ``
        }&includeChildLogs=${includeChild}&pageNo=${pageNo}&pageSize=1000`;
        const response = await get(dispatch, url, user.AT);
        if (!response.data.success) {
          createNotification(NotificationManager, notificationType.error, response.data.error);
          ATexpired(response, dispatch, removeCookie, history);
          break;
        } else {
          logs = [...logs, ...response.data.accessLogs];
          if (pageNo === response.data.noOfPages || response.data.noOfPages === 0) {
            break;
          } else {
            pageNo++;
          }
        }
      }

      if (
        nodeInfo.exitAuthentication ||
        (nodeInfo.door &&
          nodeInfo.door.some((x) => {
            return typeof x.outAccessDevice !== "undefined";
          }))
      ) {
        let visitedSet = new Set();
        for (let i = logs.length - 1; i >= 0; i--) {
          let entry = logs[i];
          if (!entry["deviceId"] || !entry["userId"] || visitedSet.has(i)) continue;
          entry["tailgating"] = "NO";
          let currentUserId = `${entry["userId"]}`;
          let currentAccessType = entry["accessType"];
          let currentDeviceTimestamp = Number(entry["deviceTimestamp"]);
          // let currentDate = new Date(currentDeviceTimestamp).toDateString();
          let currentDate = Math.floor(currentDeviceTimestamp / 86400000);
          let currentNodeId = `${entry["nodeId"]}`;
          for (let j = i - 1; j >= 0; j--) {
            let nextEntry = logs[j];
            if (!nextEntry["deviceId"] || !nextEntry["userId"]) {
              visitedSet.add(j);
              continue;
            }
            let nextUserId = `${nextEntry["userId"]}`;
            let nextAccessType = nextEntry["accessType"];
            let nextDeviceTimestamp = Number(nextEntry["deviceTimestamp"]);
            // let nextDate = new Date(nextDeviceTimestamp).toDateString();
            let nextDate = Math.floor(nextDeviceTimestamp / 86400000);
            let nextNodeId = `${nextEntry["nodeId"]}`;
            if (currentDate !== nextDate) break;
            if (currentUserId === nextUserId && currentNodeId === nextNodeId) {
              if (currentAccessType !== nextAccessType) break;
              else if (currentAccessType === nextAccessType) {
                logs[j]["tailgating"] = "YES";
                visitedSet.add(j);
              }
            }
          }
        }
      }
      setLoading(false);
      setAccessLogs([...logs]);
      setCsvData([...logs]);
    } catch (err) {
      setLoading(false);
      throw err;
    }
  };

  useEffect(() => {
    setDateWatcher(csvStartDate + csvEndDate);
  }, [csvStartDate, csvEndDate]);

  useEffect(() => {
    if (csvStartDate && csvEndDate && selectedNode.nodeId) {
      updateCsvData(csvStartDate, csvEndDate);
    }
  }, [dateWatcher, selectedNode.nodeId, subDepartment, department, includeChild, role, selfLogs]);

  return (
    <>
      {formStatus[formNames.attendanceUserFilterForm] ? (
        <AttendanceUserfilterForm userIds={userIds} setUserIds={setUserIds} />
      ) : null}
      <div className="col-12">
        <TimingContainer>
          <div className="row">
            <div className="col-md-4 col-lg-4 col-xs-12 col-sm-12">
              <AttendanceLogsConfigBox dimentions={dimentions}>
                <h5 className="font-weight-bold m-0">Download Attendance Logs</h5>
                <div>
                  <p className="  mb-1 font-weight-bold" style={{ fontSize: "1rem" }}>
                    From<span className="ml-4">To</span>
                  </p>

                  <DateRangePicker
                    className="m-0"
                    initialSettings={{
                      startDate: `${new Date(csvStartDate).getMonth() + 1}/${new Date(
                        csvStartDate
                      ).getDate()}/${new Date(csvStartDate).getFullYear()}`,
                      endDate: `${new Date(csvEndDate).getMonth() + 1}/${new Date(
                        csvEndDate
                      ).getDate()}/${new Date(csvEndDate).getFullYear()}`,
                      showDropdowns: true,
                    }}
                    onCallback={(s, e) => {
                      setCsvStartDate(s);
                      setCsvEndDate(e);
                    }}
                  >
                    <InputBox
                      style={{ height: "30px", fontSize: "0.9rem" }}
                      className="m-0"
                      value={`${new Date(csvStartDate).getMonth() + 1}/${new Date(
                        csvStartDate
                      ).getDate()}/${new Date(csvStartDate).getFullYear()} - ${
                        new Date(csvEndDate).getMonth() + 1
                      }/${new Date(csvEndDate).getDate()}/${new Date(csvEndDate).getFullYear()}`}
                    />
                  </DateRangePicker>
                </div>
                <div className="d-flex align-items-center">
                  <p className=" m-0 mr-2 font-weight-bold" style={{ fontSize: "1rem" }}>
                    Include data from child nodes :
                  </p>

                  <GreenSwitch checked={includeChild} onChange={(e) => setIncludeChild(!includeChild)} />
                </div>
                {isAdmin(user, nodeInfo) ? (
                  <div className="d-flex align-items-center">
                    <p className=" m-0 mr-2 font-weight-bold" style={{ fontSize: "1rem" }}>
                      Self Logs Only :
                    </p>
                    <GreenSwitch checked={selfLogs} onChange={(e) => setSelfLogs(!selfLogs)} />
                  </div>
                ) : null}
              </AttendanceLogsConfigBox>
            </div>
            {isAdmin(user, nodeInfo) ? (
              <div className="col-md-8 col-lg-8 col-xs-12 col-sm-12 ">
                <AttendanceLogsConfigBox dimentions={dimentions}>
                  <h5 className="font-weight-bold m-0">Filter</h5>
                  <div className="row">
                    <div className="col-md-3 col-lg-3 col-xs-12 col-sm-12 mb-2 d-flex">
                      {!nodeInfo?.features?.attendanceFilters.department ? (
                        <DisabledLockIcon className="mr-3" size={"1.5rem"} />
                      ) : null}
                      <div style={{ position: "relative" }}>
                        {!nodeInfo?.features?.attendanceFilters.department ? <DisabledOverlay /> : null}
                        <p className=" mb-1 font-weight-bold" style={{ fontSize: "1rem" }}>
                          Department
                        </p>
                        <SelectBox
                          style={{ height: "30px", fontSize: "0.9rem" }}
                          onChange={(e) => {
                            if (e.target.vlue === "Select") {
                              setSubDepartment("");
                              setDepartment("");
                            } else {
                              setDepartment(e.target.value);
                            }
                          }}
                        >
                          <option value="">Select</option>

                          {departmentInfo
                            ? departmentInfo.map((d) => <option value={d.depId}>{d.name}</option>)
                            : null}
                        </SelectBox>
                      </div>
                    </div>
                    {department.length ? (
                      <div className="col-md-3 col-lg-3 col-xs-12 col-sm-12 mb-2 d-flex">
                        {!nodeInfo?.features?.attendanceFilters.subDepartment ? (
                          <DisabledLockIcon className="mr-3" size={"1.5rem"} />
                        ) : null}
                        <div style={{ position: "relative" }}>
                          {!nodeInfo?.features?.attendanceFilters.subDepartment ? (
                            <DisabledOverlay />
                          ) : null}
                          <p className=" mb-1 font-weight-bold" style={{ fontSize: "1rem" }}>
                            Sub Department
                          </p>
                          <SelectBox
                            style={{ height: "30px", fontSize: "0.9rem" }}
                            onChange={(e) => setSubDepartment(e.target.value)}
                          >
                            <option value="">Select</option>
                            {departmentInfo
                              ? departmentInfo
                                  .filter((d) => d.depId === department)[0]
                                  .subDepartments.map((x) => (
                                    <option value={x.subDepId}>{x.name}</option>
                                  ))
                              : null}
                          </SelectBox>
                        </div>
                      </div>
                    ) : null}
                    <div className="col-md-3 col-lg-3 col-xs-12 col-sm-12 mb-2 d-flex">
                      {!nodeInfo?.features?.attendanceFilters.role ? (
                        <DisabledLockIcon className="mr-3" size={"1.5rem"} />
                      ) : null}
                      <div style={{ position: "relative" }}>
                        {!nodeInfo?.features?.attendanceFilters.role ? <DisabledOverlay /> : null}
                        <p className=" mb-1 font-weight-bold" style={{ fontSize: "1rem" }}>
                          User Type
                        </p>
                        <SelectBox
                          style={{ height: "30px", fontSize: "0.9rem" }}
                          onChange={(e) => {
                            if (e.target.vlue === "Select") {
                              setRole(null);
                            } else {
                              setRole(e.target.value);
                            }
                          }}
                        >
                          <option value="">Select</option>

                          {membershipRoles(nodeInfo.nodeCategory)
                            .map((v) => v.substring(1))
                            .map((d, i) => (i === 0 ? null : <option value={d}>{d}</option>))}
                        </SelectBox>
                      </div>
                    </div>
                    <div className="col-md-3 col-lg-3 col-xs-12 col-sm-12 mb-2 d-flex">
                      {!nodeInfo?.features?.attendanceFilters.role ? (
                        <DisabledLockIcon className="mr-3" size={"1.5rem"} />
                      ) : null}
                      <div style={{ position: "relative" }}>
                        {!nodeInfo?.features?.attendanceFilters.role ? <DisabledOverlay /> : null}
                        <p className=" mb-1 font-weight-bold" style={{ fontSize: "1rem" }}>
                          User
                        </p>
                        <SolidButton
                          className="ml-0"
                          onClick={(e) =>
                            toggleForm(
                              formState,
                              history,
                              location,
                              dispatch,
                              formNames.attendanceUserFilterForm
                            )
                          }
                        >
                          {Object.keys(userIds).length
                            ? `${Object.keys(userIds).length} Selected`
                            : `Filter By User`}
                        </SolidButton>
                        {Object.keys(userIds).length ? (
                          <span
                            className="font-weight-bold text-danger ml-2 cursor-pointer"
                            onClick={(e) => setUserIds({})}
                          >
                            Clear All
                          </span>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </AttendanceLogsConfigBox>
              </div>
            ) : null}

            <div className="col-12">
              <div className="d-flex align-items-center justify-content-end">
                <AttendanceCSVDownload
                  csvEndDate={csvEndDate}
                  csvStartDate={csvStartDate}
                  loading={loading}
                  csvData={csvData}
                  allMemberships={allMemberships}
                  setAllMemberships={setAllMemberships}
                  userIds={userIds}
                  department={department}
                  subDepartment={subDepartment}
                  role={role}
                />
                <AttendanceSheetDownload
                  accessLogs={accessLogs}
                  allMemberships={allMemberships}
                  setAllMemberships={setAllMemberships}
                  userIds={userIds}
                  selfLogs={selfLogs}
                  sd={csvStartDate}
                  ed={csvEndDate}
                  includeChild={includeChild}
                  department={department}
                  subDepartment={subDepartment}
                  role={role}
                />
                {isAdmin(user, nodeInfo) ? (
                  <IconContainer>
                    <FcSettings
                      onClick={(e) =>
                        toggleForm(
                          formState,
                          history,
                          location,
                          dispatch,
                          formNames.attendanceFormatForm
                        )
                      }
                    />
                  </IconContainer>
                ) : null}
              </div>
            </div>
          </div>
        </TimingContainer>
      </div>
      <div className="col-12" ref={attendanceDateBoxRef}>
        <TimingContainer>
          <AttendanceLogsConfigBox dimentions={dimentions}>
            <h5 className="font-weight-bold mr-3 m-0">Attendance Logs</h5>
            <div className="row">
              <div className="col-md-12 col-lg-12 col-xs-12 col-sm-12">
                <DatePicker
                  dateFormat="dd/MM/yyyy"
                  className="react-datepicker-custom-css"
                  selected={date}
                  onChange={(date) => setDate(date)}
                  maxDate={Date.now()}
                />
                <SolidButton
                  onClick={(e) =>
                    toggleForm(formState, history, location, dispatch, formNames.logSyncStatusForm)
                  }
                >
                  Log Sync Status
                </SolidButton>
                <SolidButton
                  onClick={(e)=>{
                    nodePlatformLockLogsSync(
                      user,
                      dispatch,
                      NotificationManager,
                      removeCookie,
                      history,
                      selectedNode.nodeId
                    )
                  }}
                >
                  Data Sync
                </SolidButton>
              </div>
            </div>
          </AttendanceLogsConfigBox>
        </TimingContainer>
      </div>
      <div className="col-12" ref={attendanceLogBoxRef}>
        <AttendanceLogsUserRow zoneLogs={zoneLogs} />
      </div>
    </>
  );
};

export default AttendanceLogs;
