import React, { useEffect, useState } from "react";
import { BsFillTriangleFill } from "react-icons/bs";
import { hasSubArray } from "../../utils/utilFunctions";
import AccessDays from "../forms/AccessDays";
import AccessTime from "../forms/AccessTime";
import { FieldInput, FieldLabel, InputGroup } from "../forms/FormBase.styled";
import { SubContainer } from "../selectBox/customSelect/customSelect.styled";
import { Arrow, ArrowContainer } from "../forms/visitorAddForm/VisitorAddForm";
const SubMenu = ({
  hierarchy,
  name,
  nodeId,
  expanded,
  setExpanded,
  accessPermission,
  setAccessPermission,
  setParentChildIds,
  parentChildIds,
  checkedNodes,
  setCheckedNodes,
}) => {
  const [childIds, setChildIds] = useState([]);

  const handleCheck = (e) => {
    if (e.target.checked) {
      setCheckedNodes({
        ...checkedNodes,
        [nodeId]: {
          ...checkedNodes[nodeId],
          daysofWeek: [true, true, true, true, true, true, true],
          startTimeInDay: "00:00",
          endTimeInDay: "24:00",
        },
      });
    } else {
      let temp = { ...checkedNodes };
      delete temp[nodeId].daysofWeek;
      delete temp[nodeId].startTimeInDay;
      delete temp[nodeId].endTimeInDay;
      setCheckedNodes({ ...temp });
    }
  };

  const handleBulkNodeCheck = (nodeids, parent) => {
    let temp = { ...checkedNodes };
    let daysofWeek = temp[parent] ? temp[parent].daysofWeek : [true, true, true, true, true, true, true];

    let startTimeInDay = temp[parent] ? temp[parent].startTimeInDay : "00:00";

    let endTimeInDay = temp[parent] ? temp[parent].endTimeInDay : "24:00";

    let visitorManagement = temp[parent] ? temp[parent].visitorManagement : false;

    let CRB = temp[parent] ? temp[parent].CRB : false;

    nodeids.forEach((nodeId) => {
      temp = {
        ...temp,
        [nodeId]: {
          nodeId,
          daysofWeek,
          startTimeInDay,
          endTimeInDay,
          visitorManagement,
          CRB,
        },
      };
    });
    setCheckedNodes({ ...temp });
  };

  const clearBulkNodeCheck = (nodeids) => {
    let temp = { ...checkedNodes };
    nodeids.forEach((nodeId) => {
      if (temp && Object.keys(temp).includes(nodeId)) {
        delete temp[nodeId];
      }
    });
    setCheckedNodes({ ...temp });
  };

  const handleNodeCheck = (nodeId) => {
    let temp = { ...checkedNodes };
    if (temp && Object.keys(temp).includes(nodeId)) {
      delete temp[nodeId];
      setCheckedNodes({ ...temp });
    } else {
      temp = {
        ...temp,
        [nodeId]: {
          nodeId,
          visitorManagement: false,
          CRB: false,
        },
      };
      setCheckedNodes({ ...temp });
    }
  };

  const setTime = (e, field) => {
    if (e === true) {
      setCheckedNodes({
        ...checkedNodes,
        [nodeId]: {
          ...checkedNodes[nodeId],
          startTimeInDay: "00:00",
          endTimeInDay: "24:00",
        },
      });
    } else if (e === false) {
      setCheckedNodes({
        ...checkedNodes,
        [nodeId]: {
          ...checkedNodes[nodeId],
          startTimeInDay: "09:00",
          endTimeInDay: "21:00",
        },
      });
    } else {
      setCheckedNodes({
        ...checkedNodes,
        [nodeId]: {
          ...checkedNodes[nodeId],
          [field]: e,
        },
      });
    }
  };

  const handleDaysChange = (index, nodeId, selectAll, clearAll) => {
    let tempDays = [...checkedNodes[nodeId].daysofWeek];
    tempDays[index] = !tempDays[index];
    if (selectAll) {
      tempDays = tempDays.map((i) => true);
    }
    if (clearAll) {
      tempDays = tempDays.map((i) => false);
    }
    setCheckedNodes({
      ...checkedNodes,
      [nodeId]: { ...checkedNodes[nodeId], daysofWeek: [...tempDays] },
    });
  };

  // const createChildIdsArray = (nodes, acc) => {
  //   let temp = acc ? acc : [nodeId];
  //   if (nodes.length !== undefined) {
  //     nodes.forEach((obj) => {
  //       temp.push(obj.nodeId);
  //       if (obj.children.length !== undefined) {
  //         obj.children.forEach((nObj) => {
  //           return createChildIdsArray(nObj, temp)
  //         });
  //       }
  //     });
  //   }
  //   return temp;
  // };

  useEffect(() => {
    if (hierarchy && hierarchy.length) {
      setChildIds([nodeId, ...hierarchy.map((h) => h.nodeId)]);
    }
  }, [hierarchy]);

  useEffect(() => {
    if (setParentChildIds) {
      setParentChildIds([...Array.from([...new Set([...parentChildIds, ...childIds])])]);
    }
  }, [childIds]);

  return (
    <div>
      <InputGroup>
        <FieldLabel>
          <ArrowContainer
            onClick={(e) => {
              setExpanded({ ...expanded, [nodeId]: !expanded[nodeId] });
            }}
          >
            {hierarchy.length ? (
              <Arrow className="m-0 p-0 mr-2" expanded={nodeId === "" ? true : expanded[nodeId]}>
                <BsFillTriangleFill />
              </Arrow>
            ) : null}

            <>
              <input
                type="checkbox"
                className="mr-2"
                checked={Object.keys(checkedNodes).includes(nodeId)}
                onChange={(e) => {
                  e.stopPropagation();
                  handleNodeCheck(nodeId);
                }}
                onClick={(e) => e.stopPropagation()}
              />
              <small style={{ fontSize: "1.3rem" }} className="font-weight-bold">
                {name}
              </small>
              {childIds.length ? (
                <div className="ml-auto">
                  {hasSubArray(Object.keys(checkedNodes), childIds) ? (
                    <small
                      style={{ fontWeight: "bold", cursor: "pointer", color: "red" }}
                      onClick={(e) => {
                        e.stopPropagation();
                        clearBulkNodeCheck(childIds);
                      }}
                    >
                      clear
                    </small>
                  ) : (
                    <input
                      type="checkbox"
                      className="mr-2"
                      checked={hasSubArray(Object.keys(checkedNodes), childIds)}
                      onChange={(e) => {
                        e.stopPropagation();
                        handleBulkNodeCheck(childIds, nodeId);
                      }}
                      onClick={(e) => e.stopPropagation()}
                    />
                  )}
                </div>
              ) : null}
            </>
          </ArrowContainer>
        </FieldLabel>
        {Object.keys(checkedNodes).includes(nodeId) && checkedNodes[nodeId] ? (
          <FieldInput className="ml-4">
            <div style={{ display: "flex", alignItems: "center" }}>
              <input
                className="m-0"
                type="checkbox"
                onChange={handleCheck}
                checked={checkedNodes[nodeId] && checkedNodes[nodeId].daysofWeek}
              />
              <span className="ml-2" style={{ fontSize: "1rem" }}>
                Access Permission
              </span>
            </div>
            {checkedNodes[nodeId] && checkedNodes[nodeId].daysofWeek ? (
              <>
                <AccessDays
                  daysofWeek={checkedNodes[nodeId].daysofWeek}
                  onClick={handleDaysChange}
                  nodeId={nodeId}
                />
                <AccessTime onChange={setTime} time={checkedNodes[nodeId]} />
              </>
            ) : null}
          </FieldInput>
        ) : null}
      </InputGroup>
      {Object.keys(checkedNodes).includes(nodeId) ? (
        <div className="ml-4">
          <InputGroup noMargin={true}>
            <FieldLabel></FieldLabel>
            <FieldInput>
              <div style={{ display: "flex", alignItems: "center" }}>
                <input
                  className="m-0"
                  type="checkbox"
                  checked={checkedNodes[nodeId].visitorManagement}
                  onChange={(e) =>
                    setCheckedNodes({
                      ...checkedNodes,
                      [nodeId]: {
                        ...checkedNodes[nodeId],
                        visitorManagement: e.target.checked,
                      },
                    })
                  }
                />
                <span className="ml-2">Visitor permission</span>
              </div>
            </FieldInput>
          </InputGroup>
          {/* <InputGroup noMargin={true}>
            <FieldLabel></FieldLabel>
            <FieldInput>
              <div style={{ display: "flex", alignItems: "center" }}>
                <input
                  className="m-0"
                  type="checkbox"
                  checked={checkedNodes[nodeId].CRB}
                  onChange={(e) =>
                    setCheckedNodes({
                      ...checkedNodes,
                      [nodeId]: {
                        ...checkedNodes[nodeId],
                        CRB: e.target.checked,
                      },
                    })
                  }
                />
                <span className="ml-2">Confrence room booking</span>
              </div>
            </FieldInput>
          </InputGroup> */}
        </div>
      ) : null}

      {hierarchy.map((nodes) => {
        return (
          <SubContainer
            className="ml-5"
            key={nodes.nodeId}
            expanded={nodeId === "" ? true : expanded[nodeId]}
          >
            {nodes.children ? (
              <SubMenu
                name={nodes.name}
                nodeId={nodes.nodeId}
                hierarchy={nodes.children}
                setParentChildIds={setChildIds}
                parentChildIds={childIds}
                expanded={expanded}
                setExpanded={setExpanded}
                checkedNodes={checkedNodes}
                setCheckedNodes={setCheckedNodes}
                accessPermission={accessPermission}
                setAccessPermission={setAccessPermission}
              />
            ) : null}
          </SubContainer>
        );
      })}
    </div>
  );
};

export default SubMenu;
