import React, { useEffect, useState } from "react";
import { apiEndPoints, formNames } from "../../../config";
import { breakPointWidth } from "../../../config";
import { FormWrapper } from "../doorAddForm/DoorAddForm";
import { useCookies } from "react-cookie";
import { toggleForm } from "../../../utils/utilFunctions";
import { Tooltip } from "react-hover-tooltip";
import { confirmAlert } from "react-confirm-alert"; // Import
import {
  RowContainer,
  FormContainer,
  FormContainerMobile,
  RowStyle,
  Code,
  Type,
  Name,
  Disabled,
  StartTime,
  EndTime,
  Button,
  Status,
  EditCode,
  CustomPopup,
  FieldInputMobile,
} from "./OACs.styled";
import { OverFLowScrollBox } from "../../App.styled";
import {
  FieldInput,
  FieldLabel,
  InputBox,
  InputGroup,
  SelectBox,
  FormBackground,
} from "../FormBase.styled";
import { nullChecker, displayTimeMinutes, displayDate } from "../../../utils/utilFunctions";
import {
  FieldLabelMobile,
  FieldValueMobile,
  ZoneLogsUserRowMobileView,
  ZoneLogsUserRowMobileWrapper,
} from "../../zone/ZoneLogsUserRow/zoneLogsUserRow.styled";
import { RiDeleteBin6Line } from "react-icons/ri";
import { OACTypes } from "../../../config";
import { useHistory, useLocation } from "react-router-dom";
import NotificationManager from "react-notifications/lib/NotificationManager";
import { getDeviceOACs, resyncOACs } from "../../../utils/requests";
import { hourMap } from "../../../config";
import { BiEdit } from "react-icons/bi";
import { BsFillExclamationTriangleFill } from "react-icons/bs";
import { useSelector, useDispatch } from "react-redux";
import ButtonState from "../../ButtonState/ButtonState";

const CheckOACForm = () => {
  const closeForm = () => {
    toggleForm(formState, history, location, dispatch, formNames.checkOACForm);
  };
  const [cookies, setCookie, removeCookie] = useCookies(["AT"]);
  let history = useHistory();
  const location = useLocation();
  const user = useSelector((state) => state.user);
  const initialData = useSelector((state) => state.formState[formNames.checkOACForm]);
  const dispatch = useDispatch();
  const dimentions = useSelector((state) => state.dimentions);
  const selectedNode = useSelector((state) => state.selectedNode);
  const formState = useSelector((state) => state.formState);
  const apiStatus = useSelector((state) => state.apiStatus);
  const [OACs, setOACs] = useState([]);

  useEffect(() => {
    if (initialData.deviceId)
      getDeviceOACs(
        user,
        dispatch,
        NotificationManager,
        removeCookie,
        history,
        initialData.deviceId,
        selectedNode.nodeId,
        setOACs
      );
  }, [initialData.deviceId]);

  return dimentions.width > breakPointWidth ? (
    <FormWrapper dimentions={dimentions} closeForm={closeForm}>
      <Button
        style={{ width: "200px", display: "inline-block", marginBottom: "0.5rem" }}
        disabled={initialData.type === "NORMAL_LOCK"}
        onClick={(e) =>
          resyncOACs(
            user,
            dispatch,
            NotificationManager,
            removeCookie,
            history,
            confirmAlert,
            selectedNode,
            initialData.deviceId
          )
        }
      >
        <ButtonState loading={apiStatus[apiEndPoints.resyncOACs]} innerText={"Resync OACs"} />
      </Button>
      <RowContainer>
        <RowStyle isHead={true}>
          <Name>Name</Name>
          <Code>Code</Code>
          <Type>Type</Type>
          <StartTime>Start Time</StartTime>
          <EndTime>End Time</EndTime>
          <Disabled>Disabled</Disabled>
          <Status>Sync Status</Status>
        </RowStyle>
        <OverFLowScrollBox>
          {OACs && OACs.length
            ? OACs.map((i) => {
                if (i.OAC && !(i.disabled && i.modified === 0))
                  return (
                    <RowStyle isHead={true}>
                      <Name>{i.name}</Name>
                      <Code>
                        <div>{i.OAC + `#`}</div>
                        {i.disabled ? (
                          <Tooltip content={<div>Sync needed before this code stops working</div>}>
                            <BsFillExclamationTriangleFill color="#EED202" size="1rem" />
                          </Tooltip>
                        ) : null}
                        {i.modified && !i.disabled ? (
                          <Tooltip content={<div>Sync needed before this code starts working</div>}>
                            <BsFillExclamationTriangleFill color="#EED202" size="1rem" />
                          </Tooltip>
                        ) : null}
                        {i.newOAC && !i.disabled && (
                          <div>
                            &nbsp;({i.newOAC + `#`}&nbsp;
                            <Tooltip content={<div>Sync needed before the new code works</div>}>
                              <BsFillExclamationTriangleFill color="#EED202" size="1rem" />)
                            </Tooltip>
                          </div>
                        )}
                      </Code>
                      <Type>{OACTypes[Number(i.OACType)][0]}</Type>
                      <StartTime>{displayTimeMinutes(i.startTime * 1000)}</StartTime>
                      <EndTime>{i.endTime ? displayTimeMinutes(i.endTime * 1000) : "-"}</EndTime>
                      <Disabled>{i.disabled ? "Disabled" : "-"}</Disabled>
                      <Status>
                        {i.modified
                          ? `Sync pending from ${displayTimeMinutes(i.modified * 1000)}`
                          : i.lastSyncTime
                          ? `Last synced at ${displayTimeMinutes(i.lastSyncTime * 1000)}`
                          : "Synced"}
                      </Status>
                    </RowStyle>
                  );
              })
            : null}
        </OverFLowScrollBox>
      </RowContainer>
    </FormWrapper>
  ) : (
    <FormWrapper dimentions={dimentions} closeForm={closeForm}>
      <div className="col-sm-12 col-xs-12 p-0 col-md-12 px-md-2">
        <Button
          style={{ width: "200px", display: "inline-block", marginBottom: "0.5rem" }}
          disabled={initialData.type === "NORMAL_LOCK"}
          onClick={(e) =>
            resyncOACs(
              user,
              dispatch,
              NotificationManager,
              removeCookie,
              history,
              confirmAlert,
              selectedNode,
              initialData.deviceId
            )
          }
        >
          <ButtonState loading={apiStatus[apiEndPoints.resyncOACs]} innerText={"Resync OACs"} />
        </Button>
        {OACs && OACs.length
          ? OACs.map((i) => {
              if (i.OAC && !(i.disabled && i.modified === 0))
                return (
                  <ZoneLogsUserRowMobileWrapper>
                    <ZoneLogsUserRowMobileView>
                      <div>
                        <FieldLabelMobile>Name</FieldLabelMobile>
                        <div>
                          <FieldValueMobile>{i.name}</FieldValueMobile>
                        </div>
                      </div>
                      <div>
                        <FieldLabelMobile>Code</FieldLabelMobile>
                        <div>
                          <FieldValueMobile>
                            {i.OAC + `#`}
                            {i.disabled ? (
                              <Tooltip content={<div>Sync needed before this code stops working</div>}>
                                <BsFillExclamationTriangleFill color="#EED202" size="1rem" />
                              </Tooltip>
                            ) : null}
                            {i.modified ? (
                              <Tooltip content={<div>Sync needed before this code starts working</div>}>
                                <BsFillExclamationTriangleFill color="#EED202" size="1rem" />
                              </Tooltip>
                            ) : null}
                            {i.newOAC && !i.disabled && (
                              <div>
                                &nbsp;({i.newOAC + `#`}&nbsp;
                                <Tooltip content={<div>Sync needed before the new code works</div>}>
                                  <BsFillExclamationTriangleFill color="#EED202" size="1rem" />)
                                </Tooltip>
                              </div>
                            )}
                          </FieldValueMobile>
                        </div>
                      </div>
                      <div>
                        <FieldLabelMobile>Type</FieldLabelMobile>
                        <div>
                          <FieldValueMobile>{OACTypes[Number(i.OACType)][0]}</FieldValueMobile>
                        </div>
                      </div>
                      <div>
                        <FieldLabelMobile>Disabled</FieldLabelMobile>
                        <div>
                          <FieldValueMobile>{i.disabled ? "Disabled" : "-"}</FieldValueMobile>
                        </div>
                      </div>
                      <div>
                        <FieldLabelMobile>Start Time</FieldLabelMobile>
                        <div>
                          <FieldValueMobile>{displayTimeMinutes(i.startTime * 1000)}</FieldValueMobile>
                        </div>
                      </div>
                      <div>
                        <FieldLabelMobile>End Time</FieldLabelMobile>
                        <div>
                          <FieldValueMobile>
                            {i.endTime ? displayTimeMinutes(i.endTime * 1000) : "-"}
                          </FieldValueMobile>
                        </div>
                      </div>
                    </ZoneLogsUserRowMobileView>
                    <div style={{ marginTop: "0.5rem" }}>
                      <FieldLabelMobile>Status</FieldLabelMobile>
                      <div>
                        <FieldValueMobile>
                          {i.modified
                            ? `Sync pending from ${displayTimeMinutes(i.modified * 1000)}`
                            : i.lastSyncTime
                            ? `Last synced at ${displayTimeMinutes(i.lastSyncTime * 1000)}`
                            : "Synced"}
                        </FieldValueMobile>
                      </div>
                    </div>
                  </ZoneLogsUserRowMobileWrapper>
                );
            })
          : null}
      </div>
    </FormWrapper>
  );
};

export default CheckOACForm;
