import React from "react";
import { ZoneRestrictionCardContainer, Title } from "./zoneRestrictionCard.styled";
import { MutedText, ToggleBox, ToggleTitle } from "../zoneNotificationsCard/zoneNotificationCard.styled";
import { BiEdit } from "react-icons/bi";
import GreenSwitch from "../../../switches/GreenSwitch";
import { useDispatch, useSelector } from "react-redux";
import { formNames } from "../../../../config";
import { useCookies } from "react-cookie";
import { useHistory, useLocation } from "react-router-dom";
import NotificationManager from "react-notifications/lib/NotificationManager";
import { toggleForm } from "../../../../utils/utilFunctions";
import { IconContainer } from "../../../App.styled";
import { ZoneAccessCardCount } from "../zoneAccessCard/zoneAccessCard.styled";
import { updateNodeInfoAPI } from "../../../../utils/requests";
import { confirmAlert } from "react-confirm-alert";
import GateOpenAlertDuration from "../../../forms/gateOpenAlertDurationForm/GateOpenAlertDuration";
import DisabledOverlay from "../../../DisabledOverlay";

const ZoneRestrictionsCard = () => {
  const [cookies, setCookie, removeCookie] = useCookies(["AT"]);
  let history = useHistory();
  const dispatch = useDispatch();
  const nodeInfo = useSelector((state) => state.nodeInfo);
  const selectedNode = useSelector((state) => state.selectedNode);
  const user = useSelector((state) => state.user);
  const formState = useSelector((state) => state.formState);
  const location = useLocation();
  const updateNodeInfo = async (description, reqBody) => {
    let body = { nodeId: selectedNode.nodeId, ...reqBody };
    confirmAlert({
      title: "Confirm!",
      message: description,
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            updateNodeInfoAPI(
              user,
              dispatch,
              NotificationManager,
              removeCookie,
              history,
              selectedNode,
              body
            );
          },
        },
        {
          label: "No",
          onClick: () => null,
        },
      ],
    });
  };

  return (
    <ZoneRestrictionCardContainer>
      <Title>Configuration</Title>
      {/* <ToggleBox>
        <ToggleTitle>Allow if Threshold Reached</ToggleTitle>
        <GreenSwitch
        checked={nodeInfo.restrictIfPeopleLimitReached}
        onChange={(e) =>
          updateNodeInfo(
            `This action would ${
              nodeInfo.restrictIfPeopleLimitReached ? "not allow" : "allow"
            } people to enter the premises after the limit has reached. Are you sure you want to enable this?`,
            {
              restrictIfPeopleLimitReached: !nodeInfo.restrictIfPeopleLimitReached,
            }
            )
          }
          />
        </ToggleBox> */}
      <ToggleBox>
        <ToggleTitle>Allow if Fever Detected</ToggleTitle>
        <GreenSwitch
          checked={nodeInfo.restrictIfTempHigh}
          onChange={(e) =>
            updateNodeInfo(
              `This action would ${
                nodeInfo.restrictIfTempHigh ? "not allow" : "allow"
              } people with higher temperatures to enter the premises. Are you sure you want to enable this?`,
              {
                restrictIfTempHigh: !nodeInfo.restrictIfTempHigh,
              }
            )
          }
        />
      </ToggleBox>
      <ToggleBox>
        <ToggleTitle>Disable Access Control</ToggleTitle>
        <GreenSwitch
          checked={nodeInfo.bypassAccessControl}
          onChange={(e) =>
            updateNodeInfo(
              `This action would ${
                nodeInfo.bypassAccessControl ? "prevent" : "allow"
              } people who dont have access to enter the premises. Are you sure you want to enable this?`,
              {
                bypassAccessControl: !nodeInfo.bypassAccessControl,
              }
            )
          }
        />
      </ToggleBox>
      <ToggleBox>
        <ToggleTitle>Enable Exit Authentication</ToggleTitle>
        <GreenSwitch
          checked={nodeInfo.exitAuthentication}
          onChange={(e) =>
            updateNodeInfo(
              `This action would ${
                nodeInfo.exitAuthentication ? "not require" : "require"
              } people to provide authentication while exiting the premises. Are you sure you want to enable this?`,
              {
                exitAuthentication: !nodeInfo.exitAuthentication,
              }
            )
          }
        />
      </ToggleBox>
      <ToggleBox>
        <ToggleTitle>Disable Temperature Scanner</ToggleTitle>
        <GreenSwitch
          checked={nodeInfo.bypassTemperatureScanner}
          onChange={(e) =>
            updateNodeInfo(
              `This action would ${
                nodeInfo.bypassTemperatureScanner ? "not allow" : "allow"
              } people to enter the premises without scanning their temperature. Are you sure you want to enable this?`,
              {
                bypassTemperatureScanner: !nodeInfo.bypassTemperatureScanner,
              }
            )
          }
        />
      </ToggleBox>
      <ToggleBox>
        <ToggleTitle>Disable Proximity Scanner</ToggleTitle>
        <GreenSwitch
          checked={nodeInfo.bypassProximityScanner}
          onChange={(e) =>
            updateNodeInfo(
              `This action would ${
                nodeInfo.bypassProximityScanner ? "enable" : "disable"
              } the proximity sensor in the entry/exit control devices. Are you sure you want to enable this?`,
              {
                bypassProximityScanner: !nodeInfo.bypassProximityScanner,
              }
            )
          }
        />
      </ToggleBox>
      <ToggleBox>
        <ToggleTitle>Enable Gate Open Alert</ToggleTitle>
        <GreenSwitch
          checked={nodeInfo.gateOpenAlert}
          onChange={(e) =>
            updateNodeInfo(
              `This action would ${
                nodeInfo.gateOpenAlert ? "disable" : "enable"
              } the alerts to the proper personnel on gate open events. Are you sure that you want to enable this?`,
              {
                gateOpenAlert: !nodeInfo.gateOpenAlert,
              }
            )
          }
        />
      </ToggleBox>
      <div className="d-flex justify-content-between aic">
        <div>
          {nodeInfo.gateOpenAlertDuration ? (
            <ZoneAccessCardCount>{nodeInfo.gateOpenAlertDuration / 1000}s</ZoneAccessCardCount>
          ) : null}
        </div>
        <MutedText className="ml-2">Gate Open Alert Duration</MutedText>
      </div>
      <div className="d-flex align-items-center justify-content-end mb-2 mt-1">
        <IconContainer>
          <BiEdit
            // onClick={(e) =>
            //   toggleForm(
            //     formState,
            //     history,
            //     location,
            //     dispatch,
            //     formNames.GOADUpdateForm
            //   )
            // }
            data-toggle="collapse"
            data-target="#goadDialouge"
            aria-expanded="false"
            aria-controls="goadDialouge"
            className="cursor-pointer m-0 p-0"
            id="goadDialougeControl"
          />
        </IconContainer>
      </div>
      <div className="collapse my-3" id="goadDialouge">
        <div className="card card-body">
          <GateOpenAlertDuration />
        </div>
      </div>
    </ZoneRestrictionCardContainer>
  );
};

export default ZoneRestrictionsCard;
