import { UPDATE_USER, DELETE_USER } from "../actionTypes";

const userReducer = (user = null, action) => {
  if (action.type === UPDATE_USER) {
    
    return (user = action.payload);
  }
  if (action.type === DELETE_USER) {
    return (user = null);
  }

  return user;
};

export default userReducer;
