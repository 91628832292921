import React, { useState } from "react";
import { useCookies } from "react-cookie";
import NotificationManager from "react-notifications/lib/NotificationManager";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { rootApi } from "../../../config";
import { get, post } from "../../../utils/apiService";
import { ATexpired } from "../../../utils/logout";
import { createNotification, notificationType } from "../../../vms/Services/utils";

const PLATFORMLOCKFORM = ({ user }) => {
  const [cookies, setCookie, removeCookie] = useCookies(["AT"]);
  const dispatch = useDispatch();
  const history = useHistory();
  const [response, setResponse] = useState(null);
  const platformLockAdd = async (e) => {
    e.preventDefault();
    const url = `${rootApi}device/add`;
    const body = {
      thirdPartyDeviceId: e.target.thirdPartyDeviceId.value,
      name: e.target.name.value,
      MAC: e.target.MAC.value,
      type: "PLATFORM_LOCK",
    };
    try {
      const response = await post(dispatch, url, body, user.AT);
      if (!response.data.success) {
        createNotification(NotificationManager, notificationType.error, response.data.error);
      } else {
        const { error, success, message, customDeviceId } = response.data;
        setResponse({ error, success, message, customDeviceId });
      }
      ATexpired(response, dispatch, removeCookie, history);
    } catch (err) {
      console.log("rf card add failed");
    }
  };
  return (
    <div className="py-5 px-2 text-center">
      {response ? (
        <div className="text-center">
          <h4 className={`${response.success ? "text-success" : "text-danger"} my-4 font-weight-bold`}>
            {response.message ? response.message : response.error}
          </h4>
          <h6>Custom Device Id : {response.customDeviceId}</h6>
          <button className="btn btn-sm btn-info" onClick={(e) => setResponse(null)}>
            Add Another
          </button>
        </div>
      ) : (
        <form onSubmit={platformLockAdd}>
          <div className="form-group">
            <input
              placeholder="thirdPartyDeviceId"
              type="text"
              className="form-control"
              required
              name="thirdPartyDeviceId"
            />
          </div>
          <div className="form-group">
            <input placeholder="name" type="text" className="form-control" required name="name" />
          </div>
          <div className="form-group">
            <input placeholder="MAC" type="text" className="form-control" required name="MAC" />
          </div>

          <button type="submit" className="btn btn-primary">
            Submit
          </button>
        </form>
      )}
    </div>
  );
};

export default PLATFORMLOCKFORM;
