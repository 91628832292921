import React from "react";

const PlaceholderMessage = ({ message }) => {
  return (
    <h5
      className="py-5 my-5"
      style={{ color: "grey", textAlign: "center", width: "100%" }}
    >
      {message ? message : "Please add a root node to start"}
    </h5>
  );
};

export default PlaceholderMessage;
