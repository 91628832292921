import { nodeCategoryMap } from "../../config";
import { logoutURI } from "../configs";

export const formatDate = (d) => {
  let months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

  const date = new Date(d * 1000);
  return `${date.getDate()} ${months[date.getMonth()]} ${
    date.getHours() < 10 ? "0" + date.getHours() : date.getHours()
  }:${date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()} `;
};

export const getQueryParams = (qs) => {
  qs = qs.split("+").join(" ");

  var params = {},
    tokens,
    re = /[?&]?([^=]+)=([^&]*)/g;

  while ((tokens = re.exec(qs))) {
    params[decodeURIComponent(tokens[1])] = decodeURIComponent(tokens[2]);
  }

  return params;
};

export const ATexpired = (response) => {
  if (response.data.code === 1003) {
    window.location.href = logoutURI;
  }
};

export const createNotification = (NotificationManager, type, message) => {
  if (message === "provided nodeId doesnot belongs to any zone") return;
  let delay = message && message.length >= 150 ? 6000 : 3000;
  if (message === "User not registered or Wrong AT" && sessionStorage.getItem("AuthError") === "true") {
    return;
  } else if (message === "User not registered or Wrong AT") {
    sessionStorage.setItem("AuthError", "true");
  }
  switch (type) {
    case "info":
      NotificationManager.info(message, "", delay);
      break;
    case "success":
      NotificationManager.success(message, "", delay);
      break;
    case "warning":
      NotificationManager.warning(message, "", delay);
      break;
    case "error":
      NotificationManager.error(message, "Error", delay);
      break;
  }
};

export const notificationType = {
  info: "info",
  success: "success",
  warning: "warning",
  error: "error",
};

export const inputFieldsMap = (node) => {
  const {
    INSTITUTE,
    COMMERCIAL,
    STUDENT_HOUSING,
    RESIDENTIAL,
    COWORKING,
    GYM,
    COLIVING,
    HOSPITAL,
    OFFICE,
    EVENT,
  } = nodeCategoryMap;
  if (!node) {
    return null;
  } else {
    const fields = {
      [RESIDENTIAL]: [
        "name",
        "contact",
        "userToMeetContact",
        "selectedNode",

        node.maxAddonGuests >= 1 ? "noOfGuests" : null,
        "purpose",
      ],
      [COMMERCIAL]: [
        "name",
        "contact",
        "userToMeetContact",
        "selectedNode",

        node.maxAddonGuests >= 1 ? "noOfGuests" : null,
        // "purpose",
        // "companyName",
      ],

      [OFFICE]: [
        "name",
        "contact",
        "userToMeetContact",
        "selectedNode",
        node.maxAddonGuests >= 1 ? "noOfGuests" : null,
        "purpose",
        "companyName",
      ],
      [INSTITUTE]: [
        "name",
        "contact",
        "userToMeetContact",
        "selectedNode",
        node.maxAddonGuests >= 1 ? "noOfGuests" : null,
      ],

      [COLIVING]: [
        "name",
        "contact",
        "userToMeetContact",
        "selectedNode",

        // "purpose",
        node.maxAddonGuests >= 1 ? "noOfGuests" : null,
      ],
      [STUDENT_HOUSING]: [
        "name",
        "contact",
        "userToMeetContact",
        "selectedNode",

        // "purpose",
        node.maxAddonGuests >= 1 ? "noOfGuests" : null,
      ],
      [COWORKING]: [
        "name",
        "contact",
        "userToMeetContact",
        "selectedNode",

        // "purpose",
        node.maxAddonGuests >= 1 ? "noOfGuests" : null,
      ],
      [HOSPITAL]: ["name", "contact", "selectedNode", node.maxAddonGuests >= 1 ? "noOfGuests" : null],
      [GYM]: ["name", "contact", "selectedNode", node.maxAddonGuests >= 1 ? "noOfGuests" : null],

      [EVENT]: ["name", "contact", "selectedNode", node.maxAddonGuests >= 1 ? "noOfGuests" : null],
    };
    if (fields[node.nodeCategory].length) {
      if (node.slots && node.slots.length > 0) {
        return [...fields[node.nodeCategory], "slots"];
      } else {
        return [...fields[node.nodeCategory], "startTime", "endTime"];
      }
    }
  }
};
