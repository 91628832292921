import React, { useEffect } from "react";
import styled from "styled-components";
import {
  PeopleRowContainer,
  PeopleRowStyled,
  Name,
  Id,
  Phone,
  Email,
  JoinDate,
  Gates,
  Type,
  EditAccess,
  Delete,
  Button,
  OAC,
  RFID,
  EndDate,
  UDID,
  EditName,
} from "./peopleRow.styled";
import { SelectBox } from "../../forms/FormBase.styled";
import { PageButtonContainer, PageButton } from "../../../paging.styled";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";
import { FcSearch } from "react-icons/fc";
// import PeopleView from "../peopleView/PeopleView";
import { useState } from "react";
// import Popover, { ArrowContainer } from "react-tiny-popover";
import { BiEdit } from "react-icons/bi";
import { RiDeleteBin6Line } from "react-icons/ri";
import { AiOutlineEye } from "react-icons/ai";
import {
  breakPointWidth,
  formNames,
  rolesMap,
  membershipRoles,
  apiEndPoints,
  rootApi,
  contentLength,
} from "../../../config";
import { useSelector } from "react-redux";
import { SolidButton } from "../../buttons/solidButton.styled";
import { confirmAlert } from "react-confirm-alert"; // Import
import { convetToLocaleDateString, nullChecker, toggleForm } from "../../../utils/utilFunctions";
import {
  ButtonMobile,
  FieldLabelMobile,
  FieldValueMobile,
  ZoneLogsUserRowMobileView,
  ZoneLogsUserRowMobileWrapper,
} from "../../zone/ZoneLogsUserRow/zoneLogsUserRow.styled";

import PlaceholderMessage from "../../PlaceholderMessage";
import { OverFLowScrollBox } from "../../App.styled";
import GreenSwitch from "../../switches/GreenSwitch";
import NotificationManager from "react-notifications/lib/NotificationManager";
import { removeMembershipAPI, getPeopleAPI, searchPeopleAPI } from "../../../utils/requests";
import { updateUtils } from "../../../redux/utils/utilsActions";
import { useLocation } from "react-router-dom";
import StringSpreader from "../../StringSpreader";
import BulkMembershipCSVdownload from "../../BulkMembershipCSVdownload";

export const SearchBox = styled.input`
  border: 1px solid #898989;
  background-color: transparent;
  padding: 0px 0.5rem;
  height: 2rem;
  border-radius: 25px;
  outline: 0;
  opacity: ${(props) => (props.disabled ? "0.7" : "1")};
  background: ${(props) => (props.disabled ? "#C4C4C4" : "")};
  width: ${(props) => (props.dimentions.width > breakPointWidth ? "70%" : "100%")};
  :focus : {
    outline: 0;
  }
`;

const PeopleRow = ({ removeCookie, history, dispatch }) => {
  const [role, setRole] = useState(null);
  const [positions, setPositions] = useState([]);
  const [showMenu, setShowMenu] = useState([]);

  const enableOAC = useSelector((state) => state.nodeInfo.enableOAC);
  const isIDCardAvailable = useSelector((state) => state.nodeInfo.isIDCardAvailable);
  const [showRfid, setShowRfid] = useState(true);
  const nodeInfo = useSelector((state) => state.nodeInfo);
  const apiStatus = useSelector((state) => state.apiStatus);
  const [searchQuery, setSearchQuery] = useState("");
  const memberships = useSelector((state) => state.memberships);
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [noOfPages, setNoOfPages] = useState(1);
  const [tempPage, setTempPage] = useState([1, 1]);
  const [displaySearch, setDisplaySearch] = useState(false);
  const [displayValues, setDisplayValues] = useState(null);
  const [membershipsHaveAccess, setmembershipsHaveAccess] = useState(null);
  const selectedNode = useSelector((state) => state.selectedNode);
  const utils = useSelector((state) => state.utils);
  const user = useSelector((state) => state.user);
  const dimentions = useSelector((state) => state.dimentions);
  const formState = useSelector((state) => state.formState);
  const location = useLocation();

  // const getMembershipsHaveAccess = async () => {
  //   let membersHaveAccess = await get(
  //     dispatch,
  //     `${rootApi}node/inAccessInfo?nodeId=${selectedNode.nodeId}&includeChild=${false}`,
  //     user.AT
  //   );
  //   membersHaveAccess = membersHaveAccess.data.users;
  //   setmembershipsHaveAccess([...membersHaveAccess]);
  // };

  useEffect(() => {
    if (nodeInfo && !nodeInfo.rootNodeId) {
      setmembershipsHaveAccess(null);
    }
  }, [nodeInfo]);

  // useEffect(() => {
  //   if (selectedNode.rootNodeId) {
  //     getMembershipsHaveAccess();
  //   }
  // }, [selectedNode.rootNodeId]);

  // useEffect(() => {
  //   console.log({ displayValues });
  // }, [displayValues]);

  // useEffect(() => {
  //   if (membershipsHaveAccess && memberships) {
  //     setDisplayValues(null);
  //     const users = memberships.filter((v) => membershipsHaveAccess.includes(v.userId));
  //     setDisplayValues([...users]);
  //   }
  // }, [membershipsHaveAccess, selectedNode, memberships, displaySearch]);

  useEffect(() => {
    setPageNo(1);
    setDisplaySearch(false);
  }, [selectedNode.nodeId, utils.includeExpired]);

  useEffect(() => {
    if (!displaySearch) {
      setDisplayValues(memberships);
    }
  }, [displaySearch, memberships]);

  useEffect(() => {
    if (nodeInfo.nodeId) {
      if (!displaySearch) {
        getPeopleAPI(
          user,
          dispatch,
          NotificationManager,
          removeCookie,
          history,
          selectedNode,
          utils,
          pageNo,
          pageSize,
          setNoOfPages,
          null,
          role
        );
      } else {
        searchPeopleAPI(
          user,
          dispatch,
          NotificationManager,
          removeCookie,
          history,
          selectedNode,
          searchQuery,
          utils,
          pageNo,
          pageSize,
          setDisplaySearch,
          setDisplayValues,
          setNoOfPages
        );
      }
    }
  }, [pageNo, pageSize, utils]);

  return (
    <>
      <div className="col-12 d-flex align-items-center m-0 p-0 my-3">
        <div className="row w-100 m-0 p-0">
          <div className="col-md-6 col-lg-6 col-sm-12 col-xs-12 d-flex align-items-center justify-content-start m-0 my-1 p-0">
            <SearchBox
              dimentions={dimentions}
              placeholder="Type to search..."
              value={searchQuery}
              onChange={(e) => {
                if (e.target.value === "") {
                  if (displaySearch) {
                    setDisplaySearch(false);
                    setPageNo(tempPage[0]);
                    setNoOfPages(tempPage[1]);
                  }
                }
                setSearchQuery(e.target.value);
              }}
              onKeyDown={(e) => {
                if (e.keyCode === 13) {
                  if (!displaySearch) {
                    setTempPage([pageNo, noOfPages]);
                  }
                  setDisplaySearch(true);
                  setPageNo(1);
                  searchPeopleAPI(
                    user,
                    dispatch,
                    NotificationManager,
                    removeCookie,
                    history,
                    selectedNode,
                    searchQuery,
                    utils,
                    1,
                    pageSize,
                    setDisplaySearch,
                    setDisplayValues,
                    setNoOfPages
                  );
                }
              }}
            />
            <FcSearch
              size="1.5rem"
              style={{ paddingLeft: "0.2rem" }}
              cursor="pointer"
              onClick={(e) => {
                if (!displaySearch) {
                  setTempPage([pageNo, noOfPages]);
                }
                setDisplaySearch(true);
                setPageNo(1);
                searchPeopleAPI(
                  user,
                  dispatch,
                  NotificationManager,
                  removeCookie,
                  history,
                  selectedNode,
                  searchQuery,
                  utils,
                  1,
                  pageSize,
                  setDisplaySearch,
                  setDisplayValues,
                  setNoOfPages
                );
              }}
            />
          </div>
          <div className="col-md-6 col-lg-6 col-sm-12 col-xs-12 d-flex align-items-center justify-content-end m-0 p-0 my-1">
            <SelectBox
              style={{ height: "30px", width: "150px", fontSize: "0.9rem" }}
              onChange={(e) => {
                if (e.target.value === "Select User Type") {
                  setRole(null);
                } else {
                  setRole(e.target.value);
                }
                setPageNo(1);
                getPeopleAPI(
                  user,
                  dispatch,
                  NotificationManager,
                  removeCookie,
                  history,
                  selectedNode,
                  utils,
                  1,
                  pageSize,
                  setNoOfPages,
                  null,
                  e.target.value === "Select User Type" ? null : e.target.value
                );
              }}
            >
              {membershipRoles(nodeInfo.nodeCategory).map((val, index) => (
                <option key={index} value={val === "Select" ? "Select User Type" : val}>
                  {val === "Select" ? "Select User Type" : val}
                </option>
              ))}
            </SelectBox>
            {/* <FieldLabelMobile className="mx-2">Show RFID</FieldLabelMobile> */}
            {/* <GreenSwitch checked={showRfid} onChange={(e) => setShowRfid(e.target.checked)} /> */}
            <FieldLabelMobile className="mx-2">Include Expired</FieldLabelMobile>
            <GreenSwitch
              checked={utils.includeExpired}
              onChange={(e) => dispatch(updateUtils({ includeExpired: !utils.includeExpired }))}
            />
            <FieldLabelMobile className="mx-2">Include Child</FieldLabelMobile>
            <GreenSwitch
              checked={utils.includeChildMemberships}
              onChange={(e) =>
                dispatch(updateUtils({ includeChildMemberships: !utils.includeChildMemberships }))
              }
            />
          </div>
          <div className="col-12 d-flex align-items-center justify-content-end m-0 p-0 my-1">
            <BulkMembershipCSVdownload />
            <SolidButton
              style={{ marginRight: "0px !important" }}
              onClick={(e) =>
                toggleForm(formState, history, location, dispatch, formNames.peopleAddForm)
              }
            >
              Add People
            </SolidButton>
            <SolidButton
              style={{ marginRight: "0px !important" }}
              onClick={(e) =>
                toggleForm(formState, history, location, dispatch, formNames.bulkOperationForm)
              }
            >
              Bulk Operations
            </SolidButton>
          </div>
        </div>
      </div>

      {dimentions.width > breakPointWidth ? (
        <PeopleRowContainer>
          <PeopleRowStyled enableOAC={enableOAC} isHead={true}>
            <Id>ID</Id>
            <Name>Name</Name>
            <EditName isHead={true}>Edit Name</EditName>
            <Phone>Phone</Phone>
            <UDID>User ID</UDID>
            <Email>Email</Email>
            <JoinDate>Join Date</JoinDate>
            <EndDate>EndDate</EndDate>
            {/* <Gates head={true}>Gates</Gates> */}
            {enableOAC ? <OAC>Keycodes</OAC> : null}
            <RFID>RFID</RFID>
            <Type>Type</Type>
            <EditAccess isHead={true}>Edit Access</EditAccess>
          </PeopleRowStyled>
          <OverFLowScrollBox>
            {apiStatus[apiEndPoints.getPeopleAPI] ||
            apiStatus[apiEndPoints.searchPeopleAPI] ||
            !displayValues ? (
              <>Loading</>
            ) : displayValues && displayValues.length ? (
              membershipsHaveAccess && membershipsHaveAccess.length ? (
                displayValues
                  .filter((v, i) => {
                    return membershipsHaveAccess.includes(v.userId);
                  })
                  .map((i, index) => {
                    return (
                      <PeopleRowStyled
                        isIDCardAvailable={isIDCardAvailable}
                        enableOAC={enableOAC}
                        key={i.membershipId}
                        isHead={false}
                        ref={(el) => {
                          let temp = positions;
                          let show = showMenu;
                          show[index] = false;
                          if (!temp[index]) {
                            temp[index] = el
                              ? {
                                  top: el.getBoundingClientRect().top,
                                  left: el.getBoundingClientRect().left,
                                  width: el.getBoundingClientRect().width,
                                }
                              : null;
                            setShowMenu(show);
                            setPositions([...temp]);
                          }
                        }}
                      >
                        <Id>
                          <StringSpreader
                            length={contentLength.customUserId}
                            string={nullChecker(i.userInfo.customUserId)}
                          ></StringSpreader>
                        </Id>
                        <Name>
                          <StringSpreader
                            length={contentLength.name}
                            string={
                              nullChecker(i.userInfo.firstName) + " " + nullChecker(i.userInfo.lastName)
                            }
                          ></StringSpreader>
                        </Name>
                        <EditName>
                          <BiEdit
                            className="cursor-pointer"
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Edit Personal Details"
                            onClick={(e) =>
                              toggleForm(
                                formState,
                                history,
                                location,
                                dispatch,
                                formNames.editNameForm,
                                {
                                  userId: i.userId,
                                  firstName: i.userInfo.firstName,
                                  lastName: i.userInfo.lastName,
                                  membershipId: i.membershipId,
                                  isIDCardAvailable: isIDCardAvailable,
                                }
                              )
                            }
                          />
                        </EditName>
                        <Phone>
                          <StringSpreader
                            length={contentLength.mobileNo}
                            string={nullChecker(i.userInfo.mobile.number)}
                          ></StringSpreader>
                        </Phone>
                        <UDID>
                          <StringSpreader
                            length={contentLength.customUserId}
                            string={nullChecker(i.UDId)}
                          ></StringSpreader>
                        </UDID>
                        <Email>
                          <StringSpreader
                            length={contentLength.email}
                            string={nullChecker(i.userInfo.email.id)}
                          ></StringSpreader>
                        </Email>
                        <JoinDate>{convetToLocaleDateString(i.startTime)}</JoinDate>
                        <EndDate>{convetToLocaleDateString(i.endTime)}</EndDate>
                        {/* <Popover
                  onClickOutside={(e) =>
                    showMenu[index] &&
                    setShowMenu([...showMenu.map((v) => false)])
                  }
                  isOpen={showMenu[index]}
                  position={"bottom"} // preferred position
                  content={({ position, targetRect, popoverRect }) => (
                    <ArrowContainer
                      position={position}
                      targetRect={targetRect}
                      popoverRect={popoverRect}
                      arrowColor={"white"}
                      arrowSize={10}
                      arrowStyle={{ border: "0.5px solid #e8e8e8" }}
                    >
                      <PeopleView
                        showMenu={showMenu}
                        noAttendance={true}
                        nodeId={selectedNode.nodeId}
                        membershipId={membershipId}
                        setShowMenu={setShowMenu}
                          removeCookie={removeCookie}
            history={history}
            dispatch={dispatch}
                      />
                    </ArrowContainer>
                  )}
                >
                  <Gates
                    onClick={(e) => {
                      let show = showMenu.map((v, i) =>
                        i === index ? true : false
                      );
                      setMembershipId(i.membershipId);
                      setShowMenu(show);
                    }}
                  >
                    View
                  </Gates>
                </Popover> */}
                        {enableOAC ? (
                          <OAC>
                            <AiOutlineEye
                              size="20px"
                              className="m-0 cursor-pointer"
                              onClick={(e) =>
                                toggleForm(
                                  formState,
                                  history,
                                  location,
                                  dispatch,
                                  formNames.addOACForm,
                                  {
                                    membershipId: i.membershipId,
                                  }
                                )
                              }
                            />
                          </OAC>
                        ) : null}
                        <RFID>
                          {i.RFCards && i.RFCards.length > 0 ? (
                            <>
                              {showRfid ? (
                                <div>
                                  {i.RFCards.map((item) => {
                                    return (
                                      <span
                                        className="cursor-pointer m-0"
                                        onClick={(e) =>
                                          toggleForm(
                                            formState,
                                            history,
                                            location,
                                            dispatch,
                                            formNames.rfidAddForm,
                                            {
                                              ...i,
                                            }
                                          )
                                        }
                                      >
                                        {item.customRFId}
                                        <br />
                                      </span>
                                    );
                                  })}
                                </div>
                              ) : (
                                <AiOutlineEye
                                  size="20px"
                                  className="m-0 cursor-pointer"
                                  onClick={(e) =>
                                    toggleForm(
                                      formState,
                                      history,
                                      location,
                                      dispatch,
                                      formNames.rfidAddForm,
                                      {
                                        ...i,
                                      }
                                    )
                                  }
                                />
                              )}
                            </>
                          ) : (
                            <Button
                              onClick={(e) =>
                                toggleForm(
                                  formState,
                                  history,
                                  location,
                                  dispatch,
                                  formNames.rfidAddForm,
                                  {
                                    ...i,
                                  }
                                )
                              }
                            >
                              Add
                            </Button>
                          )}
                        </RFID>
                        <Type>
                          <StringSpreader
                            length={contentLength.role}
                            string={nullChecker(
                              i.roles.filter((v) => v.nodeId === selectedNode.nodeId).length
                                ? i.roles.filter((v) => v.nodeId === selectedNode.nodeId)[0].role
                                : ""
                            )}
                          ></StringSpreader>
                        </Type>
                        {/* {i.accessGroups.length ? (
                      <EditAccess></EditAccess>
                    ) : ( */}
                        <EditAccess>
                          <BiEdit
                            // className="cursor-pointer"
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Edit Access"
                            style={{ color: "black", cursor: "pointer" }}
                            onClick={(e) =>
                              toggleForm(
                                formState,
                                history,
                                location,
                                dispatch,
                                formNames.membershipAccessInfoUpdateForm,
                                {
                                  membershipId: i.membershipId,
                                  subDepId: i.subDepId,
                                  nodeId: selectedNode.nodeId,
                                  depId: i.depId,
                                  UDId: i.UDId,
                                  startTime: i.startTime,
                                  emergencyContactNo: i.emergencyContactNo,
                                  endTime: i.endTime,
                                  // accessGroups: i.accessGroups,
                                  designation: i.designation,
                                  displaySearch: displaySearch,
                                  displayValues: displayValues,
                                  index: index,
                                  setDisplayValues: setDisplayValues,
                                }
                              )
                            }
                          />
                        </EditAccess>
                        {i.endTime && i.endTime < Date.now() / 1000 ? (
                          <p className="text-danger m-0 p-0 text-center mt-2 font-weight-bold">
                            Expired
                          </p>
                        ) : (
                          <Delete>
                            <RiDeleteBin6Line
                              className="cursor-pointer"
                              onClick={(e) =>
                                removeMembershipAPI(
                                  user,
                                  dispatch,
                                  NotificationManager,
                                  removeCookie,
                                  history,
                                  nullChecker(i.userInfo.mobile.number),
                                  i.membershipId,
                                  selectedNode,
                                  utils,
                                  confirmAlert
                                )
                              }
                            />
                          </Delete>
                        )}
                      </PeopleRowStyled>
                    );
                  })
              ) : (
                displayValues.map((i, index) => {
                  return (
                    <PeopleRowStyled
                      isIDCardAvailable={isIDCardAvailable}
                      enableOAC={enableOAC}
                      key={i.membershipId}
                      isHead={false}
                      ref={(el) => {
                        let temp = positions;
                        let show = showMenu;
                        show[index] = false;
                        if (!temp[index]) {
                          temp[index] = el
                            ? {
                                top: el.getBoundingClientRect().top,
                                left: el.getBoundingClientRect().left,
                                width: el.getBoundingClientRect().width,
                              }
                            : null;
                          setShowMenu(show);
                          setPositions([...temp]);
                        }
                      }}
                    >
                      <Id>
                        <StringSpreader
                          length={contentLength.customUserId}
                          string={nullChecker(i.userInfo.customUserId)}
                        ></StringSpreader>
                      </Id>
                      <Name>
                        <StringSpreader
                          length={contentLength.name}
                          string={
                            nullChecker(i.userInfo.firstName) + " " + nullChecker(i.userInfo.lastName)
                          }
                        ></StringSpreader>
                      </Name>
                      <EditName>
                        <BiEdit
                          className="cursor-pointer"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Edit Personal Details"
                          onClick={(e) =>
                            toggleForm(formState, history, location, dispatch, formNames.editNameForm, {
                              userId: i.userId,
                              firstName: i.userInfo.firstName,
                              lastName: i.userInfo.lastName,
                              membershipId: i.membershipId,
                              isIDCardAvailable: isIDCardAvailable,
                            })
                          }
                        />
                      </EditName>
                      <Phone>
                        <StringSpreader
                          length={contentLength.mobileNo}
                          string={nullChecker(i.userInfo.mobile.number)}
                        ></StringSpreader>
                      </Phone>
                      <UDID>
                        <StringSpreader
                          length={contentLength.customUserId}
                          string={nullChecker(i.UDId)}
                        ></StringSpreader>
                      </UDID>
                      <Email>
                        <StringSpreader
                          length={contentLength.email}
                          string={nullChecker(i.userInfo.email.id)}
                        ></StringSpreader>
                      </Email>
                      <JoinDate>{convetToLocaleDateString(i.startTime)}</JoinDate>
                      <EndDate>{convetToLocaleDateString(i.endTime)}</EndDate>
                      {/* <Popover
                  onClickOutside={(e) =>
                    showMenu[index] &&
                    setShowMenu([...showMenu.map((v) => false)])
                  }
                  isOpen={showMenu[index]}
                  position={"bottom"} // preferred position
                  content={({ position, targetRect, popoverRect }) => (
                    <ArrowContainer
                      position={position}
                      targetRect={targetRect}
                      popoverRect={popoverRect}
                      arrowColor={"white"}
                      arrowSize={10}
                      arrowStyle={{ border: "0.5px solid #e8e8e8" }}
                    >
                      <PeopleView
                        showMenu={showMenu}
                        noAttendance={true}
                        nodeId={selectedNode.nodeId}
                        membershipId={membershipId}
                        setShowMenu={setShowMenu}
                          removeCookie={removeCookie}
            history={history}
            dispatch={dispatch}
                      />
                    </ArrowContainer>
                  )}
                >
                  <Gates
                    onClick={(e) => {
                      let show = showMenu.map((v, i) =>
                        i === index ? true : false
                      );
                      setMembershipId(i.membershipId);
                      setShowMenu(show);
                    }}
                  >
                    View
                  </Gates>
                </Popover> */}
                      {enableOAC ? (
                        <OAC>
                          <AiOutlineEye
                            size="20px"
                            className="m-0 cursor-pointer"
                            onClick={(e) =>
                              toggleForm(formState, history, location, dispatch, formNames.addOACForm, {
                                membershipId: i.membershipId,
                              })
                            }
                          />
                        </OAC>
                      ) : null}
                      <RFID>
                        {i.RFCards && i.RFCards.length > 0 ? (
                          <>
                            {showRfid ? (
                              <div>
                                {i.RFCards.map((item) => {
                                  return (
                                    <span
                                      className="cursor-pointer m-0"
                                      onClick={(e) =>
                                        toggleForm(
                                          formState,
                                          history,
                                          location,
                                          dispatch,
                                          formNames.rfidAddForm,
                                          {
                                            ...i,
                                          }
                                        )
                                      }
                                    >
                                      {item.customRFId}
                                      <br />
                                    </span>
                                  );
                                })}
                              </div>
                            ) : (
                              <AiOutlineEye
                                size="20px"
                                className="m-0 cursor-pointer"
                                onClick={(e) =>
                                  toggleForm(
                                    formState,
                                    history,
                                    location,
                                    dispatch,
                                    formNames.rfidAddForm,
                                    {
                                      ...i,
                                    }
                                  )
                                }
                              />
                            )}
                          </>
                        ) : (
                          <Button
                            onClick={(e) =>
                              toggleForm(formState, history, location, dispatch, formNames.rfidAddForm, {
                                ...i,
                              })
                            }
                          >
                            Add
                          </Button>
                        )}
                      </RFID>

                      <Type>
                        <StringSpreader
                          length={contentLength.role}
                          string={nullChecker(
                            i.roles.filter((v) => v.nodeId === selectedNode.nodeId).length
                              ? i.roles.filter((v) => v.nodeId === selectedNode.nodeId)[0].role
                              : ""
                          )}
                        ></StringSpreader>
                      </Type>
                      {/* {i.accessGroups.length ? (
                      <EditAccess></EditAccess>
                    ) : ( */}
                      <EditAccess>
                        <BiEdit
                          // className="cursor-pointer"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Edit Access"
                          style={{ color: "black", cursor: "pointer" }}
                          onClick={(e) =>
                            toggleForm(
                              formState,
                              history,
                              location,
                              dispatch,
                              formNames.membershipAccessInfoUpdateForm,
                              {
                                membershipId: i.membershipId,
                                subDepId: i.subDepId,
                                nodeId: selectedNode.nodeId,
                                depId: i.depId,
                                UDId: i.UDId,
                                startTime: i.startTime,
                                emergencyContactNo: i.emergencyContactNo,
                                endTime: i.endTime,
                                // accessGroups: i.accessGroups,
                                designation: i.designation,
                                displaySearch: displaySearch,
                                displayValues: displayValues,
                                index: index,
                                setDisplayValues: setDisplayValues,
                                roles: i.roles,
                              }
                            )
                          }
                        />
                      </EditAccess>
                      {i.endTime && i.endTime < Date.now() / 1000 ? (
                        <p className="text-danger m-0 p-0 text-center mt-2 font-weight-bold">Expired</p>
                      ) : (
                        <Delete>
                          <RiDeleteBin6Line
                            className="cursor-pointer"
                            onClick={(e) =>
                              removeMembershipAPI(
                                user,
                                dispatch,
                                NotificationManager,
                                removeCookie,
                                history,
                                nullChecker(i.userInfo.mobile.number),
                                i.membershipId,
                                selectedNode,
                                utils,
                                confirmAlert
                              )
                            }
                          />
                        </Delete>
                      )}
                    </PeopleRowStyled>
                  );
                })
              )
            ) : (
              <PlaceholderMessage message="No data to display" />
            )}
          </OverFLowScrollBox>
        </PeopleRowContainer>
      ) : (
        <>
          <div className="row m-0 p-0">
            {displayValues && displayValues.length ? (
              membershipsHaveAccess && membershipsHaveAccess.length ? (
                displayValues
                  .filter((v, i) => {
                    return membershipsHaveAccess.includes(v.userId);
                  })
                  .map((i, index) => {
                    return (
                      <div className="col-sm-12 col-xs-12 p-0 col-md-6 px-md-2" key={i.membershipId}>
                        <ZoneLogsUserRowMobileWrapper>
                          <ZoneLogsUserRowMobileView>
                            <div>
                              <FieldLabelMobile>ID</FieldLabelMobile>
                              <FieldValueMobile>{nullChecker(i.userInfo.customUserId)}</FieldValueMobile>
                            </div>
                            <div>
                              <FieldLabelMobile>Name</FieldLabelMobile>
                              <div className="d-flex justify-content-between">
                                <FieldValueMobile>
                                  {nullChecker(i.userInfo.firstName) +
                                    " " +
                                    nullChecker(i.userInfo.lastName)}
                                </FieldValueMobile>
                                <BiEdit
                                  style={{ fontSize: "1rem" }}
                                  className="cursor-pointer"
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title="Edit Name"
                                  onClick={(e) =>
                                    toggleForm(
                                      formState,
                                      history,
                                      location,
                                      dispatch,
                                      formNames.editNameForm,
                                      {
                                        userId: i.userId,
                                        firstName: i.userInfo.firstName,
                                        lastName: i.userInfo.lastName,
                                        membershipId: i.membershipId,
                                        isIDCardAvailable: isIDCardAvailable,
                                      }
                                    )
                                  }
                                />
                              </div>
                            </div>
                            <div>
                              <FieldLabelMobile>Phone</FieldLabelMobile>
                              <FieldValueMobile>
                                {nullChecker(i.userInfo.mobile.number)}
                              </FieldValueMobile>
                            </div>
                            <div>
                              <FieldLabelMobile>User ID</FieldLabelMobile>
                              <FieldValueMobile>{nullChecker(i.UDId)}</FieldValueMobile>
                            </div>

                            <div>
                              <FieldLabelMobile>Join Date</FieldLabelMobile>
                              <FieldValueMobile>
                                {convetToLocaleDateString(i.startTime)}
                              </FieldValueMobile>
                            </div>
                            <div>
                              <FieldLabelMobile>End Date</FieldLabelMobile>
                              <FieldValueMobile>{convetToLocaleDateString(i.endTime)}</FieldValueMobile>
                            </div>
                            {i?.RFCards && i?.RFCards.length > 0 ? (
                              <>
                                <div>
                                  <FieldLabelMobile>RFID</FieldLabelMobile>
                                  <FieldValueMobile
                                    onClick={(e) =>
                                      toggleForm(
                                        formState,
                                        history,
                                        location,
                                        dispatch,
                                        formNames.rfidAddForm,
                                        {
                                          ...i,
                                        }
                                      )
                                    }
                                    style={{ cursor: "pointer" }}
                                  >
                                    {showRfid ? (
                                      <>
                                        {i.RFCards.map((item) => {
                                          return (
                                            <span>
                                              {item.customRFId}
                                              <br />
                                            </span>
                                          );
                                        })}
                                      </>
                                    ) : (
                                      "view"
                                    )}
                                  </FieldValueMobile>
                                </div>
                              </>
                            ) : (
                              <>
                                <div>
                                  <FieldLabelMobile>RFID</FieldLabelMobile>
                                  <FieldValueMobile>
                                    <p
                                      style={{
                                        color: "blue",
                                        fontSize: "0.8rem",
                                        fontWeight: "bold",
                                        margin: "0px 0px",
                                        cursor: "pointer",
                                      }}
                                      onClick={(e) =>
                                        toggleForm(
                                          formState,
                                          history,
                                          location,
                                          dispatch,
                                          formNames.rfidAddForm,
                                          {
                                            ...i,
                                          }
                                        )
                                      }
                                    >
                                      + Add
                                    </p>
                                  </FieldValueMobile>
                                </div>
                              </>
                            )}
                            <div>
                              <FieldLabelMobile>Role</FieldLabelMobile>
                              <FieldValueMobile>
                                {i.roles.filter((v) => v.nodeId === selectedNode.nodeId)[0]
                                  ? i.roles.filter((v) => v.nodeId === selectedNode.nodeId)[0].role
                                  : "-"}
                              </FieldValueMobile>
                            </div>
                            {enableOAC ? (
                              <>
                                <div>
                                  <FieldLabelMobile>Keycodes</FieldLabelMobile>
                                  <FieldValueMobile
                                    onClick={(e) =>
                                      toggleForm(
                                        formState,
                                        history,
                                        location,
                                        dispatch,
                                        formNames.addOACForm,
                                        {
                                          membershipId: i.membershipId,
                                        }
                                      )
                                    }
                                  >
                                    view
                                  </FieldValueMobile>
                                </div>
                              </>
                            ) : null}
                          </ZoneLogsUserRowMobileView>
                          <div className="row my-2 mt-2">
                            <div className="col-12 mb-2">
                              <FieldLabelMobile>Email</FieldLabelMobile>
                              <FieldValueMobile>{nullChecker(i.userInfo.email.id)}</FieldValueMobile>
                            </div>
                          </div>
                          <div className="row my-2">
                            <div className="col-6">
                              <ButtonMobile
                                // type={i.endTime && i.endTime < Date.now() / 1000 ? "disabled" : "info"}
                                type={"info"}
                                className="w-100"
                                onClick={(e) =>
                                  toggleForm(
                                    formState,
                                    history,
                                    location,
                                    dispatch,
                                    formNames.membershipAccessInfoUpdateForm,

                                    {
                                      membershipId: i.membershipId,
                                      subDepId: i.subDepId,
                                      nodeId: selectedNode.nodeId,
                                      depId: i.depId,
                                      UDId: i.UDId,
                                      startTime: i.startTime,
                                      endTime: i.endTime,
                                      // accessGroups: i.accessGroups,
                                      designation: i.designation,
                                      displaySearch: displaySearch,
                                      displayValues: displayValues,
                                      index: index,
                                      setDisplayValues: setDisplayValues,
                                      roles: i.roles,
                                    }
                                  )
                                }
                              >
                                Edit
                              </ButtonMobile>
                            </div>
                            <div className="col-6">
                              {i.endTime && i.endTime < Date.now() / 1000 ? (
                                <p
                                  className="text-danger m-0 p-0 text-center mt-2 font-weight-bold"
                                  style={{ fontSize: "1rem" }}
                                >
                                  Expired
                                </p>
                              ) : (
                                <ButtonMobile
                                  className="w-100"
                                  onClick={(e) =>
                                    removeMembershipAPI(
                                      user,
                                      dispatch,
                                      NotificationManager,
                                      removeCookie,
                                      history,
                                      nullChecker(i.userInfo.mobile.number),
                                      i.membershipId,
                                      selectedNode,
                                      utils,
                                      confirmAlert
                                    )
                                  }
                                >
                                  Remove
                                </ButtonMobile>
                              )}
                            </div>
                          </div>
                        </ZoneLogsUserRowMobileWrapper>
                      </div>
                    );
                  })
              ) : (
                displayValues.map((i, index) => {
                  return (
                    <div className="col-sm-12 col-xs-12 p-0 col-md-6 px-md-2" key={i.membershipId}>
                      <ZoneLogsUserRowMobileWrapper>
                        <ZoneLogsUserRowMobileView>
                          <div>
                            <FieldLabelMobile>ID</FieldLabelMobile>
                            <FieldValueMobile>{nullChecker(i.userInfo.customUserId)}</FieldValueMobile>
                          </div>
                          <div>
                            <FieldLabelMobile>Name</FieldLabelMobile>
                            <div className="d-flex justify-content-between">
                              <FieldValueMobile>
                                {nullChecker(i.userInfo.firstName) +
                                  " " +
                                  nullChecker(i.userInfo.lastName)}
                              </FieldValueMobile>
                              <BiEdit
                                style={{ fontSize: "1rem" }}
                                className="cursor-pointer"
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Edit Name"
                                onClick={(e) =>
                                  toggleForm(
                                    formState,
                                    history,
                                    location,
                                    dispatch,
                                    formNames.editNameForm,
                                    {
                                      userId: i.userId,
                                      firstName: i.userInfo.firstName,
                                      lastName: i.userInfo.lastName,
                                      membershipId: i.membershipId,
                                      isIDCardAvailable: isIDCardAvailable,
                                    }
                                  )
                                }
                              />
                            </div>
                          </div>
                          <div>
                            <FieldLabelMobile>Phone</FieldLabelMobile>
                            <FieldValueMobile>{nullChecker(i.userInfo.mobile.number)}</FieldValueMobile>
                          </div>
                          <div>
                            <FieldLabelMobile>User ID</FieldLabelMobile>
                            <FieldValueMobile>{nullChecker(i.UDId)}</FieldValueMobile>
                          </div>

                          <div>
                            <FieldLabelMobile>Join Date</FieldLabelMobile>
                            <FieldValueMobile>{convetToLocaleDateString(i.startTime)}</FieldValueMobile>
                          </div>
                          <div>
                            <FieldLabelMobile>End Date</FieldLabelMobile>
                            <FieldValueMobile>{convetToLocaleDateString(i.endTime)}</FieldValueMobile>
                          </div>
                          {i?.RFCards && i?.RFCards.length > 0 ? (
                            <>
                              <div>
                                <FieldLabelMobile>RFID</FieldLabelMobile>
                                <FieldValueMobile
                                  onClick={(e) =>
                                    toggleForm(
                                      formState,
                                      history,
                                      location,
                                      dispatch,
                                      formNames.rfidAddForm,
                                      {
                                        ...i,
                                      }
                                    )
                                  }
                                  style={{ cursor: "pointer" }}
                                >
                                  {showRfid ? (
                                    <>
                                      {i.RFCards.map((item) => {
                                        return (
                                          <span>
                                            {item.customRFId}
                                            <br />
                                          </span>
                                        );
                                      })}
                                    </>
                                  ) : (
                                    "view"
                                  )}
                                </FieldValueMobile>
                              </div>
                            </>
                          ) : (
                            <>
                              <div>
                                <FieldLabelMobile>RFID</FieldLabelMobile>
                                <FieldValueMobile>
                                  <p
                                    style={{
                                      color: "blue",
                                      fontSize: "0.8rem",
                                      fontWeight: "bold",
                                      margin: "0px 0px",
                                      cursor: "pointer",
                                    }}
                                    onClick={(e) =>
                                      toggleForm(
                                        formState,
                                        history,
                                        location,
                                        dispatch,
                                        formNames.rfidAddForm,
                                        {
                                          ...i,
                                        }
                                      )
                                    }
                                  >
                                    + Add
                                  </p>
                                </FieldValueMobile>
                              </div>
                            </>
                          )}
                          <div>
                            <FieldLabelMobile>Role</FieldLabelMobile>
                            <FieldValueMobile>
                              {i.roles.filter((v) => v.nodeId === selectedNode.nodeId)[0]
                                ? i.roles.filter((v) => v.nodeId === selectedNode.nodeId)[0].role
                                : "-"}
                            </FieldValueMobile>
                          </div>
                          {enableOAC ? (
                            <>
                              <div>
                                <FieldLabelMobile>Keycodes</FieldLabelMobile>
                                <FieldValueMobile
                                  onClick={(e) =>
                                    toggleForm(
                                      formState,
                                      history,
                                      location,
                                      dispatch,
                                      formNames.addOACForm,
                                      {
                                        membershipId: i.membershipId,
                                      }
                                    )
                                  }
                                >
                                  view
                                </FieldValueMobile>
                              </div>
                            </>
                          ) : null}
                        </ZoneLogsUserRowMobileView>
                        <div className="row my-2 mt-2">
                          <div className="col-12 mb-2">
                            <FieldLabelMobile>Email</FieldLabelMobile>
                            <FieldValueMobile>{nullChecker(i.userInfo.email.id)}</FieldValueMobile>
                          </div>
                        </div>
                        <div className="row my-2">
                          <div className="col-6">
                            <ButtonMobile
                              // type={i.endTime && i.endTime < Date.now() / 1000 ? "disabled" : "info"}
                              type={"info"}
                              className="w-100"
                              onClick={(e) =>
                                toggleForm(
                                  formState,
                                  history,
                                  location,
                                  dispatch,
                                  formNames.membershipAccessInfoUpdateForm,

                                  {
                                    membershipId: i.membershipId,
                                    subDepId: i.subDepId,
                                    nodeId: selectedNode.nodeId,
                                    depId: i.depId,
                                    UDId: i.UDId,
                                    startTime: i.startTime,
                                    endTime: i.endTime,
                                    // accessGroups: i.accessGroups,
                                    designation: i.designation,
                                    displaySearch: displaySearch,
                                    displayValues: displayValues,
                                    index: index,
                                    setDisplayValues: setDisplayValues,
                                    roles: i.roles,
                                  }
                                )
                              }
                            >
                              Edit
                            </ButtonMobile>
                          </div>
                          <div className="col-6">
                            {i.endTime && i.endTime < Date.now() / 1000 ? (
                              <p
                                className="text-danger m-0 p-0 text-center mt-2 font-weight-bold"
                                style={{ fontSize: "1rem" }}
                              >
                                Expired
                              </p>
                            ) : (
                              <ButtonMobile
                                className="w-100"
                                onClick={(e) =>
                                  removeMembershipAPI(
                                    user,
                                    dispatch,
                                    NotificationManager,
                                    removeCookie,
                                    history,
                                    nullChecker(i.userInfo.mobile.number),
                                    i.membershipId,
                                    selectedNode,
                                    utils,
                                    confirmAlert
                                  )
                                }
                              >
                                Remove
                              </ButtonMobile>
                            )}
                          </div>
                        </div>
                      </ZoneLogsUserRowMobileWrapper>
                    </div>
                  );
                })
              )
            ) : (
              <PlaceholderMessage message="No data to display" />
            )}
          </div>
        </>
      )}
      <div>
        <PageButtonContainer>
          {pageNo > 3 ? (
            <PageButton
              onClick={(e) => {
                if (pageNo - 5 > 0) {
                  setPageNo(pageNo - 5);
                } else {
                  setPageNo(1);
                }
              }}
            >
              <FaAngleLeft />
            </PageButton>
          ) : null}
          {pageNo - 2 > 0 ? (
            <PageButton
              onClick={(e) => {
                setPageNo(pageNo - 2);
              }}
            >
              {pageNo - 2}
            </PageButton>
          ) : null}
          {pageNo - 1 > 0 ? (
            <PageButton
              onClick={(e) => {
                setPageNo(pageNo - 1);
              }}
            >
              {pageNo - 1}
            </PageButton>
          ) : null}
          {noOfPages > 1 ? <PageButton selected={true}>{pageNo}</PageButton> : null}
          {noOfPages >= pageNo + 1 ? (
            <PageButton
              onClick={(e) => {
                setPageNo(pageNo + 1);
              }}
            >
              {pageNo + 1}
            </PageButton>
          ) : null}
          {noOfPages >= pageNo + 2 ? (
            <PageButton
              onClick={(e) => {
                setPageNo(pageNo + 2);
              }}
            >
              {pageNo + 2}
            </PageButton>
          ) : null}
          {pageNo + 2 < noOfPages ? (
            <PageButton
              onClick={(e) => {
                if (pageNo + 5 <= noOfPages) {
                  setPageNo(pageNo + 5);
                } else {
                  setPageNo(noOfPages);
                }
              }}
            >
              <FaAngleRight />
            </PageButton>
          ) : null}
        </PageButtonContainer>
      </div>
    </>
  );
};

export default PeopleRow;
