import { UPDATE_USER, DELETE_USER } from "../actionTypes";
import TagManager from "react-gtm-module";
export const updateUser = (payload) => {
  return {
    type: UPDATE_USER,
    payload: payload.mobileNo ? { ...payload, mobileNo: `${payload.mobileNo}` } : payload,
  };
};

export const deleteUser = () => {
  return {
    type: DELETE_USER,
  };
};
