import { UPDATE_ADD_ROOTNODE } from "../actionTypes";

const addRootNodeReducer = (addRootNode = false, action) => {
  if (action.type === UPDATE_ADD_ROOTNODE) {
    return (addRootNode = action.payload);
  }

  return addRootNode;
};

export default addRootNodeReducer;
