import React from "react";
import { HiOutlineDocumentDownload } from "react-icons/hi";
import { useState } from "react";
import { getAllMemberships } from "../utils/requests";
import { useCookies } from "react-cookie";
import NotificationManager from "react-notifications/lib/NotificationManager";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { downloadCSV } from "../utils/csv";
import { displayTimeMinutes, nullChecker } from "../utils/utilFunctions";
import { FieldLabelMobile } from "./zone/ZoneLogsUserRow/zoneLogsUserRow.styled";

const BulkMembershipCSVdownload = () => {
  const [cookies, setCookie, removeCookie] = useCookies(["AT"]);
  const user = useSelector((state) => state.user);
  const nodeInfo = useSelector((state) => state.nodeInfo);
  const utils = useSelector((state) => state.utils);
  let history = useHistory();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const [allMemberships, setAllMemberships] = useState(null);
  const downloadData = async () => {
    if (!loading) {
      setLoading(true);
      try {
        const { memberships } = await getAllMemberships(
          user,
          dispatch,
          NotificationManager,
          removeCookie,
          history,
          nodeInfo.nodeId,
          setAllMemberships,
          utils.includeChildMemberships,
          utils.includeExpired
        );
        const data = memberships.map((m) => {
          return {
            Name: `${m.userInfo.firstName} ${m.userInfo.lastName}`,
            Phone: m.userInfo.mobile.number,
            Email: m.userInfo.email.id,
            UDID: m.UDId,
            Roles: m.roles.length ? m.roles.map((r) => r.role).join(", ") : "-",
            "Start Time": m.startTime ? displayTimeMinutes(m.startTime * 1000) : "-",
            "End Time": m.endTime ? displayTimeMinutes(m.endTime * 1000) : "-",
            RFID: m.RFCards.length ? m.RFCards.map((rf) => rf.customRFId).join(", ") : "-",
            Designation: nullChecker(m.designation),
            Department: nullChecker(m.depName),
            "Sub Department": nullChecker(m.subDepName),
          };
        });
        downloadCSV(data, null, "Memberships");
        setLoading(false);
        // return data;
      } catch (error) {
        setLoading(false);
      }
    }
  };

  return loading ? (
    "Loading csv..."
  ) : (
    <div onClick={downloadData} style={{ cursor: "pointer" }} className="d-flex">
      <FieldLabelMobile className="mx-2">Download CSV</FieldLabelMobile>
      <HiOutlineDocumentDownload style={{ fontSize: "1.2rem" }} />
    </div>
  );
};

export default BulkMembershipCSVdownload;
