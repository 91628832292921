import React, { useState } from "react";
import {
  ZoneStatusCardContainer,
  Entrance,
  EntranceStatusBadge,
  Device,
  DeviceId,
  Floor,
} from "./zoneStatusCard.styled";
import { TiDeleteOutline } from "react-icons/ti";
import { SolidButton } from "../../../buttons/solidButton.styled";
import { BiEdit } from "react-icons/bi";
import { IconContainer, OverFLowScrollBox } from "../../../App.styled";
import DoorAddForm from "../../../forms/doorAddForm/DoorAddForm";
import DeviceAddForm from "../../../forms/deviceAddForm/DeviceAddForm";
import { useDispatch, useSelector } from "react-redux";

import { useHistory, useLocation } from "react-router-dom";
import { useCookies } from "react-cookie";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import cs
import NotificationManager from "react-notifications/lib/NotificationManager";
import { deleteDoorAPI } from "../../../../utils/requests";
import { toggleForm } from "../../../../utils/utilFunctions";
import { formNames, SUPER_ADMIN_LIST } from "../../../../config";
const ZoneStatusCard = () => {
  const [cookies, setCookie, removeCookie] = useCookies(["AT"]);
  let history = useHistory();
  const location = useLocation();
  const [showAddDoorForm, setShowAddDoorForm] = useState(false);
  const [showAddDeviceForm, setShowAddDeviceForm] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const door = useSelector((state) => state.nodeInfo.door);
  const user = useSelector((state) => state.user);
  const formState = useSelector((state) => state.formState);
  const selectedNode = useSelector((state) => state.selectedNode);
  const doorAddClick = () => {
    setShowAddDoorForm(true);
  };

  const dispatch = useDispatch();

  const [deviceAddData, setDeviceAddData] = useState(null);
  const deviceAddClick = (mode, doorId, nodeId) => {
    setShowAddDeviceForm(true);
    setDeviceAddData({ mode, doorId, nodeId });
  };

  return (
    <ZoneStatusCardContainer>
      {showAddDoorForm ? <DoorAddForm setShowAddDoorForm={setShowAddDoorForm} /> : null}
      {showAddDeviceForm ? (
        <DeviceAddForm setShowAddDeviceForm={setShowAddDeviceForm} deviceAddData={deviceAddData} />
      ) : null}

      <Floor>{selectedNode ? selectedNode.name : ""}</Floor>
      <div style={{ height: "50vh" }}>
        <OverFLowScrollBox>
          <div className="mr-2">
            {door
              ? door.map((d) => (
                  <div key={d.doorId}>
                    <div className="d-flex align-items-center justify-content-between">
                      <Entrance>{d.name}</Entrance>
                      {editMode ? (
                        <div className="d-flex align-items-center">
                          <TiDeleteOutline
                            className="m-0 p-0 text-danger cursor-pointer mr-2"
                            style={{ fontSize: "1.2rem" }}
                            onClick={(e) =>
                              deleteDoorAPI(
                                user,
                                dispatch,
                                NotificationManager,
                                removeCookie,
                                history,
                                confirmAlert,
                                d.doorId,
                                selectedNode
                              )
                            }
                          />
                          <IconContainer>
                            <BiEdit
                              className="m-0 p-0"
                              style={{ fontSize: "1.2rem" }}
                              onClick={(e) =>
                                toggleForm(
                                  formState,
                                  history,
                                  location,
                                  dispatch,
                                  formNames.doorInfoUpdateForm,
                                  d
                                )
                              }
                            />
                          </IconContainer>
                        </div>
                      ) : null}
                    </div>
                    <div className="d-flex align-items-center justify-content-between">
                      <Device>Entry Device</Device>
                      <DeviceId>
                        {d.inAccessDevice ? (
                          <p
                            className="cursor-pointer"
                            onClick={(e) =>
                              d.inAccessDevice.type === "SPAC"
                                ? toggleForm(
                                    formState,
                                    history,
                                    location,
                                    dispatch,
                                    formNames.deviceConfigForm,
                                    {
                                      deviceId: d.inAccessDevice.deviceId,
                                      customDeviceId: d.inAccessDevice.customDeviceId,
                                    }
                                  )
                                : null
                            }
                          >
                            {d.inAccessDevice.customDeviceId}
                          </p>
                        ) : (
                          <SolidButton onClick={(e) => deviceAddClick("IN", d.doorId, d.nodeId)}>
                            Add
                          </SolidButton>
                        )}
                      </DeviceId>
                    </div>
                    <div className="d-flex align-items-center justify-content-between">
                      <Device>Exit Device</Device>
                      <DeviceId>
                        {d.outAccessDevice ? (
                          <p
                            className="cursor-pointer"
                            onClick={(e) =>
                              d.outAccessDevice.type === "SPAC"
                                ? toggleForm(
                                    formState,
                                    history,
                                    location,
                                    dispatch,
                                    formNames.deviceConfigForm,
                                    {
                                      deviceId: d.outAccessDevice.deviceId,
                                      customDeviceId: d.outAccessDevice.customDeviceId,
                                    }
                                  )
                                : null
                            }
                          >
                            {d.outAccessDevice.customDeviceId}
                          </p>
                        ) : (
                          <SolidButton onClick={(e) => deviceAddClick("OUT", d.doorId, d.nodeId)}>
                            Add
                          </SolidButton>
                        )}
                      </DeviceId>
                    </div>
                    <div>
                      <div className="d-flex align-items-center justify-content-between">
                        <Device style={{ color: "black", fontWeight: "bold" }}>Other Devices</Device>
                        <SolidButton onClick={(e) => deviceAddClick(null, d.doorId, d.nodeId)}>
                          Add
                        </SolidButton>
                      </div>
                    </div>
                    <div className="px-3">
                      {d.otherDevices &&
                        d.otherDevices.map((od) => {
                          return od ? (
                            <div className="d-flex align-items-center justify-content-between">
                              <Device>{od.type}</Device>
                              <DeviceId>{od.customDeviceId}</DeviceId>
                            </div>
                          ) : null;
                        })}
                    </div>
                  </div>
                ))
              : null}
          </div>
        </OverFLowScrollBox>
      </div>
      {user && user.mobileNo && SUPER_ADMIN_LIST.includes(user.mobileNo) ? (
        <div className="d-flex align-items-center justify-content-end mt-2">
          <SolidButton onClick={doorAddClick}>Add Door</SolidButton>
          <IconContainer>
            <BiEdit className="m-0 p-0" onClick={(e) => setEditMode(!editMode)} />
          </IconContainer>
        </div>
      ) : null}
    </ZoneStatusCardContainer>
  );
};

export default ZoneStatusCard;
