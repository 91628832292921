import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { apiEndPoints, formNames } from "../../../config";
import { SolidButton } from "../../buttons/solidButton.styled";
import { FormWrapper } from "../doorAddForm/DoorAddForm";
import { useCookies } from "react-cookie";
import { toggleForm } from "../../../utils/utilFunctions";
import { FieldInput, FieldLabel, InputBox } from "../FormBase.styled";
import { InputGroup } from "../FormBase.styled";
import { useHistory, useLocation } from "react-router-dom";
import NotificationManager from "react-notifications/lib/NotificationManager";
import {
  generateUserCredentials,
  getUserCredintials,
  registerOauthApp,
  updateOauthApp,
} from "../../../utils/requests";
import ButtonState from "../../ButtonState/ButtonState";

const CreateUserCredentialForm = () => {
  const [cookies, setCookie, removeCookie] = useCookies(["AT"]);
  let history = useHistory();
  const dispatch = useDispatch();
  const dimentions = useSelector((state) => state.dimentions);
  const formState = useSelector((state) => state.formState);
  const location = useLocation();
  const user = useSelector((state) => state.user);
  const [credentials, setCredentials] = useState(null);
  const nodeInfo = useSelector((state) => state.nodeInfo);
  const [nodeId, setNodeId] = useState(nodeInfo.nodeId);
  const nodes = useSelector((state) => state.nodes);
  const apiStatus = useSelector((state) => state.apiStatus);
  const [error, setError] = useState({ accessError: false });
  useEffect(() => {
    if (nodeId)
      getUserCredintials(
        user,
        dispatch,
        NotificationManager,
        removeCookie,
        history,
        nodeId,
        setCredentials
      );
  }, [nodeId]);

  const closeForm = () => {
    toggleForm(formState, history, location, dispatch, formNames.createUserCredentialsForm);
  };

  return (
    <FormWrapper dimentions={dimentions} closeForm={closeForm}>
      <h6 className="font-weight-bold mb-4">Self Use credentials</h6>
      <InputGroup dimentions={dimentions}>
        <FieldLabel dimentions={dimentions}>Node</FieldLabel>
        <FieldInput>
          <select
            className="vms-select"
            id="AccessArea"
            onChange={(e) => {
              if (e.target.value === "Select a node") {
                setNodeId(null);
                setError({ ...error, accessError: true });
              } else {
                setError({ ...error, accessError: false });
                console.log(e.target.value);
                setNodeId(e.target.value);
              }
            }}
          >
            <option value={null}>{"Select a node"}</option>
            {nodes.map((n) => (
              <option selected={nodeId === n.nodeId} value={n.nodeId}>
                {n.name}
              </option>
            ))}
          </select>
        </FieldInput>
      </InputGroup>

      {nodeId ? (
        credentials ? (
          <>
            {credentials?.message === "No keys found" ? null : (
              <>
                <InputGroup dimentions={dimentions}>
                  <FieldLabel dimentions={dimentions}>Access Token</FieldLabel>
                  <FieldInput>
                    <InputBox type="text" value={credentials?.accessToken} disabled={true} />
                  </FieldInput>
                </InputGroup>
                <InputGroup dimentions={dimentions}>
                  <FieldLabel dimentions={dimentions}>Refresh Token</FieldLabel>
                  <FieldInput>
                    <InputBox type="text" value={credentials?.refreshToken} disabled={true} />
                  </FieldInput>
                </InputGroup>
              </>
            )}
            {/* <InputGroup dimentions={dimentions}>
              <FieldLabel dimentions={dimentions}></FieldLabel>
              <FieldInput>
                <div className="text-danger font-weight-bold">
                  {credentials?.message
                    ? credentials.message
                    : "Please save the Client ID and Client Secret for future use"}
                </div>
              </FieldInput>
            </InputGroup> */}
          </>
        ) : (
          <div className="text-center">
            <div className="spinner-border" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        )
      ) : (
        <InputGroup dimentions={dimentions}>
          <FieldLabel dimentions={dimentions}></FieldLabel>
          <FieldInput>
            <div className="col-12">
              <h6 className="font-weight-bold text-danger" id="accessError">
                Please select a Node.
              </h6>
            </div>
          </FieldInput>
        </InputGroup>
      )}
      <InputGroup dimentions={dimentions}>
        <FieldLabel dimentions={dimentions}></FieldLabel>
        <FieldInput submitButton={true}>
          <SolidButton
            submit={true}
            onClick={(e) => {
              generateUserCredentials(
                user,
                dispatch,
                NotificationManager,
                removeCookie,
                history,
                nodeId,
                setCredentials
              );
            }}
          >
            <ButtonState
              loading={apiStatus[apiEndPoints.generateUserCredentials]}
              innerText={credentials?.message === "No keys found" ? "Generate" : "Refresh"}
            />
          </SolidButton>
        </FieldInput>
      </InputGroup>
    </FormWrapper>
  );
};

export default CreateUserCredentialForm;
