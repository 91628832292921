import { detectDeviceType } from "./utilFunctions";

export const isPlatformAllowed = (nodeInfo) => {
  console.log(nodeInfo);
  if (nodeInfo && nodeInfo.features) {
    let allowed = true;
    const { allowMobileView, allowDesktopView } = nodeInfo.features;
    const platform = detectDeviceType();

    allowed =
      platform === "Mobile" && !allowMobileView
        ? false
        : platform === "Desktop" && !allowDesktopView
        ? false
        : true;
    return allowed;
  } else {
    return false;
  }
};
