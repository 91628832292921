import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import NotificationManager from "react-notifications/lib/NotificationManager";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { apiEndPoints, formNames } from "../../../config";
import { addDeviceFirmwareAPI, updateDeviceFirmwareAPI } from "../../../utils/requests";
import { toggleForm } from "../../../utils/utilFunctions";
import { SolidButton } from "../../buttons/solidButton.styled";
import ButtonState from "../../ButtonState/ButtonState";
import { FormWrapper } from "../doorAddForm/DoorAddForm";
import { FieldInput, FieldLabel, InputBox, InputGroup } from "../FormBase.styled";

const DeviceFirmwareByDFIForm = ({ edit }) => {
  const dimentions = useSelector((state) => state.dimentions);
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const initialData = useSelector((state) => state.formState[formNames.editDeviceFirmwareByDFIForm]);
  const [cookies, setCookie, removeCookie] = useCookies(["AT"]);
  const history = useHistory();
  const formState = useSelector((state) => state.formState);
  const apiStatus = useSelector((state) => state.apiStatus);
  const location = useLocation();

  useEffect(() => {
    if (edit && initialData) {
      setFormData({ ...initialData });
    }
  }, [edit, initialData]);

  const [formData, setFormData] = useState({
    DFI: "",
    updateDFITo: "",
    appUrl: "",
    bootloaderUrl: "",
  });

  const closeForm = () => {
    {
      edit
        ? toggleForm(formState, history, location, dispatch, formNames.editDeviceFirmwareByDFIForm)
        : toggleForm(formState, history, location, dispatch, formNames.addDeviceFirmwareByDFIForm);
    }
  };

  const handleSubmit = async () => {
    if (edit) {
      await updateDeviceFirmwareAPI(
        user,
        dispatch,
        NotificationManager,
        removeCookie,
        history,
        formData,
        closeForm
      );
    } else {
      await addDeviceFirmwareAPI(
        user,
        dispatch,
        NotificationManager,
        removeCookie,
        history,
        formData,
        closeForm
      );
    }
  };

  return (
    <FormWrapper dimentions={dimentions} closeForm={closeForm}>
      <h6 className="font-weight-bold mb-4">Device Firmware {edit ? "Update" : "Add"}</h6>
      <InputGroup dimentions={dimentions}>
        <FieldLabel dimentions={dimentions}>DFI</FieldLabel>
        <FieldInput>
          <InputBox
            type="text"
            value={formData.DFI}
            onChange={(e) => setFormData({ ...formData, DFI: e.target.value })}
          />
        </FieldInput>
      </InputGroup>
      <InputGroup dimentions={dimentions}>
        <FieldLabel dimentions={dimentions}>Update DFI To</FieldLabel>
        <FieldInput>
          <InputBox
            type="text"
            value={formData.updateDFITo}
            onChange={(e) => setFormData({ ...formData, updateDFITo: e.target.value })}
          />
        </FieldInput>
      </InputGroup>
      <InputGroup dimentions={dimentions}>
        <FieldLabel dimentions={dimentions}>APP Url</FieldLabel>
        <FieldInput>
          <InputBox
            type="text"
            value={formData.appUrl}
            onChange={(e) => setFormData({ ...formData, appUrl: e.target.value })}
          />
        </FieldInput>
      </InputGroup>
      <InputGroup dimentions={dimentions}>
        <FieldLabel dimentions={dimentions}>Bootloader Url</FieldLabel>
        <FieldInput>
          <InputBox
            type="text"
            value={formData.bootloaderUrl}
            onChange={(e) => setFormData({ ...formData, bootloaderUrl: e.target.value })}
          />
        </FieldInput>
      </InputGroup>
      <InputGroup dimentions={dimentions}>
        <FieldLabel dimentions={dimentions}></FieldLabel>
        <FieldInput submitButton={true}>
          <SolidButton submit={true} onClick={handleSubmit}>
            <ButtonState
              loading={
                apiStatus[apiEndPoints.updateDeviceFirmwareAPI] ||
                apiStatus[apiEndPoints.addDeviceFirmwareAPI]
              }
              innerText={edit ? "Update" : "Add"}
            />
          </SolidButton>
        </FieldInput>
      </InputGroup>
    </FormWrapper>
  );
};

export default DeviceFirmwareByDFIForm;
