import React, { useEffect } from "react";
import { Redirect, Route, useLocation } from "react-router-dom";
import Header from "./Components/Header";
import Navigation from "./Components/Navigation";
import Placeholder from "./Components/Placeholder";
import Declaration from "./Pages/Declaration";
import Home from "./Pages/Home";
import Qr from "./Pages/Qr";
import Invite from "./Pages/Invite";

import { SuccessWindow } from "./Components/SuccessWindow";
import { useDispatch, useSelector } from "react-redux";
import { parseQueryParams } from "../utils/utilFunctions";
import { updateSelectedNode } from "../redux/selectedNode/selectedNodeActions";
function VMS() {
  const user = useSelector((state) => state.user);
  const selectedNode = useSelector((state) => state.selectedNode);
  const nodes = useSelector((state) => state.nodes);
  const dispatch = useDispatch();
  const location = useLocation();
  useEffect(() => {
    if (nodes && nodes.length && location.search.length && parseQueryParams(location.search).nodeId) {
      const node = nodes.filter((n) => n.nodeId === parseQueryParams(location.search).nodeId);

      if (node.length) {
        dispatch(updateSelectedNode(node[0]));
      }
      console.log({
        selectednodeid: selectedNode.nodeid,
        querynodeid: parseQueryParams(location.search).nodeId,
        node: node[0],
        nodes,
      });
    }
  }, [nodes, location.search]);

  return (
    <div className="vmsApp text-center pt-5 mt-5">
      {/* <Header /> */}

      <div id="react-content ">
        <Route exact path="/vms">
          {user &&
          selectedNode &&
          selectedNode.nodeId &&
          user.visitorPermissions[selectedNode.nodeId] ? (
            <Home />
          ) : location.search.length && parseQueryParams(location.search).nodeId ? null : (
            <>
              <Redirect to={"/apps"} />
            </>
          )}
        </Route>
        <Route exact path="/vms/add/success">
          <SuccessWindow />
        </Route>
        <Route exact path="/vms/qr">
          <Qr />
        </Route>
        <Route exact path="/vms/declaration">
          <Declaration />
        </Route>
        <Route exact path="/vms/invite">
          {user ? <Invite /> : <Redirect to="/apps" />}
        </Route>
      </div>
    </div>
  );
}
export default VMS;
