import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import NotificationManager from "react-notifications/lib/NotificationManager";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import AttendanceLogs from "../components/attendance/attendanceLogs/AttendanceLogs";
import Stats from "../components/attendance/cards/stats/Stats";
import { PageButtonContainer, PageButton } from "../paging.styled";
import Timings from "../components/attendance/cards/timings/Timings";
import PlaceholderMessage from "../components/PlaceholderMessage";
import { realJsDayMap, rootApi, yearMap } from "../config";
import { get } from "../utils/apiService";
import { ATexpired } from "../utils/logout";
import { convertTime, getUserRole, isAdmin } from "../utils/utilFunctions";
import { FaAngleDoubleLeft, FaAngleDoubleRight, FaAngleLeft, FaAngleRight } from "react-icons/fa";
import { createNotification, notificationType } from "../vms/Services/utils";
import { sendCustomPageView } from "../utils/tagManagerEvents";

const Attendance = () => {
  const [cookies, setCookie, removeCookie] = useCookies(["AT"]);
  const [date, setDate] = useState(Date.now());
  const selectedNode = useSelector((state) => state.selectedNode);
  const selectedNodeHierarchy = useSelector((state) => state.selectedNodeHierarchy);
  const nodeInfo = useSelector((state) => state.nodeInfo);
  const [zoneLogs, setZoneLogs] = useState(null);
  const user = useSelector((state) => state.user);
  const history = useHistory();
  const dispatch = useDispatch();
  // const memberships = useSelector((state) => state.memberships);
  const [selfLogs, setSelfLogs] = useState(isAdmin(user, nodeInfo) ? false : true);
  const [activeMembershipsCount, setActiveMembershipsCount] = useState(0);
  const [presentCount, setPresentCount] = useState(0);
  const [department, setDepartment] = useState("");
  const [pageNo, setPageNo] = useState(1);
  const [noOfPages, setNoOfPages] = useState(1);
  const [subDepartment, setSubDepartment] = useState("");
  const [includeChild, setIncludeChild] = useState(false);
  useEffect(() => {
    sendCustomPageView(
      user.userId,
      nodeInfo.name,
      nodeInfo.nodeCategory,
      getUserRole(user.roles, nodeInfo)
    );
  }, [nodeInfo, user]);
  const getZoneLogsAPI = async (d) => {
    const url = `${rootApi}node/accessLogs?nodeId=${selectedNode.nodeId}&startTime=${parseInt(
      new Date(d).setHours(0, 0, 0, 0)
    )}&endTime=${parseInt(new Date(d).setHours(23, 59, 59, 99))}${
      department.length ? `&depId=${department}` : ``
    }${
      subDepartment.length && department.length ? `&subDepId=${subDepartment}` : ``
    }&includeChildLogs=${includeChild}${selfLogs ? `&sendSelfLogs=true` : ``}&pageNo=${pageNo}`;
    try {
      const response = await get(dispatch, url, user.AT);
      if (!response.data.success) {
        createNotification(NotificationManager, notificationType.error, response.data.error);
      } else {
        setZoneLogs([...response.data.accessLogs]);
        setNoOfPages(response.data.noOfPages);
      }
      ATexpired(response, dispatch, removeCookie, history);
    } catch (err) {
      throw err;
    }
  };

  useEffect(() => {
    setActiveMembershipsCount(nodeInfo.peopleWithAccess);
  }, [nodeInfo]);

  // useEffect(() => {
  //   if (
  //     selectedNodeHierarchy &&
  //     selectedNodeHierarchy.length &&
  //     selectedNodeHierarchy[0].children &&
  //     selectedNodeHierarchy[0].children.length
  //   ) {
  //     setIncludeChild(true);
  //   }
  // }, [selectedNodeHierarchy]);

  useEffect(() => {
    if (zoneLogs && zoneLogs.length) {
      let uIds = [];
      zoneLogs.forEach((i) => {
        if (i.userId) {
          uIds.push(i.userId);
        }
      });
      setPresentCount([...new Set(uIds)].length);
    } else {
      setPresentCount(0);
    }
  }, [zoneLogs]);

  useEffect(() => {
    if (selectedNode.nodeId && date) {
      getZoneLogsAPI(date);
    }
  }, [selectedNode.nodeId, date, department, subDepartment, includeChild, pageNo, selfLogs]);

  useEffect(() => {
    if (selectedNode.nodeId && date) {
      setPageNo(1);
    }
  }, [selectedNode.nodeId, date, department, subDepartment, includeChild]);
  return (
    <div>
      <h5 className="font-weight-bold">Attendance</h5>
      {selectedNode.nodeId ? (
        <>
          {/* <h6 className="font-weight-bold mt-5">Today</h6>
          <p className="mb-3" style={{ fontSize: "1rem" }}>{`${
            realJsDayMap[new Date().getDay()]
          }, ${new Date(Date.now()).getDate()} ${
            yearMap[new Date(Date.now()).getMonth()]
          } ${new Date(Date.now()).getFullYear()}`}</p> */}
          <div className="row">
            <div className="d-flex col-12">{isAdmin(user, nodeInfo) ? <Timings /> : null}</div>
            {/* <div className="d-flex col-12">
              <Stats
                activeMembershipsCount={activeMembershipsCount}
                presentCount={presentCount}
              />
            </div> */}

            <AttendanceLogs
              setActiveMembershipsCount={setActiveMembershipsCount}
              setIncludeChild={setIncludeChild}
              setSelfLogs={setSelfLogs}
              selfLogs={selfLogs}
              includeChild={includeChild}
              date={date}
              setDate={setDate}
              zoneLogs={zoneLogs}
              department={department}
              setDepartment={setDepartment}
              subDepartment={subDepartment}
              setSubDepartment={setSubDepartment}
            />
          </div>
        </>
      ) : (
        <PlaceholderMessage />
      )}
      <div>
        <PageButtonContainer>
          {pageNo > 3 ? (
            <PageButton
              onClick={(e) => {
                if (pageNo - 5 > 0) {
                  setPageNo(pageNo - 5);
                } else {
                  setPageNo(1);
                }
              }}
            >
              <FaAngleLeft />
            </PageButton>
          ) : null}
          {pageNo - 2 > 0 ? (
            <PageButton
              onClick={(e) => {
                setPageNo(pageNo - 2);
              }}
            >
              {pageNo - 2}
            </PageButton>
          ) : null}
          {pageNo - 1 > 0 ? (
            <PageButton
              onClick={(e) => {
                setPageNo(pageNo - 1);
              }}
            >
              {pageNo - 1}
            </PageButton>
          ) : null}
          {noOfPages > 1 ? <PageButton selected={true}>{pageNo}</PageButton> : null}
          {noOfPages >= pageNo + 1 ? (
            <PageButton
              onClick={(e) => {
                setPageNo(pageNo + 1);
              }}
            >
              {pageNo + 1}
            </PageButton>
          ) : null}
          {noOfPages >= pageNo + 2 ? (
            <PageButton
              onClick={(e) => {
                setPageNo(pageNo + 2);
              }}
            >
              {pageNo + 2}
            </PageButton>
          ) : null}
          {pageNo + 2 < noOfPages ? (
            <PageButton
              onClick={(e) => {
                if (pageNo + 5 <= noOfPages) {
                  setPageNo(pageNo + 5);
                } else {
                  setPageNo(noOfPages);
                }
              }}
            >
              <FaAngleRight />
            </PageButton>
          ) : null}
        </PageButtonContainer>
      </div>
    </div>
  );
};

export default Attendance;
