import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import PlaceholderMessage from "../components/PlaceholderMessage";
import VisitorRow from "../components/visitor/visitorRow/VisitorRow";
import VisitorStat from "../components/visitor/visitorStats/VisitorStat";
import { sendCustomPageView } from "../utils/tagManagerEvents";
import { getUserRole } from "../utils/utilFunctions";

const Visitor = ({ removeCookie, history, dispatch, getVisitorsLogsAPI }) => {
  const selectedNode = useSelector((state) => state.selectedNode);
  const user = useSelector((state) => state.user);
  const nodeInfo = useSelector((state) => state.nodeInfo);
  useEffect(() => {
    sendCustomPageView(
      user.userId,
      nodeInfo.name,
      nodeInfo.nodeCategory,
      getUserRole(user.roles, nodeInfo)
    );
  }, [nodeInfo, user]);
  return (
    <div>
      <h5 className="font-weight-bold">Visitor</h5>
      {selectedNode.nodeId ? (
        <>
          <VisitorStat />
          <VisitorRow
            getVisitorsLogsAPI={getVisitorsLogsAPI}
            removeCookie={removeCookie}
            history={history}
            dispatch={dispatch}
          />
        </>
      ) : (
        <PlaceholderMessage />
      )}
    </div>
  );
};

export default Visitor;
