import React, { useState } from "react";
import { useCookies } from "react-cookie";
import NotificationManager from "react-notifications/lib/NotificationManager";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { get } from "../../../utils/apiService";
import styled, { css } from "styled-components";
import { rootApi } from "../../../config";
import { createNotification, notificationType } from "../../../vms/Services/utils";
import { ATexpired } from "../../../utils/logout";
import { displayDate } from "../../../utils/utilFunctions";

const NODESEARCHFORM = ({ user }) => {
  const [cookies, setCookie, removeCookie] = useCookies(["AT"]);
  const dispatch = useDispatch();
  const history = useHistory();
  const [searchQuery, setSearchQuery] = useState("");
  const UserRow = styled.div`
    display: grid;
    grid-row-gap: 0.5rem;
    grid-column-gap: 0.2rem;
    padding: 0.5rem 1rem;
    grid-template-columns: 8fr 4fr; /*  */
    grid-template-areas: name role;
    ${(props) =>
      props.isHead
        ? css`
            font-weight: bold;
          `
        : css`
            :hover {
              color: white;
              background-image: linear-gradient(91deg, #fe6dad -3%, #fc9f35);
            }
          `}
  `;

  const Name = styled.div`
    grid-area: "name";
    display: flex;
    text-align: left;
    justify-content: left;
    align-items: left;
  `;

  const Role = styled.div`
    grid-area: "role";
    display: flex;
    text-align: left;
    justify-content: left;
    align-items: left;
  `;
  const [response, setResponse] = useState(null);
  const [memberships, setMemberships] = useState(null);
  const selectedNode = useSelector((state) => state.selectedNode);
  const searchNodes = async (searchKey) => {
    try {
      let url = `${rootApi}node/search?searchKey=${searchKey}&nodeId=${selectedNode.nodeId}`;
      const response = await get(dispatch, url, user.AT);
      // console.log(response);
      if (!response.data.success) {
        createNotification(NotificationManager, notificationType.error, response.data.error);
      } else {
        // console.log(response);
        setResponse(response.data.searchResult);
      }
      ATexpired(response, dispatch, removeCookie, history);
    } catch (err) {
      console.log("Search Failed", err);
    }
  };

  return (
    <div className="py-5 px-2">
      <input
        className="form-control"
        placeholder="Type to search..."
        value={searchQuery}
        onChange={(e) => {
          if (e.target.value === "") {
            setResponse(null);
            setMemberships(null);
          }
          setSearchQuery(e.target.value);
        }}
        onKeyDown={(e) => {
          if (e.key === "Enter" || e.keyCode === 13) {
            searchNodes(searchQuery);
          }
        }}
      />
      {response && response.length ? (
        <select
          className="form-control"
          style={{ marginTop: "1rem" }}
          onChange={(e) => {
            setMemberships(response[e.target.value].memberships);
          }}
        >
          <option value="">Select Node</option>
          {response.map((x, i) => (
            <option value={i}>
              {x.name} ({x.originalName}) - {displayDate(x.timestamp * 1000)}
            </option>
          ))}
        </select>
      ) : null}
      {memberships && memberships.length ? (
        <div>
          <h5 className="font-weight-bold mt-2">Admins and Owners</h5>
          <UserRow className="font-weight-bold" isHead={true}>
            <Name>Name</Name>
            <Role>Role</Role>
          </UserRow>
          {memberships.map((x) => (
            <UserRow>
              <Name>
                {x.firstName} {x.lastName} ( {x.mobileNo && x.mobileVerified ? x.mobileNo : "-"} /{" "}
                {x.emailId && x.emailVerified ? x.emailId : "-"} )
              </Name>
              <Role>{x.role}</Role>
            </UserRow>
          ))}
        </div>
      ) : null}
    </div>
  );
};

export default NODESEARCHFORM;
