import React from "react";
import { IconContainer } from "../../../App.styled";
import {
  FieldLabelMobile,
  FieldValueMobile,
} from "../../../zone/ZoneLogsUserRow/zoneLogsUserRow.styled";
import { TimingContainer } from "./timing.styled";
import { BiEdit } from "react-icons/bi";
import AttendanceInfoUpdateForm from "../../../forms/attendanceInfoUpdateForm/AttendanceInfoUpdateForm";
import { decodeTime, toggleForm } from "../../../../utils/utilFunctions";
import { formNames } from "../../../../config";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { SolidButton } from "../../../buttons/solidButton.styled";
const weekMap = ["M", "T", "W", "Th", "F", "Sa", "S"];
const Timings = () => {
  const dispatch = useDispatch();
  const nodeInfo = useSelector((state) => state.nodeInfo);
  const formState = useSelector((state) => state.formState);
  const history = useHistory();
  const location = useLocation();
  return (
    <TimingContainer>
      {(nodeInfo.startTimeInDay &&
        (nodeInfo.fullDayMin || nodeInfo.fullDayMin === 0) &&
        nodeInfo.halfDayMin) ||
      nodeInfo.halfDayMin === 0 ? (
        <div className="row">
          <div className="col-md-2 col-lg-2 col-xs-12 col-sm-12 my-1">
            <FieldLabelMobile>Office Timings</FieldLabelMobile>
            <FieldValueMobile>
              {decodeTime(nodeInfo.startTimeInDay) +
                " - " +
                decodeTime(
                  nodeInfo.startTimeInDay + nodeInfo.workHours > 1440
                    ? nodeInfo.startTimeInDay + (nodeInfo.workHours - 1440)
                    : nodeInfo.startTimeInDay + nodeInfo.workHours
                )}
            </FieldValueMobile>
          </div>
          <div className="col-md-2 col-lg-2 col-xs-12 col-sm-12 my-1">
            <FieldLabelMobile>Work Days</FieldLabelMobile>
            <FieldValueMobile>
              {nodeInfo.workDays.map((i, idx) =>
                i ? (
                  <span key={weekMap[`${idx}`]} className="mx-1">
                    {weekMap[`${idx}`]}
                  </span>
                ) : null
              )}
            </FieldValueMobile>
          </div>
          <div className="col-md-2 col-lg-2 col-xs-12 col-sm-12 my-1">
            <FieldLabelMobile>Duration</FieldLabelMobile>
            <FieldValueMobile>{decodeTime(nodeInfo.workHours) + " Hrs"}</FieldValueMobile>
          </div>
          <div className="col-md-2 col-lg-2 col-xs-12 col-sm-12 my-1">
            <FieldLabelMobile>Full Day</FieldLabelMobile>
            <FieldValueMobile>{decodeTime(nodeInfo.fullDayMin) + " Hrs"}</FieldValueMobile>
          </div>
          <div className="col-md-2 col-lg-2 col-xs-12 col-sm-12 my-1">
            <FieldLabelMobile>Half Day</FieldLabelMobile>
            <FieldValueMobile>{decodeTime(nodeInfo.halfDayMin) + " Hrs"}</FieldValueMobile>
          </div>
          <div className="col-md-2 col-lg-2 col-xs-12 col-sm-12 d-flex justify-content-end align-items-center">
            <IconContainer>
              <SolidButton
                onClick={(e) =>
                  toggleForm(formState, history, location, dispatch, formNames.attendanceInfoUpdateForm)
                }
              >
                Edit
              </SolidButton>
            </IconContainer>
            <IconContainer>
              <SolidButton
                onClick={(e) =>
                  toggleForm(formState, history, location, dispatch, formNames.nodeHolidayUpdateForm)
                }
              >
                Manage
              </SolidButton>
            </IconContainer>
          </div>
        </div>
      ) : (
        <div className="row">
          <div className="col-md-8 col-lg-8 col-xs-12 col-sm-12 d-flex justify-content-center align-items-center">
            <div className="text-center py-4">
              <FieldLabelMobile>Please Update Info</FieldLabelMobile>
            </div>
          </div>
          <div className="col-md-4 col-lg-4 col-xs-12 col-sm-12 d-flex justify-content-end align-items-center">
            <IconContainer>
              <SolidButton
                onClick={(e) =>
                  toggleForm(formState, history, location, dispatch, formNames.attendanceInfoUpdateForm)
                }
              >
                Edit
              </SolidButton>
              <SolidButton
                onClick={(e) =>
                  toggleForm(formState, history, location, dispatch, formNames.nodeHolidayUpdateForm)
                }
              >
                Manage
              </SolidButton>
            </IconContainer>
          </div>
        </div>
      )}
    </TimingContainer>
  );
};

export default Timings;
