import React from "react";

const ButtonState = ({ loading, innerText }) => {
  return loading ? (
    <>
      <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
      <span className="sr-only">Loading...</span>
    </>
  ) : (
    <>{innerText}</>
  );
};

export default ButtonState;
