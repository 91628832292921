import styled, { css } from "styled-components";
import { breakPointWidth } from "../../../config";

export const InputGroup = styled.div`
  ${(props) =>
    props?.dimentions?.width > breakPointWidth
      ? css`
          display: grid;
          grid-template-areas: "label field";
          grid-column-gap: 3rem;
          margin-bottom: ${(props) => (props.noMargin ? "0.5rem" : "1rem")};
        `
      : css``}
    grid-template-columns: 0.2fr 0.7fr;    
`;