import axios from "axios";
import { updateAPIStatus } from "../../redux/apiStatus/apiStatusActions";
const qs = require("querystring");
const setLoadingTrue = (dispatch, url) => {
  if (dispatch && typeof dispatch === "function") dispatch(updateAPIStatus({ url, loading: true }));
};

const setLoadingFalse = (dispatch, url) => {
  if (dispatch && typeof dispatch === "function") dispatch(updateAPIStatus({ url, loading: false }));
};

const getURLInitials = (url) => {
  if (url.includes("?")) {
    return url.slice(0, url.indexOf("?"));
  } else {
    return url;
  }
};

const config = {
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
  },
};

const getConfig = (AT, config) => {
  if (AT) {
    config.headers["Authorization"] = `Bearer ${AT}`;
    return config;
  } else {
    return config;
  }
};

export const get = async (dispatch, url, AT) => {
  let initials = getURLInitials(url);
  setLoadingTrue(dispatch, initials);
  const response = await axios.get(url, getConfig(AT, config));
  setLoadingFalse(dispatch, initials);
  return response;
};

export const post = async (dispatch, url, requestBody, AT) => {
  let initials = getURLInitials(url);
  setLoadingTrue(dispatch, initials);
  const response = await axios.post(url, qs.stringify(requestBody), getConfig(AT, config));
  setLoadingFalse(dispatch, initials);
  return response;
};