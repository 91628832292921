import { RESET_FORM_STATE, TOGGLE_FORM, UPDATE_FORM } from "../actionTypes";
const defaultState = {
  zoneAddForm: false,
  zoneUpdateForm: false,
  visitorAddForm: false,
  addAccessForm: false,
  addOACForm: false,
  checkOACForm: false,
  checkDeviceRFDataForm: false,
  deviceAddForm: false,
  doorAddForm: false,
  groupAddForm: false,
  groupUpdateForm: false,
  membershipAccessInfoUpdateForm: false,
  peopleAddForm: false,
  peopleLimitEditForm: false,
  rfidAddForm: false,
  GOADUpdateForm: false,
  attendanceInfoUpdateForm: false,
  wifiInfoUpdateForm: false,
  editNameForm: false,
  departmentForm: false,
  userDeviceRequestsForm: false,
  hierarchyViewForm: false,
  deviceConfigForm: false,
  nodeHolidayUpdateForm: false,
  nodeConfigForm: false,
  attendanceFormatForm: false,
  doorInfoUpdateForm: false,
  notificationBox: false,
  addOauthAppForm: false,
  attendanceUserFilterForm: false,
  logSyncStatusForm: false,
  createUserCredentialsForm: false,
  addOauthAppForm: false,
  greytHrIntegrationForm: false,
  addDeviceFirmwareByDFIForm: false,
  addDeviceFirmwareByNameForm: false,
  editDeviceFirmwareByDFIForm: false,
  editDeviceFirmwareByNameForm: false,
  bulkOperationForm: false,
};
const formStateReducer = (
  formState = {
    ...defaultState,
  },
  action
) => {
  if (action.type === TOGGLE_FORM) {
    let temp = { ...formState };
    if (action.data) {
      temp[action.payload] = action.data;
    } else {
      temp[action.payload] = !temp[action.payload];
    }
    return (formState = { ...temp });
  }
  if (action.type === UPDATE_FORM) {
    let temp = { ...formState };
    temp[action.formName] = action.payload;
    return (formState = { ...temp });
  }
  if (action.type === RESET_FORM_STATE) {
    formState = {
      ...defaultState,
    };
  }
  return formState;
};

export default formStateReducer;
