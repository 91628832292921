import React from "react";
import { useSelector } from "react-redux";
import DisabledOverlay, { DisabledLockIcon } from "../DisabledOverlay";
const PeoplesMenu = ({ tabStatus, setTabStatus }) => {
  const nodeInfo = useSelector((state) => state.nodeInfo);
  return (
    <div className="dFlex aic">
      <div className="d-flex">
        <div style={{ position: "relative" }}>
          <h5
            style={{
              borderBottom: `${tabStatus === "people" ? "2px solid black" : ""}`,
              margin: "0 .5rem 0 0",
              textDecoration: "none",
              color: `${tabStatus === "people" ? "black" : "grey"}`,
            }}
            className="font-weight-bold cursor-pointer"
            onClick={(e) => setTabStatus("people")}
          >
            People
          </h5>
        </div>
      </div>
      <div className="d-flex">
        <div style={{ position: "relative" }}>
          {!nodeInfo?.features?.allowGroups ? <DisabledOverlay size="1rem" /> : null}
          <h5
            style={{
              borderBottom: `${tabStatus === "groups" ? "2px solid black" : ""}`,
              margin: "0 .5rem 0 .5rem",
              textDecoration: "none",
              color: `${tabStatus === "people" ? "grey" : "black"}`,
            }}
            className="font-weight-bold cursor-pointer"
            // onClick={(e) => setTabStatus("groups")}
          >
            Groups
          </h5>
        </div>
        {!nodeInfo?.features?.allowGroups ? <DisabledLockIcon size="1rem" /> : null}
      </div>
    </div>
  );
};

export default PeoplesMenu;
