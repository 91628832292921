import React from "react";

const PrivacyAndTerms = () => {
  return (
    <div className="col-12 d-flex justify-content-center mt-3">
      <p>
        By using our app you agree to the{" "}
        <a href="https://singularity.services/ems/privacy.html" target="blank">
          Privacy Policy
        </a>{" "}
        and{" "}
        <a href="https://singularity.services/ems/terms.html" target="blank">
          Terms
        </a>
      </p>
    </div>
  );
};

export default PrivacyAndTerms;
