import styled, { css } from "styled-components";

export const RowContainer = styled.div`
  border: 0.5px solid #e8e8e8;
  box-shadow: 0px 0.2rem 0.2rem 0.2rem rgba(0, 0, 0, 0.06);
  border-radius: 5px;
  height: 30vh;
  position: relative;
  padding-bottom : 30px;
  margin-bottom:10px;
`;

export const FormContainer = styled.div`
border: 0.5px solid #e8e8e8;
box-shadow: 0px 0.2rem 0.2rem 0.2rem rgba(0, 0, 0, 0.06);
border-radius: 5px;
margin-bottom: 20px;
padding-bottom: 5px;
position: relative;
:hover {
  cursor: pointer;
}
`;

export const FormContainerMobile = styled.div`
  padding: 0.5rem;
  border: 0.5px solid #e8e8e8;
  box-shadow: 0px 0.2rem 0.2rem 0.2rem rgba(0, 0, 0, 0.06);
  border-radius: 5px;
  margin-bottom: 20px;
  position: relative;
  :hover {
    cursor: pointer;
}
`;

export const RowStyle = styled.div`
  display: grid;
  grid-row-gap: 0.5rem;
  padding: 0.5rem 1rem;
  grid-template-columns: 1fr 2fr 2fr 1.5fr 1.5fr 2fr 2fr;
  grid-template-areas: "CardID StartTime EndTime StartTimeInDay EndTimeInDay Disabled Status";

  ${(props) =>
    props.active&&!props.disabled
      ? css`
          background-image: linear-gradient(91deg, #fe6dad -3%, #fc9f35);
        `
      : css``}
  ${(props) =>
    props.isHead
      ? css`
          font-weight: bold;
          padding-right: 1.5rem;
        `
      :!props.disabled && css`
          :hover {
            background-image: linear-gradient(91deg, #fe6dad -3%, #fc9f35);
            color : white;
          }
        `}
  ${(props)=>
    props.disabled?
    css`
      opacity: 0.5;
      background: linear-gradient(0deg, #606060, #696969);
      pointer-events:initial;
      color:white;
    `:css``}
  
`;

export const Button = styled.button`
  justify-self: center;
  align-self: center;
  cursor: pointer;
  font-size: 0.8rem;
  padding: 0.2rem 1rem;
  border-radius: 20px;
  border: 1px solid black;
  :hover {
    background-color: orange;
  }
`;

export const CardID = styled.div`
  display: flex;
  grid-area: "CardID";
  justify-content: center;
  align-items: center;
`;

export const StartTime = styled.div`
  grid-area: "StartTime";
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const EndTime = styled.div`
  grid-area: "EndTime";
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const StartTimeInDay = styled.div`
  grid-area: "StartTimeInDay";
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const EndTimeInDay = styled.div`
  grid-area: "EndTimeInDay";
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const Status = styled.div`
  grid-area: "Status";
  display: flex;
  align-items: center;
`;

export const Disabled = styled.div`
  grid-area: "Disabled";
  display: flex;
  justify-content: center;
  align-items: center;
`;