import React from "react";
import { IoMdArrowRoundBack } from "react-icons/io";
import { FiLogOut } from "react-icons/fi";
import { NavigationHeaderBox } from "./navigationHeader.styled";
import { cloudFrontOrigin } from "../../config";
import { Redirect, useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { resetFormState } from "../../redux/formStatus/formStatusActions";
import { updateAddRootNode } from "../../redux/addRootNode/addRootNodeActions";
import { logout } from "../../utils/logout";
import { useCookies } from "react-cookie";

const NavigationHeader = () => {
  const user = useSelector((state) => state.user);
  const [cookies, setCookie, removeCookie] = useCookies(["AT"]);
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  return (
    <NavigationHeaderBox>
      <IoMdArrowRoundBack
        className="m-0 p-0"
        style={{
          fontSize: "1.5rem",
          color: "#e54654",
          // visibility: `${location.pathname === "/apps" ? "hidden" : "visible"}`,
        }}
        onClick={(e) => {
          history.goBack();
          dispatch(resetFormState());
          dispatch(updateAddRootNode(false));
        }}
      />

      <img
        alt="Securepass"
        id="brandLogo"
        className="p-0 m-0"
        style={{ width: "100px" }}
        src={`${cloudFrontOrigin}SecurePass_b3mm6p_rnqlfl.jpg`}
        onClick={(e) => <Redirect to="/apps" />}
      />

      <FiLogOut
        onClick={(e) => logout(dispatch, removeCookie, history)}
        className="m-0 p-0"
        style={{
          fontSize: "1.5rem",
          color: "#e54654",
          visibility: `${user && user.AT ? "visible" : "hidden"}`,
        }}
      />
    </NavigationHeaderBox>
  );
};

export default NavigationHeader;
