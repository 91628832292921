import { updateSelectedNodeHierarchy } from "../redux/selectedNodeHierarchy/selectedNodeHierarchyActions";
import { updateVisitorLogs } from "../redux/visitorLogs/visitorLogsActions";
import { updateSelectedNode } from "../redux/selectedNode/selectedNodeActions";
import { updateNodes } from "../redux/nodes/nodesActions";
import { updateNodeInfo } from "../redux/nodeInfo/nodeInfoActions";
import { updateMembership } from "../redux/memberships/membershipsActions";
import { updateAddRootNode } from "../redux/addRootNode/addRootNodeActions";
import { deleteUser } from "../redux/user/userActions";
import { cookieDomain } from "../config";
import { updateSidebarStatus } from "../redux/sidebarStatus/sidebarStatusActions";
import { resetFormState } from "../redux/formStatus/formStatusActions";
import { resetNotifications } from "../redux/notifications/notificationsActions";
export const logout = (dispatch, removeCookie, history, fromVMS, atMismatch) => {
  removeCookie("AT", {
    path: "/",
    maxAge: 31536000,
    // domain: cookieDomain,
  });
  dispatch(updateVisitorLogs(null));
  dispatch(updateSelectedNodeHierarchy({}));
  dispatch(updateSelectedNode({}));
  dispatch(updateNodes({}));
  dispatch(updateNodeInfo({}));
  dispatch(updateMembership([]));
  dispatch(deleteUser());
  dispatch(resetFormState());
  dispatch(updateAddRootNode(false));
  dispatch(updateSidebarStatus({ target: "reset" }));
  dispatch(updateSidebarStatus({ target: "reset" }));
  dispatch(resetNotifications());
  localStorage.clear();

  if (atMismatch && history) {
    history.replace("/");
    localStorage.setItem("ATMISMATCH", "true");
  } else if (history) {
    history.replace("/");
  }
  if (fromVMS) {
    window.location.href = `${window.location.origin}/vms`;
  }
};

export const ATexpired = (response, dispatch, removeCookie, history) => {
  if (response?.data?.code === 1003 || response?.code === 1003) {
    logout(dispatch, removeCookie, history);
  }
};
