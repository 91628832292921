import styled, { css } from "styled-components";

const approveButtonBgColor = "#86D119";
const rejectButtonBgColor = "tomato";

export const RowContainer = styled.div`
  border: 0.5px solid #e8e8e8;
  box-shadow: 0px 0.2rem 0.2rem 0.2rem rgba(0, 0, 0, 0.06);
  border-radius: 5px;
  margin-bottom: 20px;
  height: 75vh;
  position: relative;
`;

export const RowStyled = styled.div`
  display: grid;
  grid-row-gap: 0.5rem;
  grid-column-gap: 0.2rem;
  padding: 1rem 1rem;
  grid-template-columns: 2fr 2fr 2fr 2fr 2fr 2fr;
  grid-template-areas: "Name Phone Email DeviceModel CreatedDate Status";
  
  ${(props) =>
    props.active
      ? css`
          background-image: linear-gradient(91deg, #fe6dad -3%, #fc9f35);
        `
      : css``}
  ${(props) =>
    props.isHead
      ? css`
          font-weight: bold;
        `
      : css`
          :hover {
            color: white;
            background-image: linear-gradient(91deg, #fe6dad -3%, #fc9f35);
          }
        `}
`;

export const Name = styled.div`
  grid-area: "Name";
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Phone = styled.div`
  grid-area: "Phone";
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Email = styled.div`
  grid-area: "Email";
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const DeviceModel = styled.div`
  grid-area: "DeviceModel";
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CreatedDate = styled.div`
  grid-area: "CreatedDate";
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Status = styled.div`
  grid-area: "Status";
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ApproveButton = styled.button`
  background-color: ${approveButtonBgColor};
  border: 1px solid white;
  outline: none;
  color: white;
  cursor: pointer;
  font-size: 0.8rem;
  margin-right:9%;
  padding:0.4rem;
  border-radius: 12px;  
  :hover {
    opacity: 0.5;
    color: black;
    border: 1px solid black;
  }
`;

export const RejectButton = styled.button`
  background-color: ${rejectButtonBgColor};
  border: 1px solid white;
  outline: none;
  color: white;
  cursor: pointer;
  font-size: 0.8rem;
  padding: 0.4rem;
  border-radius: 12px;  
  :hover {
    opacity: 0.5;
    color: black;
    border: 1px solid black;
  }
`;

export const ApproveButtonMobile = styled.button`
  background-color:${approveButtonBgColor};
  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.15);
  border-radius: 25px;
  outline: none;
  color: white;
  justify-self: center;
  align-self: center;
  cursor: pointer;
  font-size: 1rem;
  font-weight : bold;
  padding: 0.3rem 1.8rem;
  border : 0px;
`;

export const RejectButtonMobile = styled.button`
  background-color:${rejectButtonBgColor};
  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.15);
  border-radius: 25px;
  outline: none;
  color: white;
  justify-self: center;
  align-self: center;
  cursor: pointer;
  font-size: 1rem;
  font-weight : bold;
  padding: 0.3rem 1.8rem;
  border : 0px;
`;

export const StatusMobile = styled.div`
  font-size:1rem;
`;
