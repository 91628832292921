import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import DatePicker from "react-datepicker";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { breakPointWidth, rootApi, typeCode, formNames } from "../../../config";
import { get } from "../../../utils/apiService";
import { ATexpired } from "../../../utils/logout";
import ZoneLogsUserRow from "../ZoneLogsUserRow/ZoneLogsUserRow";
import CsvDownload from "react-json-to-csv";
import styled, { css } from "styled-components";
import {
  createNotification,
  notificationType,
} from "../../../vms/Services/utils";
import NotificationManager from "react-notifications/lib/NotificationManager";
import { formatTemp, nullChecker, toggleForm } from "../../../utils/utilFunctions";
import { SolidButton } from "../../buttons/solidButton.styled";
const ZoneLogs = () => {
  const [cookies, setCookie, removeCookie] = useCookies(["AT"]);
  let history = useHistory();
  const [date, setDate] = useState(Date.now());
  const [csvStartDate, setCsvStartDate] = useState(Date.now());
  const [csvEndDate, setCsvEndDate] = useState(Date.now());
  const [csvData, setCsvData] = useState(null);
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const selectedNode = useSelector((state) => state.selectedNode);
  const dimentions = useSelector((state) => state.dimentions);
  const formState = useSelector((state) => state.formState);
  const location = useLocation();
  
  const updateCsvData = async (sd, ed) => {
    let pageNo=1;
    let data=[];
    try {
      while(pageNo<10000){
        let url = `${rootApi}node/accessLogs?nodeId=${
          selectedNode.nodeId
        }&startTime=${new Date(sd).setHours(0, 0, 0, 0)}&endTime=${new Date(
          ed
        ).setHours(23, 59, 59, 99)}&pageNo=${pageNo}&pageSize=1000`;  
        let response = await get(dispatch, url, user.AT);
        if (!response.data.success) {
          createNotification(
            NotificationManager,
            notificationType.error,
            response.data.error
          );
          break;
        } else {
          let temp = response.data.accessLogs;
          temp = temp.map((t) => {
            delete t.deviceId;
            delete t.doorId;
            delete t.nodeId;
            delete t.userId;
            delete t.nodeAccessLogId;
            delete t.timestamp;
            delete t.newEntry;
            delete t.keyCardId;
            t.temperature = formatTemp(t.temperature, nullChecker);
            t.deviceTimestamp = new Date(t.deviceTimestamp)
              .toString()
              .slice(0, 24);
            t.recordType = typeCode[`${t.recordType}`];
            Object.keys(t).forEach((key) => (t[key] = t[key] ? t[key] : "-"));
            return t;
          });
          data = [...data,...temp];
          if(pageNo===response.data.noOfPages||response.data.noOfPages===0){
            break;
          }else{
            pageNo++;
          }
        }  
        ATexpired(response, dispatch, removeCookie, history);
      }
      setCsvData([...data]);
    } catch (err) {
      throw err;
    }
  };

  useEffect(() => {
    console.log("csvUpdate")
    updateCsvData(csvStartDate, csvEndDate);
  }, [csvStartDate, csvEndDate]);

  return (
    <div>
      <ZoneLogsCSVOptionsContainer dimentions={dimentions}>
        <h5 className="font-weight-bold m-0">Download Logs</h5>
        <div>
          <p className=" mb-1 font-weight-bold">From</p>
          <DatePicker
            dateFormat="dd/MM/yyyy"
            className="react-datepicker-custom-css"
            selected={csvStartDate}
            maxDate={Date.now()}
            onChange={(date) => setCsvStartDate(date)}
          />
        </div>
        <div>
          <p className="mb-1 font-weight-bold">To</p>
          <DatePicker
            dateFormat="dd/MM/yyyy"
            className="react-datepicker-custom-css"
            selected={csvEndDate}
            maxDate={Date.now()}
            onChange={(date) => setCsvEndDate(date)}
          />
        </div>
        <CsvDownload
          className="solid-button"
          disabled={!csvData || !csvData.length}
          data={csvData}
          filename={`${new Date(csvStartDate)
            .toString()
            .slice(0, 15)}-${new Date(csvEndDate).toString().slice(0, 15)}.csv`}
        />
      </ZoneLogsCSVOptionsContainer>
      <ZoneLogsCSVOptionsContainer dimentions={dimentions}>
        <h5 className="font-weight-bold mr-3 m-0">Day Logs</h5>
        <DatePicker
          dateFormat="dd/MM/yyyy"
          className="react-datepicker-custom-css"
          selected={date}
          maxDate={Date.now()}
          onChange={(date) => setDate(date)}
        />
        <SolidButton onClick={(e) =>
          toggleForm(formState, history, location, dispatch, formNames.logSyncStatusForm)
        }>
          Log Sync Status
        </SolidButton>
      </ZoneLogsCSVOptionsContainer>
      <div>
        <ZoneLogsUserRow filterDate={date} />
      </div>
    </div>
  );
};

export default ZoneLogs;

export const ZoneLogsCSVOptionsContainer = styled.div`
  ${(props) =>
    props.dimentions.width > breakPointWidth
      ? css`
          display: flex;
          align-items: flex-end;
          gap: 1rem 1rem;
          margin: 2rem 0rem;
        `
      : css`
          display: flex;
          flex-direction: column;
          gap: 12px;
          border: 0.5px solid grey;
          padding: 1rem;
          margin: 0.5rem 0px;
          border-radius: 10px;
        `}
`;
