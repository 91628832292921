// export const rootApi =
//   process.env.REACT_APP_API_ROOT === "PROD"
//     ? "https://api.snglty.com/v2/"
//     : "https://api.snglty.com/staging-securepass-v2/";

export const rootApi =
  process.env.REACT_APP_API_ROOT === "PROD"
    ? "https://api.snglty.com/v2/"
    : // : "http://ec2-13-232-201-166.ap-south-1.compute.amazonaws.com:4000/v2/";
      "https://api.snglty.com/staging-securepass-v2/";

export const gtmId = process.env.REACT_APP_API_ROOT === "PROD" ? "GTM-T3GPCWL" : "GTM-T3GPCWL";
export const measurement_id =
  process.env.REACT_APP_API_ROOT === "PROD" ? "G-5FP8WK1SL6" : "G-Y08K1S3ME0";

// "http://localhost:4000/v2/";
// export const rootApi = "https://api.snglty.com/staging-securepass-v2/";
// export const rootApi = "http://127.0.0.1:4000/v2/";
export const cookieDomain = ".thesecurepass.com";
// export const cookieDomain = ".netlify.app";
// export const cookieDomain = "localhost";
export const breakPointWidth = 1300;
export const cloudFrontOrigin = "https://doswljnolqe2s.cloudfront.net/";
export const formNames = {
  zoneAddForm: "zoneAddForm",
  zoneUpdateForm: "zoneUpdateForm",
  addRootNodeForm: "addRootNodeForm",
  zoneUpdateForm: "zoneUpdateForm",
  visitorAddForm: "visitorAddForm",
  addAccessForm: "addAccessForm",
  addOACForm: "addOACForm",
  deviceAddForm: "deviceAddForm",
  doorAddForm: "doorAddForm",
  groupAddForm: "groupAddForm",
  groupUpdateForm: "groupUpdateForm",
  membershipAccessInfoUpdateForm: "membershipAccessInfoUpdateForm",
  peopleAddForm: "peopleAddForm",
  peopleLimitEditForm: "peopleLimitEditForm",
  rfidAddForm: "rfidAddForm",
  GOADUpdateForm: "GOADUpdateForm",
  attendanceInfoUpdateForm: "attendanceInfoUpdateForm",
  wifiInfoUpdateForm: "wifiInfoUpdateForm",
  editNameForm: "editNameForm",
  departmentForm: "departmentForm",
  userDeviceRequestsForm: "userDeviceRequestsForm",
  hierarchyViewForm: "hierarchyViewForm",
  deviceConfigForm: "deviceConfigForm",
  checkOACForm: "checkOACForm",
  checkDeviceRFDataForm: "checkDeviceRFDataForm",
  nodeHolidayUpdateForm: "nodeHolidayUpdateForm",
  nodeConfigForm: "nodeConfigForm",
  freshTeamsIntegrationFrom: "freshTeamsIntegrationFrom",
  integrationEmployeeDetailsEditForm: "integrationEmployeeDetailsEditForm",
  attendanceFormatForm: "attendanceFormatForm",
  doorInfoUpdateForm: "doorInfoUpdateForm",
  logSyncStatusForm: "logSyncStatusForm",
  notificationBox: "notificationBox",
  attendanceUserFilterForm: "attendanceUserFilterForm",
  createUserCredentialsForm: "createUserCredentialsForm",
  addOauthAppForm: "addOauthAppForm",
  greytHrIntegrationForm: "greytHrIntegrationForm",
  addDeviceFirmwareByDFIForm: "addDeviceFirmwareByDFIForm",
  addDeviceFirmwareByNameForm: "addDeviceFirmwareByNameForm",
  editDeviceFirmwareByDFIForm: "editDeviceFirmwareByDFIForm",
  editDeviceFirmwareByNameForm: "editDeviceFirmwareByNameForm",
  bulkOperationForm: "bulkOperationForm",
};

export const typeCode = {
  1: "BLE unlock",
  11: "BLE lock",
  4: "KeyCode(OAC) unlock",
  34: "lock by passcode",
  7: "IC card unlock",
  8: "Fingerprint Unlock",
  33: "Fingerprint Lock",
  35: "lock by IC card",
  101: "BLE Exit",
  238: "NO TOUCH EXIT",
  246: "NO AUTH",
  230: "DOOR OPENED",
  229: "DOOR CLOSED",
};

export const dayMap = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];

export const realJsDayMap = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

export const weekOffReference = [1, 2, 3, 4, 5, 6, 0];

export const yearMap = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const yearMapShort = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const OACTypes = [
  ["Select", 0],
  ["OTP", 1],
  ["Permanent", 2],
  ["Timed", 3],
];

export const hourMap = [
  "12 AM",
  "01 AM",
  "02 AM",
  "03 AM",
  "04 AM",
  "05 AM",
  "06 AM",
  "07 AM",
  "08 AM",
  "09 AM",
  "10 AM",
  "11 AM",
  "12 PM",
  "01 PM",
  "02 PM",
  "03 PM",
  "04 PM",
  "05 PM",
  "06 PM",
  "07 PM",
  "08 PM",
  "09 PM",
  "10 PM",
  "11 PM",
];
export const rolesMap = {
  NODE_OWNER: " NODE_OWNER",
  ADMIN: " ADMIN",
  VISITOR: " VISITOR",
  PROPERTY_MANAGER: " PROPERTY_MANAGER",
  EMPLOYEE: " EMPLOYEE",
  CARETAKER: " CARETAKER",
  TENANT: " TENANT",
  STUDENT: " STUDENT",
  UNIT_OWNER: " UNIT_OWNER",
  MAINTENANCE_STAFF: " MAINTENANCE_STAFF",
  INTERN: " INTERN",
  EXTERNAL_STAFF: " EXTERNAL_STAFF",
  HOUSE_KEEPING: " HOUSE_KEEPING",
  SECURITY: " SECURITY",
  MEMBER: " MEMBER",
  PROFESSOR: " PROFESSOR",
  TEACHER: " TEACHER",
  STAFF: " STAFF",
  DOCTOR: " DOCTOR",
  NURSE: " NURSE",
};

export const nodeCategoryMap = {
  INSTITUTE: "INSTITUTE/SCHOOL/COACHING_CENTER",
  COMMERCIAL: "COMMERCIAL",
  STUDENT_HOUSING: "STUDENT_HOUSING",
  RESIDENTIAL: "RESIDENTIAL",
  COWORKING: "COWORKING",
  GYM: "GYM/CLUB",
  COLIVING: "COLIVING",
  HOSPITAL: "HOSPITAL",
  OFFICE: "OFFICE",
  EVENT: "EVENT",
};

export const membershipRoles = (cat) => {
  const {
    NODE_OWNER,
    ADMIN,
    PROPERTY_MANAGER,
    EMPLOYEE,
    CARETAKER,
    TENANT,
    STUDENT,
    UNIT_OWNER,
    MAINTENANCE_STAFF,
    INTERN,
    EXTERNAL_STAFF,
    HOUSE_KEEPING,
    SECURITY,
    MEMBER,
    PROFESSOR,
    TEACHER,
    STAFF,
    DOCTOR,
    NURSE,
  } = rolesMap;

  const {
    INSTITUTE,
    COMMERCIAL,
    STUDENT_HOUSING,
    RESIDENTIAL,
    COWORKING,
    GYM,
    COLIVING,
    HOSPITAL,
    OFFICE,
    EVENT,
  } = nodeCategoryMap;

  const roles = ["Select", NODE_OWNER, ADMIN, SECURITY]; //Common Roles
  switch (cat) {
    case RESIDENTIAL:
      roles.push(...[TENANT, UNIT_OWNER, MAINTENANCE_STAFF, HOUSE_KEEPING]);
      break;
    case COMMERCIAL:
      roles.push(...[EMPLOYEE, UNIT_OWNER, MAINTENANCE_STAFF, HOUSE_KEEPING]);
      break;
    case OFFICE:
      roles.push(...[EMPLOYEE, MAINTENANCE_STAFF, INTERN, EXTERNAL_STAFF, HOUSE_KEEPING]);
      break;
    case INSTITUTE:
      roles.push(...[MAINTENANCE_STAFF, STUDENT, PROFESSOR, TEACHER, STAFF]);
      break;
    case COLIVING:
      roles.push(...[PROPERTY_MANAGER, CARETAKER, TENANT, MAINTENANCE_STAFF, HOUSE_KEEPING]);
      break;
    case STUDENT_HOUSING:
      roles.push(...[PROPERTY_MANAGER, CARETAKER, STUDENT, MAINTENANCE_STAFF, HOUSE_KEEPING]);
      break;
    case COWORKING:
      roles.push(...[PROPERTY_MANAGER, EMPLOYEE, CARETAKER, MAINTENANCE_STAFF, HOUSE_KEEPING, MEMBER]);
      break;
    case HOSPITAL:
      roles.push(...[STAFF, DOCTOR, NURSE]);
      break;
    case GYM:
      roles.push(...[PROPERTY_MANAGER, EXTERNAL_STAFF, MEMBER, STAFF]);
      break;
    case EVENT:
      roles.push(...[]);
  }

  return roles;
};

export const UDIDAllowedNodeCategories = [
  "INSTITUTE/SCHOOL/COACHING_CENTER",
  "COMMERCIAL",
  "STUDENT_HOUSING",
  "RESIDENTIAL",
  "COWORKING",
  "GYM/CLUB",
  "COLIVING",
  "HOSPITAL",
  "OFFICE",
  "EVENT",
];

export const OPS_LIST = ["9999999999", "9686023101", "9882990388", "7007070981"];

export const notificationsData = [
  {
    link: "https://securepass.notion.site/Click-here-Updated-Attendance-Module-500ab6d2dec14991a54982a875f9bf65",
    createdTimestamp: 1617561000,
    readTimestamp: null,
    text: "The Attendance Dashboard has been updated to provide a better experience to our customers. click to get more details",
    membershipId: null,
    notificationId: null,
  },
  {
    link: "https://securepass.notion.site/User-Employee-Dashboard-930832d4f66d4af283477f04ff1dc695",
    createdTimestamp: 1621535400,
    readTimestamp: null,
    text: "The End User (Employee) dashboard has been added to provide a better experience to our clients. Employees can now check their attendance logs from Mobile App. Please click here to check how to use it.",
    membershipId: null,
    notificationId: null,
  },
  {
    link: "https://securepass.notion.site/Click-here-Updated-App-UI-c7db548ec5e4460691c6d9490876d06e",
    createdTimestamp: 1633587625,
    readTimestamp: null,
    text: "The logs sections is shifted to attendance page please use attendance page to check employee logs.",
    membershipId: null,
    notificationId: null,
  },

  {
    link: "https://securepass.notion.site/Click-here-Updated-VMS-fb8f3125e8764f93a456c664252a70e4",
    createdTimestamp: 1639374965,
    readTimestamp: 0,
    text: "Visitor Management System has been updated, click to know more.",
    membershipId: null,
    notificationId: null,
  },
  {
    link: "https://securepass.notion.site/Updated-Access-Creation-Guide-3fb264abfcfa48cc98cd5ed609b514e8",
    createdTimestamp: 1651838543,
    readTimestamp: 0,
    text: "Add People form has been updated, click to know more.",
    membershipId: null,
    notificationId: null,
  },
];

export const SUPER_ADMIN_LIST = ["9999999999", "9686023101"];

export const basicFeatures = {
  allowNotifications: {
    membershipExpiry: false,
  },
  attendanceFilters: {
    department: false,
    subDepartment: false,
    role: false,
  },
  attendanceConfig: {
    firstName: true,
    lastName: true,
    emailId: true,
    mobileNo: true,
    UDID: true,
    designation: true,
    department: true,
    subDepartment: true,
    userType: true,
    inTime: true,
    outTime: true,
    firstInToLastOut: true,
    totalInTime: false,
    overtime: false,
    lateBy: false,
    earlyBy: false,
    punchRecords: false,
    daySummary: false,
    totalInTimeSum: false,
    totalBreakTimeSum: false,
    totalPresent: true,
    totalWorkOff: false,
    totalAbsent: false,
    totalPresentPlusWorkoff: false,
    totalShortFall: false,
    totalhalfDays: false,
    totalDays: false,
    totalWorkingDays: false,
    totalPrms: false,
  },
  allowDeviceRestriction: false,
  allowMobileView: false,
  allowDesktopView: true,
  basicSupport: true,
  quickSupport: false,
  instantSupport: false,
  editAccessDays: false,
  allowIntegrations: false,
  editAccessHours: false,
};

export const basicFeatures2 = {
  allowNotifications: {
    membershipExpiry: false,
  },
  attendanceFilters: {
    department: false,
    subDepartment: false,
    role: false,
  },
  attendanceConfig: {
    firstName: true,
    lastName: true,
    emailId: true,
    mobileNo: true,
    UDID: true,
    designation: true,
    department: true,
    subDepartment: true,
    userType: true,
    inTime: true,
    outTime: true,
    firstInToLastOut: true,
    totalInTime: false,
    overtime: false,
    lateBy: false,
    earlyBy: false,
    punchRecords: false,
    daySummary: false,
    totalInTimeSum: false,
    totalBreakTimeSum: false,
    totalPresent: true,
    totalWorkOff: false,
    totalAbsent: false,
    totalPresentPlusWorkoff: false,
    totalShortFall: false,
    totalhalfDays: false,
    totalDays: false,
    totalWorkingDays: false,
    totalPrms: false,
  },
  allowDeviceRestriction: false,
  allowMobileView: true,
  allowDesktopView: false,

  basicSupport: true,
  quickSupport: false,
  instantSupport: false,
  editAccessDays: false,
  allowIntegrations: false,
  editAccessHours: false,
};

export const advancedFeatures = {
  allowNotifications: {
    membershipExpiry: true,
  },
  attendanceFilters: {
    department: true,
    subDepartment: true,
    role: true,
  },
  attendanceConfig: {
    firstName: true,
    lastName: true,
    emailId: true,
    mobileNo: true,
    UDID: true,
    designation: true,
    department: true,
    subDepartment: true,
    userType: true,
    inTime: true,
    outTime: true,
    firstInToLastOut: true,
    totalInTime: true,
    overtime: true,
    lateBy: true,
    earlyBy: true,
    punchRecords: true,
    daySummary: true,
    totalInTimeSum: true,
    totalBreakTimeSum: true,
    totalPresent: true,
    totalWorkOff: true,
    totalAbsent: true,
    totalPresentPlusWorkoff: true,
    totalShortFall: true,
    totalhalfDays: true,
    totalDays: true,
    totalWorkingDays: true,
    totalPrms: true,
  },
  allowDeviceRestriction: true,
  allowMobileView: true,
  allowDesktopView: true,
  basicSupport: true,
  quickSupport: true,
  instantSupport: true,
  editAccessDays: true,
  editAccessHours: true,
  allowIntegrations: true,
};

export const apiEndPoints = {
  rfCardAdd: `${rootApi}RFCard/add`,
  updateRfid: `${rootApi}RFCard/assignUser`,
  bulkRfidAssignAPI: `${rootApi}RFCard/bulkAssignUsers`,
  removeRfid: `${rootApi}RFCard/removeUser`,
  addGroupAPI: `${rootApi}accessgroup/create`,
  removeMembershipFromAccessGroup: `${rootApi}accessgroup/membership/remove`,
  getGroupNodeMemberships: `${rootApi}accessgroup/memberships`,
  removeAccessGroupAPI: `${rootApi}accessgroup/remove`,
  updateGroupApi: `${rootApi}accessgroup/update`,
  getDeviceOACs: `${rootApi}device/OACs`,
  getDeviceRFData: `${rootApi}device/RFData`,
  getDeviceConfig: `${rootApi}device/configuration`,
  deviceConfigUpdate: `${rootApi}device/configuration/update`,
  deleteOAC: `${rootApi}device/deleteOAC`,
  addDeviceFirmwareAPI: `${rootApi}device/firmware/add`,
  deleteDeviceFirmwareAPI: `${rootApi}device/firmware/delete`,
  getDeviceFirmwareListAPI: `${rootApi}device/firmware/list`,
  updateDeviceFirmwareAPI: `${rootApi}device/firmware/update`,
  resyncOACs: `${rootApi}device/resyncOACs`,
  resyncRFCards: `${rootApi}device/resyncRFCards`,
  updateOAC: `${rootApi}device/updateOAC`,
  deviceAddAPI: `${rootApi}door/addDevice`,
  deleteDoorAPI: `${rootApi}door/delete`,
  updateDoorInfo: `${rootApi}door/info/update`,
  removeDeviceAPI: `${rootApi}door/removeDevice`,
  IntegrationEmployeeListAPI: `${rootApi}integration/employeeList`,
  getIntegrationsDetailsAPI: `${rootApi}integration/integrationDetails`,
  SyncMatchedEmoloyeesAPI: `${rootApi}integration/syncEmployees`,
  updateIntegrationCredentialsAPI: `${rootApi}integration/update`,
  membershipAccessInfoRevoke: `${rootApi}membership/accessInfo/revoke`,
  addMemberAPI: `${rootApi}membership/add`,
  bulkDeleteMembershipsAPI: `${rootApi}membership/bulkDeleteMemberships`,
  bulkUpdateAccessAPI: `${rootApi}membership/bulkUpdateAccess`,
  removeMembershipAPI: `${rootApi}membership/revoke`,
  updateMemberShipAPI: `${rootApi}membership/update`,
  getOACs: `${rootApi}node/OACs`,
  // getAccessGroupsAPI: `${rootApi}node/accessGroups`,
  addZoneAPI: `${rootApi}node/add`,
  addDoorAPI: `${rootApi}node/addDoor`,
  addRootNodeAPI: `${rootApi}node/addRootNode`,
  addUserAlertAPI: `${rootApi}node/alerts/addUser`,
  removeUserFromAlertGroupAPI: `${rootApi}node/alerts/removeUser`,
  getNodeUsersToAlert: `${rootApi}node/alerts/users`,
  deleteZoneAPI: `${rootApi}node/delete`,
  addDepartmentAPI: `${rootApi}node/department/add`,
  removeDepartmentAPI: `${rootApi}node/department/remove`,
  updateDepartmentAPI: `${rootApi}node/department/update`,
  getDevices: `${rootApi}node/devices`,
  generateOACs: `${rootApi}node/generateOACs`,
  getSelectedNodeHierarchy: `${rootApi}node/hierarchy`,
  nodeHolidayAdd: `${rootApi}node/holiday/add`,
  nodeHolidayRemove: `${rootApi}node/holiday/remove`,
  nodeHolidayUpdate: `${rootApi}node/holiday/update`,
  getNodeInfo: `${rootApi}node/info`,
  updateNodeInfoAPI: `${rootApi}node/info/update`,
  getNodeLogSyncStatusRequestsAPI: `${rootApi}node/logs/syncStatus`,
  getAccessInfoAPI: `${rootApi}node/membership/accessInfo`,
  searchPeopleAPI: `${rootApi}node/membership/search`,
  getUserDeviceRequestsAPI: `${rootApi}node/userDevice/requests`,
  visitorAddAPI: `${rootApi}node/visitor/add`,
  getVisitorsLogsAPI: `${rootApi}node/visitorLogs`,
  fetchNotifications: `${rootApi}notifications`,
  markNotificationAsReadAPI: `${rootApi}notifications/update`,
  fetchAppDetails: `${rootApi}oauth/appDetails`,
  getOauthPermissions: `${rootApi}oauth/clientInfo`,
  allowOauth: `${rootApi}oauth/dialog/allow`,
  getOauthApproovedApps: `${rootApi}oauth/getApprovedApps`,
  fetchOauthApps: `${rootApi}oauth/oauthApps`,
  registerOauthApp: `${rootApi}oauth/registerApp`,
  updateOauthApp: `${rootApi}oauth/update`,
  findDeviceNode: `${rootApi}operations/findDeviceNode`,
  searchUserNode: `${rootApi}operations/findUserNode`,
  getUserDeviceRequestsAPI: `${rootApi}user/devices`,
  generateUserCredentials: `${rootApi}user/generateApiKeys`,
  getUserCredintials: `${rootApi}user/getApiKeys`,
  getNodes: `${rootApi}user/node/hierarchy`,
  getUserProfileImageAPI: `${rootApi}user/profileImage`,
  deleteUserProfileImageAPI: `${rootApi}user/profileImage/remove`,
  uploadUserProfileImageAPI: `${rootApi}user/profileImage/upload`,
  getUserRoles: `${rootApi}user/roles`,
  updateUserNameAPI: `${rootApi}user/updateInfo`,
  updateUserDeviceRequestAPI: `${rootApi}user/verifyDeviceWithoutCode`,
  getZoneLogsAPI: `${rootApi}node/accessLogs`,
  OwnershipTransfer: `${rootApi}operations/changeOwnership`,
  deviceAddFromConfig: `${rootApi}device/add`,
  getDeviceQrCodes: `${rootApi}device/qrCodes`,
  login: `${rootApi}user/login`,
  requestResetPasswordOTP: `${rootApi}user/sendForgotPasswordOTP`,
  resetPassword: `${rootApi}user/resetPassword`,
  requestRegisterOTP: `${rootApi}user/sendRegistrationOTP`,
  register: `${rootApi}user/register`,
};

export const contentLength = {
  email: 25,
  mobileNo: 10,
  name: 10,
  text: 30,
  customUserId: 10,
  role: 17,
};
