import { toggleFormDispatch } from "../redux/formStatus/formStatusActions";
import { updateSidebarStatus } from "../redux/sidebarStatus/sidebarStatusActions";
export const convertTime = (time, type) => {
  let date = time.slice(0, 15);
  return new Date(
    `${date} ${type === "start" ? "00:00:00" : "23:59:59"} GMT+0530 (India Standard Time)`
  );
};

export const nullChecker = (v) => (v ? v : "-");

export const parseQueryParams = (query) => {
  const queryArray = query.split("?")[1].split("&");
  let queryParams = {};
  for (let i = 0; i < queryArray.length; i++) {
    const [key, val] = queryArray[i].split("=");
    queryParams[key] = val ? val : true;
  }
  return queryParams;
};

export const displayDate = (t) => {
  return new Date(t).toLocaleDateString("en-GB", {
    month: "2-digit",
    day: "2-digit",
    year: "numeric",
  });
};

export const displayTimeMinutes = (t) => {
  return new Intl.DateTimeFormat("en-IN", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  }).format(t);
};

export const toggleSidebar = (dispatch, target) => {
  dispatch(updateSidebarStatus({ target }));
};

export const toggleForm = (formState, history, location, dispatch, formName, data) => {
  if (location.search.length && parseQueryParams(location.search)[formName]) {
    history.goBack();
  } else {
    history.push(`${location.pathname}?${formName}=true`);
  }
  dispatch(toggleFormDispatch(formName, data));
};

export const convetToLocaleDateString = (t) => {
  return t != null && t > 0 ? new Date(t * 1000).toLocaleDateString("hi-IN") : "-";
};

export const encodeTime = (t) => {
  return t
    .split(":")
    .map((v, i) => (i === 0 ? parseInt(v) * 60 : parseInt(v)))
    .reduce((sum, current) => sum + current, 0);
};

export const decodeTime = (t) => {
  return `${parseInt(t / 60) < 10 ? "0" + parseInt(t / 60) : parseInt(t / 60)}:${
    parseInt(t % 60) < 10 ? "0" + parseInt(t % 60) : parseInt(t % 60)
  }`;
};

export const getTimeFromUNIX = (t) => {
  return `${new Date(t).getHours() < 10 ? "0" + new Date(t).getHours() : new Date(t).getHours()}:${
    new Date(t).getMinutes() < 10 ? "0" + new Date(t).getMinutes() : new Date(t).getMinutes()
  }`;
};

export const formatTemp = (t, nullChecker) => {
  return `${nullChecker(t)}` === "-" ? `${nullChecker(t)}` : `${(t * 1.8 + 32).toFixed(2)}°F`;
};

export const sanitizeReqBody = (b) => {
  console.log(b);
  let t = { ...b };
  Object.keys(b).forEach((k) => {
    if ((typeof b[k] === "undefined" || b[k] === null || b[k] === "") && k !== "locationCode") {
      delete t[k];
    }
  });
  return t;
};

export const isRootNode = (nodeId, nodes) => {
  return nodes.map((n) => n.nodeId).includes(nodeId);
};

export const inRange = (x, min, max) => {
  return (x - min) * (x - max) <= 0;
};

export const isAdmin = (user, selectedNode) => {
  return (
    user &&
    user.nodesAsAdminAccess &&
    user.nodesAsAdminAccess.length &&
    user.nodesAsAdminAccess.includes(selectedNode.nodeId)
  );
};

export const getUserRole = (roles, selectedNode) => {
  if (roles && selectedNode && selectedNode.nodeId) {
    if (
      (selectedNode.rootNodeId &&
        roles[selectedNode.rootNodeId] &&
        roles[selectedNode.rootNodeId].includes("ADMIN")) ||
      (selectedNode.rootNodeId &&
        roles[selectedNode.rootNodeId] &&
        roles[selectedNode.rootNodeId].includes("NODE_OWNER"))
    ) {
      return "ADMIN";
    } else if (roles[selectedNode.nodeId]) {
      return roles[selectedNode.nodeId][0];
    } else return "none";
  } else return "none";
};

export const getUserRoleArray = (roles, selectedNode) => {
  if (roles && selectedNode && selectedNode.nodeId) {
    const id = selectedNode.rootNodeId || selectedNode.nodeId;
    return roles[id].roles.map((v) => v.role);
  } else return [];
};
export const detectDeviceType = () => {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
    ? "Mobile"
    : "Desktop";
};

export const sortPlanObject = (c) => {
  const s = (unordered) => {
    return Object.keys(unordered)
      .sort()
      .reduce((obj, key) => {
        obj[key] = unordered[key];
        return obj;
      }, {});
  };
  const sorted = s({
    ...c,
    allowNotifications: s({ ...c.allowNotifications }),
    attendanceFilters: s({ ...c.attendanceFilters }),
    attendanceConfig: s({ ...c.attendanceConfig }),
  });
  return sorted;
};

export const hasSubArray = (master, sub) => master.sort().join("").includes(sub.sort().join(""));

export const stringToChunks = (string, chunkSize) => {
  const chunks = [];
  while (string.length > 0) {
    chunks.push(string.substring(0, chunkSize));
    string = string.substring(chunkSize, string.length);
  }
  return chunks;
};
