import React from "react";

import {
  ZoneLogsUserRowMobileView,
  FieldLabelMobile,
  FieldValueMobile,
  ZoneLogsUserRowMobileWrapper,
  ZoneLogsUserRowContainer,
  ZoneLogsUserRowStyle,
  Sn,
  NameAvatar,
  AccessType,
  Type,
  Time,
  Door,
  Temperature,
  Button,
  ButtonMobile,
} from "../../zone/ZoneLogsUserRow/zoneLogsUserRow.styled";
import { useDispatch, useSelector } from "react-redux";
import { breakPointWidth, rootApi, typeCode } from "../../../config";
import { formatTemp, nullChecker } from "../../../utils/utilFunctions";
import { useHistory } from "react-router-dom";
import { useCookies } from "react-cookie";
import PlaceholderMessage from "../../PlaceholderMessage";

import { OverFLowScrollBox } from "../../App.styled";
import { PageButton, PageButtonContainer } from "../../../paging.styled";
const AttendanceLogsUserRow = ({ zoneLogs }) => {
  const [cookies, setCookie, removeCookie] = useCookies(["AT"]);
  let history = useHistory();
  const dispatch = useDispatch();

  const user = useSelector((state) => state.user);
  const dimentions = useSelector((state) => state.dimentions);

  return (
    <>
      {dimentions.width > breakPointWidth ? (
        <ZoneLogsUserRowContainer dimentions={dimentions}>
          <ZoneLogsUserRowStyle isHead={true}>
            <Sn>UDID</Sn>
            <NameAvatar>Name</NameAvatar>
            <AccessType>Access Way</AccessType>
            <Type>Record Type</Type>
            <Time>Time</Time>
            <Door>Door</Door>
            <Temperature>Temperature</Temperature>
          </ZoneLogsUserRowStyle>
          <OverFLowScrollBox>
            {zoneLogs && zoneLogs.length ? (
              zoneLogs.map((i, index) => {
                return (
                  <ZoneLogsUserRowStyle key={i.nodeAccessLogId}>
                    <Sn>{nullChecker(i.UDId)}</Sn>
                    <NameAvatar>{nullChecker(i.firstName) + " " + nullChecker(i.lastName)}</NameAvatar>
                    <AccessType>{nullChecker(i.accessType)}</AccessType>
                    <Type>{
                      (i.recordType===230 && i.success===180)?"GATE_ALERT":nullChecker(typeCode[i.recordType])
                    }</Type>
                    <Time>{new Date(parseInt(i.deviceTimestamp)).toTimeString().slice(0, 8)}</Time>
                    <Door>{nullChecker(i.doorName)}</Door>
                    <Temperature>{formatTemp(i.temperature, nullChecker)}</Temperature>
                    <Button>Revoke</Button>
                  </ZoneLogsUserRowStyle>
                );
              })
            ) : (
              <PlaceholderMessage message="No data to display" />
            )}
          </OverFLowScrollBox>
        </ZoneLogsUserRowContainer>
      ) : (
        <div className="row m-0 p-0">
          {zoneLogs && zoneLogs.length ? (
            zoneLogs.map((i) => (
              <div className="col-sm-12 col-xs-12 p-0 col-md-6 px-md-2" key={i.nodeAccessLogId}>
                <ZoneLogsUserRowMobileWrapper>
                  <ZoneLogsUserRowMobileView>
                    <div>
                      <FieldLabelMobile>UDID</FieldLabelMobile>
                      <FieldValueMobile>{nullChecker(i.UDId)}</FieldValueMobile>
                    </div>
                    <div>
                      <FieldLabelMobile>Name</FieldLabelMobile>
                      <FieldValueMobile>
                        {nullChecker(i.firstName) + " " + nullChecker(i.lastName)}
                      </FieldValueMobile>
                    </div>
                    <div>
                      <FieldLabelMobile>Access Way</FieldLabelMobile>
                      <FieldValueMobile>{nullChecker(i.accessType)}</FieldValueMobile>
                    </div>
                    <div>
                      <FieldLabelMobile>Access Type</FieldLabelMobile>
                      <FieldValueMobile>{nullChecker(typeCode[i.recordType])}</FieldValueMobile>
                    </div>
                    <div>
                      <FieldLabelMobile>Time</FieldLabelMobile>
                      <FieldValueMobile>
                        {new Date(parseInt(i.deviceTimestamp)).toTimeString().slice(0, 8)}
                      </FieldValueMobile>
                    </div>
                    <div>
                      <FieldLabelMobile>Door</FieldLabelMobile>
                      <FieldValueMobile>{nullChecker(i.doorName)}</FieldValueMobile>
                    </div>
                    <div>
                      <FieldLabelMobile>Temperature</FieldLabelMobile>
                      <FieldValueMobile>{formatTemp(i.temperature, nullChecker)}</FieldValueMobile>
                    </div>
                  </ZoneLogsUserRowMobileView>
                  <div className="text-center">
                    <ButtonMobile>Revoke</ButtonMobile>
                  </div>
                </ZoneLogsUserRowMobileWrapper>
              </div>
            ))
          ) : (
            <PlaceholderMessage message="No data to display" />
          )}
        </div>
      )}
    </>
  );
};

export default AttendanceLogsUserRow;
