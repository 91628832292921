import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { formNames } from "../../../config";
import { updateSelectedNode } from "../../../redux/selectedNode/selectedNodeActions";
import { toggleForm } from "../../../utils/utilFunctions";
import { FormWrapper } from "../doorAddForm/DoorAddForm";
import { FieldInput, FieldLabel, InputGroup } from "../FormBase.styled";

const HierarchyViewForm = () => {
  const dimentions = useSelector((state) => state.dimentions);
  const nodeInfo = useSelector((state) => state.nodeInfo);
  const formState = useSelector((state) => state.formState);
  const nodes = useSelector((state) => state.nodes);
  const [rootNode, setRootNode] = useState(null);
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();

  const closeForm = () => {
    toggleForm(formState, history, location, dispatch, formNames.hierarchyViewForm);
  };

  useEffect(() => {
    let targetNode = null;
    nodes.forEach((n) => {
      if (nodeInfo.rootNodeId) {
        if (n.nodeId === nodeInfo.rootNodeId) {
          targetNode = [n];
        }
      } else if (n.nodeId === nodeInfo.nodeId) {
        targetNode = [n];
      }
    });
    setRootNode(targetNode);
  }, [nodeInfo]);

  return (
    <FormWrapper dimentions={dimentions} closeForm={closeForm}>
      <h6 className="font-weight-bold mb-4">{`${rootNode ? rootNode[0].name : ""}  Hierarchy`}</h6>
      <InputGroup dimentions={dimentions}>
        <FieldLabel dimentions={dimentions}></FieldLabel>
        <FieldInput>
          {rootNode
            ? rootNode.map((n) => (
                <Menu
                  name={n.name}
                  nodeId={n.nodeId}
                  node={n.children}
                  dispatch={dispatch}
                  closeForm={closeForm}
                />
              ))
            : null}
        </FieldInput>
      </InputGroup>
    </FormWrapper>
  );
};

export default HierarchyViewForm;

const Menu = ({ node, dispatch, name, nodeId, closeForm, children }) => {
  console.log({ node, name, nodeId });
  return (
    <div className="ml-3">
      <>
        <p
          className="m-0 my-2 cursor-pointer text-primary font-weight-bold"
          onClick={(e) => {
            dispatch(
              updateSelectedNode({
                name,
                nodeId,
                parentId: nodeId,
                children: node,
                expanded: false,
              })
            );
            closeForm();
          }}
        >
          {name}
        </p>
        {node && node.length
          ? node.map((t) => (
              <Menu
                name={t.name}
                nodeId={t.nodeId}
                node={t.children}
                dispatch={dispatch}
                closeForm={closeForm}
              />
            ))
          : null}
      </>
    </div>
  );
};
