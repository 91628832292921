import styled, { css } from "styled-components";

export const PageButtonContainer = styled.div`
    display:flex;
    flex-direction:row;
    align-items: flex-end;
    margin-top: 0.5rem;
    margin-right:0.5rem;
    justify-content: flex-end;
`;

export const PageButton = styled.button`
    font-size: 1.5 rem;
    width: 2rem;
    height: 2rem;
    ${(props)=>
        props.selected?
        css`
        color:white;
        font-weight: bold;
        border-style:groove;
        background-color: blue;
        `:
        css`
        color:black;
        background-color:#dcdcdc;`        
    }
`;