import styled, { css } from "styled-components";
import { breakPointWidth } from "../../../config";

export const ZoneLogsUserRowContainer = styled.div`
  ${(props) =>
    props.dimentions.width > breakPointWidth
      ? css`
          box-shadow: 0px 0.2rem 0.2rem 0.2rem rgba(0, 0, 0, 0.06);
          border: 0.5px solid #e8e8e8;
          border-radius: 5px;
          height : 35vh;
        `
      : css``}
  margin-bottom: 20px;
`;

export const ZoneLogsUserRowStyle = styled.div`
  display: grid;
  grid-row-gap: 0.5rem;
  grid-column-gap: 0.2rem;
  padding: 0.5rem 1rem;
  grid-template-columns: 1fr 2fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-areas: "sn nameAvatar accessType type time door temperature revokeButton";

  ${(props) =>
    props.active
      ? css`
          background-image: linear-gradient(91deg, #fe6dad -3%, #fc9f35);
        `
      : css``}
  ${(props) =>
    props.isHead
      ? css`
          font-weight: bold;
        `
      : css`
          :hover {
            color: white;
            background-image: linear-gradient(91deg, #fe6dad -3%, #fc9f35);
          }
        `}
`;

export const Sn = styled.div`
  display: flex;
  grid-area: "sn";
  justify-content: center;
  align-items: center;
  text-align: center;
`;
export const NameAvatar = styled.div`
  grid-area: "nameAvatar";
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`;
export const AccessType = styled.div`
  grid-area: "accesType";
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`;
export const Type = styled.div`
  grid-area: "type";
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`;
export const Time = styled.div`
  grid-area: "time";
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`;
export const Door = styled.div`
  grid-area: "door";
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

export const Temperature = styled.div`
  grid-area: "temperature";
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

export const Button = styled.button`
  grid-area: "button";
  background-color: transparent;
  border: 1px solid white;
  outline: none;
  color: white;
  justify-self: center;
  align-self: center;
  cursor: pointer;
  font-size: 0.8rem;
  padding: 0.2rem 1rem;
  border-radius: 20px;
  :hover {
    opacity: 0.5;
    color: black;
    border: 1px solid black;
  }
`;

export const ZoneLogsUserRowMobileWrapper = styled.div`
  padding: 1rem 1rem;
  border: 0.5px solid #c1c1c1;
  border-radius: 10px;
  margin-bottom: 20px;
  ${(props)=>
    props.disabled?
    css`opacity: 0.5;
    background: linear-gradient(0deg, #101010, #191919);
    pointer-events:initial;
    color:white;`:
    css``
  }
`;

export const ZoneLogsUserRowMobileView = styled.div`
  display: grid;
  grid-row-gap: 0.5rem;
  grid-column-gap: 0.2rem;
  grid-template-columns: 1fr 1fr;
`;

export const FieldLabelMobile = styled.p`
  color: rgba(161, 161, 161, 1);
  font-weight: 700;
  font-size: 0.8rem;
  margin: 0 0;
`;

export const FieldValueMobile = styled.p`
  font-weight: 400;
  font-size: 0.9rem;
  margin: 0 0;
  ${(props)=>
  props.disabled?
  css``:
  css
  `color: rgba(67, 66, 75, 1);`
}
`;

export const ButtonMobile = styled.button`
  background-color: ${(props) =>
    props.type === "info"
      ? "#49EECC;"
      : props.type === "disabled"
      ? "rgba(160, 159, 158, 0.5);"
      : "rgba(249, 106, 106, 1);"}

  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.15);
  border-radius: 25px;
  outline: none;
  color: white;
  justify-self: center;
  align-self: center;
  cursor: pointer;
  font-size: 1rem;
  font-weight : bold;
  padding: 0.3rem 1.8rem;
  border : 0px;
`;
