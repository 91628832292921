import styled, { css } from "styled-components";
import { breakPointWidth } from "../../config";

export const SideNav = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${(props) =>
    props.dimentions.width > breakPointWidth ? "3rem 0;" : "1rem 0;"};
`;

export const SideNavItem = styled.div`
  display: grid;
  grid-template-columns: 2fr 10fr;
  grid-template-rows: 50px;
  grid-template-areas: "icon title";
`;

export const SideNavItemLogo = styled.div`
  ${(props) =>
    props.active
      ? css`
          color: white;
          justify-self: center;
          align-self: center;
          background-color: #43424b;
          border-radius: 3px;
          grid-area: "logo";
          padding: 0.3rem 0.5rem;
        `
      : css`
          justify-self: center;
          align-self: center;
          border-radius: 6px;
          background-color: #ffffff;
          grid-area: "logo";
          padding: 0.5rem 0.5rem;
        `}
`;

export const SideNavItemTitle = styled.p`
  color: ${(props) => (props.active ? "#43424b" : "#a6a9b7")};
  font-size: 14px;
  align-self: center;
  margin-bottom: 0px;
  grid-area: "title";
  margin-left: 1rem;
  text-align: left;
`;
