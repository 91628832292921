import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useDispatch, useSelector } from "react-redux";
import { Link, Redirect, useHistory, useLocation } from "react-router-dom";
import { isAdmin, parseQueryParams } from "../../utils/utilFunctions";
import { navList, restrictedNavList } from "../configs";
import { detectDeviceType } from "../../utils/utilFunctions";
import { updateUser } from "../../redux/user/userActions";
const Navigation = () => {
  const [cookies, setCookie, removeCookie] = useCookies(["AT"]);
  const [nadmin, setnadmin] = useState("xxyyzz");
  const user = useSelector((state) => state.user);
  const history = useHistory();
  const dispatch = useDispatch();
  const nodes = useSelector((state) => state.nodes);
  const nodeInfo = useSelector((state) => state.nodeInfo);
  const selectedNode = useSelector((state) => state.selectedNode);
  const location = useLocation();
  const selectedNodeHierarchy = useSelector((state) => state.selectedNodeHierarchy);

  return (
    <>
      {location.search.length && parseQueryParams(location.search).platformRestricted ? (
        <div className="alert alert-danger" role="alert">
          {detectDeviceType()} support is not available in the Basic tier of SecurePass.
          <br />
          Kindly upgrade to Advanced Tier to get access to your Dashboard on both Desktop & Mobile.
          <br />
          To upgrade, kindly contact Customer Support at -
          <br />
          <span className="font-weight-bold">Email : </span>
          <a href="mailto:support@singularity.services" style={{ color: "inherit" }}>
            support@singularity.services
          </a>
          {" " + "," + " "}
          <span className="font-weight-bold">Phone : </span>
          <span>080 47188188</span>
        </div>
      ) : (
        <div className="text-center d-flex justify-content-center" style={{ width: "100%" }}>
          <div id="navigation" className="mt-5 pt-5 ">
            {user && user.roles ? (
              isAdmin(user, nodeInfo) ? (
                navList.map(({ title, image, path }) => {
                  if (title === "Visitor") {
                    return user.visitorPermissions[selectedNode.nodeId] ? (
                      <NavItem title={title} image={image} path={path} />
                    ) : null;
                  } else {
                    return <NavItem title={title} image={image} path={path} />;
                  }
                })
              ) : (
                restrictedNavList.map(({ title, image, path }) => {
                  if (title === "Visitor") {
                    return user.visitorPermissions[selectedNode.nodeId] ? (
                      <NavItem title={title} image={image} path={path} />
                    ) : null;
                  } else {
                    return <NavItem title={title} image={image} path={path} />;
                  }
                })
              )
            ) : (
              <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default Navigation;

const NavItem = ({ title, image, path }) => {
  return (
    <Link to={path} style={{ textDecoration: "none" }}>
      <div className="nav-grid-item">
        <div className="nav-grid-image">
          <img
            className="nav-icon-image"
            style={{ height: "80px", width: "80px" }}
            src={image}
            alt={title}
          />
        </div>
        <h6 className="linkText" style={{ margin: "10px 0px" }}>
          {title}
        </h6>
      </div>
    </Link>
  );
};
