import { convetToLocaleDateString } from "./utilFunctions";
const isOps = (i) => (i === "5f64acff28dccf00687bea0c" ? true : false);

export const sendCustomPageView = (user_id, node, node_category, role) => {
  window.dataLayer.push({
    user_id,
    ops: isOps(user_id),
    node,
    node_category,
    role,
    event: "custom_page_view",
  });
};
export const sendLogFileDownloadEvent = (user_id, node, node_category, role, duration) => {
  window.dataLayer.push({
    user_id,
    ops : isOps(user_id),
    node,
    node_category,
    role,
    duration,
    event: "attendance_log_download",
  });
};
export const sendAttendanceReportDownloadEvent = (
  user_id,
  node,
  node_category,
  role,
  duration,
  department_filter,
  user_type_filter,
  include_child,
  format
) => {
  let in_time = false;
  let out_time = false;
  let first_in_to_last_out = false;
  let total_in_time = false;
  let over_time = false;
  let day_summary = false;
  let total_in_time_sum = false;
  let total_break_time_sum = false;
  let total_present = false;
  let total_absent = false;
  let sheet_count = format.length;
  format.forEach((f) => {
    if (f.dailyReports.inTime) {
      in_time = true;
    }
    if (f.dailyReports.outTime) {
      out_time = true;
    }
    if (f.dailyReports.firstInToLastOut) {
      first_in_to_last_out = true;
    }
    if (f.dailyReports.totalInTime) {
      total_in_time = true;
    }
    if (f.dailyReports.overtime) {
      over_time = true;
    }
    if (f.dailyReports.daySummary) {
      day_summary = true;
    }
    if (f.calculations.totalInTimeSum) {
      total_in_time_sum = true;
    }
    if (f.calculations.totalBreakTimeSum) {
      total_break_time_sum = true;
    }
    if (f.calculations.totalPresent) {
      total_present = true;
    }
    if (f.calculations.totalAbsent) {
      total_absent = true;
    }
  });

  window.dataLayer.push({
    user_id,
    ops: isOps(user_id),
    node,
    node_category,
    role,
    duration,
    department_filter,
    user_type_filter,
    include_child,
    sheet_count,
    in_time,
    out_time,
    first_in_to_last_out,
    total_in_time,
    over_time,
    day_summary,
    total_in_time_sum,
    total_break_time_sum,
    total_present,
    total_absent,
    event: "attendance_report_download",
  });
};

export const sendLogViewEvent = (user_id, node, node_category, role, date_of_log) => {
  window.dataLayer.push({
    user_id,
    ops: isOps(user_id),
    node,
    node_category,
    role,
    date_of_log: convetToLocaleDateString(parseInt(date_of_log / 1000)),
    event: "attendance_log_view",
  });
};

export const sendNotificationClickEvent = (user_id, node, node_category, role) => {
  window.dataLayer.push({
    user_id,
    ops: isOps(user_id),
    node,
    node_category,
    role,
    event: "notification_clicked",
  });
};
