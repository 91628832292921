import styled from "styled-components";

export const TabRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
`;

export const Tab = styled.div`
  background-color: #f4f4f4;
  border-radius: 4px 4px 0px 0px;
  color: #4c515d;
  flex: 0 1 auto;
  min-width: 0;
  display: flex;
  cursor: pointer;
  overflow: hidden;
  font-weight: bold;
  font-size: 1rem;
  white-space: nowrap;
  justify-content: start;
  align-items: center;
  margin-right: 3px;
  padding: 5px 10px;
`;
export const AddButtonTab = styled.div`
  background-color: #f4f4f4;
  border-radius: 4px 4px 0px 0px;
  color: #4c515d;
  flex: 0 0 auto;
  display: flex;
  cursor: pointer;
  overflow: hidden;
  font-weight: bold;
  font-size: 1rem;
  white-space: nowrap;
  justify-content: start;
  align-items: center;
  padding: 5px 10px;
`;

export const CheckBoxContainer = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  padding: 10px 0px;
`;

export const CheckBox = styled.input`
  width: 20px;
  height: 20px;
  margin-left: 10px;
`;

export const LockIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1rem;
`;

export const CheckBoxLabel = styled.label`
  font-size: 1.2rem;
  color: #737373;
  margin: 0px 0px 0px 15px;
`;

export const SectionHeading = styled.h6`
  font-weight: 700;
  color: #4c515d;
  text-transform: uppercase;
  margin-bottom: 20px;
`;

export const DownloadButton = styled.button`
  max-width: 500px;
  padding: 1rem 3rem;

  color: #ffffff;
  font-size: 1.6rem;
  border: 0px;
  border-radius: 15px;
  background-color: #57d464;
  :focus {
    outline: none;
  }
`;
