import React, { useEffect, useState } from "react";
import styled from "styled-components";

import AccessDays from "../AccessDays";
import { FormWrapper } from "../doorAddForm/DoorAddForm";
import DatePicker from "react-datepicker";
import { FieldInput, FieldLabel, InputBox, InputGroup } from "../FormBase.styled";
import { SubContainer } from "../../selectBox/customSelect/customSelect.styled";
import { BsFillTriangleFill } from "react-icons/bs";
import AccessTime from "../AccessTime";
import { SolidButton } from "../../buttons/solidButton.styled";
import { useDispatch, useSelector } from "react-redux";
import { convertTime } from "../../../utils/utilFunctions";
import { useCookies } from "react-cookie";
import { useHistory } from "react-router-dom";
import NotificationManager from "react-notifications/lib/NotificationManager";
import { visitorAddAPI } from "../../../utils/requests";
import { apiEndPoints } from "../../../config";
import ButtonState from "../../ButtonState/ButtonState";

const VisitorAddForm = ({ setShowAddVisitorForm, formName }) => {
  const [cookies, setCookie, removeCookie] = useCookies(["AT"]);
  let history = useHistory();
  const dispatch = useDispatch();

  const [expanded, setExpanded] = useState({});
  const [accessPermission, setAccessPermission] = useState({});
  const [checkedNodes, setCheckedNodes] = useState({});
  const nodes = useSelector((state) => state.selectedNodeHierarchy);
  const dimentions = useSelector((state) => state.dimentions);
  const apiStatus = useSelector((state) => state.apiStatus);
  const selectedNode = useSelector((state) => state.selectedNode);
  const user = useSelector((state) => state.user);
  const [formData, setFormData] = useState({
    nodeId: selectedNode.nodeId,
    firstName: "",
    lastName: "",
    purpose: "",
    companyName: "",
    startTime: new Date(),
    endTime: convertTime(new Date().toString(), "end"),
  });

  const updateFormData = (e) => {
    if (e.target.name === "phnOrEmail") {
      let temp = { ...formData };
      if (e.target.value.includes("@")) {
        if (temp.mobileNo) delete temp.mobileNo;
        temp.emailId = e.target.value;
      } else if (!isNaN(parseInt(e.target.value))) {
        if (temp.emailId) delete temp.emailId;
        temp.mobileNo = e.target.value;
      }
      setFormData({ ...temp });
    } else {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };

  const initiallySetExpanded = (nodes, acc) => {
    let temp = acc ? acc : {};

    if (nodes.length !== undefined) {
      nodes.forEach((obj) => (temp[obj.nodeId] = false));
    }
    if (nodes.length !== undefined) {
      nodes.forEach((obj) => obj.children.forEach((nObj) => initiallySetExpanded(nObj, temp)));
    }
    return temp;
  };

  const updateStartTime = (date) => {
    if (
      new Date(Date.parse(`${date}`)).getFullYear() > new Date(Date.now()).getFullYear() ||
      new Date(Date.now()).getMonth() < new Date(Date.parse(`${date}`)).getMonth() ||
      new Date(Date.now()).getDate() < new Date(Date.parse(`${date}`)).getDate()
    ) {
      console.log("date is greater than today");
      setFormData({
        ...formData,
        startTime: convertTime(date.toString(), "start"),
      });
    } else {
      setFormData({
        ...formData,
        startTime: new Date(),
      });
    }
  };

  useEffect(() => {
    if (nodes) {
      setExpanded(initiallySetExpanded(nodes));
    }
    // eslint-disable-next-line
  }, [nodes]);

  const closeForm = () => {
    setShowAddVisitorForm(dispatch, formName);
  };
  return (
    <FormWrapper dimentions={dimentions} closeForm={closeForm}>
      <h6 className="font-weight-bold mb-4">Add New Visitor</h6>

      <InputGroup dimentions={dimentions}>
        <FieldLabel dimentions={dimentions}>First Name</FieldLabel>
        <FieldInput>
          <InputBox
            name="firstName"
            // placeholder="Enter visitor's first name"
            value={formData.firstName}
            onChange={updateFormData}
          />
        </FieldInput>
      </InputGroup>

      <InputGroup dimentions={dimentions}>
        <FieldLabel dimentions={dimentions}>Last Name</FieldLabel>
        <FieldInput>
          <InputBox
            name="lastName"
            // placeholder="Enter visitor's last name"
            value={formData.lastName}
            onChange={updateFormData}
          />
        </FieldInput>
      </InputGroup>

      <InputGroup dimentions={dimentions}>
        <FieldLabel dimentions={dimentions}>Phone or Email</FieldLabel>
        <FieldInput>
          <InputBox
            onChange={updateFormData}
            name="phnOrEmail"
            // placeholder="Phone or email"
          />
        </FieldInput>
      </InputGroup>
      <InputGroup dimentions={dimentions}>
        <FieldLabel dimentions={dimentions}>Start Date</FieldLabel>
        <FieldInput>
          <DatePicker
            dateFormat="dd/MM/yyyy"
            minDate={new Date()}
            selected={formData.startTime}
            onChange={(date) => updateStartTime(date)}
          />
        </FieldInput>
      </InputGroup>
      <InputGroup dimentions={dimentions}>
        <FieldLabel dimentions={dimentions}>End Date</FieldLabel>
        <FieldInput>
          <DatePicker
            dateFormat="dd/MM/yyyy"
            selected={formData.endTime}
            minDate={new Date()}
            onChange={(date) =>
              setFormData({
                ...formData,
                endTime: convertTime(date.toString(), "end"),
              })
            }
          />
        </FieldInput>
      </InputGroup>
      <InputGroup dimentions={dimentions}>
        <FieldLabel dimentions={dimentions}>Company name</FieldLabel>
        <FieldInput>
          <InputBox
            name="companyName"
            // placeholder="Company name"
            onChange={updateFormData}
          />
        </FieldInput>
      </InputGroup>
      <InputGroup dimentions={dimentions}>
        <FieldLabel dimentions={dimentions}>Purpose</FieldLabel>
        <FieldInput>
          <InputBox
            name="purpose"
            // placeholder="Enter reason for visit"
            onChange={updateFormData}
          />
        </FieldInput>
      </InputGroup>
      <InputGroup dimentions={dimentions}>
        <FieldLabel dimentions={dimentions}>Permission</FieldLabel>
        <FieldInput border={true}>
          {nodes
            ? nodes.map((node) => (
                <SubMenu
                  key={node.nodeId}
                  checkedNodes={checkedNodes}
                  setCheckedNodes={setCheckedNodes}
                  name={node.name}
                  nodeId={node.nodeId}
                  hierarchy={node.children}
                  expanded={expanded}
                  setExpanded={setExpanded}
                  accessPermission={accessPermission}
                  setAccessPermission={setAccessPermission}
                />
              ))
            : null}
        </FieldInput>
      </InputGroup>
      <InputGroup dimentions={dimentions}>
        <FieldLabel dimentions={dimentions}></FieldLabel>
        <FieldInput submitButton={true}>
          <SolidButton
            submit={true}
            onClick={(e) =>
              visitorAddAPI(
                user,
                dispatch,
                NotificationManager,
                removeCookie,
                history,
                checkedNodes,
                formData,
                closeForm,
                selectedNode
              )
            }
          >
            <ButtonState loading={apiStatus[apiEndPoints.visitorAddAPI]} innerText={"Add"} />
          </SolidButton>
        </FieldInput>
      </InputGroup>
    </FormWrapper>
  );
};

export default VisitorAddForm;

const SubMenu = ({
  hierarchy,
  checkedNodes,
  setCheckedNodes,
  name,
  parentId,
  nodeId,
  expanded,
  setExpanded,
  accessPermission,
  setAccessPermission,
}) => {
  const handleCheck = (e) => {
    if (e.target.checked) {
      setCheckedNodes({
        ...checkedNodes,
        [nodeId]: {
          ...checkedNodes[nodeId],
          daysofWeek: [false, false, false, false, false, false, false],
          startTimeInDay: "10:00",
          endTimeInDay: "10:00",
        },
      });
    } else {
      let temp = { ...checkedNodes };
      delete temp[nodeId].daysofWeek;
      delete temp[nodeId].startTimeInDay;
      delete temp[nodeId].endTimeInDay;
      setCheckedNodes({ ...temp });
    }
  };

  const handleNodeCheck = (nodeId) => {
    let temp = { ...checkedNodes };
    if (temp && Object.keys(temp).includes(nodeId)) {
      delete temp[nodeId];
      setCheckedNodes({ ...temp });
    } else {
      temp = {
        ...temp,
        [nodeId]: {
          nodeId,
        },
      };
      setCheckedNodes({ ...temp });
    }
  };

  const setTime = (e, field) => {
    if (e === true) {
      setCheckedNodes({
        ...checkedNodes,
        [nodeId]: {
          ...checkedNodes[nodeId],
          startTimeInDay: "00:00",
          endTimeInDay: "24:00",
        },
      });
    } else if (e === false) {
      setCheckedNodes({
        ...checkedNodes,
        [nodeId]: {
          ...checkedNodes[nodeId],
          startTimeInDay: "09:00",
          endTimeInDay: "21:00",
        },
      });
    } else {
      setCheckedNodes({
        ...checkedNodes,
        [nodeId]: {
          ...checkedNodes[nodeId],
          [field]: e,
        },
      });
    }
  };

  const handleDaysChange = (index, nodeId, selectAll, clearAll) => {
    let tempDays = [...checkedNodes[nodeId].daysofWeek];
    tempDays[index] = !tempDays[index];
    if (selectAll) {
      tempDays = tempDays.map((i) => true);
    }
    if (clearAll) {
      tempDays = tempDays.map((i) => false);
    }
    setCheckedNodes({
      ...checkedNodes,
      [nodeId]: { ...checkedNodes[nodeId], daysofWeek: [...tempDays] },
    });
  };

  return (
    <div>
      <InputGroup>
        <FieldLabel>
          <ArrowContainer
            onClick={(e) => {
              setExpanded({ ...expanded, [nodeId]: !expanded[nodeId] });
            }}
          >
            {hierarchy.length ? (
              <Arrow className="m-0 p-0 mr-2" expanded={nodeId === "" ? true : expanded[nodeId]}>
                <BsFillTriangleFill />
              </Arrow>
            ) : null}
            <input
              type="checkbox"
              className="mr-2"
              onClick={(e) => {
                e.stopPropagation();
                handleNodeCheck(nodeId);
              }}
            />
            <small className="font-weight-bold">{name}</small>
          </ArrowContainer>
        </FieldLabel>
        {Object.keys(checkedNodes).includes(nodeId) ? (
          <FieldInput>
            <div style={{ display: "flex", alignItems: "center" }}>
              <input className="m-0" type="checkbox" onChange={handleCheck} />
              <span className="ml-2">Access Permission</span>
            </div>
            {checkedNodes[nodeId] && checkedNodes[nodeId].daysofWeek ? (
              <>
                <AccessDays
                  daysofWeek={checkedNodes[nodeId].daysofWeek}
                  onClick={handleDaysChange}
                  nodeId={nodeId}
                />
                <AccessTime onChange={setTime} time={checkedNodes[nodeId]} />
              </>
            ) : null}
          </FieldInput>
        ) : null}
      </InputGroup>
      {/* {Object.keys(checkedNodes).includes(nodeId) ? (
        <>
          <InputGroup noMargin={true}>
            <FieldLabel></FieldLabel>
            <FieldInput>
              <div style={{ display: "flex", alignItems: "center" }}>
                <input
                  className="m-0"
                  type="checkbox"
                  checked={checkedNodes[nodeId].visitorManagement}
                  onChange={(e) =>
                    setCheckedNodes({
                      ...checkedNodes,
                      [nodeId]: {
                        ...checkedNodes[nodeId],
                        visitorManagement: e.target.checked,
                      },
                    })
                  }
                />
                <span className="ml-2">Visitor permission</span>
              </div>
            </FieldInput>
          </InputGroup>
          <InputGroup noMargin={true}>
            <FieldLabel></FieldLabel>
            <FieldInput>
              <div style={{ display: "flex", alignItems: "center" }}>
                <input
                  className="m-0"
                  type="checkbox"
                  checked={checkedNodes[nodeId].CRB}
                  onChange={(e) =>
                    setCheckedNodes({
                      ...checkedNodes,
                      [nodeId]: {
                        ...checkedNodes[nodeId],
                        CRB: e.target.checked,
                      },
                    })
                  }
                />
                <span className="ml-2">CRB</span>
              </div>
            </FieldInput>
          </InputGroup>{" "}
        </>
      ) : null} */}

      {hierarchy.map((nodes) => {
        return (
          <SubContainer key={nodes.nodeId} expanded={nodeId === "" ? true : expanded[nodeId]}>
            {nodes.hierarchy ? (
              <SubMenu
                name={nodes.name}
                nodeId={nodes.nodeId}
                hierarchy={nodes.hierarchy}
                expanded={expanded}
                setExpanded={setExpanded}
                checkedNodes={checkedNodes}
                setCheckedNodes={setCheckedNodes}
                accessPermission={accessPermission}
                setAccessPermission={setAccessPermission}
              />
            ) : null}
          </SubContainer>
        );
      })}
    </div>
  );
};

export const ArrowContainer = styled.div`
  display: flex;
  align-items: center;
  font-weight: bold;
`;

export const Arrow = styled.div`
  font-size: 0.7rem;
  transform: ${(props) => (props.expanded ? "rotate(180deg)" : "rotate(90deg)")};
`;
