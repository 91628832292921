import React, { useEffect, useState } from "react";
import { SolidButton } from "../components/buttons/solidButton.styled";
import { formNames } from "../config";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useCookies } from "react-cookie";
import { useLocation } from "react-router-dom";
import { nullChecker, toggleForm } from "../utils/utilFunctions";
import { fetchAppDetails, fetchOauthApps } from "../utils/requests";
import OauthAppLogo from "../assets/OauthApp.png";
import NotificationManager from "react-notifications/lib/NotificationManager";
import { Card } from "../components/integrations/integrationsView/integrationView.styled";

const DeveloperPage = () => {
  const formState = useSelector((state) => state.formState);
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const selectedNode = useSelector((state) => state.selectedNode);
  const user = useSelector((state) => state.user);
  const [apps, setAppData] = useState([]);
  const [cookies, setCookie, removeCookie] = useCookies(["AT"]);
  const getOauthAppsList = async () => {
    let result = await fetchOauthApps(user, dispatch, NotificationManager, removeCookie, history);
    if (result.success) {
      setAppData(result.apps);
    }
  };
  useEffect(() => {
    getOauthAppsList();
  }, [formState[formNames.addOauthAppForm]]);

  const editOauthApp = async (clientId) => {
    //first get the app details and then toggle the form with data prefilled
    let result = await fetchAppDetails(
      user,
      dispatch,
      NotificationManager,
      removeCookie,
      history,
      clientId,
      selectedNode.nodeId
    );
    if (result.success) {
      toggleForm(formState, history, location, dispatch, formNames.addOauthAppForm, result);
    } else {
      //error already thrown while eveluating response from request
    }
  };

  return (
    <>
      <div className="d-flex flex-row justify-content-between my-2">
        <h5 className="font-weight-bold">Oauth Apps</h5>
        {/* <SolidButton
          style={{ marginRight: "50px" }}
          onClick={(e) => toggleForm(formState, history, location, dispatch, formNames.addOauthAppForm)}
        >
          Create Oauth App
        </SolidButton> */}
      </div>
      <div className="row" style={{ maxHeight: "80vh", overflow: "scroll" }}>
        {apps.map((value, index) => (
          <div key={index} className="col-md-2 col-lg-2 col-sm-6 col-xs-6">
            <Card>
              <img src={OauthAppLogo} style={{ padding: "40px", paddingBottom: "20px" }} />
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  fontSize: "1rem",
                }}
                className="font-weight-bold"
              >
                {value.name}
              </div>
              <p className="text-center pt-2">{nullChecker(value.description)}</p>
              <SolidButton className="ml-0" onClick={(e) => editOauthApp(value.clientId)}>
                Edit
              </SolidButton>
            </Card>
          </div>
        ))}
      </div>
    </>
  );
};

export default DeveloperPage;
