import { UPDATE_SIDEBAR_STATUS } from "../actionTypes";

const sidebarStatusReducer = (
  sidebarStatus = {
    left: false,
    right: false,
  },
  action
) => {
  if (action.type === UPDATE_SIDEBAR_STATUS) {
    return action.payload.target === "reset"
      ? (sidebarStatus = {
          left: false,
          right: false,
        })
      : (sidebarStatus = {
          ...sidebarStatus,
          [action.payload.target]: !sidebarStatus[action.payload.target],
        });
  }

  return sidebarStatus;
};

export default sidebarStatusReducer;
