import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import NotificationManager from "react-notifications/lib/NotificationManager";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { formNames } from "../../../config";
import { getAllMemberships } from "../../../utils/requests";
import { toggleForm } from "../../../utils/utilFunctions";
import { FormWrapper } from "../doorAddForm/DoorAddForm";
import { FieldInput, FieldLabel, InputGroup, SelectBox } from "../FormBase.styled";
import BulkDeleteMembershipsForm from "./BulkDeleteMembershipsForm";
import BulkRfidAssignForm from "./BulkRfidAssignForm";
import BulkUpdateAccessForm from "./BulkUpdateAccessForm";

const BulkOperationForm = () => {
  const [cookies, setCookie, removeCookie] = useCookies(["AT"]);
  const dimentions = useSelector((state) => state.dimentions);
  const formState = useSelector((state) => state.formState);
  const [allMemberships, setAllMemberships] = useState(null);
  const nodeInfo = useSelector((state) => state.nodeInfo);
  const history = useHistory();
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const location = useLocation();
  const [formToShow, setFormToShow] = useState(bulkOperationsMenu.rfidForm);

  const closeForm = () => {
    toggleForm(formState, history, location, dispatch, formNames.bulkOperationForm);
  };

  useEffect(() => {
    getAllMemberships(
      user,
      dispatch,
      NotificationManager,
      removeCookie,
      history,
      nodeInfo.nodeId,
      setAllMemberships,
      false
    );
  }, []);

  return (
    <FormWrapper dimentions={dimentions} closeForm={closeForm}>
      <h6 className="font-weight-bold mb-4">Bulk Operations</h6>
      <InputGroup dimentions={dimentions}>
        <FieldLabel dimentions={dimentions}></FieldLabel>
        <FieldInput>
          <SelectBox onChange={(e) => setFormToShow(e.target.value)}>
            {Object.entries(bulkOperationsMenu).map(([key, val]) => (
              <option key={key} value={val}>
                {val}
              </option>
            ))}
          </SelectBox>
        </FieldInput>
      </InputGroup>
      {formToShow === bulkOperationsMenu.rfidForm ? (
        <BulkRfidAssignForm allMemberships={allMemberships} />
      ) : formToShow === bulkOperationsMenu.bulkDeleteMemberships ? (
        <BulkDeleteMembershipsForm allMemberships={allMemberships} />
      ) : formToShow === bulkOperationsMenu.bulkUpdateAccess ? (
        <BulkUpdateAccessForm allMemberships={allMemberships} />
      ) : (
        <>Please Select an option from above menu</>
      )}
    </FormWrapper>
  );
};

export default BulkOperationForm;

const bulkOperationsMenu = {
  rfidForm: "Bulk Assign RFIDs",
  bulkDeleteMemberships: "Bulk Delete Membership",
  bulkUpdateAccess: "Bulk Update Access",
};
