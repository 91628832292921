import React, { useEffect, useState } from "react";
import { MdCheckCircle } from "react-icons/md";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import Memberships from "../components/RFIDassignment/Memberships";
import NodeList from "../components/RFIDassignment/NodeList";
import { isAdmin, parseQueryParams } from "../utils/utilFunctions";

const AssignRFID = () => {
  const [params, setParams] = useState({});
  const location = useLocation();
  const user = useSelector((state) => state.user);
  const nodeInfo = useSelector((state) => state.nodeInfo);
  const nodes = useSelector((state) => state.nodes);
  useEffect(() => {
    if (location.search.length) {
      setParams(parseQueryParams(location.search));
    }
  }, [location.search]);

  return (
    <div className="mt-5">
      {!user && !nodes && !params.RFCardQRCode ? (
        <div className="spinner-border" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      ) : params.RFCardQRCode && !params.nodeId ? (
        <NodeList nodes={nodes} params={params} />
      ) : params.RFCardQRCode && params.nodeId && !params.assigned ? (
        <Memberships params={params} />
      ) : (
        <div className="text-center">
          <div className="ml-auto mr-auto">
            <div className="col-12 mt-5 pt-5">
              <MdCheckCircle style={{ fontSize: "100px", color: "green" }} />
              <h5 className="mt-5">
                RFID assigned Successfully
                <br />
                please scan another RF CARD
              </h5>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AssignRFID;
