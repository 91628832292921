import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { apiEndPoints, formNames } from "../../../config";
import { SolidButton } from "../../buttons/solidButton.styled";
import {
  InputBox,
  InputGroup,
  FieldLabel,
  FieldInput,
} from "../FormBase.styled";

import { useCookies } from "react-cookie";
import { useHistory, useLocation } from "react-router-dom";
import { toggleForm } from "../../../utils/utilFunctions";
import { FormWrapper } from "../doorAddForm/DoorAddForm";
import NotificationManager from "react-notifications/lib/NotificationManager";
import { updateNodeInfoAPI } from "../../../utils/requests";
import ButtonState from "../../ButtonState/ButtonState";

const NodeWifiUpdateForm = () => {
  const [cookies, setCookie, removeCookie] = useCookies(["AT"]);
  let history = useHistory();
  const location = useLocation()
  const dispatch = useDispatch();
  const dimentions = useSelector((state) => state.dimentions);
  const apiStatus = useSelector((state) => state.apiStatus);
  const nodeInfo = useSelector((state) => state.nodeInfo);
  const selectedNode = useSelector((state) => state.selectedNode);
  const user = useSelector((state) => state.user);
  const [ssid, setSsid] = useState("");
  const [password, setPassword] = useState("");
  const formState = useSelector((state) => state.formState);

  const closeForm = () => {
    toggleForm(
      formState,
      history,
      location,
      dispatch,
      formNames.wifiInfoUpdateForm
    );
  };

  useEffect(() => {
    if (nodeInfo.WiFiPassword && nodeInfo.WiFiSSID) {
      setSsid(nodeInfo.WiFiSSID);
      setPassword(nodeInfo.WiFiPassword);
    }
  }, []);

  return (
    <FormWrapper dimentions={dimentions} closeForm={closeForm}>
      <h6 className="font-weight-bold mb-4">Add WiFi Details</h6>
      <InputGroup dimentions={dimentions}>
        <FieldLabel dimentions={dimentions}>SSID</FieldLabel>
        <FieldInput>
          <InputBox value={ssid} onChange={(e) => setSsid(e.target.value)} />
        </FieldInput>
      </InputGroup>
      <InputGroup dimentions={dimentions}>
        <FieldLabel dimentions={dimentions}>Password</FieldLabel>
        <FieldInput>
          <InputBox type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
        </FieldInput>
      </InputGroup>
      <InputGroup dimentions={dimentions}>
        <FieldLabel dimentions={dimentions}></FieldLabel>
        <FieldInput submitButton={true}>
          <SolidButton
            submit={true}
            onClick={(e) =>
              updateNodeInfoAPI(
                user,
                dispatch,
                NotificationManager,
                removeCookie,
                history,
                selectedNode,
                {
                  WiFiSSID: ssid,
                  WiFiPassword: password,
                  nodeId: selectedNode.nodeId,
                },
                closeForm
              )
            }
          >
            <ButtonState loading={apiStatus[apiEndPoints.updateNodeInfoAPI]} innerText={"Add"} />
          </SolidButton>
        </FieldInput>
      </InputGroup>
    </FormWrapper>
  );
};

export default NodeWifiUpdateForm;
