import React from "react";
import QRCode from "qrcode.react";
import "../styles/dstyles.css";
import { createNotification, notificationType } from "../Services/utils";
import { NotificationManager } from "react-notifications";
import { cloudFrontOrigin } from "../../config";
const DeclarationSuccess = ({ declarationRes }) => {
  const downloadQRCode = () => {
    const canvas = document.getElementById("qr-gen");
    const pngUrl = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    let downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = `${"securepass-access-QR"}.png`;
    document.body.appendChild(downloadLink);
    downloadLink.click();

    document.body.removeChild(downloadLink);
    createNotification(
      NotificationManager,
      notificationType.success,
      "Downloading QR"
    );
  };
  return (
    <>
      <div
        id="Page2"
        style={{ display: "flex", backgroundColor: "white" }}
        className="page text-center"
      >
        <div className="pageHeading" id="page_heading">
          Your Access has been granted
        </div>
        {declarationRes.OACs ? (
          <>
            <img
              className="temp_scam"
              id="temp_scan"
              // alt="Request Sent"
              src={`${cloudFrontOrigin}temp_scan_cgqhiq.gif`}
              style={{ width: "200px", height: "auto" }}
            />
            <h5 style={{ textAlign: "center", margin: "10px 0px" }}>
              Please Scan your temperature after entering the OTP.
            </h5>

            <ol id="OTPList">
              <li className="heading">
                <p>Dir</p>
                <p>Door Name</p>
                <p>OTP</p>
              </li>
              {declarationRes.OACs.map((v, i) => (
                <li>
                  <p>{v.direction}</p>
                  <p>{v.doorName}</p>
                  <p>{v.OAC}#</p>
                </li>
              ))}
            </ol>
          </>
        ) : null}
        <img
          className="tick"
          id="tick"
          alt="Request Sent"
          src={`${cloudFrontOrigin}tick_ptsfyv.png`}
          style={{ width: "125px", height: "auto" }}
        />

        <h5
          className="declarationSuccessMessage"
          id="successMessage"
          style={{ textAlign: "center", margin: "10px 0px" }}
        >
          You have submitted the Self Declaration form
        </h5>

        <h3
          className="declarationSuccessMessage"
          style={{ textAlign: "center", margin: "0px 0px" }}
        >
          Thank You
        </h3>
        <h3
          id="declarationErrorMessage"
          style={{ textAlign: "center", margin: "0px 0px" }}
        >
          &nbsp;
        </h3>

        {declarationRes && declarationRes.offlineVerificationEnabled ? (
          <div id="qrwrapper pb-5">
            <h5 id="qrSaveMessage" style={{ display: "block" }}>
              please download the below QR code for verification at the
              premises.
            </h5>
            <QRCode
              id="qr-gen"
              value={declarationRes.data}
              size={290}
              level={"H"}
              includeMargin={true}
            />
            <div className="fixed-bottom bg-white py-1">
              <button
                className="bigButton"
                style={{ outline: "0" }}
                id="downloadButton"
                onClick={downloadQRCode}
              >
                Download
              </button>
            </div>
          </div>
        ) : (
          <div id="hideWhileOfflineVerificationEnabled">
            <p style={{ textAlign: "center" }} id="appDownload">
              For contactless entry, please download the app from Play Store or
              App Store.
            </p>
            <a
              id="downloadImage1"
              href="https://play.google.com/store/apps/details?id=services.singularity.securepass&amp;hl=en_IN"
              target="_blank"
              style={{ marginTop: "15px" }}
            >
              <img
                className="storeLink"
                alt="link to Android App"
                src={`${cloudFrontOrigin}google-play_uxf5ir.png`}
                style={{ width: "150px", height: "auto" }}
              />
            </a>
            <a
              id="downloadImage1"
              href="https://apps.apple.com/us/app/securepass-access-control/id1530070958"
              target="_blank"
              style={{ marginTop: "15px" }}
            >
              <img
                className="storeLink"
                id="appStoreLink"
                alt="link to iOS App"
                src={`${cloudFrontOrigin}app-store_sgzyyr.png`}
                style={{ width: "150px", height: "auto" }}
              />
            </a>
          </div>
        )}
      </div>
    </>
  );
};

export default DeclarationSuccess;
