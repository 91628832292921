import styled, { css } from "styled-components";

export const RowContainer = styled.div`
  border: 0.5px solid #e8e8e8;
  box-shadow: 0px 0.2rem 0.2rem 0.2rem rgba(0, 0, 0, 0.06);
  border-radius: 5px;
  margin-bottom: 20px;
  height: 75vh;
  position: relative;
`;

export const RowStyled = styled.div`
  display: grid;
  grid-row-gap: 0.5rem;
  grid-column-gap: 0.2rem;
  padding: 1rem 1rem;
  grid-template-columns: 4fr 4fr 4fr;
  grid-template-areas: "DeviceId DeviceSyncDate LogSyncDate";
  
  ${(props) =>
    props.active
      ? css`
          background-image: linear-gradient(91deg, #fe6dad -3%, #fc9f35);
        `
      : css``}
  ${(props) =>
    props.isHead
      ? css`
          font-weight: bold;
        `
      : css`
          :hover {
            color: white;
            background-image: linear-gradient(91deg, #fe6dad -3%, #fc9f35);
          }
        `}
`;

export const DeviceId = styled.div`
  grid-area: "DeviceId";
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const DeviceSyncDate = styled.div`
  grid-area: "DeviceSyncDate";
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const LogSyncDate = styled.div`
  grid-area: "LogSyncDate";
  display: flex;
  justify-content: center;
  align-items: center;
`;