import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { updateSelectedNode } from "../../redux/selectedNode/selectedNodeActions";
import LoadingSpinner from "../LoadingSpinner";

const NodeList = ({ nodes, params }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const [node, setNode] = useState(null);

  useEffect(() => {
    if (nodes && nodes.length === 1 && !params.nodeId) {
      setNode(nodes[0]);
    }
  }, [nodes]);

  useEffect(() => {
    if (node && !params.nodeId) {
      dispatch(
        updateSelectedNode({
          name: node.name,
          nodeId: node.nodeId,
          children: node.children,
          expanded: false,
          parentId: node.nodeId,
        })
      );
      window.location.href = `${window.location.origin}${location.pathname}${location.search}&nodeId=${node.nodeId}`;
    }
  }, [node]);

  return (
    <>
      {nodes && nodes.length ? (
        <div>
          {nodes.map((n) => (
            <h6
              className="font-weight-bold mb-3 cursor-pointer
          "
              onClick={(e) => setNode(n)}
            >
              {n.name}
            </h6>
          ))}
        </div>
      ) : (
        <LoadingSpinner />
      )}
    </>
  );
};

export default NodeList;
