import { UPDATE_NOTIFICATIONS, RESET_NOTIFICATIONS, SET_DEFAULT_NOTIFICATIONS } from "../actionTypes";

export const updateNotifications = (payload) => {
  return {
    type: UPDATE_NOTIFICATIONS,
    payload,
  };
};

export const resetNotifications = () => {
  return {
    type: RESET_NOTIFICATIONS,
  };
};

export const setDefaultNotifications = () => {
  return {
    type: SET_DEFAULT_NOTIFICATIONS,
  };
};
