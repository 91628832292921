import React from "react";
import { useCookies } from "react-cookie";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { cloudFrontOrigin } from "../../config";
import { logout } from "../../utils/logout";

const Header = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const [cookies, setCookie, removeCookie] = useCookies(["AT"]);
  return (
    <div
      className="fixed-top container-fluid d-flex justify-content-center"
      style={{ maxWidth: "500px" }}
    >
      <div
        className="d-flex align-items-center bg-white w-100 px-3 py-2 mb-2 justify-content-between"
        id="header"
      >
        <Link to="/">
          <img
            alt="Securepass"
            id="brandLogo"
            className="p-0 m-0"
            style={{ width: "100%" }}
            src={`${cloudFrontOrigin}SecurePass_b3mm6p_rnqlfl.jpg`}
          />
        </Link>
        {/* <div id="logoutButton"> */}
        {user && user.AT ? (
          <p
            id="logoutText"
            className="typeC p-0 m-0"
            onClick={(e) => logout(dispatch, removeCookie, history)}
          >
            Logout
          </p>
        ) : null}
      </div>
    </div>
  );
};

export default Header;
