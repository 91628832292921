import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { formNames } from "../../../config";
import { toggleForm } from "../../../utils/utilFunctions";
import ZoneRestrictionsCard from "../../zone/zoneCards/zoneRestrictionCard/ZoneRestrictionsCard";
import { FormWrapper } from "../doorAddForm/DoorAddForm";
import { FieldInput, FieldLabel, InputGroup } from "../FormBase.styled";

const NodeConfigForm = () => {
  const dimentions = useSelector((state) => state.dimentions);
  const dispatch = useDispatch();
  const formState = useSelector((state) => state.formState);
  let history = useHistory();
  const location = useLocation();
  const closeForm = () => {
    toggleForm(
      formState,
      history,
      location,
      dispatch,
      formNames.nodeConfigForm
    );
  };

  return (
    <FormWrapper dimentions={dimentions} closeForm={closeForm}>
      <h6 className="font-weight-bold mb-4">Zone Configuraton</h6>
      <InputGroup dimentions={dimentions}>
        <FieldLabel dimentions={dimentions}></FieldLabel>
        <FieldInput>
          <p className="text-danger my-3 text-center font-weight-bold">
            Warning : Please do not change the below device settings. Any
            changes will impact functioning of the device
          </p>
          <ZoneRestrictionsCard />
        </FieldInput>
      </InputGroup>
    </FormWrapper>
  );
};

export default NodeConfigForm;
