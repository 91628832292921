import React, { useEffect, useState } from "react";

import { Redirect, useHistory } from "react-router-dom";
import { useCookies } from "react-cookie";
import { get, post } from "../Services/apiService";
import {
  ATexpired,
  createNotification,
  formatDate,
  getQueryParams,
  notificationType,
} from "../Services/utils";
import { NotificationManager } from "react-notifications";
import Confirmation from "../Components/Confirmation";
import { useDispatch, useSelector } from "react-redux";
import { rootApi } from "../../config";
import { sanitizeReqBody } from "../../utils/utilFunctions";
import InviteForm from "../Components/InviteForm";
const permission = (nodeIds) => {
  let info = nodeIds.map((nodeId) => {
    return {
      nodeId,
      daysofWeek: [true, true, true, true, true, true, true],
      startTimeInDay: 0,
      endTimeInDay: 1339,
      CRB: false,
      visitorManagement: false,
    };
  });

  return JSON.stringify([...info]);
};

const Invite = () => {
  const [formData, setFormData] = useState({
    name: "",
    contact: "",
    accessInfo: "",
    nodeId: "",
  });

  const [filled, setFilled] = useState(false);
  const [nodes, setNodes] = useState([]);
  const [error, setError] = useState({});
  const [selectedNode, setSelectedNode] = useState(null);
  const [includeSelected, setIncludeSelected] = useState(true);
  const [selectedChildNodes, setSelectedChildNodes] = useState([]);
  const user = useSelector((state) => state.user);
  const [success, setSuccess] = useState(false);
  const [cookies, setCookie, removeCookie] = useCookies(["AT"]);
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [AT, setAT] = useState(null);
  const dispatch = useDispatch();
  const history = useHistory();

  const getNodeList = async () => {
    const url = `${rootApi}user/employeeNodes`;
    try {
      const response = await get(dispatch, url, AT);

      if (!response.data.success) {
        createNotification(NotificationManager, notificationType.error, response.data.error);
      } else {
        setNodes(response.data.nodes);
        if (getQueryParams(window.location.search).nodeId) {
          if (
            user.visitorPermissions &&
            user.visitorPermissions[getQueryParams(window.location.search).nodeId]
          ) {
            setSelectedNode(
              response.data.nodes.filter(
                (v) => v.nodeId === getQueryParams(window.location.search).nodeId
              )[0]
            );
          } else {
            window.location.pathname = "/apps";
          }
        }
      }
      ATexpired(response, dispatch, removeCookie, history);
    } catch (err) {
      throw err;
    }
  };

  const checkError = (e, idTxt) => {
    console.log(e.target.value);
    if (e.target.value.length !== 0) {
      setError({ ...error, [idTxt]: false });
    } else {
      setError({ ...error, [idTxt]: true });
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "name&Number") {
      setFormData({ ...formData, name: value[0], contact: value[1] });
    } else if (name === "endTime") {
      setEndTime(e.target.value);
    } else if (name === "startTime") {
      setStartTime(e.target.value);
    } else if (name === "slots") {
      setFormData({
        ...formData,
        startTime: JSON.parse(value).startTime,
        endTime: JSON.parse(value).endTime,
      });
    } else if (name === "purpose" && value === "Purpose of Visit") {
      setFormData({ ...formData, purpose: "" });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  useEffect(() => {
    setFormData({
      ...formData,
      startTime: new Date(startTime).getTime() / 1000,
      endTime: new Date(endTime).getTime() / 1000,
    });
  }, [startTime, endTime]);

  useEffect(() => {
    if (startTime) {
      let currentTime, endTime;
      currentTime = new Date(`${startTime}`);
      let offset = currentTime.getTimezoneOffset();
      console.log({ currentTime, startTime, offset });
      endTime = new Date(currentTime.getTime() - offset * 60000 + 86400000).toISOString().slice(0, 16);
      setEndTime(endTime);
    }
  }, [startTime]);

  useEffect(() => {
    if (user && user.AT) {
      setAT(user.AT);
    }
  }, [user]);

  useEffect(() => {
    if (selectedNode) {
      setFormData({
        ...formData,
        nodeId: selectedNode.nodeId,
        accessInfo: permission(
          includeSelected ? [selectedNode.nodeId, ...selectedChildNodes] : [...selectedChildNodes]
        ),
      });
    } else {
      setFormData({ ...formData, nodeId: "", accessInfo: "" });
    }
  }, [selectedNode, selectedChildNodes, includeSelected]);

  const handleSubmit = async () => {
    const url = `${rootApi}node/visitor/add`;
    let body = {
      ...formData,
    };
    if (body.name && body.name.length) {
      let n = body.name.split(" ");
      delete body.name;
      let ln = "";
      for (let i = 1; i < n.length; i++) {
        ln = ln + " " + n[i];
      }
      body.firstName = n[0];
      body.lastName = ln.length > 0 ? ln : "-";
    }
    if (body.contact.includes("@")) {
      let emailId = body.contact;
      delete body.contact;
      body.emailId = emailId;
    } else {
      let mobileNo = body.contact;
      delete body.contact;
      body.mobileNo = mobileNo;
    }

    if (!selectedNode.maxAddonGuests) {
      delete body.noOfGuests;
    }
    body = sanitizeReqBody(body);

    try {
      const response = await post(dispatch, url, body, AT);
      if (!response.data.success) {
        createNotification(NotificationManager, notificationType.error, response.data.error);
      } else {
        setSuccess(true);
      }
      ATexpired(response, dispatch, removeCookie, history);
    } catch (err) {
      console.log("invite Failed");
    }
  };

  useEffect(() => {
    if (AT) {
      getNodeList();
    }
  }, [AT]);

  return (
    <div className="row">
      {success ? <Redirect to="/vms/add/success" /> : null}
      {filled ? (
        <Confirmation
          handleSubmit={handleSubmit}
          setFilled={setFilled}
          formData={formData}
          formatDate={formatDate}
        />
      ) : (
        <InviteForm
          selectedChildNodes={selectedChildNodes}
          setSelectedChildNodes={setSelectedChildNodes}
          setStartTime={setStartTime}
          startTime={startTime}
          setEndTime={setEndTime}
          handleChange={handleChange}
          checkError={checkError}
          error={error}
          setError={setError}
          includeSelected={includeSelected}
          setIncludeSelected={setIncludeSelected}
          setSelectedNode={setSelectedNode}
          selectedNode={selectedNode}
          setFilled={setFilled}
          formatDate={formatDate}
          nodes={nodes}
          formData={formData}
          handleSubmit={handleSubmit}
        />
      )}
    </div>
  );
};

export default Invite;
