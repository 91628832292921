import { UPDATE_INTEGRATION_USER } from "../actionTypes";

const integrationUserEditReducer = (editedUser = {}, action) => {
  if (action.type === UPDATE_INTEGRATION_USER) {
    return (editedUser = action.payload);
  }

  return editedUser;
};

export default integrationUserEditReducer;
