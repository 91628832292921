import styled, { css } from "styled-components";

export const GroupRowContainer = styled.div`
  border: 0.5px solid #e8e8e8;
  box-shadow: 0px 0.2rem 0.2rem 0.2rem rgba(0, 0, 0, 0.06);
  border-radius: 5px;
  margin-bottom: 20px;
  height: 75vh;
  position: relative;
`;

export const GroupRowStyled = styled.div`
  display: grid;
  grid-row-gap: 0.5rem;
  grid-column-gap: 0.2rem;
  padding: 0.5rem 1rem;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-template-areas: "groupName inceptionDate people editButton removeButton";

  ${(props) =>
    props.active
      ? css`
          background-image: linear-gradient(91deg, #fe6dad -3%, #fc9f35);
        `
      : css``}
  ${(props) =>
    props.isHead
      ? css`
          font-weight: bold;
        `
      : css`
          :hover {
            color: white;
            background-image: linear-gradient(91deg, #fe6dad -3%, #fc9f35);
          }
        `}
`;

export const GroupName = styled.div`
  grid-area: "groupName";
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const People = styled.div`
  grid-area: "people";
  display: flex;
  justify-content: center;
  align-items: center;
  ${(props) =>
    props.isHead
      ? css``
      : css`
          cursor: pointer;
          color: #5c659d;
          text-decoration: underline;
        `}
`;
export const InceptionDate = styled.div`
  grid-area: "inceptionDate";
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Gates = styled.div`
  grid-area: "gates";
  display: flex;
  justify-content: center;
  align-items: center;
  ${(props) =>
    props.isHead
      ? css``
      : css`
          cursor: pointer;
          color: #5c659d;
          text-decoration: underline;
        `}
`;

export const RFID = styled.div`
  grid-area: "RFID";
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Type = styled.div`
  grid-area: "type";
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const EditBtnContainer = styled.div`
  grid-area: "editButton";
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const RemoveBtnContainer = styled.div`
  grid-area: "removeButton";
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Button = styled.button`
  background-color: transparent;
  border: 1px solid white;
  outline: none;

  justify-self: center;
  align-self: center;
  cursor: pointer;
  font-size: 0.8rem;
  padding: 0.2rem 1rem;
  border-radius: 20px;
  opacity: 0.8;
  color: black;
  border: 1px solid black;
`;
