import React, { useEffect } from "react";
import { useState } from "react";
import ZoneMenu from "../components/zone/ZoneMenu";
import ZoneAccessCard from "../components/zone/zoneCards/zoneAccessCard/ZoneAccessCard";
import ZoneCurrentCard from "../components/zone/zoneCards/zoneCurrentCountCard/ZoneCurrentCard";
import ZoneLogs from "../components/zone//zoneLogs/ZoneLogs";
import ZoneStatusCard from "../components/zone/zoneCards/zoneStatusCard/ZoneStatusCard";
import ZOneNotificationCard from "../components/zone/zoneCards/zoneNotificationsCard/ZOneNotificationCard";
import ZoneRestrictionsCard from "../components/zone/zoneCards/zoneRestrictionCard/ZoneRestrictionsCard";
import ZoneDeviceRestrictionCard from "../components/zone/zoneCards/zoneDeviceRestrictionCard/ZoneDeviceRestrictionCard";
import { SolidButton } from "../components/buttons/solidButton.styled";
import { IconContainer } from "../components/App.styled";
import ZoneAttendanceCard from "../components/zone/zoneCards/zoneAttendanceCard/ZoneAttendanceCard";
import { useDispatch, useSelector } from "react-redux";
import PlaceholderMessage from "../components/PlaceholderMessage";
import { useHistory, useLocation } from "react-router-dom";
import { getUserRole, isRootNode, toggleForm } from "../utils/utilFunctions";
import { useCookies } from "react-cookie";
import { formNames, rootApi, OPS_LIST, SUPER_ADMIN_LIST } from "../config";
import ZoneWifiCard from "../components/zone/zoneCards/zoneWifiCard/ZoneWifiCard";
import { BiEdit } from "react-icons/bi";
import { RiDeleteBinLine } from "react-icons/ri";
import NotificationManager from "react-notifications/lib/NotificationManager";
import { confirmAlert } from "react-confirm-alert";
import Poster from "../components/nodeQRPoster/Poster";
import { deleteZoneAPI } from "../utils/requests";
import ZoneDepartmentCard from "../components/zone/zoneCards/zoneDepartmentCard/ZoneDepartmentCard";
import { FcSettings, FcTreeStructure } from "react-icons/fc";
import ZonePlanCard from "../components/zone/zoneCards/zonePlanCard/ZonePlanCard";
import { sendCustomPageView } from "../utils/tagManagerEvents";
const Doors = () => {
  const [cookies, setCookie, removeCookie] = useCookies(["AT"]);
  const selectedNode = useSelector((state) => state.selectedNode);
  const nodeInfo = useSelector((state) => state.nodeInfo);
  const [tabStatus, setTabStatus] = useState("zone");
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const history = useHistory();
  const nodes = useSelector((state) => state.nodes);
  const formState = useSelector((state) => state.formState);
  const location = useLocation();

  useEffect(() => {
    sendCustomPageView(
      user.userId,
      nodeInfo.name,
      nodeInfo.nodeCategory,
      getUserRole(user.roles, nodeInfo)
    );
  }, [nodeInfo, user]);

  return (
    <div>
      <ZoneMenu tabStatus={tabStatus} setTabStatus={setTabStatus} />
      {tabStatus === "zone" ? (
        <>
          {selectedNode.nodeId ? (
            <div className="container-fluid p-0">
              <div className="d-flex align-items-center justify-content-end my-2">
                {user && user.mobileNo && SUPER_ADMIN_LIST.includes(user.mobileNo) ? (
                  <SolidButton
                    onClick={(e) =>
                      toggleForm(formState, history, location, dispatch, formNames.zoneAddForm)
                    }
                  >
                    Add Child Zone
                  </SolidButton>
                ) : null}
                <IconContainer>
                  <BiEdit
                    style={{ fontSize: "1.5rem", fontWeight: "bold" }}
                    onClick={(e) =>
                      toggleForm(formState, history, location, dispatch, formNames.zoneUpdateForm)
                    }
                  />
                </IconContainer>
                <IconContainer>
                  {nodeInfo.nodeQRCode ? <Poster nodeInfo={nodeInfo} /> : null}
                </IconContainer>
                {/* <IconContainer>
                  <FcSettings
                    onClick={(e) =>
                      toggleForm(
                        formState,
                        history,
                        location,
                        dispatch,
                        formNames.nodeConfigForm
                      )
                    }
                  />
                </IconContainer> */}

                {user && user.mobileNo && OPS_LIST.includes(user.mobileNo) ? (
                  <>
                    <IconContainer>
                      <FcTreeStructure
                        style={{
                          fontSize: "1.5rem",
                          fontWeight: "bold",
                        }}
                        onClick={(e) =>
                          toggleForm(formState, history, location, dispatch, formNames.hierarchyViewForm)
                        }
                      />
                    </IconContainer>
                    <IconContainer>
                      <RiDeleteBinLine
                        style={{
                          fontSize: "1.5rem",
                          fontWeight: "bold",
                          color: "red",
                        }}
                        onClick={(e) =>
                          deleteZoneAPI(
                            user,
                            dispatch,
                            NotificationManager,
                            removeCookie,
                            history,
                            selectedNode,
                            confirmAlert,
                            nodes
                          )
                        }
                      />
                    </IconContainer>
                  </>
                ) : null}
              </div>
              <div className="row">
                <div className="col-md-3 col-lg-3 col-sm-12 col-xs-12">
                  <ZoneAccessCard />
                  {/* <ZonePlanCard /> */}

                  {/* <ZoneCurrentCard /> */}
                </div>
                <div className="col-md-3 col-lg-3 col-sm-12 col-xs-12">
                  <ZoneDeviceRestrictionCard />
                  {/* <ZoneAttendanceCard /> */}
                  {/* <ZoneRestrictionsCard /> */}
                  {/* <ZoneWifiCard /> */}
                  {/* <ZOneNotificationCard /> */}
                </div>
                <div className="col-md-3 col-lg-3 col-sm-12 col-xs-12">
                  <ZoneDepartmentCard />
                </div>
                <div className="col-md-3 col-lg-3 col-sm-12 col-xs-12">
                  <ZoneStatusCard />
                </div>
              </div>
            </div>
          ) : (
            <PlaceholderMessage />
          )}
        </>
      ) : (
        <div>{selectedNode.nodeId ? <ZoneLogs /> : <PlaceholderMessage />}</div>
      )}
    </div>
  );
};

export default Doors;
