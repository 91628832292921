import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { rootApi } from "../../config";
import { get, post } from "../../utils/apiService";
import { ATexpired } from "../../utils/logout";
import { AccessInfoViewBackground, AccessInfoViewWrapper } from "./accessInfoMobileView.styled";
import { AiOutlineCloseCircle } from "react-icons/ai";
import {
  ButtonMobile,
  FieldLabelMobile,
  FieldValueMobile,
  ZoneLogsUserRowMobileView,
  ZoneLogsUserRowMobileWrapper,
} from "../zone/ZoneLogsUserRow/zoneLogsUserRow.styled";
import { decodeTime, nullChecker } from "../../utils/utilFunctions";
import { Checkbox } from "../people/peopleView/peopleView.styled";
import { FormBackground, FormBox, FormContainer } from "../forms/FormBase.styled";
import PlaceholderMessage from "../PlaceholderMessage";
import NotificationManager from "react-notifications/lib/NotificationManager";
import { getAccessInfoAPI, membershipAccessInfoRevoke } from "../../utils/requests";
const AccessInfoMobileView = ({ showMenu, nodeId, data, setShowMenu, membershipId }) => {
  const [cookies, setCookie, removeCookie] = useCookies(["AT"]);
  const weekMap = ["S", "M", "T", "W", "Th", "F", "Sa"];
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const [accessInfo, setAccessInfo] = useState(null);
  const dimentions = useSelector((state) => state.dimentions);
  const history = useHistory();

  useEffect(() => {
    if (membershipId) {
      getAccessInfoAPI(
        user,
        dispatch,
        NotificationManager,
        removeCookie,
        history,
        nodeId,
        membershipId,
        setAccessInfo
      );
    } else if (data) {
      let temp = data.map((d) => ({ accessInfo: d }));

      setAccessInfo([...temp]);
    }
    return () => {
      setAccessInfo([]);
    };
  }, [membershipId]);
  return (
    <FormBackground dimentions={dimentions} onClick={(e) => setShowMenu(false)}>
      <FormContainer dimentions={dimentions}>
        <FormBox dimentions={dimentions}>
          <div className="text-right">
            <p onClick={(e) => setShowMenu(false)} style={{ cursor: "pointer", fontSize: "1.2rem" }}>
              <AiOutlineCloseCircle />
            </p>
          </div>
          <div className="row m-0 p-0">
            {accessInfo ? (
              accessInfo.map((v, indx) => (
                <div className="col-sm-12 col-xs-12 p-0 col-md-6 px-md-2" key={indx}>
                  <ZoneLogsUserRowMobileWrapper>
                    <ZoneLogsUserRowMobileView>
                      <div>
                        <FieldLabelMobile>Gate</FieldLabelMobile>
                        <FieldValueMobile>{v.node ? v.node[0].name : ""}</FieldValueMobile>
                      </div>
                      <div>
                        <FieldLabelMobile>Access Days</FieldLabelMobile>
                        <FieldValueMobile>
                          {v.accessInfo.daysofWeek.map((i, idx) =>
                            i ? (
                              <span key={weekMap[`${idx}`]} className="mx-1">
                                {weekMap[`${idx}`]}
                              </span>
                            ) : null
                          )}
                        </FieldValueMobile>
                      </div>
                      <div>
                        <FieldLabelMobile>Access Time</FieldLabelMobile>
                        <FieldValueMobile>
                          {`${decodeTime(v.accessInfo.startTimeInDay)} - ${decodeTime(
                            v.accessInfo.endTimeInDay
                          )}`}
                        </FieldValueMobile>
                      </div>
                      <div>
                        <FieldLabelMobile>Visitor Permission</FieldLabelMobile>
                        <FieldValueMobile>
                          {!v.accessInfo.visitorManagement ? (
                            "-"
                          ) : (
                            // <SolidButton>Upgrade Plan</SolidButton>
                            <Checkbox
                              type="checkbox"
                              readOnly
                              checked={v.accessInfo.visitorManagement}
                            />
                          )}
                        </FieldValueMobile>
                      </div>
                      <div>
                        <FieldLabelMobile>CRB</FieldLabelMobile>
                        <FieldValueMobile>
                          {!v.accessInfo.CRB ? (
                            "-"
                          ) : (
                            // <SolidButton>Upgrade Plan</SolidButton>
                            <Checkbox type="checkbox" readOnly checked={v.accessInfo.CRB} />
                          )}
                        </FieldValueMobile>
                      </div>
                    </ZoneLogsUserRowMobileView>
                    {/* <div className="col-12 text-center my-2">
                      <ButtonMobile
                        onClick={(e) =>
                          membershipAccessInfoRevoke(
                            user,
                            dispatch,
                            NotificationManager,
                            removeCookie,
                            history,
                            v.accessInfo.nodeId,
                            membershipId,
                            setAccessInfo
                          )
                        }
                      >
                        Revoke
                      </ButtonMobile>
                    </div> */}
                  </ZoneLogsUserRowMobileWrapper>
                </div>
              ))
            ) : (
              <PlaceholderMessage message="No data to display" />
            )}
          </div>
        </FormBox>
      </FormContainer>
    </FormBackground>
  );
};

export default AccessInfoMobileView;
