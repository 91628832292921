import React, { useEffect } from "react";
import {
  ZoneNotificationCardContainer,
  Title,
  ToggleBox,
  ToggleTitle,
  MutedText,
} from "./zoneNotificationCard.styled";
import GreenSwitch from "../../../switches/GreenSwitch";
import { TiDeleteOutline } from "react-icons/ti";
import { ZoneAccessCardCount } from "../zoneAccessCard/zoneAccessCard.styled";
import { IconContainer } from "../../../App.styled";
import { SolidButton } from "../../../buttons/solidButton.styled";
import { BiEdit } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import AddAccessForm from "../../../forms/addAccessForm/AddAccessForm";
import { useCookies } from "react-cookie";
import { useHistory } from "react-router-dom";
import NotificationManager from "react-notifications/lib/NotificationManager";
import {
  getNodeUsersToAlert,
  removeUserFromAlertGroupAPI,
  updateNodeInfoAPI,
} from "../../../../utils/requests";
const ZOneNotificationCard = () => {
  let history = useHistory();
  const dispatch = useDispatch();
  const [cookies, setCookie, removeCookie] = useCookies(["AT"]);
  const [usersToAlert, setUsersToAlert] = useState(null);
  const [showAddAccessForm, setShowAddAccessForm] = useState(false);
  const [showRemoveUserButton, setShowRemoveUserButton] = useState(false);
  const nodeInfo = useSelector((state) => state.nodeInfo);
  const user = useSelector((state) => state.user);
  const selectedNode = useSelector((state) => state.selectedNode);
  const updateNodeInfo = async (reqBody) => {
    let body = { nodeId: selectedNode.nodeId, ...reqBody };
    await updateNodeInfoAPI(
      user,
      dispatch,
      NotificationManager,
      removeCookie,
      history,
      selectedNode,
      body
    );
  };

  useEffect(() => {
    getNodeUsersToAlert(
      user,
      dispatch,
      NotificationManager,
      removeCookie,
      history,
      selectedNode,
      setUsersToAlert
    );
  }, [selectedNode]);

  return (
    <ZoneNotificationCardContainer>
      {showAddAccessForm ? (
        <AddAccessForm
          setUsersToAlert={setUsersToAlert}
          usersToAlert={usersToAlert}
          setShowAddAccessForm={setShowAddAccessForm}
        />
      ) : null}
      <Title>Notifications</Title>
      <ToggleBox>
        <ToggleTitle>Temperature Scanning</ToggleTitle>
        <GreenSwitch
          checked={nodeInfo.alerts && nodeInfo.alerts.highTemp}
          onChange={(e) =>
            updateNodeInfo({ highTempAlert: !nodeInfo.alerts.highTemp })
          }
        />
      </ToggleBox>
      <ToggleBox>
        <ToggleTitle>Social Distancing</ToggleTitle>
        <GreenSwitch
          checked={nodeInfo.alerts && nodeInfo.alerts.peopleLimit}
          onChange={(e) =>
            updateNodeInfo({
              peopleLimitAlert: !nodeInfo.alerts.peopleLimit,
            })
          }
        />
      </ToggleBox>
      <ToggleBox>
        <ToggleTitle>Tailgating Detection</ToggleTitle>
        <GreenSwitch
          checked={nodeInfo.alerts && nodeInfo.alerts.tailGating}
          onChange={(e) =>
            updateNodeInfo({ tailGatingAlert: !nodeInfo.alerts.tailGating })
          }
        />
      </ToggleBox>

      <div className="d-flex justify-content-between aic">
        <ZoneAccessCardCount>
          {usersToAlert && usersToAlert.length}
        </ZoneAccessCardCount>
        <MutedText className="ml-2">people Assiged</MutedText>
      </div>
      {usersToAlert
        ? usersToAlert.map((u) => (
            <ToggleBox key={u.user.userId}>
              <MutedText>{u.user.firstName + " " + u.user.lastName}</MutedText>
              <MutedText>
                {u && u.user.mobile ? u.user.mobile.number : "-"}
                {showRemoveUserButton ? (
                  <TiDeleteOutline
                    onClick={(e) =>
                      removeUserFromAlertGroupAPI(
                        user,
                        dispatch,
                        NotificationManager,
                        removeCookie,
                        history,
                        u.userId,
                        selectedNode,
                        setUsersToAlert
                      )
                    }
                    className="m-0 p-0 text-danger cursor-pointer ml-2"
                    style={{ fontSize: "1.2rem" }}
                  />
                ) : null}
              </MutedText>
            </ToggleBox>
          ))
        : null}
      <div className="d-flex align-items-center justify-content-end mt-4">
        <SolidButton onClick={(e) => setShowAddAccessForm(true)}>
          Add Access
        </SolidButton>
        <IconContainer>
          <BiEdit
            className="m-0 p-0"
            onClick={(e) => setShowRemoveUserButton(!showRemoveUserButton)}
          />
        </IconContainer>
      </div>
    </ZoneNotificationCardContainer>
  );
};

export default ZOneNotificationCard;
