import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { breakPointWidth, contentLength, formNames } from "../../../../config";
import { nullChecker, toggleForm } from "../../../../utils/utilFunctions";
import { OverFLowScrollBox } from "../../../App.styled";
import { SolidButton } from "../../../buttons/solidButton.styled";
import StringSpreader from "../../../StringSpreader";
import {
  ButtonMobile,
  FieldLabelMobile,
  FieldValueMobile,
  ZoneLogsUserRowMobileView,
  ZoneLogsUserRowMobileWrapper,
} from "../../../zone/ZoneLogsUserRow/zoneLogsUserRow.styled";
import {
  Button,
  CustomDeviceId,
  Delete,
  DevicesWithNamesRowContainer,
  DevicesWithNamesRowStyled,
  DFI,
  Edit,
  FirmwareId,
  MAC,
  Name,
} from "./deviceWithNames.styled";

const DevicesWithNames = ({ devices, handleDelete }) => {
  const dimentions = useSelector((state) => state.dimentions);
  const formState = useSelector((state) => state.formState);
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  return (
    <>
      <div className="text-right pb-3">
        <SolidButton
          style={{ marginRight: "0px !important" }}
          onClick={(e) =>
            toggleForm(formState, history, location, dispatch, formNames.addDeviceFirmwareByNameForm)
          }
        >
          Add
        </SolidButton>
      </div>
      {dimentions.width > breakPointWidth ? (
        <DevicesWithNamesRowContainer>
          <DevicesWithNamesRowStyled isHead={true}>
            <Name>Name</Name>
            <CustomDeviceId>Custom DeviceID</CustomDeviceId>
            <MAC>MAC</MAC>
            <DFI>Update DFI To</DFI>
            <Edit>Edit</Edit>
            <Delete>Delete</Delete>
          </DevicesWithNamesRowStyled>
          <OverFLowScrollBox>
            {devices &&
              devices
                .filter((d) => d.deviceName)
                .map((d) => (
                  <DevicesWithNamesRowStyled>
                    <Name>{nullChecker(d.deviceName)}</Name>
                    <CustomDeviceId>{nullChecker(d.customDeviceId)}</CustomDeviceId>
                    <MAC>{nullChecker(d.MAC)}</MAC>
                    <DFI>
                      <StringSpreader
                        length={contentLength.text}
                        string={nullChecker(d.updateDFITo)}
                      ></StringSpreader>
                    </DFI>
                    <Edit>
                      <Button
                        onClick={(e) =>
                          toggleForm(
                            formState,
                            history,
                            location,
                            dispatch,
                            formNames.editDeviceFirmwareByNameForm,
                            { ...d }
                          )
                        }
                      >
                        Edit
                      </Button>
                    </Edit>
                    <Delete>
                      <Button onClick={(e) => handleDelete(d.deviceFirmwareId)}>Delete</Button>
                    </Delete>
                  </DevicesWithNamesRowStyled>
                ))}
          </OverFLowScrollBox>
        </DevicesWithNamesRowContainer>
      ) : (
        <div className="row">
          {devices &&
            devices
              .filter((d) => d.deviceName)
              .map((d) => (
                <div className="col-sm-12 col-xs-12 p-0 col-md-6 px-md-2" key={d.deviceFirmwareId}>
                  <ZoneLogsUserRowMobileWrapper>
                    <div className="row text-left">
                      <div className="col-sm-6 col-md-6 col-xs-12 mt-1">
                        <FieldLabelMobile>Name</FieldLabelMobile>
                        <FieldValueMobile>{nullChecker(d.deviceName)}</FieldValueMobile>
                      </div>
                      <div className="col-sm-6 col-md-6 col-xs-12 mt-1">
                        <FieldLabelMobile>Custom DeviceId</FieldLabelMobile>
                        <FieldValueMobile>{nullChecker(d.customDeviceId)}</FieldValueMobile>
                      </div>
                      <div className="col-sm-6 col-md-6 col-xs-12 mt-1">
                        <FieldLabelMobile>MAC</FieldLabelMobile>
                        <FieldValueMobile>{nullChecker(d.MAC)}</FieldValueMobile>
                      </div>
                      <div className="col-sm-6 col-md-6 col-xs-12 mt-1">
                        <FieldLabelMobile>Update DFI To</FieldLabelMobile>
                        <FieldValueMobile>{nullChecker(d.updateDFITo)}</FieldValueMobile>
                      </div>
                    </div>

                    <div className="row my-2">
                      <div className="col-12">
                        <ButtonMobile
                          className="w-100"
                          onClick={(e) =>
                            toggleForm(
                              formState,
                              history,
                              location,
                              dispatch,
                              formNames.editDeviceFirmwareByNameForm,
                              { ...d }
                            )
                          }
                        >
                          Edit
                        </ButtonMobile>
                      </div>
                    </div>
                    <div className="row my-2">
                      <div className="col-12">
                        <ButtonMobile
                          className="w-100"
                          onClick={(e) => handleDelete(d.deviceFirmwareId)}
                        >
                          Delete
                        </ButtonMobile>
                      </div>
                    </div>
                  </ZoneLogsUserRowMobileWrapper>
                </div>
              ))}
        </div>
      )}
    </>
  );
};

export default DevicesWithNames;
