import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import NotificationManager from "react-notifications/lib/NotificationManager";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { apiEndPoints, formNames } from "../../../config";
import { updateNodeInfoAPI } from "../../../utils/requests";
import { toggleForm } from "../../../utils/utilFunctions";
import { FormWrapper } from "../doorAddForm/DoorAddForm";
import { AiFillCloseCircle } from "react-icons/ai";
import { FieldInput, FieldLabel, InputBox, InputGroup } from "../FormBase.styled";
import {
  AddButtonTab,
  SectionHeading,
  Tab,
  TabRow,
  CheckBoxLabel,
  CheckBoxContainer,
  CheckBox,
  DownloadButton,
  LockIconContainer,
} from "./attendanceFormatForm.styled";
import { DisabledLockIcon } from "../../DisabledOverlay";
import ButtonState from "../../ButtonState/ButtonState";

const defaultFormat = (n) => {
  return {
    name: `Sheet ${n}`,
    personalInfo: {
      firstName: true,
      lastName: true,
      emailId: false,
      mobileNo: false,
      UDID: false,
      designation: false,
      department: true,
      subDepartment: false,
      userType: false,
    },
    dailyReports: {
      inTime: true,
      outTime: true,
      firstInToLastOut: true,
      totalInTime: false,
      overtime: false,
      lateBy: false,
      earlyBy: false,
      punchRecords: false,
      daySummary: false,
    },
    calculations: {
      totalInTimeSum: false,
      totalBreakTimeSum: false,
      totalPresent: true,
      totalWorkOff: false,
      totalAbsent: false,
      totalPresentPlusWorkoff: false,
      totalShortFall: false,
      totalhalfDays: false,
      totalDays: false,
      totalWorkingDays: false,
      totalPrms: false,
    },
    suffix: {
      lateBySuffix: true,
      earlyBySuffix: true,
      firstInToLastOutSuffix: true,
      totalInTimeSuffix: true,
      overtimeSuffix: true,
    },
  };
};

const AttendanceFormatForm = () => {
  const attendanceFormat = useSelector((state) => state.nodeInfo.attendanceFormat);
  const [cookies, setCookie, removeCookie] = useCookies(["AT"]);
  const formState = useSelector((state) => state.formState);
  const dimentions = useSelector((state) => state.dimentions);
  const nodeInfo = useSelector((state) => state.nodeInfo);
  const user = useSelector((state) => state.user);
  const selectedNode = useSelector((state) => state.selectedNode);
  const apiStatus = useSelector((state) => state.apiStatus);
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const [tempFormat, setTempFormat] = useState([]);
  const [expandedCollapseIndex, setExpandedCollapseIndex] = useState(0);
  const [error, setError] = useState(false);

  const closeForm = () => {
    toggleForm(formState, history, location, dispatch, formNames.attendanceFormatForm);
  };

  const updateNodeInfo = async () => {
    const body = {
      nodeId: selectedNode.nodeId,
      attendanceFormat: JSON.stringify(tempFormat),
    };
    updateNodeInfoAPI(
      user,
      dispatch,
      NotificationManager,
      removeCookie,
      history,
      selectedNode,
      body,
      closeForm
    );
  };

  const handleRemoveSheet = (e, i) => {
    e.stopPropagation();
    const temp = [...tempFormat];
    temp.splice(i, 1);
    setTempFormat([...temp]);
    if (i === expandedCollapseIndex) {
      if (i === 0) {
        setExpandedCollapseIndex(0);
      } else {
        setExpandedCollapseIndex(i - 1);
      }
    }
  };

  useEffect(() => {
    if (!attendanceFormat || !attendanceFormat.length) {
      setTempFormat([{ ...defaultFormat(1) }]);
    } else if (attendanceFormat && attendanceFormat.length && !attendanceFormat[0].suffix) {
      setTempFormat([
        ...attendanceFormat.map((v) => ({ ...v, suffix: { ...defaultFormat(1).suffix } })),
      ]);
    } else {
      setTempFormat([...attendanceFormat]);
    }
  }, [attendanceFormat]);

  const handleChange = (e, catagory, index) => {
    let temp = [...tempFormat];
    if (e.target.name === "name") {
      temp[index].name = e.target.value;
    } else if (e.target.name === "selectAllCalc") {
      if (
        Object.values(temp[index].calculations).filter((v) => v).length ===
        Object.values(temp[index].calculations).length
      ) {
        temp[index].calculations = {
          totalInTimeSum: false,
          totalBreakTimeSum: false,
          totalPresent: false,
          totalWorkOff: false,
          totalAbsent: false,
          totalPresentPlusWorkoff: false,
          totalShortFall: false,
          totalhalfDays: false,
          totalDays: false,
          totalWorkingDays: false,
          totalPrms: false,
        };
      } else {
        temp[index].calculations = {
          totalInTimeSum: true,
          totalBreakTimeSum: true,
          totalPresent: true,
          totalWorkOff: true,
          totalAbsent: true,
          totalPresentPlusWorkoff: true,
          totalShortFall: true,
          totalhalfDays: true,
          totalDays: true,
          totalWorkingDays: true,
          totalPrms: true,
        };
      }
    } else if (e.target.name === "selectAllPi") {
      if (
        Object.values(temp[index].personalInfo).filter((v) => v).length ===
        Object.values(temp[index].personalInfo).length
      ) {
        temp[index].personalInfo = {
          firstName: false,
          lastName: false,
          emailId: false,
          mobileNo: false,
          UDID: false,
          designation: false,
          department: false,
          subDepartment: false,
          userType: false,
        };
      } else {
        temp[index].personalInfo = {
          firstName: true,
          lastName: true,
          emailId: true,
          mobileNo: true,
          UDID: true,
          designation: true,
          department: true,
          subDepartment: true,
          userType: true,
        };
      }
    } else if (e.target.name === "selectAllDr") {
      if (
        Object.values(temp[index].dailyReports).filter((v) => v).length ===
        Object.values(temp[index].dailyReports).length
      ) {
        temp[index].dailyReports = {
          inTime: false,
          outTime: false,
          firstInToLastOut: false,
          totalInTime: false,
          overtime: false,
          lateBy: false,
          earlyBy: false,
          punchRecords: false,
          daySummary: false,
        };
      } else {
        temp[index].dailyReports = {
          inTime: true,
          outTime: true,
          firstInToLastOut: true,
          totalInTime: true,
          overtime: true,
          lateBy: true,
          earlyBy: true,
          punchRecords: true,
          daySummary: true,
        };
      }
    } else {
      temp[index][catagory][e.target.name] = e.target.checked;
    }

    setTempFormat([...temp]);
  };

  return (
    <FormWrapper dimentions={dimentions} closeForm={closeForm}>
      <h6 className="font-weight-bold mb-4">Download Attendence Report</h6>
      <h6 className="font-weight-bold mb-4 text-center text-uppercase" style={{ color: "#1D46A8" }}>
        columns required in the report
      </h6>
      {/* columns required in the report */}
      <TabRow>
        {/* <SheetNamesContainer> */}
        {tempFormat.map((v, i) => (
          <Tab
            data-toggle="collapse"
            aria-expanded={i === 0 ? "true" : "false"}
            aria-controls={`formatForm${i}`}
            data-target={`#formatForm${i}`}
            onClick={(e) => setExpandedCollapseIndex(i)}
          >
            <div className="d-flex justify-content-between align-items-center">
              <p className="m-0">{v.name}</p>
              <AiFillCloseCircle className="ml-3 mb-0" onClick={(e) => handleRemoveSheet(e, i)} />
            </div>
          </Tab>
        ))}
        <AddButtonTab
          onClick={(e) => setTempFormat([...tempFormat, defaultFormat(tempFormat.length + 1)])}
        >
          +
        </AddButtonTab>
      </TabRow>
      <div className="row p-5" style={{ backgroundColor: "#F4F4F4" }}>
        {tempFormat.map((f, i) => (
          <div
            className={`col-12 collapse ${expandedCollapseIndex === i ? "show" : ""}`}
            id={`formatForm${i}`}
          >
            <div className="row">
              <div className="col-12 mb-3">
                <InputGroup dimentions={dimentions}>
                  <FieldLabel dimentions={dimentions}>Rename Sheet (Optional)</FieldLabel>
                  <FieldInput>
                    <InputBox
                      className="w-auto"
                      type="text"
                      value={tempFormat[i].name}
                      name="name"
                      onChange={(e) => handleChange(e, "name", i)}
                    />
                  </FieldInput>
                </InputGroup>
              </div>
              <div className="col-md-4 col-lg-4 col-sm-12 col-xs-12 my-3">
                <SectionHeading>Personal information</SectionHeading>
                <CheckBoxContainer>
                  <LockIconContainer></LockIconContainer>
                  <CheckBox
                    type="checkbox"
                    name="selectAllPi"
                    checked={
                      Object.values(tempFormat[i].personalInfo).filter((v) => v).length ===
                      Object.keys(tempFormat[i].personalInfo).length
                    }
                    onChange={(e) => handleChange(e, "personalInfo", i)}
                  />
                  <CheckBoxLabel>Select All</CheckBoxLabel>
                </CheckBoxContainer>
                <CheckBoxContainer>
                  <LockIconContainer>
                    {nodeInfo?.features?.attendanceConfig.firstName ? null : (
                      <DisabledLockIcon size={"1rem"} />
                    )}
                  </LockIconContainer>
                  <CheckBox
                    disabled={!nodeInfo?.features?.attendanceConfig.firstName}
                    type="checkbox"
                    name="firstName"
                    checked={tempFormat[i].personalInfo.firstName}
                    onChange={(e) => handleChange(e, "personalInfo", i)}
                  />
                  <CheckBoxLabel>First Name</CheckBoxLabel>
                </CheckBoxContainer>
                <CheckBoxContainer>
                  <LockIconContainer>
                    {nodeInfo?.features?.attendanceConfig.lastName ? null : (
                      <DisabledLockIcon size={"1rem"} />
                    )}
                  </LockIconContainer>
                  <CheckBox
                    disabled={!nodeInfo?.features?.attendanceConfig.lastName}
                    type="checkbox"
                    name="lastName"
                    checked={tempFormat[i].personalInfo.lastName}
                    onChange={(e) => handleChange(e, "personalInfo", i)}
                  />
                  <CheckBoxLabel>Last Name</CheckBoxLabel>
                </CheckBoxContainer>
                <CheckBoxContainer>
                  <LockIconContainer>
                    {nodeInfo?.features?.attendanceConfig.emailId ? null : (
                      <DisabledLockIcon size={"1rem"} />
                    )}
                  </LockIconContainer>
                  <CheckBox
                    disabled={!nodeInfo?.features?.attendanceConfig.emailId}
                    type="checkbox"
                    name="emailId"
                    checked={tempFormat[i].personalInfo.emailId}
                    onChange={(e) => handleChange(e, "personalInfo", i)}
                  />
                  <CheckBoxLabel>Email ID</CheckBoxLabel>
                </CheckBoxContainer>
                <CheckBoxContainer>
                  <LockIconContainer>
                    {nodeInfo?.features?.attendanceConfig.mobileNo ? null : (
                      <DisabledLockIcon size={"1rem"} />
                    )}
                  </LockIconContainer>
                  <CheckBox
                    disabled={!nodeInfo?.features?.attendanceConfig.mobileNo}
                    type="checkbox"
                    name="mobileNo"
                    checked={tempFormat[i].personalInfo.mobileNo}
                    onChange={(e) => handleChange(e, "personalInfo", i)}
                  />
                  <CheckBoxLabel>Mobile No.</CheckBoxLabel>
                </CheckBoxContainer>
                <CheckBoxContainer>
                  <LockIconContainer>
                    {nodeInfo?.features?.attendanceConfig.UDID ? null : (
                      <DisabledLockIcon size={"1rem"} />
                    )}
                  </LockIconContainer>
                  <CheckBox
                    disabled={!nodeInfo?.features?.attendanceConfig.UDID}
                    type="checkbox"
                    name="UDID"
                    checked={tempFormat[i].personalInfo.UDID}
                    onChange={(e) => handleChange(e, "personalInfo", i)}
                  />
                  <CheckBoxLabel>UDID</CheckBoxLabel>
                </CheckBoxContainer>
                <CheckBoxContainer>
                  <LockIconContainer>
                    {nodeInfo?.features?.attendanceConfig.designation ? null : (
                      <DisabledLockIcon size={"1rem"} />
                    )}
                  </LockIconContainer>
                  <CheckBox
                    disabled={!nodeInfo?.features?.attendanceConfig.designation}
                    type="checkbox"
                    name="designation"
                    checked={tempFormat[i].personalInfo.designation}
                    onChange={(e) => handleChange(e, "personalInfo", i)}
                  />
                  <CheckBoxLabel>Designation</CheckBoxLabel>
                </CheckBoxContainer>
                <CheckBoxContainer>
                  <LockIconContainer>
                    {nodeInfo?.features?.attendanceConfig.department ? null : (
                      <DisabledLockIcon size={"1rem"} />
                    )}
                  </LockIconContainer>
                  <CheckBox
                    disabled={!nodeInfo?.features?.attendanceConfig.department}
                    type="checkbox"
                    name="department"
                    checked={tempFormat[i].personalInfo.department}
                    onChange={(e) => handleChange(e, "personalInfo", i)}
                  />
                  <CheckBoxLabel>Department</CheckBoxLabel>
                </CheckBoxContainer>
                <CheckBoxContainer>
                  <LockIconContainer>
                    {nodeInfo?.features?.attendanceConfig.subDepartment ? null : (
                      <DisabledLockIcon size={"1rem"} />
                    )}
                  </LockIconContainer>
                  <CheckBox
                    disabled={!nodeInfo?.features?.attendanceConfig.subDepartment}
                    type="checkbox"
                    name="subDepartment"
                    checked={tempFormat[i].personalInfo.subDepartment}
                    onChange={(e) => handleChange(e, "personalInfo", i)}
                  />
                  <CheckBoxLabel>Sub Department</CheckBoxLabel>
                </CheckBoxContainer>
                <CheckBoxContainer>
                  <LockIconContainer>
                    {nodeInfo?.features?.attendanceConfig.userType ? null : (
                      <DisabledLockIcon size={"1rem"} />
                    )}
                  </LockIconContainer>
                  <CheckBox
                    disabled={!nodeInfo?.features?.attendanceConfig.userType}
                    type="checkbox"
                    name="userType"
                    checked={tempFormat[i].personalInfo.userType}
                    onChange={(e) => handleChange(e, "personalInfo", i)}
                  />
                  <CheckBoxLabel>User Type</CheckBoxLabel>
                </CheckBoxContainer>
              </div>

              <div className="col-md-4 col-lg-4 col-sm-12 col-xs-12 my-3">
                <SectionHeading>Daily Reports</SectionHeading>
                <CheckBoxContainer>
                  <LockIconContainer></LockIconContainer>
                  <CheckBox
                    type="checkbox"
                    name="selectAllDr"
                    checked={
                      Object.values(tempFormat[i].dailyReports).filter((v) => v).length ===
                      Object.keys(tempFormat[i].dailyReports).length
                    }
                    onChange={(e) => handleChange(e, "dailyReports", i)}
                  />
                  <CheckBoxLabel>Select All</CheckBoxLabel>
                </CheckBoxContainer>

                <CheckBoxContainer>
                  <LockIconContainer>
                    {nodeInfo?.features?.attendanceConfig.inTime ? null : (
                      <DisabledLockIcon size={"1rem"} />
                    )}
                  </LockIconContainer>
                  <CheckBox
                    disabled={!nodeInfo?.features?.attendanceConfig.inTime}
                    type="checkbox"
                    name="inTime"
                    checked={tempFormat[i].dailyReports.inTime}
                    onChange={(e) => handleChange(e, "dailyReports", i)}
                  />
                  <CheckBoxLabel>In Time</CheckBoxLabel>
                </CheckBoxContainer>
                <CheckBoxContainer>
                  <LockIconContainer>
                    {nodeInfo?.features?.attendanceConfig.outTime ? null : (
                      <DisabledLockIcon size={"1rem"} />
                    )}
                  </LockIconContainer>
                  <CheckBox
                    disabled={!nodeInfo?.features?.attendanceConfig.outTime}
                    type="checkbox"
                    name="outTime"
                    checked={tempFormat[i].dailyReports.outTime}
                    onChange={(e) => handleChange(e, "dailyReports", i)}
                  />
                  <CheckBoxLabel>Out Time</CheckBoxLabel>
                </CheckBoxContainer>
                <CheckBoxContainer>
                  <LockIconContainer>
                    {nodeInfo?.features?.attendanceConfig.firstInToLastOut ? null : (
                      <DisabledLockIcon size={"1rem"} />
                    )}
                  </LockIconContainer>
                  <CheckBox
                    disabled={!nodeInfo?.features?.attendanceConfig.firstInToLastOut}
                    type="checkbox"
                    name="firstInToLastOut"
                    checked={tempFormat[i].dailyReports.firstInToLastOut}
                    onChange={(e) => handleChange(e, "dailyReports", i)}
                  />
                  <CheckBoxLabel>First In To Last Out</CheckBoxLabel>
                </CheckBoxContainer>
                {tempFormat[i].dailyReports.firstInToLastOut ? (
                  <CheckBoxContainer style={{ marginLeft: "2rem" }}>
                    <CheckBox
                      disabled={!nodeInfo?.features?.attendanceConfig.firstInToLastOut}
                      type="checkbox"
                      name="firstInToLastOutSuffix"
                      checked={tempFormat[i]?.suffix?.firstInToLastOutSuffix}
                      onChange={(e) => handleChange(e, "suffix", i)}
                    />
                    <CheckBoxLabel>Add Suffix</CheckBoxLabel>
                  </CheckBoxContainer>
                ) : null}
                <CheckBoxContainer>
                  <LockIconContainer>
                    {nodeInfo?.features?.attendanceConfig.totalInTime ? null : (
                      <DisabledLockIcon size={"1rem"} />
                    )}
                  </LockIconContainer>
                  <CheckBox
                    disabled={!nodeInfo?.features?.attendanceConfig.totalInTime}
                    type="checkbox"
                    name="totalInTime"
                    checked={tempFormat[i].dailyReports.totalInTime}
                    onChange={(e) => handleChange(e, "dailyReports", i)}
                  />
                  <CheckBoxLabel>Total In Time</CheckBoxLabel>
                </CheckBoxContainer>
                {tempFormat[i].dailyReports.totalInTime ? (
                  <CheckBoxContainer style={{ marginLeft: "2rem" }}>
                    <CheckBox
                      disabled={!nodeInfo?.features?.attendanceConfig.totalInTime}
                      type="checkbox"
                      name="totalInTimeSuffix"
                      checked={tempFormat[i]?.suffix?.totalInTimeSuffix}
                      onChange={(e) => handleChange(e, "suffix", i)}
                    />
                    <CheckBoxLabel>Add Suffix</CheckBoxLabel>
                  </CheckBoxContainer>
                ) : null}
                <CheckBoxContainer>
                  <LockIconContainer>
                    {nodeInfo?.features?.attendanceConfig.overtime ? null : (
                      <DisabledLockIcon size={"1rem"} />
                    )}
                  </LockIconContainer>
                  <CheckBox
                    disabled={!nodeInfo?.features?.attendanceConfig.overtime}
                    type="checkbox"
                    name="overtime"
                    checked={tempFormat[i].dailyReports.overtime}
                    onChange={(e) => handleChange(e, "dailyReports", i)}
                  />
                  <CheckBoxLabel>Over Time</CheckBoxLabel>
                </CheckBoxContainer>
                {tempFormat[i].dailyReports.overtime ? (
                  <CheckBoxContainer style={{ marginLeft: "2rem" }}>
                    <CheckBox
                      disabled={!nodeInfo?.features?.attendanceConfig.overtime}
                      type="checkbox"
                      name="overtimeSuffix"
                      checked={tempFormat[i]?.suffix?.overtimeSuffix}
                      onChange={(e) => handleChange(e, "suffix", i)}
                    />
                    <CheckBoxLabel>Add Suffix</CheckBoxLabel>
                  </CheckBoxContainer>
                ) : null}
                <CheckBoxContainer>
                  <LockIconContainer>
                    {nodeInfo?.features?.attendanceConfig.lateBy ? null : (
                      <DisabledLockIcon size={"1rem"} />
                    )}
                  </LockIconContainer>
                  <CheckBox
                    disabled={!nodeInfo?.features?.attendanceConfig.lateBy}
                    type="checkbox"
                    name="lateBy"
                    checked={tempFormat[i].dailyReports.lateBy}
                    onChange={(e) => handleChange(e, "dailyReports", i)}
                  />
                  <CheckBoxLabel>Late By</CheckBoxLabel>
                </CheckBoxContainer>
                {tempFormat[i].dailyReports.lateBy ? (
                  <CheckBoxContainer style={{ marginLeft: "2rem" }}>
                    <CheckBox
                      disabled={!nodeInfo?.features?.attendanceConfig.overtime}
                      type="checkbox"
                      name="lateBySuffix"
                      checked={tempFormat[i]?.suffix?.lateBySuffix}
                      onChange={(e) => handleChange(e, "suffix", i)}
                    />
                    <CheckBoxLabel>Add Suffix</CheckBoxLabel>
                  </CheckBoxContainer>
                ) : null}
                <CheckBoxContainer>
                  <LockIconContainer>
                    {nodeInfo?.features?.attendanceConfig.earlyBy ? null : (
                      <DisabledLockIcon size={"1rem"} />
                    )}
                  </LockIconContainer>
                  <CheckBox
                    disabled={!nodeInfo?.features?.attendanceConfig.earlyBy}
                    type="checkbox"
                    name="earlyBy"
                    checked={tempFormat[i].dailyReports.earlyBy}
                    onChange={(e) => handleChange(e, "dailyReports", i)}
                  />
                  <CheckBoxLabel>Early By</CheckBoxLabel>
                </CheckBoxContainer>
                {tempFormat[i].dailyReports.earlyBy ? (
                  <CheckBoxContainer style={{ marginLeft: "2rem" }}>
                    <CheckBox
                      disabled={!nodeInfo?.features?.attendanceConfig.overtime}
                      type="checkbox"
                      name="earlyBySuffix"
                      checked={tempFormat[i]?.suffix?.earlyBySuffix}
                      onChange={(e) => handleChange(e, "suffix", i)}
                    />
                    <CheckBoxLabel>Add Suffix</CheckBoxLabel>
                  </CheckBoxContainer>
                ) : null}
                <CheckBoxContainer>
                  <LockIconContainer>
                    {nodeInfo?.features?.attendanceConfig.punchRecords ? null : (
                      <DisabledLockIcon size={"1rem"} />
                    )}
                  </LockIconContainer>
                  <CheckBox
                    disabled={!nodeInfo?.features?.attendanceConfig.punchRecords}
                    type="checkbox"
                    name="punchRecords"
                    checked={tempFormat[i].dailyReports.punchRecords}
                    onChange={(e) => handleChange(e, "dailyReports", i)}
                  />
                  <CheckBoxLabel>Punch Records</CheckBoxLabel>
                </CheckBoxContainer>
                <CheckBoxContainer>
                  <LockIconContainer>
                    {nodeInfo?.features?.attendanceConfig.daySummary ? null : (
                      <DisabledLockIcon size={"1rem"} />
                    )}
                  </LockIconContainer>
                  <CheckBox
                    disabled={!nodeInfo?.features?.attendanceConfig.daySummary}
                    type="checkbox"
                    name="daySummary"
                    checked={tempFormat[i].dailyReports.daySummary}
                    onChange={(e) => handleChange(e, "dailyReports", i)}
                  />
                  <CheckBoxLabel>Day Summary</CheckBoxLabel>
                </CheckBoxContainer>
              </div>
              <div className="col-md-4 col-lg-4 col-sm-12 col-xs-12 my-3">
                <SectionHeading>Calculations (Monthly)</SectionHeading>
                <CheckBoxContainer>
                  <LockIconContainer></LockIconContainer>
                  <CheckBox
                    type="checkbox"
                    name="selectAllCalc"
                    checked={
                      Object.values(tempFormat[i].calculations).filter((v) => v).length ===
                      Object.keys(tempFormat[i].calculations).length
                    }
                    onChange={(e) => handleChange(e, "calculations", i)}
                  />
                  <CheckBoxLabel>Select All</CheckBoxLabel>
                </CheckBoxContainer>
                <CheckBoxContainer>
                  <LockIconContainer>
                    {nodeInfo?.features?.attendanceConfig.totalInTimeSum ? null : (
                      <DisabledLockIcon size={"1rem"} />
                    )}
                  </LockIconContainer>
                  <CheckBox
                    type="checkbox"
                    name="totalInTimeSum"
                    checked={tempFormat[i].calculations.totalInTimeSum}
                    onChange={(e) => handleChange(e, "calculations", i)}
                  />
                  <CheckBoxLabel>Total In Time Sum</CheckBoxLabel>
                </CheckBoxContainer>
                <CheckBoxContainer>
                  <LockIconContainer>
                    {nodeInfo?.features?.attendanceConfig.totalBreakTimeSum ? null : (
                      <DisabledLockIcon size={"1rem"} />
                    )}
                  </LockIconContainer>
                  <CheckBox
                    type="checkbox"
                    name="totalBreakTimeSum"
                    checked={tempFormat[i].calculations.totalBreakTimeSum}
                    onChange={(e) => handleChange(e, "calculations", i)}
                  />
                  <CheckBoxLabel>Total Break Time Sum</CheckBoxLabel>
                </CheckBoxContainer>
                <CheckBoxContainer>
                  <LockIconContainer>
                    {nodeInfo?.features?.attendanceConfig.totalPresent ? null : (
                      <DisabledLockIcon size={"1rem"} />
                    )}
                  </LockIconContainer>
                  <CheckBox
                    disabled={!nodeInfo?.features?.attendanceConfig.totalPresent}
                    type="checkbox"
                    name="totalPresent"
                    checked={tempFormat[i].calculations.totalPresent}
                    onChange={(e) => handleChange(e, "calculations", i)}
                  />
                  <CheckBoxLabel>Total Present</CheckBoxLabel>
                </CheckBoxContainer>
                <CheckBoxContainer>
                  <LockIconContainer>
                    {nodeInfo?.features?.attendanceConfig.totalWorkOff ? null : (
                      <DisabledLockIcon size={"1rem"} />
                    )}
                  </LockIconContainer>
                  <CheckBox
                    disabled={!nodeInfo?.features?.attendanceConfig.totalWorkOff}
                    type="checkbox"
                    name="totalWorkOff"
                    checked={tempFormat[i].calculations.totalWorkOff}
                    onChange={(e) => handleChange(e, "calculations", i)}
                  />
                  <CheckBoxLabel>Total Work Off</CheckBoxLabel>
                </CheckBoxContainer>
                <CheckBoxContainer>
                  <LockIconContainer>
                    {nodeInfo?.features?.attendanceConfig.totalAbsent ? null : (
                      <DisabledLockIcon size={"1rem"} />
                    )}
                  </LockIconContainer>
                  <CheckBox
                    disabled={!nodeInfo?.features?.attendanceConfig.totalAbsent}
                    type="checkbox"
                    name="totalAbsent"
                    checked={tempFormat[i].calculations.totalAbsent}
                    onChange={(e) => handleChange(e, "calculations", i)}
                  />
                  <CheckBoxLabel>Total Absent</CheckBoxLabel>
                </CheckBoxContainer>
                <CheckBoxContainer>
                  <LockIconContainer>
                    {nodeInfo?.features?.attendanceConfig.totalPresentPlusWorkoff ? null : (
                      <DisabledLockIcon size={"1rem"} />
                    )}
                  </LockIconContainer>
                  <CheckBox
                    disabled={!nodeInfo?.features?.attendanceConfig.totalPresentPlusWorkoff}
                    type="checkbox"
                    name="totalPresentPlusWorkoff"
                    checked={tempFormat[i].calculations.totalPresentPlusWorkoff}
                    onChange={(e) => handleChange(e, "calculations", i)}
                  />
                  <CheckBoxLabel>Total Present Plus Work Off</CheckBoxLabel>
                </CheckBoxContainer>
                <CheckBoxContainer>
                  <LockIconContainer>
                    {nodeInfo?.features?.attendanceConfig.totalShortFall ? null : (
                      <DisabledLockIcon size={"1rem"} />
                    )}
                  </LockIconContainer>
                  <CheckBox
                    disabled={!nodeInfo?.features?.attendanceConfig.totalShortFall}
                    type="checkbox"
                    name="totalShortFall"
                    checked={tempFormat[i].calculations.totalShortFall}
                    onChange={(e) => handleChange(e, "calculations", i)}
                  />
                  <CheckBoxLabel>Total Short Fall</CheckBoxLabel>
                </CheckBoxContainer>
                <CheckBoxContainer>
                  <LockIconContainer>
                    {nodeInfo?.features?.attendanceConfig.totalhalfDays ? null : (
                      <DisabledLockIcon size={"1rem"} />
                    )}
                  </LockIconContainer>
                  <CheckBox
                    disabled={!nodeInfo?.features?.attendanceConfig.totalhalfDays}
                    type="checkbox"
                    name="totalhalfDays"
                    checked={tempFormat[i].calculations.totalhalfDays}
                    onChange={(e) => handleChange(e, "calculations", i)}
                  />
                  <CheckBoxLabel>Total Half Days</CheckBoxLabel>
                </CheckBoxContainer>
                <CheckBoxContainer>
                  <LockIconContainer>
                    {nodeInfo?.features?.attendanceConfig.totalDays ? null : (
                      <DisabledLockIcon size={"1rem"} />
                    )}
                  </LockIconContainer>
                  <CheckBox
                    disabled={!nodeInfo?.features?.attendanceConfig.totalDays}
                    type="checkbox"
                    name="totalDays"
                    checked={tempFormat[i].calculations.totalDays}
                    onChange={(e) => handleChange(e, "calculations", i)}
                  />
                  <CheckBoxLabel>Total Days</CheckBoxLabel>
                </CheckBoxContainer>
                <CheckBoxContainer>
                  <LockIconContainer>
                    {nodeInfo?.features?.attendanceConfig.totalWorkingDays ? null : (
                      <DisabledLockIcon size={"1rem"} />
                    )}
                  </LockIconContainer>
                  <CheckBox
                    disabled={!nodeInfo?.features?.attendanceConfig.totalWorkingDays}
                    type="checkbox"
                    name="totalWorkingDays"
                    checked={tempFormat[i].calculations.totalWorkingDays}
                    onChange={(e) => handleChange(e, "calculations", i)}
                  />
                  <CheckBoxLabel>Total Working Days</CheckBoxLabel>
                </CheckBoxContainer>
                <CheckBoxContainer>
                  <LockIconContainer>
                    {nodeInfo?.features?.attendanceConfig.totalPrms ? null : (
                      <DisabledLockIcon size={"1rem"} />
                    )}
                  </LockIconContainer>
                  <CheckBox
                    disabled={!nodeInfo?.features?.attendanceConfig.totalPrms}
                    type="checkbox"
                    name="totalPrms"
                    checked={tempFormat[i].calculations.totalPrms}
                    onChange={(e) => handleChange(e, "calculations", i)}
                  />
                  <CheckBoxLabel>Total PRMs</CheckBoxLabel>
                </CheckBoxContainer>
              </div>
            </div>
          </div>
        ))}
      </div>

      <div className="text-center my-3">
        <DownloadButton onClick={updateNodeInfo}>
          <ButtonState loading={apiStatus[apiEndPoints.updateNodeInfoAPI]} innerText={"Save Format"} />
        </DownloadButton>
      </div>
    </FormWrapper>
  );
};

export default AttendanceFormatForm;
