import React from "react";
import { useEffect } from "react";
import { useCookies } from "react-cookie";
import { logout } from "../utils/logout";
const Logout = ({ removeCookie, history, dispatch }) => {
  // const [cookies, setCookie, removeCookie] = useCookies(["AT"]);

  useEffect(() => {
    logout(dispatch, removeCookie, history);
  }, []);

  return (
    <>
      <div>...logging out</div>
    </>
  );
};

export default Logout;
