import React from "react";
import styled from "styled-components";
import { BiLock } from "react-icons/bi";
import { Tooltip } from "react-hover-tooltip";
const DisabledOverlay = ({ size }) => {
  return <Wrapper></Wrapper>;
};

export default DisabledOverlay;

const Wrapper = styled.div`
  background-color: rgba(255, 255, 255, 0.85);
  position: absolute;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: red;
  font-weight: bold;
  top: 0;
  bottom: 0;
  z-index: 44;
`;

export const DisabledLockIcon = ({ size, className, msg }) => {
  return (
    <div className={`${className} d-flex justify-content-end align-items-center`}>
      <Tooltip
        content={
          <div>
            {msg ||
              "This feature is not available in your current plan (Basic). Please contact SecurePass support team on phone or e-mail to upgrade your plan."}
          </div>
        }
      >
        <BiLock style={{ fontSize: size, color: "red" }} />
      </Tooltip>
    </div>
  );
};
