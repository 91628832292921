import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import Form from "../Components/Form";
import QRwelcomePage from "../Components/QRwelcomePage";
import { get, post } from "../Services/apiService";
import {
  createNotification,
  formatDate,
  getQueryParams,
  notificationType,
} from "../Services/utils";
import { NotificationManager } from "react-notifications";
import Header from "../Components/Header";
import { rootApi } from "../../config";
import { sanitizeReqBody } from "../../utils/utilFunctions";
import { useDispatch } from "react-redux";
const permission = (nodeId) =>
  JSON.stringify([
    {
      nodeId,
      daysofWeek: [true, true, true, true, true, true, true],
      startTimeInDay: 0,
      endTimeInDay: 1440,
      CRB: false,
      visitorManagement: false,
    },
  ]);

const Qr = () => {
  const [formData, setFormData] = useState({
    companyName: "",
    firstName: "",
    lastName: "",
    purpose: "",
    contact: "",
    accessInfo: "",
    nodeId: "",
    startTime: "",
    endTime: "",
    noOfGuests: "",
  });
  const dispatch = useDispatch();
  const [filled, setFilled] = useState(false);
  const [selectedNode, setSelectedNode] = useState([]);
  const [error, setError] = useState({});
  const [success, setSuccess] = useState(false);
  const [params, setParams] = useState(null);
  const [node, setNode] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const getNodeInfo = async (params) => {
    if (params.qr) {
      const url = `${rootApi}node/visitor/qrDetails?qr=${params.qr}`;
      try {
        const response = await get(dispatch, url);
        if (!response.data.success) {
          createNotification(
            NotificationManager,
            notificationType.error,
            response.data.error
          );
        } else {
          setNode(response.data);
        }
      } catch (err) {
        throw err;
      }
    } else if (params.deviceQR) {
      const url = `${rootApi}node/qrCode?deviceQRCode=${params.deviceQR}`;
      try {
        const response = await get(dispatch, url);
        if (!response.data.success) {
          createNotification(
            NotificationManager,
            notificationType.error,
            response.data.error
          );
        } else {
          getNodeInfo({ qr: response.data.nodeQRCode });
        }
      } catch (err) {
        throw err;
      }
    }
  };

  const checkError = (e, idTxt) => {
    console.log(e.target.value);
    if (e.target.value.length !== 0) {
      setError({ ...error, [idTxt]: false });
    } else {
      setError({ ...error, [idTxt]: true });
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "name&Number") {
      setFormData({ ...formData, name: value[0], contact: value[1] });
    } else if (name === "endTime") {
      setEndTime(e.target.value);
    } else if (name === "startTime") {
      setStartTime(e.target.value);
    } else if (name === "slots") {
      setFormData({
        ...formData,
        startTime: JSON.parse(value).startTime,
        endTime: JSON.parse(value).endTime,
      });
    } else if (name === "purpose" && value === "Purpose of Visit") {
      setFormData({ ...formData, purpose: "" });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

   useEffect(() => {
     setFormData({
       ...formData,
       startTime: new Date(startTime).getTime() / 1000,
       endTime: new Date(endTime).getTime() / 1000,
     });
   }, [startTime, endTime]);

  useEffect(() => {
    if (node) {
      setFormData({
        ...formData,
        nodeId: node.nodeId,
        accessInfo: permission(node.nodeId),
      });
    } else {
      setFormData({ ...formData, nodeId: "", accessInfo: "" });
    }
  }, [node]);

  const handleSubmit = async () => {
    const url = `${rootApi}node/visitor/selfRequest`;
    let body = {
      ...formData,
    };
    if (body.name && body.name.length) {
      let n = body.name.split(" ");
      delete body.name;
      let ln = "";
      for (let i = 1; i < n.length; i++) {
        ln = ln + " " + n[i];
      }
      body.firstName = n[0];
      body.lastName = ln.length > 0 ? ln : "-";
    }
    if (body.contact.includes("@")) {
      let emailId = body.contact;
      delete body.contact;
      body.emailId = emailId;
    } else {
      let mobileNo = body.contact;
      delete body.contact;
      body.mobileNo = mobileNo;
    }
    if (body.userToMeetContact) {
      if (body.userToMeetContact.includes("@")) {
        let userToMeetEmailId = body.userToMeetContact;
        delete body.userToMeetContact;
        body.userToMeetEmailId = userToMeetEmailId;
      } else {
        let userToMeetMobileNo = body.userToMeetContact;
        delete body.userToMeetContact;
        body.userToMeetMobileNo = userToMeetMobileNo;
      }
    }
    if (!selectedNode.maxAddonGuests) {
      delete body.noOfGuests;
    }
    body = sanitizeReqBody(body);
    try {
      const response = await post(dispatch, url, body);
      if (!response.data.success) {
        createNotification(
          NotificationManager,
          notificationType.error,
          response.data.error
        );
      } else {
        setSuccess(true);
      }
    } catch (err) {
      console.log("request Failed");
    }
  };

  useEffect(() => {
    if (params) {
      getNodeInfo(params);
    }
  }, [params]);

  useEffect(() => {
    // console.log(getQueryParams(window.location.search));
    setParams({ ...getQueryParams(window.location.search) });
  }, [window.location.search]);

  return (
    <div className="mt-5 pt-5">
      <Header />
      <div className="row m-0 w-100">
        {success ? <Redirect to="/vms/add/success" /> : null}
        {showForm ? (
          filled ? (
            <></>
          ) : (
            // <Confirmation
            //   handleSubmit={handleSubmit}
            //   setFilled={setFilled}
            //   formData={formData}
            //   formatDate={formatDate}
            // />
            <Form
              startTime={startTime}
              setStartTime={setStartTime}
              endTime={endTime}
              setEndTime={setEndTime}
              mountedOnQR={true}
              handleChange={handleChange}
              checkError={checkError}
              error={error}
              handleSubmit={handleSubmit}
              setError={setError}
              params={params}
              selectedNode={node}
              setFilled={setFilled}
              formatDate={formatDate}
              setFormData={setFormData}
              formData={formData}
            />
          )
        ) : (
          <QRwelcomePage node={node} setShowForm={setShowForm} />
        )}
      </div>
    </div>
  );
};

export default Qr;
