import React, { useEffect, useState } from "react";
import CsvDownload from "react-json-to-csv";
import { useSelector } from "react-redux";
import { typeCode } from "../../config";
import { sendLogFileDownloadEvent } from "../../utils/tagManagerEvents";
import { formatTemp, getUserRole, nullChecker } from "../../utils/utilFunctions";

function AttendanceCSVDownload({
  csvEndDate,
  csvStartDate,
  loading,
  allMemberships,
  csvData,
  userIds,
  department,
  subDepartment,
  role,
}) {
  const [ids, setIds] = useState(null);
  const [data, setData] = useState(null);
  const user = useSelector((state) => state.user);
  const nodeInfo = useSelector((state) => state.nodeInfo);

  useEffect(() => {
    if (csvData && ids) {
      let data = [...csvData];
      let filter = [...ids];
      let d = data.filter((d) => filter.includes(d.userId));
      console.log("2",d[0],d[1],d.length)
      let c = d.map((a) => {
        let t = { ...a };
        let {
          firstName,
          lastName,
          recordType,
          accessType,
          deviceTimestamp,
          UDId,
          designation,
          doorName,
          temperature,
        } = a;
        temperature = formatTemp(temperature, nullChecker);
        deviceTimestamp = new Date(deviceTimestamp).toString().slice(0, 24);
        recordType = typeCode[`${recordType}`];
        return {
          firstName,
          lastName,
          recordType,
          accessType,
          deviceTimestamp,
          UDId,
          designation,
          doorName,
          temperature,
        };
      });
      setData([...c]);
    }
  }, [csvData, ids]);

  useEffect(() => {
    if (allMemberships) {
      let activeEmployees = [...allMemberships];
      if (department.length && subDepartment.length) {
        activeEmployees = activeEmployees.filter(
          (v) => v.depId === department && v.subDepId === subDepartment
        );
      } else if (department.length && subDepartment.length === 0) {
        activeEmployees = activeEmployees.filter((v) => v.depId === department);
      }
      if (role) {
        activeEmployees = activeEmployees.map((v) => ({
          ...v,
          role: v.roles.filter((nv) => nv.nodeId === nodeInfo.nodeId),
        }));
        activeEmployees = activeEmployees.filter(
          (v) => v.role && v.role.length && v.role[0].role === role
        );
      }
      if (userIds && Object.keys(userIds).length) {
        let users = Object.keys(userIds);
        activeEmployees = activeEmployees.filter((a) => users.includes(a.userId));
      }
      setIds([...activeEmployees.map((a) => a.userId)]);
    }
  }, [userIds, department, subDepartment, role, allMemberships]);

  return (
    <span
      onClick={(e) => {
        sendLogFileDownloadEvent(
          user.userId,
          nodeInfo.name,
          nodeInfo.nodeCategory,
          getUserRole(user.roles, nodeInfo),
          parseInt(
            (new Date(csvEndDate).setHours(23, 59, 59, 999) -
              new Date(csvStartDate).setHours(0, 0, 0, 0)) /
              (1000 * 3600 * 24)
          )
        );
      }}
    >
      <CsvDownload
        className="solid-button mr-2"
        disabled={!data || !data.length}
        data={data}
        delimiter=","
        name={"Name"}
        filename={`Attendance-Report-${new Date(csvStartDate).toString().slice(0, 15)}-${new Date(
          csvEndDate
        )
          .toString()
          .slice(0, 15)}.csv`}
      >
        {loading ? (
          <div className="spinner-border spinner-border-sm" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        ) : !data || !data.length ? (
          "No Data"
        ) : (
          "Download CSV"
        )}
      </CsvDownload>
    </span>
  );
}

export default AttendanceCSVDownload;
