import React, { useEffect } from "react";
import { SolidButton } from "../components/buttons/solidButton.styled";
import ActiveDevices from "../components/devices/activeDevices/ActiveDevices";
import InActiveDevices from "../components/devices/inActiveDevices/InActiveDevices";
import { confirmAlert } from "react-confirm-alert";
import { useSelector } from "react-redux";
import { sendCustomPageView } from "../utils/tagManagerEvents";
import { getUserRole } from "../utils/utilFunctions";

const activateAlert = async () => {
  confirmAlert({
    title: "Device Activation",
    message:
      "Please go the zones page where you want to add this device, create a door and then assign this device to that door",
    buttons: [
      {
        label: "OK",
        onClick: () => null,
      },
    ],
  });
};
const Devices = () => {
  const user = useSelector((state) => state.user);
  const nodeInfo = useSelector((state) => state.nodeInfo);
  useEffect(() => {
    sendCustomPageView(
      user.userId,
      nodeInfo.name,
      nodeInfo.nodeCategory,
      getUserRole(user.roles, nodeInfo)
    );
  }, [nodeInfo, user]);
  return (
    <div>
      <div className="d-flex align-items-center justify-content-between my-2">
        <h5 className="font-weight-bold">Devices</h5>
        {/* <SolidButton>Add new device</SolidButton> */}
      </div>
      <ActiveDevices />
      <div className="d-flex align-items-center justify-content-between mt-5">
        <h5 className="font-weight-bold">Not Assigned</h5>
      </div>
      <InActiveDevices activateDevice={activateAlert} />
    </div>
  );
};

export default Devices;
