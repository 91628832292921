import React, { useState } from "react";
import { useCookies } from "react-cookie";
import NotificationManager from "react-notifications/lib/NotificationManager";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { rootApi } from "../../../config";
import { get, post } from "../../../utils/apiService";
import { ATexpired } from "../../../utils/logout";
import { createNotification, notificationType } from "../../../vms/Services/utils";
import { jsPDF } from "jspdf";
import QRCode from "qrcode";


const DeviceQRCode = ({ user }) => {
  const [cookies, setCookie, removeCookie] = useCookies(["AT"]);
  const dispatch = useDispatch();
  const history = useHistory();
  const [response, setResponse] = useState(null);
  const [allUrls, setAllUrls] = useState([]);
  const [downloading, setDownloading] = useState(false);

  const getDeviceQrCodes = async (e) => {
    setResponse(null);
    setDownloading(false);
    e.preventDefault();
    const url = `${rootApi}device/qrCodes?fromCustomDeviceId=${e.target.fromCustomDeviceId.value}&toCustomDeviceId=${e.target.toCustomDeviceId.value}`;
    try {
      const response = await get(dispatch, url, user.AT);

      if (!response.data.success) {
        createNotification(NotificationManager, notificationType.error, response.data.error);
      } else {
        const { error, success, message, devices } = response.data;
        setResponse({ error, success, message, devices });
      }
      ATexpired(response, dispatch, removeCookie, history);
    } catch (err) {
      console.log("unable to fetch device qr codes");
    }
  };

  const downloadAllQRCode = async () => {
    setDownloading(`Processing`);
    const format = [36.72, 22.083];
    let doc = new jsPDF({
      orientation: "p",
      unit: "in",
      format,
    });
    const posterUrl = "https://securepass-static-assets.s3.ap-south-1.amazonaws.com/qrposter.svg";
    // `${cloudFrontOrigin}50182.svg`;
    const canvas = document.getElementById(`myCanvas22`);
    // append qr image to canvas
    const context = canvas.getContext("2d");
    const bg = new Image();
    bg.src = posterUrl;
    bg.setAttribute("crossorigin", "anonymous");
    bg.onload = function () {
      response.devices.forEach(async ({ deviceQRCode, customDeviceId }, i) => {
        setDownloading(`Processing ${i + 1} of ${response.devices.length}`);
        QRCode.toDataURL(`https://dashboard.thesecurepass.com/vms/qr?deviceQR=${deviceQRCode}`)
          .then((qrurl) => {
            loadImage(qrurl)
              .then((qr) => {
                context.drawImage(bg, 0, 0);
                context.drawImage(qr, 470, 2058, 1180, 1180);
                context.font = "bold 50px Roboto white";
                context.fillStyle = "#ffffff";
                context.textAlign = "center";
                context.fillText(customDeviceId, canvas.width / 2, canvas.height - 100);
                const pngUrl = canvas.toDataURL("image/jpeg");
                if (i > 0) {
                  doc.addPage(format, "p");
                }
                doc.addImage(pngUrl, "JPEG", 0, 0);
                setDownloading(`Processed ${i + 1} of ${response.devices.length}`);
                if (i === response.devices.length - 1) {
                  doc.save("allQRS.pdf", { returnPromise: true }).then(() => {
                    setDownloading("PDF file downloaded successfully...");
                  });
                  setDownloading("Preparing PDF for downloading, please wait..");
                }
                context.clearRect(0, 0, canvas.width, canvas.height);
              })
              .catch((error) => console.log(error, i));
          })
          .catch((error) => console.log(error));
      });
    };
  };

  const loadImage = (url) => {
    return new Promise((resolve, reject) => {
      const qr = new Image(1180, 1180);
      qr.addEventListener("load", () => {
        resolve(qr);
      });
      qr.addEventListener("error", () => {
        reject("error loading image (QR)");
      });

      qr.width = 1180;
      qr.height = 1180;
      qr.setAttribute("crossorigin", "anonymous");
      qr.src = url;
    });
  };

  return (
    <div className="py-5 px-2 text-center">
      <form onSubmit={getDeviceQrCodes}>
        <div className="form-group">
          <input
            placeholder="From Custom Device ID"
            type="text"
            className="form-control"
            required
            name="fromCustomDeviceId"
          />
        </div>
        <div className="form-group">
          <input
            placeholder="To Custom Device ID"
            type="text"
            className="form-control"
            required
            name="toCustomDeviceId"
          />
        </div>
        <button type="submit" className="btn btn-primary">
          Submit
        </button>
      </form>
      {response ? (
        <>
          <div className="text-center">
            <h4 className={`${response.success ? "text-success" : "text-danger"} my-4 font-weight-bold`}>
              QR Codes Fetched
            </h4>
          </div>
          <div className="container border border-sm rounded shadow shadow-sm">
            <div className="row">
              {response.devices.length ? (
                <>
                  <div className="col-12 my-4 text-center">
                    {downloading ? (
                      <p>{downloading}</p>
                    ) : (
                      <p>{response.devices.length} qrcodes fetched</p>
                    )}
                  </div>
                  <div className="col-12 my-4">
                    <button
                      className="w-100 btn btn-warning btn-sm"
                      style={{ outline: "0" }}
                      id="downloadButton"
                      onClick={(e) => downloadAllQRCode()}
                    >
                      Download All
                    </button>
                  </div>
                </>
              ) : (
                <div className="col-12">
                  <h4 className={`${"text-danger"} text-center my-4 font-weight-bold`}>
                    No QR Codes For Entered DeviceID range
                  </h4>
                </div>
              )}
            </div>
          </div>
        </>
      ) : null}
      <canvas style={{ display: "none" }} id={`myCanvas22`} width="2120" height="3525"></canvas>
    </div>
  );
};

export default DeviceQRCode;
