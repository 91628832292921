import React from "react";
import {
  ZoneDeviceRestrictionCardContainer,
  Title,
  ToggleBox,
  ToggleTitle,
} from "./ZoneDeviceRestrictionCard.styles";
import { SolidButton } from "../../../buttons/solidButton.styled";
import GreenSwitch from "../../../switches/GreenSwitch";
import { useDispatch, useSelector } from "react-redux";
import { useCookies } from "react-cookie";
import { useHistory, useLocation } from "react-router-dom";
import NotificationManager from "react-notifications/lib/NotificationManager";
import { toggleForm } from "../../../../utils/utilFunctions";
import { formNames } from "../../../../config";
import { updateNodeInfoAPI } from "../../../../utils/requests";
import { confirmAlert } from "react-confirm-alert";
import DisabledOverlay, { DisabledLockIcon } from "../../../DisabledOverlay";

const ZoneDeviceRestrictionCard = () => {
  const location = useLocation();
  let history = useHistory();
  const dispatch = useDispatch();
  const [cookies, setCookie, removeCookie] = useCookies(["AT"]);
  // const [showUserDeviceRequests, setshowUserDeviceRequests] = useState(false);
  const nodeInfo = useSelector((state) => state.nodeInfo);
  const user = useSelector((state) => state.user);
  const selectedNode = useSelector((state) => state.selectedNode);
  const formState = useSelector((state) => state.formState);
  const updateNodeInfo = async (reqBody) => {
    let body = { nodeId: selectedNode.nodeId, ...reqBody };

    if (body.deviceRestriction === true) {
      confirmAlert({
        title: " Confirm User Device Restriction",
        message:
          "Please take extreme care while enabling this feature. Once enabled, all users in your organization will be locked out of using multiple Smartphones and will be logged out automatically.",
        buttons: [
          {
            label: "Yes",
            onClick: async () => {
              await updateNodeInfoAPI(
                user,
                dispatch,
                NotificationManager,
                removeCookie,
                history,
                selectedNode,
                body
              );
            },
          },
          {
            label: "No",
            onClick: () => null,
          },
        ],
      });
    } else {
      await updateNodeInfoAPI(
        user,
        dispatch,
        NotificationManager,
        removeCookie,
        history,
        selectedNode,
        body
      );
    }
  };

  return (
    <ZoneDeviceRestrictionCardContainer>
      <div className="d-flex">
        {nodeInfo?.features?.allowDeviceRestriction ? null : (
          <div className="mr-3 d-flex align-items-center justify-content-center">
            <DisabledLockIcon size="1rem" />
          </div>
        )}
        <div style={{ position: "relative" }} className="w-100">
          {nodeInfo?.features?.allowDeviceRestriction ? null : <DisabledOverlay />}
          <Title>Device Restriction</Title>
          <ToggleBox>
            <ToggleTitle>Limit 1 mobile per user only</ToggleTitle>
            <GreenSwitch
              checked={nodeInfo.deviceRestriction || false}
              onChange={(e) => updateNodeInfo({ deviceRestriction: !nodeInfo.deviceRestriction })}
            />
          </ToggleBox>
          <div className="d-flex align-items-center justify-content-end mt-4">
            {nodeInfo.deviceRestriction ? (
              <SolidButton
                onClick={(e) =>
                  toggleForm(formState, history, location, dispatch, formNames.userDeviceRequestsForm)
                }
              >
                View Pending Requests
              </SolidButton>
            ) : null}
          </div>
        </div>
      </div>
    </ZoneDeviceRestrictionCardContainer>
  );
};

export default ZoneDeviceRestrictionCard;
