import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import Auth from "./Auth";
import Main from "./Main";
import useDimensions from "react-use-dimensions";
import { parseQueryParams } from "./utils/utilFunctions";
import { updateDimentions } from "./redux/dimentions/dimentionsActions";
import { updateUser } from "./redux/user/userActions";
import { useCookies } from "react-cookie";
import { logout } from "./utils/logout";

function App() {
  const [cookies, setCookie, removeCookie] = useCookies(["AT"]);
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector((state) => state.user);
  const [params, setParams] = useState({});
  const [main, dimentions] = useDimensions();
  const [cameTo, setCameTo] = useState(null);
  useEffect(() => {
    if (location.search.length) {
      let path = location.pathname + location.search;
      setParams(parseQueryParams(location.search));
      if (parseQueryParams(location.search).AT && (!user || !user.AT)) {
        dispatch(
          updateUser({
            AT: parseQueryParams(location.search).AT,
            initialPath: path ? path : "/apps",
          })
        );
      } else if (
        parseQueryParams(location.search).AT &&
        user.AT &&
        parseQueryParams(location.search).AT !== user.AT
      ) {
        logout(dispatch, removeCookie, history, false, true);
      }
    }
  }, [location.search]);

  useEffect(() => {
    setCameTo(location.pathname + location.search);
  }, []);

  useEffect(() => {
    let dim = dimentions;
    delete dim.top;
    delete dim.left;
    delete dim.x;
    delete dim.y;
    delete dim.right;
    delete dim.bottom;
    dispatch(updateDimentions(dim));
  }, [dimentions]);

  return (
    <div ref={main}>
      {user ? <Main params={params} /> : <Auth params={params} cameTo={cameTo} setParams={setParams} />}
    </div>
  );
}

export default App;
