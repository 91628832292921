import * as FileSaver from "file-saver";
import * as ExcelJS from "exceljs";
import { xlTemplate } from "../../utils/excelTemplate";
import { decodeTime, encodeTime, getTimeFromUNIX } from "../../utils/utilFunctions";
import { realJsDayMap, yearMap, yearMapShort } from "../../config";

export const generateAndDownloadSheet = async (
  sd,
  ed,
  logs,
  allMemberships,
  membershipsHaveAccess,
  department,
  subDepartment,
  weekOffs,
  format,
  nodeInfo,
  role,
  userIds
) => {

  const dateToTimestamp = (d) => new Date(d).setHours(0, 0, 0, 0);
  const isExpiredOnThisDate = (arrOfTimestamps, dateString) => {
    let expired = true;
    let timestampoftheday = dateToTimestamp(dateString);
    // console.log(timestampoftheday);
    arrOfTimestamps.forEach((s) => {
      let [start, end] = s;
      if (timestampoftheday >= start && timestampoftheday <= end) {
        expired = false;
      }
    });
    return expired;
  };
  let { holidayList, considerOnlyInTime } = { ...nodeInfo };
  holidayList = holidayList.map((h) => getDate(h.timestamp * 1000)[0]);
  // console.log(holidayList);
  const fileName = `Attendance Report ${new Date(sd).getDate()}-${
    yearMap[new Date(sd).getMonth()]
  } to ${new Date(ed).getDate()}-${yearMap[new Date(ed).getMonth()]}`;
  let dates = [];
  let employees = {};
  let dailyReport = {};

  let dayCounts = parseInt(
    (new Date(ed).setHours(23, 59, 59, 999) - new Date(sd).setHours(0, 0, 0, 0)) / (1000 * 3600 * 24)
  );
  dayCounts = dayCounts === 0 ? 1 : dayCounts + 1;
  let i = 1;
  let start = new Date(sd).setHours(0, 0, 0, 0);
  while (i <= dayCounts) {
    if (i === 1) {
      dates = [...dates, getDate(start)];
    } else {
      start = start + 1000 * 3600 * 24;
      dates = [...dates, getDate(start)];
    }
    i++;
  }
  let allUserIds = allMemberships.map((i) => i.userId);

  allUserIds = new Set([...allUserIds]);
  let membershipsInfoAssociatedToAUserId = {};

  allUserIds.forEach((uid) => {
    membershipsInfoAssociatedToAUserId[uid] = [];
    let mems = allMemberships.filter((m) => m.userId === uid);
    mems.forEach((m) =>
      membershipsInfoAssociatedToAUserId[uid].push([
        parseInt(new Date(m.startTime * 1000).setHours(0, 0, 0, 0)),
        m.endTime ? parseInt(new Date(m.endTime * 1000).setHours(23, 59, 59, 999)) : 10424970009000,
      ])
    );
  });

  // console.log({ allMemberships: allMemberships.filter((v) => v.UDId === "DL00061") });


  // removed as we are now including expired in report as well.

  // let activeEmployees = allMemberships.filter((v) => {
  //   return (
  //     !v.startTime ||
  //     (v.startTime <= parseInt(new Date(ed).setHours(23, 59, 59, 999) / 1000) &&
  //       (!v.endTime || v.endTime <= parseInt(new Date(ed).setHours(23, 59, 59, 999) / 1000)))
  //   );
  // });

  // console.log({ activeEmployees: activeEmployees.filter((v) => v.UDId === "DL00061") });

  let activeEmployees = [...allMemberships];

  if (department.length && subDepartment.length) {
    activeEmployees = activeEmployees.filter(
      (v) => v.depId === department && v.subDepId === subDepartment
    );
  } else if (department.length && subDepartment.length === 0) {
    activeEmployees = activeEmployees.filter((v) => v.depId === department);
  }
  if (role) {
    activeEmployees = activeEmployees.map((v) => {
      return {
        ...v,
        role: v.roles.filter((nv) => nv.nodeId === nodeInfo.nodeId),
      };
    });

    activeEmployees = activeEmployees.filter((v) => v.role && v.role.length && v.role[0].role === role);
  }

  activeEmployees.forEach((i) => {
    if (employees[i.userId]) return;
    if (Object.keys(userIds).length) {
      if (Object.keys(userIds).includes(i.userId)) {
        employees[i.userId] = {
          firstName: `${i.userInfo.firstName ? i.userInfo.firstName : ""}`,
          lastName: `${i.userInfo.lastName ? i.userInfo.lastName : ""}`,
          emailId: i.userInfo.email.id ? i.userInfo.email.id : "",
          mobileNo: i.userInfo.mobile.number ? i.userInfo.mobile.number : "",
          UDID: i.UDId ? i.UDId : "",
          designation: i.designation ? i.designation : "",
          department: i.depName ? i.depName : "",
          subDepartment: i.subDepName ? i.subDepName : "",
          userType: i.role ? i.role : "",
          days: {},
        };
        dates.forEach((d) => {
          employees[i.userId].days[d[0]] = {
            inTime: "-",
            outTime: "-",
            firstInToLastOut: "",
            totalInTime: "",
            overtime: "",
            lateBy: "",
            earlyBy: "",
            punchRecords: "",
            punchPairs: [],
            daySummary: "",
            expired: isExpiredOnThisDate(membershipsInfoAssociatedToAUserId[i.userId], d[0]),
          };
          dailyReport[d[0]] = {
            A: 0,
            P: 0,
            HD: 0,
            SF: 0,
            PRM: 0,
            EXPIRED_MEMBERS: 0,
          };
          // if (d[0].length && !weekOffs.includes(d[1])) {
          // } else if (d[0].length && weekOffs.includes(d[1])) {
          //   employees[i.userId] = {
          //     ...employees[i.userId],
          //     [d[0]]: {
          //       ...employees[i.userId][d[0]],

          //       inTime: "WO",
          //       outTime: "WO",
          //       firstInToLastOut: "",
          //       totalInTime: "",
          //       overtime: "",
          //       lateBy: "",
          //       earlyBy: "",
          //       punchRecords: "WO",
          //       daySummary: "",
          //     },
          //   };
          // }
        });
      }
    } else {
      employees[i.userId] = {
        firstName: `${i.userInfo.firstName ? i.userInfo.firstName : ""}`,
        lastName: `${i.userInfo.lastName ? i.userInfo.lastName : ""}`,
        emailId: i.userInfo.email.id ? i.userInfo.email.id : "",
        mobileNo: i.userInfo.mobile.number ? i.userInfo.mobile.number : "",
        UDID: i.UDId ? i.UDId : "",
        designation: i.designation ? i.designation : "",
        department: i.depName ? i.depName : "",
        subDepartment: i.subDepName ? i.subDepName : "",
        userType: i.role ? i.role : "",
        days: {},
      };
      dates.forEach((d) => {
        employees[i.userId].days[d[0]] = {
          inTime: "-",
          outTime: "-",
          firstInToLastOut: "",
          totalInTime: "",
          overtime: "",
          lateBy: "",
          earlyBy: "",
          punchRecords: "",
          punchPairs: [],
          daySummary: "",
          expired: isExpiredOnThisDate(membershipsInfoAssociatedToAUserId[i.userId], d[0]),
        };
        dailyReport[d[0]] = {
          A: 0,
          P: 0,
          HD: 0,
          SF: 0,
          PRM: 0,
          EXPIRED_MEMBERS: 0,
        };

        // if (d[0].length && !weekOffs.includes(d[1])) {
        // } else if (d[0].length && weekOffs.includes(d[1])) {
        //   employees[i.userId] = {
        //     ...employees[i.userId],
        //     [d[0]]: {
        //       ...employees[i.userId][d[0]],

        //       inTime: "WO",
        //       outTime: "WO",
        //       firstInToLastOut: "",
        //       totalInTime: "",
        //       overtime: "",
        //       lateBy: "",
        //       earlyBy: "",
        //       punchRecords: "WO",
        //       daySummary: "",
        //     },
        //   };
        // }
      });
    }
  });

  for (let i = logs.length - 1; i >= 0; i--) {
    let date = getDate(logs[i].deviceTimestamp)[0];
    let day = getDate(logs[i].deviceTimestamp)[1];
    let time = getTimeFromUNIX(logs[i].deviceTimestamp);

    // ! setting punchRecords
    if (logs[i].userId && employees[logs[i].userId] && employees[logs[i].userId].days[date]) {
      employees[logs[i].userId].days[date].punchRecords = `${
        employees[logs[i].userId].days[date].punchRecords
      } ${time} ${logs[i].accessType}`;
    }

    // ! setting first inTime for the day
    if (
      logs[i].userId &&
      logs[i].userId.length &&
      logs[i].accessType === "IN" &&
      employees[logs[i].userId] &&
      employees[logs[i].userId].days[date] &&
      employees[logs[i].userId].days[date].inTime === "-"
    ) {
      employees[logs[i].userId].days[date].inTime = time;
      employees[logs[i].userId].days[date].lateBy =
        encodeTime(time) - nodeInfo.startTimeInDay > 0
          ? `${decodeTime(encodeTime(time) - nodeInfo.startTimeInDay)} Hrs`
          : "-";
      employees[logs[i].userId].days[date].earlyBy =
        nodeInfo.startTimeInDay - encodeTime(time) > 0
          ? `${decodeTime(nodeInfo.startTimeInDay - encodeTime(time))} Hrs`
          : "-";
      employees[logs[i].userId].days[date].outTime = "-";
    }
    // ! setting in records as 0th index of array for punchPairs array later to calculate actual in time
    // ! format will look like punchPairs = [ [ in, out ], [ in, out ] ]
    // ! setting it null in case of any inconsistency
    if (
      logs[i].userId &&
      logs[i].userId.length &&
      logs[i].accessType === "IN" &&
      employees[logs[i].userId] &&
      employees[logs[i].userId].days[date] &&
      employees[logs[i].userId].days[date].punchPairs
    ) {
      if (logs[i].tailgating === "YES") {
        employees[logs[i].userId].days[date].punchPairs = null;
      } else if (employees[logs[i].userId].days[date].punchPairs) {
        employees[logs[i].userId].days[date].punchPairs.push([time]);
      }
    }
    // ! gettign every out time of employee and setting it ias outTime
    if (
      !considerOnlyInTime &&
      logs[i].userId &&
      logs[i].userId.length &&
      logs[i].accessType === "OUT" &&
      employees[logs[i].userId] &&
      employees[logs[i].userId].days[date] &&
      employees[logs[i].userId].days[date].inTime !== "WO"
    ) {
      employees[logs[i].userId].days[date].outTime = time;
      // ! now that we have outTime calculating total duration from firstIn to lastOut
      if (employees[logs[i].userId].days[date].inTime !== "-") {
        employees[logs[i].userId].days[date].firstInToLastOut = `${decodeTime(
          encodeTime(time) - encodeTime(employees[logs[i].userId].days[date].inTime)
        )} Hrs`;
      }
    }
    // ! gettign every in time of employee and setting it ias outTime as considerOnlyInTime is true
    if (
      considerOnlyInTime &&
      logs[i].userId &&
      logs[i].userId.length &&
      logs[i].accessType === "IN" &&
      employees[logs[i].userId] &&
      employees[logs[i].userId].days[date] &&
      employees[logs[i].userId].days[date].inTime !== "WO"
    ) {
      employees[logs[i].userId].days[date].outTime = time;
      // ! now that we have outTime calculating total duration from firstIn to lastOut

      if (employees[logs[i].userId].days[date].inTime !== "-") {
        employees[logs[i].userId].days[date].firstInToLastOut = `${decodeTime(
          encodeTime(time) - encodeTime(employees[logs[i].userId].days[date].inTime)
        )} Hrs`;
      }
    }

    if (
      logs[i].userId &&
      logs[i].userId.length &&
      logs[i].accessType === "OUT" &&
      employees[logs[i].userId] &&
      employees[logs[i].userId].days[date] &&
      employees[logs[i].userId].days[date].punchPairs &&
      employees[logs[i].userId].days[date].punchPairs.length
    ) {
      // ! setting out records as 1st index of array for punchPairs array later to calculate actual in time
      // ! format will look like punchPairs = [ [ in, out ], [ in, out ] ]
      // ! setting it null in case of any inconsistency
      if (logs[i].tailgating === "YES") {
        employees[logs[i].userId].days[date].punchPairs = null;
      } else {
        employees[logs[i].userId].days[date].punchPairs[
          employees[logs[i].userId].days[date].punchPairs.length - 1
        ][1] = time;
      }
    }
    // ! calculating all the remaining values in the end of the loop when we have all the data for the report
    if (i === 0) {
      // ! extracting keys from employees Object in an array witch will hold all the user ids present in the report
      const ids = Object.keys(employees);
      // ! looping over keys
      for (let j = 0; j < ids.length; j++) {
        const id = ids[j];
        let totalInTimeSum = 0;
        let totalBreakTimeSum = 0;
        let totalPresent = 0;
        let totalWorkOff = 0;
        let totalAbsent = 0;
        let totalPresentPlusWorkoff = 0;
        let totalShortFall = 0;
        let totalhalfDays = 0;
        let totalDays = 0;
        let totalHolidays = 0;
        let totalWorkingDays = 0;
        let totalPrms = 0;
        let totalExpired = 0;
        for (let k = 0; k < dates.length; k++) {
          const date = dates[k][0],
            weekday = dates[k][1];

          // ! calculating total In time
          let prs = employees[id].days[date].punchPairs;
          let totalInTime = 0;
          if (prs) {
            for (let l = 0; l < prs.length; l++) {
              if (prs[l][1] && prs[l][0]) {
                totalInTime = totalInTime + encodeTime(prs[l][1]) - encodeTime(prs[l][0]);
              }
            }
          } else {
            totalInTime = "";
          }
          employees[id].days[date].totalInTime = totalInTime ? `${decodeTime(totalInTime)} Hrs` : "";
          // ! calculating Over Time time

          let overtime = "";
          if (totalInTime > nodeInfo.workHours) {
            overtime = `${decodeTime(totalInTime - nodeInfo.workHours)} Hrs`;
            employees[id].days[date].overtime = overtime;
          }
          let daySummary = "";

          // ! compare the totals on basis of nodeInfo.considerActualInTime

          let comparisonBase =
            nodeInfo.considerActualInTime && !considerOnlyInTime
              ? totalInTime
              : encodeTime(employees[id].days[date].firstInToLastOut);
          if (prs && prs.length && totalInTimeSum !== "" && totalBreakTimeSum !== "") {
            totalInTimeSum = totalInTimeSum + comparisonBase;

            totalBreakTimeSum =
              totalBreakTimeSum +
              encodeTime(employees[id].days[date].firstInToLastOut.split(" ")[0]) -
              totalInTime;
          } else {
            totalInTimeSum = "";
            totalBreakTimeSum = "";
          }

          //  ! A - absent - Absent on this day

          if (
            (comparisonBase >= 0 && comparisonBase < nodeInfo.halfDayMin) ||
            (employees[id].days[date].inTime === "-" && !weekOffs.includes(weekday)) ||
            employees[id].days[date].expired
          ) {
            if (employees[id].days[date].expired) {
              daySummary = "MEMBERSHIP EXPIRED ";
              totalExpired = totalExpired + 1;
              dailyReport[date].EXPIRED_MEMBERS = dailyReport[date].EXPIRED_MEMBERS + 1;
            } else {
              daySummary = "A ";
              totalAbsent = totalAbsent + 1;
              dailyReport[date].A = dailyReport[date].A + 1;
            }
          }
          //  ! HD - Half day - If a person has worked for less than minimum for full day but more than minimum for half day
          if (comparisonBase < nodeInfo.fullDayMin && comparisonBase >= nodeInfo.halfDayMin) {
            daySummary = "HD ";
            totalhalfDays = totalhalfDays + 1;
            dailyReport[date].HD = dailyReport[date].HD + 1;
          }
          //  ! SF - Shortfall - If person has worked for less then workHours but more than minimum for full day
          if (
            comparisonBase < nodeInfo.workHours &&
            comparisonBase >= nodeInfo.fullDayMin
            // ||            (employees[id].days[date].inTime !== "A" && !weekOffs.includes(weekday))
          ) {
            daySummary = "SF ";
            totalShortFall = totalShortFall + 1;
            dailyReport[date].SF = dailyReport[date].SF + 1;
          }
          //  ! P - present - if person is present and worked for > workHours set for this node
          if (comparisonBase >= nodeInfo.workHours) {
            daySummary = "P ";
            totalPresent = totalPresent + 1;

            dailyReport[date].P = dailyReport[date].P + 1;
          }
          // ! if incorrect punch records
          if (
            (employees[id].days[date].inTime !== "-" &&
              employees[id].days[date].outTime === "-" &&
              !weekOffs.includes(weekday)) ||
            (employees[id].days[date].inTime === "-" &&
              employees[id].days[date].outTime !== "-" &&
              !weekOffs.includes(weekday)) ||
            (nodeInfo.considerActualInTime && comparisonBase === "") ||
            !considerOnlyInTime
          ) {
            daySummary = "PRM ";
            dailyReport[date].PRM = dailyReport[date].PRM + 1;
            totalPrms = totalPrms + 1;
          }
          //  ! WO - Work off - If this day is a holiday \
          if (weekOffs.includes(weekday)) {
            daySummary = "WO ";
            totalWorkOff = totalWorkOff + 1;
          }
          //  ! Holidays  - If this day is a holiday \
          // console.log(holidayList.includes(date), date, holidayList, "holiday");
          if (holidayList.includes(date)) {
            daySummary = "Holiday ";
            totalHolidays = totalHolidays + 1;
          }
          if (considerOnlyInTime) {
          }
          employees[id].days[date].daySummary = daySummary;

          // ! do totals here if this is the last day of report
          if (k === dates.length - 1) {
            totalPresentPlusWorkoff = totalPresent + totalWorkOff;
            totalDays = dates.length;
            totalWorkingDays = totalDays - (totalWorkOff + totalHolidays);
            employees[id].totalInTimeSum =
              isNaN(totalInTimeSum) || !totalInTimeSum ? "" : decodeTime(totalInTimeSum);
            employees[id].totalBreakTimeSum =
              isNaN(totalBreakTimeSum) || !totalBreakTimeSum ? "" : decodeTime(totalBreakTimeSum);
            employees[id].totalPresent = totalPresent;
            employees[id].totalWorkOff = totalWorkOff + totalHolidays;
            employees[id].totalAbsent = totalAbsent;
            employees[id].totalPresentPlusWorkoff = totalPresentPlusWorkoff;
            employees[id].totalShortFall = totalShortFall;
            employees[id].totalhalfDays = totalhalfDays;
            employees[id].totalDays = totalDays;
            employees[id].totalWorkingDays = totalWorkingDays;
            employees[id].totalPrms = totalPrms;
            employees[id].totalExpired = totalExpired;
          }
        }
      }
    }
  }
  generateReport(employees, dates, nodeInfo, fileName, dailyReport);
};

const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
const fileExtension = ".xlsx";

const colors = {
  blue: "FF1F497D",
  mud: "FF948A54",
  absent: "FFFF0000",
  late: "FFFF6347",
  halfDay: "FFFF9933",
  fullDay: "FF32CD32",
  shortFall: "FF7FFF00",
  NA: "FFFF4500",
  holiday: "FFF0E68C",
  prm: "FFFF7F50",
};

const getDate = (t) => {
  return [
    `${new Date(t).getDate() < 10 ? "0" + new Date(t).getDate() : new Date(t).getDate()}-${
      yearMapShort[new Date(t).getMonth()]
    }-${new Date(t).getFullYear()}`,
    new Date(t).getDay(),
  ];
};

const generateReport = async (data, dates, nodeInfo, fileName, dailyReport) => {
  const workbook = new ExcelJS.Workbook();
  console.log(data);
  let { attendanceFormat, features } = nodeInfo;
  for (let i = 0; i < attendanceFormat.length; i++) {
    let f = attendanceFormat[i];
    let { suffix } = f;
    const getSuffixToggle = (s, n) => s.suffix[n];
    f = applyfeaturesToAttendanceFormat(f, features.attendanceConfig);
    const sheet = workbook.addWorksheet(f.name);
    let abbr = [
      `A (Absent) - If both IN and OUT logs of the user are missing (or) duration inside the facility is less than "Minimum Half Day Duration" set in the dashboard`,
      `P (Present) - If the user's total duration inside the facility is greater than "Work Hours" set in the dashboard`,
      `HD (Half Day) - If the user's total duration is less than "Minimum Full Day Duration" & greater than "Minimum Half Day Duration"`,
      `SF (Shortfall) - If the user's total duration is less than "Work Hours" and greater than "Minimum Full Day Duration"`,
      `WO / Holiday - If this day is marked as Holiday or Week Off`,
      `PRM (Punch Records Missing) - If either IN log or OUT log is missing.`,
    ];
    sheet.mergeCells(1, 1, 5, 2);
    sheet.getCell(1, 1).value = nodeInfo.name;
    sheet.getCell(1, 1).alignment = { horizontal: "center", vertical: "center" };
    sheet.getCell(1, 1).font = {
      size: 20,
      underline: false,
      bold: true,
    };

    sheet.mergeCells(6, 1, 6, 2);
    sheet.getCell(6, 1).value = fileName;
    sheet.getCell(6, 1).alignment = { horizontal: "center", vertical: "center" };
    sheet.getCell(6, 1).font = {
      bold: true,
    };
    abbr.forEach((v, i) => {
      sheet.mergeCells(1 + i, 3, 1 + i, 11);
      let cell = sheet.getCell(1 + i, 3);
      cell.value = v;

      if (
        v ===
        `P (Present) - If the user's total duration inside the facility is greater than "Work Hours" set in the dashboard`
      ) {
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: colors.fullDay },
        };
      }
      if (
        v ===
        `HD (Half Day) - If the user's total duration is less than "Minimum Full Day Duration" & greater than "Minimum Half Day Duration"`
      ) {
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: colors.halfDay },
        };
      }
      if (
        v ===
        `SF (Shortfall) - If the user's total duration is less than "Work Hours" and greater than "Minimum Full Day Duration"`
      ) {
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: colors.shortFall },
        };
      }
      if (
        v ===
        `A (Absent) - If both IN and OUT logs of the user are missing (or) duration inside the facility is less than "Minimum Half Day Duration" set in the dashboard`
      ) {
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: colors.absent },
        };
      }
      if (v === `PRM (Punch Records Missing) - If either IN log or OUT log is missing.`) {
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: colors.prm },
        };
      }
      if (v === `WO / Holiday - If this day is marked as Holiday or Week Off`) {
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: colors.mud },
        };
      }
    });

    let piCols = 0;
    let dailyReportsCols = 0;
    let calculationcols = 0;
    piCols = Object.values(f.personalInfo).filter((v) => v).length;
    dailyReportsCols = Object.values(f.dailyReports).filter((v) => v).length;
    calculationcols = Object.values(f.calculations).filter((v) => v).length;

    if (dailyReportsCols) {
      let start = piCols + 1;
      let end = start + dailyReportsCols - 1;
      for (let i = 0; i < dates.length; i++) {
        sheet.mergeCells(7, start, 7, end);
        sheet.mergeCells(8, start, 8, end);
        sheet.mergeCells(9, start, 9, end);
        sheet.getCell(7, start).value = realJsDayMap[dates[i][1]];
        sheet.getCell(8, start).value = dates[i][0];
        sheet.getCell(7, start).alignment = { horizontal: "center" };
        sheet.getCell(8, start).alignment = { horizontal: "center" };
        sheet.getCell(9, start).value = `${
          f.calculations.totalAbsent ? `A-${dailyReport[dates[i][0]].A}` : ``
        }${f.calculations.totalPresent ? ` P-${dailyReport[dates[i][0]].P}` : ``}${
          f.calculations.totalShortFall ? ` SF-${dailyReport[dates[i][0]].SF}` : ``
        }${f.calculations.totalhalfDays ? ` HD-${dailyReport[dates[i][0]].HD}` : ``}${
          f.calculations.totalPrms ? ` PRM-${dailyReport[dates[i][0]].PRM}` : ``
        }${f.calculations.totalPrms ? ` EXPIRED-${dailyReport[dates[i][0]].EXPIRED_MEMBERS}` : ``}`;
        sheet.getCell(9, start).alignment = { horizontal: "center" };
        start = end + 1;
        end = end + dailyReportsCols;
      }
    }

    Object.keys(data).forEach((k, i) => {
      let row = sheet.getRow(11 + i);
      let tmp = [];
      let topRow = [];
      if (f.personalInfo.firstName) {
        tmp.push(data[k].firstName);
        if (i === 0) topRow.push("First Name");
      }
      if (f.personalInfo.lastName) {
        tmp.push(data[k].lastName);
        if (i === 0) topRow.push("Last Name");
      }
      if (f.personalInfo.UDID) {
        tmp.push(data[k].UDID);
        if (i === 0) topRow.push("UDID");
      }
      if (f.personalInfo.emailId) {
        tmp.push(data[k].emailId);
        if (i === 0) topRow.push("Email");
      }
      if (f.personalInfo.mobileNo) {
        tmp.push(data[k].mobileNo);
        if (i === 0) topRow.push("Phone");
      }
      if (f.personalInfo.department) {
        tmp.push(data[k].department);
        if (i === 0) topRow.push("Department");
      }
      if (f.personalInfo.subDepartment) {
        tmp.push(data[k].subDepartment);
        if (i === 0) topRow.push("Sub Department");
      }
      if (f.personalInfo.designation) {
        tmp.push(data[k].designation);
        if (i === 0) topRow.push("Designation");
      }
      if (f.personalInfo.userType) {
        tmp.push(data[k].userType);
        if (i === 0) topRow.push("User Type");
      }

      Object.keys(data[k].days).forEach((d, di) => {
        const dayStats = data[k].days[d];
        if (f.dailyReports.inTime) {
          tmp.push(dayStats.inTime);
          if (i === 0) topRow.push("In Time");
        }
        if (f.dailyReports.outTime) {
          tmp.push(dayStats.outTime);
          if (i === 0) topRow.push("Out Time");
        }
        if (f.dailyReports.firstInToLastOut) {
          let v = !suffix
            ? dayStats.firstInToLastOut
            : suffix && suffix.firstInToLastOutSuffix
            ? dayStats.firstInToLastOut
            : dayStats.firstInToLastOut.slice(0, 5);
          tmp.push(v);
          if (i === 0) topRow.push("first log in time to last log out time");
        }
        if (f.dailyReports.totalInTime) {
          let v =
            suffix && suffix.totalInTimeSuffix ? dayStats.totalInTime : dayStats.totalInTime.slice(0, 5);
          tmp.push(v);
          if (i === 0) topRow.push("Total In Time");
        }
        if (f.dailyReports.overtime) {
          let v = suffix && suffix.overtimeSuffix ? dayStats.overtime : dayStats.overtime.slice(0, 5);
          tmp.push(v);
          if (i === 0) topRow.push("OverTime");
        }
        if (f.dailyReports.lateBy) {
          let v = suffix && suffix.lateBySuffix ? dayStats.lateBy : dayStats.lateBy.slice(0, 5);
          tmp.push(v);
          if (i === 0) topRow.push("Late By");
        }
        if (f.dailyReports.earlyBy) {
          let v = suffix && suffix.earlyBySuffix ? dayStats.earlyBy : dayStats.earlyBy.slice(0, 5);
          tmp.push(v);
          if (i === 0) topRow.push("Early By");
        }
        if (f.dailyReports.punchRecords) {
          tmp.push(dayStats.punchRecords);
          if (i === 0) topRow.push("Punch Records");
        }
        if (f.dailyReports.daySummary) {
          tmp.push(dayStats.daySummary);
          if (i === 0) topRow.push("Day Summary");
        }
      });
      if (f.calculations.totalInTimeSum) {
        tmp.push(data[k].totalInTimeSum);
        if (i === 0) topRow.push("Total In Time Sum");
      }
      if (f.calculations.totalBreakTimeSum) {
        tmp.push(data[k].totalBreakTimeSum);
        if (i === 0) topRow.push("Total Break Time Sum");
      }
      if (f.calculations.totalPresent) {
        tmp.push(data[k].totalPresent);
        if (i === 0) topRow.push("Total Present");
      }
      if (f.calculations.totalPrms) {
        tmp.push(data[k].totalPrms);
        if (i === 0) topRow.push("Total PRMs");
      }
      if (f.calculations.totalWorkOff) {
        tmp.push(data[k].totalWorkOff);
        if (i === 0) topRow.push("Total work off and Holidays");
      }
      if (f.calculations.totalAbsent) {
        tmp.push(data[k].totalAbsent);
        if (i === 0) topRow.push("Total Absent");
      }
      if (f.calculations.totalPresentPlusWorkoff) {
        tmp.push(data[k].totalPresentPlusWorkoff);
        if (i === 0) topRow.push("Total Present plus WO");
      }
      if (f.calculations.totalShortFall) {
        tmp.push(data[k].totalShortFall);
        if (i === 0) topRow.push("Total Short fall");
      }
      if (f.calculations.totalhalfDays) {
        tmp.push(data[k].totalhalfDays);
        if (i === 0) topRow.push("Total Half Days");
      }
      if (f.calculations.totalDays) {
        tmp.push(data[k].totalDays);
        if (i === 0) topRow.push("Total Days");
      }
      if (f.calculations.totalWorkingDays) {
        tmp.push(data[k].totalWorkingDays);
        if (i === 0) topRow.push("Total Working Days");
      }
      if (i === 0) {
        sheet.getRow(10).values = topRow;
        for (let rn = 7; rn < 11; rn++) {
          sheet.getRow(rn).eachCell({ includeEmpty: true }, function (cell, colNumber) {
            cell.fill = {
              type: "pattern",
              pattern: "solid",
              fgColor: { argb: colors.blue },
            };
            cell.font = { color: { argb: "ffffffff" }, bold: true };
          });
        }
      }
      row.values = tmp;
      row.eachCell({ includeEmpty: true }, function (cell, colNumber) {
        if (colNumber > piCols) {
          cell.alignment = { vertical: "center", horizontal: "center" };
        }
        if (colNumber <= piCols) {
          cell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: colors.mud },
          };
          cell.font = { color: { argb: "ffffffff" }, bold: true };
        }
        if (cell.value === "P ") {
          cell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: colors.fullDay },
          };
        }
        if (cell.value === "PRM ") {
          cell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: colors.prm },
          };
        }
        if (cell.value === "HD ") {
          cell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: colors.halfDay },
          };
        }
        if (cell.value === "SF ") {
          cell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: colors.shortFall },
          };
        }
        if (cell.value === "A ") {
          cell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: colors.absent },
          };
        }
        if (cell.value === "WO " || cell.value === "Holiday ") {
          cell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: colors.mud },
          };
        }
      });
    });
    sheet.columns.forEach(function (column, i) {
      let maxLength = 0;
      column["eachCell"]({ includeEmpty: true }, function (cell) {
        let columnLength = cell.value ? cell.value.toString().length : 10;
        if (columnLength > maxLength) {
          maxLength = columnLength;
        }
      });
      column.width = maxLength < 10 ? 10 : maxLength > 60 ? 10 : maxLength;
    });
  }

  const buffer = await workbook.xlsx.writeBuffer();
  const d = new Blob([buffer], { type: fileType });
  FileSaver.saveAs(d, fileName + fileExtension);
};

const applyfeaturesToAttendanceFormat = (f, attendanceConfig) => {
  let tempF = { ...f };

  Object.keys(tempF.personalInfo).forEach((k) =>
    !attendanceConfig[k]
      ? (tempF.personalInfo[k] = false)
      : (tempF.personalInfo[k] = tempF.personalInfo[k])
  );
  Object.keys(tempF.dailyReports).forEach((k) =>
    !attendanceConfig[k]
      ? (tempF.dailyReports[k] = false)
      : (tempF.dailyReports[k] = tempF.dailyReports[k])
  );
  Object.keys(tempF.calculations).forEach((k) =>
    !attendanceConfig[k]
      ? (tempF.calculations[k] = false)
      : (tempF.calculations[k] = tempF.calculations[k])
  );

  return tempF;
};
