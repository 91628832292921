import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import { apiEndPoints, formNames } from "../../../config";
import { Popup } from "reactjs-popup";
import { breakPointWidth } from "../../../config";

import {
  ToggleBox,
  ToggleTitle,
} from "../../zone/zoneCards/zoneDeviceRestrictionCard/ZoneDeviceRestrictionCard.styles";
import GreenSwitch from "../../switches/GreenSwitch";
import { FormWrapper } from "../doorAddForm/DoorAddForm";
import { useCookies } from "react-cookie";
import { toggleForm } from "../../../utils/utilFunctions";
import { Tooltip } from "react-hover-tooltip";
import { confirmAlert } from "react-confirm-alert"; // Import
import {
  RowContainer,
  FormContainer,
  RowStyle,
  Code,
  Type,
  Direction,
  DoorName,
  NodeName,
  StartTime,
  EndTime,
  Delete,
  Button,
  EditCode,
  CustomPopup,
  FieldInputMobile,
  NodeList,
  NodeSelectContainer,
} from "./OACs.styled";
import { OverFLowScrollBox } from "../../App.styled";
import {
  FieldInput,
  FieldLabel,
  InputBox,
  InputGroup,
  SelectBox,
  FormBackground,
} from "../FormBase.styled";
import { nullChecker, displayTimeMinutes, displayDate } from "../../../utils/utilFunctions";
import {
  FieldLabelMobile,
  FieldValueMobile,
  ZoneLogsUserRowMobileView,
  ZoneLogsUserRowMobileWrapper,
} from "../../zone/ZoneLogsUserRow/zoneLogsUserRow.styled";
import { RiDeleteBin6Line } from "react-icons/ri";
import { BsFillTriangleFill } from "react-icons/bs";
import { OACTypes } from "../../../config";
import { useHistory, useLocation } from "react-router-dom";
import NotificationManager from "react-notifications/lib/NotificationManager";
import { generateOACs, getOACs, deleteOAC, updateOAC } from "../../../utils/requests";
import { hourMap } from "../../../config";
import { BiEdit } from "react-icons/bi";
import { BsFillExclamationTriangleFill } from "react-icons/bs";
import { SubContainer, Arrow } from "../../selectBox/customSelect/customSelect.styled";
import { set } from "date-fns";
import ButtonState from "../../ButtonState/ButtonState";

const AddOACForm = () => {
  const closeForm = () => {
    toggleForm(formState, history, location, dispatch, formNames.addOACForm);
  };
  const [cookies, setCookie, removeCookie] = useCookies(["AT"]);
  let history = useHistory();
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const dimentions = useSelector((state) => state.dimentions);
  const selectedNode = useSelector((state) => state.selectedNode);
  const apiStatus = useSelector((state) => state.apiStatus);
  const formState = useSelector((state) => state.formState);
  const location = useLocation();
  // const hours = [...Array(24).keys()];
  const initialData = useSelector((state) => state.formState[formNames.addOACForm]);
  const [listExpand, setListExpand] = useState({ [selectedNode.nodeId]: false });
  const [formData, setFormData] = useState({
    startDate: "",
    endDate: "",
    startHours: "",
    endHours: "",
    OACType: "",
  });
  const [forFutureTime, setForFutureTime] = useState(false);
  const [customOAC, setCustomOAC] = useState(false);
  const [includeChild, setIncludeChild] = useState(false);
  const [currentNode, setCurrentNode] = useState(selectedNode);
  const [OACs, setOACs] = useState([]);
  const [showEditOAC, setShowEditOAC] = useState(false);
  const [OACDetails, setOACDetails] = useState([null, null]);
  const [customOacError, setCustomOacError] = useState(false);
  const ListNode = ({ node }) => {
    return (
      <div>
        <NodeList>
          {node.children && node.children.length ? (
            <Arrow
              expanded={listExpand[node.nodeId]}
              onClick={(e) => {
                setListExpand({
                  ...listExpand,
                  [node.nodeId]: !listExpand[node.nodeId],
                });
              }}
            >
              <BsFillTriangleFill />
            </Arrow>
          ) : null}
          <p
            className="ml-3 my-1"
            style={{ fontSize: "0.9rem", fontWeight: "bold" }}
            onClick={(e) => {
              setCurrentNode(node);
              setListExpand({
                ...listExpand,
                [node.nodeId]: false,
              });
            }}
          >
            {node.name}
          </p>
        </NodeList>
        {listExpand[node.nodeId] ? (
          <SubContainer className="ml-4 my-1" expanded={listExpand[node.nodeId]}>
            {node.children && node.children.length
              ? node.children.map((i) => <ListNode node={i} />)
              : null}
          </SubContainer>
        ) : null}
      </div>
    );
  };

  const SelectNode = ({ position }) => {
    return (
      <NodeSelectContainer
        position={position}
        expanded={listExpand[currentNode.nodeId]}
        style={dimentions.width < breakPointWidth ? {} : { width: "400px" }}
      >
        <OverFLowScrollBox>
          {currentNode ? (
            <div>
              <NodeList>
                <Arrow
                  expanded={listExpand[currentNode.nodeId]}
                  onClick={(e) => {
                    if (currentNode.nodeId === selectedNode.nodeId) {
                      setListExpand({
                        ...listExpand,
                        [currentNode.nodeId]: !listExpand[currentNode.nodeId],
                      });
                    } else {
                      if (!listExpand[currentNode.nodeId]) {
                        setListExpand({
                          ...listExpand,
                          [currentNode.nodeId]: true,
                          [selectedNode.nodeId]: true,
                        });
                        setCurrentNode(selectedNode);
                      }
                    }
                  }}
                >
                  <BsFillTriangleFill />
                </Arrow>
                <p
                  className="ml-3 my-1"
                  style={{ fontSize: "0.9rem", fontWeight: "bold" }}
                  onClick={(e) => {
                    setCurrentNode(currentNode);
                    setListExpand({
                      ...listExpand,
                      [currentNode.nodeId]: false,
                    });
                  }}
                >
                  {currentNode.name}
                </p>
              </NodeList>
              {listExpand[currentNode.nodeId] ? (
                <SubContainer className="ml-4 my-1" expanded={listExpand[currentNode.nodeId]}>
                  {currentNode.children && currentNode.children.length
                    ? currentNode.children.map((i) => <ListNode node={i} />)
                    : null}
                </SubContainer>
              ) : null}
            </div>
          ) : null}
        </OverFLowScrollBox>
      </NodeSelectContainer>
    );
  };

  const EditOAC = ({ OACId, deviceId, nodeId, width, show }) => {
    const dimentions = useSelector((state) => state.dimentions);
    const [newOAC, setNewOAC] = useState("");
    return (
      <Popup open={show} nested>
        {(close) => (
          <FormBackground
            onClick={(e) => {
              e.stopPropagation();
              setShowEditOAC(false);
            }}
            dimentions={dimentions}
          >
            <CustomPopup
              style={{ backgroundColor: "white", borderRadius: "15px", zIndex: "99", width: width }}
              onClick={(e) => e.stopPropagation()}
            >
              <h5>Edit Keycode</h5>
              <InputGroup style={{ gridTemplateColumns: "1fr 2fr" }} dimentions={dimentions}>
                <FieldLabel>New Keycode:</FieldLabel>
                <FieldInput>
                  <InputBox
                    value={newOAC}
                    onChange={(e) => {
                      setNewOAC(e.target.value);
                    }}
                  />
                </FieldInput>
                <Button
                  style={{ width: "fit-content" }}
                  onClick={(e) => {
                    e.stopPropagation();
                    updateOAC(
                      user,
                      dispatch,
                      NotificationManager,
                      removeCookie,
                      history,
                      nodeId,
                      OACId,
                      deviceId,
                      newOAC,
                      OACs,
                      setOACs
                    );
                    setShowEditOAC(false);
                  }}
                >
                  <ButtonState loading={apiStatus[apiEndPoints.updateOAC]} innerText={"Edit"} />
                </Button>
                {/* < */}
              </InputGroup>
            </CustomPopup>
          </FormBackground>
        )}
      </Popup>
    );
  };

  useEffect(() => {
    if (customOAC && typeof customOAC === "string") {
      setFormData({ ...formData, customOAC });
      if (customOAC.length < 4 || customOAC.length > 10) {
        setCustomOacError("Oac must be minimum 4 and maximum 10 digits");
      } else {
        setCustomOacError(false);
      }
    } else {
      let temp = { ...formData };
      delete temp.customOAC;
      setFormData({ ...temp });
    }
  }, [customOAC]);

  useEffect(() => {
    if (!forFutureTime) {
      setFormData({ ...formData, startDate: "", startHours: "" });
    } else {
      let today = new Date();
      today.setHours(0, 0, 0, 0);
      setFormData({
        ...formData,
        startDate: today.getTime(),
        startHours: (new Date().getHours() + 1) * 3600000,
      });
    }
  }, [forFutureTime]);

  useEffect(() => {
    if (initialData && initialData.membershipId) {
      getOACs(
        user,
        dispatch,
        NotificationManager,
        removeCookie,
        history,
        initialData.membershipId,
        selectedNode,
        setOACs
      );
    } else {
    }
  }, [initialData]);
  return dimentions.width > breakPointWidth ? (
    <FormWrapper dimentions={dimentions} closeForm={closeForm}>
      <EditOAC
        OACId={OACDetails[0]}
        deviceId={OACDetails[1]}
        nodeId={OACDetails[2]}
        width="25%"
        show={showEditOAC}
      />
      <FormContainer dimentions={dimentions}>
        <h5 className="font-weight-bold mb-4">Generate Keycodes</h5>
        <div style={{ border: "0.5px solid #e8e8e8", padding: "5px 5px 10px" }}>
          <div style={{ fontWeight: "bold", fontSize: "1rem", marginLeft: "5px" }}>Select Zones:</div>
          <div style={{ display: "flex", justifyContent: "space-between", margin: "0px 10px" }}>
            <div>
              <SelectNode position="absolute" display={{ position: "absolute" }} />
            </div>
            <ToggleBox style={{ width: "200px", margin: "0px" }}>
              <ToggleTitle style={{ fontWeight: "bold", fontSize: "0.9rem" }}>
                Include Child Zones:{" "}
              </ToggleTitle>
              <GreenSwitch
                checked={includeChild}
                onChange={(e) => {
                  // if(!includeChild){
                  //   setListExpand({
                  //     ...listExpand,
                  //     [selectedNode.nodeId]:false,
                  //   })
                  //   setCurrentNode(selectedNode);
                  // }
                  setIncludeChild(!includeChild);
                }}
              />
            </ToggleBox>
          </div>
        </div>
        <InputGroup>
          <FieldLabel style={{ display: "inline-block" }}>Code Type:&nbsp;</FieldLabel>
          <FieldInput style={{ width: "200px", display: "inline-block" }}>
            <SelectBox onChange={(e) => setFormData({ ...formData, OACType: e.target.value })}>
              {OACTypes.map((x) => (
                <option value={x[1]}>{x[0]}</option>
              ))}
            </SelectBox>
          </FieldInput>
          &emsp;&emsp;
          <FieldInput style={{ display: "inline-block" }}>
            <Button
              onClick={(e) =>
                generateOACs(
                  user,
                  dispatch,
                  NotificationManager,
                  removeCookie,
                  history,
                  initialData.membershipId,
                  currentNode,
                  formData.customOAC,
                  formData.OACType,
                  Math.floor((Number(formData.startDate) + Number(formData.startHours)) / 1000),
                  Math.floor((Number(formData.endDate) + Number(formData.endHours)) / 1000),
                  OACs,
                  setOACs,
                  includeChild,
                  closeForm
                )
              }
            >
              <ButtonState loading={apiStatus[apiEndPoints.generateOACs]} innerText={"Generate"} />
            </Button>
          </FieldInput>
        </InputGroup>
        {formData.OACType === "2" || formData.OACType === "3" ? (
          <InputGroup>
            <FieldLabel style={{ display: "inline-block" }}>Custom OAC code:&nbsp;</FieldLabel>
            <input
              type="checkbox"
              checked={typeof customOAC === "string" ? true : false}
              onChange={(e) => {
                e.stopPropagation();
                if (e.target.checked) {
                  setCustomOAC("");
                } else {
                  setCustomOAC(false);
                }
              }}
            />
          </InputGroup>
        ) : null}
        {typeof customOAC === "string" ? (
          <>
            <InputGroup dimentions={dimentions}>
              <FieldLabel dimentions={dimentions}>Custom OAC Code</FieldLabel>
              <FieldInput>
                <InputBox
                  type="number"
                  value={parseInt(customOAC)}
                  onChange={(e) => setCustomOAC(`${e.target.value}`)}
                />
                {customOacError ? <small className="text-danger">{customOacError}</small> : null}
              </FieldInput>
            </InputGroup>
          </>
        ) : null}
        <InputGroup>
          <FieldLabel style={{ display: "inline-block" }}>Generate for a future Time:&nbsp;</FieldLabel>
          <input
            type="checkbox"
            checked={forFutureTime}
            onChange={(e) => {
              e.stopPropagation();
              setForFutureTime(!forFutureTime);
            }}
          />
        </InputGroup>
        {forFutureTime || formData.OACType === 1 ? (
          <InputGroup style={{ display: "inline-block", width: "50%" }}>
            <FieldLabel style={{ display: "inline-block" }}>Start Time:&nbsp;</FieldLabel>
            <FieldInput style={{ display: "inline-block" }}>
              <DatePicker
                style={{ display: "inline" }}
                dateFormat="dd/MM/yyyy"
                className="react-datepicker-custom-css"
                selected={formData.startDate}
                placeholderText="DD/MM/YYYY"
                onChange={(date) => {
                  let currentTime = new Date().getTime();
                  if (currentTime < date.getTime() + formData.startHours) {
                    setFormData({ ...formData, startDate: date.getTime() });
                  }
                }}
              />
              &nbsp;
              <select
                style={{ display: "inline" }}
                value={formData.startHours / 3600000}
                onChange={(e) => {
                  let currentTime = new Date().getTime();
                  if (currentTime < formData.startDate + e.target.value * 3600000) {
                    setFormData({
                      ...formData,
                      startHours: e.target.value * 3600000,
                    });
                  }
                }}
              >
                <option>HH</option>
                {hourMap.map((x, index) => (
                  <option value={index}>{x}</option>
                ))}
              </select>
            </FieldInput>
          </InputGroup>
        ) : null}
        {formData.OACType === "3" ? (
          <InputGroup style={{ display: "inline-block", width: "50%" }}>
            <FieldLabel style={{ display: "inline-block" }}>End Time:&nbsp;</FieldLabel>
            <FieldInput style={{ display: "inline-block" }}>
              <DatePicker
                style={{ display: "inline" }}
                dateFormat="dd/MM/yyyy"
                className="react-datepicker-custom-css"
                selected={formData.endDate}
                placeholderText="DD/MM/YYYY"
                onChange={(date) => {
                  let today = new Date().getTime();
                  if (date.getTime() > formData.startDate && date.getTime() > today) {
                    setFormData({ ...formData, endDate: date.getTime() });
                  }
                }}
              />
              &nbsp;
              <select
                style={{ display: "inline" }}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    endHours: Number(e.target.value) * 3600000,
                  });
                }}
              >
                <option>HH</option>
                {hourMap.map((x, index) => (
                  <option value={index}>{x}</option>
                ))}
              </select>
            </FieldInput>
          </InputGroup>
        ) : null}
      </FormContainer>
      <h6 className="font-weight-bold mb-4">Keycodes :</h6>
      <RowContainer>
        <RowStyle isHead={true}>
          <Code>Code</Code>
          <EditCode>&emsp;</EditCode>
          <Type>Type</Type>
          <NodeName>Zone</NodeName>
          <DoorName>Door Name</DoorName>
          <Direction>Direction</Direction>
          <StartTime>Start Time</StartTime>
          <EndTime>End Time</EndTime>
          <Delete>&emsp;</Delete>
        </RowStyle>
        <OverFLowScrollBox>
          {OACs && OACs.length
            ? OACs.map((i) => {
                if (i.OAC && !(i.disabled && i.modified === 0))
                  return (
                    <RowStyle disabled={i.disabled}>
                      <Code>
                        <div>{i.OAC + `#`}</div>
                        {i.disabled && i.OACType !== 1 ? (
                          <Tooltip content={<div>Sync needed before this code stops working</div>}>
                            <BsFillExclamationTriangleFill color="#EED202" size="1rem" />
                          </Tooltip>
                        ) : i.newOAC && i.OACType !== 1 && !i.disabled ? (
                          <div>
                            &nbsp;({i.newOAC + `#`}&nbsp;
                            <Tooltip content={<div>Sync needed before the new code works</div>}>
                              <BsFillExclamationTriangleFill color="#EED202" size="1rem" />)
                            </Tooltip>
                          </div>
                        ) : i.modified && !i.disabled ? (
                          <Tooltip content={<div>Sync needed before this code starts working</div>}>
                            <BsFillExclamationTriangleFill color="#EED202" size="1rem" />
                          </Tooltip>
                        ) : null}
                      </Code>
                      <EditCode style={{ pointerEvents: i.disabled ? "none" : "auto" }}>
                        {i.OACType !== 1 ? (
                          <BiEdit
                            className="cursor-pointer"
                            size="1rem"
                            onClick={(e) => {
                              e.stopPropagation();
                              setOACDetails([i.OACId, i.deviceId, i.nodeId]);
                              setShowEditOAC(true);
                            }}
                          />
                        ) : null}
                      </EditCode>
                      <Type>{OACTypes[i.OACType][0]} </Type>
                      <NodeName>{i.nodeName}</NodeName>
                      <DoorName>{i.doorName}</DoorName>
                      <Direction>{i.direction}</Direction>
                      <StartTime>{displayTimeMinutes(i.startTime * 1000)}</StartTime>
                      <EndTime>
                        {i.endTime && `${i.endTime}`.length === 10
                          ? displayTimeMinutes(i.endTime * 1000)
                          : "-"}
                      </EndTime>
                      <Delete style={{ pointerEvents: i.disabled ? "none" : "auto" }}>
                        {i.OACType !== 1 ? (
                          <ButtonState
                            loading={apiStatus[apiEndPoints.deleteOAC]}
                            innerText={
                              <RiDeleteBin6Line
                                className="cursor-pointer"
                                size="1rem"
                                onClick={(e) => {
                                  deleteOAC(
                                    user,
                                    dispatch,
                                    NotificationManager,
                                    removeCookie,
                                    history,
                                    confirmAlert,
                                    i.nodeId,
                                    i.OACId,
                                    i.deviceId,
                                    OACs,
                                    setOACs
                                  );
                                }}
                              />
                            }
                          />
                        ) : null}
                      </Delete>
                    </RowStyle>
                  );
              })
            : null}
        </OverFLowScrollBox>
      </RowContainer>
      <p>
        * Please use the code within 24 hours of start time to activate it. Sync will be needed if you
        need to activate the code post that
      </p>
    </FormWrapper>
  ) : (
    <FormWrapper dimentions={dimentions} closeForm={closeForm}>
      <div className="col-sm-12 col-xs-12 p-0 col-md-12 px-md-2">
        <EditOAC
          OACId={OACDetails[0]}
          deviceId={OACDetails[1]}
          nodeId={OACDetails[2]}
          width="100%"
          show={showEditOAC}
        />
        <ZoneLogsUserRowMobileWrapper>
          <h5 className="font-weight-bold mb-3 mr-3">Generate Keycodes</h5>
          <InputGroup>
            <FieldLabel>Select Zones:</FieldLabel>
            <FieldInputMobile border={true}>
              <SelectNode />
              <ToggleBox style={{ width: "250px" }}>
                <ToggleTitle style={{ fontWeight: "bold", fontSize: "0.9rem" }}>
                  Include Child Zones:{" "}
                </ToggleTitle>
                <GreenSwitch
                  checked={includeChild}
                  onChange={(e) => {
                    setIncludeChild(!includeChild);
                  }}
                />
              </ToggleBox>
            </FieldInputMobile>
          </InputGroup>
          <InputGroup>
            <FieldLabel>Code Type:</FieldLabel>
            <FieldInputMobile style={{ width: "200px" }}>
              <SelectBox onChange={(e) => setFormData({ ...formData, OACType: e.target.value })}>
                {OACTypes.map((x) => (
                  <option value={x[1]}>{x[0]}</option>
                ))}
              </SelectBox>
            </FieldInputMobile>
          </InputGroup>
          {formData.OACType === "2" || formData.OACType === "3" ? (
            <InputGroup>
              <FieldLabel style={{ display: "inline-block" }}>Custom OAC code:&nbsp;</FieldLabel>
              <input
                type="checkbox"
                checked={typeof customOAC === "string" ? true : false}
                onChange={(e) => {
                  e.stopPropagation();
                  if (e.target.checked) {
                    setCustomOAC("");
                  } else {
                    setCustomOAC(false);
                  }
                }}
              />
            </InputGroup>
          ) : null}
          {typeof customOAC === "string" ? (
            <>
              <InputGroup dimentions={dimentions}>
                <FieldLabel dimentions={dimentions}>Custom OAC Code</FieldLabel>
                <FieldInputMobile>
                  <InputBox
                    type="number"
                    value={parseInt(customOAC)}
                    onChange={(e) => setCustomOAC(`${e.target.value}`)}
                  />
                  {customOacError ? <small className="text-danger">{customOacError}</small> : null}
                </FieldInputMobile>
              </InputGroup>
            </>
          ) : null}
          <InputGroup>
            <FieldLabel style={{ display: "inline-block" }}>
              Generate for a future Time:&nbsp;
            </FieldLabel>
            <input
              type="checkbox"
              checked={forFutureTime}
              onChange={(e) => {
                e.stopPropagation();
                setForFutureTime(!forFutureTime);
              }}
            />
          </InputGroup>
          {forFutureTime ? (
            <InputGroup>
              <FieldLabel>Start Time:</FieldLabel>
              <FieldInputMobile>
                <DatePicker
                  style={{ display: "inline-block" }}
                  dateFormat="dd/MM/yyyy"
                  className="react-datepicker-custom-css"
                  selected={formData.startDate}
                  placeholderText="DD/MM/YYYY"
                  onChange={(date) => {
                    let currentTime = new Date().getTime();
                    if (currentTime < date.getTime() + formData.startHours) {
                      setFormData({ ...formData, startDate: date.getTime() });
                    }
                  }}
                />
                <select
                  style={{ display: "inline-block" }}
                  value={formData.startHours / 3600000}
                  onChange={(e) => {
                    let currentTime = new Date().getTime();
                    if (currentTime < formData.startDate + e.target.value * 3600000) {
                      setFormData({
                        ...formData,
                        startHours: e.target.value * 3600000,
                      });
                    }
                  }}
                >
                  <option>HH</option>
                  {hourMap.map((x, index) => (
                    <option value={index}>{x}</option>
                  ))}
                </select>
              </FieldInputMobile>
            </InputGroup>
          ) : null}
          {formData.OACType === "3" ? (
            <InputGroup>
              <FieldLabel>End Time:&nbsp;</FieldLabel>
              <FieldInputMobile>
                <DatePicker
                  style={{ display: "inline" }}
                  dateFormat="dd/MM/yyyy"
                  className="react-datepicker-custom-css"
                  selected={formData.endDate}
                  placeholderText="DD/MM/YYYY"
                  onChange={(date) => {
                    let today = new Date().getTime();
                    if (date.getTime() > formData.startDate && date.getTime() > today) {
                      setFormData({ ...formData, endDate: date.getTime() });
                    }
                  }}
                />
                &nbsp;
                <select
                  style={{ display: "inline" }}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      endHours: Number(e.target.value) * 3600000,
                    });
                  }}
                >
                  <option>HH</option>
                  {hourMap.map((x, index) => (
                    <option value={index}>{x}</option>
                  ))}
                </select>
              </FieldInputMobile>
            </InputGroup>
          ) : null}
          <Button
            style={{ width: "fit-content", marginTop: "0.5rem" }}
            onClick={(e) =>
              generateOACs(
                user,
                dispatch,
                NotificationManager,
                removeCookie,
                history,
                initialData.membershipId,
                currentNode,
                formData.customOAC,
                formData.OACType,
                Math.floor((Number(formData.startDate) + Number(formData.startHours)) / 1000),
                Math.floor((Number(formData.endDate) + Number(formData.endHours)) / 1000),
                OACs,
                setOACs,
                includeChild,
                closeForm
              )
            }
          >
            <ButtonState loading={apiStatus[apiEndPoints.generateOACs]} innerText={"Generate"} />
          </Button>
        </ZoneLogsUserRowMobileWrapper>
      </div>
      {OACs && OACs.length
        ? OACs.map((i) => {
            if (i.OAC && !(i.disabled && i.modified === 0)) {
              return (
                <div className="col-sm-6 col-xs-6 p-0 col-md-6 px-md-2">
                  <ZoneLogsUserRowMobileWrapper disabled={i.disabled}>
                    <ZoneLogsUserRowMobileView>
                      <div>
                        <FieldLabelMobile>ZoneName</FieldLabelMobile>
                        <div>
                          <FieldValueMobile disabled={i.disabled}>{i.nodeName}</FieldValueMobile>
                        </div>
                      </div>
                      <div>
                        <FieldLabelMobile>Type</FieldLabelMobile>
                        <div>
                          <FieldValueMobile disabled={i.disabled}>
                            {OACTypes[i.OACType][0]}
                          </FieldValueMobile>
                        </div>
                      </div>
                      <div>
                        <FieldLabelMobile>DoorName</FieldLabelMobile>
                        <div>
                          <FieldValueMobile disabled={i.disabled}>{i.doorName}</FieldValueMobile>
                        </div>
                      </div>
                      <div>
                        <FieldLabelMobile>Direction</FieldLabelMobile>
                        <div>
                          <FieldValueMobile disabled={i.disabled}>{i.direction}</FieldValueMobile>
                        </div>
                      </div>
                      <div>
                        <FieldLabelMobile>StartTime</FieldLabelMobile>
                        <div>
                          <FieldValueMobile disabled={i.disabled}>
                            {displayTimeMinutes(i.startTime * 1000)}
                          </FieldValueMobile>
                        </div>
                      </div>
                      <div>
                        <FieldLabelMobile>EndTime</FieldLabelMobile>
                        <div>
                          <FieldValueMobile disabled={i.disabled}>
                            {i.endTime && `${i.endTime}`.length === 10
                              ? displayTimeMinutes(i.endTime * 1000)
                              : "-"}
                          </FieldValueMobile>
                        </div>
                      </div>
                    </ZoneLogsUserRowMobileView>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                        placeContent: "space-between",
                      }}
                    >
                      <div>
                        <FieldLabelMobile>Code</FieldLabelMobile>
                        <div>
                          <FieldValueMobile disabled={i.disabled}>
                            <div style={{ display: "inline-block" }}>{i.OAC + "#"}</div>
                            {i.disabled && i.OACType !== 1 ? (
                              <Tooltip
                                style={{ display: "inline-block" }}
                                content={<div>Sync needed before this code stops working</div>}
                              >
                                <BsFillExclamationTriangleFill color="#EED202" size="1rem" />
                              </Tooltip>
                            ) : i.newOAC && i.OACType !== 1 && !i.disabled ? (
                              <div style={{ display: "inline-block" }}>
                                &nbsp;({i.newOAC + `#`}&nbsp;
                                <Tooltip content={<div>Sync needed before the new code works</div>}>
                                  <BsFillExclamationTriangleFill color="#EED202" size="1rem" />)
                                </Tooltip>
                              </div>
                            ) : i.modified && !i.disabled ? (
                              <Tooltip content={<div>Sync needed before this code starts working</div>}>
                                <BsFillExclamationTriangleFill color="#EED202" size="1rem" />
                              </Tooltip>
                            ) : null}
                          </FieldValueMobile>
                        </div>
                        ,
                      </div>
                      {i.OACType !== 1 ? (
                        <div style={{ display: "flex", flexDirection: "row" }}>
                          <div style={{ display: "flex", flexDirection: "row", alignSelf: "center" }}>
                            <EditCode style={{ pointerEvents: i.disabled ? "none" : "auto" }}>
                              <BiEdit
                                className="cursor-pointer"
                                size="1.5rem"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setOACDetails([i.OACId, i.deviceId, i.nodeId]);
                                  setShowEditOAC(true);
                                }}
                              />
                            </EditCode>
                            <Delete style={{ pointerEvents: i.disabled ? "none" : "auto" }}>
                              <ButtonState
                                loading={apiStatus[apiEndPoints.deleteOAC]}
                                innerText={
                                  <RiDeleteBin6Line
                                    className="cursor-pointer"
                                    size="1rem"
                                    onClick={(e) => {
                                      deleteOAC(
                                        user,
                                        dispatch,
                                        NotificationManager,
                                        removeCookie,
                                        history,
                                        confirmAlert,
                                        i.nodeId,
                                        i.OACId,
                                        i.deviceId,
                                        OACs,
                                        setOACs
                                      );
                                    }}
                                  />
                                }
                              />
                            </Delete>
                          </div>
                          <div></div>
                        </div>
                      ) : null}
                    </div>
                  </ZoneLogsUserRowMobileWrapper>
                </div>
              );
            }
          })
        : null}
    </FormWrapper>
  );
};

export default AddOACForm;
