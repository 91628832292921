import React from "react";
import { cloudFrontOrigin } from "../../config";

const QRwelcomePage = ({ node, setShowForm }) => {
  return (
    <>
      <div className="col-12 p-0 my-3 w-100">
        <h4
          className="font-weight-bold text-center w-100"
          style={{ textDecoration: "underline" }}
          id="brandName"
        >
          Visitor Authorization <br />
          and Management System
        </h4>
      </div>
      <div className="col-12">
        <img
          id="nodeImage"
          alt="cLogo"
          style={{ width: "100px" }}
          src={
            !node || !node.nodeImage
              ? `${cloudFrontOrigin}singularityLogo_exa8op.png`
              : node.nodeImage
          }
        />
      </div>
      <div className="col-12">
        <div id="company_name" className="strongText">
          {!node || !node.name ? "Singularity Automation" : node.name}
        </div>
      </div>
      {!node ? (
        <div className="col-12">
          <p id="errorMessage" className="strongText">
            QR Code does't match
          </p>
        </div>
      ) : (
        <div className="col-12">
          <p id="errorMessage" className="strongText">
            {node.nodeAddress}
          </p>
        </div>
      )}
      <div className="col-12">
        <p id="company_address" className="infoText"></p>
      </div>
      <div className="col-12 fixed-bottom bg-white py-4">
        {node ? (
          <button
            id="submitbutton"
            className="bigButton"
            onClick={(e) => setShowForm(true)}
          >
            Continue
          </button>
        ) : null}
      </div>
    </>
  );
};

export default QRwelcomePage;
