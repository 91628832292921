import React from "react";
import { useDispatch, useSelector } from "react-redux";
import GreenSwitch from "../../../switches/GreenSwitch";
import {
  ToggleBox,
  ToggleTitle,
  ZoneNotificationCardContainer,
} from "../zoneNotificationsCard/zoneNotificationCard.styled";
import { useCookies } from "react-cookie";
import { useHistory } from "react-router-dom";
import NotificationManager from "react-notifications/lib/NotificationManager";
import { updateNodeInfoAPI } from "../../../../utils/requests";

const ZoneAttendanceCard = () => {
  const [cookies, setCookie, removeCookie] = useCookies(["AT"]);
  let history = useHistory();
  const dispatch = useDispatch();
  const enableAttendanceMonitoring = useSelector(
    (state) => state.nodeInfo.enableAttendanceMonitoring
  );
  const user = useSelector((state) => state.user);
  const selectedNode = useSelector((state) => state.selectedNode);

  const updateNodeInfo = async (reqBody) => {
    let body = { nodeId: selectedNode.nodeId, ...reqBody };
    await updateNodeInfoAPI(
      user,
      dispatch,
      NotificationManager,
      removeCookie,
      history,
      selectedNode,
      body
    );
  };

  return (
    <ZoneNotificationCardContainer>
      <ToggleBox>
        <ToggleTitle>Attendance</ToggleTitle>
        <GreenSwitch
          checked={enableAttendanceMonitoring}
          onChange={(e) =>
            updateNodeInfo({
              enableAttendanceMonitoring: !enableAttendanceMonitoring,
            })
          }
        />
      </ToggleBox>
    </ZoneNotificationCardContainer>
  );
};

export default ZoneAttendanceCard;
