import styled, { css } from "styled-components";

export const PeopleRowContainer = styled.div`
  border: 0.5px solid #e8e8e8;
  box-shadow: 0px 0.2rem 0.2rem 0.2rem rgba(0, 0, 0, 0.06);
  border-radius: 5px;
  margin-bottom: 20px;
  height: 68vh;
  position: relative;
`;

export const PeopleRowStyled = styled.div`
  display: grid;
  grid-row-gap: 0.5rem;
  grid-column-gap: 0.2rem;
  padding: 0.5rem 1rem;

  ${(props) =>
    props.enableOAC
      ? css`
          grid-template-columns: 0.5fr 1fr 0.5fr 1fr 0.5fr 2.5fr 1fr 1fr 1fr 1fr minmax(0, 1fr) 0.5fr 0.5fr;
          grid-template-areas: "id name editname phone udid email joinDate endDate OAC RFID type editAcccess delete";
        `
      : css`
          grid-template-columns: 0.5fr 1fr 0.5fr 1fr 0.5fr 3fr 1fr 1fr 1fr 1.5fr 0.5fr 0.5fr;
          grid-template-areas: "id name editname phone udid email joinDate endDate RFID type editAcccess delete";
        `}

  ${(props) =>
    props.active
      ? css`
          background-image: linear-gradient(91deg, #fe6dad -3%, #fc9f35);
        `
      : css``}
  ${(props) =>
    props.isHead
      ? css`
          font-weight: bold;
        `
      : css`
          :hover {
            color: white;
            background-image: linear-gradient(91deg, #fe6dad -3%, #fc9f35);
          }
        `}
`;
export const Id = styled.div`
  grid-area: "id";
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
`;

export const Name = styled.div`
  grid-area: "name";
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
`;
export const EditName = styled.div`
  grid-area: "editname";
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  ${(props) =>
    props.isHead
      ? css`
          color: black;
        `
      : css`
          font-size: 1.2rem;
          :hover {
            color: white;
          }
        `}
`;

export const UDID = styled.div`
  grid-area: "udid";
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
`;
export const Phone = styled.div`
  grid-area: "phone";
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
`;
export const Email = styled.div`
  grid-area: "email";
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
`;
export const JoinDate = styled.div`
  grid-area: "joinDate";
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
`;
export const EndDate = styled.div`
  grid-area: "endDate";
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
`;

export const Gates = styled.div`
  grid-area: "gates";
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  ${(props) =>
    !props.head
      ? css`
          cursor: pointer;
          color: blue;
          text-decoration: underline;
        `
      : css``}
`;

export const RFID = styled.div`
  grid-area: "RFID";
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

export const OAC = styled.div`
  grid-area: "OAC";
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

export const Type = styled.div`
  grid-area: "type";
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

export const EditAccess = styled.div`
  grid-area: "editAccess";
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  ${(props) =>
    props.isHead
      ? css`
          color: black;
        `
      : css`
          font-size: 1.2rem;
          :hover {
            color: white;
          }
        `}
`;

export const Delete = styled.div`
  grid-area: "delete";
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  ${(props) =>
    props.isHead
      ? css`
          color: black;
        `
      : css`
          font-size: 1.2rem;
          :hover {
            color: white;
          }
        `}
`;
export const Button = styled.button`
  background-color: transparent;
  border: 1px solid white;
  outline: none;
  color: white;
  justify-self: center;
  align-self: center;
  cursor: pointer;
  font-size: 0.8rem;
  padding: 0.2rem 1rem;
  border-radius: 20px;
  opacity: 0.5;
  color: black;
  border: 2px solid black;
`;
