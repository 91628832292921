import { notificationsData } from "../../config";
import { UPDATE_NOTIFICATIONS, RESET_NOTIFICATIONS, SET_DEFAULT_NOTIFICATIONS } from "../actionTypes";

const notificationsReducer = (
  notifications = {
    entries: [],
    lastPage: false,
  },
  action
) => {
  if (action.type === UPDATE_NOTIFICATIONS) {
    let temp = [...notifications.entries];
    let lastPage = false;

    if (
      (action.payload.notifications && action.payload.notifications.length === 0) ||
      (action.payload.notifications && action.payload.notifications.length + 1) % 10 !== 0
    ) {
      lastPage = true;
    }
    if (action.payload.pageNo === 1) {
      temp = [...notificationsData];
    }
    temp = [...temp, ...action.payload.notifications];
    temp = temp.sort(function (a, b) {
      return b.createdTimestamp - a.createdTimestamp;
    });
    return (notifications = { entries: [...temp], lastPage });
  }
  if (action.type === SET_DEFAULT_NOTIFICATIONS) {
    let temp = [...notificationsData];
    temp = temp.sort(function (a, b) {
      return b.createdTimestamp - a.createdTimestamp;
    });
    return (notifications = { entries: [...temp], lastPage: true });
  }
  if (action.type === RESET_NOTIFICATIONS) {
    return (notifications = { entries: [], lastPage: false });
  }

  return notifications;
};

export default notificationsReducer;
