import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  VisitorStatBarCountContainer,
  VisitorStatContainer,
  VisitorStatHeading,
  VisitorStatCount,
  VisitorStatBarContainer,
  VisitorStatBarLoggedOut,
  VisitorStatBarNotIn,
  VisitorStatBarLoggedOutCountContainer,
  VisitorStatBarLoggedOutCount,
  VisitorStatBarOnPremise,
  VisitorStatBarNotInCountContainer,
  VisitorStatBarOnPremiseCountContainer,
  VisitorStatBarOnPremiseCount,
  VisitorStatBarNotInCount,
} from "./VisitorStat.styled";

const VisitorStat = () => {
  const visitorLogs = useSelector((state) => state.visitorLogs);
  const [total, setTotal] = useState(0);
  const [loggedOut, setLoggedOut] = useState(0);
  const [onPremise, setOnPremise] = useState(0);

  const [inPremisePercentage, setInPremisePercentage] = useState(0);
  const [loggedOutPercentage, setLoggedOutPercentage] = useState(0);
  const [notInPercentage, setNotInPercentage] = useState(0);

  useEffect(() => {
    setNotInPercentage(((total - (loggedOut + onPremise)) * 100) / total);
    setLoggedOutPercentage((loggedOut * 100) / total);
    setInPremisePercentage((onPremise * 100) / total);
  }, [total, loggedOut, onPremise]);

  useEffect(() => {
    if(visitorLogs){
      setTotal(visitorLogs.totalNoOfVisitor);
      setLoggedOut(visitorLogs.visitorLoggedOut);
      setOnPremise(visitorLogs.visitorInside);
    }
  }, [visitorLogs]);

  return (
    <VisitorStatContainer>
      <VisitorStatHeading>Today's Total</VisitorStatHeading>
      <VisitorStatCount>{total}</VisitorStatCount>
      <VisitorStatBarContainer>
        <VisitorStatBarLoggedOut
          loggedOutPercentage={loggedOutPercentage}
        ></VisitorStatBarLoggedOut>
        <VisitorStatBarOnPremise
          inPremisePercentage={inPremisePercentage}
        ></VisitorStatBarOnPremise>
        <VisitorStatBarNotIn
          notInPercentage={notInPercentage}
        ></VisitorStatBarNotIn>
      </VisitorStatBarContainer>
      <VisitorStatBarCountContainer>
        <VisitorStatBarLoggedOutCountContainer
          loggedOutPercentage={loggedOutPercentage}
        >
          <VisitorStatBarLoggedOutCount>
            <p
              className="m-0 p-0 font-weight-bold"
              style={{ fontSize: "1.3rem" }}
            >
              {loggedOut}
            </p>
            <small className="text-muted m-0 p-0">Logged Out</small>
          </VisitorStatBarLoggedOutCount>
        </VisitorStatBarLoggedOutCountContainer>
        <VisitorStatBarOnPremiseCountContainer
          inPremisePercentage={inPremisePercentage}
        >
          <VisitorStatBarOnPremiseCount>
            <p
              className="m-0 p-0 font-weight-bold"
              style={{ fontSize: "1.3rem" }}
            >
              {onPremise}
            </p>
            <small className="text-muted m-0 p-0"> On Premise</small>
          </VisitorStatBarOnPremiseCount>
        </VisitorStatBarOnPremiseCountContainer>
        <VisitorStatBarNotInCountContainer notInPercentage={notInPercentage}>
          <VisitorStatBarNotInCount>
            <p
              className="m-0 p-0 font-weight-bold"
              style={{ fontSize: "1.3rem" }}
            >
              {total - (loggedOut + onPremise)}
            </p>
            <small className="text-muted m-0 p-0"> Not In</small>
          </VisitorStatBarNotInCount>
        </VisitorStatBarNotInCountContainer>
      </VisitorStatBarCountContainer>
    </VisitorStatContainer>
  );
};

export default VisitorStat;
