import React, { useEffect, useState } from "react";
import QRCode from "qrcode.react";
import NotificationManager from "react-notifications/lib/NotificationManager";
import { createNotification, notificationType } from "../../vms/Services/utils";
import { ImQrcode } from "react-icons/im";
import { useSelector } from "react-redux";

const Poster = ({ nodeInfo, mountedOnConfigure }) => {
  const posterUrl = "https://securepass-static-assets.s3.ap-south-1.amazonaws.com/qrposter.svg";
  // `${cloudFrontOrigin}50182.svg`;

  // const [qrUrl, setQrUrl] = useState("");
  const [enable, setEnable] = useState(false);

  const append = () => {
    setEnable(false);
    const qrCanvas = document.getElementById(`qr-gen${nodeInfo.nodeQRCode}`);
    const qrUrl = qrCanvas.toDataURL("image/png");
    const canvas = document.getElementById(`myCanvas${nodeInfo.nodeQRCode}`);
    const context = canvas.getContext("2d");
    const bg = new Image();
    const qr = new Image(1180, 1180);
    bg.src = posterUrl;
    bg.setAttribute("crossorigin", "anonymous");
    qr.width = 1180;
    qr.height = 1180;
    qr.src = qrUrl;
    qr.setAttribute("crossorigin", "anonymous");

    bg.onload = function () {
      // qr.onload = function () {
      context.drawImage(bg, 0, 0);
      context.drawImage(qr, 470, 2058, 1180, 1180);
      context.font = "bold 50px Roboto white";
      context.fillStyle = "#ffffff";
      context.textAlign = "center";
      context.fillText(nodeInfo.name, canvas.width / 2, canvas.height - 100);
      setEnable(true);
      // };
    };
  };

  const download = () => {
    const canvas = document.getElementById(`myCanvas${nodeInfo.nodeQRCode}`);
    const pngUrl = canvas.toDataURL("image/png").replace("image/png", "image/octet-stream");
    let downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = `Node-QR-Poster-${nodeInfo.name}.png`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
    createNotification(NotificationManager, notificationType.success, "Poster Downloaded Successfully");
  };

  useEffect(() => {
    append();
  }, [nodeInfo]);

  return (
    <>
      <QRCode
        style={{ display: "none" }}
        id={`qr-gen${nodeInfo.nodeQRCode}`}
        value={
          mountedOnConfigure
            ? `https://dashboard.thesecurepass.com/vms/qr?deviceQR=${nodeInfo.nodeQRCode}`
            : `https://vms.dthnsde8z1w71.amplifyapp.com/qr.html?qr=${nodeInfo.nodeQRCode}`
        }
        size={1180}
        level={"H"}
        includeMargin={true}
      />
      {enable ? (
        mountedOnConfigure ? (
          <button
            className="w-100 btn btn-danger btn-sm"
            style={{ outline: "0" }}
            // id="downloadButton"
            onClick={download}
          >
            Download {nodeInfo.name}
          </button>
        ) : (
          <ImQrcode
            onClick={download}
            style={{ fontSize: "1rem", fontWeight: "bold", color: "blue" }}
          ></ImQrcode>
        )
      ) : (
        <div className="spinner-border spinner-border-sm text-info" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      )}
      {/* {qrUrl.length ? (
        <img src={qrUrl} alt="qr" style={{ width: "1180px" }} />
      ) : null} */}

      <canvas
        style={{ display: "none" }}
        id={`myCanvas${nodeInfo.nodeQRCode}`}
        width="2120"
        height="3525"
      ></canvas>
    </>
  );
};

export default Poster;
