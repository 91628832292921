import React from "react";
import { cloudFrontOrigin } from "../../config";

const DeclarationForm = ({ checked, setChecked }) => {
  const handleCheck = (e, idx) => {
    let temp = checked;
    temp[idx] = e.target.checked;

    setChecked([...temp]);
  };
  return (
    <div id="Page1">
      <div id="decHead">
        <img
          alt="formpicture"
          src={`${cloudFrontOrigin}doc-tick_wq2pil.png`}
          width="47px"
          height="68px"
        />
        <div className="pageHeading">Coronavirus self declaration form</div>
      </div>
      <div id="terms" className="terms px-4">
        <p className="text-center">
          For health and safety of our community, <br />
          declaration of illness is required. Be sure to read the information
          and accept the following
        </p>
      </div>
      <div className="chkform">
        <form id="decForm" className="vms-form">
          <div className="checks">
            <label>
              I have not tested Covid-19 positive in last two months
              <input
                className="dec-input"
                type="checkbox"
                id="chk1"
                checked={checked[0]}
                onChange={(e) => handleCheck(e, 0)}
              />
              <span className="checkmark"></span>
            </label>
          </div>
          <div className="checks">
            <label>
              I am not suffering form any cough, fever or respirtory distress.
              <input
                className="dec-input"
                type="checkbox"
                id="chk2"
                checked={checked[1]}
                onChange={(e) => handleCheck(e, 1)}
              />
              <span className="checkmark"></span>
            </label>
          </div>
          <div className="checks">
            <label>
              I have not been in contact with someone who tested Covid-19
              positive.
              <input
                type="checkbox"
                className="dec-input"
                id="chk3"
                checked={checked[2]}
                onChange={(e) => handleCheck(e, 2)}
              />
              <span className="checkmark"></span>
            </label>
          </div>
          <div className="checks">
            <label>
              I understand that furnishing incorrect information would make me
              liable to penal action.
              <input
                className="dec-input"
                type="checkbox"
                id="chk4"
                checked={checked[3]}
                onChange={(e) => handleCheck(e, 3)}
              />
              <span className="checkmark"></span>
            </label>
          </div>
        </form>
        <h3 id="declarationErrorMessage">&nbsp;</h3>
      </div>
    </div>
  );
};

export default DeclarationForm;
