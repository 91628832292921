import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SolidButton } from "../../../components/buttons/solidButton.styled";
import { useHistory, useLocation } from "react-router-dom";
import { BiEdit } from "react-icons/bi";
import { useCookies } from "react-cookie";
import { IntegrationEmployeeListAPI, getPeopleAPI, getAllMemberships } from "../../../utils/requests";
import NotificationManager from "react-notifications/lib/NotificationManager";
import PlaceholderMessage from "../../PlaceholderMessage";
import { formNames } from "../../../config";
import { toggleForm } from "../../../utils/utilFunctions";
import { SyncMatchedEmoloyeesAPI } from "../../../utils/requests";
import {
  PeopleRowContainer,
  PeopleRowStyled,
  Name,
  Id,
  Phone,
  Email,
  EditAccess,
  FieldInput,
  FieldLabel,
  SelectBox,
  OverFLowScrollBox,
} from "./IntegrationEmployeeSyncView.styled";

const changeToBoolean = (value) => {
  if (value === undefined || value === null) return false;
  else return value;
};

const matchUsers = async (memberships, usersList, compareName) => {
  // console.log("initial", compareName);
  let newList = [];
  let uniqueUserList = [];
  let uniqueUserMap = {};
  let newConflictList = [];

  for (let i = 0; i < usersList.length; i++) {
    if (compareName === "mobile.number") {
      if (!usersList[i].mobileNo) continue;
      if (!uniqueUserMap[usersList[i].mobileNo]) {
        uniqueUserMap[usersList[i].mobileNo] = 1;
        uniqueUserList.push(usersList[i]);
      } else {
        uniqueUserMap[usersList[i].mobileNo] += 1;
        newConflictList.push(usersList[i]);
      }
    } else {
      if (!usersList[i].email) continue;
      if (!uniqueUserMap[usersList[i].email]) {
        uniqueUserMap[usersList[i].email] = 1;
        uniqueUserList.push(usersList[i]);
      } else {
        uniqueUserMap[usersList[i].email] += 1;
        newConflictList.push(usersList[i]);
      }
    }
  }
  //mark the non-unique identifier if its repeated
  let nonUniqueSet = new Set();
  for (let i = 0; i < uniqueUserList.length; i++) {
    uniqueUserList[i].included = true;
    if (compareName === "mobile.number") {
      if (!uniqueUserList[i].email) continue;
      if (nonUniqueSet.has(uniqueUserList[i].email)) {
        uniqueUserList[i].isNonUniqueRepeated = true;
      } else {
        nonUniqueSet.add(uniqueUserList[i].email);
        uniqueUserList[i].isNonUniqueRepeated = false;
      }
    } else {
      if (!uniqueUserList[i].mobileNo) continue;
      if (nonUniqueSet.has(uniqueUserList[i].mobileNo)) {
        uniqueUserList[i].isNonUniqueRepeated = true;
      } else {
        nonUniqueSet.add(uniqueUserList[i].mobileNo);
        uniqueUserList[i].isNonUniqueRepeated = false;
      }
    }
  }
  // console.log(uniqueUserMap, "uniqueUserMap");
  for (let i = 0; i < uniqueUserList.length; i++) {
    let count = 0;
    let updateRequired = {
      firstName: false,
      lastName: false,
      UDId: false,
      mobileNo: false,
      email: false,
    };
    let data = {};

    for (let j = 0; j < memberships.length; j++) {
      if (
        memberships[j].userInfo[compareName.split(".")[0]][compareName.split(".")[1]] ===
        uniqueUserList[i][compareName === "mobile.number" ? "mobileNo" : "email"]
      ) {
        count++;
        // console.log("comparing", memberships[j].userInfo, uniqueUserList[i], count);

        data = {
          membershipId: memberships[j].membershipId,
          role: "EMPLOYEE",
        };
        if (memberships[j].userInfo.firstName !== uniqueUserList[i].firstName)
          updateRequired.firstName = true;
        if (memberships[j].userInfo.lastName !== uniqueUserList[i].lastName)
          updateRequired.lastName = true;
        if (memberships[j].UDId !== uniqueUserList[i].UDId) updateRequired.UDId = true;
        if (
          changeToBoolean(memberships[j].userInfo.mobile.number) !==
            changeToBoolean(uniqueUserList[i].mobileNo) &&
          compareName === "email.id"
        )
          updateRequired.mobileNo = true;
        if (
          changeToBoolean(memberships[j].userInfo.email.id) !==
            changeToBoolean(uniqueUserList[i].email) &&
          compareName === "mobile.number"
        )
          updateRequired.email = true;
      }
    }
    // console.log("count result", uniqueUserList[i].mobileNo, count, updateRequired, compareName);
    if (count === 1) {
      //entry already present
      let obj = {
        ...uniqueUserList[i],
        ...data,
        entryType: "alreadyPresent",
      };
      //get what all etries are edited
      obj.updateRequired = updateRequired;
      newList.push(obj);
    } else if (count > 1) {
      //conflict entry
      //this case not going to appear in current scenario
      newConflictList.push({
        ...uniqueUserList[i],
        ...data,
        entryType: "conflicted",
      });
    } else {
      //does not match new entry
      newList.push({
        ...uniqueUserList[i],
        ...data,
        entryType: "new",
        updateRequired: {},
      });
    }
  }
  return { newList, newConflictList };
};

const IntegrationEmployeeSyncView = ({ type, memberships, setMemberships }) => {
  const [usersList, setUserList] = useState([]);
  const [stringToShow, setStringToShow] = useState("Loading......");
  const [disableUpdatButton, setDisableUpdateButton] = useState(false);
  const [showConflictList, setConflictBoolean] = useState(false);
  const selectedNode = useSelector((state) => state.selectedNode);
  const user = useSelector((state) => state.user);
  const location = useLocation();
  const formState = useSelector((state) => state.formState);
  let history = useHistory();
  const dispatch = useDispatch();
  const dimentions = useSelector((state) => state.dimentions);
  const [uniqueIdentifier, setUniqueIdentifier] = useState("Mobile Number");
  const [conflictList, setConflictList] = useState([]);
  const [allSelected, setAllSelected] = useState(true);
  const utils = useSelector((state) => state.utils);
  const integrationEditedUser = useSelector((state) => state.integrationEditedUser);
  const [cookies, setCookie, removeCookie] = useCookies(["AT"]);

  useEffect(() => {
    if (usersList.length && integrationEditedUser && integrationEditedUser.index > -1) {
      let newList = JSON.parse(JSON.stringify(usersList));
      let previousEntry = newList[integrationEditedUser.index];

      if (previousEntry.entryType === "alreadyPresent") {
        if (previousEntry.firstName !== integrationEditedUser.firstName)
          newList[integrationEditedUser.index].updateRequired.firstName = true;
        if (previousEntry.lastName !== integrationEditedUser.lastName)
          newList[integrationEditedUser.index].updateRequired.lastName = true;
        if (previousEntry.UDId !== integrationEditedUser.UDId)
          newList[integrationEditedUser.index].updateRequired.UDId = true;
        if (previousEntry.mobileNo !== integrationEditedUser.mobileNo)
          newList[integrationEditedUser.index].updateRequired.mobileNo = true;
        if (previousEntry.email !== integrationEditedUser.email)
          newList[integrationEditedUser.index].updateRequired.email = true;
      }

      newList[integrationEditedUser.index].mobileNo = integrationEditedUser.mobileNo;
      newList[integrationEditedUser.index].email = integrationEditedUser.email;
      newList[integrationEditedUser.index].firstName = integrationEditedUser.firstName;
      newList[integrationEditedUser.index].lastName = integrationEditedUser.lastName;
      newList[integrationEditedUser.index].UDId = integrationEditedUser.UDId;
      let matchingParam = uniqueIdentifier === "Mobile Number" ? "mobile.number" : "email.id";
      setUserList(newList);
      matchUsers(memberships, newList, matchingParam);
    }
  }, [integrationEditedUser]);

  const getchAndMatchUser = async (matchingParam) => {
    setStringToShow("Loading......");
    let list = await IntegrationEmployeeListAPI(
      user,
      selectedNode,
      type,
      NotificationManager,
      dispatch,
      removeCookie,
      history
    );
    // console.log("fetched list", list);
    if (list && list.length) {
      // console.log("before matching", memberships, list);
      //after getting list match the unique identifier and  add the corresponding membershipId
      //not sure whether .sort() sorts in nlogn, currently using n^2 solution for comparision
      let { newList, newConflictList } = await matchUsers(memberships, list, matchingParam);
      // console.log("after matching", newList, newConflictList, matchingParam);
      setUserList(newList);
      setConflictList(newConflictList);
    }
    setStringToShow("No User Data found from Fresh Teams");
  };

  useEffect(() => {
    if (type === "GREYTHR") {
      setUniqueIdentifier("Email Id");
      getchAndMatchUser("email.id");
    } else {
      getchAndMatchUser("mobile.number");
    }
  }, []);

  return (
    <>
      <div className="d-flex flex-column justify-content-between my-2">
        {showConflictList ? (
          <div>
            Below list show conflicted records based on unique identifier (if any), please handle them
            seperately.
          </div>
        ) : (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <FieldLabel dimentions={dimentions}>Unique Identifier</FieldLabel>
                <FieldInput>
                  <SelectBox
                    onChange={async (e) => {
                      setUniqueIdentifier(e.target.value);
                      setUserList([]);
                      setConflictList([]);
                      if (e.target.value === "Mobile Number") {
                        await getchAndMatchUser("mobile.number");
                      } else if (e.target.value === "Email Id") {
                        await getchAndMatchUser("email.id");
                      }
                    }}
                    value={uniqueIdentifier}
                  >
                    <option>Mobile Number</option>
                    <option>Email Id</option>
                  </SelectBox>
                </FieldInput>
              </div>
              {usersList && usersList.length && !showConflictList ? (
                <div className="d-flex flex-row-reverse">
                  <SolidButton
                    disabled={disableUpdatButton ? true : false}
                    onClick={async (e) => {
                      setDisableUpdateButton(true);
                      //only get employees which are included
                      let list = [];
                      for (let i = 0; i < usersList.length; i++) {
                        if (usersList[i].included) list.push(usersList[i]);
                      }

                      //this API should return conflicted List
                      let result = await SyncMatchedEmoloyeesAPI(
                        user,
                        selectedNode,
                        list,
                        NotificationManager,
                        uniqueIdentifier,
                        dispatch,
                        removeCookie,
                        history
                      );
                      setStringToShow(
                        `Employees Synced Successfully,
                    No conflicted user data found based on ${uniqueIdentifier} in SecurePass database,
                      You can check people's page for synced users`
                      );
                      if (result && result.success) {
                        setConflictBoolean(true);
                        getAllMemberships(
                          user,
                          dispatch,
                          NotificationManager,
                          removeCookie,
                          history,
                          selectedNode.nodeId,
                          setMemberships,
                          false
                        );
                        // setConflictList(result.conflictedEmployees); //here in conflict list expired membership may also come
                      } else {
                        setDisableUpdateButton(false);
                      }
                    }}
                  >
                    {disableUpdatButton ? "Updating...." : "Update"}
                  </SolidButton>
                </div>
              ) : null}
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                marginTop: "15px",
                marginBottom: "10px",
              }}
            >
              <div className="d-flex flex-row justify-content-center align-items-center">
                <div
                  style={{
                    height: "18px",
                    width: "18px",
                    backgroundColor: "#ff7e75",
                    borderRadius: "50%",
                    display: "inline-block",
                  }}
                ></div>
                <div style={{ fontSize: "0.8rem", marginLeft: "5px" }}>Data will not be updated</div>
              </div>
              <div className="d-flex flex-row justify-content-center align-items-center">
                <div
                  style={{
                    height: "18px",
                    width: "18px",
                    backgroundColor: "#ffccb3",
                    borderRadius: "50%",
                    display: "inline-block",
                  }}
                ></div>
                <div style={{ fontSize: "0.8rem", marginLeft: "5px" }}>Data will be updated</div>
              </div>
              <div className="d-flex flex-row justify-content-center align-items-center">
                <div
                  style={{
                    height: "18px",
                    width: "18px",
                    backgroundColor: "#d2f5c6",
                    borderRadius: "50%",
                    display: "inline-block",
                  }}
                ></div>
                <div style={{ fontSize: "0.8rem", marginLeft: "5px" }}>New data will be created</div>
              </div>
            </div>
          </div>
        )}
      </div>
      <PeopleRowContainer>
        <OverFLowScrollBox>
          <PeopleRowStyled isHead={true} isConflicted={showConflictList}>
            <Phone>Mobile Number</Phone>
            <Email>Email</Email>
            <Id>UDId</Id>
            <Name>Name</Name>
            {showConflictList ? null : <EditAccess isHead={true}>Edit</EditAccess>}
            {showConflictList ? null : (
              <input
                type="checkbox"
                checked={allSelected}
                onChange={(e) => {
                  //make all opposite
                  let temp = [...usersList];
                  for (let i = 0; i < temp.length; i++) {
                    temp[i].included = !allSelected;
                  }
                  setUserList(temp);
                  setAllSelected(!allSelected);
                }}
              />
            )}
          </PeopleRowStyled>

          {showConflictList ? (
            conflictList && conflictList.length ? (
              conflictList.map((i, index) => {
                return (
                  <PeopleRowStyled isConflicted={showConflictList} key={index}>
                    <Phone>{i.mobileNo}</Phone>
                    <Email>{i.email}</Email>
                    <Id>{i.UDId}</Id>
                    <Name>{i.firstName + " " + i.lastName}</Name>
                  </PeopleRowStyled>
                );
              })
            ) : (
              <PlaceholderMessage message={stringToShow} />
            )
          ) : usersList && usersList.length ? (
            usersList.map((i, index) => {
              return (
                <PeopleRowStyled key={index} entryType={i.entryType}>
                  <Phone
                    isNonUniqueRepeated={i.isNonUniqueRepeated && uniqueIdentifier === "Email Id"}
                    edited={i.updateRequired.mobileNo}
                  >
                    {i.mobileNo}
                  </Phone>
                  <Email
                    isNonUniqueRepeated={i.isNonUniqueRepeated && uniqueIdentifier === "Mobile Number"}
                    edited={i.updateRequired.email}
                  >
                    {i.email}
                  </Email>
                  <Id edited={i.updateRequired.UDId}>{i.UDId}</Id>
                  <Name edited={i.updateRequired.firstName || i.updateRequired.lastName}>
                    {i.firstName + " " + i.lastName}
                  </Name>
                  <EditAccess isHead={false}>
                    <BiEdit
                      className="cursor-pointer"
                      data-toggle="tooltip"
                      data-placement="top"
                      title="Edit Access"
                      onClick={(e) =>
                        toggleForm(
                          formState,
                          history,
                          location,
                          dispatch,
                          formNames.integrationEmployeeDetailsEditForm,
                          {
                            index: index,
                            indentifier: uniqueIdentifier,
                            membershipId: i.membershipId,
                            firstName: i.firstName,
                            lastName: i.lastName,
                            mobileNo: i.mobileNo,
                            email: i.email,
                            UDId: i.UDId,
                          }
                        )
                      }
                    />
                  </EditAccess>
                  <input
                    className="mt-1"
                    type="checkbox"
                    checked={i.included}
                    onChange={(e) => {
                      //make all opposite
                      let temp = [...usersList];
                      temp[index].included = !temp[index].included;
                      setUserList(temp);
                    }}
                  />
                </PeopleRowStyled>
              );
            })
          ) : (
            <PlaceholderMessage message={stringToShow} />
          )}
        </OverFLowScrollBox>
      </PeopleRowContainer>
    </>
  );
};

export default IntegrationEmployeeSyncView;
