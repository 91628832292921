import React, { useEffect } from "react";
import { BsFillTriangleFill } from "react-icons/bs";
import { useState } from "react";
import { useSelector } from "react-redux";
import { FloorSelectContainer, Arrow, SubContainer, MenuHead, NodeName } from "./customSelect.styled";
import { updateSelectedNode } from "../../../redux/selectedNode/selectedNodeActions";
import { updateAddRootNode } from "../../../redux/addRootNode/addRootNodeActions";
import { SolidButton } from "../../buttons/solidButton.styled";
import { OverFLowScrollBox } from "../../App.styled";
import NotificationManager from "react-notifications/lib/NotificationManager";
import { getNodeInfo, getNodes, getPeopleAPI, getSelectedNodeHierarchy } from "../../../utils/requests";
import { isAdmin, parseQueryParams, toggleForm } from "../../../utils/utilFunctions";
import { breakPointWidth, formNames, OPS_LIST } from "../../../config";
import { useLocation } from "react-router-dom";
import { updateUser } from "../../../redux/user/userActions";

const CustomSelect = ({ removeCookie, history, dispatch }) => {
  const [expanded, setExpanded] = useState({});
  const location = useLocation();
  const utils = useSelector((state) => state.utils);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchHierarchy, setSearchHierarchy] = useState({});
  const nodeInfo = useSelector((state) => state.nodeInfo);
  const dimentions = useSelector((state) => state.dimentions);
  const formState = useSelector((state) => state.formState);
  const n = useSelector((state) => state.nodes);
  const [nodes, setNodes] = useState(null);
  const selectedNode = useSelector((state) => state.selectedNode);
  const user = useSelector((state) => state.user);
  const expandTillSelected = (nodes) => {
    let temp = dummyF(nodes);
    let { nodeId } = selectedNode;
    let path = [];
    const iterator = () => {
      Object.values(temp).forEach((v, i) => {
        if (v.includes(nodeId)) {
          path.push(Object.keys(temp)[i]);
          nodeId = Object.keys(temp)[i];
          iterator();
        }
      });
    };
    iterator();
    let exp = {};
    let collapsed = {};
    path.forEach((id) => (exp[id] = true));
    Object.keys(expanded).forEach((id) =>
      path.includes(id) ? (collapsed[id] = true) : (collapsed[id] = false)
    );
    setExpanded({ ...collapsed, ...exp });
  };

  const dummyF = (nodes, acc) => {
    let temp = acc ? acc : {};
    for (let i = 0; i < nodes.length; i++) {
      let { children, nodeId, name } = nodes[i];
      if (children.length) temp[nodeId] = children.map((c) => c.nodeId).join(",");
      dummyF(children, temp);
    }
    return temp;
  };

  const initiallySetExpanded = (nodes, acc) => {
    let temp = acc ? acc : {};
    if (nodes.length) {
      nodes.forEach((obj) => {
        temp[obj.nodeId] =
          nodeInfo && nodeInfo.rootNodeId && obj.nodeId === nodeInfo.rootNodeId ? true : false;
        initiallySetExpanded(obj.children, temp);
      });
    }

    return temp;
  };
  const createSearchHierarchy = (nodes, acc) => {
    // console.log(nodes);
    let searchHierarchy = acc ? acc : {};
    if (nodes?.length !== undefined) {
      nodes.forEach((obj) => {
        searchHierarchy[obj.name] = { ...obj, rootNodeId: obj.nodeId };
        if (obj.children.length) {
          createSearchHierarchy(obj.children, searchHierarchy);
        }
      });
    }
    return searchHierarchy;
  };

  useEffect(() => {
    getNodes(user, dispatch, NotificationManager, removeCookie, history);
  }, []);

  useEffect(() => {
    if (nodes) {
      const nodesWithAdmin = [];
      const filterNodesWithAdminAccess = (n) => {
        if (
          (user?.roles && user.roles[n.nodeId] && user.roles[n.nodeId].includes("ADMIN")) ||
          (user?.roles && user.roles[n.nodeId] && user.roles[n.nodeId].includes("NODE_OWNER")) ||
          nodesWithAdmin.includes(n.parentNodeId)
        ) {
          nodesWithAdmin.push(n.nodeId);
        }
        if (n.children) {
          n.children
            .map((ns) => ({ ...ns, parentNodeId: n.nodeId }))
            .forEach((nc) => {
              filterNodesWithAdminAccess(nc);
            });
        }
      };
      nodes.forEach((node) => filterNodesWithAdminAccess(node));
      dispatch(updateUser({ ...user, nodesAsAdminAccess: nodesWithAdmin }));
    }
  }, [nodes]);

  useEffect(() => {
    if (n && n.length) {
      // setExpanded({ ...expanded, ...initiallySetExpanded(n) });
      setSearchHierarchy(createSearchHierarchy(n));
    }
  }, [n]);

  useEffect(() => {
    if (nodes && nodes.length && user.nodesAsAdminAccess && !selectedNode.nodeId) {
      if (location.search.length && parseQueryParams(location.search).nodeId) {
        const node = nodes.filter((n) => n.nodeId === parseQueryParams(location.search).nodeId);
        if (node.length) {
          dispatch(
            updateSelectedNode({
              rootNodeId: nodes[0].nodeId,
              name: node[0].name,
              nodeId: node[0].nodeId,
              children: node[0].children,
              expanded: false,
              parentId: node[0].nodeId,
            })
          );
        }
      } else {
        let node = {};
        const earliestNodeWithAccess = (n) => {
          if (user.nodesAsAdminAccess.includes(n.nodeId) || user.roles[n.nodeId]) {
            node = { ...n };
            return;
          }
          if (n.children) {
            n.children.forEach((ns) => earliestNodeWithAccess(ns));
          }
        };

        nodes.forEach((nl) => earliestNodeWithAccess(nl));

        if (node.nodeId) {
          dispatch(
            updateSelectedNode({
              rootNodeId: node.nodeId,
              name: node.name,
              nodeId: node.nodeId,
              children: node.children,
              expanded: false,
              parentId: node.nodeId,
            })
          );
        }
      }
    }
  }, [n, user]);

  useEffect(() => {
    if (n && n.length) setNodes(n);
  }, [n]);

  //bring selected node to the top of the menu
  useEffect(() => {
    if (selectedNode && selectedNode.nodeId && nodes && nodes.length) {
      let id = selectedNode.rootNodeId;
      //filter selected node
      let nn = nodes.filter((n) => n.nodeId === id);
      //filter rest of the nodes other than selected one
      let nn2 = nodes.filter((n) => n.nodeId !== id);
      // set first node as selected and then the rest of the nodes
      console.log({ nn, nn2 });
      setNodes([...nn2, ...nn]);
    }
  }, [selectedNode, n]);
  useEffect(() => {
    if (selectedNode && selectedNode.nodeId && nodes && nodes.length) {
      expandTillSelected(nodes);
    }
  }, [selectedNode]);
  const closeBox = () => {
    // setExpanded({
    //   ...expanded,
    //   [selectedNode.nodeId]: false,
    // });
    dispatch(updateSelectedNode({ ...selectedNode, expanded: false }));
    setSearchQuery("");
  };

  useEffect(() => {
    if (selectedNode.nodeId) {
      getNodeInfo(user, dispatch, NotificationManager, removeCookie, history, selectedNode);
    }
  }, [selectedNode.nodeId]);

  useEffect(() => {
    if (selectedNode.nodeId) {
      getPeopleAPI(user, dispatch, NotificationManager, removeCookie, history, selectedNode, utils);
      getSelectedNodeHierarchy(user, dispatch, NotificationManager, removeCookie, history, selectedNode);
    }
  }, [selectedNode]);

  // useEffect(() => {
  //   if (location.pathname !== "/apps") {
  //     if (nodeInfo.rootNodeId && n) {
  //       let tn = n.filter((sn) => sn.nodeId === nodeInfo.rootNodeId);
  //       setNodes(tn);
  //     } else if (n && n.length) {
  //       let tn = n.filter((sn) => sn.nodeId === nodeInfo.nodeId);
  //       setNodes(tn);
  //     }
  //   } else if (location.pathname === "/apps") {
  //     setNodes(n);
  //   }
  // }, [location, nodeInfo, n]);

  return (
    <>
      {nodes && nodes.length ? (
        <FloorSelectContainer dimentions={dimentions} expanded={selectedNode.expanded}>
          <OverFLowScrollBox
            tabIndex="0"
            onBlur={(e) => {
              if (!e.currentTarget.contains(e.relatedTarget)) {
                closeBox();
              }
            }}
          >
            {selectedNode && !selectedNode?.expanded ? (
              <MenuHead>
                <Arrow
                  expanded={selectedNode.expanded}
                  onClick={(e) => {
                    setExpanded({
                      ...expanded,
                      [selectedNode.nodeId]: !expanded[selectedNode.nodeId],
                    });
                    dispatch(
                      updateSelectedNode({
                        rootNodeId: selectedNode.nodeId,
                        ...selectedNode,
                        expanded: !selectedNode.expanded,
                      })
                    );
                  }}
                >
                  <BsFillTriangleFill />
                </Arrow>
                <p
                  className="ml-3 my-1"
                  style={
                    dimentions.width < breakPointWidth ? { fontSize: "1rem", fontWeight: "bold" } : {}
                  }
                >
                  {selectedNode.name}
                </p>
              </MenuHead>
            ) : null}
            {selectedNode.expanded ? (
              <>
                <input
                  type="text"
                  onKeyUp={(e) => {
                    if (e.key === "Escape") closeBox();
                  }}
                  className="mx-auto w-100 p-1 my-2 rounded"
                  onChange={(e) => {
                    setSearchQuery(e.target.value);
                  }}
                  style={dimentions.width < breakPointWidth ? { fontSize: "1rem" } : {}}
                />

                {searchQuery.length ? (
                  <>
                    {Object.keys(searchHierarchy)
                      .filter((v) => v.toLowerCase().includes(searchQuery.toLowerCase()))
                      .map((v) => (
                        <p
                          onClick={(e) => {
                            dispatch(
                              updateSelectedNode({
                                name: searchHierarchy[v].name,
                                nodeId: searchHierarchy[v].nodeId,
                                rootNodeId: searchHierarchy[v].rootNodeId,
                                parentId: searchHierarchy[v].parentId,
                                children: searchHierarchy[v].children,
                                expanded: false,
                              })
                            );

                            setSearchQuery("");
                          }}
                          className="mx-2"
                          style={
                            dimentions.width < breakPointWidth
                              ? {
                                  cursor: "pointer",
                                  fontWeight: "bold",
                                  fontSize: "1rem",
                                }
                              : {
                                  cursor: "pointer",
                                  fontWeight: "bold",
                                }
                          }
                        >
                          {v}
                        </p>
                      ))
                      .reverse()}
                  </>
                ) : (
                  <>
                    {nodes
                      ? nodes
                          .map((node) => (
                            <>
                              <SubMenu
                                rootNodeId={node.nodeId}
                                user={user}
                                key={node.nodeId}
                                name={node.name}
                                dimentions={dimentions}
                                parentId={node.nodeId}
                                location={location}
                                children={node.children}
                                nodeId={node.nodeId}
                                hierarchy={node.children}
                                expanded={expanded}
                                setExpanded={setExpanded}
                                dispatch={dispatch}
                                selectedNode={selectedNode}
                              />
                            </>
                          ))
                          .reverse()
                      : null}
                    {isAdmin(user, nodeInfo) &&
                    OPS_LIST.includes(user.mobileNo) &&
                    location.pathname.startsWith("/apps") ? (
                      <p
                        className="ml-3 my-3 text-center font-weight-bold cursor-pointer"
                        onClick={(e) =>
                          toggleForm(formState, history, location, dispatch, formNames.addRootNodeForm)
                        }
                        style={dimentions.width < breakPointWidth ? { fontSize: "1rem" } : {}}
                      >
                        + Add new Node
                      </p>
                    ) : null}
                  </>
                )}
              </>
            ) : null}
          </OverFLowScrollBox>
        </FloorSelectContainer>
      ) : (
        <>
          {isAdmin(user, nodeInfo) &&
          OPS_LIST.includes(user.mobileNo) &&
          location.pathname.startsWith("/apps") ? (
            <SolidButton
              // className="ml-3 my-3 text-center font-weight-bold cursor-pointer"
              onClick={(e) => dispatch(updateAddRootNode(true))}
            >
              + Add new Node
            </SolidButton>
          ) : null}
        </>
      )}
    </>
  );
};

export default CustomSelect;

const SubMenu = ({
  user,
  rootNodeId,
  hierarchy,
  name,
  parentId,
  location,
  children,
  setRootNodeRefForInnerMenu,
  dimentions,
  nodeId,
  expanded,
  setExpanded,
  dispatch,
  selectedNode,
}) => {
  return (
    <div style={{ width: "100%" }} key={nodeId}>
      <MenuHead selectedNode={selectedNode.nodeId === nodeId}>
        {/* {hierarchy && hierarchy.length && location.pathname !== "/apps" ? ( */}
        <Arrow
          expanded={nodeId === "" ? true : expanded[nodeId]}
          onClick={(e) => {
            setExpanded({ ...expanded, [nodeId]: !expanded[nodeId] });
          }}
        >
          <BsFillTriangleFill />
        </Arrow>
        <NodeName
          className="ml-3 my-1 w-100"
          onClick={(e) => {
            if (Object.keys(user.roles).includes(nodeId) || isAdmin(user, { nodeId })) {
              dispatch(
                updateSelectedNode({
                  name,
                  nodeId,
                  children,
                  rootNodeId: rootNodeId,
                  parentId: parentId,
                  expanded: false,
                })
              );
            }
          }}
          dimentions={dimentions}
          breakPointWidth={breakPointWidth}
          disabled={!Object.keys(user.roles).includes(nodeId) && !isAdmin(user, { nodeId })}
        >
          {name}
        </NodeName>
      </MenuHead>
      {hierarchy &&
        hierarchy.map((v) => {
          return (
            <SubContainer
              className="ml-5 w-100"
              key={v.nodeId}
              expanded={nodeId === "" ? true : expanded[nodeId]}
            >
              {/* {v.children && location.pathname !== "/apps" ? ( */}
              <SubMenu
                user={user}
                rootNodeId={rootNodeId}
                name={v.name}
                hierarchy={v.children}
                children={v.children}
                parentId={v.nodeId}
                location={location}
                dimentions={dimentions}
                nodeId={v.nodeId}
                expanded={expanded}
                setExpanded={setExpanded}
                dispatch={dispatch}
                selectedNode={selectedNode}
              />
              {/* ) : null} */}
            </SubContainer>
          );
        })}
    </div>
  );
};
