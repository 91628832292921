import styled, { css } from "styled-components";
import { breakPointWidth } from "./config";
export const MainDiv = styled.div`
  grid-area: main;
  max-height: 100vh;

  ${(props) =>
    props.dimentions.width > breakPointWidth
      ? "padding: 2rem 2rem;"
      : "padding: 0px 2rem;"}
`;

//   ${(props) =>
//     props.submitButton
//       ? css`
//           display: flex;
//           flex-direction: row;
//           justify-content: flex-end;
//         `
//       : css``}
//   ${(props) =>
//     props.border
//       ? css`
//           padding: 10px 10px;
//           border: 0.5px solid rgba(137, 137, 137, 0.4);
//           box-sizing: border-box;
//           border-radius: 6px;
//         `
//       : css``}
