import React from "react";
import "./greenSwitch.css";
const GreenSwitch = ({ checked, onChange }) => {
  return (
    <div>
      <label className="switch">
        <input type="checkbox" checked={checked} onChange={onChange} />
        <span className="slider round"></span>
      </label>
    </div>
  );
};

export default GreenSwitch;
