import styled from "styled-components";

export const VisitorStatContainer = styled.div`
  margin-bottom: 6rem;
`;
export const VisitorStatHeading = styled.p`
  font-weight: bold;
  margin-bottom: 5px;
  margin-top: 1.5rem;
`;

export const VisitorStatCount = styled.p`
  font-weight: bold;
  font-size: 2rem;
  margin-bottom: 5px;
  color: #5c659d;
`;

export const VisitorStatBarContainer = styled.div`
  display: flex;
  width: 100%;
  height: 1.5rem;
  border: 0.5px solid #e8e8e8;
  border-radius: 5px;
  overflow: hidden;
`;

export const VisitorStatBarLoggedOut = styled.div`
  width: ${(props) => props.loggedOutPercentage}%;
  background-color: #00ce6b;
`;

export const VisitorStatBarOnPremise = styled.div`
  width: ${(props) => props.onPremisePercentage}%;
  background-color: #fe6dad;
`;

export const VisitorStatBarNotIn = styled.div`
  width: ${(props) => props.notInPercentage}%;
  background-color: #e7e7e7;
`;

export const VisitorStatBarCountContainer = styled.div`
  display: flex;
`;

export const VisitorStatBarLoggedOutCountContainer = styled.div`
  width: ${(props) => props.loggedOutPercentage}%;
    min-width : 100px;
  color: #00ce6b;
`;

export const VisitorStatBarOnPremiseCountContainer = styled.div`
  width: ${(props) => props.onPremisePercentage}%;
  min-width: 100px;
  color: #fe6dad;
`;

export const VisitorStatBarNotInCountContainer = styled.div`
  width: ${(props) => props.notInPercentage}%;
  min-width: 100px;
  color: #a6a9b7;
`;

export const VisitorStatBarLoggedOutCount = styled.div``;

export const VisitorStatBarOnPremiseCount = styled.div``;

export const VisitorStatBarNotInCount = styled.div``;
