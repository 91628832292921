import React from "react";

const StringSpreader = ({ string, length }) => {
  return length && string && string.length > length ? (
    <span
      onClick={() => navigator.clipboard.writeText(string)}
      style={{ cursor: "pointer" }}
      data-toggle="tooltip"
      data-placement="top"
      title={string}
    >
      {string.substr(0, length) + "..."}
    </span>
  ) : (
    string
  );
};

export default StringSpreader;
