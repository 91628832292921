import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import NotificationManager from "react-notifications/lib/NotificationManager";
import { CSVDownloader, readString } from "react-papaparse";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { apiEndPoints } from "../../../config";
import { bulkUpdateAccessAPI } from "../../../utils/requests";
import { encodeTime } from "../../../utils/utilFunctions";
import { SolidButton } from "../../buttons/solidButton.styled";
import ButtonState from "../../ButtonState/ButtonState";
import SubMenu from "../../SubMenu/SubMenu";
import GreenSwitch from "../../switches/GreenSwitch";
import { FieldLabelMobile } from "../../zone/ZoneLogsUserRow/zoneLogsUserRow.styled";
import { FieldInput, FieldLabel, InputGroup } from "../FormBase.styled";
import BulkOperationTable from "./BulkOperationTable";

const BulkUpdateAccessForm = ({ allMemberships }) => {
  const [cookies, setCookie, removeCookie] = useCookies(["AT"]);
  const [data, setData] = useState(null);
  const dispatch = useDispatch();
  const history = useHistory();
  const [formData, setFormData] = useState([]);
  const [error, setError] = useState(false);
  const [expanded, setExpanded] = useState({});
  const [checkedNodes, setCheckedNodes] = useState({});
  const [accessPermission, setAccessPermission] = useState({});
  const [overwrite, setOverwrite] = useState(false);
  const [nodesAccessibleFromGroups, setNodesaccessibleFromGroups] = useState(null);
  const nodes = useSelector((state) => state.selectedNodeHierarchy);
  const user = useSelector((state) => state.user);
  const selectedNode = useSelector((state) => state.selectedNode);
  const dimentions = useSelector((state) => state.dimentions);
  const apiStatus = useSelector((state) => state.apiStatus);

  const initiallySetExpanded = (nodes, acc) => {
    let temp = acc ? acc : {};
    if (nodes.length !== undefined) {
      nodes.forEach((obj) => (temp[obj.nodeId] = false));
    }
    if (nodes.length !== undefined) {
      nodes.forEach((obj) => obj.children.forEach((nObj) => initiallySetExpanded(nObj, temp)));
    }
    return temp;
  };

  const populateData = (data) => {
    setError(false);
    let formData = [];
    if (data && data.length > 1) {
      for (let i = 1; i < data.length; i++) {
        const [name, emailOrMobile] = data[i];
        if (emailOrMobile && emailOrMobile.length) {
          if (emailOrMobile.includes("@")) {
            formData.push({ emailId: emailOrMobile });
          } else formData.push({ mobileNo: emailOrMobile });
        }
        if (i === data.length - 1) {
          setFormData([...formData]);
        }
      }
    } else setError("uploaded File is empty");
  };

  const handleSubmit = () => {
    let body = {};
    body.nodeId = selectedNode.nodeId;
    body.accessInfo = JSON.stringify([
      ...Object.values(checkedNodes)
        .map((obj) => {
          if (obj.startTimeInDay && obj.startTimeInDay) {
            return {
              ...obj,
              startTimeInDay: encodeTime(obj.startTimeInDay),
              endTimeInDay: encodeTime(obj.endTimeInDay),
            };
          } else if (obj.endTimeInDay) {
            return {
              ...obj,
              endTimeInDay: encodeTime(obj.endTimeInDay),
            };
          } else if (obj.startTimeInDay) {
            return {
              ...obj,
              startTimeInDay: encodeTime(obj.startTimeInDay),
            };
          }
        })
        .filter((v) => v),
    ]);
    body.bulkData = JSON.stringify(formData);
    body.overwrite = overwrite;
    bulkUpdateAccessAPI(
      user,
      dispatch,
      NotificationManager,
      removeCookie,
      history,
      body,
      () => null,
      setError
    );
  };

  useEffect(() => {
    if (nodes) {
      setExpanded(initiallySetExpanded(nodes));
    }
  }, [nodes]);

  useEffect(() => {
    if (allMemberships && allMemberships.length) {
      let d = allMemberships.map((m) => ({
        Name: m.userInfo.firstName + " " + m.userInfo.lastName,
        "Email or Mobile No (only one type)": m.userInfo.mobile.number || m.userInfo.email.id,
      }));
      setData([...d]);
    } else if (allMemberships && allMemberships.length === 0) {
      setData([
        {
          "Email or Mobile No (only one type)": "9090909090",
        },
        {
          "Email or Mobile No (only one type)": "9900990099",
        },
      ]);
    }
  }, [allMemberships]);

  return (
    <>
      <InputGroup dimentions={dimentions}>
        <FieldLabel dimentions={dimentions}>Access Permissions</FieldLabel>
        <FieldInput border={true}>
          {nodes
            ? nodes.map((node) => (
                <SubMenu
                  nodesAccessibleFromGroups={nodesAccessibleFromGroups}
                  checkedNodes={checkedNodes}
                  setCheckedNodes={setCheckedNodes}
                  name={node.name}
                  parentId={node.nodeId}
                  nodeId={node.nodeId}
                  hierarchy={node.children}
                  expanded={expanded}
                  setExpanded={setExpanded}
                  accessPermission={accessPermission}
                  setAccessPermission={setAccessPermission}
                />
              ))
            : null}
        </FieldInput>
      </InputGroup>

      <div className="mt-5">
        <h6 className="font-weight-bold mb-4">Bulk Update Access</h6>
        <div style={{ display: "flex", justifyContent: "flex-end" }} className="my-3">
          <button
            className="solid-button"
            style={{ display: "inline-block", margin: "0rem 0.5rem" }}
            onClick={(e) => document.getElementById("BulkUpdateAccess").click()}
          >
            Bulk Import CSV
          </button>
          <input
            type="file"
            id="BulkUpdateAccess"
            name="BulkUpdateAccess"
            onChange={(e) => {
              let reader = new FileReader();
              if (e.target.files[0]) {
                reader.readAsText(e.target.files[0]);
                reader.onload = (ex) => {
                  var data = readString(ex.target.result, {
                    header: false,
                  }).data;
                  populateData(data);
                };
              }
            }}
            accept=".csv"
            style={{ display: "none" }}
          />
          <CSVDownloader
            className="solid-button"
            style={{ display: "inline-block", margin: "0rem 0.5rem" }}
            data={data}
            type="button"
            filename="Bulk Access Update Sample"
            bom={true}
            disabled={!data}
          >
            Download Sample CSV
          </CSVDownloader>
        </div>
        <div
          style={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}
          className="my-3"
        >
          <FieldLabelMobile className="mx-2">Overwrite</FieldLabelMobile>
          <GreenSwitch checked={overwrite} onChange={(e) => setOverwrite(!overwrite)} />
        </div>
        {formData && formData.length ? (
          <>
            <BulkOperationTable data={formData} />
          </>
        ) : null}
        {error ? <p className="text-danger text-center">{error.message}</p> : null}
        {error && error.users && error.users.length ? <BulkOperationTable data={error.users} /> : null}
        <div className="text-right py-4">
          <SolidButton onClick={handleSubmit}>
            <ButtonState
              loading={apiStatus[apiEndPoints.bulkUpdateAccessAPI]}
              innerText={"Save Format"}
            />
          </SolidButton>
        </div>
      </div>
    </>
  );
};

export default BulkUpdateAccessForm;
