import styled from "styled-components";

export const PeopleViewContainer = styled.div`
  border: 1px solid #e8e8e8;
  width: 70vw;
  background-color: white;
  padding-bottom: 1.5rem;
  box-shadow: 0px 0.2rem 0.2rem 0.2rem rgba(0, 0, 0, 0.06);
  border-radius: 5px;
`;


export const CloseButtonContainer = styled.div`
  display: flex;
  padding: 0 10px;
  padding-top: 5px;
  justify-content: flex-end;
  align-items: center;
`;

export const PeopleViewRowStyled = styled.div`
  display: grid;
  grid-row-gap: 0.5rem;
  grid-column-gap: 0.2rem;
  padding: 0.5rem 1rem;
  height: 5vh;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-areas: "gate accessDays accessTime visitorPermission Attendance crb button";

  font-weight : ${props => props.isHead ? "bold" : "normal" }
`;
export const Gate = styled.div`
  grid-area: "gate";
  display: flex;
  justify-content: center;
  align-items: center;
  text-align : center;
`;

export const Checkbox = styled.input`
background-color : transparent;

`

export const AccessDays = styled.div`
  grid-area: "accessDays";
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const AccessTime = styled.div`
  grid-area: "accessTime";
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const VisitorPermission = styled.div`
  grid-area: "visitorPermission";
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const Attendance = styled.div`
  grid-area: "attendance";
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const CRB = styled.div`
  grid-area: "crb";
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Button = styled.button`
  grid-area: "button";
  padding: 0.2rem 1rem;
  background-color: transparent;
  outline: none;
  font-size: 0.8rem;
  border-radius: 20px;
  justify-self: center;
  align-self: center;
  cursor: pointer;
  opacity: 0.5;
  color: black;
  border: 1px solid black;
  :hover {
  }
`;
