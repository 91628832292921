import React from "react";
import { Link, Redirect, Route, useLocation } from "react-router-dom";
import { cloudFrontOrigin } from "./config";
import Login from "./pages/Login";
import Register from "./pages/Register";

const Auth = ({ params, setParams, cameTo }) => {
  const location = useLocation();

  const checkAuth = () => {
    if (!location.pathname.startsWith("/vms")) {
      return <Redirect to={`/${window.location.search}`} />;
    }
  };

  return (
    <div className="container-fluid">
      {checkAuth()}
      <Route exact path="/">
        <Login params={params} setParams={setParams} cameTo={cameTo} />
      </Route>
      <Route exact path="/register">
        <Redirect to="/" />
      </Route>
      <Route path="/register/:medium">
        <Register />
      </Route>
    </div>
  );
};

export default Auth;

export const Head = () => {
  return (
    <div className="container-fluid d-flex justify-content-center align-items-center my-5">
      <Link to="/">
        <img
          alt="Securepass"
          id="brandLogo"
          className="p-0 m-0"
          style={{ width: "200px" }}
          src={`${cloudFrontOrigin}SecurePass_b3mm6p_rnqlfl.jpg`}
        />
      </Link>
    </div>
  );
};
