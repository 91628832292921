import React from 'react'

const LoadingSpinner = () => {
  return (
    <div className="container">
      <div className="row">
        <div className="col-12 text-center mt-5">
          <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
      </div>{" "}
    </div>
  );
}

export default LoadingSpinner