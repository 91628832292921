import { combineReducers } from "redux";
import addRootNodeReducer from "../addRootNode/addRootNodeReducer";
import dimentionsReducer from "../dimentions/dimentionsReducer";
import formStateReducer from "../formStatus/FormStatusReducer";
import groupsReducer from "../groups/groupsReducer";
import membershipsReducer from "../memberships/membershipsReducers";
import nodeInfoReducer from "../nodeInfo/nodeInfoReducers";
import nodesReducer from "../nodes/nodesReducer";
import selectedNodeReducer from "../selectedNode/selectedNodeReducers";
import selectedNodeHierarchyReducer from "../selectedNodeHierarchy/selectedNodeHierarchyReducer";
import sidebarStatusReducer from "../sidebarStatus/sidebarStatusReducers";
import userReducer from "../user/userReducer";
import utilsReducer from "../utils/utilsReducer";
import visitorLogsReducer from "../visitorLogs/visitorLogsReducer";
import integrationUserEditReducer from "../integrationUserEdit/integrationUserEditReducer";
import notificationsReducer from "../notifications/notificationsReducer";
import deviceFirmwaresReducer from "../deviceFirmware/DeviceFirmwareReducers";
import apiStatusReducer from "../apiStatus/apiStatusReducer";
const combinedReducers = combineReducers({
  nodes: nodesReducer,
  memberships: membershipsReducer,
  nodeInfo: nodeInfoReducer,
  selectedNode: selectedNodeReducer,
  visitorLogs: visitorLogsReducer,
  user: userReducer,
  addRootNode: addRootNodeReducer,
  selectedNodeHierarchy: selectedNodeHierarchyReducer,
  dimentions: dimentionsReducer,
  sidebarStatus: sidebarStatusReducer,
  formState: formStateReducer,
  groups: groupsReducer,
  utils: utilsReducer,
  integrationEditedUser: integrationUserEditReducer,
  notifications: notificationsReducer,
  deviceFirmwares: deviceFirmwaresReducer,
  apiStatus : apiStatusReducer,
});

export default combinedReducers;
