import styled, {css} from "styled-components";

export const MembershipViewContainer = styled.div`
  border: 1px solid #e8e8e8;
  width: 100%;
  background-color: white;
  padding-bottom: 1.5rem;
  border-radius: 5px;
`;


export const CloseButtonContainer = styled.div`
  display: flex;
  padding: 0 10px;
  padding-top: 5px;
  justify-content: flex-end;
  align-items: center;
`;

export const MembershipViewRowStyled = styled.div`
  display: grid;
  grid-row-gap: 0.5rem;
  grid-column-gap: 0.2rem;
  padding: 0.5rem 1rem;
  height: 5vh;
  grid-template-columns: 1fr 2fr 1fr 1fr 1fr;
  grid-template-areas: "name phone email id  button";
  ${(props) =>
    props.isHead
      ? css`
          font-weight: bold;
        `
      : css`
          :hover {
            color: white;
            background-image: linear-gradient(91deg, #fe6dad -3%, #fc9f35);
          }
        `}
`;
export const Name = styled.div`
  grid-area: "name";
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

export const Phone = styled.div`
  grid-area: "phone";
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Email = styled.div`
  grid-area: "email";
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const ID = styled.div`
  grid-area: "id";
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const AccessDate = styled.div`
  grid-area: "accessDate";
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Button = styled.button`
  grid-area: "button";
  padding: 0.2rem 1rem;
  background-color: transparent;
  outline: none;
  font-size: 0.8rem;
  border-radius: 20px;
  justify-self: center;
  align-self: center;
  cursor: pointer;
  opacity: 0.5;
  color: black;
  border: 1px solid black;
 
`;
