import { UPDATE_DEVICE_FIRMWARE } from "../actionTypes";

const deviceFirmwaresReducer = (deviceFirmwares = [], action) => {
  if (action.type === UPDATE_DEVICE_FIRMWARE) {
    return (deviceFirmwares = action.payload);
  }

  return deviceFirmwares;
};

export default deviceFirmwaresReducer;
