import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { apiEndPoints, formNames } from "../../../config";
import GreenSwitch from "../../switches/GreenSwitch";
import { SolidButton } from "../../buttons/solidButton.styled";
import { FormWrapper } from "../doorAddForm/DoorAddForm";
import { useCookies } from "react-cookie";
import { toggleForm } from "../../../utils/utilFunctions";
import { FieldInput, FieldLabel, InputBox } from "../FormBase.styled";
import { InputGroup } from "../freshTeamsIntegrationForm/freshTeamsIntegrationForm.styled";
import { useHistory, useLocation } from "react-router-dom";
import NotificationManager from "react-notifications/lib/NotificationManager";
import { updateIntegrationCredentialsAPI } from "../../../utils/requests";
import ButtonState from "../../ButtonState/ButtonState";

const AddGreytHrApiCredentials = () => {
  const closeForm = () => {
    toggleForm(formState, history, location, dispatch, formNames.greytHrIntegrationForm);
  };
  const [cookies, setCookie, removeCookie] = useCookies(["AT"]);
  let history = useHistory();
  const dispatch = useDispatch();
  const dimentions = useSelector((state) => state.dimentions);
  const selectedNode = useSelector((state) => state.selectedNode);
  const formState = useSelector((state) => state.formState);
  const apiStatus = useSelector((state) => state.apiStatus);
  const location = useLocation();
  const initialData = useSelector((state) => state.formState[formNames.greytHrIntegrationForm]);
  const user = useSelector((state) => state.user);
  const [formData, setFormData] = useState({
    host: "",
    apiId: "",
    privateKey: "",
    syncLogsToGreytHr: false,
  });

  useEffect(() => {
    if (initialData) {
      setFormData({ ...initialData });
    }
  }, [initialData]);

  return (
    <FormWrapper dimentions={dimentions} closeForm={closeForm}>
      <h6 className="font-weight-bold mb-4">Insert GreytHR Attendance Credentials</h6>
      <InputGroup dimentions={dimentions}>
        <FieldLabel dimentions={dimentions}>Allow Sync</FieldLabel>
        <FieldInput>
          <GreenSwitch
            checked={formData.syncLogsToGreytHr}
            onChange={(e) => setFormData({ ...formData, syncLogsToGreytHr: e.target.checked })}
          />
        </FieldInput>
      </InputGroup>
      <InputGroup dimentions={dimentions}>
        <FieldLabel dimentions={dimentions}>Domain Name</FieldLabel>
        <FieldInput>
          <InputBox
            type="text"
            disabled={!formData.syncLogsToGreytHr}
            placeholder="https://<subdomain>.greythr.com"
            value={formData.host}
            onChange={(e) => setFormData({ ...formData, host: e.target.value })}
          />
        </FieldInput>
      </InputGroup>
      <InputGroup dimentions={dimentions}>
        <FieldLabel dimentions={dimentions}>App Id</FieldLabel>
        <FieldInput>
          <InputBox
            type="text"
            disabled={!formData.syncLogsToGreytHr}
            value={formData.apiId}
            onChange={(e) => setFormData({ ...formData, apiId: e.target.value })}
          />
        </FieldInput>
      </InputGroup>
      <InputGroup dimentions={dimentions}>
        <FieldLabel dimentions={dimentions}>Private Key</FieldLabel>
        <FieldInput>
          <InputBox
            type="text"
            disabled={!formData.syncLogsToGreytHr}
            value={formData.privateKey}
            onChange={(e) => setFormData({ ...formData, privateKey: e.target.value })}
          />
        </FieldInput>
      </InputGroup>
      <InputGroup dimentions={dimentions}>
        <FieldLabel dimentions={dimentions}></FieldLabel>
        <FieldInput submitButton={true}>
          <SolidButton
            submit={true}
            onClick={(e) =>
              updateIntegrationCredentialsAPI(
                "GREYTHR",
                user,
                dispatch,
                NotificationManager,
                removeCookie,
                history,
                selectedNode,
                formData,
                closeForm
              )
            }
          >
            <ButtonState loading={apiStatus[apiEndPoints.updateIntegrationCredentialsAPI]} innerText={"Submit"} />
          </SolidButton>
        </FieldInput>
      </InputGroup>
    </FormWrapper>
  );
};

export default AddGreytHrApiCredentials;
