import styled, { css } from "styled-components";
import { breakPointWidth } from "../../../config";

export const FloorSelectContainer = styled.div`
  width: ${(props) =>
    props.dimentions.width > breakPointWidth
      ? "300"
      : props.dimentions.width - 60}px;
  position: absolute;
  z-index: 45;
  height: ${(props) => (props.expanded ? "40vh" : "")};
  overflow-y: scroll;
  background-color: #ffffff;
  border: 0.5px solid #e8e8e8;
  border-radius: 10px;
  outline: none;
  overflow: hidden;
  padding: ${(props) => (props.expanded ? "0px 5px" : "0px 0px")};
`;

export const SubContainer = styled.div`
  display: ${(props) => (props.expanded ? "xyx" : "none")};
  transition: height 0.2s;
`;

export const Arrow = styled.div`
  font-size: 0.7rem;
  transform: ${(props) =>
    props.expanded ? "rotate(180deg)" : "rotate(90deg)"};
`;

export const NodeName = styled.div`
  font-size: ${(props) => (props.dimentions.width < props.breakPointWidth ? "1rem" : "")};
  color: ${(props) => (props.disabled ? "grey" : "black")};
`;

export const MenuHead = styled.div`
  padding: 2px 10px;
  cursor: pointer;
  display: flex;
  border-radius: 10px;
  align-items: center;
  transition: all 0.2s ease;
  :hover {
    color: white;
    background-image: linear-gradient(91deg, #fe6dad -3%, #fc9f35);
  }
  ${(props) =>
    props.selectedNode
      ? css`
          color: white;
          background-image: linear-gradient(91deg, #fe6dad -3%, #fc9f35);
        `
      : css``};
`;
