import React, { useState, useEffect } from "react";

const BulkOperationTable = ({ data }) => {
  const [users, setUsers] = useState(null);

  useEffect(() => {
    let temp = data.map((u) => {
      // need to find a way to display accessInfo and roles in error table
      // for now deleting it to omit error on membership add form due to invalid react childs
      delete u.roles;
      delete u.accessInfo;
      return u;
    });
    setUsers([...temp]);
  }, [data]);

  return users && users.length ? (
    <>
      <table className="table table-striped">
        <thead>
          <tr>
            {Object.keys(users[0]).map((key) => (
              <td scope="row">{key}</td>
            ))}
          </tr>
        </thead>
      </table>
      <div style={{ maxHeight: "20vh", overflowY: "scroll" }}>
        <table className="table table-striped">
          <tbody>
            {users.map((d) => (
              <tr>
                {Object.keys(d).map((key) => (
                  <td scope="row">{d[key]}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  ) : null;
};

export default BulkOperationTable;
