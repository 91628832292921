import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import NotificationManager from "react-notifications/lib/NotificationManager";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { advancedFeatures, basicFeatures, basicFeatures2 } from "../../config";
import { updateNodeInfoAPI } from "../../utils/requests";
import { sortPlanObject } from "../../utils/utilFunctions";
import { SolidButton } from "../buttons/solidButton.styled";
import GreenSwitch from "../switches/GreenSwitch";
import {
  ZoneAccessCardContainer,
  ZoneAccessCardTitle,
} from "../zone/zoneCards/zoneAccessCard/zoneAccessCard.styled";
import {
  ToggleBox,
  ToggleTitle,
} from "../zone/zoneCards/zoneNotificationsCard/zoneNotificationCard.styled";

const FeatureForm = () => {
  const [cookies, setCookie, removeCookie] = useCookies(["AT"]);
  const [features, setFeatures] = useState({ ...basicFeatures });
  const user = useSelector((state) => state.user);
  const nodeInfo = useSelector((state) => state.nodeInfo);
  const selectedNode = useSelector((state) => state.selectedNode);
  const dispatch = useDispatch();
  const history = useHistory();

  const handleChange = (v, key, type) => {
    if (!type) {
      setFeatures({ ...features, [key]: v });
    } else {
      setFeatures({ ...features, [type]: { ...features[type], [key]: v } });
    }
  };

  const update = () => {
    const body = {
      nodeId: selectedNode.nodeId,
      features: JSON.stringify(features),
    };
    updateNodeInfoAPI(user, dispatch, NotificationManager, removeCookie, history, selectedNode, body);
  };

  useEffect(() => {
    if (nodeInfo && nodeInfo.features) {
      setFeatures({ ...nodeInfo.features });
    }
  }, [nodeInfo]);

  return (
    <div className="row py-5 px-2">
      <div className="col-md-3 col-lg-3 col-sm-12 col-xs-12">
        <ZoneAccessCardContainer>
          <ZoneAccessCardTitle>Features</ZoneAccessCardTitle>
          <ToggleBox>
            <ToggleTitle>Basic</ToggleTitle>
            <GreenSwitch
              checked={
                JSON.stringify(sortPlanObject(features)) ==
                  JSON.stringify(sortPlanObject(basicFeatures)) ||
                JSON.stringify(sortPlanObject(features)) ==
                  JSON.stringify(sortPlanObject(basicFeatures2))
              }
              onChange={(e) => {
                if (e.target.checked) {
                  setFeatures({ ...basicFeatures });
                }
              }}
            />
          </ToggleBox>
          <ToggleBox>
            <ToggleTitle>Advanced</ToggleTitle>
            <GreenSwitch
              checked={
                JSON.stringify(sortPlanObject(features)) ==
                JSON.stringify(sortPlanObject(advancedFeatures))
              }
              onChange={(e) => {
                if (e.target.checked) {
                  setFeatures({ ...advancedFeatures });
                }
              }}
            />
          </ToggleBox>
          <ToggleBox>
            <ToggleTitle>Custom</ToggleTitle>
            <GreenSwitch
              disabled={true}
              checked={
                JSON.stringify(sortPlanObject(features)) !==
                  JSON.stringify(sortPlanObject(basicFeatures)) &&
                JSON.stringify(sortPlanObject(features)) !==
                  JSON.stringify(sortPlanObject(basicFeatures2)) &&
                JSON.stringify(sortPlanObject(features)) !==
                  JSON.stringify(sortPlanObject(advancedFeatures))
              }
            />
          </ToggleBox>
        </ZoneAccessCardContainer>
        <ZoneAccessCardContainer>
          <ZoneAccessCardTitle>Cloud Integration</ZoneAccessCardTitle>
          <ToggleBox>
            <ToggleTitle>Desktop View</ToggleTitle>
            <GreenSwitch
              checked={features?.allowDesktopView}
              onChange={(e) => handleChange(e.target.checked, "allowDesktopView")}
            />
          </ToggleBox>
          <ToggleBox>
            <ToggleTitle>Mobile View</ToggleTitle>
            <GreenSwitch
              checked={features?.allowMobileView}
              onChange={(e) => handleChange(e.target.checked, "allowMobileView")}
            />
          </ToggleBox>
          <ToggleBox>
            <ToggleTitle>Integrations</ToggleTitle>
            <GreenSwitch
              checked={features?.allowIntegrations}
              onChange={(e) => handleChange(e.target.checked, "allowIntegrations")}
            />
          </ToggleBox>
        </ZoneAccessCardContainer>
        <ZoneAccessCardContainer>
          <ZoneAccessCardTitle>Notifications</ZoneAccessCardTitle>
          <ToggleBox>
            <ToggleTitle>Membership Expiry</ToggleTitle>
            <GreenSwitch
              checked={features?.allowNotifications.membershipExpiry}
              onChange={(e) => handleChange(e.target.checked, "membershipExpiry", "allowNotifications")}
            />
          </ToggleBox>
        </ZoneAccessCardContainer>
        <ZoneAccessCardContainer>
          <ZoneAccessCardTitle>Support</ZoneAccessCardTitle>
          <ToggleBox>
            <ToggleTitle>Basic Support</ToggleTitle>
            <GreenSwitch
              checked={features?.basicSupport}
              onChange={(e) => handleChange(e.target.checked, "basicSupport")}
            />
          </ToggleBox>
          <ToggleBox>
            <ToggleTitle>Quick Support</ToggleTitle>
            <GreenSwitch
              checked={features?.quickSupport}
              onChange={(e) => handleChange(e.target.checked, "quickSupport")}
            />
          </ToggleBox>
          <ToggleBox>
            <ToggleTitle>Instant Support</ToggleTitle>
            <GreenSwitch
              checked={features?.instantSupport}
              onChange={(e) => handleChange(e.target.checked, "instantSupport")}
            />
          </ToggleBox>
        </ZoneAccessCardContainer>
      </div>
      <div className="col-md-3 col-lg-3 col-sm-12 col-xs-12">
        <ZoneAccessCardContainer>
          <ZoneAccessCardTitle>Attendance Personal Info</ZoneAccessCardTitle>
          <ToggleBox>
            <ToggleTitle>First Name</ToggleTitle>
            <GreenSwitch
              checked={features?.attendanceConfig?.firstName}
              onChange={(e) => handleChange(e.target.checked, "firstName", "attendanceConfig")}
            />
          </ToggleBox>
          <ToggleBox>
            <ToggleTitle>Last Name</ToggleTitle>
            <GreenSwitch
              checked={features?.attendanceConfig?.lastName}
              onChange={(e) => handleChange(e.target.checked, "lastName", "attendanceConfig")}
            />
          </ToggleBox>
          <ToggleBox>
            <ToggleTitle>Email Id</ToggleTitle>
            <GreenSwitch
              checked={features?.attendanceConfig?.emailId}
              onChange={(e) => handleChange(e.target.checked, "emailId", "attendanceConfig")}
            />
          </ToggleBox>
          <ToggleBox>
            <ToggleTitle>Mobile No</ToggleTitle>
            <GreenSwitch
              checked={features?.attendanceConfig?.mobileNo}
              onChange={(e) => handleChange(e.target.checked, "mobileNo", "attendanceConfig")}
            />
          </ToggleBox>
          <ToggleBox>
            <ToggleTitle>UDID</ToggleTitle>
            <GreenSwitch
              checked={features?.attendanceConfig?.UDID}
              onChange={(e) => handleChange(e.target.checked, "UDID", "attendanceConfig")}
            />
          </ToggleBox>
          <ToggleBox>
            <ToggleTitle>Designation</ToggleTitle>
            <GreenSwitch
              checked={features?.attendanceConfig?.designation}
              onChange={(e) => handleChange(e.target.checked, "designation", "attendanceConfig")}
            />
          </ToggleBox>{" "}
          <ToggleBox>
            <ToggleTitle>Department</ToggleTitle>
            <GreenSwitch
              checked={features?.attendanceConfig?.department}
              onChange={(e) => handleChange(e.target.checked, "department", "attendanceConfig")}
            />
          </ToggleBox>{" "}
          <ToggleBox>
            <ToggleTitle>Sub Department</ToggleTitle>
            <GreenSwitch
              checked={features?.attendanceConfig?.subDepartment}
              onChange={(e) => handleChange(e.target.checked, "subDepartment", "attendanceConfig")}
            />
          </ToggleBox>{" "}
          <ToggleBox>
            <ToggleTitle>User Type</ToggleTitle>
            <GreenSwitch
              checked={features?.attendanceConfig?.userType}
              onChange={(e) => handleChange(e.target.checked, "userType", "attendanceConfig")}
            />
          </ToggleBox>
        </ZoneAccessCardContainer>
        <ZoneAccessCardContainer>
          <ZoneAccessCardTitle>User Access</ZoneAccessCardTitle>
          <ToggleBox>
            <ToggleTitle>Device Restriction</ToggleTitle>
            <GreenSwitch
              checked={features?.allowDeviceRestriction}
              onChange={(e) => handleChange(e.target.checked, "allowDeviceRestriction")}
            />
          </ToggleBox>
          <ToggleBox>
            <ToggleTitle>Edit Access Days</ToggleTitle>
            <GreenSwitch
              checked={features?.editAccessDays}
              onChange={(e) => handleChange(e.target.checked, "editAccessDays")}
            />
          </ToggleBox>
          <ToggleBox>
            <ToggleTitle>Edit Access Hours</ToggleTitle>
            <GreenSwitch
              checked={features?.editAccessHours}
              onChange={(e) => handleChange(e.target.checked, "editAccessHours")}
            />
          </ToggleBox>
        </ZoneAccessCardContainer>
      </div>
      <div className="col-md-3 col-lg-3 col-sm-12 col-xs-12">
        <ZoneAccessCardContainer>
          <ZoneAccessCardTitle>Attendance Daily Reports</ZoneAccessCardTitle>
          <ToggleBox>
            <ToggleTitle>In Time</ToggleTitle>
            <GreenSwitch
              checked={features?.attendanceConfig?.inTime}
              onChange={(e) => handleChange(e.target.checked, "inTime", "attendanceConfig")}
            />
          </ToggleBox>
          <ToggleBox>
            <ToggleTitle>Out Time</ToggleTitle>
            <GreenSwitch
              checked={features?.attendanceConfig?.outTime}
              onChange={(e) => handleChange(e.target.checked, "outTime", "attendanceConfig")}
            />
          </ToggleBox>
          <ToggleBox>
            <ToggleTitle>First In To Last Out</ToggleTitle>
            <GreenSwitch
              checked={features?.attendanceConfig?.firstInToLastOut}
              onChange={(e) => handleChange(e.target.checked, "firstInToLastOut", "attendanceConfig")}
            />
          </ToggleBox>
          <ToggleBox>
            <ToggleTitle>Total In Time</ToggleTitle>
            <GreenSwitch
              checked={features?.attendanceConfig?.totalInTime}
              onChange={(e) => handleChange(e.target.checked, "totalInTime", "attendanceConfig")}
            />
          </ToggleBox>
          <ToggleBox>
            <ToggleTitle>Over Time</ToggleTitle>
            <GreenSwitch
              checked={features?.attendanceConfig?.overtime}
              onChange={(e) => handleChange(e.target.checked, "overtime", "attendanceConfig")}
            />
          </ToggleBox>
          <ToggleBox>
            <ToggleTitle>Late By</ToggleTitle>
            <GreenSwitch
              checked={features?.attendanceConfig?.lateBy}
              onChange={(e) => handleChange(e.target.checked, "lateBy", "attendanceConfig")}
            />
          </ToggleBox>{" "}
          <ToggleBox>
            <ToggleTitle>Early By</ToggleTitle>
            <GreenSwitch
              checked={features?.attendanceConfig?.earlyBy}
              onChange={(e) => handleChange(e.target.checked, "earlyBy", "attendanceConfig")}
            />
          </ToggleBox>{" "}
          <ToggleBox>
            <ToggleTitle>Punch Records</ToggleTitle>
            <GreenSwitch
              checked={features?.attendanceConfig?.punchRecords}
              onChange={(e) => handleChange(e.target.checked, "punchRecords", "attendanceConfig")}
            />
          </ToggleBox>{" "}
          <ToggleBox>
            <ToggleTitle>Day Summary</ToggleTitle>
            <GreenSwitch
              checked={features?.attendanceConfig?.daySummary}
              onChange={(e) => handleChange(e.target.checked, "daySummary", "attendanceConfig")}
            />
          </ToggleBox>
        </ZoneAccessCardContainer>
        <ZoneAccessCardContainer>
          <ZoneAccessCardTitle>Attendance Filters</ZoneAccessCardTitle>
          <ToggleBox>
            <ToggleTitle>Department</ToggleTitle>
            <GreenSwitch
              checked={features?.attendanceFilters?.department}
              onChange={(e) => handleChange(e.target.checked, "department", "attendanceFilters")}
            />
          </ToggleBox>
          <ToggleBox>
            <ToggleTitle>Sub Department</ToggleTitle>
            <GreenSwitch
              checked={features?.attendanceFilters?.subDepartment}
              onChange={(e) => handleChange(e.target.checked, "subDepartment", "attendanceFilters")}
            />
          </ToggleBox>
          <ToggleBox>
            <ToggleTitle>Roles</ToggleTitle>
            <GreenSwitch
              checked={features?.attendanceFilters?.role}
              onChange={(e) => handleChange(e.target.checked, "role", "attendanceFilters")}
            />
          </ToggleBox>
        </ZoneAccessCardContainer>
      </div>
      {/*          */}
      <div className="col-md-3 col-lg-3 col-sm-12 col-xs-12">
        <ZoneAccessCardContainer>
          <ZoneAccessCardTitle>Attendance Calculations</ZoneAccessCardTitle>
          <ToggleBox>
            <ToggleTitle>Total Present</ToggleTitle>
            <GreenSwitch
              checked={features?.attendanceConfig?.totalPresent}
              onChange={(e) => handleChange(e.target.checked, "totalPresent", "attendanceConfig")}
            />
          </ToggleBox>
          <ToggleBox>
            <ToggleTitle>Total Work Off</ToggleTitle>
            <GreenSwitch
              checked={features?.attendanceConfig?.totalWorkOff}
              onChange={(e) => handleChange(e.target.checked, "totalWorkOff", "attendanceConfig")}
            />
          </ToggleBox>
          <ToggleBox>
            <ToggleTitle>Total Absent</ToggleTitle>
            <GreenSwitch
              checked={features?.attendanceConfig?.totalAbsent}
              onChange={(e) => handleChange(e.target.checked, "totalAbsent", "attendanceConfig")}
            />
          </ToggleBox>
          <ToggleBox>
            <ToggleTitle>Total Present Plus Work Off</ToggleTitle>
            <GreenSwitch
              checked={features?.attendanceConfig?.totalPresentPlusWorkoff}
              onChange={(e) =>
                handleChange(e.target.checked, "totalPresentPlusWorkoff", "attendanceConfig")
              }
            />
          </ToggleBox>
          <ToggleBox>
            <ToggleTitle>Total Short Fall</ToggleTitle>
            <GreenSwitch
              checked={features?.attendanceConfig?.totalShortFall}
              onChange={(e) => handleChange(e.target.checked, "totalShortFall", "attendanceConfig")}
            />
          </ToggleBox>
          <ToggleBox>
            <ToggleTitle>Total Half Days</ToggleTitle>
            <GreenSwitch
              checked={features?.attendanceConfig?.totalhalfDays}
              onChange={(e) => handleChange(e.target.checked, "totalhalfDays", "attendanceConfig")}
            />
          </ToggleBox>{" "}
          <ToggleBox>
            <ToggleTitle>Total Days</ToggleTitle>
            <GreenSwitch
              checked={features?.attendanceConfig?.totalDays}
              onChange={(e) => handleChange(e.target.checked, "totalDays", "attendanceConfig")}
            />
          </ToggleBox>{" "}
          <ToggleBox>
            <ToggleTitle>Total Working Days</ToggleTitle>
            <GreenSwitch
              checked={features?.attendanceConfig?.totalWorkingDays}
              onChange={(e) => handleChange(e.target.checked, "totalWorkingDays", "attendanceConfig")}
            />
          </ToggleBox>{" "}
          <ToggleBox>
            <ToggleTitle>Total PRMs</ToggleTitle>
            <GreenSwitch
              checked={features?.attendanceConfig?.totalPrms}
              onChange={(e) => handleChange(e.target.checked, "totalPrms", "attendanceConfig")}
            />
          </ToggleBox>
        </ZoneAccessCardContainer>
        <div className="d-flex align-items-center justify-content-end mt-4">
          <SolidButton submit={true} onClick={(e) => update()}>
            Save
          </SolidButton>
        </div>
      </div>
    </div>
  );
};

export default FeatureForm;
