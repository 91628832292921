import { UPDATE_NODES } from "../actionTypes";

const nodesReducer = (nodes = {}, action) => {
  if (action.type === UPDATE_NODES) {
    return (nodes = action.payload);
  }

  return nodes;
};

export default nodesReducer;
