import React from "react";
import {
  ZoneAccessCardContainer,
  ZoneAccessCardTitle,
  ZoneAccessCardCount,
  ZoneAccessCardSubtitle,
  ZoneAccessCardEditIconContainer,
} from "./zoneAccessCard.styled";
import { IconContainer } from "../../../App.styled";
import { BiEdit } from "react-icons/bi";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
const ZoneAccessCard = ({ count }) => {
  const peopleWithAccess = useSelector(
    (state) => state.nodeInfo.peopleWithAccess
  );
  const nodeCategory = useSelector((state)=>state.nodeInfo.nodeCategory);
  return (
    <ZoneAccessCardContainer>
      <ZoneAccessCardTitle>Access</ZoneAccessCardTitle>
      <ZoneAccessCardCount>{peopleWithAccess}</ZoneAccessCardCount>
      <ZoneAccessCardSubtitle>
        people have access to this zone
      </ZoneAccessCardSubtitle>
      <ZoneAccessCardEditIconContainer>
        <IconContainer>
          <Link
            to="/people"
            className="m-0 p-0"
            style={{ color: "#49eecc", textDecoration: "none" }}
          >
            <BiEdit className="m-0 p-0" />
          </Link>
        </IconContainer>
      </ZoneAccessCardEditIconContainer>
      <ZoneAccessCardSubtitle>
        Node Category: {nodeCategory}
      </ZoneAccessCardSubtitle>
    </ZoneAccessCardContainer>
  );
};

export default ZoneAccessCard;
