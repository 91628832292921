import styled, { css } from "styled-components";

export const AccessInfoViewWrapper = styled.div`
  height: 90vh;
  width: 90vw;
  z-index: 19999;
  background-color: white;
  border-radius: 16px;
  padding: 2rem 1rem;
  overflow-y: scroll;
`;

export const AccessInfoViewBackground = styled.div`
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
`;
