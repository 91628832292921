import React, { useEffect, useState } from "react";
import PeoplesMenu from "../components/people/PeoplesMenu";
import PeopleRow from "../components/people/peopleRow/PeopleRow";
import GroupRow from "../components/people/groupRow/GroupRow";
import { useSelector } from "react-redux";
import PlaceholderMessage from "../components/PlaceholderMessage";
import NotificationManager from "react-notifications/lib/NotificationManager";
import { getUserRole } from "../utils/utilFunctions";
import { sendCustomPageView } from "../utils/tagManagerEvents";
import DisabledOverlay from "../components/DisabledOverlay";
const People = ({ removeCookie, history, dispatch }) => {
  const [tabStatus, setTabStatus] = useState("people");
  const selectedNode = useSelector((state) => state.selectedNode);
  const user = useSelector((state) => state.user);
  const nodeInfo = useSelector((state) => state.nodeInfo);
  useEffect(() => {
    sendCustomPageView(
      user.userId,
      nodeInfo.name,
      nodeInfo.nodeCategory,
      getUserRole(user.roles, nodeInfo)
    );
  }, [nodeInfo, user]);
  // useEffect(() => {
  //   getAccessGroupsAPI(user, dispatch, NotificationManager, removeCookie, history, selectedNode.nodeId);
  // }, []);
  return (
    <div>
      <PeoplesMenu tabStatus={tabStatus} setTabStatus={setTabStatus} />
      {tabStatus === "people" ? (
        selectedNode.nodeId ? (
          <div className="">
            <PeopleRow removeCookie={removeCookie} history={history} dispatch={dispatch} />
          </div>
        ) : (
          <PlaceholderMessage />
        )
      ) : selectedNode.nodeId ? (
        <div className="">
          <GroupRow
            // getAccessGroupsAPI={getAccessGroupsAPI}
            removeCookie={removeCookie}
            history={history}
            dispatch={dispatch}
          >
            <DisabledOverlay />
          </GroupRow>
        </div>
      ) : (
        <PlaceholderMessage />
      )}
    </div>
  );
};

export default People;
