import React from "react";
import styled from "styled-components";
import { Link, Redirect, useHistory, useParams } from "react-router-dom";
import { InputBox } from "../components/forms/FormBase.styled";
import { useState } from "react";
import { SolidButton } from "../components/buttons/solidButton.styled";
import { rootApi } from "../config";
import { post } from "../utils/apiService";
import { createNotification, notificationType } from "../vms/Services/utils";
import NotificationManager from "react-notifications/lib/NotificationManager";
import { ATexpired } from "../utils/logout";
import { useCookies } from "react-cookie";
import { useDispatch } from "react-redux";
import { Head } from "../Auth";
import PrivacyAndTerms from "../components/PrivacyAndTerms";

const Register = () => {
  const [cookies, setCookie, removeCookie] = useCookies(["AT"]);
  const params = useParams();
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    password: "",
    OTP: "",
  });
  const dispatch = useDispatch();
  const history = useHistory();

  const [registerSuccess, setRegisterSuccess] = useState(false);

  const [emailId, setEmailId] = useState("");
  const [mobileNo, setMobileNo] = useState("");
  const [showOTPForm, setShowOTPForm] = useState(false);
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const requestOTP = async () => {
    const url =
      params.medium === "email"
        ? `${rootApi}user/sendRegistrationOTP/email`
        : `${rootApi}user/sendRegistrationOTP/mobile`;
    const body =
      params.medium === "email"
        ? {
            emailId,
          }
        : {
            mobileNo,
          };

    try {
      const response = await post(dispatch, url, body);
      if (!response.data.success) {
        createNotification(NotificationManager, notificationType.error, response.data.error);
      } else {
        setShowOTPForm(true);
      }
      ATexpired(response, dispatch, removeCookie, history);
    } catch (err) {
      throw err;
    }
  };

  const handleSubmit = async () => {
    const url =
      params.medium === "email" ? `${rootApi}user/register/email` : `${rootApi}user/register/mobile`;
    const body =
      params.medium === "email"
        ? {
            firstName: formData.firstName,
            lastName: formData.lastName,
            password: formData.password,
            OTP: formData.OTP,
            emailId,
          }
        : {
            firstName: formData.firstName,
            lastName: formData.lastName,
            password: formData.password,
            OTP: formData.OTP,
            mobileNo,
          };
    try {
      const response = await post(dispatch, url, body);
      if (!response.data.success) {
        createNotification(NotificationManager, notificationType.error, response.data.error);
      } else {
        setRegisterSuccess(true);
      }
      ATexpired(response, dispatch, removeCookie, history);
    } catch (err) {
      throw err;
    }
  };

  return (
    <FormContainer>
      <Head />
      {registerSuccess ? <Redirect to="/" /> : null}
      <Form>
        <h5 className="text-center">Register</h5>
        {showOTPForm ? (
          <div className="row">
            <div className="col-sm-12 col-xs-12 col-md-8 col-lg-6 text-center mt-4">
              <p>Please enter OTP recieved in your {" " + params.medium}</p>
            </div>
            <div className="col-12">
              <InputBox
                className="text-center"
                placeholder="Enter OTP"
                name="OTP"
                onChange={handleChange}
                value={formData.OTP}
              />
            </div>

            <div className="col-12 px-1 mt-3 text-center">
              <SolidButton onClick={handleSubmit}>Submit</SolidButton>
            </div>
            <div className="col-12 text-center">
              <p className="text-primary mt-2" style={{ cursor: "pointer" }} onClick={requestOTP}>
                Didn't recieved OTP ? Resend.
              </p>
            </div>
          </div>
        ) : (
          <div className="row">
            <div className="col-12 px-1">
              <InputBox
                placeholder="First Name"
                name="firstName"
                type="text"
                onChange={handleChange}
                value={formData.firstName}
              />
            </div>
            {/* <div className="col-md-6 col-sm-12 col-xs-12 col-lg-6 px-1"> */}
            <div className="col-12 px-1">
              <InputBox
                name="lastName"
                type="text"
                onChange={handleChange}
                placeholder="Last Name"
                value={formData.lastName}
              />
            </div>
            <div className="col-12 px-1">
              {params.medium === "mobile" ? (
                <InputBox
                  placeholder="10 digit Mobile No"
                  value={mobileNo}
                  type="number"
                  onChange={(e) => setMobileNo(e.target.value)}
                />
              ) : (
                <InputBox
                  placeholder="Email"
                  type="email"
                  value={emailId}
                  onChange={(e) => setEmailId(e.target.value)}
                />
              )}
            </div>
            <div className="col-12 px-1">
              <InputBox
                placeholder="Password"
                value={formData.password}
                name="password"
                type="password"
                onChange={handleChange}
              />
            </div>
            <div className="col-12 px-1 mt-3 text-center">
              <SolidButton onClick={requestOTP}>Register</SolidButton>
            </div>
            <div className="col-12 d-flex align-items-center justify-content-center mt-3">
              <Link to="/">Already registered go to Login</Link>
            </div>
            <PrivacyAndTerms />
          </div>
        )}
      </Form>
    </FormContainer>
  );
};

export default Register;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
`;

export const Form = styled.div`
  background: #ffffff;
  border: 0.5px solid #e5e5e5;
  box-sizing: border-box;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.06);
  border-radius: 10px;
  padding: 1.5rem 3rem;
  margin: 1.5rem;
`;
