import React, { useEffect, useState } from "react";
import IntegrationsView from "../components/integrations/integrationsView/integrationsView";
import IntegrationEmployeeSyncView from "../components/integrations/IntegrationEmployeeSyncView/IntegrationEmployeeSyncView";
import { useSelector, useDispatch } from "react-redux";
import { useHistory} from "react-router-dom";
import { getAllMemberships } from "../utils/requests";
import { sendCustomPageView } from "../utils/tagManagerEvents";
import { getUserRole } from "../utils/utilFunctions";
import { useCookies } from "react-cookie";
import NotificationManager from "react-notifications/lib/NotificationManager";

const Integrations = () => {
  const [syncView, updateSyncView] = useState({ type: "FRESHTEAMS", showSync: false });
  const user = useSelector((state) => state.user);
  const [memberships, setMemberships] = useState([]);
  const dispatch = useDispatch();
  let history = useHistory();
 const [cookies, setCookie, removeCookie] = useCookies(["AT"]);
  const nodeInfo = useSelector((state) => state.nodeInfo);
  const fetchMemberships = async() => {
     await getAllMemberships(
       user,
       dispatch,
       NotificationManager,
       removeCookie,
       history,
       nodeInfo.nodeId,
       setMemberships,
       false,
     )
   }
   useEffect(() => {
     sendCustomPageView(
       user.userId,
       nodeInfo.name,
       nodeInfo.nodeCategory,
       getUserRole(user.roles, nodeInfo)
     );
     fetchMemberships();
   }, [nodeInfo, user]);
  return (
    <>
      {!syncView.showSync ? (
        <IntegrationsView updateSyncView={updateSyncView} />
      ) : (
        <IntegrationEmployeeSyncView type={syncView.type} memberships={memberships} setMemberships={setMemberships} />
      )}
    </>
  );
};

export default Integrations;
