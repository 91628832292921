import {
  UPDATE_FORM,
  DISABLE_FORM,
  TOGGLE_FORM,
  RESET_FORM_STATE,
} from "../actionTypes";

export const toggleFormDispatch = (payload, data) => {
  return {
    type: TOGGLE_FORM,
    payload,
    data,
  };
};

export const updateFormData = (formName, payload) => {
  return {
    type: UPDATE_FORM,
    payload,
    formName,
  };
};

export const disableForm = () => {
  return {
    type: DISABLE_FORM,
  };
};

export const resetFormState = () => {
  return {
    type: RESET_FORM_STATE,
  };
};
