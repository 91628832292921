import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CSSTransitionGroup } from "react-transition-group";
import { formNames, rootApi } from "../../config";
import { toggleForm } from "../../utils/utilFunctions";
import PeopleAddForm from "./peopleAddForm/PeopleAddForm";
import VisitorAddForm from "./visitorAddForm/VisitorAddForm";
import ZoneAddForm from "./zoneAddForm/ZoneAddForm";
import MembershipAccessInfoUpdateForm from "./membershipAccessInfoUpdateForm/MembershipAccessInfoUpdateForm";
// import GroupAddForm from "./groupAddForm/GroupAddForm";
import FreshTeamsIntegrationForm from "./freshTeamsIntegrationForm/FreshTeamsIntegrationForm";
import GreytHRIntegrationForm from "./greytHrIntegrationForm/greytHrIntegrationForm";
// import GroupUpdateForm from "./groupUpdateForm/GroupUpdateForm";
import RFIDAddForm from "./rfidAddForm/RFIDAddForm";
import GateOpenAlertDuration from "./gateOpenAlertDurationForm/GateOpenAlertDuration";
import AttendanceInfoUpdateForm from "./attendanceInfoUpdateForm/AttendanceInfoUpdateForm";
import NodeWifiUpdateForm from "./nodeWifiInfoUpdateForm/NodeWifiUpdateForm";
import EditNameForm from "./editNameForm/EditNameForm";
import AddOACForm from "./addOACForm/AddOACForm";
import CheckOACForm from "./checkOACForm/CheckOACForm";
import CheckDeviceRFDataForm from "./checkDeviceRFDataForm/CheckDeviceRFDataForm";
import DepartmentForm from "./DepartmentForm/DepartmentForm";
import UserDeviceRequestsForm from "./userDeviceRequestsForm/UserDeviceRequestsForm";
import { useLocation } from "react-router-dom";
import { getQueryParams } from "../../vms/Services/utils";
import HierarchyViewForm from "./hierarchyViewForm/HierarchyViewForm";
import DeviceConfigForm from "./deviceConfigForm/DeviceConfigForm";
import NodeHolidaysForm from "./nodeHolidaysForm/NodeHolidaysForm";
import NodeConfigForm from "./nodeConfigForm/NodeConfigForm";
import IntegrationEmployeeDetailsEditForm from "./integrationEmployeeSyncEditForm/IntegrationEmployeeSyncEditForm";
import AttendanceFormatForm from "./attendanceFormatForm/AttendanceFormatForm";
import DoorInfoUpdateForm from "./doorInfoUpdateForm/DoorInfoUpdateForm";
import FeatureNotofication from "../featureNotification/FeatureNotofication";
import AddOauthAppForm from "./addOauthAppForm/addOauthAppForm";
import LogSyncStatusForm from "./logSyncStatusForm/LogSyncStatusForm";
import AttendanceUserfilterForm from "./AttendanceUserfilterForm";
import CreateUserCredentialForm from "./createUserCredentialForm/CreateUserCredentialForm";
import DeviceFirmwareByDFIForm from "./deviceFirmware/DeviceFirmwareByDFIForm";
import DeviceFirmwareByNameForm from "./deviceFirmware/DeviceFirmwareByNameForm";
import BulkOperationForm from "./bulkOperationForm/BulkOperationForm";

const FormController = ({
  getVisitorsLogsAPI,
  getPeopleAPI,
  // getAccessGroupsAPI,
  notificationPageNo,
  setNotificationPageNo,
}) => {
  const formState = useSelector((state) => state.formState);
  const [formStatus, setFormStatus] = useState({});
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    setFormStatus({ ...getQueryParams(location.search) });
  }, [location]);

  return (
    <>
      <CSSTransitionGroup
        transitionName="example"
        transitionAppear={false}
        transitionEnter={true}
        transitionLeave={true}
        transitionAppearTimeout={500}
        transitionEnterTimeout={500}
        transitionLeaveTimeout={500}
      >
        <div key={"0"}></div>
        {formStatus[formNames.zoneAddForm] ? <ZoneAddForm key={1} /> : null}
        {formStatus[formNames.zoneUpdateForm] ? <ZoneAddForm key={2} /> : null}
        {formStatus[formNames.addRootNodeForm] ? <ZoneAddForm key={2283} /> : null}
        {formStatus[formNames.visitorAddForm] ? (
          <VisitorAddForm
            key={3}
            getVisitorsLogsAPI={getVisitorsLogsAPI}
            formName={formNames.visitorAddForm}
            setShowAddVisitorForm={toggleForm}
          />
        ) : null}
        {formStatus[formNames.peopleAddForm] ? (
          <PeopleAddForm key={4} getPeopleAPI={getPeopleAPI} />
        ) : null}
        {formStatus[formNames.membershipAccessInfoUpdateForm] ? (
          <MembershipAccessInfoUpdateForm
            key={5}
            getMembershipsAPI={getPeopleAPI}
            data={formState[formNames.membershipAccessInfoUpdateForm]}
          />
        ) : null}
        {/* {formStatus[formNames.groupAddForm] ? (
          <GroupAddForm key={6} getAccessGroupsAPI={getAccessGroupsAPI} />
        ) : null} */}
        {/* {formStatus[formNames.groupUpdateForm] ? (
          <GroupUpdateForm
            key={7}
            getAccessGroupsAPI={getAccessGroupsAPI}
            previousAccessGroupData={formState[formNames.groupUpdateForm]}
          />
        ) : null} */}
        {formStatus[formNames.rfidAddForm] ? (
          <RFIDAddForm
            key={8}
            getPeopleAPI={getPeopleAPI}
            id={formState[formNames.rfidAddForm].membershipId}
          />
        ) : null}
        {formStatus[formNames.GOADUpdateForm] ? <GateOpenAlertDuration key={9} /> : null}
        {formStatus[formNames.attendanceInfoUpdateForm] ? <AttendanceInfoUpdateForm key={10} /> : null}
        {formStatus[formNames.wifiInfoUpdateForm] ? <NodeWifiUpdateForm /> : null}
        {formStatus[formNames.editNameForm] ? (
          <EditNameForm getPeopleAPI={getPeopleAPI} key={11} />
        ) : null}
        {formStatus[formNames.addOACForm] ? <AddOACForm key={12} /> : null}
        {formStatus[formNames.departmentForm] ? <DepartmentForm key={13} /> : null}
        {formStatus[formNames.userDeviceRequestsForm] ? <UserDeviceRequestsForm key={14} /> : null}
        {formStatus[formNames.hierarchyViewForm] ? <HierarchyViewForm key={15} /> : null}
        {formStatus[formNames.deviceConfigForm] ? <DeviceConfigForm key={16} /> : null}
        {formStatus[formNames.nodeHolidayUpdateForm] ? <NodeHolidaysForm key={17} /> : null}
        {formStatus[formNames.nodeConfigForm] ? <NodeConfigForm key={17} /> : null}
        {formStatus[formNames.attendanceFormatForm] ? <AttendanceFormatForm key={18} /> : null}
        {formStatus[formNames.freshTeamsIntegrationFrom] ? <FreshTeamsIntegrationForm key={19} /> : null}
        {formStatus[formNames.integrationEmployeeDetailsEditForm] ? (
          <IntegrationEmployeeDetailsEditForm
            key={20}
            selectedUser={formState[formNames.integrationEmployeeDetailsEditForm]}
          />
        ) : null}
        {formStatus[formNames.doorInfoUpdateForm] ? <DoorInfoUpdateForm key={21} /> : null}
        {formStatus[formNames.checkOACForm] ? <CheckOACForm key={22} /> : null}
        {formStatus[formNames.checkDeviceRFDataForm] ? <CheckDeviceRFDataForm key={23} /> : null}
        {formStatus[formNames.addOauthAppForm] ? <AddOauthAppForm key={24} /> : null}
        {formStatus[formNames.logSyncStatusForm] ? <LogSyncStatusForm key={25} /> : null}
        {formStatus[formNames.notificationBox] ? (
          <FeatureNotofication
            notificationPageNo={notificationPageNo}
            setNotificationPageNo={setNotificationPageNo}
            key={26}
          />
        ) : null}
        {formStatus[formNames.createUserCredentialsForm] ? <CreateUserCredentialForm key={27} /> : null}
        {formStatus[formNames.greytHrIntegrationForm] ? (
          <GreytHRIntegrationForm key={28} selectedUser={formState[formNames.greytHrIntegrationForm]} />
        ) : null}
        {formStatus[formNames.addDeviceFirmwareByDFIForm] ? (
          <DeviceFirmwareByDFIForm key={29} edit={false} />
        ) : null}
        {formStatus[formNames.editDeviceFirmwareByDFIForm] ? (
          <DeviceFirmwareByDFIForm key={30} edit={true} />
        ) : null}
        {formStatus[formNames.addDeviceFirmwareByNameForm] ? (
          <DeviceFirmwareByNameForm key={31} edit={false} />
        ) : null}
        {formStatus[formNames.editDeviceFirmwareByNameForm] ? (
          <DeviceFirmwareByNameForm key={32} edit={true} />
        ) : null}
        {formStatus[formNames.bulkOperationForm] ? <BulkOperationForm key={33} /> : null}
      </CSSTransitionGroup>
    </>
  );
};

export default FormController;
