import React, { useState } from "react";
import { useCookies } from "react-cookie";
import NotificationManager from "react-notifications/lib/NotificationManager";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { findDeviceNode } from "../../../utils/requests";

const SEARCH_DEVICE = ({ user }) => {
  const [cookies, setCookie, removeCookie] = useCookies(["AT"]);
  const dispatch = useDispatch();
  const history = useHistory();
  const [response, setResponse] = useState({
    exactNodeName: "",
    rootNodeName: "",
  });
  const [input, setInput] = useState("");
  return (
    <div className="py-5 px-2 text-center">
      <div className="form-group">
        <input
          placeholder="Device Id"
          type="text"
          className="form-control"
          required
          value={input}
          onChange={(e) => setInput(e.target.value)}
        />
      </div>
      <div
        className="btn btn-primary"
        onClick={async (e) => {
          let result = await findDeviceNode(
            user,
            dispatch,
            NotificationManager,
            removeCookie,
            history,
            input
          );
          if (result.success) {
            setResponse({
              exactNodeName: result.exactNodeName,
              rootNodeName: result.rootNodeName,
            });
          }
        }}
      >
        Submit
      </div>

      <div style={{ fontSize: "large" }}>
        Exact Node Name: <b>{response.exactNodeName}</b>
      </div>

      <div style={{ fontSize: "large" }}>
        Root Node Name: <b>{response.rootNodeName}</b>
      </div>
    </div>
  );
};

export default SEARCH_DEVICE;
