import styled, { css } from "styled-components";

export const SolidButton = styled.button`
  border: 2px solid #49eecc;
  border-radius: 2rem;
  background-color: transparent;
  color: grey;
  margin-left: 1rem;
  outline: none;
  cursor: pointer;
  
  :hover {
    border: 2px solid #49eecc;
    color: #353535;;
    background-color : #49eecc;
    outline: none;
  }
  ${(props) =>
    props.submit
      ? css`
          padding: 0.5rem 1.5rem;
          font-size: 1rem;
          font-weight: bold;
        `
      : css`
          padding: 0.2rem 0.5rem;
          font-size: 0.8rem;
        `}
`;
