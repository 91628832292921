import {
  UPDATE_NODE_INFO,
  UPDATE_NODE_INFO_ALERT,
  UPDATE_NODE_INFO_RESTRICTIONS,
  UPDATE_NODE_INFO_PEOPLE_LIMIT,
  UPDATE_NODE_INFO_DEPARTMENTS,
  EDIT_NODE_INFO_DEPARTMENTS,
} from "../actionTypes";

export const updateNodeInfo = (payload) => {
  return {
    type: UPDATE_NODE_INFO,
    payload,
  };
};

export const updateNodeInfoAlert = (payload) => {
  return {
    type: UPDATE_NODE_INFO_ALERT,
    payload,
  };
};


export const updateNodeInfoRestrictions = (payload) => {
  return {
    type: UPDATE_NODE_INFO_RESTRICTIONS,
    payload,
  };
};

export const updateNodeInfoDepatments = (payload) =>{
  return {
    type : UPDATE_NODE_INFO_DEPARTMENTS,
    payload,
  }
};

export const updateNodeInfoPeopleLimit = (payload)=>{
  return {
    type: UPDATE_NODE_INFO_PEOPLE_LIMIT,
    payload : payload
  };
}