import React, { useEffect, useState } from "react";
import DevicesWithNames from "../deviceFirmware/devicesWithNames/DevicesWithNames";
import DevicesWithDFI from "../deviceWithDFI/DevicesWithDFI";
import { useCookies } from "react-cookie";
import NotificationManager from "react-notifications/lib/NotificationManager";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { deleteDeviceFirmwareAPI, getDeviceFirmwareListAPI } from "../../../utils/requests";

const DeviceFirmwareSection = ({ user }) => {
  const [cookies, setCookie, removeCookie] = useCookies(["AT"]);
  const dispatch = useDispatch();
  const history = useHistory();
  const [tabStatus, setTabStatus] = useState("names");
  const deviceFirmwares = useSelector((state) => state.deviceFirmwares);
  const getDevices = async () => {
    try {
      await getDeviceFirmwareListAPI(user, dispatch, NotificationManager, removeCookie, history);
    } catch (err) {
      console.log(err);
    }
  };

  const handleDelete = async (deviceFirmwareId) => {
    try {
      await deleteDeviceFirmwareAPI(
        user,
        dispatch,
        NotificationManager,
        removeCookie,
        history,
        deviceFirmwareId
      );
    } catch (err) {
      console.log(err, "error deleting device firmware");
    }
  };

  useEffect(() => {
    getDevices();
  }, []);
  return (
    <div className="py-3 px-2 text-center">
      <div className="dFlex aic pb-3">
        <div className="d-flex">
          <div style={{ position: "relative" }}>
            <h5
              style={{
                borderBottom: `${tabStatus === "names" ? "2px solid black" : ""}`,
                margin: "0 .5rem 0 0",
                textDecoration: "none",
                color: `${tabStatus === "names" ? "black" : "grey"}`,
              }}
              className="font-weight-bold cursor-pointer"
              onClick={(e) => setTabStatus("names")}
            >
              By Name
            </h5>
          </div>
        </div>
        <div className="d-flex">
          <div style={{ position: "relative" }}>
            <h5
              style={{
                borderBottom: `${tabStatus === "dfi" ? "2px solid black" : ""}`,
                margin: "0 .5rem 0 .5rem",
                textDecoration: "none",
                color: `${tabStatus === "dfi" ? "black" : "grey"}`,
              }}
              className="font-weight-bold cursor-pointer"
              onClick={(e) => setTabStatus("dfi")}
            >
              By DFI
            </h5>
          </div>
        </div>
      </div>

      {tabStatus === "names" ? (
        <DevicesWithNames devices={deviceFirmwares} handleDelete={handleDelete} />
      ) : (
        <DevicesWithDFI devices={deviceFirmwares} handleDelete={handleDelete} />
      )}
    </div>
  );
};

export default DeviceFirmwareSection;
