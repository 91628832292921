import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import { SolidButton } from "../components/buttons/solidButton.styled";
import { InputBox } from "../components/forms/FormBase.styled";
import { cookieDomain, rootApi } from "../config";
import { updateUser } from "../redux/user/userActions";
import { get, post } from "../utils/apiService";
import { useCookies } from "react-cookie";
import { FormContainer, Form } from "./Register";
import { parseQueryParams } from "../utils/utilFunctions";
import { createNotification, getQueryParams, notificationType } from "../vms/Services/utils";
import NotificationManager from "react-notifications/lib/NotificationManager";
import { ATexpired } from "../utils/logout";
import { Head } from "../Auth";
import { getUserMemberships, getUserRoles } from "../utils/requests";
import SignInWithGoogleButton from "../components/SignInWithGoogleButton";
import PrivacyAndTerms from "../components/PrivacyAndTerms";

const Login = ({ setMembershipsFetched, membershipsFetched, cameTo }) => {
  const [cookies, setCookie, removeCookie] = useCookies(["AT"]);
  const [forgotPasswordForm, setForgotPasswordForm] = useState(false);
  const [authURL, setAuthURL] = useState(null);
  const [queryParams, setQueryParams] = useState(null);
  const [formData, setFormData] = useState({
    id: "",
    password: "",
  });
  const location = useLocation();
  const [message, setMessage] = useState(null);
  const dispatch = useDispatch();
  const history = useHistory();
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const login = async () => {
    const url = `${rootApi}user/login`;

    setMessage(null);
    const body = formData.id.includes("@")
      ? {
          emailId: formData.id,
          password: formData.password,
        }
      : {
          mobileNo: formData.id,
          password: formData.password,
        };

    try {
      const response = await post(dispatch, url, body);

      if (!response.data.success) {
        createNotification(NotificationManager, notificationType.error, response.data.error);
        setMessage(response.data.error);
      } else {
        sessionStorage.removeItem("AuthError");
        const { roles, visitorPermissions } = await getUserRoles(
          { AT: response.data.AT },
          dispatch,
          NotificationManager,
          removeCookie,
          history
        );

        const r = {};
        Object.values(roles).forEach((ra) =>
          ra.roles.forEach((rl) => {
            console.log({ ra, rl });
            if (r[rl.nodeId]) {
              r[rl.nodeId].push(rl.role);
            } else {
              r[rl.nodeId] = [rl.role];
            }
          })
        );

        dispatch(updateUser({ ...response.data, roles: r, visitorPermissions }));
        setCookie("AT", response.data.AT, {
          path: "/",
          maxAge: 31536000,
          // domain: cookieDomain,
        });
        if (cameTo && cameTo.length) {
          // window.location.href = parseQueryParams(location.search).backto;
          window.location.href = cameTo;
        } else {
          location.pathname = "/apps";
        }
      }
      ATexpired(response, dispatch, removeCookie, history);
    } catch (err) {
      throw err;
    }
  };

  const getAuthURL = async () => {
    const url = `${rootApi}user/oauthURL?oauthProvider=google&redirectURI=${
      window.location.origin === "http://localhost:3000"
        ? "http://127.0.0.1:3000"
        : window.location.origin
    }`;
    const response = await get(dispatch, url);
    if (response.data.success) setAuthURL(response.data.authURL);
  };

  const getTokenFromOauthCode = async (params) => {
    const url = `${rootApi}user/login/oauth`;
    if (params.code) {
      const body = {
        oauthProvider: "google",
        redirectURI:
          window.location.origin === "http://localhost:3000"
            ? "http://127.0.0.1:3000"
            : window.location.origin,
        code: params.code,
      };
      const response = await post(dispatch, url, body);

      const { roles, visitorPermissions } = await getUserRoles(
        { AT: response.data.AT },
        dispatch,
        NotificationManager,
        removeCookie,
        history
      );

      const r = {};
      Object.values(roles).forEach((ra) =>
        ra.roles.forEach((rl) => {
          console.log({ ra, rl });
          if (r[rl.nodeId]) {
            r[rl.nodeId].push(rl.role);
          } else {
            r[rl.nodeId] = [rl.role];
          }
        })
      );

      dispatch(updateUser({ ...response.data, roles: r, visitorPermissions }));
      setCookie("AT", response.data.AT, {
        path: "/",
        maxAge: 31536000,
        // domain: cookieDomain,
      });
      if (cameTo && cameTo.length) {
        // window.location.href = parseQueryParams(location.search).backto;
        window.location.href = cameTo;
      } else {
        location.pathname = "/apps";
      }
    }
  };

  useEffect(() => {
    getAuthURL();
    const params = getQueryParams(window.location.search);
    if (params.code) {
      getTokenFromOauthCode(params);
    }
  }, []);

  return (
    <>
      <FormContainer>
        <Head />
        {!forgotPasswordForm ? (
          <Form>
            <h5 className="text-center">Login</h5>
            <div className="row">
              <div className="col-12 ">
                <InputBox
                  placeholder="Email or Mobile"
                  name="id"
                  type="text"
                  onChange={handleChange}
                  value={formData.id}
                />
              </div>
              <div className="col-12 ">
                <InputBox
                  name="password"
                  type="password"
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      login();
                    }
                  }}
                  onChange={handleChange}
                  placeholder="Password"
                  value={formData.password}
                />
              </div>
              <div className="col-12 px-1 mt-3 text-center">
                {message ? <p className="font-weight-bold text-danger text-center">{message}</p> : null}
                {localStorage.getItem("ATMISMATCH") === "true" ? (
                  <p className="font-weight-bold text-danger text-center">
                    {"Account conflict occured please login again"}
                  </p>
                ) : null}
              </div>
              <div className="col-12 px-1 mt-3 text-center">
                <SolidButton onClick={(e) => login()}>Login</SolidButton>
              </div>
              <div className="col-12 px-1 mt-3 d-flex justify-content-center">
                <SignInWithGoogleButton authURL={authURL} />
              </div>
              <div className="col-12 text-center mt-3">
                <p className="m-0 p-0">
                  <span
                    style={{
                      color: "#007bff",
                    }}
                    className="m-0 p-0 cursor-pointer hoverUnderline"
                    onClick={(e) => setForgotPasswordForm(true)}
                  >
                    Forgot Password ?
                  </span>
                </p>
                <p className="m-0 p-0">
                  <Link to="/register/mobile">Register With Mobile</Link>
                </p>
                <p className="m-0 p-0">
                  <Link to="/register/email">Register With Email</Link>
                </p>
              </div>
              <PrivacyAndTerms />
            </div>
          </Form>
        ) : (
          <ResetPassword
            formData={formData}
            setFormData={setFormData}
            handleChange={handleChange}
            setForgotPasswordForm={setForgotPasswordForm}
          />
        )}
      </FormContainer>
    </>
  );
};

export default Login;

const ResetPassword = ({ formData, handleChange, setForgotPasswordForm }) => {
  const [cookies, setCookie, removeCookie] = useCookies(["AT"]);
  const [OTPmessage, setOTPMessage] = useState(null);
  const [resetPasswordMessage, setResetPasswordMessage] = useState(null);
  const [OTPRecieved, setOTPRecieved] = useState(false);
  const [resetFormData, setResetFormData] = useState({
    OTP: "",
    password: "",
    password2: "",
  });
  const dispatch = useDispatch();
  const history = useHistory();
  const resetFormHandleChange = (e) => {
    setResetPasswordMessage(false);
    setResetFormData({ ...resetFormData, [e.target.name]: e.target.value });
  };
  const requestResetPasswordOTP = async () => {
    const url = `${rootApi}user/sendForgotPasswordOTP`;

    setOTPMessage(null);
    const body = formData.id.includes("@")
      ? {
          emailId: formData.id,
        }
      : {
          mobileNo: formData.id,
        };

    try {
      const response = await post(dispatch, url, body);

      if (!response.data.success) {
        createNotification(NotificationManager, notificationType.error, response.data.error);
        setOTPMessage(response.data.message);
      } else {
        console.log(response.data);
        setOTPRecieved(true);
      }
      ATexpired(response, dispatch, removeCookie, history);
    } catch (err) {
      throw err;
    }
  };

  const resetPassword = async () => {
    const url = `${rootApi}user/resetPassword`;
    setResetPasswordMessage(null);
    if (resetFormData.password === resetFormData.password2) {
      const body = formData.id.includes("@")
        ? {
            OTP: resetFormData.OTP,
            emailId: formData.id,
            password: resetFormData.password,
          }
        : {
            OTP: resetFormData.OTP,
            mobileNo: formData.id,
            password: resetFormData.password,
          };

      try {
        const response = await post(dispatch, url, body);
        if (!response.data.success) {
          createNotification(NotificationManager, notificationType.error, response.data.error);
          setResetPasswordMessage(response.data.error);
        } else {
          setForgotPasswordForm(false);
        }
        ATexpired(response, dispatch, removeCookie, history);
      } catch (err) {
        throw err;
      }
    } else {
      setResetPasswordMessage("Passwords Don't match");
    }
  };

  // useEffect(() => {
  //   return () => {
  //     setOTPRecieved(false);
  //   };
  // }, [OTPRecieved]);

  return (
    <>
      {!OTPRecieved ? (
        <Form>
          <h5 className="text-center">Reset Password</h5>
          <div className="row">
            <div className="col-12 ">
              <InputBox
                placeholder="Email or Mobile"
                name="id"
                type="text"
                onChange={handleChange}
                value={formData.id}
              />
            </div>

            <div className="col-12 px-1 mt-3 text-center">
              {OTPmessage ? (
                <p className="font-weight-bold text-danger text-center">{OTPmessage}</p>
              ) : null}
            </div>
            <div className="col-12 px-1 mt-3 text-center">
              <SolidButton onClick={requestResetPasswordOTP}>Request OTP</SolidButton>
            </div>
            <div className="col-12 text-center mt-3">
              <p className="m-0 p-0">
                <span
                  style={{ color: "#007bff" }}
                  className="m-0 p-0 cursor-pointer hoverUnderline"
                  onClick={(e) => setForgotPasswordForm(false)}
                >
                  Go to Login
                </span>
              </p>
            </div>
            <PrivacyAndTerms />
          </div>
        </Form>
      ) : (
        <Form>
          <h5 className="text-center">Reset Password</h5>
          <div className="row">
            <div className="col-12 ">
              <InputBox
                placeholder="Enter OTP"
                name="OTP"
                type="text"
                onChange={resetFormHandleChange}
                value={resetFormData.OTP}
              />
            </div>
            <div className="col-12 ">
              <InputBox
                placeholder="Enter Password"
                name="password"
                type="password"
                onChange={resetFormHandleChange}
                value={resetFormData.password}
              />
            </div>
            <div className="col-12 ">
              <InputBox
                placeholder="Re Enter Password"
                name="password2"
                type="password"
                onChange={resetFormHandleChange}
                value={resetFormData.password2}
              />
            </div>

            <div className="col-12 px-1 mt-3 text-center">
              {resetPasswordMessage ? (
                <p className="font-weight-bold text-danger text-center">{resetPasswordMessage}</p>
              ) : null}
            </div>
            <div className="col-12 px-1 mt-3 text-center">
              <SolidButton onClick={resetPassword}>Reset Password</SolidButton>
            </div>
            <div className="col-12 text-center mt-3">
              <p className="m-0 p-0">
                <span
                  style={{ color: "#007bff" }}
                  className="m-0 p-0 cursor-pointer hoverUnderline"
                  onClick={(e) => setForgotPasswordForm(false)}
                >
                  Go to Login
                </span>
              </p>
            </div>
            <PrivacyAndTerms />
          </div>
        </Form>
      )}
    </>
  );
};
