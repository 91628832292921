import React from "react";
import { IoMdArrowRoundBack } from "react-icons/io";
import { Link } from "react-router-dom";
const Placeholder = () => {
  return (
    <div>
      <div
        style={{
          textAlign: "left",
          marginTop: " 30px",
          fontSize: "1.5rem",
        }}
      >
        {/* <Link to="/" style={{ color: "#e53d54", textDecoration: "none" }}>
          <IoMdArrowRoundBack />
        </Link> */}
      </div>
      <div
        style={{
          height: "70vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <h4>Coming Soon ...</h4>
      </div>
    </div>
  );
};

export default Placeholder;
