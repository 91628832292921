import { UPDATE_DIMENTIONS } from "../actionTypes";

const dimentionsReducer = (dimentions = {}, action) => {
  if (action.type === UPDATE_DIMENTIONS) {
    return (dimentions = action.payload);
  }

  return dimentions;
};

export default dimentionsReducer;
