import React, { useState, useEffect } from "react";
import { useCookies } from "react-cookie";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import NotificationManager from "react-notifications/lib/NotificationManager";
import { getNodeLogSyncStatusRequestsAPI } from "../../../utils/requests";
import { breakPointWidth, formNames } from "../../../config";
import { toggleForm, nullChecker, displayDate } from "../../../utils/utilFunctions";
import { FormWrapper } from "../doorAddForm/DoorAddForm";
import PlaceholderMessage from "../../PlaceholderMessage";
import {
  RowContainer,
  RowStyled,
  DeviceId,
  DeviceSyncDate,
  LogSyncDate,
} from "./LogSyncStatusForm.styled";
import { OverFLowScrollBox } from "../../App.styled";

const LogSyncStatusForm = () => {
  const [cookies, setCookie, removeCookie] = useCookies(["AT"]);
  const location = useLocation();
  let history = useHistory();
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [newData, setNewData] = useState([]);
  const user = useSelector((state) => state.user);
  const selectedNode = useSelector((state) => state.selectedNode);
  const dimentions = useSelector((state) => state.dimentions);
  const formState = useSelector((state) => state.formState);
  const nodeInfo = useSelector((state) => state.nodeInfo);
  const closeForm = () => {
    toggleForm(formState, history, location, dispatch, formNames.logSyncStatusForm);
  };

  const getNodeLogSyncStatusRequestsAPICall = () => {
    getNodeLogSyncStatusRequestsAPI(
      user,
      dispatch,
      NotificationManager,
      removeCookie,
      history,
      setData,
      selectedNode
    );
  };

  useEffect(() => {
    if (
      data &&
      data.devices &&
      data.devices.length &&
      nodeInfo &&
      nodeInfo.door &&
      nodeInfo.door.length
    ) {
      let temp = { devices: [] };

      data.devices.forEach((devices) => {
        nodeInfo.door.forEach((d) => {
          if (d.doorId === devices.doorId) {
            temp.devices.push({
              ...devices,
              name: d.name,
            });
          }
        });
      });

      setNewData({ ...temp });
    }
  }, [data]);

  useEffect(() => {
    getNodeLogSyncStatusRequestsAPICall();
  }, [selectedNode]);

  return (
    <FormWrapper dimentions={dimentions} closeForm={closeForm}>
      <div className="text-center text-info pb-4">
        This page show the times when data was uploaded from device to dashboard, as well as the latest
        log uploaded to dashboard.
      </div>
      <RowContainer>
        <RowStyled isHead={true}>
          <DeviceId>{"Door Name"}</DeviceId>
          <DeviceSyncDate>{"Latest time when device data uploaded to dashboard"}</DeviceSyncDate>
          <LogSyncDate>{"Latest log uploaded to dashboard"}</LogSyncDate>
        </RowStyled>
        <OverFLowScrollBox>
          {newData.devices && newData.devices.length ? (
            newData.devices.map((item, index) => {
              return (
                <RowStyled key={`${item.deviceId}`}>
                  <DeviceId>{`${nullChecker(item.name)} / ${item.customDeviceId}`}</DeviceId>
                  <DeviceSyncDate>
                    {item.timestamp ? displayDate(item.timestamp) : "-"}{" "}
                    {item.timestamp ? new Date(item.timestamp).toTimeString().slice(0, 8) : "-"}
                  </DeviceSyncDate>
                  <LogSyncDate>
                    {item.deviceTimestamp ? displayDate(item.deviceTimestamp) : "-"}{" "}
                    {item.deviceTimestamp
                      ? new Date(item.deviceTimestamp).toTimeString().slice(0, 8)
                      : "-"}
                  </LogSyncDate>
                </RowStyled>
              );
            })
          ) : (
            <PlaceholderMessage message="No data to display" />
          )}
        </OverFLowScrollBox>
      </RowContainer>
    </FormWrapper>
  );
};

export default LogSyncStatusForm;
