import { UPDATE_VISITOR_LOGS } from "../actionTypes";

const initialState = {
  data: [],
  visitorLoggedOut: 0,
  visitorInside: 0,
  totalNoOfVisitor: 0,
};

const visitorLogsReducer = (visitorLogs = initialState, action) => {
  switch (action.type) {
    case UPDATE_VISITOR_LOGS:
      return action.payload;
    default:
      return visitorLogs;
  }
};

export default visitorLogsReducer;
