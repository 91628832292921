import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { breakPointWidth, contentLength, formNames } from "../../../config";
import { nullChecker, stringToChunks, toggleForm } from "../../../utils/utilFunctions";
import { OverFLowScrollBox } from "../../App.styled";
import { SolidButton } from "../../buttons/solidButton.styled";
import StringSpreader from "../../StringSpreader";
import {
  ButtonMobile,
  FieldLabelMobile,
  FieldValueMobile,
  ZoneLogsUserRowMobileWrapper,
} from "../../zone/ZoneLogsUserRow/zoneLogsUserRow.styled";

import {
  Delete,
  DevicesWithDFIRowContainer,
  DevicesWithDFIRowStyled,
  DFI,
  UpdateDFITo,
  AppURL,
  BootloaderURL,
  Edit,
  FirmwareId,
  Button,
} from "./deviceWithDFI.styled";

const DevicesWithDFI = ({ devices, handleDelete }) => {
  const dimentions = useSelector((state) => state.dimentions);
  const formState = useSelector((state) => state.formState);
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();

  return (
    <>
      <div className="text-right pb-3">
        <SolidButton
          style={{ marginRight: "0px !important" }}
          onClick={(e) =>
            toggleForm(formState, history, location, dispatch, formNames.addDeviceFirmwareByDFIForm)
          }
        >
          Add
        </SolidButton>
      </div>
      {dimentions.width > breakPointWidth ? (
        <DevicesWithDFIRowContainer>
          <DevicesWithDFIRowStyled isHead={true}>
            <DFI>DFI</DFI>
            <UpdateDFITo>Update DFI To</UpdateDFITo>
            <AppURL>App URL</AppURL>
            <BootloaderURL>Bootloader URL</BootloaderURL>
            <Edit>Edit</Edit>
            <Delete>Delete</Delete>
          </DevicesWithDFIRowStyled>
          <OverFLowScrollBox>
            {devices &&
              devices
                .filter((d) => d.DFI)
                .map((d) => (
                  <DevicesWithDFIRowStyled>
                    <DFI>{nullChecker(d.DFI)}</DFI>
                    <UpdateDFITo>
                      <StringSpreader
                        length={contentLength.text}
                        string={nullChecker(d.updateDFITo)}
                      ></StringSpreader>
                    </UpdateDFITo>
                    <AppURL>
                      <StringSpreader
                        length={contentLength.text}
                        string={nullChecker(d.appUrl)}
                      ></StringSpreader>
                    </AppURL>
                    <BootloaderURL>
                      <StringSpreader
                        length={contentLength.text}
                        string={nullChecker(d.bootloaderUrl)}
                      ></StringSpreader>
                    </BootloaderURL>
                    <Edit>
                      <Button
                        onClick={(e) =>
                          toggleForm(
                            formState,
                            history,
                            location,
                            dispatch,
                            formNames.editDeviceFirmwareByDFIForm,
                            { ...d }
                          )
                        }
                      >
                        Edit
                      </Button>
                    </Edit>
                    <Delete>
                      <Button onClick={(e) => handleDelete(d.deviceFirmwareId)}>Delete</Button>
                    </Delete>
                  </DevicesWithDFIRowStyled>
                ))}
          </OverFLowScrollBox>
        </DevicesWithDFIRowContainer>
      ) : (
        <div className="row">
          {devices &&
            devices
              .filter((d) => d.DFI)
              .map((d) => (
                <div className="col-sm-12 col-xs-12 p-0 col-md-6 px-md-2" key={d.deviceFirmwareId}>
                  <ZoneLogsUserRowMobileWrapper>
                    <FieldLabelMobile>DFI</FieldLabelMobile>
                    <FieldValueMobile>{nullChecker(d.DFI)}</FieldValueMobile>
                    <FieldLabelMobile>Update DFI To</FieldLabelMobile>
                    <FieldValueMobile>{nullChecker(d.updateDFITo)}</FieldValueMobile>
                    <FieldLabelMobile>App Url</FieldLabelMobile>
                    <FieldValueMobile>{nullChecker(d.appUrl)}</FieldValueMobile>
                    <FieldLabelMobile>Bootloader Url</FieldLabelMobile>
                    <FieldValueMobile>{nullChecker(d.bootloaderUrl)}</FieldValueMobile>
                    <div className="row my-2">
                      <div className="col-12">
                        <ButtonMobile
                          className="w-100"
                          onClick={(e) =>
                            toggleForm(
                              formState,
                              history,
                              location,
                              dispatch,
                              formNames.editDeviceFirmwareByDFIForm,
                              { ...d }
                            )
                          }
                        >
                          Edit
                        </ButtonMobile>
                      </div>
                    </div>
                    <div className="row my-2">
                      <div className="col-12">
                        <ButtonMobile
                          className="w-100"
                          onClick={(e) => handleDelete(d.deviceFirmwareId)}
                        >
                          Delete
                        </ButtonMobile>
                      </div>
                    </div>
                  </ZoneLogsUserRowMobileWrapper>
                </div>
              ))}
        </div>
      )}
    </>
  );
};

export default DevicesWithDFI;
