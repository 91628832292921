import React, { useState } from "react";
import { useSelector } from "react-redux";
import styled, { css } from "styled-components";
import { breakPointWidth } from "../../config";
import DisabledOverlay, { DisabledLockIcon } from "../DisabledOverlay";
const AccessDays = ({ daysofWeek, onClick, nodeId, onAttendance }) => {
  const [data, setData] = useState(["M", "T", "W", "Th", "F", "S", "Su"]);
  const dimentions = useSelector((state) => state.dimentions);
  const nodeInfo = useSelector((state) => state.nodeInfo);

  return (
    <div className="d-flex ml-4 ">
      {!onAttendance && !nodeInfo?.features?.editAccessDays ? <DisabledLockIcon size="2rem" /> : null}
      <div className="ml-4 my-3 p-3 rounded" style={{ position: "relative" }}>
        {!onAttendance && !nodeInfo?.features?.editAccessDays ? <DisabledOverlay size="2.5rem" /> : null}
        {onAttendance ? null : <p className="m-0 font-weight-bold">Access days</p>}
        <div className="d-flex w-100 justify-content-end mb-2" style={{ color: "grey" }}>
          <p
            className="m-0 font-weight-bold text-right cursor-pointer mr-2"
            onClick={(e) => onClick(daysofWeek, nodeId, true)}
          >
            Select all
          </p>
          <p
            className="m-0 font-weight-bold text-right cursor-pointer"
            onClick={(e) => onClick(daysofWeek, nodeId, false, true)}
          >
            Clear all
          </p>
        </div>
        <DayGroup>
          {daysofWeek.map((day, index) => (
            <Day
              dimentions={dimentions}
              key={data[index]}
              selected={day}
              onClick={(e) => onClick(index, nodeId)}
            >
              {data[index]}
            </Day>
          ))}
        </DayGroup>
      </div>
    </div>
  );
};

export default AccessDays;

const DayGroup = styled.div`
  display: flex;
  align-items: center;
`;

const Day = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  cursor: pointer;
  padding: ${(props) => (props.dimentions.width > breakPointWidth ? "5px 12px;" : "2px 4px;")}
  margin-right: ${(props) => (props.dimentions.width > breakPointWidth ? "15px;" : "2px;")}
  ${(props) =>
    props.selected
      ? css`
          background-color: #49eecc;
          color: #ffffff;
          border: 1px solid #49eecc;
          box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
          border-radius: 4px;
        `
      : css`
          background-color: #ffffff;
          color: #000000;
          border: 1px solid #898989;
          border-radius: 5px;
        `};
`;
