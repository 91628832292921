import styled, { css } from "styled-components";


export const InActiveDevicesRowStyle = styled.div`
  display: grid;
  grid-row-gap: 0.5rem;
  grid-column-gap: 0.2rem;
  padding: 0.5rem 1rem;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-areas: "deviceId type addDate button";

  ${(props) =>
    props.active
      ? css`
          background-image: linear-gradient(91deg, #fe6dad -3%, #fc9f35);
        `
      : css``}
  ${(props) =>
    props.isHead
      ? css`
          font-weight: bold;
        `
      : css`
          :hover {
            color: white;
            background-image: linear-gradient(91deg, #fe6dad -3%, #fc9f35);
          }
        `}
`;

export const DeviceId = styled.div`
  display: flex;
  grid-area: "deviceId";
  justify-content: center;
  align-items: center;
`;
export const Type = styled.div`
  grid-area: "type";
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const AddDate = styled.div`
  grid-area: "addDate";
  display: flex;
  justify-content: center;
  align-items: center;
`;


export const Button = styled.button`
  grid-area: "button";
  background-color: transparent;
  border: 1px solid black;
  outline: none;
  color: black;
  justify-self: center;
  align-self: center;
  cursor: pointer;
  font-size: 0.8rem;
  padding: 0.2rem 1rem;
  border-radius: 20px;
  ${(props) =>
    props.isHead
      ? css`
          visibility: hidden;
        `
      : css``}
  :hover {
    opacity: 0.5;
    color: black;
    border: 1px solid black;
  }
`;
