import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import NotificationManager from "react-notifications/lib/NotificationManager";
import { CSVDownloader, readString } from "react-papaparse";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { apiEndPoints } from "../../../config";
import { bulkRfidAssignAPI } from "../../../utils/requests";
import { SolidButton } from "../../buttons/solidButton.styled";
import ButtonState from "../../ButtonState/ButtonState";
import BulkOperationTable from "./BulkOperationTable";

const BulkRfidAssignForm = ({ allMemberships }) => {
  const [cookies, setCookie, removeCookie] = useCookies(["AT"]);
  const [data, setData] = useState(null);
  const dispatch = useDispatch();
  const history = useHistory();
  const [formData, setFormData] = useState([]);
  const [error, setError] = useState(false);
  const user = useSelector((state) => state.user);
  const selectedNode = useSelector((state) => state.selectedNode);
  const apiStatus = useSelector((state) => state.apiStatus);

  const populateData = (data) => {
    setError(false);
    let formData = [];
    if (data && data.length > 1) {
      for (let i = 1; i < data.length; i++) {
        const [name, emailOrMobile, rfid] = data[i];
        if (emailOrMobile && emailOrMobile.length) {
          if (emailOrMobile.includes("@")) {
            formData.push({ emailId: emailOrMobile, customRFId: rfid });
          } else formData.push({ mobileNo: emailOrMobile, customRFId: rfid });
        }
        if (i === data.length - 1) {
          setFormData([...formData]);
        }
      }
    } else setError("uploaded File is empty");
  };

  const handleSubmit = () => {
    let body = {};
    body.nodeId = selectedNode.nodeId;
    body.bulkRFCardUserData = JSON.stringify(formData);
    bulkRfidAssignAPI(
      user,
      dispatch,
      NotificationManager,
      removeCookie,
      history,
      body,
      () => null,
      setError
    );
  };

  useEffect(() => {
    if (allMemberships && allMemberships.length) {
      let d = allMemberships.map((m) => ({
        Name: m.userInfo.firstName + " " + m.userInfo.lastName,
        "Email or Mobile No (only one type)": m.userInfo.mobile.number || m.userInfo.email.id,
        RFID: m.RFCards.length ? m.RFCards[0].customRFId : "",
      }));
      setData([...d]);
    } else if (allMemberships && allMemberships.length === 0) {
      setData([
        {
          "Email or Mobile No (only one type)": "9898989898",
          RFID: "00000",
        },
        {
          "Email or Mobile No (only one type)": "test@test.com",
          RFID: "00001",
        },
      ]);
    }
  }, [allMemberships]);

  return (
    <div className="mt-5">
      <h6 className="font-weight-bold mb-4">Bulk RFID assign</h6>
      <div style={{ display: "flex", justifyContent: "flex-end" }} className="my-3">
        <button
          className="solid-button"
          style={{ display: "inline-block", margin: "0rem 0.5rem" }}
          onClick={(e) => document.getElementById("BulkAssignRFID").click()}
        >
          Bulk Import CSV
        </button>
        <input
          type="file"
          id="BulkAssignRFID"
          name="BulkAssignRFID"
          onChange={(e) => {
            let reader = new FileReader();
            if (e.target.files[0]) {
              reader.readAsText(e.target.files[0]);
              reader.onload = (ex) => {
                var data = readString(ex.target.result, {
                  header: false,
                }).data;
                populateData(data);
              };
            }
          }}
          accept=".csv"
          style={{ display: "none" }}
        />
        <CSVDownloader
          className="solid-button"
          style={{ display: "inline-block", margin: "0rem 0.5rem" }}
          data={data}
          type="button"
          filename="Bulk RFID assign Sample"
          bom={true}
          disabled={!data}
        >
          Download Sample CSV
        </CSVDownloader>
      </div>
      {formData && formData.length ? (
        <>
          <BulkOperationTable data={formData} />
        </>
      ) : null}
      {error ? <p className="text-danger text-center">{error.message}</p> : null}
      {error && error.users && error.users.length ? <BulkOperationTable data={error.users} /> : null}
      <div className="text-right py-4">
        <SolidButton onClick={handleSubmit}>
          <ButtonState loading={apiStatus[apiEndPoints.bulkRfidAssignAPI]} innerText={"Submit"} />
        </SolidButton>
      </div>
    </div>
  );
};

export default BulkRfidAssignForm;
