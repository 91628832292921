import styled from "styled-components";

export const TimingContainer = styled.div`
  border: 0.5px solid #e8e8e8;
  width : 100%;
  margin : .5rem 0rem;
  box-sizing: border-box;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.06);
  border-radius: 10px;
  padding: 0.2rem 1rem;
`;


