import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import NotificationManager from "react-notifications/lib/NotificationManager";
import { useDispatch, useSelector } from "react-redux";
import { Link, Redirect, useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";
import { cloudFrontOrigin } from "../config";
import { getOauthPermissions, allowOauth } from "../utils/requests";
import { getQueryParams } from "../vms/Services/utils";

const OauthDialouge = () => {
  const [cookies, setCookie, removeCookie] = useCookies(["AT"]);
  const location = useLocation();
  const [params, setParams] = useState(getQueryParams(location.search));
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const history = useHistory();
  const nodeInfo = useSelector((state) => state.nodeInfo);
  const [clientInfo, setClientInfo] = useState(null);
  const [selectedNode, setSelectedNode] = useState(nodeInfo || null);
  const nodes = useSelector((state) => state.nodes);
  const [error, setError] = useState({ accessError: false });
  useEffect(() => {
    if (params && params.clientId) {
      getOauthPermissions(
        user,
        dispatch,
        NotificationManager,
        removeCookie,
        history,
        params.clientId,
        setClientInfo
      );
    }
  }, [params]);

  return (
    <OauthWrapper>
      {clientInfo && clientInfo.code === 1007 ? <Redirect to="/" /> : null}
      <OauthBox>
        {clientInfo ? (
          <div className="container-fluid">
            {console.log("inside render oauth")}
            <div className="row">
              <div className="col-12 text-center">
                <img
                  alt="Securepass"
                  className="p-0 m-0"
                  style={{ width: "200px" }}
                  src={`${cloudFrontOrigin}SecurePass_b3mm6p_rnqlfl.jpg`}
                />
              </div>
              <>
                {clientInfo.success ? (
                  <>
                    <div className="col-12 text-center py-5">
                      <div className="col-12 my-3">
                        <select
                          className="vms-select"
                          id="AccessArea"
                          onChange={(e) => {
                            if (e.target.value === "Select a node") {
                              setSelectedNode(null);
                              setError({ ...error, accessError: true });
                            } else {
                              setError({ ...error, accessError: false });
                              setSelectedNode({ ...JSON.parse(e.target.value) });
                            }
                          }}
                        >
                          <option value={null}>{"Select a node"}</option>
                          {nodes.map((n) => (
                            <option selected={selectedNode.nodeId === n.nodeId} value={JSON.stringify(n)}>
                              {n.name}
                            </option>
                          ))}
                        </select>

                        {error.accessError ? (
                          <div className="col-12">
                            <small className="vms-small" id="accessError">
                              Please select a Node.
                            </small>
                          </div>
                        ) : null}
                      </div>
                      <h5 className="my-3">
                        <span style={{ color: "blue" }}>{clientInfo ? clientInfo.name : ""}</span>
                        <br />
                        needs your permission to access data
                      </h5>
                      {/* <div className="mt-3">
                        {params.scopes.split(",").map((v) => (
                          <h6 className="m-0 p-0">{v}</h6>
                        ))}
                      </div> */}
                    </div>
                    <div className="col-6 d-flex justify-content-end ">
                      <button
                        className="btn btn-sm btn-success w-75 py-2"
                        disabled={error.accessError}
                        onClick={(e) => {
                          allowOauth(
                            user,
                            dispatch,
                            NotificationManager,
                            removeCookie,
                            history,
                            "true",
                            JSON.stringify(params.scopes.split(",")),
                            params.clientId,
                            params.state,
                            params.responseType,
                            params.redirectUri,
                            location,
                            selectedNode.nodeId
                          );
                        }}
                      >
                        Accept
                      </button>
                    </div>
                    <div className="col-6 d-flex justify-content-start ">
                      <Link
                        className="btn btn-sm btn-danger w-75 py-2"
                        style={{ textDecoration: "none", color: "white" }}
                        to="/access"
                      >
                        Reject
                      </Link>
                    </div>
                  </>
                ) : (
                  <div className="col-12 text-center">
                    <h6 className="m-0 p-0 my-5">{clientInfo.error}</h6>
                    <div className="btn btn-sm btn-danger py-2">
                      <Link style={{ textDecoration: "none", color: "white" }} to="/">
                        Go to Homepage
                      </Link>
                    </div>
                  </div>
                )}
              </>
            </div>
          </div>
        ) : (
          "loading..."
        )}
      </OauthBox>
    </OauthWrapper>
  );
};

export default OauthDialouge;

const OauthWrapper = styled.div`
  position: fixed;
  height: 100vh;
  width: 100%;
  top: 0;
  left: 0;
  background-color: rgba(114, 110, 110, 0.5);
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const OauthBox = styled.div`
  max-width: 90%;
  background: #ffffff;
  box-shadow: 8px 4px 24px 10px #979797, -8px 0px 24px 10px rgba(151, 151, 151, 0.3);
  border-radius: 19px;
  padding: 2rem 1rem;
`;
