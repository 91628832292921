import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { apiEndPoints, formNames } from "../../../config";
import { SolidButton } from "../../buttons/solidButton.styled";
import { FormWrapper } from "../doorAddForm/DoorAddForm";
import { useCookies } from "react-cookie";
import { toggleForm } from "../../../utils/utilFunctions";
import { FieldInput, FieldLabel, InputBox, InputGroup } from "../FormBase.styled";
import { useHistory, useLocation } from "react-router-dom";
import NotificationManager from "react-notifications/lib/NotificationManager";
import { updateDoorInfo } from "../../../utils/requests";
import ButtonState from "../../ButtonState/ButtonState";

const DoorInfoUpdateForm = () => {
  const closeForm = () => {
    toggleForm(formState, history, location, dispatch, formNames.doorInfoUpdateForm);
  };
  const [cookies, setCookie, removeCookie] = useCookies(["AT"]);
  let history = useHistory();
  const dispatch = useDispatch();
  const nodeInfo = useSelector((state) => state.nodeInfo);
  const dimentions = useSelector((state) => state.dimentions);
  const apiStatus = useSelector((state) => state.apiStatus);
  const selectedNode = useSelector((state) => state.selectedNode);
  const formState = useSelector((state) => state.formState);
  const location = useLocation();
  const initialData = useSelector((state) => state.formState[formNames.doorInfoUpdateForm]);
  const user = useSelector((state) => state.user);
  const [formData, setFormData] = useState({
    doorName: "",
    nodeId: "",
    doorId: "",
  });

  useEffect(() => {
    if (initialData) {
      setFormData({
        doorName: initialData.name,
        nodeId: initialData.nodeId,
        doorId: initialData.doorId,
      });
    }
  }, [initialData]);

  return (
    <FormWrapper dimentions={dimentions} closeForm={closeForm}>
      <h6 className="font-weight-bold mb-4">Update Door {`( ${initialData.name} )`}</h6>
      <InputGroup dimentions={dimentions}>
        <FieldLabel dimentions={dimentions}>Door Name</FieldLabel>
        <FieldInput>
          <InputBox
            type="text"
            // placeholder="Enter first name"
            value={formData.doorName}
            onChange={(e) => setFormData({ ...formData, doorName: e.target.value })}
          />
        </FieldInput>
      </InputGroup>

      <InputGroup dimentions={dimentions}>
        <FieldLabel dimentions={dimentions}></FieldLabel>
        <FieldInput submitButton={true}>
          <SolidButton
            submit={true}
            onClick={(e) =>
              updateDoorInfo(
                user,
                formData,
                NotificationManager,
                dispatch,
                removeCookie,
                history,
                closeForm,
                selectedNode
              )
            }
          >
            <ButtonState loading={apiStatus[apiEndPoints.updateDoorInfo]} innerText={"Update"} />
          </SolidButton>
        </FieldInput>
      </InputGroup>
    </FormWrapper>
  );
};

export default DoorInfoUpdateForm;
