import React, { useEffect, useState } from "react";
import { FormWrapper } from "../doorAddForm/DoorAddForm";
import "react-datepicker/dist/react-datepicker.css";
import { FieldInput, FieldLabel, InputBox, InputGroup, SelectBox } from "../FormBase.styled";
import { SolidButton } from "../../buttons/solidButton.styled";
import { useDispatch, useSelector } from "react-redux";
import { apiEndPoints, formNames } from "../../../config";
import { toggleForm } from "../../../utils/utilFunctions";
import { addDepartmentAPI, removeDepartmentAPI, updateDepartmentAPI } from "../../../utils/requests";
import NotificationManager from "react-notifications/lib/NotificationManager";
import { useCookies } from "react-cookie";
import { useHistory, useLocation } from "react-router-dom";
import { IconContainer } from "../../App.styled";
import { BiEdit } from "react-icons/bi";
import { AiOutlineCheckCircle } from "react-icons/ai";
import { TiDeleteOutline } from "react-icons/ti";
import { confirmAlert } from "react-confirm-alert";
import ButtonState from "../../ButtonState/ButtonState";
const EditDepartmentForm = ({}) => {
  const dispatch = useDispatch();
  const [cookies, setCookie, removeCookie] = useCookies(["AT"]);
  const selectedNode = useSelector((state) => state.selectedNode);
  const nodeInfo = useSelector((state) => state.nodeInfo);
  const dimentions = useSelector((state) => state.dimentions);
  const apiStatus = useSelector((state) => state.apiStatus);
  const formState = useSelector((state) => state.formState);
  const utils = useSelector((state) => state.utils);
  const [departmentName, setDepartmentName] = useState(null);
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const user = useSelector((state) => state.user);
  const [response, setResponse] = useState(null);
  const [showSubDepartmentForm, setShowSubDepartmentForm] = useState(false);
  const [subDepartmentEditId, setSubDepartmentEditId] = useState(null);
  const history = useHistory();
  const location = useLocation();
  const closeForm = () => {
    toggleForm(formState, history, location, dispatch, formNames.departmentForm);
  };

  useEffect(() => {
    if (response && response.success) {
      closeForm();
    }
  }, [response]);

  useEffect(() => {
    if (formState && formState[formNames.departmentForm].depId && nodeInfo && nodeInfo.departments) {
      const temp = nodeInfo.departments.filter(
        (v) => v.depId === formState[formNames.departmentForm].depId
      );
      setSelectedDepartment(temp[0]);
      setDepartmentName(temp[0].name);
    }
  }, [formNames, nodeInfo]);

  return (
    <FormWrapper dimentions={dimentions} closeForm={closeForm}>
      <h6 className="font-weight-bold mb-4">Edit Department Form</h6>

      <InputGroup dimentions={dimentions}>
        <FieldLabel dimentions={dimentions}>Node</FieldLabel>
        <FieldInput>
          <InputBox disabled={true} value={nodeInfo.name} />
        </FieldInput>
      </InputGroup>
      <InputGroup dimentions={dimentions}>
        <FieldLabel dimentions={dimentions}>Department</FieldLabel>
        <FieldInput>
          <InputBox value={departmentName} onChange={(e) => setDepartmentName(e.target.value)} />
        </FieldInput>
      </InputGroup>
      <InputGroup dimentions={dimentions}>
        <FieldLabel dimentions={dimentions}></FieldLabel>
        <FieldInput>
          <FieldInput submitButton={true}>
            {selectedDepartment ? (
              <SolidButton
                submit={true}
                onClick={(e) =>
                  updateDepartmentAPI(
                    user,
                    {
                      departmentName,
                      nodeId: nodeInfo.nodeId,
                      departmentId: selectedDepartment.depId,
                    },
                    dispatch,
                    NotificationManager,
                    removeCookie,
                    history,
                    setResponse,
                    selectedNode,
                    closeForm
                  )
                }
              >
                <ButtonState
                  loading={apiStatus[apiEndPoints.updateDepartmentAPI]}
                  innerText={"Update"}
                />
              </SolidButton>
            ) : (
              <SolidButton
                submit={true}
                onClick={(e) =>
                  addDepartmentAPI(
                    user,
                    {
                      departmentName,
                      nodeId: nodeInfo.nodeId,
                      parentDepartmentId: null,
                    },
                    dispatch,
                    NotificationManager,
                    removeCookie,
                    history,
                    setResponse,
                    selectedNode,
                    closeForm
                  )
                }
              >
                <ButtonState loading={apiStatus[apiEndPoints.addDepartmentAPI]} innerText={"Add"} />
              </SolidButton>
            )}
          </FieldInput>
        </FieldInput>
      </InputGroup>
      {selectedDepartment ? (
        <InputGroup dimentions={dimentions}>
          <FieldLabel dimentions={dimentions}>Sub Departments</FieldLabel>
          <FieldInput>
            <div className="row">
              {selectedDepartment.subDepartments.length
                ? selectedDepartment.subDepartments.map((v) => (
                    <EditSubDepartmentForm
                      dimentions={dimentions}
                      setShowSubDepartmentForm={setShowSubDepartmentForm}
                      user={user}
                      dispatch={dispatch}
                      removeCookie={removeCookie}
                      history={history}
                      selectedNode={selectedNode}
                      selectedDepartment={selectedDepartment}
                      nodeInfo={nodeInfo}
                      utils={utils}
                      v={v}
                      closeForm={closeForm}
                    />
                  ))
                : null}
            </div>
          </FieldInput>
        </InputGroup>
      ) : null}

      {showSubDepartmentForm ? (
        <AddSubDepartmentForm
          dimentions={dimentions}
          setShowSubDepartmentForm={setShowSubDepartmentForm}
          user={user}
          dispatch={dispatch}
          removeCookie={removeCookie}
          history={history}
          selectedNode={selectedNode}
          selectedDepartment={selectedDepartment}
          nodeInfo={nodeInfo}
          closeForm={closeForm}
        />
      ) : null}
      <InputGroup dimentions={dimentions}>
        <FieldLabel dimentions={dimentions}></FieldLabel>
        <FieldInput submitButton={true}>
          {selectedDepartment ? (
            <SolidButton submit={true} onClick={(e) => setShowSubDepartmentForm(true)}>
              Add Sub Department
            </SolidButton>
          ) : null}
        </FieldInput>
      </InputGroup>
    </FormWrapper>
  );
};

export default EditDepartmentForm;

const AddSubDepartmentForm = ({
  dimentions,
  setShowSubDepartmentForm,
  history,
  selectedNode,
  removeCookie,
  dispatch,
  user,
  selectedDepartment,
  nodeInfo,
  closeForm,
}) => {
  const [subDepartmentName, setSubDepartmentName] = useState(null);
  const [response, setResponse] = useState(null);
  const apiStatus = useSelector((state) => state.apiStatus);

  useEffect(() => {
    if (response && response.success) {
      setShowSubDepartmentForm(false);
    }
  }, [response]);

  return (
    <>
      <InputGroup dimentions={dimentions}>
        <FieldLabel dimentions={dimentions}>Sub Department Name</FieldLabel>
        <FieldInput>
          <InputBox value={subDepartmentName} onChange={(e) => setSubDepartmentName(e.target.value)} />
        </FieldInput>
      </InputGroup>
      <InputGroup dimentions={dimentions}>
        <FieldLabel dimentions={dimentions}></FieldLabel>
        <FieldInput submitButton={true}>
          <SolidButton onClick={(e) => setShowSubDepartmentForm(false)}>Close</SolidButton>
          <SolidButton
            onClick={(e) =>
              addDepartmentAPI(
                user,
                {
                  departmentName: subDepartmentName,
                  nodeId: nodeInfo.nodeId,
                  parentDepartmentId: selectedDepartment.depId,
                },
                dispatch,
                NotificationManager,
                removeCookie,
                history,
                setResponse,
                selectedNode,
                closeForm
              )
            }
          >
            <ButtonState loading={apiStatus[apiEndPoints.addDepartmentAPI]} innerText={"Add"} />
          </SolidButton>
        </FieldInput>
      </InputGroup>
    </>
  );
};

const EditSubDepartmentForm = ({
  history,
  selectedNode,
  removeCookie,
  dispatch,
  user,
  utils,
  selectedDepartment,
  nodeInfo,
  v,
  closeForm,
}) => {
  const [editMode, setEditMode] = useState(false);
  const [departmentName, setDepartmentName] = useState(null);
  const [response, setResponse] = useState(null);
  const apiStatus = useSelector((state) => state.apiStatus);
  useEffect(() => {
    if (response && response.success) {
      setEditMode(false);
    }
  }, [response]);
  useEffect(() => {
    if (v && v.name) {
      setDepartmentName(v.name);
    }
  }, [v]);

  return (
    <div className="col-md-6 col-lg-6 col-sm-12 col-xs-12">
      <div className="d-flex justify-content-center align-items-center">
        <InputBox
          value={departmentName}
          disabled={!editMode}
          onChange={(e) => setDepartmentName(e.target.value)}
        />
        <IconContainer>
          {!editMode ? (
            <BiEdit className="m-0 p-0" onClick={(e) => setEditMode(true)} />
          ) : (
            <ButtonState
              loading={apiStatus[apiEndPoints.updateDepartmentAPI]}
              innerText={
                <AiOutlineCheckCircle
                  onClick={(e) =>
                    updateDepartmentAPI(
                      user,
                      {
                        departmentName,
                        nodeId: nodeInfo.nodeId,
                        departmentId: v.subDepId,
                      },
                      dispatch,
                      NotificationManager,
                      removeCookie,
                      history,
                      setResponse,
                      selectedNode
                      // closeForm
                    )
                  }
                />
              }
            />
          )}
        </IconContainer>
        <IconContainer>
          <ButtonState
            loading={apiStatus[apiEndPoints.removeDepartmentAPI]}
            innerText={
              <TiDeleteOutline
                className="text-danger m-0 p-0"
                onClick={(e) => {
                  removeDepartmentAPI(
                    user,
                    dispatch,
                    NotificationManager,
                    removeCookie,
                    history,
                    confirmAlert,
                    selectedNode,
                    utils,
                    v.subDepId,
                    closeForm
                  );
                }}
              />
            }
          />
        </IconContainer>
      </div>
    </div>
  );
};
