import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SolidButton } from "../../buttons/solidButton.styled";
import {
  FormBackground,
  FormContainer,
  InputBox,
  InputGroup,
  FieldLabel,
  FieldInput,
  FormBox,
} from "../FormBase.styled";
import { useCookies } from "react-cookie";
import { useHistory } from "react-router-dom";
import { FaArrowRight } from "react-icons/fa";
import NotificationManager from "react-notifications/lib/NotificationManager";

import { addDoorAPI } from "../../../utils/requests";
import { apiEndPoints, breakPointWidth } from "../../../config";
import ButtonState from "../../ButtonState/ButtonState";

const DoorAddForm = ({ setShowAddDoorForm, getNodes }) => {
  const [cookies, setCookie, removeCookie] = useCookies(["AT"]);
  let history = useHistory();
  const dispatch = useDispatch();
  const dimentions = useSelector((state) => state.dimentions);
  const apiStatus = useSelector((state) => state.apiStatus);
  const [doorName, setDoorName] = useState(null);
  const selectedNode = useSelector((state) => state.selectedNode);
  const user = useSelector((state) => state.user);

  const closeForm = () => {
    setShowAddDoorForm(false);
  };

  return (
    <FormWrapper dimentions={dimentions} closeForm={closeForm}>
      <h6 className="font-weight-bold mb-4">Add New Door</h6>
      <InputGroup dimentions={dimentions}>
        <FieldLabel dimentions={dimentions}>Door</FieldLabel>
        <FieldInput>
          <InputBox onChange={(e) => setDoorName(e.target.value)} />
        </FieldInput>
      </InputGroup>
      <InputGroup dimentions={dimentions}>
        <FieldLabel dimentions={dimentions}></FieldLabel>
        <FieldInput submitButton={true}>
          <SolidButton
            submit={true}
            onClick={(e) =>
              addDoorAPI(
                user,
                dispatch,
                NotificationManager,
                removeCookie,
                history,
                doorName,
                selectedNode,
                closeForm
              )
            }
          >
            <ButtonState loading={apiStatus[apiEndPoints.addDoorAPI]} innerText={"Add"} />
          </SolidButton>
        </FieldInput>
      </InputGroup>
    </FormWrapper>
  );
};

export default DoorAddForm;

export const FormWrapper = ({ children, closeForm, dimentions, width }) => {
  return (
    <FormBackground onClick={closeForm} dimentions={dimentions}>
      {dimentions.width > breakPointWidth ? (
        <FaArrowRight
          style={{
            color: "white",
            fontSize: "1rem",
            position: "absolute",
            top: "3rem",
            left: "2rem",
            cursor: "pointer",
          }}
        />
      ) : null}
      <FormContainer
        dimentions={dimentions}
        onClick={(e) => e.stopPropagation()}
        width={width}
      >
        <FormBox dimentions={dimentions} onClick={(e) => e.stopPropagation()}>
          {children}
        </FormBox>
      </FormContainer>
    </FormBackground>
  );
};
