import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import NotificationManager from "react-notifications/lib/NotificationManager";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { formNames, weekOffReference, yearMap } from "../../config";
import { getAllMemberships } from "../../utils/requests";
import { sendAttendanceReportDownloadEvent } from "../../utils/tagManagerEvents";
import { getUserRole, toggleForm } from "../../utils/utilFunctions";
import { generateAndDownloadSheet } from "./attendanceSheetGenerate";

const AttendanceSheetDownload = ({
  accessLogs,
  sd,
  ed,
  includeChild,
  selfLogs,
  department,
  subDepartment,
  role,
  userIds,
  allMemberships,
  setAllMemberships,
}) => {
  const [cookies, setCookie, removeCookie] = useCookies(["AT"]);
  const user = useSelector((state) => state.user);
  const nodeInfo = useSelector((state) => state.nodeInfo);
  const selectedNode = useSelector((state) => state.selectedNode);
  const formState = useSelector((state) => state.formState);
  const [membershipsHaveAccess, setmembershipsHaveAccess] = useState(null);
  const [weekOffs, setWeekOffs] = useState(null);
  let history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const handleClick = () => {
    let dayCounts = parseInt(
      (new Date(ed).setHours(23, 59, 59, 999) - new Date(sd).setHours(0, 0, 0, 0)) / (1000 * 3600 * 24)
    );
    sendAttendanceReportDownloadEvent(
      user.userId,
      nodeInfo.name,
      nodeInfo.nodeCategory,
      getUserRole(user.roles, nodeInfo),
      dayCounts,
      department ? true : false,
      role ? true : false,
      includeChild,
      nodeInfo.attendanceFormat
    );
    if (!nodeInfo.attendanceFormat || !nodeInfo.attendanceFormat.length) {
      toggleForm(formState, history, location, dispatch, formNames.attendanceFormatForm);
    } else {
      generateAndDownloadSheet(
        sd,
        ed,
        accessLogs,
        allMemberships,
        membershipsHaveAccess,
        department,
        subDepartment,
        weekOffs,
        nodeInfo.attendanceFormat,
        nodeInfo,
        role,
        userIds
      );
    }
  };

  useEffect(() => {
    if (nodeInfo && nodeInfo.workDays && nodeInfo.workDays.length) {
      let weekoffs = [];
      for (let i = 0; i < 7; i++) {
        if (!nodeInfo.workDays[i]) {
          weekoffs.push(weekOffReference[i]);
        }
      }
      setWeekOffs([...weekoffs]);
    }
    if (nodeInfo) {
      if (!selfLogs) {
        getAllMemberships(
          user,
          dispatch,
          NotificationManager,
          removeCookie,
          history,
          nodeInfo.nodeId,
          setAllMemberships,
          includeChild,
          true
        );
      } else {
        setAllMemberships([
          {
            ...user,
            userInfo: {
              firstName: user.firstName,
              lastName: user.lastName,
              mobile: { number: user.mobileNo },
              email: { id: user.emailId },
            },
          },
        ]);
      }
    }
  }, [nodeInfo, selfLogs, includeChild]);

  return (
    <button
      className="solid-button mr-3"
      disabled={!allMemberships || !accessLogs || !accessLogs.length}
      onClick={handleClick}
    >
      Download Report
    </button>
  );
};

export default AttendanceSheetDownload;
