import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SolidButton } from "../../buttons/solidButton.styled";
import { FormWrapper } from "../doorAddForm/DoorAddForm";
import { useCookies } from "react-cookie";
import {
  FieldInput,
  FieldLabel,
  InputGroup,
  SelectBox,
} from "../FormBase.styled";
import { useHistory } from "react-router-dom";
import NotificationManager from "react-notifications/lib/NotificationManager";
import { deviceAddAPI, getDeviceIds } from "../../../utils/requests";
import { apiEndPoints } from "../../../config";
import ButtonState from "../../ButtonState/ButtonState";

const DeviceAddForm = ({ setShowAddDeviceForm, deviceAddData, getNodes }) => {
  const closeForm = () => {
    setShowAddDeviceForm(false);
  };
  const [cookies, setCookie, removeCookie] = useCookies(["AT"]);
  let history = useHistory();
  const dispatch = useDispatch();
  const dimentions = useSelector((state) => state.dimentions);
  const apiStatus = useSelector((state) => state.apiStatus);
  const [devices, setDevices] = useState(null);
  const [deviceId, setDeviceId] = useState("");
  const user = useSelector((state) => state.user);

  useEffect(() => {
    getDeviceIds(
      user,
      dispatch,
      NotificationManager,
      removeCookie,
      history,
      setDevices
    );
  }, []);

  return (
    <FormWrapper dimentions={dimentions} closeForm={closeForm}>
      <h6 className="font-weight-bold mb-4">Add Device</h6>
      <InputGroup dimentions={dimentions}>
        <FieldLabel dimentions={dimentions}>Device ID</FieldLabel>
        <FieldInput>
          <SelectBox onChange={(e) => setDeviceId(e.target.value)}>
            <option value={null}>select device</option>
            {devices
              ? devices.map((v) => (
                  <option key={v.deviceId} value={v.deviceId}>
                    {v.customDeviceId}
                  </option>
                ))
              : null}
          </SelectBox>
        </FieldInput>
      </InputGroup>
      <InputGroup dimentions={dimentions}>
        <FieldLabel dimentions={dimentions}></FieldLabel>
        <FieldInput submitButton={true}>
          <SolidButton
            submit={true}
            onClick={(e) =>
              deviceAddAPI(
                user,
                dispatch,
                NotificationManager,
                removeCookie,
                history,
                deviceAddData,
                deviceId,
                closeForm
              )
            }
          >
            <ButtonState loading={apiStatus[apiEndPoints.deviceAddAPI]} innerText={"Add"} />
          </SolidButton>
        </FieldInput>
      </InputGroup>
    </FormWrapper>
  );
};

export default DeviceAddForm;
