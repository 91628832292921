import styled, { css } from "styled-components";
import { breakPointWidth } from "../../../config";

export const PeopleRowContainer = styled.div`
  border: 0.5px solid #e8e8e8;
  box-shadow: 0px 0.2rem 0.2rem 0.2rem rgba(0, 0, 0, 0.06);
  border-radius: 5px;
  margin-bottom: 20px;
  height: 75vh;
  position: relative;
`;

export const PeopleRowStyled = styled.div`
  display: grid;
  grid-row-gap: 0.5rem;
  grid-column-gap: 0.2rem;
  padding: 0.5rem 1rem;
  ${(props) =>
    props.isConflicted
      ? css`
          grid-template-columns: 3fr 3fr 3fr 3fr;
          grid-template-areas: "MoibleNo Email Name UDId";
        `
      : css`
          grid-template-columns: 3fr 3fr 3fr 3fr 3fr 0.8fr;
          grid-template-areas: "MoibleNo Email Name UDId Edit";
        `}

  ${(props) =>
    props.isHead
      ? css`
          font-weight: bold;
          padding: 1rem 1rem;
        `
      : css`
          :hover {
            color: white;
            background-image: linear-gradient(91deg, #fe6dad -3%, #fc9f35);
          }
        `}
  ${(props) =>
    props.entryType === "new"
      ? css`
          background-color: #d2f5c6;
        `
      : css``}
`;

export const Id = styled.div`
  grid-area: "id";
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  ${(props) =>
    props.edited === true
      ? css`
          background-color: #ffccb3;
        `
      : css``}
`;

export const Name = styled.div`
  grid-area: "name";
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  ${(props) =>
    props.edited === true
      ? css`
          background-color: #ffccb3;
        `
      : css``}
`;

export const Phone = styled.div`
  grid-area: "phone";
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  ${(props) =>
    props.isNonUniqueRepeated === true
      ? css`
          background-color: #ff7e75;
        `
      : props.edited === true
      ? css`
          background-color: #ffccb3;
        `
      : css``}
`;

export const Email = styled.div`
  grid-area: "email";
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  ${(props) =>
    props.isNonUniqueRepeated === true
      ? css`
          background-color: #ff7e75;
        `
      : props.edited === true
      ? css`
          background-color: #ffccb3;
        `
      : css``}
`;

export const EditAccess = styled.div`
  grid-area: "editAccess";
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  ${(props) =>
    props.isHead
      ? css`
          color: black;
        `
      : css`
          font-size: 1.2rem;
          :hover {
            color: white;
          }
        `}
`;

export const FieldLabel = styled.div`
  font-weight: bold;
  font-size: 0.9rem;
  grid-area: "label";
  padding-top: 0.5rem;
  text-align: ${(props) => (props?.dimentions?.width > breakPointWidth ? "right;" : "left")};
`;

export const FieldInput = styled.div`
  font-size: 0.9rem;
  grid-area: "field";
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 30px;
`;

export const SelectBox = styled.select`
  border: 1px solid #898989;
  background-color: transparent;
  height: 2rem;
  padding: 0px 0.5rem;
  border-radius: 6px;
  width: 100%;
  outline: 0;
  :focus : {
    outline: 0;
  }
`;

export const OverFLowScrollBox = styled.div`
  height: 99%;
  overflow-y: scroll;
  :focus {
    outline: none;
  }
`;
