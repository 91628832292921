import React from "react";
import { MdContactPhone } from "react-icons/md";
const ContactPicker = ({ handleChange, fieldName }) => {
  const props = ["name", "tel"];
  const supported = "contacts" in window.navigator;
  const opts = { multiple: false };
  async function getContacts() {
    // alert("clicked");
    try {
      const result = await window.navigator.contacts.select(props, opts);
      let number = result[0].tel[0].split(",");
      let name = result[0].name[0];
      console.log(result);
      let tel = number[0]
        .replaceAll(" ", "")
        .replaceAll("-", "")
        .slice(number[0].replaceAll(" ", "").replaceAll("-", "").length - 10);

      if (fieldName === "userToMeetContact") {
        handleChange({
          target: {
            name: "userToMeetContact",
            value: tel,
          },
        });
      } else {
        handleChange({
          target: {
            name: "name&Number",
            value: [name, tel],
          },
        });
      }
    } catch (ex) {
      console.log(ex);
      // Handle any errors here.
    }
  }

  return supported ? (
    <MdContactPhone
      onClick={(e) => getContacts()}
      style={{ cursor: "pointer", fontSize: "2rem", paddingLeft: "5px" }}
    />
  ) : null;
};

export default ContactPicker;
