export const UPDATE_NODES = "UPDATE_NODES";
export const UPDATE_NODE_INFO = "UPDATE_NODE_INFO";
export const UPDATE_SELECTED_NODE = "UPDATE_SELECTED_NODE";
export const UPDATE_NODE_INFO_ALERT = "UPDATE_NODE_INFO_ALERT";
export const UPDATE_NODE_INFO_RESTRICTIONS = "UPDATE_NODE_INFO_RESTRICTIONS";
export const UPDATE_VISITOR_LOGS = "UPDATE_VISITOR_LOGS";
export const UPDATE_USER = "UPDATE_USER";
export const DELETE_USER = "DELETE_USER";
export const UPDATE_NODE_INFO_PEOPLE_LIMIT = "UPDATE_NODE_INFO_PEOPLE_LIMIT";
export const UPDATE_MEMBERSHIPS = "UPDATE_MEMBERSHIPS";
export const UPDATE_ADD_ROOTNODE = "UPDATE_ADD_ROOTNODE";
export const UPDATE_SELECTED_NODE_HIERARCHY = "UPDATE_SELECTED_NODE_HIERARCHY";
export const UPDATE_DIMENTIONS = "UPDATE_DIMENTIONS";
export const UPDATE_SIDEBAR_STATUS = "UPDATE_SIDEBAR_STATUS";
export const TOGGLE_FORM = "TOGGLE_FORM";
export const DISABLE_FORM = "DISABLE_FORM";
export const UPDATE_GROUPS = "UPDATE_GROUPS";
export const UPDATE_FORM = "UPDATE_FORM";
export const UPDATE_UTILS = "UPDATE_UTILS";
export const RESET_FORM_STATE = "RESET_FORM_STATE";
export const UPDATE_INTEGRATION_USER = "UPDATE_INTEGRATION_USER";
export const UPDATE_NOTIFICATIONS = "UPDATE_NOTIFICATIONS";
export const RESET_NOTIFICATIONS = "RESET_NOTIFICATIONS";
export const SET_DEFAULT_NOTIFICATIONS = "SET_DEFAULT_NOTIFICATIONS";
export const UPDATE_DEVICE_FIRMWARE = "UPDATE_DEVICE_FIRMWARE";
export const UPDATE_NODE_INFO_DEPARTMENTS = "UPDATE_NODE_INFO_DEPARTMENTS";
export const EDIT_NODE_INFO_DEPARTMENTS = "EDIT_NODE_INFO_DEPARTMENTS";
export const UPDATE_API_STATUS = "UPDATE_API_STATUS";