import React, { useEffect, useState } from "react";
import "./App.css";
import LeftSidebar from "./components/LeftSidebar";
import Integrations from "./pages/Integrations";
import Developer from "./pages/Developer";
import Sidenav from "./components/sidenav/Sidenav";
import { Redirect, Route, useHistory, useLocation } from "react-router-dom";
import { ImMenu3 } from "react-icons/im";
import { VscBellDot } from "react-icons/vsc";
import Zone from "./pages/Zone";
import Visitor from "./pages/Visitor";
import { AppContainer } from "./components/App.styled";
import People from "./pages/People";
import Devices from "./pages/Devices";
import CustomSelect from "./components/selectBox/customSelect/CustomSelect";
import { useDispatch, useSelector } from "react-redux";
import { useCookies } from "react-cookie";
import { isAdmin, toggleForm, toggleSidebar } from "./utils/utilFunctions";
import Logout from "./components/Logout";
import { MainDiv } from "./MainDiv.styled";
import { breakPointWidth, formNames, OPS_LIST } from "./config";
import FormController from "./components/forms/FormController";
import Configure from "./pages/Configure";
import Attendance from "./pages/Attendance";
import PlaceholderMessage from "./components/PlaceholderMessage";
import Navigation from "./vms/Components/Navigation";
import Header from "./vms/Components/Header";
import Placeholder from "./vms/Components/Placeholder";
import {
  fetchNotifications,
  // getAccessGroupsAPI,
  getUserInfo,
  getVisitorsLogsAPI,
} from "./utils/requests";
import NotificationManager from "react-notifications/lib/NotificationManager";
import OauthDialouge from "./pages/OauthDialouge";
import NavigationHeader from "./components/NavigationHeader/NavigationHeader";
import CheckPlatform from "./components/CheckPlatform";
import {
  setDefaultNotifications,
  updateNotifications,
} from "./redux/notifications/notificationsActions";
import { date } from "date-fns/locale/af";
import AssignRFID from "./pages/AssignRFID";
function Main({ params }) {
  const [cookies, setCookie, removeCookie] = useCookies(["AT"]);
  let history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const selectedNode = useSelector((state) => state.selectedNode);
  let location = useLocation();
  const dimentions = useSelector((state) => state.dimentions);
  const nodeInfo = useSelector((state) => state.nodeInfo);
  const formState = useSelector((state) => state.formState);
  const notifications = useSelector((state) => state.notifications);
  const [notificationPageNo, setNotificationPageNo] = useState(1);
  const [unreadNotifs, setUnreadNotifs] = useState(false);
  useEffect(() => {
    if (user.AT && !user.mobileNo && !user.emailId) {
      getUserInfo(user, dispatch, NotificationManager, removeCookie, history);
    }
  }, [user]);

  useEffect(() => {
    if (window.location.pathname === "/") {
      window.location.pathname = "/apps";
    }
  }, [window.location.pathname]);

  useEffect(() => {
    if (notifications.entries.filter((v) => v.readTimestamp === 0).length) {
      setUnreadNotifs(true);
    } else {
      setUnreadNotifs(false);
    }
  }, [notifications.entries]);

  const getNotifications = async (
    user,
    dispatch,
    NotificationManager,
    removeCookie,
    history,
    nodeId,
    pageSize,
    notificationPageNo
  ) => {
    const notif = await fetchNotifications(
      user,
      dispatch,
      NotificationManager,
      removeCookie,
      history,
      nodeId,
      pageSize,
      notificationPageNo
    );
    if (notif)
      dispatch(updateNotifications({ notifications: notif.notifications, pageNo: notificationPageNo }));
  };

  useEffect(() => {
    if (
      nodeInfo &&
      isAdmin(user, nodeInfo) &&
      nodeInfo.features &&
      nodeInfo.features.allowNotifications &&
      nodeInfo.features.allowNotifications.membershipExpiry &&
      notificationPageNo &&
      (nodeInfo.nodeId || nodeInfo.rootNodeId)
    ) {
      getNotifications(
        user,
        dispatch,
        NotificationManager,
        removeCookie,
        history,
        nodeInfo.rootNodeId || nodeInfo.nodeId,
        10,
        notificationPageNo
      );
    } else {
      dispatch(setDefaultNotifications());
    }
  }, [notificationPageNo, nodeInfo]);

  return (
    <>
      {!nodeInfo?.nodeId ? null : OPS_LIST.includes(user.mobileNo) ? null : <CheckPlatform />}
      <AppContainer pathname={location.pathname} dimentions={dimentions}>
        {dimentions.width < breakPointWidth ? <NavigationHeader /> : null}
        {location.pathname.startsWith("/vms") ||
        location.pathname === "/assignRFID" ||
        location.pathname === "/apps" ? null : (
          <>
            <LeftSidebar dimentions={dimentions}>
              <Sidenav
                toggleSidebar={toggleSidebar}
                dimentions={dimentions}
                params={params}
                removeCookie={removeCookie}
                history={history}
                dispatch={dispatch}
              />
            </LeftSidebar>
            {/* {(location.pathname !== "/visitor" &&
              location.pathname !== "/people") ||
            dimentions.width < breakPointWidth ? (
              <RightSidebar
                toggleSidebar={toggleSidebar}
                dimentions={dimentions}
              >
                <div className="text-left">
                  {dimentions.width > breakPointWidth ? null : (
                    <GrFormClose
                      style={{ fontSize: "2rem" }}
                      onClick={(e) => toggleSidebar(dispatch, "right")}
                    />
                  )}
                </div>
                <div className="h-100 text-center d-flex justify-content-center align-items-center">
                  <FieldLabelMobile>Nothing to display</FieldLabelMobile>
                </div>
              </RightSidebar>
            ) : null} */}
          </>
        )}
        <MainDiv dimentions={dimentions}>
          {!user.AT && !location.pathname.startsWith("/vms") ? <Redirect to="/logout" /> : null}
          {location.pathname === "/apps" ||
          (location.pathname === "/assignRFID" && dimentions.width > breakPointWidth) ? (
            <Header />
          ) : null}
          <FormController
            notificationPageNo={notificationPageNo}
            setNotificationPageNo={setNotificationPageNo}
            // getAccessGroupsAPI={getAccessGroupsAPI}
            getVisitorsLogsAPI={getVisitorsLogsAPI}
          />
          {dimentions.width > breakPointWidth ||
          location.pathname.startsWith("/vms") ||
          location.pathname === "/apps" ||
          location.pathname === "/assignRFID" ||
          location.pathname === "/" ? null : (
            <div className="container-fluid d-flex justify-content-between align-items-center py-4 px-2">
              <ImMenu3
                onClick={(e) => toggleSidebar(dispatch, "left")}
                style={{ fontSize: "1.5rem", color: "#FC9F35" }}
              />
              <VscBellDot
                onClick={(e) =>
                  toggleForm(formState, history, location, dispatch, formNames.notificationBox)
                }
                style={{
                  fontSize: "1.5rem",
                  color: `${unreadNotifs || Date.now() < 1633631399000 ? "#F96A6A" : "#000"}`,
                  cursor: "pointer",
                }}
              />
            </div>
          )}

          {dimentions.width < breakPointWidth ||
          location.pathname.startsWith("/vms") ||
          location.pathname === "/" ||
          location.pathname === "/assignRFID" ||
          location.pathname === "/apps" ? null : (
            <div className="d-flex align-tems-center w-100 justify-content-end mb-2 py-1">
              <VscBellDot
                onClick={(e) =>
                  toggleForm(formState, history, location, dispatch, formNames.notificationBox)
                }
                style={{
                  fontSize: "1.5rem",
                  color: `${unreadNotifs || Date.now() < 1633631399000 ? "#F96A6A" : "#000"}`,
                  cursor: "pointer",
                }}
              />
            </div>
          )}

          <div
            className="align-tems-center w-100 justify-content-end mb-5 py-1"
            style={
              location.pathname.startsWith("/developer") ||
              location.pathname.startsWith("/vms") ||
              location.pathname === "/assignRFID" ||
              location.pathname === "/"
                ? { display: "none" }
                : { display: "flex" }
            }
          >
            <CustomSelect
              params={params}
              removeCookie={removeCookie}
              history={history}
              dispatch={dispatch}
            />
          </div>
          <Route exact path="/apps">
            {user && user.roles && Object.keys(user.roles).length === 0 ? (
              <h6 className="text-center text-danger font-weight-bold pt-5">You have no memberships</h6>
            ) : nodeInfo.success === false ? (
              <h6 className="text-center text-danger font-weight-bold pt-5">Access Denied</h6>
            ) : !nodeInfo?.nodeId ? (
              <div className="text-center pt-5">
                <div className="spinner-border text-secondary mt-5 " role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            ) : (
              <Navigation />
            )}
          </Route>

          <>
            <Route exact path="/access">
              {user && !isAdmin(user, nodeInfo) ? <Redirect to="/apps" /> : <Zone />}
            </Route>
            <Route exact path="/people">
              {user && !isAdmin(user, nodeInfo) ? (
                <Redirect to="/apps" />
              ) : (
                <People
                  // getAccessGroupsAPI={getAccessGroupsAPI}
                  removeCookie={removeCookie}
                  history={history}
                  dispatch={dispatch}
                />
              )}
            </Route>

            <Route exact path="/assignRFID">
              <AssignRFID />
            </Route>
            <Route exact path="/devices">
              {user && user.mobileNo && OPS_LIST.includes(user.mobileNo) ? (
                user && !isAdmin(user, nodeInfo) ? (
                  <Redirect to="/apps" />
                ) : (
                  <Devices />
                )
              ) : user && !isAdmin(user, nodeInfo) ? (
                <Redirect to="/apps" />
              ) : (
                <>
                  <h4 className="text-center my-5 py-5 font-weight-bold text-danger">
                    Unauthorized Access
                  </h4>
                </>
              )}
            </Route>
            <Route exact path="/visitor">
              {user &&
              user.mobileNo &&
              selectedNode &&
              nodeInfo &&
              !user.visitorPermissions[selectedNode.nodeId] &&
              !isAdmin(user, nodeInfo) ? (
                <Redirect to="/apps" />
              ) : (
                <Visitor
                  getVisitorsLogsAPI={getVisitorsLogsAPI}
                  removeCookie={removeCookie}
                  history={history}
                  dispatch={dispatch}
                />
              )}
            </Route>
            <Route exact path="/attendance">
              {/* {user && !user.isAdmin ? (
                <Redirect to="/apps" />
              ) : ( */}
              <>
                {!nodeInfo.parentId ? <Attendance /> : <PlaceholderMessage message="Not a root Node" />}
              </>
              {/* )} */}
            </Route>
          </>
          <Route exact path="/configure">
            {user && user.mobileNo && OPS_LIST.includes(user.mobileNo) ? (
              <Configure />
            ) : (
              <h4 className="text-center my-5 py-5 font-weight-bold text-danger">Unauthorized Access</h4>
            )}
          </Route>

          {/* <Route exact path="/stats">
            <Stats />
          </Route>
          <Route exact path="/payments">
            <Payments />
          </Route>
          <Route exact path="/profile">
            <Profile />
          </Route>

          {/* NEW ROUTES */}

          {/* <Route exact path="/zones">
          <Zone />
        </Route> */}

          <Route exact path="/logout">
            <Logout removeCookie={removeCookie} history={history} dispatch={dispatch} />
          </Route>

          <Route exact path="/booking">
            <Placeholder />
          </Route>
          <Route exact path="/livefeed">
            <Placeholder />
          </Route>
          <Route exact path="/store">
            <Placeholder />
          </Route>
          <Route exact path="/integrations">
            <Integrations />
          </Route>
          <Route exact path="/developer">
            <Developer />
          </Route>
          <Route exact path="/accounts"></Route>
          <Route exact path="/reports"></Route>
          <Route path="/dialog">
            {user && nodeInfo.nodeId ? (
              !isAdmin(user, nodeInfo) ? (
                <Redirect to="/apps" />
              ) : (
                <OauthDialouge />
              )
            ) : null}
          </Route>

          {/* <Route exact path="/booking"></Route> */}
          {/* /////////////////////////////////////////////////////////////////// */}
        </MainDiv>
      </AppContainer>
    </>
  );
}

export default Main;
