import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { apiEndPoints, formNames } from "../../../config";
import { SolidButton } from "../../buttons/solidButton.styled";
import { FormWrapper } from "../doorAddForm/DoorAddForm";
import { useCookies } from "react-cookie";
import { toggleForm } from "../../../utils/utilFunctions";
import { FieldInput, FieldLabel, InputBox, TextArea } from "../FormBase.styled";
import { InputGroup } from "./addOauthAppForm.styled";
import { useHistory, useLocation } from "react-router-dom";
import NotificationManager from "react-notifications/lib/NotificationManager";
import { registerOauthApp, updateOauthApp } from "../../../utils/requests";
import ButtonState from "../../ButtonState/ButtonState";

const AddOauthAppForm = () => {
  const [cookies, setCookie, removeCookie] = useCookies(["AT"]);
  let history = useHistory();
  const selectedNode = useSelector((state) => state.selectedNode);
  const dispatch = useDispatch();
  const dimentions = useSelector((state) => state.dimentions);
  const apiStatus = useSelector((state) => state.apiStatus);
  const formState = useSelector((state) => state.formState);
  const location = useLocation();
  const initialData = useSelector((state) => state.formState[formNames.freshTeamsIntegrationFrom]);
  const user = useSelector((state) => state.user);
  const [showSecrets, setShowSecrets] = useState(false);
  const [secrets, setSecrets] = useState({
    updateForm: false,
    clientId: "",
    clientSecret: "",
  });

  useEffect(() => {
    if (formState[formNames.addOauthAppForm].clientId) {
      let entry = formState[formNames.addOauthAppForm];
      setSecrets({
        updateForm: true,
        clientId: entry.clientId,
        clientSecret: entry.clientSecret,
      });
      setFormData({
        name: entry.name,
        redirectUri: entry.redirectUri,
        appDescription: entry.description ? entry.description : "",
      });
      setShowSecrets(true);
    }
  }, []);
  const closeForm = () => {
    toggleForm(formState, history, location, dispatch, formNames.addOauthAppForm);
  };
  const [formData, setFormData] = useState({
    name: "",
    redirectUri: "",
    appDescription: "",
  });

  useEffect(() => {
    if (initialData) {
      setFormData({ ...initialData });
    }
  }, [initialData]);

  return (
    <FormWrapper dimentions={dimentions} closeForm={closeForm}>
      <h6 className="font-weight-bold mb-4">Oauth app details</h6>
      <InputGroup dimentions={dimentions}>
        <FieldLabel dimentions={dimentions}>Oauth App Name</FieldLabel>
        <FieldInput>
          <InputBox
            type="text"
            placeholder="App Name"
            value={formData.name}
            onChange={(e) => setFormData({ ...formData, name: e.target.value })}
          />
        </FieldInput>
      </InputGroup>
      <InputGroup dimentions={dimentions}>
        <FieldLabel dimentions={dimentions}>Redirect URI</FieldLabel>
        <FieldInput>
          <InputBox
            type="text"
            placeholder="https://yourdomain.com/securepass/oauth/redirect"
            value={formData.redirectUri}
            onChange={(e) => setFormData({ ...formData, redirectUri: e.target.value })}
          />
        </FieldInput>
      </InputGroup>
      <InputGroup dimentions={dimentions}>
        <FieldLabel dimentions={dimentions}>Description</FieldLabel>
        <FieldInput>
          <TextArea
            type="text"
            placeholder="App description"
            value={formData.appDescription}
            onChange={(e) => setFormData({ ...formData, appDescription: e.target.value })}
          />
          {formData.appDescription.length > 100 ? (
            <span className="text-left text-danger">Maximum 100 characters allowed</span>
          ) : null}
          <span className="text-right">{formData.appDescription.length} / 100</span>
        </FieldInput>
      </InputGroup>
      {showSecrets ? (
        <>
          <InputGroup dimentions={dimentions}>
            <FieldLabel dimentions={dimentions}>Client ID</FieldLabel>
            <FieldInput>
              <InputBox type="text" value={secrets.clientId} disabled={true} />
            </FieldInput>
          </InputGroup>
          <InputGroup dimentions={dimentions}>
            <FieldLabel dimentions={dimentions}>Client Secret</FieldLabel>
            <FieldInput>
              <InputBox type="text" value={secrets.clientSecret} disabled={true} />
            </FieldInput>
          </InputGroup>
        </>
      ) : null}
      <InputGroup dimentions={dimentions}>
        <FieldLabel dimentions={dimentions}></FieldLabel>
        <FieldInput>
          <p className="text-info font-weight-bold">
            Please save the Client ID and Client Secret for future use
          </p>
        </FieldInput>
      </InputGroup>

      <InputGroup dimentions={dimentions}>
        <FieldLabel dimentions={dimentions}></FieldLabel>
        <FieldInput submitButton={true}>
          <SolidButton
            disabled={formData.appDescription.length > 100}
            submit={true}
            onClick={async (e) => {
              if (!showSecrets) {
                //submit button functionality
                let result = await registerOauthApp(
                  user,
                  dispatch,
                  NotificationManager,
                  removeCookie,
                  history,
                  selectedNode.nodeId,
                  formData
                );
                if (result.success) {
                  //registred successfully, show app secrets
                  setSecrets({
                    updateForm: false,
                    clientId: result.clientId,
                    clientSecret: result.clientSecret,
                  });
                  setShowSecrets(!showSecrets);
                } else {
                  //error registering
                }
              } else {
                if (secrets.updateForm) {
                  //call edit api
                  let result = await updateOauthApp(
                    user,
                    dispatch,
                    NotificationManager,
                    removeCookie,
                    history,
                    formData,
                    secrets.clientId,
                    selectedNode.nodeId,
                    closeForm
                  );
                  if (result.success) {
                  }
                } else {
                  //close button functionality
                  closeForm();
                }
              }
            }}
          >
            {showSecrets ? (
              secrets.updateForm ? (
                <ButtonState loading={apiStatus[apiEndPoints.updateOauthApp]} innerText={"Update"} />
              ) : (
                <ButtonState loading={apiStatus[apiEndPoints.updateOauthApp]} innerText={"Close"} />
              )
            ) : (
              <ButtonState loading={apiStatus[apiEndPoints.registerOauthApp]} innerText={"Submit"} />
            )}
          </SolidButton>
        </FieldInput>
      </InputGroup>
    </FormWrapper>
  );
};

export default AddOauthAppForm;
