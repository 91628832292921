import styled, { css } from "styled-components";

export const VisitorRowContainer = styled.div`
  border: 0.5px solid #e8e8e8;
  box-shadow: 0px 0.2rem 0.2rem 0.2rem rgba(0, 0, 0, 0.06);
  border-radius: 5px;
  height : 40vh;
  margin-bottom: 20px;
`;

export const VisitorRowStyled = styled.div`
  display: grid;
  grid-row-gap: 0.5rem;
  grid-column-gap: 0.2rem;
  padding: 0.5rem 1rem;
  /* grid-template-columns: 1.5fr 1.5fr 2fr 1fr 2fr 1fr 1fr 1fr 1.5fr 0.5fr 1fr; */
  /* grid-template-areas: "name phone email company contactedPerson inTime outTime duration temperature alert button"; */
  grid-template-columns: 1.5fr 1.5fr 2fr 1fr 2fr 1fr 1fr 1fr 0.5fr 1fr;
  grid-template-areas: "name phone email company contactedPerson inTime outTime duration alert button";

  ${(props) =>
    props.active
      ? css`
          background-image: linear-gradient(91deg, #fe6dad -3%, #fc9f35);
        `
      : css``}
  ${(props) =>
    props.isHead
      ? css`
          font-weight: bold;
        `
      : css`
          :hover {
            color: white;
            background-image: linear-gradient(91deg, #fe6dad -3%, #fc9f35);
          }
        `}
`;

export const Name = styled.div`
  grid-area: "name";
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const Phone = styled.div`
  grid-area: "phone";
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const Email = styled.div`
  grid-area: "email";
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const Company = styled.div`
  grid-area: "company";
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const ContactedPerson = styled.div`
  grid-area: "contactedPerson";
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const InTime = styled.div`
  grid-area: "inTime";
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const OutTime = styled.div`
  grid-area: "outTime";
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Duration = styled.div`
  grid-area: "duration";
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Temperature = styled.div`
  grid-area: "temperature";
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Alert = styled.div`
  grid-area: "alert";
  display: flex;
  justify-content: center;
  align-items: center;
  color : red;
  :hover : {
    color: white;
  }
  ${(props) =>
    props.isHead
      ? css`
          visibility: hidden;
        `
      : css``}
`;

export const Button = styled.button`
  background-color: transparent;
  ${(props) =>
    props.isHead
      ? css`
          visibility: hidden;
        `
      : css``}
  border: 1px solid white;
  outline: none;
  color: white;
  justify-self: center;
  align-self: center;
  cursor: pointer;
  font-size: 0.8rem;
  padding: 0.2rem 1rem;
  border-radius: 20px;
  :hover {
    opacity: 0.5;
    color: black;
    border: 1px solid black;
  }
`;
