import React, { useEffect, useState } from "react";
import DeclarationForm from "../Components/DeclarationForm";
import DeclarationSuccess from "../Components/DeclarationSuccess";
import { get, post } from "../Services/apiService";
import {
  createNotification,
  getQueryParams,
  notificationType,
} from "../Services/utils";
import "../styles/dstyles.css";
import { NotificationManager } from "react-notifications";
import { ATexpired } from "../../utils/logout";
import { useDispatch } from "react-redux";
import { useCookies } from "react-cookie";
import { useHistory } from "react-router-dom";
import { rootApi } from "../../config";
const Declaration = () => {
    const [cookies, setCookie, removeCookie] = useCookies(["AT"]);
  const [params, setParams] = useState(null);
  const [sdpInfo, setSdpInfo] = useState(null);
  const [checked, setChecked] = useState([false, false, false, false]);
  const [allChecked, setAllChecked] = useState(false);
  const [declarationRes, setDeclarationRes] = useState(null);
  const dispatch = useDispatch();
  const history = useHistory()
  const getSdpInfo = async (sdp) => {
    const url = `${rootApi}membership/sdpInfo?sdp=${sdp}`;
    try {
      const response = await get(dispatch, url);

      if (!response.data.success) {
        createNotification(
          NotificationManager,
          notificationType.error,
          response.data.error
        );
      } else {
        if (response.data.selfDeclarationTimestamp === 0) {
          setSdpInfo(response.data);
        } else if (response.data.selfDeclarationTimestamp > 0) {
          setDeclarationRes(response.data);
        }
      }
      ATexpired(response, dispatch, removeCookie, history);
    } catch (err) {
      console.log(err);
    }
  };

  const submitDeclaration = async () => {
    const url = `${rootApi}membership/selfDeclaration`;
    try {
      const response = await post(dispatch, url, { selfDeclarationPayload: params.sdp });
      if (!response.data.success) {
        createNotification(
          NotificationManager,
          notificationType.error,
          response.data.error
        );
      } else {
        setSdpInfo(null);
        setDeclarationRes(response.data);
      }
      ATexpired(response, dispatch, removeCookie, history);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    setParams({ ...getQueryParams(window.location.search) });
  }, [window.location.search]);
  useEffect(() => {
    if (params) {
      getSdpInfo(params.sdp);
    }
  }, [params]);

  useEffect(() => {
    setAllChecked(checked.filter((v) => !v).length ? false : true);
  }, [checked]);

  return (
    <>
      {sdpInfo && sdpInfo.selfDeclarationTimestamp === 0 ? (
        <DeclarationForm checked={checked} setChecked={setChecked} />
      ) : null}
      {declarationRes ? (
        <DeclarationSuccess declarationRes={declarationRes} />
      ) : null}

      {allChecked && !declarationRes ? (
        <div className="fixed-bottom py-4 bg-white">
          <div className="col-12">
            <button
              id="submitbutton"
              className="bigButton"
              onClick={(e) => submitDeclaration()}
            >
              Continue
            </button>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default Declaration;
