import { UPDATE_GROUPS } from "../actionTypes";

const groupsReducer = (groups = {}, action) => {
  if (action.type === UPDATE_GROUPS) {
    return (groups = action.payload);
  }

  return groups;
};

export default groupsReducer;
