import React, { useEffect, useState } from "react";
import { BiChevronDownCircle } from "react-icons/bi";
import { inputFieldsMap } from "../Services/utils";
import ContactPicker from "./ContactPicker";
const Form = ({
  handleChange,
  checkError,
  error,
  setSelectedNode,
  selectedNode,
  mountedOnQR,
  setFilled,
  formatDate,
  setFormData,
  formData,
  handleSubmit,
  nodes,
  setError,
  startTime,
  setStartTime,
  endTime,
  setEndTime,
}) => {
  const [fields, setFields] = useState(null);
  const [collapsed, setCollapsed] = useState(false);
  useEffect(() => {
    let currentTime, startTime, endTime;
    currentTime = new Date();
    let offset = currentTime.getTimezoneOffset();
    startTime = new Date(currentTime.getTime() - offset * 60000).toISOString().slice(0, 16);
    endTime = new Date(currentTime.getTime() - offset * 60000 + 86400000).toISOString().slice(0, 16);

    setStartTime(startTime);
    setEndTime(endTime);
  }, []);

  useEffect(() => {
    if (selectedNode) {
      setFields([...inputFieldsMap(selectedNode)]);
    } else {
      setFields(null);
    }
  }, [selectedNode]);

  return (
    <div id="requestForm" className="w-100 mb-5 pb-5 text-left">
      {/* {mountedOnQR ? null : (
        <div className="col-12 my-3">
          <select
            className="vms-select"
            id="AccessArea"
            onChange={(e) => {
              if (e.target.value === "Select a node") {
                setSelectedNode(null);
                setError({ ...error, accessError: true });
              } else {
                setError({ ...error, accessError: false });
                setSelectedNode({ ...JSON.parse(e.target.value) });
              }
            }}
          >
            <option value={null}>{"Select a node"}</option>
            {nodes.map((n) => (
              <option selected={formData?.nodeId === n.nodeId} value={JSON.stringify(n)}>
                {n.parentIds.map((i) => "- ")}
                {n.name}
              </option>
            ))}
          </select>

          {error.accessError ? (
            <div className="col-12">
              <small className="vms-small" id="accessError">
                Please select a Node.
              </small>
            </div>
          ) : null}
        </div>
      )} */}

      {fields && fields.includes("contact") ? (
        <>
          <div className="col-12 my-3" style={{ display: "flex", alignItems: "center" }}>
            <input
              type="tel"
              id="phoneNumber"
              required
              className="vms-input"
              name="contact"
              onChange={handleChange}
              value={formData.contact}
              placeholder={"Visitor’s Phone Number or Email"}
            />
            <ContactPicker handleChange={handleChange} fieldName={"contact"} />
          </div>
          {error.numberError ? (
            <div className="col-12">
              <small className="vms-small" id="numberError">
                &nbsp;
              </small>
            </div>
          ) : null}
        </>
      ) : null}

      {/* {fields && fields.includes("companyName") ? (
        <>
          <div className="col-12 my-3">
            <input
              className="vms-input"
              type="text"
              id="companyName"
              optional
              name="companyName"
              onChange={handleChange}
              pattern="[A-Za-z ]+"
              title="Must contain only alphabets"
              placeholder="Visitor’s Company Name (Optional)"
              onKeyUp={(e) => checkError(e, "cNameError")}
            />
            {error.cNameError ? (
              <div className="col-12">
                <small className="vms-small" id="cNameError">
                  &nbsp;
                </small>
              </div>
            ) : null}
          </div>
        </>
      ) : null} */}
      {/* {fields && fields.includes("purpose") ? (
        <>
          <div className="col-12 my-3">
            <input
              className="vms-input"
              list="visitingReasons"
              id="visitingReason"
              name="purpose"
              onChange={handleChange}
              onKeyUp={(e) => {
                checkError(e, "reasonError");
              }}
              optional
              placeholder="Purpose of Visit"
            />
            <datalist id="visitingReasons">
              <option value="Purpose of Visit" disabled selected>
                Purpose of Visit
              </option>
              <option value="Personal">Personal</option>
              <option value="Official">Official</option>
            </datalist>
            {error.reasonError ? (
              <div className="col-12">
                <small className="vms-small" id="reasonError">
                  Please enter a reason
                </small>
              </div>
            ) : null}
          </div>
        </>
      ) : null} */}
      {fields && fields.includes("userToMeetContact") ? (
        <>
          <div className="col-12 my-3" style={{ display: "flex", alignItems: "center" }}>
            <input
              className="vms-input"
              type="text"
              id="whomToMeet"
              optional
              name="userToMeetContact"
              value={formData.userToMeetContact}
              onChange={handleChange}
              placeholder="Host's Phone Number / Email ( optional )"
              onKeyUp={(e) => checkError(e, "vPersonError", true)}
            />
            <ContactPicker handleChange={handleChange} fieldName={"userToMeetContact"} />
          </div>
          {error.vPersonError ? (
            <div className="col-12 my-3">
              <small className="vms-small" id="vPersonError">
                Enter the visiting person's phone Number or Email
              </small>
            </div>
          ) : null}
        </>
      ) : null}
      {fields &&
      selectedNode &&
      selectedNode.slots &&
      selectedNode.slots.length > 0 &&
      fields.includes("slots") ? (
        <>
          <div className="col-12 my-3">
            <select name="slots" className="vms-select" onChange={handleChange}>
              <option disabled selected>
                Select a slot
              </option>
              {selectedNode.slots.map((slot) => (
                <option
                  value={JSON.stringify(slot)}
                  disabled={slot.peopleLimit - slot.noOfApplications <= 0}
                >
                  {`
          ${formatDate(slot.startTime)} - ${formatDate(slot.endTime)} ${
                    slot.peopleLimit >= 0 || slot.peopleLimit != null
                      ? (slot.peopleLimit - slot.noOfApplications < 0
                          ? 0
                          : slot.peopleLimit - slot.noOfApplications) +
                        "/" +
                        slot.peopleLimit +
                        " Left"
                      : ""
                  }
          `}
                </option>
              ))}
            </select>
          </div>
        </>
      ) : null}
      {/* {fields && fields.includes("startTime") ? (
        <>
          <div className="col-12 my-3 ml-auto mr-auto">
            <p className="col-5 my-1 vms-heading">Start Time</p>
            <input
              className="vms-input"
              required
              type="datetime-local"
              id="startTime"
              name="startTime"
              value={startTime}
              onChange={handleChange}
              placeholder="Start Time"
            />

            {error.stError ? (
              <div className="col-12 my-3">
                <small className="vms-small" id="stError">
                  Enter the proper Start Time
                </small>
              </div>
            ) : null}
          </div>
        </>
      ) : null} */}
      {/* {fields && fields.includes("endTime") ? (
        <>
          <div className="col-12 my-3 ml-auto mr-auto">
            <p className="col-5 my-1 vms-heading">End Time</p>
            <input
              className="vms-input"
              required
              type="datetime-local"
              id="endTime"
              name="endTime"
              value={endTime}
              onChange={handleChange}
              placeholder="End Time"
            />
          </div>
        </>
      ) : null} */}
      {fields && selectedNode && fields.includes("noOfGuests") ? (
        <>
          <div className="col-12 my-3 ml-auto mr-auto">
            <select name="noOfGuests" onChange={handleChange} className="maxCountSelect vms-select">
              <option disabled selected>
                Select No. of Guests
              </option>
              {[...Array(selectedNode.maxAddonGuests)].map((v, i) => (
                <option value={i + 1}>{i + 1}</option>
              ))}
            </select>
          </div>
        </>
      ) : null}

      <hr className="my-4"></hr>

      <div>
        <div class="collapse" id="collapseExample">
          {fields && fields.includes("name") ? (
            <>
              <div className="col-12 my-3">
                <input
                  className="vms-input"
                  type="text"
                  required
                  id="firstName"
                  name="name"
                  onChange={handleChange}
                  title="Must contain only alphabets"
                  placeholder="Visitor’s Name"
                  value={formData.name}
                  onKeyUp={(e) => checkError(e, "firstName")}
                />
              </div>
              {error.firstName ? (
                <div className="col-12">
                  <small className="vms-small" clas id="fNameError">
                    Please enter visitor's Name
                  </small>
                </div>
              ) : null}
            </>
          ) : null}
        </div>
        <div className="text-center">
          <p style={{ fontSize: "1rem", margin: "0px" }}>Add Name</p>
          <BiChevronDownCircle
            onClick={(e) => setCollapsed(!collapsed)}
            data-toggle="collapse"
            aria-controls="collapseExample"
            data-target="#collapseExample"
            role="button"
            style={{
              fontSize: "2.5rem",
              transform: !collapsed ? "rotate(0deg)" : "rotate(180deg)",
              transition: "all 0.5s ease",
            }}
          />
        </div>
      </div>

      <div className="fixed-bottom bg-white py-4">
        <div className="col-12 text-center">
          <button id="submitbutton" className="bigButton" onClick={(e) => handleSubmit()}>
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default Form;
