import React from "react";
import styled, { css } from "styled-components";
import TimePicker from "react-time-picker";
import { useSelector } from "react-redux";
import { breakPointWidth } from "../../config";
import DisabledOverlay, { DisabledLockIcon } from "../DisabledOverlay";
const AccessTime = ({ onChange, time }) => {
  const dimentions = useSelector((state) => state.dimentions);
  const nodeInfo = useSelector((state) => state.nodeInfo);
  return (
    <div className="d-flex ml-4 ">
      {!nodeInfo?.features?.editAccessHours ? <DisabledLockIcon size="2rem" /> : null}
      <div className="ml-4 p-3 rounded" style={{ position: "relative" }}>
        {!nodeInfo?.features?.editAccessHours ? <DisabledOverlay /> : null}
        <p className="m-0 font-weight-bold">Access time</p>
        {time.endTimeInDay === "24:00" ? null : (
          <TimeGroup dimentions={dimentions}>
            <Field>
              <TimePicker
                required
                clockIcon={null}
                isOpen={false}
                clearIcon={null}
                value={time.startTimeInDay}
                onChange={(e) => onChange(e, "startTimeInDay")}
              />
            </Field>
            <Field className={dimentions.width > breakPointWidth ? "" : "ml-4"}>to</Field>
            <Field>
              <TimePicker
                clockIcon={null}
                required
                isOpen={false}
                clearIcon={null}
                value={time.endTimeInDay}
                onChange={(e) => onChange(e, "endTimeInDay")}
              />
            </Field>
          </TimeGroup>
        )}
        <div className="d-flex align-items-center">
          <input
            className="my-2"
            type="checkbox"
            checked={time.endTimeInDay === "24:00"}
            onChange={(e) => {
              onChange(e.target.checked);
            }}
          />
          <span className="ml-2">Full Day</span>
        </div>
      </div>
    </div>
  );
};

export default AccessTime;

const TimeGroup = styled.div`
  ${(props) =>
    props.dimentions.width > breakPointWidth
      ? css`
          display: flex;
          align-items: center;
        `
      : css`
          display: block;
        `}
`;

const Field = styled.div`
  margin-right: 10px;
`;
